<template>
  <div class="overlay"
       v-if="activeDialog"
       :class="activeDialog ? 'active': ''"
  >
    <div class="logout-dialog-wrapper"
         v-click-outside="closePopups">
      <div
          id="close_dialog_logout"
          class="close-dialog"
           @click="activeDialog = false">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M12.0007 0.333008C5.54898 0.333008 0.333984 5.54801 0.333984 11.9997C0.333984 18.4513 5.54898 23.6663 12.0007 23.6663C18.4523 23.6663 23.6673 18.4513 23.6673 11.9997C23.6673 5.54801 18.4523 0.333008 12.0007 0.333008ZM17.834 16.188L16.189 17.833L12.0007 13.6447L7.81232 17.833L6.16732 16.188L10.3557 11.9997L6.16732 7.81134L7.81232 6.16634L12.0007 10.3547L16.189 6.16634L17.834 7.81134L13.6457 11.9997L17.834 16.188Z"
              fill="#D7DCE6"/>
        </svg>
      </div>
      <h2 class="logout-title">Log out</h2>
      <h3 class="logout-subtitle">Are you sure you want to leave PME Account?</h3>
      <div class="logout-image">
        <img :src="logout" alt="logout" />
      </div>
      <div class="dialog-btns-wrap">
        <button
            class="btn dialog-btn"
            id="dialog_logout_btn"
            @click="onConfirm"
        >
          <span class="dialog-btn-text">Log out</span>
        </button>

        <button
            id="dialog_logout_cancel_btn"
            @click="activeDialog = false"
            class="dialog-cancel">
          <span class="dialog-btn-text cancel">Cancel</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import logout from "@/assets/img/logout.svg"

export default {
  name: "LogoutPopup",
  data() {
    return {
      logout,
      activeDialog: false,
    };
  },
  methods: {
    openPopups() {
      this.activeDialog = true;
    },
    closePopups() {
      this.activeDialog = false;
    },
    onConfirm() {
      this.activeDialog = false;
      this.$emit('onConfirm');
    }
  }
}
</script>

<style scoped>
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay.active {
  animation: show .3s linear;
}

.logout-dialog-wrapper {
  width: 100%;
  max-width: 588px;
  min-height: 547px;
  border: 1px solid #D7DCE6;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 52px 32px 20px 32px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  position: relative;
}

.close-dialog {
  width: 70px;
  height: 70px;
  padding: 23px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.close-dialog svg {
  width: 100%;
  height: 100%;
}

.close-dialog svg path {
  transition: .5s all;
}

.close-dialog:hover svg path {
  fill: var(--accent-color);
}

.logout-title {
  margin: 0;
  font-size: 30px;
  font-weight: 700;
  color: var(--black-color);
}

.logout-subtitle {
  margin: 10px 0 0 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--light-gray);
}

.logout-image {
  margin: 27px 0 0 0;
  width: 274px;
  height: 274px;
}

.logout-image svg {
  width: 100%;
  height: 100%;
}

.logout-dialog-wrapper .dialog-btn {
  min-width: 140px;
  width: 100%;
  max-width: 152px;
  padding: 13px 12px;
  background-color: var(--white-color);
  border-color: var(--accent-color);
}

.logout-dialog-wrapper .dialog-btn:hover {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
}

.logout-dialog-wrapper .dialog-btn:hover .dialog-btn-text {
  color: var(--white-color);
}

.dialog-cancel:hover .dialog-btn-text.cancel {
  color: var(--secondary-black-color);
}

.dialog-btns-wrap {
  margin: 19px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.dialog-cancel {
  display: inline-block;
  padding: 15px;
  cursor: pointer;
  border: none;
  transition: .5s all;
  background: none;
}

.dialog-btn-text {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: var(--accent-color);
  transition: .5s all;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}

.dialog-btn-text.cancel {
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-text-color);
  text-decoration: underline;
}
</style>