<template>
  <tbody v-if="loadFlag">
  <tr>
    <div class="loader-bg">
      <div class="loader-p"></div>
    </div>
  </tr>
  </tbody>
</template>

<script>
export default {
  name: "Loader",
  props: {
    loadFlag: {
      type: Boolean
    }
  },
}
</script>

<style scoped>
.loader-bg {
  top: 100px;
}
</style>