import { render, staticRenderFns } from "./CaseMessagesApps.vue?vue&type=template&id=2e132834&scoped=true"
import script from "./CaseMessagesApps.vue?vue&type=script&lang=js"
export * from "./CaseMessagesApps.vue?vue&type=script&lang=js"
import style0 from "./CaseMessagesApps.vue?vue&type=style&index=0&id=2e132834&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e132834",
  null
  
)

export default component.exports