<template>
  <div class="container-small">
    <div class="holder-error">
      <div class="error-content">
        <div class="error-msg">
          <h4>Error 404. Page not found</h4>
          <p>Sorry! We are unable to find the page you are looking for.</p>
          <button
            id="no_foun_page_goback"
            class="btn"
            @click="$router.back()"
          >
            Go back
          </button>
        </div>
        <div class="error-img">
          <img src="@/assets/img/404.svg" alt="error image">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error",
}
</script>

<style scoped>
img {
  max-width: 100%;
  max-height: 100%;
}

.holder-error {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0px;
  position: relative;
  height: 100vh;
  min-height: 600px;
}

.error-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-msg {
  max-width: 525px;
}

.error-msg h4 {
  font-weight: 700;
  font-size: 24px;
  color: var(--light-gray);
  margin-bottom: 30px;
}

.error-msg p {
  font-weight: 500;
  font-size: 40px;
  margin-bottom: 30px;
}
</style>