import { render, staticRenderFns } from "./EditTagsPopup.vue?vue&type=template&id=566634f0&scoped=true"
import script from "./EditTagsPopup.vue?vue&type=script&lang=js"
export * from "./EditTagsPopup.vue?vue&type=script&lang=js"
import style0 from "./EditTagsPopup.vue?vue&type=style&index=0&id=566634f0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "566634f0",
  null
  
)

export default component.exports