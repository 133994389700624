<template>
  <div class="profile-content">
    <div class="holder-permissions-case" ref='permissions-cases'>
      <div class="holder-search">
        <CustomSearch
            :id="'permission_case_search'"
            :modelValue="searchText"
            @update:modelValue="searchText = $event"
            @filter="filter"
            placeholder="Search by case name"
        />
      </div>
      <vue-custom-scrollbar class="scroll-area" :settings="settings">
        <ul class="profile-nav">
          <router-link
              v-for="(caseItem, i) of casesList"
              :key="i"
              :id="`permission_case_${i}`"
              tag="li"
              :to="{name: `Case`, params: { slug: caseItem.id, caseName: caseItem.caseName, userId: caseItem.userId} }"
          >
            <a @click="getCaseInfo(caseItem)">
              <span class="span-admin nav-title">{{caseItem.caseName}}</span>
              <div class="holder-contacts">
                <div class="contacts-imgs">
                  <div
                      v-for="(el, index) in caseItem.usersAvatars.slice(0, 3)"
                      :key="index"
                      class="img-item"
                  >
                    <img
                        v-if="el"
                        :src="el"
                        onerror="this.src='https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png'"
                        alt=""
                    >
                    <img
                        v-else
                        src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_avatar_child_kid-512.png"
                        alt=""
                    >
                  </div>
                </div>
                <div class="contacts-count">
                  <span>{{caseItem.countUsers + " " + (caseItem.countUsers === 1 ? "contact" : "contacts") }}</span>
                </div>
              </div>
            </a>
          </router-link>
        </ul>
      </vue-custom-scrollbar>
    </div>
    <div class="permissions-empty-wrap">
      <div class="permissions-empty-content">
        <svg width="114" height="158" viewBox="0 0 114 158" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M74.9169 56.1811C74.9169 46.286 66.8872 38.2676 56.9965 38.2676C47.0991 38.2676 39.0762 46.286 39.0762 56.1811C39.0762 62.9001 42.7775 68.7453 48.2492 71.8128L48.6204 59.9255C48.6204 55.0628 52.5663 51.117 57.4357 51.117H57.936C61.7709 51.117 64.8792 54.2252 64.8792 58.0601V72.2565C70.8126 69.3384 74.9169 63.242 74.9169 56.1811Z" fill="#082655"/>
          <path d="M64.8813 58.0579C64.8813 54.223 61.7731 51.1147 57.9382 51.1147H57.4379C52.5684 51.1147 48.6226 55.0606 48.6226 59.9232L48.2513 71.8105L47.425 98.0006C47.2371 98.8088 47.1307 117.019 47.1307 117.019C45.5913 115.45 43.8776 113.655 42.3382 111.937C38.7433 107.927 32.1397 105.224 27.7502 104.726C25.4389 104.462 23.6731 104.778 22.4959 105.661C22.1201 105.949 21.7511 106.268 21.407 106.614C19.1409 108.847 17.4317 112.451 19.0141 115.09C19.6978 116.245 20.8727 117.01 22.1224 117.814C23.7772 118.887 26.564 120.048 28.658 122.721C32.1669 127.211 40.2895 137.244 45.2812 142.598C46.3203 143.243 48.5184 145.437 49.5553 145.991C52.7065 148.004 55.2804 149.899 56.1724 150.924C56.8696 151.723 60.9943 153.835 60.9943 153.835C64.3289 155.947 70.3982 157.922 77.4092 157.922C84.4067 157.922 90.7635 158.34 95.4609 154.209H95.7122L95.8367 153.896C100.616 149.518 103.588 143.453 103.588 136.746C103.588 135.086 103.588 128.97 103.588 127.82V104.686C103.588 103.115 103.242 97.8467 98.8136 96.2643C96.6856 95.5081 94.6731 95.7934 92.9571 96.5337L92.5564 96.5518L92.4319 96.5608C91.6622 93.6677 89.738 91.8 86.8923 91.3042C83.4559 90.7089 81.0743 91.7366 79.6413 92.7757C78.2174 89.715 75.3627 88.119 71.4984 88.445C68.6007 88.6895 66.4456 90.1135 64.8813 91.9879V72.2542V58.0579Z" fill="#FFBE9D"/>
          <path d="M96.9819 85.9321L100.538 89.8892C101.222 90.6589 102.463 90.6114 103.065 89.7783C109.922 80.2635 113.7 68.8108 113.7 56.7651C113.7 25.495 88.2617 0.0610352 56.9961 0.0610352C54.85 0.0610352 52.6745 0.181017 50.5239 0.420981C24.4312 3.32772 3.44786 24.388 0.63167 50.5011C-0.903196 64.7111 2.857 78.5724 10.9252 89.776C11.5229 90.6136 12.7635 90.6567 13.4562 89.8915L17.0081 85.9366C17.5129 85.3774 17.5514 84.5466 17.1236 83.9263C11.4301 75.6091 8.44183 65.6732 8.74065 55.3434C9.46281 30.2694 29.6493 9.71168 54.6938 8.54355C82.3848 7.18073 105.272 29.3774 105.272 56.7606C105.272 66.5583 102.284 75.9803 96.8597 83.9173C96.4363 84.5466 96.4725 85.3729 96.9819 85.9321Z" fill="#082655" fill-opacity="0.1"/>
          <path d="M81.044 68.1926L84.8088 72.3806C85.5785 73.2431 86.9775 73.0462 87.4936 72.0184C89.8707 67.321 91.1225 62.1527 91.1225 56.7671C91.1225 40.703 79.7174 26.6606 63.9953 23.369C59.4088 22.3978 54.5914 22.3978 49.9913 23.369C34.2781 26.6606 22.873 40.703 22.873 56.7671C22.873 62.1572 24.1249 67.321 26.4974 72.0184C27.0226 73.0484 28.4126 73.2454 29.1868 72.3806L32.9493 68.1926C33.3726 67.7217 33.47 67.0561 33.2323 66.4743C31.9668 63.4001 31.3012 60.1039 31.3012 56.7648C31.3012 46.023 38.0927 36.3203 48.1961 32.6212C53.8398 30.5611 60.1581 30.5611 65.7972 32.6212C75.9029 36.318 82.6898 46.023 82.6898 56.7648C82.6898 60.1039 82.0243 63.4001 80.7656 66.4743C80.5279 67.0606 80.623 67.7262 81.044 68.1926Z" fill="#5692B7"/>
        </svg>
        <span class="permissions-empty-title">Choose Case firstly to set permissions</span>
      </div>
    </div>
  </div>
</template>

<script>
import settings from "../../mixins/scrollSettings";
import CustomSearch from "../inputs/CustomSearch";
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import {mapActions, mapMutations} from "vuex";

export default {
  name: "PermissionsCases",
  components: {
    CustomSearch,
    vueCustomScrollbar,
  },
  mixins: [settings],
  data() {
    return {
      tabCase: "Case 3000",
      casesList: [],
      searchText: '',
    }
  },
  methods: {
    ...mapActions({
      getPermissionCase: 'profile/getPermissionCase',
    }),
    ...mapMutations({
      getCurrentCaseData: 'profile/SET_CURRENT_CASE_DATA',
    }),
    getCaseInfo(caseItem) {
      this.getCurrentCaseData(caseItem)
    },
    filter()  {
      this.getCase()
    },
    getCase() {
      this.getPermissionCase(this.searchText).then(res => {
        this.casesList = res.data.body
      })
    },
  },
  mounted() {
    this.getCase()
    this.$nextTick(function () {
      this.$refs['permissions-cases'].style.transform = "translateX(0)";
    })
  },
}
</script>

<style scoped>
p {
  margin-top: 0;
}

ul {
  padding: 0;
}

.profile-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}

.holder-permissions-case {
  border-right: 1px solid var(--border-color);
  transform: translateX(-100%);
  /*transition: all .5s;*/
  /*transition-delay: 1s;*/
}

.profile-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 430px;
  margin-top: 0;
  height: calc(100vh + 88px);
}

.profile-nav li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all .2s;
  border-bottom: 1px solid var(--border-color);
}

.profile-nav li:first-child {
  border-top: 1px solid var(--border-color);
}

.profile-nav li.active {
  color: var(--secondary-black-color);
  background-color: rgba(28, 202, 205, 0.05);
}

.profile-nav li.active {
  color: var(--secondary-black-color);
  background-color: rgba(28, 202, 205, 0.05);
}

.profile-nav li:after {
  content: '';
  width: 8px;
  height: 100%;
  background-color: var(--accent-color);
  border-radius: 10px 0 0 10px;
  opacity: 0;
  transition: all .2s;
}

.profile-nav li a {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  text-decoration: none;
  padding: 18px 0 18px 20px;
}

.profile-nav li:hover {
  color: var(--secondary-black-color);
  background-color: rgba(28, 202, 205, 0.05);
}

.profile-nav span {
  display: inline-block;
  min-width: 95px;
  padding: 10px 8px;
  text-align: center;
  border-radius: 20px;
  margin-right: 37px;
}


.profile-nav li:hover:after  {
  opacity: 1;
}

.profile-nav li.active::after {
  opacity: 1;
}

.holder-contacts {
  display: flex;
  align-items: center;
}

.contacts-imgs {
  display: flex;
}

.img-item {
  width: 32px;
  height: 32px;
  overflow: hidden;
  border: 1px solid var(--white-color);
  border-radius: 50%;
  margin-left: -13px;
}

.img-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contacts-count {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.contacts-count span {
  font-weight: 500;
  font-size: 14px;
  color: var(--secondary-text-color);
  padding: 0;
  text-align: left;
  margin-left: 10px;
  margin-bottom: 5px;
  text-decoration: underline;
}

.contacts-count span:last-child {
  font-weight: 400;
  font-size: 12px;
  color: var(--light-gray);
}

.holder-search {
  padding: 28px 0;
}

.nav-title {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  color: var(--light-gray);
}

.span-admin {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

</style>