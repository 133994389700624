<template>
  <tbody>
  <tr v-for="(item, i) in notificationsTeamList"
      :key="i">
    <td class="w-300">
      <div
          :id="`goto_user_profile_${item.userPublicId}`"
          @click="gotoProfile(item.userPublicId)"
          class="holder-info name">
        <div class="img">
          <img
              v-if="item.userAvatar"
              :src="item.userAvatar"
              onerror="this.src='https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png';"
              alt=""
          >
          <img
              v-else
              src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png"
              alt=""
          >
        </div>
        <div class="info-name" v-if="item.userFirstName && item.userLastName">
          <span
              class="info-name">{{ capitalizeFirstLetter(item.userFirstName) }} {{ capitalizeFirstLetter(item.userLastName) }}</span>
        </div>
        <div class="info-name" v-else>
          <span class="info-name">{{ item.userEmail }}</span>
        </div>
      </div>
    </td>
    <td>
      <div class="holder-info">
        <div class="info">
          <span class="secondary-black-color">{{ item.action }}</span>
        </div>
      </div>
    </td>
    <td>
      <div class="holder-info">
        <div class="info-name">
          <span v-if="item.caseName" class="secondary-black-color">{{ item.caseName }}</span>
          <span v-else class="secondary-black-color">-</span>
        </div>
      </div>
    </td>
    <td>
      <div
          :id="`goto_user_profile_${item.actorPublicId}`"
          @click="gotoProfile(item.actorPublicId)"
          class="holder-info name">
        <div class="img">
          <img
              v-if="item.actorAvatar"
              :src="item.actorAvatar"
              onerror="this.src='https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png';"
              alt=""
          >
          <img
              v-else
              src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png"
              alt=""
          >
        </div>
        <div class="info-name">
          <span
              class="info-name">{{ capitalizeFirstLetter(item.actorFirstName) }} {{ capitalizeFirstLetter(item.actorLastName) }}</span>
        </div>
      </div>
    </td>
    <td>
      <div class="holder-info">
        <div class="info">
          <div class="roles">
            <span class="span-cases" v-if="!item.actorRoles.length">-</span>
            <span class="span-cases" v-else-if="item.actorRoles.length > 2">
              <div class="span-cases-wrap">
          <span
              v-text="item.actorRoles[0].toLowerCase()[0].toUpperCase() + item.actorRoles[0].toLowerCase().slice(1).replaceAll('_', ' ')"
              class="span-cases"
              :class="`span-cases-` + item.actorRoles[0].toLowerCase()"
          ></span>
              <span>, </span>
              <span
                  v-text="item.actorRoles[1].toLowerCase()[0].toUpperCase() + item.actorRoles[1].toLowerCase().slice(1).replaceAll('_', ' ')"
                  class="span-cases"
                  :class="`span-cases-` + item.actorRoles[1].toLowerCase()"
              ></span>
                <span>, </span>
              <span
                  v-text="item.actorRoles[2].toLowerCase()[0].toUpperCase() + item.actorRoles[2].toLowerCase().slice(1).replaceAll('_', ' ')"
                  class="span-cases"
                  :class="`span-cases-` + item.actorRoles[2].toLowerCase()"
              ></span>
            </div>
            </span>
            <span v-else-if="item.actorRoles.length === 1"
                  v-text="item.actorRoles[0].toLowerCase()[0].toUpperCase() + item.actorRoles[0].toLowerCase().slice(1).replaceAll('_', ' ')"
                  class="span-cases"
                  :class="`span-cases-` + item.actorRoles[0].toLowerCase()"
            ></span>
            <div
                v-else
                class="span-cases-wrap"
            >
          <span
              v-text="item.actorRoles[0].toLowerCase()[0].toUpperCase() + item.actorRoles[0].toLowerCase().slice(1).replaceAll('_', ' ')"
              class="span-cases"
              :class="`span-cases-` + item.actorRoles[0].toLowerCase()"
          ></span>
              <span>, </span>
              <span
                  v-text="item.actorRoles[1].toLowerCase()[0].toUpperCase() + item.actorRoles[1].toLowerCase().slice(1).replaceAll('_', ' ')"
                  class="span-cases"
                  :class="`span-cases-` + item.actorRoles[1].toLowerCase()"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </td>
    <td>
      <div class="holder-info">
        <div class="info">
          <span class="">{{ parseDate(item.actionDate) }}</span>
        </div>
      </div>
    </td>
  </tr>
  </tbody>
</template>

<script>
import parseDate from "../../mixins/parseDate";
import capitalizeFirstLetter from "../../mixins/capitalizeFirstLetter";

export default {
  name: "NotificationTeamItem",
  props: {
    notificationsTeamList: Array
  },
  mixins: [parseDate, capitalizeFirstLetter],
  methods: {
    gotoProfile(id) {
      let route = this.$router.resolve({name: 'User Profile', params: {slug: id}})
      window.open(route.href, '_blank');
    }
  }
}
</script>

<style scoped>
.roles .span-cases {
  text-decoration: none;
  font-size: 12px;
  line-height: 19px;
}

.info-name {
  cursor: pointer;
  transition: all .5s;
}

.info-name:hover {
  color: var(--accent-color);
}

</style>