<template>
  <div class="overlay"
       :class="dialog ? 'active': ''"
       v-if="dialog">
    <div class="popup" v-click-outside="closePopup">
      <div
          :id="`close_case_popup_${caseId}`"
          class="popup-close" @click="closePopup">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M11.9997 0.333496C5.54801 0.333496 0.333008 5.5485 0.333008 12.0002C0.333008 18.4518 5.54801 23.6668 11.9997 23.6668C18.4513 23.6668 23.6663 18.4518 23.6663 12.0002C23.6663 5.5485 18.4513 0.333496 11.9997 0.333496ZM17.833 16.1885L16.188 17.8335L11.9997 13.6452L7.81134 17.8335L6.16634 16.1885L10.3547 12.0002L6.16634 7.81183L7.81134 6.16683L11.9997 10.3552L16.188 6.16683L17.833 7.81183L13.6447 12.0002L17.833 16.1885Z"
              fill="#D7DCE6"/>
        </svg>
      </div>
      <div class="popup-content">
        <h2 class="popup-title">Invite Case Members</h2>
        <div class="">
          <form @submit.prevent="submit">
            <div class="popup-form-item" :class="{ 'form-group--error': $v.formData.role.$error }">
              <label>Choose Role</label>
              <CustomSelect
                  :id="`assign_select_role_user`"
                  :options="mockRole"
                  v-model="$v.formData.role.$model"
                  :multiple="multipleRole"
                  :placeholder="'Role'"
                  :class="false ? 'is-invalid' : ''"
              />
              <div class="error" v-if="!$v.formData.role.required">Field is required</div>
            </div>
            <div class="popup-form-item">
              <div class="popup-form-item-headrer">
                <label for="assign_case_form_message">Write Message to your User</label>
                <span class="form-noties">Optional</span>
              </div>
              <textarea v-model="formData.assignMessage" id="assign_case_form_message" placeholder="Text message"></textarea>
            </div>
          </form>
        </div>
        <div v-if="error" class="popup-error">
          {{error}}
        </div>
        <button
            :id="`assign_case_popup_${caseId}`"
            class="btn popup-action-btn"
            @click="submit"
        >
          <span class="action-btn-text">Send</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import getRoleName from "@/mixins/getRoleName";
import CustomSelect from "@/components/inputs/CustomSelect.vue";

export default {
  name: "AssignCasePopup",
  components: {
    CustomSelect,
  },
  props: {
    caseId: {
      type: String,
      required: true,
    },
    error: {
      type: String,
    },
  },
  data() {
    return {
      dialog: false,
      payload: null,
      multipleRole: true,
      mockRole: [{name: "Reviewer"}, {name: "Review admin"}, {name: "Case administrator"}, {name: "System administrator"}],
      customClass: "role-optional",
      formData: {
        role: "",
        assignMessage: '',
      },
    }
  },
  validations: {
    formData: {
      role: {
        required,
      },
    },
  },
  mixins: [getRoleName],
  methods: {
    openPopup(payload) {
      this.dialog = true
      this.payload = payload;
    },
    closePopup() {
      this.dialog = false
    },
    resetForm() {
      this.formData.assignMessage = ""
      this.formData.role = ''
      this.$emit('clear', null);
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    submit() {
      this.isSending = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        this.isSending = false
      } else {
        this.submitStatus = 'PENDING'
        this.formData.assignMessage.length ? this.formData.assignMessage : this.formData.assignMessage = null
        this.formData.role = this.getRoleNameBE(this.formData.role);
        let data = Object.assign(this.payload, this.formData)
        this.$emit('submit', data)
        this.resetForm()
      }
    },
  },
}
</script>

<style scoped>

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.overlay.active {
  animation: show .3s linear;
}

.popup {
  position: absolute;
  padding: 52px 128px 30px 128px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 588px;
  min-height: 450px;
  border-radius: 30px;
  background-color: var(--white-color);
}

.popup-close {
  position: absolute;
  right: 0;
  top: 0;
  width: 70px;
  height: 70px;
  padding: 23px;
  cursor: pointer;
}

.popup-close svg path {
  transition: .5s all;
}

.popup-close:hover svg path {
  fill: var(--accent-color);
}

.popup-title {
  font-size: 30px;
  line-height: 30px;
  color: var(--black-color);
  font-weight: 700;
  text-align: center;
  margin: 0;
}

.popup-message {
  margin: 10px 0 0 0;
  text-align: center;
  display: block;
  color: var(--light-gray);
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
}

.popup-action-wrapper {
  margin: 30px 0 0 0;
  padding: 20px 20px 16px 20px;
  width: 100%;
  min-height: 213px;
  background: rgba(28, 202, 205, 0.05);
  border-radius: 6px;
}

.delete-action {
  padding: 20px 18px 16px 18px;
}

.action-image.archive {
  margin: 0 auto;
  width: 81px;
  height: 78px;
}

.action-image.export {
  margin: 0 auto;
  width: 93px;
  height: 89px;
}

.action-image.delete {
  margin: 0 auto;
  width: 73px;
  height: 93px;
}

.action-message {
  display: block;
  margin: 26px 0 0 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--accent-color);
  text-align: center;
}

.action-sub-message {
  display: block;
  margin: 17px 0 0 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--accent-color);
  text-align: center;
}

.action-sub-message strong {
  font-size: 18px;
}

.popup-action-btn {
  padding: 15px 50px;
  width: 100%;
  max-width: 152px;
  margin: 18px auto 0;
  background-color: var(--white-color);
  border-color: var(--accent-color);
  justify-content: center;
  border-radius: 25px;
}

.popup-action-btn:hover {
  background-color: var(--accent-color);
}

.popup-action-btn:hover .action-btn-text {
  color: var(--white-color);
}

.action-btn-text {
  display: block;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  color: var(--accent-color);
  transition: .5s all;
}

.popup-form-item span,
.popup-form-item label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: #696F79;
}

.form-noties {
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: #696F79;
}

.popup-form-item-headrer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 7px 0px;
}

.popup-form-item textarea {
  height: 130px;
  resize: none;
  border-radius: 20px !important;
}

.popup-title {
  margin-top: 0;
  margin-bottom: 35px;
}

.popup-error {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--alert-color);
  margin-top: 10px;
}

</style>
