<template>
  <div class="holder-file-input">
    <!--      :href="damagedFile.path"-->
    <a
      :class="!checkPermission(['view_case']) ? 'disabled' : ''"
      :href="`javascript:void(0)`"
      style="cursor: default"
      class="damaged-file-item"
      download=""
      :id="`dashboard_file_${damagedFile.id}`"
    >
      <div class="svg">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle opacity="0.07" cx="16" cy="16" r="16" fill="#8C8C8C"/>
          <g clip-path="url(#clip0_4461:174)">
            <path d="M22.7766 15.2509C22.706 15.2046 22.6169 15.197 22.5394 15.2307L21.0586 15.8747C21.0471 15.8796 21.0361 15.8855 21.0255 15.8921C20.9585 15.9341 20.8734 15.9318 20.8087 15.8863C20.8058 15.8842 20.8029 15.8823 20.7999 15.8803L19.0864 14.7794C18.8322 14.6036 18.4887 14.6171 18.249 14.813L17.0523 15.7914C17.0069 15.8286 16.9465 15.8433 16.8892 15.8317L14.802 15.0697C14.7943 15.0669 14.7864 15.0645 14.7785 15.0624C14.6024 15.0171 14.4184 15.0417 14.2604 15.1315C14.2565 15.1337 14.2527 15.136 14.249 15.1384L12.7439 16.1038C12.6916 16.1317 12.6285 16.1343 12.5741 16.1108C12.5731 16.1103 12.572 16.1099 12.571 16.1095L10.4566 15.2292C10.3794 15.197 10.2911 15.2056 10.2214 15.252C10.1517 15.2985 10.1099 15.3767 10.1099 15.4604V24.0004C10.1099 24.5515 10.5582 24.9999 11.1093 24.9999H18.6763C19.0703 24.9999 19.4408 24.8471 19.7201 24.5695L22.4497 22.027C22.4518 22.025 22.4539 22.0229 22.456 22.0208C22.7357 21.7411 22.8897 21.3693 22.8897 20.9737V15.4604C22.8898 15.3759 22.8472 15.2972 22.7766 15.2509Z" fill="#FFB2CD"/>
            <path d="M22.756 15.2396C22.6891 15.2043 22.6095 15.2006 22.5392 15.2311L22.5195 15.2396H22.756Z" fill="#FF9D65"/>
            <path d="M21.8912 7H11.1103C10.5592 7 10.1108 7.44835 10.1108 7.99946V12.9316C10.1108 13.0194 10.1568 13.1008 10.2321 13.1461L12.0585 14.2465C12.2096 14.3376 12.3793 14.3827 12.5487 14.3827C12.7408 14.3827 12.9325 14.3246 13.0962 14.2092L14.3061 13.3564C14.4315 13.2679 14.5914 13.2496 14.7338 13.3074L16.4816 14.0173C16.7874 14.1415 17.1418 14.0962 17.4065 13.8991L18.4985 13.0859C18.6467 12.9755 18.8512 12.9679 19.0072 13.0669L20.3555 13.922C20.6498 14.1087 21.022 14.1194 21.3266 13.9498L22.7621 13.1503C22.8415 13.1061 22.8907 13.0223 22.8907 12.9315V7.99946C22.8907 7.44835 22.4424 7 21.8912 7V7Z" fill="#FFB2CD"/>
            <path d="M19.7946 21.056C19.2232 21.056 18.7583 21.5208 18.7583 22.0923L18.7771 24.9966C19.1332 24.9729 19.465 24.8237 19.7203 24.5699L22.4499 22.0274C22.452 22.0254 22.4542 22.0234 22.4563 22.0212C22.7199 21.7576 22.8718 21.4121 22.8883 21.042L19.7946 21.056Z" fill="#F1105F"/>
          </g>
          <defs>
            <clipPath id="clip0_4461:174">
              <rect width="17" height="17" fill="white" transform="translate(8 8)"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <span
          class="damaged-file-item-text"
          v-text="damagedFile.name"></span>
    </a>
  </div>
</template>

<script>

import checkPermission from "../../utils/permission";

export default {
  name: "CustomInputFile",
  props: {
    damagedFile: Object
  },
  methods: {
    checkPermission,
  },
}
</script>

<style scoped>
.holder-file-input {
  margin-bottom: 13px;
}
.damaged-file-item {
  display: block;
  position: relative;
  color: var(--secondary-black-color);
  text-decoration: none;
  transition: all .3s;
}

.damaged-file-item:hover {
  color: var(--error-color);
}

.svg {
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
}
.damaged-file-item-text {
  display: block;
  cursor: default;
  padding: 14px;
  border-radius: 30px;
  background-color: var(--white-color);
  border: 1px solid var(--input-placeholder-value-color);
  padding-left: 56px;
  transition: all .3s;
}
</style>