<template>
  <div class="otp-wrapper">
    <span class="otp-subtitle">Enter code:</span>
    <v-otp-input
        ref="otpInput"
        input-classes="otp-input"
        separator="-"
        :num-inputs="6"
        @on-change="handleOnChange"
        @on-complete="handleOnComplete"
        :input-type="'number'"
        class="otp"
        :class="otpVerify === null ? '' : otpVerify ? 'valid' : 'not-valid'"
    />
    <div v-if="otpVerify !== null && !otpVerify" class="phone-error" style="color:red;">Incorrect code</div>
    <div class="resend" :class="{ disabled: remainingTime > 0 }">
      Didn’t get code?
      <span @click="resendCode">Resend code</span>
      <span v-if="remainingTime > 0"> available in {{ remainingTime }}s</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "OtpCode",
  props: {
    otpVerify: {
      type: Boolean,
      default: null
    },
    remainingTime: {
      type: Number,
      required: true,
    }
  },
  methods: {
    resendCode() {
      if (this.remainingTime <= 0) {
        this.$emit('resendCode');
      }
    },
    handleOnComplete(value) {
      if(value === null) {
        return
      }
      this.$emit('update:otpValue', value);
    },
    handleOnChange() {
      this.$refs.otpInput.$children.map((item) => {

        if(item.$el.firstChild.value.length) {
          item.$el.firstChild.classList.add('not-empty');
        }else{
          item.$el.firstChild.classList.remove('not-empty');
        }
      })
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    handleFocusInput() {
      this.$refs.otpInput.handleOnFocus(-1);
    },
    handleUndisabledInput() {
      this.$refs.otpInput.$children.map((item) => {
        item.$el.firstChild.disabled = false;
      })
    },
  },
  mounted() {
    this.$refs.otpInput.$children.map((item, index) => {
      item.$el.firstChild.id = `verify__otp_input_${index}`
    })
  },
}
</script>

<style>

.otp-wrapper {
  margin-bottom: 40px;
}

.otp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.otp-input {
  width: 51px;
  height: 71px;
  margin-right: 18px;
  background: #F8FCFC;
  box-shadow: 0px 2px 7px rgb(0 0 0 / 10%);
  border-radius: 2px;
  text-align: center;
  border: 1px solid transparent;
  padding: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: var(--black-color)
}

.otp-input:last-child {
  margin-right: 0;
}

.otp-input ~ span {
  display: none;
}

.otp-input .error {
  border: 1px solid red !important;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: block;
  margin-bottom: 10px;
  color: var(--secondary-black-color);
  text-align: left;
}

.otp-input:focus-visible {
  outline: none;
}

.otp.not-valid .otp-input {
  border: 1px solid var(--alert-color);
  color: var(--alert-color);
}


.otp.valid .otp-input {
  border: 1px solid var(--accent-color);
  color: var(--accent-color);
}

.otp-input.not-empty {
  border: 1px solid var(--accent-color);
  color: var(--accent-color);
}

.otp-wrapper .resend {
  margin-top: 32px;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: var(--secondary-text-color);
}

.otp-wrapper .resend span {
  color: var(--secondary-black-color);
  text-decoration: underline;
  cursor: pointer;
}

.otp-wrapper .disabled span {
  color: var(--secondary-text-color);
  text-decoration: none;
  cursor: default;
}

.otp-subtitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: var(--secondary-text-color);
}

</style>
