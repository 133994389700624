<template>
  <div>
    <Header/>
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
    <div class="container-small">
      <section class="section login-section">
        <div class="holder-img">
          <img :src="loginIcon" alt="login-in-img">
        </div>
        <div class="holder-form">
          <form @submit.prevent="submit">
            <h2 class="form-main-title">Log in your account</h2>
            <p class="login-text">Don’t have an account yet?
              <a @click="$router.push({name: 'RegisterAccount'}).catch(()=>{})" class="sign-in">Sign up</a>
            </p>
            <div class="form-group"
                 :class="{ 'form-group--error': $v.formData.email.$error }"
            >
              <label for="user-mail" class="label">Email address*</label>
              <input
                  id="user-mail"
                  type="text"
                  placeholder="Enter email address"
                  class="input"
                  :class="false ? 'is-invalid' : ''"
                  v-model.trim="$v.formData.email.$model"
              >
              <div class="error" v-if="!$v.formData.email.required">Field is required</div>
              <div class="error" v-if="!$v.formData.email.email">This must be an email</div>
            </div>
            <div class="form-group group-password"
                 :class="{ 'form-group--error': $v.formData.password.$error }"
            >
              <label for="user-pass" class="label">Password*</label>
              <input
                  id="user-pass"
                  :type="passwordType"
                  class="input"
                  placeholder="Enter password"
                  :class="false ? 'is-invalid' : ''"
                  v-model.trim="$v.formData.password.$model"
              >
              <svg v-if="passwordType === 'password'"
                   id="login_show_password_button"
                   @click="togglePassword"
                   class="input-password"
                   width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19 7C19 7 15.4375 0.5 9.5 0.5C3.5625 0.5 0 7 0 7C0 7 3.5625 13.5 9.5 13.5C15.4375 13.5 19 7 19 7ZM1.39294 7C1.96712 7.86849 2.62752 8.67737 3.36419 9.41445C4.8925 10.9378 6.9825 12.3182 9.5 12.3182C12.0175 12.3182 14.1063 10.9378 15.637 9.41445C16.3737 8.67737 17.0341 7.86849 17.6082 7C17.0341 6.13151 16.3737 5.32263 15.637 4.58555C14.1063 3.06218 12.0163 1.68182 9.5 1.68182C6.9825 1.68182 4.89369 3.06218 3.363 4.58555C2.62632 5.32263 1.96592 6.13151 1.39175 7H1.39294Z" fill="#D7DCE6"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 4.0451C8.71264 4.0451 7.95753 4.35638 7.40078 4.91047C6.84403 5.46455 6.53125 6.21605 6.53125 6.99964C6.53125 7.78324 6.84403 8.53474 7.40078 9.08882C7.95753 9.64291 8.71264 9.95419 9.5 9.95419C10.2874 9.95419 11.0425 9.64291 11.5992 9.08882C12.156 8.53474 12.4688 7.78324 12.4688 6.99964C12.4688 6.21605 12.156 5.46455 11.5992 4.91047C11.0425 4.35638 10.2874 4.0451 9.5 4.0451ZM5.34375 6.99964C5.34375 5.90261 5.78164 4.85051 6.56109 4.07479C7.34054 3.29908 8.39769 2.86328 9.5 2.86328C10.6023 2.86328 11.6595 3.29908 12.4389 4.07479C13.2184 4.85051 13.6562 5.90261 13.6562 6.99964C13.6562 8.09668 13.2184 9.14878 12.4389 9.9245C11.6595 10.7002 10.6023 11.136 9.5 11.136C8.39769 11.136 7.34054 10.7002 6.56109 9.9245C5.78164 9.14878 5.34375 8.09668 5.34375 6.99964Z" fill="#D7DCE6"/>
              </svg>
              <svg v-if="passwordType === 'text'"
                   id="login_show_password_button"
                   @click="togglePassword"
                   class="input-text"
                   width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.8644 12.4404C17.8844 10.8275 19.0006 9 19.0006 9C19.0006 9 15.4381 3.15625 9.50062 3.15625C8.36014 3.15976 7.23256 3.37239 6.1875 3.781L7.10187 4.60019C7.8707 4.3496 8.68238 4.22053 9.50062 4.21875C12.0181 4.21875 14.1069 5.45975 15.6376 6.82931C16.3743 7.49198 17.0347 8.2192 17.6089 9C17.54 9.09244 17.464 9.19444 17.3773 9.306C16.9795 9.816 16.3917 10.496 15.6376 11.1707C15.4417 11.346 15.2374 11.5192 15.0237 11.6871L15.8644 12.4404Z" fill="#696F79"/>
                <path d="M13.416 10.2493C13.681 9.5861 13.73 8.86924 13.5575 8.18209C13.3849 7.49494 12.9978 6.86576 12.4413 6.36776C11.8847 5.86976 11.1815 5.52342 10.4135 5.36902C9.64549 5.21463 8.84429 5.25854 8.1031 5.49564L9.08041 6.37008C9.53678 6.31163 10.0021 6.34909 10.4395 6.47948C10.8769 6.60987 11.2743 6.8296 11.6003 7.12128C11.9263 7.41295 12.1719 7.76855 12.3176 8.15989C12.4633 8.55123 12.5052 8.96756 12.4399 9.37589L13.416 10.2493ZM9.92116 11.6295L10.8973 12.5028C10.1561 12.7399 9.3549 12.7838 8.58691 12.6294C7.81892 12.4751 7.11572 12.1287 6.55914 11.6307C6.00255 11.1327 5.61545 10.5035 5.4429 9.81638C5.27034 9.12923 5.31942 8.41237 5.58441 7.7492L6.56172 8.62364C6.4964 9.03197 6.53827 9.4483 6.68399 9.83964C6.82972 10.231 7.07531 10.5866 7.4013 10.8783C7.72729 11.1699 8.12472 11.3897 8.5621 11.5201C8.99948 11.6504 9.46479 11.6879 9.92116 11.6295Z" fill="#696F79"/>
                <path d="M3.97812 6.31182C3.76437 6.48182 3.55894 6.65394 3.363 6.82926C2.62633 7.49193 1.96593 8.21914 1.39175 8.99995L1.62331 9.30595C2.02113 9.81595 2.60894 10.4959 3.363 11.1706C4.89369 12.5402 6.98369 13.7812 9.5 13.7812C10.3503 13.7812 11.1506 13.6399 11.8988 13.3987L12.8131 14.2189C11.7681 14.6275 10.6405 14.8402 9.5 14.8437C3.5625 14.8437 0 8.99995 0 8.99995C0 8.99995 1.11506 7.17138 3.13619 5.55957L3.97694 6.31288L3.97812 6.31182Z" fill="#696F79"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2051 15.751L1.95508 3.00103L2.79583 2.24878L17.0458 14.9988L16.2051 15.751Z" fill="#696F79"/>
              </svg>
              <div class="error" v-if="!$v.formData.password.required">Field is required</div>
            </div>
            <a @click="$router.push({name: 'ResetPasswordForm'}).catch(()=>{})" id="forgot_password_button" class="forgot-pass">Forgot password</a>
            <div class="group">
              <button id="login_button" class="submit-btn">Log in</button>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Header from "../components/layout/Header";
import AlertErrorMsg from "../components/profile/AlertErrorMsg";
import {email, required} from "vuelidate/lib/validators";
import { mapActions } from 'vuex';
import loginIcon from "@/assets/img/login.svg"

export default {
  name: "LoginInAccount",
  components: {
    Header,
    AlertErrorMsg
  },
  data() {
    return {
      loginIcon,
      formData: {
        email: "",
        password: "",
      },
      passwordType: "password",
      errorMessage: '',
      submitStatus: null,
    }
  },
  validations: {
    formData: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    ...mapActions({
      login: 'login/login',
    }),
    togglePassword() {
      this.passwordType = this.passwordType === "password" ? "text" : "password";
    },
    submit() {
      if (this.submitStatus === 'PENDING') return;
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        this.submitStatus = 'PENDING';
        this.login({params: this.formData}).then((res) => {
          localStorage.setItem('one_time_login_token', res.data.body.token);
          localStorage.setItem('one_time_login_type_verification', res.data.body.typeVerification);
          localStorage.setItem('one_time_login_email', this.formData.email);
          this.$router.push({name: 'LoginInAccountOtp'});
        }).catch(error => {
          this.submitStatus = 'ERROR';
          const { code, message } = error.response.data.errors;

          if (code && code[0] === 'employee_phone_number_not_verified') {
            this.$router.push({ name: 'ConfirmPhone' });
          }

          this.errorMessage = message[0];
          this.$refs.alert.showAlert();
          return false;
        })
      }
    },
  },
  mounted() {
    document.title = 'Login'
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.form-main-title {
  color: #373C44;
  text-align: left;
}

#login_button {
  font-weight: 700;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.login-section {
  max-width: 920px;
  display: flex;
  justify-content: space-between;
}

.holder-img {
  width: 50%;
  margin: 15% 0 0;
}

.holder-form {
  width: 50%;
  align-self: flex-start;
}

h2 {
  text-align: center;
  font-weight: 700;
  color: var(--black-color);
  font-size: 30px;
}

.login-text {
  font-weight: 500;
  font-size: 16px;
  color: var(--secondary-black-color);
  margin-bottom: 40px;
}

.sign-in {
  color: var(--accent-color);
  text-decoration: none;
}

.group {
  position: relative;
  margin-bottom: 20px;
}

.group svg {
  position: absolute;
  top: 54px;
  right: 18px;
}

.group input, .group label, .group button {
  width: 100%;
  display: block;
  border: none;
}

.group label {
  font-weight: 500;
  font-size: 14px;
  color: var(--secondary-text-color);
  margin-bottom: 20px;
}

.group input {
  padding: 15px 20px;
  border: 1px solid var(--secondary-text-color);
  border-radius: 25px;
  background-color: var(--white-color);
}

.group input::placeholder {
  color: var(--input-placeholder-value-color);
}

.group input:focus {
  outline: none;
}

.group-password {
  position: relative;
  margin-bottom: 20px;
}

.group-password .input-password {
  position: absolute;
  top: 54px;
  right: 18px;
}

.group-password .input-text {
  position: absolute;
  top: 52px;
  right: 18px;
}

.group-password input, .group-password label, .group button {
  width: 100%;
  display: block;
  border: none;
}

.group-password label {
  font-weight: 500;
  font-size: 14px;
  color: var(--secondary-black-color);
  margin-bottom: 20px;
}

.group-password input {
  padding: 15px 40px 15px 20px;
  border: 1px solid var(--input-placeholder-value-color);
  border-radius: 25px;
  background-color: var(--white-color);
}

.group-password input::placeholder {
  color: var(--input-placeholder-value-color);
}

.group-password input:focus {
  outline: none;
}

.forgot-pass {
  text-align: right;
  display: block;
  color: var(--accent-color);
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 20px;
  text-decoration: underline;
}

.submit-btn {
  text-align: center;
  background-color: var(--accent-color);
  color: var(--white-color);
  border-radius: 25px;
  padding: 15px 0;
}
</style>
