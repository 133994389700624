<template>
  <div class="public-page-wrapper">
    <Header v-if="!login" />
    <HeaderDefaultRegistered v-else />
    <h1>Accessibility policy</h1>
    <div class="main-section-logo-container">
      <img src="@/assets/img/accessibility-policy.png" alt="policy-img" class="logo">
    </div>
    <div class="container">
      <p class="subtitle">Our Commitment to Accessibility</p>
      <p class="text">
        Pivotal Mobile eDiscovery is committed to making our website's content accessible and user friendly to everyone.
        If you are having difficulty viewing or navigating the content on this website, or notice any content, feature,
        or functionality that you believe is not fully accessible to people with disabilities, please email our team at
        <a :href="'mailto:privacy@pme-discovery.com'" class="text-link"> privacy@pme-discovery.com</a>
        with “Disabled Access” in the subject line and provide a description of the specific feature you feel is not
        fully accessible or a suggestion for improvement.
      </p>
      <br>
      <p class="text">We take your feedback seriously and will consider it as we evaluate ways to accommodate all of our customers and our overall accessibility policies. Additionally, while we do not control such vendors, we strongly encourage vendors of third-party digital content to provide content that is accessible and user friendly.</p>
    </div>
    <Footer />
  </div>
</template>

<script>
import login from "../mixins/login";
import Header from "../components/layout/Header";
import HeaderDefaultRegistered from "../components/layout/HeaderDefaultRegistered";
import Footer from "../components/Footer.vue";

export default {
  name: "AccessibilityPolicy",
  data() {
    return {
      privacyPolicy: null
    }
  },
  mixins: [login],
  created() {
    this.$load(async() => {
      let response = (await this.$api.publicPage.getPageHtml('privacyPolicy'))
      this.privacyPolicy = response.data.body.content
    })
  },
  mounted() {
    document.title = 'Privacy policy'
  },
  components: {
    Footer,
    Header,
    HeaderDefaultRegistered,
  }
}
</script>

<style scoped lang="scss">
.main-section-logo-container {
  text-align: center;
  margin-bottom: 50px;
}

h1 {
  text-align: center;
  color: var(--secondary-black-color);
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 50px;
}

.container {
  max-width: 720px;
  margin: 0 auto 150px;

  .text {
    font-size: 18px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: left;
    color: #636568;
  }
}

.text-link {
  font-size: 18px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;
  color: var(--accent-color);
  text-decoration: underline;
}

.subtitle {
  font-size: 22px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 50px;
}
</style>
