<template>
  <div v-if="activeDialog" class="overlay" :class="activeDialog ? 'active': ''">
    <div class="edit-tags-popup">
      <div class="edit-tags-popup__header">
        <h1 class="filter-title">Edit Tag List</h1>
        <svg @click="activeDialog = false" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.0007 0.333008C5.54898 0.333008 0.333984 5.54801 0.333984 11.9997C0.333984 18.4513 5.54898 23.6663 12.0007 23.6663C18.4523 23.6663 23.6673 18.4513 23.6673 11.9997C23.6673 5.54801 18.4523 0.333008 12.0007 0.333008ZM17.834 16.188L16.189 17.833L12.0007 13.6447L7.81232 17.833L6.16732 16.188L10.3557 11.9997L6.16732 7.81134L7.81232 6.16634L12.0007 10.3547L16.189 6.16634L17.834 7.81134L13.6457 11.9997L17.834 16.188Z"
            fill="#D7DCE6"/>
        </svg>
      </div>
      <div class="add-category-wrapper">
        <div class="add-category" @click="showCategoryInput = !showCategoryInput">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_19972_116898)">
              <path d="M22.4348 0H1.56522C1.1501 0 0.751977 0.164906 0.458441 0.458441C0.164906 0.751977 0 1.1501 0 1.56522V22.4348C0 22.8499 0.164906 23.248 0.458441 23.5416C0.751977 23.8351 1.1501 24 1.56522 24H22.4348C22.8499 24 23.248 23.8351 23.5416 23.5416C23.8351 23.248 24 22.8499 24 22.4348V1.56522C24 1.1501 23.8351 0.751977 23.5416 0.458441C23.248 0.164906 22.8499 0 22.4348 0ZM22.9565 22.4348C22.9565 22.5732 22.9016 22.7059 22.8037 22.8037C22.7059 22.9016 22.5732 22.9565 22.4348 22.9565H1.56522C1.42684 22.9565 1.29414 22.9016 1.19629 22.8037C1.09845 22.7059 1.04348 22.5732 1.04348 22.4348V1.56522C1.04348 1.42684 1.09845 1.29414 1.19629 1.19629C1.29414 1.09845 1.42684 1.04348 1.56522 1.04348H22.4348C22.5732 1.04348 22.7059 1.09845 22.8037 1.19629C22.9016 1.29414 22.9565 1.42684 22.9565 1.56522V22.4348ZM17.7391 12C17.7391 12.1384 17.6842 12.2711 17.5863 12.3689C17.4885 12.4668 17.3558 12.5217 17.2174 12.5217H12.5217V17.2174C12.5217 17.3558 12.4668 17.4885 12.3689 17.5863C12.2711 17.6842 12.1384 17.7391 12 17.7391C11.8616 17.7391 11.7289 17.6842 11.6311 17.5863C11.5332 17.4885 11.4783 17.3558 11.4783 17.2174V12.5217H6.78261C6.64423 12.5217 6.51153 12.4668 6.41368 12.3689C6.31584 12.2711 6.26087 12.1384 6.26087 12C6.26087 11.8616 6.31584 11.7289 6.41368 11.6311C6.51153 11.5332 6.64423 11.4783 6.78261 11.4783H11.4783V6.78261C11.4783 6.64423 11.5332 6.51153 11.6311 6.41368C11.7289 6.31584 11.8616 6.26087 12 6.26087C12.1384 6.26087 12.2711 6.31584 12.3689 6.41368C12.4668 6.51153 12.5217 6.64423 12.5217 6.78261V11.4783H17.2174C17.3558 11.4783 17.4885 11.5332 17.5863 11.6311C17.6842 11.7289 17.7391 11.8616 17.7391 12Z" fill="#373C44"/>
            </g>
            <defs>
              <clipPath id="clip0_19972_116898">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <h2 class="section-header">Add Category</h2>
        </div>
        <form @submit.prevent="addNewCategory" @keydown.enter.prevent="addNewCategory">
          <div class="form-group"
               :class="{ 'form-group--error': $v.newCategoryName.$error }"
          >
            <div v-if="showCategoryInput" class="add-category" :id="'input_new_category'">
              <div class="input-wrapper">
                <svg @click="addNewCategory" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_19972_116898)">
                    <path d="M22.4348 0H1.56522C1.1501 0 0.751977 0.164906 0.458441 0.458441C0.164906 0.751977 0 1.1501 0 1.56522V22.4348C0 22.8499 0.164906 23.248 0.458441 23.5416C0.751977 23.8351 1.1501 24 1.56522 24H22.4348C22.8499 24 23.248 23.8351 23.5416 23.5416C23.8351 23.248 24 22.8499 24 22.4348V1.56522C24 1.1501 23.8351 0.751977 23.5416 0.458441C23.248 0.164906 22.8499 0 22.4348 0ZM22.9565 22.4348C22.9565 22.5732 22.9016 22.7059 22.8037 22.8037C22.7059 22.9016 22.5732 22.9565 22.4348 22.9565H1.56522C1.42684 22.9565 1.29414 22.9016 1.19629 22.8037C1.09845 22.7059 1.04348 22.5732 1.04348 22.4348V1.56522C1.04348 1.42684 1.09845 1.29414 1.19629 1.19629C1.29414 1.09845 1.42684 1.04348 1.56522 1.04348H22.4348C22.5732 1.04348 22.7059 1.09845 22.8037 1.19629C22.9016 1.29414 22.9565 1.42684 22.9565 1.56522V22.4348ZM17.7391 12C17.7391 12.1384 17.6842 12.2711 17.5863 12.3689C17.4885 12.4668 17.3558 12.5217 17.2174 12.5217H12.5217V17.2174C12.5217 17.3558 12.4668 17.4885 12.3689 17.5863C12.2711 17.6842 12.1384 17.7391 12 17.7391C11.8616 17.7391 11.7289 17.6842 11.6311 17.5863C11.5332 17.4885 11.4783 17.3558 11.4783 17.2174V12.5217H6.78261C6.64423 12.5217 6.51153 12.4668 6.41368 12.3689C6.31584 12.2711 6.26087 12.1384 6.26087 12C6.26087 11.8616 6.31584 11.7289 6.41368 11.6311C6.51153 11.5332 6.64423 11.4783 6.78261 11.4783H11.4783V6.78261C11.4783 6.64423 11.5332 6.51153 11.6311 6.41368C11.7289 6.31584 11.8616 6.26087 12 6.26087C12.1384 6.26087 12.2711 6.31584 12.3689 6.41368C12.4668 6.51153 12.5217 6.64423 12.5217 6.78261V11.4783H17.2174C17.3558 11.4783 17.4885 11.5332 17.5863 11.6311C17.6842 11.7289 17.7391 11.8616 17.7391 12Z" fill="#373C44"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_19972_116898">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <CustomInput
                  ref="newCategoryNameInput"
                  id="input_invite_mail"
                  v-model="$v.newCategoryName.$model"
                  placeholder="Enter category name"
                />
                <svg @click="showCategoryInput = false" class="close-icon" width="30" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                  <path d="M10 0.499969C4.47 0.499969 0 4.96997 0 10.5C0 16.03 4.47 20.5 10 20.5C15.53 20.5 20 16.03 20 10.5C20 4.96997 15.53 0.499969 10 0.499969ZM15 14.09L13.59 15.5L10 11.91L6.41 15.5L5 14.09L8.59 10.5L5 6.90997L6.41 5.49997L10 9.08997L13.59 5.49997L15 6.90997L11.41 10.5L15 14.09Z" fill="#D7DCE6"/>-->
                </svg>
              </div>
              <div class="error" v-if="!$v.newCategoryName.required">Field is required</div>
              <div class="error" v-if="!$v.newCategoryName.minLength || !$v.newCategoryName.maxLength">
                First name should have from 1 to 50 alphabetic characters
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="tags-wrapper">
        <CategoryTags v-for="(category, index) in defaultCategories" :key="index" :category="category" isDefault />
        <CategoryTags v-for="(category, index) in localTagsList" :key="index+4" :category="category" />
      </div>
      <div class="dialog-btns-wrap">
        <button class="btn btn-revert" id="dialog_cancel_btn" @click="closePopups()">
          <span class="dialog-btn-text">Cancel</span>
        </button>
        <button class="btn confirm-btn" id="dialog_ok_btn" @click="closePopups()">
          <span class="dialog-btn-text">Save</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomInput from "@/components/inputs/CustomInput.vue";
import CategoryTags from "@/components/сases/case-messages/tags/CategoryTags.vue";
import { maxLength, minLength, required } from "vuelidate/lib/validators";

export default {
  name: "EditTagsPopup",
  components: {
    CategoryTags,
    CustomInput,
  },
  data() {
    return {
      newCategoryName: '',
      showCategoryInput: false,
      activeDialog: false,
      localTagsList: [],
      defaultCategories: [],
    }
  },
  validations: {
    newCategoryName: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(50)
    }
  },
  watch: {
    tagsList: {
      handler(newVal) {
        if (newVal && newVal.length > 3) {
          this.defaultCategories = newVal.slice(0, 4);
          this.localTagsList = newVal.slice(4);
        }
      },
      deep: true,
    }
  },
  computed: {
    ...mapGetters('caseMessages', {
      tagsList: 'getTagsList',
      isLoadingState: 'getLoadingState',
    }),
  },
  methods: {
    ...mapActions('caseMessages', {
      changeCategoryOrder: 'changeCategoryOrder',
      setLoadingState: 'setLoadingState',
      getListTags: "getListTags",
      addNewCategoryRequest: "addNewCategory",
    }),
    openPopups() {
      this.activeDialog = true;
    },
    closePopups() {
      this.activeDialog = false;
    },
    onCategoryDragEnd(event) {
      this.setLoadingState(true)
      this.changeCategoryOrder({
        categoryId: event.item._underlying_vm_.category.id,
        orderIndex: event.newIndex + 1
      })
    },
    addNewCategory() {
      if (this.$refs.newCategoryNameInput.getInput()) {
        this.setLoadingState(true);
        this.addNewCategoryRequest({categoryName: this.$refs.newCategoryNameInput.getInput()});
        this.newCategoryName = '';
        this.showCategoryInput = false;
      }
    },
  },
  mounted() {
    this.getListTags();
  }
}
</script>

<style scoped lang="scss">

.edit-tags-popup {
  width: 100%;
  max-width: 700px;
  height: 800px;
  border: 1px solid #D7DCE6;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 30px 27px 32px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  position: relative;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg path {
      transition: .5s all;
    }

    svg:hover path {
      fill: var(--accent-color);
    }

    svg {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }

  .add-category-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .add-category {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      margin: 30px 0 15px;

      .section-header {
        font-size: 16px;
        font-weight: 700;
        line-height: 19.36px;
        text-align: left;
        color: var(--accent-color);
        margin: 0;
      }

      .input-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .form-group {

      .add-category {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 0 15px;
      }

      .error {
        margin: 0 0 0 35px;
      }
    }
  }

  .filter-title {
    margin: 0;
    font-size: 26px;
    font-weight: 700;
    line-height: 39px;
    text-align: left;
    color: var(--black-color);
  }

  .close-dialog {
    width: 70px;
    height: 70px;
    cursor: pointer;
    position: unset;
    padding: 0;
  }

  .tags-disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  .tags-wrapper {
    overflow-y: scroll;
    scrollbar-color: var(--background-color) transparent;
    scrollbar-width: thin;
    transition: all .5s;
  }

  .dialog-btns-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 30px 0 0;
    align-self: flex-end;
    max-width: 440px;
    width: 100%;

    .btn {
      max-width: 215px;
      width: 100%;
      height: 50px;
      border-radius: 25px;
    }

    .confirm-btn .dialog-btn-text {
      color: var(--white-color);
    }

    .confirm-btn:hover .dialog-btn-text {
      color: var(--accent-color);
    }

    .btn-revert:hover .dialog-btn-text {
      color: var(--white-color);
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}
</style>