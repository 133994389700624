<template>
  <div class="wrapper user-profile-public-page__wrapper">
    <Sidebar />
    <div class="content-wrapper">
      <HeaderRegistered :returnPath="returnPath" />
      <main class="main">
        <section class="profile-section">
          <div class="data-bar">
            <div class="data-bar-name">
              <div class="data-bar-name-img">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="18" cy="18" r="17.5" fill="white"/>
                  <path d="M18 6.5C14.4236 6.5 11.5 9.42356 11.5 13C11.5 16.5764 14.4236 19.5 18 19.5C21.5764 19.5 24.5 16.5764 24.5 13C24.5 9.42356 21.5764 6.5 18 6.5Z" fill="#082655"/>
                  <path d="M30.4466 24.8139C30.2566 24.3355 30.0033 23.889 29.7183 23.4744C28.2616 21.3057 26.0134 19.8705 23.4802 19.5197C23.1635 19.4878 22.8152 19.5516 22.5618 19.7429C21.2319 20.7316 19.6486 21.2419 18.002 21.2419C16.3553 21.2419 14.772 20.7316 13.4421 19.7429C13.1887 19.5516 12.8404 19.4559 12.5238 19.5197C9.9905 19.8705 7.71059 21.3057 6.28565 23.4744C6.00066 23.889 5.74731 24.3674 5.55735 24.8139C5.46238 25.0053 5.49402 25.2285 5.58899 25.4199C5.84234 25.8664 6.15897 26.3129 6.44396 26.6956C6.88726 27.3016 7.36226 27.8437 7.9006 28.354C8.34391 28.8005 8.85055 29.2151 9.35725 29.6297C11.8588 31.5114 14.8671 32.5 17.9703 32.5C21.0736 32.5 24.0818 31.5113 26.5834 29.6297C27.09 29.247 27.5967 28.8005 28.04 28.354C28.5467 27.8437 29.0533 27.3015 29.4967 26.6956C29.8133 26.281 30.0984 25.8664 30.3517 25.4199C30.5099 25.2285 30.5415 25.0052 30.4466 24.8139Z" fill="#082655"/>
                </svg>
              </div>
              <div class="data-bar-name-text">
                <span>{{client.email}}</span>
              </div>
            </div>
            <div class="data-bar-info">
              <span class="data-bar-info-sync">Last sync</span>
              <span class="data-bar-info-sync-data">{{client.lastSync}}</span>
            </div>
          </div>
          <div class="info-table">
            <div class="profile-sessions">
              <h6>Open sessions</h6>
              <ClientSessions />
            </div>
            <div class="profile-cases">
              <h6>Member of case</h6>
              <div class="holder-profile-cases">
                <ClientCaseItem
                    v-for="(caseItem, index) in client.cases"
                    :key="index"
                    :caseItem="caseItem"
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import Sidebar from "../components/Sidebar";
import HeaderRegistered from "../components/layout/HeaderRegistered";
import ClientSessions from "../components/client-profile/ClientSessions";
import ClientCaseItem from "../components/client-profile/ClientCaseItem"
export default {
  name: "ClientProfile",
  components: {ClientSessions, Sidebar, HeaderRegistered, ClientCaseItem},
  data() {
    return {
      clientId: this.$route.params.slug,
      client: {},
      returnPath: true,
    }
  },
  methods: {
    getClient() {
      this.$load(async () => {
        await this.$api.profile.getProfileClient({clientId: this.clientId}).then((res) => {
              this.client = res.data.body
            }, (error => {
              if (error.response) {
                this.errorMessage = error.response.data.errors.message[0]
              } else {
                this.errorMessage = error.data.errors.message[0]
              }
            })
        )
      })
    },
  },
  mounted() {
    document.title = 'User Profile'
    this.getClient()
  }
}
</script>

<style scoped>
.profile-section {
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  padding: 27px 55px 27px 35px;
}

.data-bar {
  display: flex;
  justify-content: space-between;
  padding: 26px 30px;
  background: #F8FCFC;
  border-radius: 10px;
  margin-bottom: 50px;
}

.data-bar-name, .data-bar-info {
  display: flex;
  align-items: center;
}

.data-bar-name-img {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: initial;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 30px;
}

.data-bar-name-img img {
  object-fit: cover;
}

.data-bar-name-text span {
  font-weight: 500;
  font-size: 14px;
  color: var(--secondary-black-color);
}

.data-bar-info-sync {
  font-weight: 400;
  font-size: 10px;
  text-transform: uppercase;
  color: var(--secondary-black-color);
  margin-right: 20px;
}

.data-bar-info-sync-data {
  font-weight: 500;
  font-size: 12px;
  color: var(--secondary-black-color);
  padding: 8px 10px;
  background-color: var(--white-color);
  border-radius: 20px;
}

.profile-sessions {
  display: flex;
  max-width: 281px;
  min-width: 236px;
  flex-direction: column;
  margin-right: 80px;
}

h6 {
  margin-bottom: 23px;
}

.info-table {
  display: flex;
}

.profile-cases {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.holder-profile-cases {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
</style>