import { render, staticRenderFns } from "./ContactsList.vue?vue&type=template&id=512bef29&scoped=true"
import script from "./ContactsList.vue?vue&type=script&lang=js"
export * from "./ContactsList.vue?vue&type=script&lang=js"
import style0 from "./ContactsList.vue?vue&type=style&index=0&id=512bef29&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "512bef29",
  null
  
)

export default component.exports