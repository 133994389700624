<template>
  <iframe
      :src="iframe.src"
      frameborder="0"
      @load="load"
      v-show="iframe.loaded"
      ref="payment"
      height="1200"
  ></iframe>
</template>

<script>
export default {
name: "RegPack",
  data() {
    return {
      loadFlag: false,
      iframe: {
        src: '../payment.html',
        loaded: false
      }
    }
  },
  methods: {
    load: function(){
      this.iframe.loaded = true;
      setTimeout(() => {
        this.$refs.payment.style.height = this.$refs.payment.contentWindow.document.documentElement.scrollHeight + 'px';
      }, 1500)
    }
  },
  mounted() {
    document.title = 'Billing account'
  }
}
</script>

<style scoped>

</style>