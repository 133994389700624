<template>
  <div v-if="message" class="reminder-info">
    <div class="reminder-header">
      <div class="reminder-user">
        <div class="img-item">
          <img v-if="message.avatar"
               :src="message.avatar" alt="avatar">
          <img
            v-else
            src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png"
            alt="">
        </div>
        <span>{{ message.senderName }}</span>
        <div class="span-chat">
          <div class="chat-icon">
            <ImgOfMessengers :nameOfMessanger="message.messenger.toLowerCase()" />
          </div>
        </div>
      </div>
      <span v-if="message.reminder" class="date">{{ 'Remind in ' + initializeDate }}</span>
    </div>
    <div class="footer-wrapper">
      <div class="message-text">
        <span v-if="!!message.isBlured" class="blurred"></span>
        <span :style="{background: highlightColor}" class="background"></span>
        <p
          v-html="reminderToShow(message.message)"
          :class="{ underline: !!highlightColor, 'nocopy': !!message.isBlured }"
          :data-color="highlightColor"
          :style="{textDecorationColor: highlightColor}"
        ></p>
      </div>
      <ContextMenu v-if="message.reminder && initializeDate !== 0" :item="message" :options="commentOptions" @onSelected="onReminderMenuSelection"/>
      <div v-else class="mark-complete" @click="markReminderComplete">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.3991 7.14714L9.39909 19.1471C9.29362 19.2525 9.15066 19.3116 9.00159 19.3116C8.85253 19.3116 8.70956 19.2525 8.60409 19.1471L3.35409 13.8971C3.25473 13.7905 3.20064 13.6495 3.20321 13.5037C3.20578 13.358 3.26482 13.219 3.36788 13.1159C3.47094 13.0129 3.60998 12.9538 3.7557 12.9513C3.90143 12.9487 4.04246 13.0028 4.14909 13.1021L9.00159 17.9537L20.6041 6.35214C20.7107 6.25278 20.8518 6.19869 20.9975 6.20126C21.1432 6.20383 21.2823 6.26287 21.3853 6.36592C21.4884 6.46898 21.5474 6.60802 21.55 6.75375C21.5525 6.89948 21.4985 7.04051 21.3991 7.14714Z" fill="#696F79"/>
        </svg>
        Mark complete
      </div>
      <InfoDialog
        ref="deleteDialog"
        :title="infoDialogData.dialogTitle"
        :text="infoDialogData.dialogText"
        :confirmBtnText="infoDialogData.confirmBtnText"
        :cancelBtnText="infoDialogData.cancelBtnText"
        @onConfirm="removeReminder"
      />
      <ReminderPopup v-if="message && message.reminder" ref="reminderPopup" :reminderId="message.reminder.id"/>
    </div>
  </div>
</template>
<script>
import ContextMenu from "@/components/popups/ContextMenu.vue";
import InfoDialog from "@/components/popups/InfoDialog.vue";
import editIcon from "@/assets/img/case-messages/edit.svg";
import deleteIcon from "@/assets/img/case-messages/delete.svg";
import { createDeleteDialog } from "@/constants/infoDialogData";
import { mapActions } from "vuex";
import dayjs from "dayjs";
import ReminderPopup from "@/components/popups/ReminderPopup.vue";
import ImgOfMessengers from "@/components/сases/case-messages/ImgOfMessangers.vue";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

const commentOptions = [
  {
    name: 'Edit Reminder',
    img: editIcon,
  },
  {
    name: 'Delete from Reminders',
    img: deleteIcon
  },
];

export default {
  name: "ReminderMessage",
  components: {
    ImgOfMessengers,
    ReminderPopup,
    ContextMenu,
    InfoDialog,
  },
  emits: ['deleted'],
  props: {
    message: {
      required: true
    },
    commentSearch: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      commentOptions,
      newComment: '',
      infoDialogData: {},
      editingComment: false,
    }
  },
  computed: {
    highlightColor() {
      return this.message.highlight ? this.message.highlight : null;
    },
    initializeDate() {
      const now = dayjs();
      const target = dayjs.utc(this.message.reminder.remindIn).local();
      if (target.isBefore(now)) {
        return 0;
      }
      return target.from(now, true);
    },
  },
  methods: {
    ...mapActions('caseMessages', {
      deleteReminder: "deleteReminder",
      markAsCompleteReminder: "markAsCompleteReminder",
    }),
    markReminderComplete() {
      this.markAsCompleteReminder(this.message.reminder.id)
    },
    onReminderMenuSelection(optionName, reminder) {
      switch (optionName) {
        case 'Edit Reminder':
          this.editReminder(reminder);
          break;
        case 'Delete from Reminders':
          this.showDeletePopup(reminder);
          break;
        default: this.errorMessage = 'No such option';
      }
    },
    editReminder() {
      this.$refs.reminderPopup.openPopups();
    },
    showDeletePopup(reminder) {
      this.infoDialogData = createDeleteDialog('reminder');
      this.$refs.deleteDialog.openPopup(reminder);
    },
    removeReminder() {
      this.deleteReminder(this.message.reminder.id);
      this.$emit('deleted');
    },
    reminderToShow(commentText) {
      if (!this.commentSearch) {
        return commentText;
      }

      const escapedMessage = this.escapeHtml(commentText);
      const escapedSearchText = this.escapeRegExp(this.commentSearch);
      const regex = new RegExp(`(${escapedSearchText})`, 'gi');

      return escapedMessage.replace(
        regex,
        '<span style="background-color: yellow;">$1</span>'
      );
    },
    escapeHtml(text) {
      return text.replace(/[&<>"']/g, (match) => {
        const escapeMap = {
          '&': '&amp;',
          '<': '&lt;',
          '>': '&gt;',
          '"': '&quot;',
          "'": '&#039;',
        };
        return escapeMap[match];
      });
    },
    escapeRegExp(text) {
      return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    },
  },
}
</script>

<style scoped lang="scss">

.reminder-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .reminder-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .reminder-user {
      display: flex;
      align-items: center;
      gap: 10px;

      .img-item {
        max-width: 32px;
        max-height: 32px;
        overflow: hidden;
        border: 1px solid var(--white-color);
        border-radius: 50%;
      }

      .img-item img {
        width: 100%;
        height: 100%;
      }

      .span-chat {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: 6px;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
      }

      .chat-icon {
        width: 16px;
        height: 16px;
      }
    }

    .date {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      text-align: right;
      color: var(--light-gray);
    }
  }

  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 15px;

    .message-text {
      position: relative;
      width: 100%;

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 19.2px;
        text-align: left;
        color: var(--secondary-text-color);
        margin: 0;
      }
    }

    .mark-complete {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 12px;
      font-weight: 500;
      line-height: 19px;
      text-align: left;
      color: var(--secondary-text-color);
      cursor: pointer;
    }
  }
}

svg {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
  text-underline-offset: 2px;
}

.blurred {
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--black-color);
  -webkit-user-select: none !important;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

</style>