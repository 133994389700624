<template>
  <div class="holder-table">
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
    <div class="holder-search">
      <CustomSearch
          id="notification_search_permission"
          :modelValue="searchText"
          @update:modelValue="searchText = $event"
      />
    </div>
    <TableHeader>
      <table cellpadding="0" cellspacing="0" border="0">
        <thead>
        <tr>
          <th>
            <div
                class="table-header-box"
                @click="sortedBy('userName')"
                :class="{
                  'sort-active': this.sortBy === 'userName',
                  'sorted-desc': this.order === 'desc'
                }"
            >
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 7.5C9.70938 7.5 11.5 5.82129 11.5 3.75C11.5 1.67871 9.70938 0 7.5 0C5.29063 0 3.5 1.67871 3.5 3.75C3.5 5.82129 5.29063 7.5 7.5 7.5ZM10.3 8.4375H9.77812C9.08437 8.73633 8.3125 8.90625 7.5 8.90625C6.6875 8.90625 5.91875 8.73633 5.22188 8.4375H4.7C2.38125 8.4375 0.5 10.2012 0.5 12.375V13.5938C0.5 14.3701 1.17188 15 2 15H13C13.8281 15 14.5 14.3701 14.5 13.5938V12.375C14.5 10.2012 12.6187 8.4375 10.3 8.4375Z" fill="#D7DCE6"/>
              </svg>
              <span class="header-table-text">User Name</span>
              <arrowDown class="arrow" />
            </div>
          </th>
          <th>
            <div
                class="table-header-box"
            >
              <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.48024 0H0.520239C0.245378 0 0.0224609 0.222917 0.0224609 0.497778V15.5022C0.0224609 15.7771 0.245378 16 0.520239 16H9.48024C9.7551 16 9.97802 15.7771 9.97802 15.5022V0.497778C9.97802 0.222917 9.7551 0 9.48024 0ZM3.16552 0.462222H6.83496C7.18774 0.462222 7.47496 0.724583 7.47496 1.04889C7.47496 1.37319 7.18774 1.63556 6.83496 1.63556H3.16552C2.81274 1.63556 2.52552 1.37319 2.52552 1.04889C2.52552 0.724583 2.81274 0.462222 3.16552 0.462222ZM1.58691 2.80889C1.58691 2.6125 1.74607 2.45333 1.94246 2.45333H8.02246C8.21885 2.45333 8.37802 2.6125 8.37802 2.80889V9.49333C8.37802 9.68972 8.21885 9.84889 8.02246 9.84889H1.94246C1.74607 9.84889 1.58691 9.68972 1.58691 9.49333V2.80889ZM1.90691 11.52C1.78913 11.52 1.69357 11.4244 1.69357 11.3067C1.69357 11.1889 1.78913 11.0933 1.90691 11.0933H8.05802C8.1758 11.0933 8.27135 11.1889 8.27135 11.3067C8.27135 11.4244 8.1758 11.52 8.05802 11.52H1.90691ZM7.73802 12.7644C7.73802 12.8822 7.64246 12.9778 7.52468 12.9778H2.44024C2.32246 12.9778 2.22691 12.8822 2.22691 12.7644C2.22691 12.6467 2.32246 12.5511 2.44024 12.5511H7.52468C7.64246 12.5511 7.73802 12.6467 7.73802 12.7644ZM8.59135 14.4H1.37357C1.25579 14.4 1.16024 14.3044 1.16024 14.1867C1.16024 14.0689 1.25579 13.9733 1.37357 13.9733H8.59135C8.70913 13.9733 8.80468 14.0689 8.80468 14.1867C8.80468 14.3044 8.70913 14.4 8.59135 14.4Z"
                    fill="#D7DCE6"/>
              </svg>
              <span class="header-table-text">Phone number</span>
            </div>
          </th>
          <th>
            <div
                class="table-header-box"
                @click="sortedBy('registerDate')"
                :class="{
                  'sort-active': this.sortBy === 'registerDate',
                  'sorted-desc': this.order === 'desc'
                }"
            >
              <CaseIcon />
              <span class="header-table-text">Registration Date</span>
              <arrowDown class="arrow" />
            </div>
          </th>
          <th></th>
        </tr>
        </thead>
      </table>
    </TableHeader>
    <CaseAssignItem
      :loadFlag="loadFlag"
      :CaseAssignUserList="caseAssignUserList"
      @getCasesUnemployed="getCasesUnemployed"
    />
    <p v-if="!caseAssignUserList.length && !loadFlag" class="no-found">No users to assign</p>
    <pagination v-if="caseAssignUserList.length && count !== 1"
                v-model="page"
                :total-pages="count"
                :link-gen="linkGen"
    ></pagination>
  </div>
</template>

<script>
import linkGenerator from "../../../constants/linkGen";
import AlertErrorMsg from "../../profile/AlertErrorMsg";
import TableHeader from "../../table/TableHeader";
import CustomSearch from "../../inputs/CustomSearch";
import CaseAssignItem from "./CaseAssignItem";
import ArrowDown from "../../icons/ArrowDown";
import CaseIcon from "../../icons/CaseIcon";
import Pagination from "@/components/common/Pagination.vue";

export default {
  name: "CaseAssignList",
  components: {
    Pagination,
    CaseIcon,
    ArrowDown,
    AlertErrorMsg,
    CaseAssignItem,
    TableHeader,
    CustomSearch
  },
  data() {
    return {
      page: 1,
      size: 10,
      count: 0,
      pageSize: 2,
      sortBy: 'userName',
      order: 'desc',
      searchText: '',
      caseAssignUserList: [],
      loadFlag: false,
      errorMessage: '',
    }
  },
  watch: {
    searchText() {
      this.getCasesUnemployed()
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if(newVal) {
          this.page = newVal
          this.getCasesUnemployed()
        } else {
          this.page = 1
        }
      }
    }
  },
  methods: {
    linkGen(pageNum) {
      return linkGenerator(pageNum, `/cases/${this.$route.params.slug}/assign`)
    },
    sortedBy(filterName) {
      this.order = filterName === this.sortBy && this.order === 'desc' ? 'asc' : 'desc'
      this.sortBy = filterName
      this.getCasesUnemployed()
    },
    fixedEncodeURIComponent (str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    },
    getCasesUnemployed() {
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.cases.getCasesUnemployed({
          sortBy: this.sortBy,
          caseId: this.$route.params.slug,
          keySearch: this.fixedEncodeURIComponent(this.searchText),
          order: this.order,
          page: this.page,
          size: this.size,
        }).then((res) => {
          this.caseAssignUserList = res.data.body.content
          this.count = res.data.body.totalPages
          this.pageSize = res.data.body.size
        }, (error) => {
          if(error) {
            if(error.response) {
              this.errorMessage = error.response.data.errors.message[0]
            }else {
              this.errorMessage = error.data.errors.message[0]
            }
            this.$refs.alert.showAlert()
          }
        })
        setTimeout(() => {
          this.loadFlag = false
        }, 1000)
      })
    },
  },
  mounted() {
    this.page = this.$route.query.page || 1
    this.$store.dispatch('profile/getPermissionPerCase', {caseId: this.$route.params.slug})
    this.getCasesUnemployed()
  }
}
</script>

<style scoped>
.holder-search {
  max-width: 300px;
  margin-bottom: 26px;
}

.holder-table {
  position: relative;
}

</style>
