<template>
  <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M5.99059 5.99797C5.79067 5.99836 5.59692 5.92865 5.44298 5.80093L0.309104 1.51743C0.134367 1.37202 0.0244811 1.16306 0.00362066 0.936524C-0.0172397 0.70999 0.0526339 0.484437 0.19787 0.309485C0.343106 0.134532 0.551808 0.0245113 0.778063 0.00362514C1.00432 -0.017261 1.22959 0.0526984 1.40433 0.198113L5.99059 4.03613L10.5768 0.335186C10.6644 0.264023 10.7651 0.210881 10.8732 0.178813C10.9813 0.146745 11.0946 0.136385 11.2068 0.148326C11.3189 0.160268 11.4275 0.194276 11.5265 0.248397C11.6254 0.302518 11.7127 0.375685 11.7833 0.463691C11.8617 0.551778 11.921 0.655119 11.9576 0.767238C11.9942 0.879357 12.0073 0.99784 11.9961 1.11526C11.9848 1.23268 11.9495 1.34652 11.8923 1.44963C11.835 1.55273 11.7572 1.6429 11.6635 1.71447L6.52964 5.85233C6.37128 5.95986 6.18148 6.01114 5.99059 5.99797Z"
        fill="#8692A6"/>
  </svg>
</template>

<script>
export default {
  name: "ArrowDown"
}
</script>

<style scoped>

</style>