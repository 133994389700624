<template>
  <div>
    <Header/>
    <section class="section confirm-section">
      <h2 class="confirm-title">You need to confirm your e-mail address</h2>
      <div class="holder-img">
        <img :src="signUpConfirm" alt="confirm-img">
      </div>
      <div class="confirm-text">
        <h4>We send you an email to confirm your email address</h4>
      </div>
    </section>
  </div>
</template>

<script>
import Header from "../components/layout/Header";
import signUpConfirm from "@/assets/img/sign-up-confirm.svg"

export default {
  name: "ConfirmMail",
  components: {
    Header,
  },
  data() {
    return {
      signUpConfirm
    }
  },
  mounted() {
    document.title = 'Confirm email'
  }
}
</script>

<style scoped>
  img {
    max-width: 100%;
    max-height: 100%;
  }
  .confirm-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  h2 {
    text-align: center;
    font-weight: 700;
    color: var(--black-color);
    font-size: 30px;
  }
  .holder-img {
    width: 300px;
    height: 300px;
  }
  .confirm-text {
    max-width: 494px;
    text-align: center;
    color: var(--accent-color);
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }

  .confirm-title {
    color: #373C44;
  }
</style>
