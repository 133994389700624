<template>
  <div id="head-button" style="position:relative;display:inline-block;"
       v-click-outside="closeNotes">

    <a class="button-default show-notifications js-show-notifications" @click="toggleNotifications">
      <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.5716 16.7084C17.1226 15.4836 16.2917 13.6934 16.2917 11.7969V9.12504C16.2917 5.75265 13.7866 2.96101 10.5417 2.49334V1.45834C10.5417 0.928377 10.1124 0.5 9.58338 0.5C9.05437 0.5 8.62504 0.928377 8.62504 1.45834V2.49334C5.37915 2.96101 2.87501 5.75265 2.87501 9.12504V11.7969C2.87501 13.6934 2.04413 15.4836 0.586503 16.716C0.213709 17.0352 0 17.499 0 17.9897C0 18.9145 0.752295 19.6668 1.67709 19.6668H17.4897C18.4145 19.6668 19.1668 18.9145 19.1668 17.9897C19.1668 17.499 18.953 17.0352 18.5716 16.7084Z"
            fill="#8692A6"/>
        <path
            d="M9.58343 23.5C11.319 23.5 12.7709 22.2628 13.1044 20.625H6.0625C6.396 22.2628 7.84788 23.5 9.58343 23.5Z"
            fill="#8692A6"/>
      </svg>
      <div class="notification-circle" :class="notifications.length ? 'notification-new' : 'notification-empty'">
        <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="4" cy="4.5" r="4"/>
        </svg>
      </div>
    </a>
    <div class="notifications js-notifications"
         :style="{ display: displayState, top: topState + 'px', opacity: opacityState }">
      <span class="notifications-main-title">Notifications</span>
        <ul class="notifications-list">
          <HeaderNotificationsList
              v-for="(notification, index) in notifications"
              :key="index"
              :notification="notification"
              @changeStatus="changeStatus"
          />
          <li class="item no-data" v-if="classBell === ''">You don't have
            notifications
          </li>
        </ul>
        <!--        <div class="notifications-list-empty" v-else>-->
        <!--          <span class="item no-data" :style="{display: displayState}">You don't have notifications</span>-->
        <!--        </div>-->
      <div class="notifications-show-all-wrap">
        <router-link :to="{path: '/notifications/files'}" class="notifications-show-all">See All</router-link>
      </div>
    </div>

  </div>
</template>

<script>
import settings from "../../mixins/scrollSettings";
import HeaderNotificationsList from "./HeaderNotificationsList";

export default {
  name: "HeaderNotificationsTemplate",
  components: {
    HeaderNotificationsList,
  },
  mixins: [settings],
  data() {
    return {
      opacityState: null,
      displayState: null,
      topState: null,
      notificationFlag: false,
      classBell: '',
    }
  },
  props: {
    notifications: {
      type: Array,
    },
  },
  watch: {
    'notifications'(newVal) {
      if (newVal.length > 0) {
        this.classBell = 'visible'
      } else {
        this.classBell = ''
      }
    }
  },
  methods: {
    closeNotes() {
      this.notificationFlag = false
      this.opacityState = '0'
      this.displayState = 'none'
      this.topState = '-999'
    },
    toggleNotifications() {
      this.notificationFlag = !this.notificationFlag
      if (this.notificationFlag) {
        this.opacityState = '1'
        this.displayState = 'block'
        this.topState = '30'
      } else {
        this.opacityState = '0'
        this.displayState = 'none'
        this.topState = '-999'
      }
    },
    changeStatus() {
      this.$emit('changedStatus')
    },
  },
}
</script>

<style scoped>
.notification-circle {
  position: absolute;
  top: -6px;
  right: 2px;
}

.notification-circle svg circle {
  fill: var(--accent-color);
  transition: all .2s;
}

.notification-circle.notification-empty svg circle {
  fill: transparent;
  transition: all .2s;
}

.nav-item svg path {
  fill: #8692A6;
}

.nav-item svg path {
  transition: all .2s;
}

.nav-item:hover svg path {
  fill: #BDC4D0;
}

</style>
