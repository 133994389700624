<template>
  <div class="faq-item-list">
    <FaqAccordeon v-for="item in data" :title="item.name" :poblemsVariants="item.questions" :key="item.id"/>
  </div>
</template>

<script>
import FaqAccordeon from "./FaqAccordeon";

export default {
  name: "FaqItemList",
  props: {
    data: Array,
  },
  data() {
    return {
      faqItems: [
        {
          title: 'Fix a problem',
          id: Math.random(),
          poblemsVariants: [
            {
              title: 'Troubleshoot problems searching objects',
            },
            {
              title: 'Fix upload problems',
            },
            {
              title: 'Fix Account Premium membership issues',
            },
            {
              title: 'Get help with the Company Partner Programme',
            },
          ]
        },
        {
          title: 'Object settings',
          id: Math.random(),
          poblemsVariants: [
            {
              title: 'Troubleshoot problems searching objects',
            },
            {
              title: 'Fix upload problems',
            },
            {
              title: 'Fix Account Premium membership issues',
            },
            {
              title: 'Get help with the Company Partner Programme',
            },
          ]
        },
        {
          title: 'Manage your account and settings',
          id: Math.random(),
          poblemsVariants: [
            {
              title: 'Troubleshoot problems searching objects',
            },
            {
              title: 'Fix upload problems',
            },
            {
              title: 'Fix Account Premium membership issues',
            },
            {
              title: 'Get help with the Company Partner Programme',
            },
          ]
        },
        {
          title: 'Join and manage Premium Account',
          id: Math.random(),
          poblemsVariants: [
            {
              title: 'Troubleshoot problems searching objects',
            },
            {
              title: 'Fix upload problems',
            },
            {
              title: 'Fix Account Premium membership issues',
            },
            {
              title: 'Get help with the Company Partner Programme',
            },
          ]
        },
        {
          title: 'Register as Developer',
          id: Math.random(),
          poblemsVariants: [
            {
              title: 'Troubleshoot problems searching objects',
            },
            {
              title: 'Fix upload problems',
            },
            {
              title: 'Fix Account Premium membership issues',
            },
            {
              title: 'Get help with the Company Partner Programme',
            },
          ]
        },
        {
          title: 'Policy and safety',
          id: Math.random(),
          poblemsVariants: [
            {
              title: 'Troubleshoot problems searching objects',
            },
            {
              title: 'Fix upload problems',
            },
            {
              title: 'Fix Account Premium membership issues',
            },
            {
              title: 'Get help with the Company Partner Programme',
            },
          ]
        }
      ]
    }
  },
  components: {
    FaqAccordeon
  }
}
</script>

<style scoped>
.faq-item-list {
  width: 100%;
  margin: 40px 0 0 0;
}
</style>
