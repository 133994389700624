let settings = {
    data() {
        return {
            settings: {
                suppressScrollY: false,
                suppressScrollX: false,
                wheelPropagation: true,
                minScrollbarLength: 700,
            },
        }
    }
}

export default settings