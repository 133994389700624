<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M11.9999 0.333328C5.54825 0.333328 0.333252 5.54833 0.333252 12C0.333252 18.4517 5.54825 23.6667 11.9999 23.6667C18.4516 23.6667 23.6666 18.4517 23.6666 12C23.6666 5.54833 18.4516 0.333328 11.9999 0.333328ZM17.8333 16.1883L16.1883 17.8333L11.9999 13.645L7.81159 17.8333L6.16659 16.1883L10.3549 12L6.16659 7.81166L7.81159 6.16666L11.9999 10.355L16.1883 6.16666L17.8333 7.81166L13.6449 12L17.8333 16.1883Z"/>
  </svg>
</template>

<script>
export default {
  name: "CloseIcon"
}
</script>

<style scoped>

</style>