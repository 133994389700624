let reformatDateToTime = {
  methods: {
    reformatTime(datetimeString) {
      const datetime = new Date(datetimeString);
      let hours = datetime.getHours();
      const minutes = datetime.getMinutes();
      const period = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12;
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      return `${formattedHours}:${formattedMinutes} ${period}`;
    },
  },
}

export default reformatDateToTime