import { render, staticRenderFns } from "./CaseMessages.vue?vue&type=template&id=5478260e"
import script from "./CaseMessages.vue?vue&type=script&lang=js"
export * from "./CaseMessages.vue?vue&type=script&lang=js"
import style0 from "./CaseMessages.vue?vue&type=style&index=0&id=5478260e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports