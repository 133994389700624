import Router from 'vue-router';
import Home from '../pages/Home.vue'
import CookiePolicy from "../pages/CookiePolicy";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsOfConditions from "../pages/TermsOfConditions";
import Support from "../pages/Support";
import Faq from "../pages/Faq";
import Register from "../pages/Register";
import RegisterInvite from "../pages/RegisterInvite";
import PhoneVerify from "../components/register/PhoneVerify";
import PhoneVerifyInvite from "../components/register-invite/PhoneVerifyInvite";
import FirmInfoStep from "../components/register/FirmInfoStep";
import Billing from "../components/register/Billing";
import ProfilePhotoStep from "../components/register/ProfilePhotoStep";
import ProfilePhotoStepInvite from "../components/register-invite/ProfilePhotoStepInvite";
import ConfirmMail from "../pages/ConfirmMail";
import ConfirmPhone from '@/pages/ConfirmPhone';
import LoginInAccount from "../pages/LoginInAccount";
import LoginInAccountOtp from "../pages/LoginInAccountOtp";
import ResetPassword from "../pages/Reset-Password/ResetPasswordMessage";
import ResetPasswordForm from "../pages/Reset-Password/ResetPasswordForm";
import CreateNewPassword from "../pages/Reset-Password/CreateNewPassword";
import RegisterAccount from "../pages/RegisterAccount";
import RegisterAccountInvite from "../pages/RegisterAccountInvite";
import Profile from "../pages/Profile";
import Download from "../pages/Download";
import EditProfileTab from "../components/profile/EditProfileTab";
import FirmInfoTab from "../components/profile/FirmInfoTab";
import PasswordSecurityTab from "../components/profile/PasswordSecurityTab";
import Dashboard from "../pages/Dashboard";
import Contacts from "../pages/Contacts";
import InviteContact from "../pages/InviteContact";
import Cases from "../pages/Cases";
import ConfigureDevice from "../pages/ConfigureDevice";
import CreateCase from '../pages/CreateCase';
import Permissions from "../pages/Permissions";
import ProfileBilling from "../pages/Billing";
import BillingHistory from "../components/billing/BillingHistory";
import RegPack from "../components/billing/RegPack";
import Notifications from "../pages/Notifications";
import NotificationsFiles from "../components/notifications/NotificationsFiles";
import NotificationsCase from "../components/notifications/NotificationsCase";
import NotificationsClients from "../components/notifications/NotificationsClients";
import NotificationsTeam from "../components/notifications/NotificationsTeam";
import NotificationsPermission from "../components/notifications/NotificationsPermission";
import NotificationsBilling from "../components/notifications/NotificationsBilling";
import SoftwareSettings from "../pages/SoftwareSettings";
import ErrorPageNotFound from "../pages/ErrorPageNotFound";
import PermissionsRole from "../components/profile/PermissionsRole";
import PermissionsIndividual from "../components/profile/PermissionsIndividual";
import PermissionsCases from "../components/profile/PermissionsCases";
import PermissionsCaseItem from "../components/profile/PermissionsCaseItem";
import PrintInvoice from "../pages/PrintInvoice";
import Email from "../pages/Email";
import Approved from "../components/invite-new/Approved";
import InviteNew from "../components/invite-new/InviteNew";
import ProcessingRequests from "../components/invite-new/ProcessingRequests";
import CaseDetails from "../components/сases/case/CaseDetails";
import CaseEmployeesTable from "../components/сases/case-employees/CaseEmployeesTable";
import CaseClientsTable from "../components/сases/case-clients/CaseClientsTable";
import CaseMessages from "../components/сases/case-messages/CaseMessages";
import CaseFilesTable from "../components/сases/case-files/CaseFilesTable";
import AssignCaseManager from "../components/сases/case-assign-manager/AssignCaseManager";
import CaseActions from "../components/сases/case-actions/CaseActions";
import CaseActionsPermission from "../components/сases/case-actions/CaseActionsPermission";
import CaseActionsTeam from "../components/сases/case-actions/CaseActionsTeam";
import CaseActionsClients from "../components/сases/case-actions/CaseActionsClients";
import CaseActionsFiles from "../components/сases/case-actions/CaseActionsFiles";
import CaseAssign from "../components/сases/case-assign/CaseAssign";
import ContactsEmployees from "../components/contacts/ContactsEmployees";
import ContactsClients from "../components/contacts/ContactsClients";
import ClientProfile from "../pages/ClientProfile";
import UserProfile from "../pages/UserProfile";
import AppVersions from "../pages/AppVersions";
import CaseInviteClient from "../components/сases/case-assign/CaseInviteClient";
import CaseExport from "../components/сases/case-export/CaseExport";
import PhoneConfirmInvite from '@/components/register/PhoneConfirmInvite';
import CaseEmails from "../components/сases/case-email/CaseEmail";
import CaseEmailList from "../components/сases/case-email/CaseEmailList";
import CaseMessagesMobile from "@/components/сases/case-messages/CaseMessagesMobile.vue";
import CaseMessagesEmails from "@/components/сases/case-messages/CaseMessagesEmails.vue";
import CaseMessagesMedia from "@/components/сases/case-messages/media/CaseMessagesMedia.vue";
import CaseMessagesApps from "@/components/сases/case-messages/CaseMessagesApps.vue";
import CaseEmailFolder from "@/components/сases/case-email/case-email-folders/CaseEmailFolder.vue";
import CaseEmailFolderList from "@/components/сases/case-email/case-email-folders/CaseEmailFolderList.vue";
import CaseEmailSpecificMessage from "@/components/сases/case-email/case-email-folders/CaseEmailSpecificMessage.vue";
import ClientSupport from "@/pages/ClientSupport.vue";
import CustodianSessionsList from "@/components/support/CustodianSessionsList.vue";
import CaseSettings from "@/components/сases/case-settings/CaseSettings.vue";
import CaseSettingsGeneral from "@/components/сases/case-settings/CaseSettingsGeneral.vue";
import CallLogs from "@/components/сases/case-messages/CaseMessagesCallLogs.vue";
import CaseMessagesContacts from "@/components/сases/case-messages/CaseMessagesContacts.vue";
import TwoFASecurityTab from "@/components/profile/TwoFASecurityTab.vue";
import ManagementPage from "@/pages/ManagementPage.vue";
import ManageKeysList from "@/components/management/ManageKeysList.vue";
import PhoneChangeTab from "@/components/profile/PhoneChangeTab.vue";
import EmailChangeTab from "@/components/profile/EmailChangeTab.vue";
import PmeSupport from "@/pages/PmeSupport.vue";
import SupportTopic from "@/components/support/SupportTopic.vue";
import AccessibilityPolicy from "@/pages/AccessibilityPolicy.vue";
import CaseMessagesMobileComments from "@/components/сases/case-messages/tags/CaseMessagesMobileComments.vue";
import CaseMessagesMobileEdit from "@/components/сases/case-messages/tags/CaseMessagesMobileEdit.vue";
import CaseMessagesTags from "@/components/сases/case-messages/tags/CaseMessagesTags.vue";
import CaseMessagesPinned from "@/components/сases/case-messages/tags/CaseMessagesPinned.vue";
import CaseMessagesHighlight from "@/components/сases/case-messages/tags/CaseMessagesHighlight.vue";
import CaseMessagesMobileReminders from "@/components/сases/case-messages/tags/CaseMessagesMobileReminders.vue";
import CaseSettingsCustodians from "@/components/сases/case-settings/CaseSettingsCustodians.vue";
import ComingSoon from "@/pages/ComingSoon.vue";
import PressRelease from "@/pages/PressRelease.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        children: [
            {
                path: "press-release",
                name: "PressRelease",
                component: PressRelease,
            },
        ]
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
        children: [
            {
                path: 'phone-verify/*',
                name: 'PhoneVerify',
                component: PhoneVerify,
                meta: {
                    authorization: true
                },
            },
            {
                path: 'firm-information',
                name: 'FirmInfoStep',
                component: FirmInfoStep,
                meta: {
                    authorization: true
                },
                beforeEnter: (to, from, next) => {
                    let arr = JSON.parse(localStorage.getItem('step'))
                    if (arr !== null) {
                        if (to.name !== 'LoginInAccount' && arr.includes(2)) next()
                        else next({name: 'LoginInAccount'})
                    } else next({name: 'LoginInAccount'})
                }
            }, {
                path: 'billing',
                name: 'Billing',
                component: Billing,
                meta: {
                    authorization: true
                },
            },
            {
                path: 'user-photo',
                name: 'ProfilePhotoStep',
                component: ProfilePhotoStep,
                meta: {
                    authorization: true
                },
            },
        ]
    },
    {
        path: "/register-invite/",
        name: "RegisterInvite",
        component: RegisterInvite,
        children: [
            {
                path: 'phone-verify/*',
                name: 'PhoneVerifyInvite',
                component: PhoneVerifyInvite,
                meta: {
                    authorization: true
                },
            },
            {
                path: 'phone-confirm/*',
                name: 'PhoneConfirmInvite',
                component: PhoneConfirmInvite,
            },
            {
                path: 'user-photo',
                name: 'ProfilePhotoStepInvite',
                component: ProfilePhotoStepInvite,
                meta: {
                    authorization: true
                },
            },
        ]
    },
    {
        path: "/cookie-policy",
        name: "CookiePolicy",
        component: CookiePolicy,
    },
    {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
    },
    {
        path: "/accessibility-policy",
        name: "AccessibilityPolicy",
        component: AccessibilityPolicy,
    },
    {
        path: "/faq",
        name: "Faq",
        component: Faq,
    },
    {
        path: "/terms-of-conditions",
        name: "TermsOfConditions",
        component: TermsOfConditions,
    },
    {
        path: "/confirm-mail",
        name: "ConfirmMail",
        component: ConfirmMail,
        meta: {
            authorization: true
        },
    },
    {
        path: '/confirm-phone',
        name: 'ConfirmPhone',
        component: ConfirmPhone,
        meta: {
            authorization: true
        },
    },
    {
        path: "/login-in",
        name: "LoginInAccount",
        component: LoginInAccount,
        meta: {
            authorization: true
        },
    },
    {
        path: "/login-in-otp",
        name: "LoginInAccountOtp",
        component: LoginInAccountOtp,
        meta: {
            authorization: true
        },
    },
    {
        path: "/reset-password-form",
        name: "ResetPasswordForm",
        component: ResetPasswordForm,
        meta: {
            authorization: true
        },
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        component: ResetPassword,
        meta: {
            authorization: true
        },
    },
    {
        path: "/create-new-password/*",
        name: "CreateNewPassword",
        component: CreateNewPassword,
        meta: {
            authorization: true
        },
    },
    {
        path: "/register-account",
        name: "RegisterAccount",
        component: RegisterAccount,
        meta: {
            authorization: true
        },
    },
    {
        path: "/register-account-invite/*",
        name: "RegisterAccountInvite",
        component: RegisterAccountInvite,
        meta: {
            authorization: true
        },
    },
    {
        path: "/profile-user",
        name: "Profile",
        component: Profile,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/profile-user',
                name: 'My profile',
                component: EditProfileTab,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'information',
                name: 'Information',
                component: FirmInfoTab,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'password',
                name: 'PasswordSecurity',
                component: PasswordSecurityTab,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'security',
                name: '2FASecurity',
                component: TwoFASecurityTab,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'phone',
                name: 'PhoneSecurity',
                component: PhoneChangeTab,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'email',
                name: 'EmailSecurity',
                component: EmailChangeTab,
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/contacts",
        redirect: "/contacts/users",
        name: "Contacts",
        component: Contacts,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'users',
                name: 'Users',
                component: ContactsEmployees,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'custodians',
                name: 'Custodians',
                component: ContactsClients,
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
    {
        path: "/invite-new-contact",
        name: "Invite contact",
        redirect: "/invite-new-contact/approved",
        component: InviteContact,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'invite-team',
                name: 'Send invitations',
                component: InviteNew,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'approved',
                name: 'Approved requests',
                component: Approved,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'processing-requests',
                name: 'Processing requests',
                component: ProcessingRequests,
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
    {
        path: "/cases",
        name: "Cases",
        component: Cases,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/cases/:slug',
        redirect: '/cases/:slug/users',
        name: "case-details",
        component: CaseDetails,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'users',
                name: 'CaseEmployeesTable',
                component: CaseEmployeesTable,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'clients',
                name: 'CaseClientsTable',
                component: CaseClientsTable,
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
    {
        path: '/cases/:slug/messages',
        redirect: { name: 'Case messages mobile list' },
        name: 'Messages',
        component: CaseMessages,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'mobile/correspondence',
                name: 'Case messages mobile list',
                component: CaseMessagesMobile,
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: 'mobile/edit',
                name: 'Case messages mobile edit',
                component: CaseMessagesMobileEdit,
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: 'mobile/tags',
                name: 'Case messages mobile tags',
                component: CaseMessagesTags,
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: 'mobile/pinned',
                name: 'Case messages mobile pinned',
                component: CaseMessagesPinned,
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: 'mobile/highlight',
                name: 'Case messages mobile highlight',
                component: CaseMessagesHighlight,
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: 'mobile/reminders',
                name: 'Case messages mobile reminders',
                component: CaseMessagesMobileReminders,
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: 'mobile/comments',
                name: 'Case messages mobile comments',
                component: CaseMessagesMobileComments,
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: 'emails',
                name: 'Case messages email list',
                component: CaseMessagesEmails,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'media',
                name: 'Case messages media list',
                component: CaseMessagesMedia,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'logs',
                name: 'Case messages call logs list',
                component: CallLogs,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'contacts',
                name: 'Case messages contacts list',
                component: CaseMessagesContacts,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'apps',
                name: 'Case messages apps list',
                component: CaseMessagesApps,
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
    {
        path: '/cases/:slug/assign',
        name: 'Assign new Users to',
        component: CaseAssign,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/cases/:slug/invite',
        name: 'Invite',
        component: CaseInviteClient,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/cases/:slug/files',
        name: 'Files',
        component: CaseFilesTable,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/cases/:slug/case-manager',
        name: `Assign new Case Manager for`,
        component: AssignCaseManager,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/cases/:slug/export',
        name: 'Export',
        component: CaseExport,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/cases/:slug/settings',
        redirect: '/cases/:slug/settings/general',
        name: 'Case Settings',
        component: CaseSettings,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/cases/:slug/settings/general',
                name: 'Case Settings',
                component: CaseSettingsGeneral,
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: '/cases/:slug/settings/custodians',
                name: 'Case Settings',
                component: CaseSettingsCustodians,
                meta: {
                    requiresAuth: true
                },
            }
        ]
    },
    {
        path: '/cases/:slug/case-actions',
        redirect: '/cases/:slug/case-actions/permission',
        name: 'case-actions',
        component: CaseActions,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'permission',
                name: 'Case Actions Permission',
                component: CaseActionsPermission,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'team',
                name: 'Case Actions Team',
                component: CaseActionsTeam,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'clients',
                name: 'Case Actions Custodians',
                component: CaseActionsClients,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'files',
                name: 'Case Actions Files',
                component: CaseActionsFiles,
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
    {
        path: "/cases/:slug/emails",
        redirect: '/cases/:slug/emails/email-integration',
        name: "E-mail integration wrapper",
        component: CaseEmails,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: "email-integration",
                name: "E-mail integration",
                component: CaseEmailList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: ':emailId',
                redirect: ':emailId/list',
                name: 'E-mail integration folder wrapper',
                component: CaseEmailFolder,
                meta: {
                    requiresAuth: true,
                },
                children: [
                    {
                        path: 'list',
                        name: 'E-mail integration list',
                        component: CaseEmailFolderList,
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'message/:messageId',
                        name: 'E-mail integration specific message',
                        component: CaseEmailSpecificMessage,
                        meta: {
                            requiresAuth: true
                        }
                    }
                ]
            },
        ]
    },
    {
        path: "/cases/:slug/management",
        name: "Management",
        redirect: "/cases/:slug/management/keys",
        component: ManagementPage,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'keys',
                name: 'Manage Keys',
                component: ManageKeysList,
                meta: {
                    requiresAuth: true
                }
            }
        ]
    },
    {
        path: "/cases/:slug/configure-device",
        name: "Configure Device",
        component: ConfigureDevice,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/create-case",
        name: "Create Case",
        component: CreateCase,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/permissions",
        name: "Permissions",
        component: Permissions,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'role',
                name: 'Permissions Role',
                component: PermissionsRole,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'individual',
                name: 'Role Individual',
                component: PermissionsIndividual,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'individual/:user_id',
                name: 'Role Individual User',
                component: PermissionsIndividual,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'case',
                name: 'Permissions Case',
                component: PermissionsCases,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'case/:slug',
                name: 'Case',
                component: PermissionsCaseItem,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'case/:case_id/:user_id',
                name: 'Case Permission',
                component: PermissionsCaseItem,
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
    {
        path: '/billing-account',
        name: 'Billing information',
        component: ProfileBilling,
        children: [
            {
                path: 'payment-history',
                name: 'Billing Payment history',
                component: BillingHistory,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'regPack',
                name: 'Billing RegPack',
                component: RegPack,
                meta: {
                    requiresAuth: true
                }
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/notifications",
        name: "Notifications",
        redirect: "/notifications/files",
        component: Notifications,
        children: [
            {
                path: 'files',
                name: 'Notifications Files',
                component: NotificationsFiles,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'cases',
                name: 'Notifications Case',
                component: NotificationsCase,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'custodians',
                name: 'Notifications Custodians',
                component: NotificationsClients,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'team',
                name: 'Notifications Team',
                component: NotificationsTeam,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'permission',
                name: 'Notifications Permission',
                component: NotificationsPermission,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'billing',
                name: 'Notifications Billing',
                component: NotificationsBilling,
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
    {
        path: "/download",
        name: "Download",
        component: Download,
    },
    {
        path: "/software-settings",
        name: "SoftwareSettings",
        component: SoftwareSettings,
    },
    {
        path: "/email",
        name: "Email",
        component: Email,
    },
    {
        path: "*",
        name: "ErrorPageNotFound",
        component: ErrorPageNotFound,
    },
    {
        path: "/coming-soon",
        name: "ComingSoon",
        component: ComingSoon,
    },
    {
        path: "/print-invoice",
        name: "PrintInvoice",
        component: PrintInvoice,
    },
    {
        path: "/get-support",
        name: "Support",
        component: Support,
    },
    {
        path: "/client-profile/:slug",
        name: "Custodian Profile",
        component: ClientProfile,
    },
    {
        path: "/user-profile/:slug",
        name: "User Profile",
        component: UserProfile,
    },
    {
        path: "/app-version/*",
        name: "App Versions",
        component: AppVersions,
    },
    {
        path: "/pme-support",
        name: "PME Support",
        component: PmeSupport,
        children: [
            {
                path: 'topic/:topicName/:postName',
                name: 'Support Topic',
                component: SupportTopic,
                meta: {
                    requiresAuth: true
                }
            }
        ]
    },
    {
        path: "/custodian-support",
        name: "Client Support",
        redirect: "/custodian-support/activity",
        component: ClientSupport,
        children: [
            {
                path: 'activity',
                name: 'Custodian Activity',
                component: CustodianSessionsList,
                meta: {
                    requiresAuth: true
                }
            }
        ]
    },
    {
        path: "/management",
        name: "Management",
        redirect: "/management/keys",
        component: ManagementPage,
        children: [
            {
                path: 'keys',
                name: 'Manage Keys',
                component: ManageKeysList,
                meta: {
                    requiresAuth: true
                }
            }
        ]
    },

];

export const router = new Router({
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    mode: 'history',
    routes,
    linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('isLogged') == null) {
            return next('/login-in');
        } else {
            return next()
        }
    }
    else if (to.matched.some(record => record.meta.authorization)) {
        if (localStorage.getItem('isLogged') !== null) {
            return next('/dashboard');
        } else {
            return next()
        }
    }else {
        return next();
    }
})

export default router;
