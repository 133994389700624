<template>
  <section class="section register-section">
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
    <div class="container-small">
      <div class="register-step">
        <div class="step-header">
          <div class="step-progress">
            <div class="bar progressbar" style="width: 50%;"></div>
          </div>
          <ul class="step-pills">
            <li class="step-item validated"><span class="step-link"><span class="tabStatus">1 </span><span
                class="tabLabel">2Factor Authentication</span></span></li>
            <li class="step-item validated"><span class="step-link"><span class="tabStatus">2 </span><span
                class="tabLabel">Company registration</span></span></li>
            <li class="step-item active"><span class="step-link"><span class="tabStatus">3 </span><span
                class="tabLabel">Billing</span></span></li>
            <li class="step-item"><span href="#" class="step-link"><span class="tabStatus">4 </span><span
                class="tabLabel">Profile photo</span></span></li>
          </ul>
        </div>
        <div class="register-form-container card-form">
          <div class="register-info">
            <h1 class="register-info-title main-title">Link your card</h1>
            <p class="register-info-description main-description">Bind your card to pay your profile on time</p>
          </div>
          <div class="form-content">
            <vue-paycard ref="card" :value-fields="valueFields" :input-fields="inputFields" :is-card-number-masked="false"/>
            <form @submit.prevent="submit"
                  class="form-registration"
            >
              <div class="form-group"
                   :class="{ 'form-group--error': $v.formData.cardNumber.$error }"
              >
                <label for="register_card-number_input">Card Number*</label>
                <input
                    id="register_card-number_input"
                    type="tel"
                    class="form-control"
                    :class="false ? 'is-invalid' : ''"
                    placeholder="Enter card name"
                    v-model="$v.formData.cardNumber.$model"
                >
                <div class="error" v-if="!$v.formData.cardNumber.required">Field is required</div>
                <div class="error" v-if="!$v.formData.cardNumber.numeric">Only number</div>
                <div class="error" v-if="!$v.formData.cardNumber.minLength || !$v.formData.cardNumber.maxLength">
                  Card number must have {{$v.formData.cardNumber.$params.minLength.min}} numbers.
                </div>
              </div>
              <div
                  class="form-group"
                  :class="{ 'form-group--error': $v.formData.cardName.$error }"
              >
                <label for="register_card-name_input">Name on card*</label>
                <input
                    id="register_card-name_input"
                    type="text"
                    class="form-control"
                    :class="false ? 'is-invalid' : ''"
                    placeholder="Enter card number"
                    v-model="$v.formData.cardName.$model"
                    :maxlength="cardNameMax"
                >
                <div class="error" v-if="$v.formData.cardName.minLength || $v.formData.cardName.maxLength">Name on card should have from 3 to 28 letters</div>
                <div class="error" v-if="!$v.formData.cardName.required">Field is required</div>
                <div class="error" v-if="!$v.formData.cardName.alpha">Enter only letters</div>
              </div>
              <div
                  class="form-group"
                  :class="{ 'form-group--error': $v.formData.cardMonth.$error }"
              >
                <label for="register_card-month_default_input">EXP Date Month*</label>
                <custom-select
                    id="register_card-month_default_input"
                    :options="month"
                    @input="selectMonth"
                    default="MM"
                />
                <select
                    id="register_card-month_input"
                    class="form-control"
                    :class="false ? 'is-invalid' : ''"
                    v-model="$v.formData.cardMonth.$model"
                    v-show="false"
                >
                  <option value disabled selected>Month</option>
                  <option
                      v-bind:value="n < 10 ? '0' + n : n"
                      v-for="n in 12"
                      v-bind:disabled="n < minCardMonth"
                      v-bind:key="n"
                  >{{generateMonthValue(n)}}</option>
                </select>
                <div class="error" v-if="!$v.formData.cardMonth.required">Field is required</div>
              </div>
              <div
                  class="form-group"
                  :class="{ 'form-group--error': $v.formData.cardYear.$error }"
              >
                <label for="register_card-year_default_input">EXP Date Year*</label>
                <custom-select-years
                    id="register_card-year_default_input"
                    :options="years"
                    @input="selectYear"
                    default="YY"
                />
                <select
                    id="register_card-year_input"
                    type="text"
                    class="form-control"
                    :class="false ? 'is-invalid' : ''"
                    v-model="$v.formData.cardYear.$model"
                    v-show="false"
                >
                  <option value disabled selected>Year</option>
                  <option
                      v-bind:value="$index + minCardYear"
                      v-for="(n, $index) in 12"
                      v-bind:key="n"
                  >{{$index + minCardYear}}</option>
                </select>
                <div class="error" v-if="!$v.formData.cardYear.required">Field is required</div>
              </div>
              <div
                  class="form-group"
                  :class="{ 'form-group--error': $v.formData.cardCvv.$error }"
              >
                <label for="register_card-cvv_input">CVC/CVV*</label>
                <input
                    id="register_card-cvv_input"
                    type="tel"
                    ref="cvv"
                    @focus="cardRevert"
                    @blur="cardDefaultState"
                    class="form-control"
                    :class="false ? 'is-invalid' : ''"
                    placeholder="Enter CVC/CVV"
                    v-model="$v.formData.cardCvv.$model"
                >
                <div class="error" v-if="!$v.formData.cardCvv.required">Field is required</div>
                <div class="error" v-if="!$v.formData.cardCvv.numeric">Enter only numbers</div>
                <div class="error" v-if="!$v.formData.cardCvv.minLength">Cvv must have at least {{$v.formData.cardCvv.$params.minLength.min}} letters.</div>
                <div class="error" v-if="!$v.formData.cardCvv.maxLength">CVV must have no more than {{$v.formData.cardCvv.$params.maxLength.max}} letters.</div>
              </div>
              <div class="form-button-wrapper">
                <button
                    id="register_card_button"
                    type="submit"
                    class="btn form-btn"
                    v-text="'Confirm'"></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>

import AlertErrorMsg from "../profile/AlertErrorMsg";
import { VuePaycard } from 'vue-paycard'
import CustomSelect from '../inputs/CustomSelect'
import CustomSelectYears from '../inputs/CustomSelectYears'
import { required, minLength, maxLength,  numeric, helpers } from 'vuelidate/lib/validators'
import LocalStorageService from "../../services/LocalStorageService";

const alpha = helpers.regex('alpha', /^[a-zA-Z ]+$/)

// LocalstorageService
const localStorageService = LocalStorageService.getService();

export default {
  name: "Billing",
  emits:['update:modelValue'],
  components: {
    AlertErrorMsg,
    VuePaycard,
    CustomSelect,
    CustomSelectYears,
  },
  data() {
    return {
      cardNameMax: 28,
      month: [
        {name: "January", number: '01'},
        {name: "February", number: '02'},
        {name: "March", number: '03'},
        {name: "April", number: '04'},
        {name: "May", number: '05'},
        {name: "June", number: '06'},
        {name: "July", number: '07'},
        {name: "August", number: '08'},
        {name: "September", number: '09'},
        {name: "October", number: '10'},
        {name: "November", number: '11'},
        {name: "December", number: '12'},
      ],
      years: [],
      formData: {
        cardNumber: '',
        cardName: '',
        cardMonth: '',
        cardYear: '',
        expiration: '',
        cardCvv: '',
      },
      valueFields: {
        cardName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: ''
      },
      inputFields: {
        cardNumber: 'v-card-number',
        cardName: 'v-card-name',
        cardMonth: 'v-card-month',
        cardYear: 'v-card-year',
        cardCvv: 'v-card-cvv'
      },
      minCardYear: new Date().getFullYear(),
      submitStatus: null,
      validForm: false,
      errorMessage: '',
    };
  },
  validations: {
    formData: {
      cardNumber: {
        required,
        minLength: minLength(16),
        maxLength: maxLength(16),
        numeric
      },
      cardName: {
        required,
        alpha,
        minLength: minLength(3),
        maxLength: maxLength(28),
      },
      cardMonth: {
        required,
      },
      cardYear: {
        required,
      },
      cardCvv: {
        required,
        numeric,
        minLength: minLength(3),
        maxLength: maxLength(4)
      },
    },
  },
  computed: {
    minCardMonth () {
      if (this.formData.cardYear === this.minCardYear) return new Date().getMonth() + 1
      return 1
    },
  },
  mounted() {
    for(let i = 0; i < 10; i++) {
      this.years.push(i + this.minCardYear)
    }
  },
  methods:{
    cardRevert() {
      this.$refs.card.$el.classList.add('-active')
    },
    cardDefaultState() {
      this.$refs.card.$el.classList.remove('-active')
    },
    generateMonthValue (n) {
      return n < 10 ? `0${n}` : n
    },
    selectMonth(event) {
      if(event === 'MM') {
        return
      }

      this.month.forEach(item => {
        if(item.name === event) {
          this.valueFields.cardMonth = item.number
        }
      })

      this.formData.cardMonth = event
      // this.valueFields.cardMonth = this.formData.cardMonth
      this.$v.formData.cardMonth.$touch()
    },
    selectYear(event) {
      if(event === 'YY') {
        return
      }
      this.formData.cardYear = event
      this.valueFields.cardYear = this.formData.cardYear
      this.$v.formData.cardYear.$touch()
    },
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'

        this.$load(async () => {
          await this.$api.auth.paymentCard({
            // number: +this.formData.cardNumber.split('').reverse().join('').match(/\d{4}/)[0].split('').reverse().join(''),
            number: +this.formData.cardNumber,
            name: this.formData.cardName,
            expiration: this.formData.expiration,
            cvc: this.formData.cardCvv,
          }).then(() => {
            localStorageService.setDataToLocalStorage(4);
            this.$router.push({path: '/register/user-photo'})
          })
        }, (error) => {
          this.errorMessage = error.data.errors.number[0]
          this.$refs.alert.showAlert()
        })
      }
    },
  },
  watch: {
    selectedCountry: function() {
      // Clear previously selected values
      this.cities = [];
      this.selectedCity = "";
      // Now we have a continent and country. Populate list of cities in the third dropdown
      if (this.selectedCountry.length > 0) {
        this.cities = this.places[this.selectedCountry]
      }
    },
    'formData.cardNumber': function() {
      this.formData.cardNumber = this.formData.cardNumber.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
      this.valueFields.cardNumber = this.formData.cardNumber.replace(/\s/g, '').replace(/(\d{4})/g, '$1 ').trim();
    },
    'formData.cardName': function() {
      this.valueFields.cardName = this.formData.cardName
    },
    'formData.cardMonth': function() {
      this.formData.expiration = this.formData.cardYear + this.formData.cardMonth
    },
    'formData.cardYear': function() {
      if (this.formData.cardMonth < this.minCardMonth) {
        this.cardMonth = ''
      }
      this.valueFields.cardYear = this.formData.cardYear
      this.formData.expiration = this.formData.cardYear + this.formData.cardMonth
    },
    'formData.cardCvv' : function() {
      this.valueFields.cardCvv = this.formData.cardCvv
    },
  },
}
</script>

<style scoped>
.card-form {
  max-width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.card-form .register-info {
  margin-top: 0;
  margin-bottom: 50px;
  width: 100%;
  text-align: center;
}

.card-form .form-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.card-form .card-item {
  margin: 0;
}

.card-form .form-registration {
  width: calc(100% - 600px);
}

#register_card-month_default_input::v-deep .selected,
#register_card-year_default_input::v-deep .selected {
  height: 49px;
  padding: 15px 30px;
}

.form-content >>> .card-item__name {
  text-overflow: ellipsis;
}
</style>
