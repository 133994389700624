<template>
  <div class="faq-item" :class="open ? 'open': ''">
    <div class="faq-item-header" @click="open = !open">
      <h2 class="faq-item-title">{{ title }}</h2>
      <div class="faq-item-icon" :class="open ? 'active': ''">
        <img src="../../assets/img/faq-accordeon-icon.svg" alt="item-icon">
      </div>
    </div>
    <div v-if="open">
      <FaqSubAccordeon
          v-for="(problem,idx) in poblemsVariants"
          :key="idx"
          :answer="problem.answer"
          :question="problem.question"
      />
    </div>
  </div>
</template>

<script>
import FaqSubAccordeon from "./FaqSubAccordeon";

export default {
  name: "FaqAccordeon",
  props: {
    title: {},
    poblemsVariants: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      open: false,
    }
  },
  components: {
    FaqSubAccordeon
  }
}
</script>

<style scoped>

.faq-item {
  margin: 15px 0 0 0;
  cursor: pointer;
  width: 100%;
  border: 1px solid var(--input-placeholder-value-color);
  border-radius: 10px;
  position: relative;
  transition: .5s all;
}

.faq-item.open {
  padding-bottom: 62px;
}

.faq-item-header {
  position: relative;
}

.faq-item:first-child {
  margin: 0;
}

.faq-item:hover {
  box-shadow: 0px 4px 20px rgba(77, 106, 147, 0.15);
  border-radius: 5px;
}

.faq-item-title {
  margin: 0;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  padding: 25px 95px 25px 88px;
}

.faq-item-icon {
  position: absolute;
  top: 50%;
  right: 68px;
  transform: translateY(-50%) rotate(180deg);
}

.faq-item-icon.active {
  transform: translateY(-50%) rotate(0deg);
}


</style>
