<template>
  <tbody>
  <tr v-for="(item, i) in actionFilesList"
      :key="item + i">
    <td class="w-300">
      <div class="holder-info">
        <div class="info">
          <span class="info-mail">{{ item.filename }}</span>
        </div>
      </div>
    </td>
    <td>
      <span>{{ item.action }}</span>
    </td>
    <td>
      <span>{{ item.device }}</span>
    </td>
    <td class="w-300">
      <div class="holder-info">
        <div class="img" v-if="item.actorFirstName && item.actorLastName">
          <img
              v-if="item.actorAvatar"
              :src="item.actorAvatar"
              onerror="this.src='https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png';"
              alt=""
          >
          <img
              v-else
              src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png"
              alt=""
          >
        </div>
        <div class="info">
                <span class="info-name"
                      v-if="item.actorFirstName && item.actorLastName"
                      v-text="(item.actorFirstName.toLowerCase()[0].toUpperCase() + item.actorFirstName.toLowerCase().slice(1)) + ' ' + (item.actorLastName.toLowerCase()[0].toUpperCase() + item.actorLastName.toLowerCase().slice(1))"
                ></span>
          <span
              class="info-name"
              v-else
              v-text="item.actorEmail"
          ></span>
        </div>
      </div>
    </td>
    <td>
      <span class="span-cases" v-if="item.actorRoles.length > 2">{{ item.actorRoles.length }} roles</span>
      <span v-else-if="item.actorRoles.length === 1"
            v-text="item.actorRoles[0].toLowerCase()[0].toUpperCase() + item.actorRoles[0].toLowerCase().slice(1).replaceAll('_', ' ')"
            class="span-cases"
            :class="`span-cases-` + item.actorRoles[0].toLowerCase()"
      ></span>
      <div
          v-else
          class="span-cases-wrap"
      >
          <span
              v-text="item.actorRoles[0].toLowerCase()[0].toUpperCase() + item.actorRoles[0].toLowerCase().slice(1).replaceAll('_', ' ')"
              class="span-cases"
              :class="`span-cases-` + item.actorRoles[0].toLowerCase()"
          ></span>
        <span
            v-text="item.actorRoles[1].toLowerCase()[0].toUpperCase() + item.actorRoles[1].toLowerCase().slice(1)"
            class="span-cases"
            :class="`span-cases-` + item.actorRoles[1].toLowerCase()"
        ></span>
      </div>
    </td>
    <td>
          <span>
              {{ parseDate(item.actionDate) }}
            </span>
    </td>
  </tr>
  </tbody>
</template>

<script>
import '@/assets/css/table.css';
import parseDate from "../../../mixins/parseDate";

export default {
  name: "CaseActionsFilesItem",
  props: {
    actionFilesList: {
      type: Array
    },
  },
  mixins: [parseDate]
}
</script>

<style scoped>
.span-cases-wrap {
  border: none;
  padding: 0;
  text-align: left;
}
</style>