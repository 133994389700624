<template>
  <div class="wrapper">
    <AlertErrorMsg
        :title="errorMessage"
        ref="alert"
    />
    <Sidebar/>
    <div class="content-wrapper">
      <HeaderRegistered :returnPath="returnPath" :should-go-back-in-route="returnPath"/>
      <main class="main">
        <section class="dashboard-section">
          <div class="case-email-table">
            <div class="contacts-bar">
              <button
                  :id="'email-integration'"
                  class="btn email-integration-btn"
                  @click="openCreateEmailPopup"
              >Add e-mail integration</button>
              <div class="case-email-bar-sort">
                <div class="holder-search">
                  <CustomSearch
                      :id="`search_in_emails_case_${caseId}`"
                      :modelValue="searchText"
                      @update:modelValue="searchText = $event"
                  />
                </div>
              </div>
            </div>
            <div class="case-email-wrapper">
              <Loader v-if="loadFlag" :load-flag="loadFlag" />
              <div v-else-if="isEmptyData" class="empty-emails-list">
                <img :src="noEmailsImg" alt=""/>
                <span>No e-mails found, add e-mail integration</span>
              </div>
              <CaseEmailsItem
                  :casesEmails="casesEmails"
                  @remove="removeEmailFromCase"
                  @changePasswordEmail="openChangePasswordEmailPopup($event)"
              />
            </div>
          </div>
        </section>
      </main>
    </div>
    <CaseEmailPopup
        ref="createCaseEmail"
        @error="showErrorMessage"
        @addedEmail="getCaseEmails"
    />
    <ChangePasswordCaseEmailPopup
        ref="changePasswordCaseEmail"
        @error="showErrorMessage"
        @changePassword="getCaseEmails"
    />
  </div>
</template>

<script>
import Loader from "../../loader/Loader";
import Sidebar from "../../Sidebar";
import AlertErrorMsg from "../../profile/AlertErrorMsg";
import CustomSearch from "../../inputs/CustomSearch";
import HeaderRegistered from "../../layout/HeaderRegistered";
import CaseEmailsItem from "./CaseEmailsItem";
import CaseEmailPopup from "../../popups/CaseEmailPopup";
import ChangePasswordCaseEmailPopup from "../../popups/ChangePasswordCaseEmailPopup";
import noEmailsImg from "@/assets/img/no-cases-found.svg"

export default {
name: "CaseEmailList",
  data() {
    return {
      noEmailsImg,
      loadFlag: false,
      returnPath: true,
      caseTitle: '',
      isEmptyData: false,
      subTitle: 'E-mail integration',
      casesEmails: [],
      caseId: this.$route.params.slug,
      searchText: "",
      page: 1,
      size: 10,
      count: 0,
      pageSize: 2,
      errorMessage: '',
    }
  },
  components: {
    Loader,
    CaseEmailPopup,
    ChangePasswordCaseEmailPopup,
    Sidebar,
    AlertErrorMsg,
    CustomSearch,
    CaseEmailsItem,
    HeaderRegistered,
  },
  watch: {
    searchText() {
      this.getCaseEmails()
    },
  },
  methods: {
    showErrorMessage(errorText) {
      this.errorMessage = errorText;
      this.$refs.alert.showAlert();
    },

    openCreateEmailPopup() {
      this.$refs.createCaseEmail.openPopups();
    },
    openChangePasswordEmailPopup(email) {
      this.$refs.changePasswordCaseEmail.openPopups(email);
    },
    removeEmailFromCase(email) {
      this.$load(async () => {
        await this.$api.cases.removeCaseEmail(this.caseId, email.emailId).then(() => {
          this.getCaseEmails();
        }).catch(err => {
          this.errorMessage = err.response.data.errors.message[0];
          this.$refs.alert.showAlert();
        })
      }, (error) => {
        if(error) {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert();
        }
      })
    },
    getCaseEmails() {
      this.casesEmails = [];
      this.loadFlag = true;
      this.isEmptyData = false;
      this.$load(async () => {
        await this.$api.cases.getCaseEmail(this.caseId, this.searchText).then((res) => {
          this.casesEmails = res.data.body.caseEmails
          if(!this.casesEmails.length) {
            this.isEmptyData = true;
          }
          this.loadFlag = false;
        }).catch(err => {
          this.loadFlag = false;
          this.errorMessage = err.response.data.errors.message[0];
          this.$refs.alert.showAlert();
        })
      }, (error) => {
        if(error) {
          this.loadFlag = false;
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert();
        }
      })
    }
  },
  mounted() {
    this.getCaseEmails();
  }
}
</script>

<style scoped>
.contacts-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.case-email-bar-sort .holder-search {
  min-width: 300px;
}

.email-integration-btn {
  font-size: 12px;
  line-height: 19px;
  padding: 7px 30px;
  min-width: 120px;
  background-color: var(--white-color);
  color: var(--accent-color);
  border: 1px solid var(--accent-color);
}

.email-integration-btn:hover {
  background-color: var(--accent-color);
  color: var(--white-color);
}

.case-email-wrapper {
  min-height: 500px;
  position: relative;
}

.case-email-wrapper tbody {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.empty-emails-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 150px auto;
  max-width: 310px;
  width: 100%;
  gap: 40px;
}

.empty-emails-list span {
  font-size: 16px;
  font-weight: 500;
  line-height: 25.6px;
  text-align: center;
  color: var(--light-gray);
}
</style>