<template>
  <div class="case-clients-table"
  >
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
      <table class="raz" cellpadding="0" cellspacing="0" border="0">
        <TableHeader
            v-if="users.length"
            :class="checkAll || checkSome.length >= 2 ? 'active ' : ''">
          <tr>
          <th class="holder-checkbox">
            <div class="custom-checkbox-group">
              <div class="checkbox-wrap">
                <input
                    :id="`checkAll_client_in_case_${caseId}`"
                    class="input-checkbox"
                    type="checkbox"
                    v-model="checkAll">
                <label :for="`checkAll_client_in_case_${caseId}`" class="label-checkbox">
                  <span>{{' '}}</span>
                </label>
                <span class="checkbox-unselect" @click="unselectAll">{{ checkAll || checkSome.length >= 2 ? 'Unselect All' : '' }}</span>
              </div>
            </div>
          </th>
          <th>
            <div v-show="!checkAll && checkSome.length <= 1"
                :id="`sort_client_in_case_${caseId}_by_name`"
                @click="sortedBy('email')"
                class="table-header-box"
                :class="{
                  'sort-active': this.sortBy === 'email',
                  'sorted-desc': this.order === 'desc',
                }"
            >
               <UserIcon />
              <span class="header-table-text">Email</span>
              <div class="arrow-icon">
                <arrowDown class="arrow" />
              </div>
            </div>
          </th>
          <th>
            <div v-show="!checkAll && checkSome.length <= 1"
                class="table-header-box table-header-box-doesnt-hover">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.4737 3.2H6.73684C6.5135 3.2 6.29931 3.28429 6.14138 3.43431C5.98346 3.58434 5.89474 3.78783 5.89474 4V14.4H0.842105C0.618765 14.4 0.404572 14.3157 0.246647 14.1657C0.0887215 14.0157 0 13.8122 0 13.6V0.8C0 0.587827 0.0887215 0.384344 0.246647 0.234315C0.404572 0.0842854 0.618765 0 0.842105 0H12.6316C12.8549 0 13.0691 0.0842854 13.227 0.234315C13.385 0.384344 13.4737 0.587827 13.4737 0.8V3.2ZM8.42105 4.8H15.1579C15.3812 4.8 15.5954 4.88429 15.7534 5.03431C15.9113 5.18434 16 5.38783 16 5.6V15.2C16 15.4122 15.9113 15.6157 15.7534 15.7657C15.5954 15.9157 15.3812 16 15.1579 16H8.42105C8.19771 16 7.98352 15.9157 7.82559 15.7657C7.66767 15.6157 7.57895 15.4122 7.57895 15.2V5.6C7.57895 5.38783 7.66767 5.18434 7.82559 5.03431C7.98352 4.88429 8.19771 4.8 8.42105 4.8Z" fill="#D7DCE6"/>
              </svg>
              <span class="header-table-text">device</span>
            </div>
          </th>
          <th>
            <div v-show="!checkAll && checkSome.length <= 1"
                class="table-header-box table-header-box-doesnt-hover">
              <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.0625 0.5C3.17853 0.5 3.28981 0.549386 3.37186 0.637294C3.45391 0.725201 3.5 0.84443 3.5 0.96875V1.4375H10.5V0.96875C10.5 0.84443 10.5461 0.725201 10.6281 0.637294C10.7102 0.549386 10.8215 0.5 10.9375 0.5C11.0535 0.5 11.1648 0.549386 11.2469 0.637294C11.3289 0.725201 11.375 0.84443 11.375 0.96875V1.4375H12.25C12.7141 1.4375 13.1592 1.63504 13.4874 1.98667C13.8156 2.33831 14 2.81522 14 3.3125V13.625C14 14.1223 13.8156 14.5992 13.4874 14.9508C13.1592 15.3025 12.7141 15.5 12.25 15.5H1.75C1.28587 15.5 0.840752 15.3025 0.512563 14.9508C0.184374 14.5992 0 14.1223 0 13.625V5.1875H14V4.25H0V3.3125C0 2.81522 0.184374 2.33831 0.512563 1.98667C0.840752 1.63504 1.28587 1.4375 1.75 1.4375H2.625V0.96875C2.625 0.84443 2.67109 0.725201 2.75314 0.637294C2.83519 0.549386 2.94647 0.5 3.0625 0.5V0.5Z"
                    fill="#D7DCE6"/>
              </svg>
              <span class="header-table-text">last sync</span>
            </div>
          </th>
          <th class="w-400">
            <div v-if="checkAll || checkSome.length >= 2" class="holder-deactivate-btn" id="deactivate-all-clients" ref="deactivateAllClients">
              <button
                  :id="`deactivate_clients_in_case_${caseId}`"
                  class="btn btn-deactivate"
                  @click.stop="openDeactivatePopup"
              >
                <span class="btn-span">Deactivate all</span>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 0.499969C4.47 0.499969 0 4.96997 0 10.5C0 16.03 4.47 20.5 10 20.5C15.53 20.5 20 16.03 20 10.5C20 4.96997 15.53 0.499969 10 0.499969ZM15 14.09L13.59 15.5L10 11.91L6.41 15.5L5 14.09L8.59 10.5L5 6.90997L6.41 5.49997L10 9.08997L13.59 5.49997L15 6.90997L11.41 10.5L15 14.09Z" fill="#D7DCE6"/>
                </svg>
              </button>
              <tooltip v-if="!checkPermission(['manage_contacts'])" :target-ref="$refs.deactivateAllClients">
                <span>To get access to remove custodians from a case, you need to get permission 'Assign user to case' in this case</span>
              </tooltip>
            </div>
          </th>
        </tr>
        </TableHeader>
        <Loader v-if="loadFlag" :load-flag="loadFlag" />
        <template v-else>
          <CaseClientsItem
              :clientsList="users"
              v-if="users.length"
              @deactivateUser="deleteUser"
              @confirmDeactivateUser="confirmDeactivateUser"
              @resend="resendInvite"
          />
          <p v-else class="no-found">not found</p>
        </template>
      </table>
    <pagination v-if="users.length && count !== 1"
                v-model="page"
                :total-pages="count"
                :link-gen="linkGen"
    ></pagination>
    <CommonDialog
        ref="commonDialogDeleteUser"
    >
      <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z"
            fill="#082655"/>
      </svg>
      <h2 class="dialog-message-title">Success!</h2>
      <p class="dialog-message-description" style="display:block;">Selected custodians were deactivated</p>
    </CommonDialog>
    <DeactivateItemPopUp
        :arrayOfCheckedUsers="checkSome"
        ref="contactsPopup"
        @deactivateArrayOfUsers="confirmDeactivateUserList"
        :subtitle="'custodian'"
        :title="'custodian'"
    />
    <InfoDialog
        ref="infoDialog"
        :title="infoDialogData.dialogTitle"
        :text="infoDialogData.dialogText"
        :confirmBtnText="infoDialogData.confirmBtnText"
        :cancelBtnText="infoDialogData.cancelBtnText"
        @onConfirm="deleteUser"
    />
    <InfoDialog
        ref="infoDialogDeactivateUserList"
        :title="infoDialogData.dialogTitle"
        :text="infoDialogData.dialogText"
        :confirmBtnText="infoDialogData.confirmBtnText"
        :cancelBtnText="infoDialogData.cancelBtnText"
        @onConfirm="deactivateArrayOfUsers"
    />
    <SuccessPopup
      ref="successPopup"
      :text="successMessage"
    />
  </div>
</template>

<script>
import checkPermission from '@/utils/permission';
import linkGenerator from "../../../constants/linkGen";
import createDeactivateDialog from "../../../constants/infoDialogData";
import InfoDialog from "../../popups/InfoDialog";
import AlertErrorMsg from "../../profile/AlertErrorMsg";
import CommonDialog from "../../popups/CommonDialog";
import CaseClientsItem from "./CaseClientsItem";
import TableHeader from "../../table/TableHeader";
import DeactivateItemPopUp from "../../popups/DeactivateItemPopUp";
import Loader from "../../loader/Loader";
import checkAll from "../../../mixins/checkAll";
import ArrowDown from "../../icons/ArrowDown";
import UserIcon from "../../icons/UserIcon";
import SuccessPopup from "@/components/popups/SuccessPopup.vue";
import Tooltip from "@/components/common/Tooltip.vue";
import Pagination from "@/components/common/Pagination.vue";

export default {
  name: "CaseClientsTable",
  components: {
    Pagination,
    Tooltip,
    Loader,
    UserIcon,
    ArrowDown,
    DeactivateItemPopUp,
    CaseClientsItem,
    TableHeader,
    AlertErrorMsg,
    CommonDialog,
    InfoDialog,
    SuccessPopup
  },
  props: {
    searchText: {
      type: String
    },
  },
  mixins: [checkAll],
  data() {
    return {
      infoDialogData: {},
      loadFlag: false,
      caseId: this.$route.params.slug,
      keySearch: '',
      roles: '',
      sortBy: 'userName',
      order: 'asc',
      page: 1,
      size: 10,
      count: 0,
      pageSize: 2,
      users: [],
      errorMessage: '',
      successMessage: '',
      headerType: 'default',
      selectedFlag: false,
    }
  },
  watch: {
    searchText() {
      this.getClients()
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if(newVal) {
          this.page = newVal
          this.getClients()
        } else {
          this.page = 1
        }
      }
    }
  },
  methods: {
    checkPermission,
    unselectAll() {
      this.users.forEach((item) => {
        item.isActive = false;
      })
    },
    changeCheckbox(item) {
      this.$emit('changeCheckbox', item, this.title)
    },
    openDeactivatePopup() {
      this.$refs.contactsPopup.openPopup()
    },
    openDeactivatePopupUser() {
      this.$refs.commonDialogDeleteUser.openPopups();
    },
    linkGen(pageNum) {
      return linkGenerator(pageNum, '/cases/:slug/clients')
    },
    sortedBy(filterName) {
      this.order = filterName === this.sortBy && this.order === 'desc' ? 'asc' : 'desc'
      this.sortBy = filterName
      this.getClients()
    },
    fixedEncodeURIComponent (str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    },
    getClients() {
      const params = {
        sortBy: this.sortBy,
        keySearch: this.fixedEncodeURIComponent(this.searchText),
        order: this.order,
        page: this.page,
        size: 10
      };

      this.$load(async () => {
        this.loadFlag = true;
        await this.$api.cases
          .getCasesClients(this.caseId, params)
          .then((res) => {
            this.count = res.data.body.totalPages
            this.pageSize = res.data.body.size
            this.users = res.data.body.content.map(i => {
              i.isActive = false;
              return i;
            });
          }, (error) => {
            if (error.response) {
              this.errorMessage = error.response.data.errors.message[0]
            } else {
              this.errorMessage = error.data.errors.message[0]
            }
          })
        setTimeout(() => {
          this.loadFlag = false
        }, 1000)
      })
    },
    confirmDeactivateUser(user) {
      this.infoDialogData = createDeactivateDialog('custodian')
      this.$refs.infoDialog.openPopup(user);
    },
    confirmDeactivateUserList(userlist) {
      this.infoDialogData = createDeactivateDialog('custodians')
      this.$refs.infoDialogDeactivateUserList.openPopup(userlist);
    },
    deleteUser(user) {
      this.openDeactivatePopupUser()
      this.$load(async () => {
        await this.$api.cases.deleteCasesUser(this.caseId, user.publicId).then(() => {
          this.$refs.infoDialog.closePopup();
          this.openDeactivatePopupUser()
          this.getClients(this.order)
        }, (error) => {
          this.errorMessageHandler(error);
        });
      });
    },
    deactivateArrayOfUsers(arrayOfCheckedUsers) {
      const userIds = arrayOfCheckedUsers.map(user => user.publicId)
      userIds.toString()
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.cases.deleteCasesUser(this.caseId, userIds).then(() => {
          this.$refs.infoDialogDeactivateUserList.closePopup();
          this.openDeactivatePopupUser()
          this.getClients()
        }, (error) => {
          this.errorMessageHandler(error);
        });
      });
    },
    resendInvite(id, userName) {
      this.$load(async () => {
        await this.$api.cases.reInviteClientToCase({ caseId: this.caseId, publicId: id }).then(() => {
          this.successMessage = `${userName} has been re-invited`;
          this.$refs.successPopup.openPopup();
        }, (error) => {
          this.errorMessageHandler(error);
        })
      });
    },
    errorMessageHandler(error) {
      if (error.response) {
        this.errorMessage = error.response.data.errors.message[0]
      } else {
        this.errorMessage = error.data.errors.message[0]
      }
      this.$refs.alert.showAlert();
    },
  },
  mounted() {
    this.page = this.$route.query.page || 1
    this.getClients()
  }
}
</script>

<style scoped>
/* table header */
.table-header.active {
  justify-content: space-between;
  padding-right: 13px;
  background-color: var(--accent-color);
}

.table-header-box svg path {
  fill: var(--input-placeholder-value-color);
  transition: all 0.3s;
}

.table-header-box:hover svg:first-child path {
  fill: var(--accent-color);
}

.arrow-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-icon svg path {
  fill: var(--light-gray);
}

.table-header.active .holder-deactivate-btn {
  margin-left: auto;
  width: fit-content;
}

.table-header.active th {
  padding: 22px 15px 21px;
}
/* end table header */
.dialog-message-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #696F79;
  display: block;
  width: 100%;
}

.checkbox-wrap .checkbox-unselect {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  text-transform: initial;
  cursor: pointer;
  padding: 2px 0 0;
}
</style>
