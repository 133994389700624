<template>
  <div>
    <PopupWrapper>
      <div class="content-box">
        <form v-on:submit.prevent>
          <div class="popup-header">
            <div class="popup-close" @click="hide">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12 0.537842C5.54834 0.537842 0.333344 5.58237 0.333344 11.8231C0.333344 18.0639 5.54834 23.1084 12 23.1084C18.4517 23.1084 23.6667 18.0639 23.6667 11.8231C23.6667 5.58237 18.4517 0.537842 12 0.537842ZM17.8333 15.8746L16.1883 17.4658L12 13.4144L7.81168 17.4658L6.16668 15.8746L10.355 11.8231L6.16668 7.77171L7.81168 6.18049L12 10.2319L16.1883 6.18049L17.8333 7.77171L13.645 11.8231L17.8333 15.8746Z"
                    fill="#D7DCE6"/>
              </svg>
            </div>
            <h1> Reset settings</h1>
            <p class="subtitle">Are you sure you want to Reset to default settings?</p>
          </div>
          <div class="popup-content">
            <div class="holder-attention">
              <svg width="82" height="72" viewBox="0 0 82 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M34.9662 22.4594C35.3651 22.8814 35.7056 23.3539 35.979 23.8645H37.5953L38.197 26.4379L36.7403 27.1327C36.721 27.7101 36.6242 28.2825 36.4524 28.8346L37.6005 29.9618L36.1802 32.2058L34.6541 31.6791C34.2285 32.0745 33.7507 32.4109 33.2338 32.6793V34.28L30.6325 34.8736L29.9389 33.4325C29.3551 33.4148 28.7764 33.319 28.2186 33.1477L27.0809 34.2835L24.8126 32.8801L25.345 31.3703C24.9462 30.9482 24.6057 30.4758 24.3323 29.9652H22.716L22.1143 27.3918L23.571 26.697C23.5904 26.1196 23.6872 25.5473 23.8588 24.9951L22.7125 23.8679L24.1311 21.6239L25.6572 22.1505C26.0836 21.7562 26.5612 21.4198 27.0775 21.1503V19.5497L29.6787 18.9561L30.3724 20.3972C30.9561 20.4156 31.5347 20.5114 32.0927 20.682L33.2303 19.5462L35.5004 20.9496L34.9662 22.4594ZM31.7754 24.5713C31.294 24.2737 30.7343 24.1239 30.167 24.141C29.5996 24.1581 29.0501 24.3412 28.588 24.6672C28.1258 24.9932 27.7717 25.4474 27.5705 25.9725C27.3693 26.4976 27.33 27.0699 27.4575 27.6171C27.5851 28.1642 27.8737 28.6617 28.2871 29.0466C28.7004 29.4315 29.2198 29.6865 29.7796 29.7793C30.3394 29.8722 30.9144 29.7987 31.432 29.5683C31.9496 29.3378 32.3866 28.9607 32.6875 28.4846C32.8877 28.1685 33.0228 27.8165 33.0853 27.4486C33.1477 27.0808 33.1363 26.7043 33.0516 26.3409C32.9668 25.9774 32.8105 25.634 32.5915 25.3304C32.3725 25.0268 32.0952 24.7688 31.7754 24.5713Z" fill="#082655"/>
                <g opacity="0.8">
                  <path d="M72.9373 13.3109L71.3971 11.5053L75.9836 9.06872L54.6725 1.93359L61.8848 22.3657L64.5105 18.9626C64.5105 18.9626 78.2973 42.9656 64.5105 57.558C50.8187 72.0498 34.6572 68.9084 34.6572 68.9084C34.6572 68.9084 63.1739 80.7623 76.8046 58.048C90.4353 35.3338 72.9373 13.3109 72.9373 13.3109Z" fill="#5692B7"/>
                  <path d="M9.03486 58.6911L10.575 60.4966L5.98169 62.9332L27.2928 70.0683L20.0806 49.6362L17.4616 53.0393C17.4616 53.0393 3.66806 29.0363 17.4616 14.4439C31.1534 -0.0478279 47.3149 3.0935 47.3149 3.0935C47.3149 3.0935 18.7914 -8.76032 5.18786 13.9405C-8.41573 36.6413 9.03486 58.6911 9.03486 58.6911Z" fill="#5692B7"/>
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M54.9807 28.8382C55.8481 29.7559 56.5872 30.7843 57.1783 31.8958H60.6759L61.9816 37.4986L58.8163 39.0131C58.7737 40.2675 58.562 41.5107 58.1868 42.7099L60.6875 45.1571L57.598 50.0286L54.2753 48.877C53.35 49.7373 52.3106 50.4695 51.1858 51.0536V54.5086L45.5314 55.8099L44.0042 52.6774C42.7423 52.6353 41.4918 52.4281 40.2851 52.0613L37.8076 54.5258L32.8702 51.4797L34.0361 48.1975C33.1683 47.2822 32.4291 46.2557 31.8385 45.1456H28.3409L27.0352 39.5428L30.2004 38.0342C30.2426 36.7797 30.4543 35.5364 30.83 34.3374L28.3351 31.8901L31.4188 27.0128L34.7414 28.1645C35.6683 27.3059 36.7074 26.5739 37.831 25.9879V22.5329L43.5028 21.2373L45.0301 24.3698C46.3003 24.4091 47.5592 24.6183 48.7725 24.9917L51.2499 22.5214L56.1873 25.5733L55.0215 28.8497L54.9807 28.8382ZM48.038 33.4448C46.9913 32.7959 45.7731 32.4685 44.5379 32.5039C43.3026 32.5394 42.1058 32.9362 41.099 33.6441C40.0922 34.352 39.3207 35.3391 38.8823 36.4805C38.4438 37.6218 38.3582 38.866 38.6361 40.0555C38.9141 41.2449 39.5431 42.3261 40.4436 43.1622C41.3441 43.9982 42.4755 44.5515 43.6945 44.7519C44.9135 44.9523 46.1653 44.7908 47.2914 44.2878C48.4174 43.7849 49.367 42.9632 50.02 41.9267C50.4548 41.2401 50.7484 40.4756 50.8842 39.6769C51.0199 38.8782 50.995 38.0609 50.811 37.2717C50.6269 36.4824 50.2873 35.7367 49.8115 35.0772C49.3358 34.4176 48.7331 33.8571 48.038 33.4275V33.4448Z" fill="#082655"/>
              </svg>
              <p>Attention!</p>
              <p>After confirmation, your permissions will be reset to their <span>original settings.</span></p>
            </div>
          </div>
          <div class="popup-footer">
<!--            :disabled="resetSettingData === null "-->
            <button
              id="permission-reset-button"
              @click="handlerResetPopup"
              class="btn btn-revert"
            >Reset</button>
          </div>
        </form>
      </div>
    </PopupWrapper>
  </div>
</template>

<script>
import PopupWrapper from "./PopupWrapper";

export default {
  name: "ResetSettingsPopup",
  components: {PopupWrapper},
  methods: {
    hide() {
      this.$emit("hide-popup")
    },
    handlerResetPopup() {
      this.$emit("handlerResetPopup")
      this.hide()
    },
  },
}
</script>

<style scoped lang="scss">
.content-box {
  width: 588px;
}

h1 {
  text-align: center;
  margin-bottom: 10px;
}

.subtitle {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: var(--light-gray);
  margin-bottom: 23px;
}

.holder-attention {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 366px;
  margin: 0 auto;
  padding: 25px 36px;
  background: var(--active-accent-color);
  border-radius: 6px;
  color: var(--accent-color);
  margin-bottom: 24px;
  svg {
    margin-bottom: 15px;
  }
  p {
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    span {
      font-size: 18px;
      font-weight: 700;
    }
  }
}

.popup-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

</style>