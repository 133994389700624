<template>
  <section class="messages-section simple-filter">
    <div class="top-bar">
      <div class="tab">
        <div class="tab-wrapper">
          <router-link
            :to="{ name: 'Case messages mobile list' }"
            tag="button"
            id="messages_mobile_tab"
            exact-path
            class="tab-btn"
          >
            <span class="tab-btn-text">Mobile chats</span>
          </router-link>
          <router-link
            :to="{ name: 'Case messages email list' }"
            tag="button"
            id="messages_email_tab"
            exact-path
            class="tab-btn"
          >
            <span class="tab-btn-text">Email</span>
          </router-link>
          <router-link
            :to="{ name: 'Case messages media list' }"
            tag="button"
            id="messages_media_tab"
            exact-path
            class="tab-btn"
          >
            <span class="tab-btn-text">Media</span>
          </router-link>
          <router-link
            :to="{ name: 'Case messages call logs list' }"
            tag="button"
            id="messages_logs_tab"
            exact-path
            class="tab-btn"
          >
            <span class="tab-btn-text">Call Logs</span>
          </router-link>
          <router-link
            :to="{ name: 'Case messages contacts list' }"
            tag="button"
            id="messages_contacts_tab"
            exact-path
            class="tab-btn"
          >
            <span class="tab-btn-text">Contacts</span>
          </router-link>
          <router-link
            :to="{ name: 'Case messages apps list' }"
            tag="button"
            id="messages_apps_tab"
            exact-path
            class="tab-btn"
          >
            <span class="tab-btn-text">Apps</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="filter-content">
      <div class="nav-chat">
        <div class="dashboard-search">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5" clip-path="url(#clip0_9084_575)">
              <path
                d="M23.0362 21.4823C23.6053 22.0461 23.6053 22.9642 23.0362 23.5334C22.7516 23.8179 22.3811 23.9575 22.0107 23.9575C21.6402 23.9575 21.2697 23.8179 20.9852 23.5334L16.0993 18.6474C16.4859 18.3629 16.851 18.0461 17.1999 17.6971C17.5436 17.3535 17.855 16.9884 18.1395 16.6072L18.2469 16.6877L23.0362 21.4823Z"
                fill="#8692A6"/>
              <path
                d="M17.2056 3.89861C20.674 7.36707 20.9854 12.7953 18.1452 16.6127C17.866 16.9939 17.5546 17.359 17.2056 17.7026C16.8566 18.0516 16.4915 18.3684 16.1049 18.653C12.2874 21.4825 6.86463 21.1657 3.40691 17.7026C-0.405169 13.8906 -0.405169 7.71069 3.40691 3.89861C7.21362 0.0865306 13.3935 0.0865306 17.2056 3.89861ZM15.6861 16.1832C18.6552 13.2087 18.6552 8.39257 15.6861 5.41807C12.7116 2.44895 7.8955 2.44895 4.92101 5.41807C1.94651 8.3872 1.95188 13.2087 4.92101 16.1832C7.89013 19.1523 12.7116 19.1523 15.6861 16.1832Z"
                fill="#8692A6"/>
              <path
                d="M15.686 5.41831C18.6551 8.38744 18.6551 13.2089 15.686 16.1834C12.7115 19.1525 7.89537 19.1525 4.92088 16.1834C1.95175 13.2089 1.95175 8.39281 4.92088 5.41831C7.89 2.44382 12.7115 2.44919 15.686 5.41831Z"
                fill="white"/>
              <path
                d="M18.8544 16.5376C20.1269 14.6477 20.7336 12.3765 20.5779 10.0839C20.4061 7.60873 19.343 5.27315 17.5873 3.51745C15.6437 1.57383 13.0558 0.5 10.3068 0.5C7.55778 0.5 4.96986 1.57383 3.02087 3.51745C1.07724 5.46644 0.00341797 8.04899 0.00341797 10.798C0.00341797 13.547 1.07724 16.1349 3.02087 18.0785C4.77657 19.8342 7.10677 20.8973 9.57657 21.0691C9.81818 21.0852 10.0598 21.096 10.296 21.096C12.3417 21.096 14.3444 20.4893 16.0356 19.351L20.5994 23.9148C20.9752 24.2906 21.4692 24.5 22.0061 24.5C22.5376 24.5 23.037 24.2906 23.4128 23.9201C23.7887 23.5443 23.9981 23.045 23.9981 22.5134C23.9981 21.9819 23.7887 21.4825 23.4128 21.1121L18.8544 16.5376ZM15.7833 18.2181C14.0222 19.5228 11.8477 20.1564 9.65174 20.0007C7.43429 19.845 5.35107 18.8946 3.77791 17.3215C2.03295 15.5819 1.07724 13.2624 1.07724 10.798C1.07724 8.33356 2.03832 6.01946 3.77791 4.2745C5.51751 2.52953 7.83698 1.57383 10.3014 1.57383C12.7658 1.57383 15.0799 2.5349 16.8249 4.2745C18.3981 5.84765 19.3484 7.93624 19.5041 10.1591C19.6544 12.355 19.0209 14.5349 17.7108 16.2906C17.4477 16.645 17.1524 16.994 16.8249 17.3161C16.492 17.6544 16.143 17.955 15.7833 18.2181ZM22.6611 23.1577C22.4893 23.3295 22.2585 23.4262 22.0115 23.4262C21.7645 23.4262 21.539 23.3295 21.3672 23.1577L16.9108 18.7013C17.1417 18.5081 17.3672 18.304 17.5873 18.0839C17.8021 17.8691 18.0115 17.6383 18.2048 17.4074L22.6611 21.8638C22.8329 22.0356 22.9296 22.2611 22.9296 22.5081C22.9296 22.7497 22.8329 22.9805 22.6611 23.1577Z"
                fill="#8692A6"/>
            </g>
            <defs>
              <clipPath id="clip0_9084_575">
                <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
              </clipPath>
            </defs>
          </svg>
          <input
            id="case-apps-search"
            class="search"
            type="text"
            placeholder="Search"
            v-model="contactSearchModel"
          >
        </div>
        <vue-custom-scrollbar
          v-if="contactList.length"
          :settings="settings"
          class="scroll-area"
        >
          <ul class="profile-nav">
            <li
              v-for="(item, index) in contactList"
              :key="index"
              :id="`apps_contact_item_${item.id}`"
              :class="{'active': item.id === currentPublicId}"
              @click="changeContact(item.id)"
            >
              <a class="holder-contacts">
                <div class="contacts-name">
                  <div class="img-item">
                    <img
                      v-if="item.avatar"
                      :src="item.avatar"
                      alt="avatar"
                    >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="12" r="11.5" fill="white" stroke="#082655"/>
                      <path d="M12.0001 4.11523C9.5477 4.11523 7.54297 6.11996 7.54297 8.57238C7.54297 11.0248 9.5477 13.0295 12.0001 13.0295C14.4525 13.0295 16.4573 11.0248 16.4573 8.57238C16.4573 6.11996 14.4525 4.11523 12.0001 4.11523Z" fill="#082655"/>
                      <path d="M20.5349 16.6731C20.4046 16.3451 20.231 16.0389 20.0355 15.7546C19.0367 14.2675 17.4951 13.2834 15.758 13.0428C15.5408 13.0209 15.302 13.0646 15.1283 13.1959C14.2163 13.8738 13.1306 14.2237 12.0015 14.2237C10.8723 14.2237 9.78668 13.8738 8.8747 13.1959C8.70098 13.0646 8.46213 12.9991 8.24501 13.0428C6.50791 13.2834 4.94455 14.2675 3.96744 15.7546C3.77202 16.0389 3.59829 16.367 3.46804 16.6731C3.40291 16.8043 3.42461 16.9574 3.48973 17.0886C3.66346 17.3948 3.88058 17.701 4.076 17.9634C4.37998 18.3789 4.70569 18.7507 5.07484 19.1006C5.37882 19.4068 5.72623 19.6911 6.07368 19.9754C7.78904 21.2657 9.85185 21.9436 11.9798 21.9436C14.1077 21.9436 16.1705 21.2656 17.8859 19.9754C18.2333 19.713 18.5807 19.4068 18.8847 19.1006C19.2321 18.7507 19.5796 18.3789 19.8836 17.9634C20.1007 17.6791 20.2962 17.3948 20.4698 17.0886C20.5784 16.9574 20.6001 16.8043 20.5349 16.6731Z" fill="#082655"/>
                    </svg>
                  </div>
                  <span class="span-name">{{ item.name }}</span>
                </div>
                <div
                  class="message-time"
                  v-text="parseDate(Date.parse(item.date)) === '-' ? '' : parseDate(Date.parse(item.date))"
                />
              </a>
            </li>
          </ul>
        </vue-custom-scrollbar>
        <div v-else class="empty-chats-wrap">
          <span>No results</span>
        </div>
      </div>
      <div class="chat-list">
        <div v-if="!appListEmpty && !loadFlag" class="apps-attached">
          <h4 class="apps-attached-title">Apps attached to this contact:</h4>
          <ul class="apps-attached-list">
            <li
              v-for="(item, index) in appList"
              :key="index"
              v-text="item.app"
              class="apps-attached-list-item"
            />
          </ul>
        </div>
        <div v-else-if="loadFlag" class="loader-bg">
          <div class="loader-p"></div>
        </div>
        <div v-else-if="appListEmpty" class="empty-apps-list">
          <img :src="noAppsImg" alt=""/>
          <span>No Apps</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import vueCustomScrollbar from 'vue-custom-scrollbar';
import settings from "../../../mixins/scrollSettings";
import debounce from '@/mixins/debounce';
import parseDate from "@/mixins/parseDate";
import noAppsImg from "@/assets/img/no-apps-img.png"

export default {
  name: 'CaseMessagesApps',
  components: {
    vueCustomScrollbar,
  },
  emits: ['show-error'],
  mixins: [settings, debounce, parseDate],
  data() {
    return {
      noAppsImg,
      loadFlag: false,
      caseId: this.$route.params.slug,
      currentPublicId: '',
      contactList: [],
      appList: [],
      contactSearchModel: ''
    }
  },
  watch: {
    currentPublicId(newVal) {
      if (newVal !== this.$route.query.publicId) {
        this.$router.replace({query: {publicId: newVal}});
      }
    },
    contactSearchModel(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.debounce(this.getContactList, 300, 'search');
      }
    }
  },
  computed: {
    appListEmpty() {
      return !this.appList || !this.appList.length;
    },
  },
  methods: {
    getContactList(flag = null, publicId = null) {
      if (flag === 'mounted') {
        this.loadFlag = true;
      }

      this.$load(async () => {
        await this.$api.cases
          .getCasesClients(this.caseId, { keySearch: this.contactSearchModel })
          .then((res) => {
            this.contactList = res.data.body.content.map(i => {
              return {
                id: i.publicId,
                name: i.email,
                date: i.lastSyncDate,
                avatar: i.avatar,
                deviceList: i.deviceList
              };
            });

            if (flag === 'mounted') {
              this.currentPublicId = publicId || this.contactList[0].id;
              this.getAppList();
              return;
            }

            this.debounce(() => this.loadFlag = false, 500);
          }, (error) => {
            if (error.response) {
              this.$emit('show-error', error.response.data.errors.message[0]);
            } else {
              this.$emit('show-error', error.data.errors.message[0]);
            }

            this.debounce(() => this.loadFlag = false, 500);
          });
      });
    },
    getAppList() {
      if (!this.currentPublicId) {
        return;
      }
      this.loadFlag = true;
      this.$load(async () => {
        await this.$api.cases
          .getClientApps(this.caseId, this.currentPublicId)
          .then((res) => {
            this.appList = res.data.body.apps;
            this.debounce(() => this.loadFlag = false, 500);
          }, (error) => {
            if (error.response) {
              if (this.currentPublicId && error.response.data.errors.code.includes('RESOURCE_NOT_FOUND')) {
                this.currentPublicId = '';
              }
              this.$emit('show-error', error.response.data.errors.message[0]);
            } else {
              this.$emit('show-error', error.data.errors.message[0]);
            }
            this.debounce(() => this.loadFlag = false, 500);
          });
      });
    },
    changeContact(id) {
      if (id && id !== this.currentPublicId) {
        this.currentPublicId = id;
        this.appList = [];
        this.getAppList();
      }
    }
  },
  mounted() {
    this.getContactList('mounted', this.$route.query.publicId);
  }
};
</script>

<style scoped>
.messages-section {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 27px 55px 27px 35px;
}

.filter-content {
  display: flex;
  width: 100%;
}

.nav-chat {
  padding-top: 120px;
  width: 381px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #D7DCE6;
}

.simple-filter .top-bar {
  padding: 30px 24px;
}

.chat-list {
  width: calc(100% - 381px);
  height: calc(100vh - 200px);
  min-height: 940px;
  overflow: auto;
  margin-top: 120px;
}

.section-no-found img {
  margin-bottom: 30px;
}

.section-no-found p {
  margin-bottom: 18px;
  font-size: 16px;
  font-weight: 500;
  color: var(--light-gray);
}

.top-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  padding: 15px 22px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(77, 106, 147, 0.15);
}

.advanced-filter .top-bar {
  position: static;
  width: 100%;
}

.img-item {
  width: 32px;
  height: 32px;
  overflow: hidden;
  border: 1px solid var(--white-color);
  border-radius: 50%;
}

.empty-chats-wrap {
  text-align: center;
  margin-top: 45px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.tab-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 310px;
  width: 100%;
  min-height: 35px;
  margin-right: 20px;
  border-radius: 79px;
  border: 1px solid var(--case-btn-border-color);
}

.tab-btn {
  margin: -1px 0 -1px 0;
  width: 50%;
  max-height: 35px;
  padding: 10px 30px;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
  border-radius: 79px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  transition: 0.3s all;
}

.tab-btn.active {
  border-color: var(--accent-color);
}

.tab-btn-text {
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: var(--light-gray);
  transition: 0.3s all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-btn.active .tab-btn-text,
.tab-btn:hover .tab-btn-text {
  color: var(--accent-color);
}

.profile-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-top: 0;
  height: calc(700px + 130px)
}

.profile-nav li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all .2s;
}

.profile-nav li:not(:last-child) {
  margin-bottom: 0;
}

.profile-nav li a {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  text-decoration: none;
  padding: 10px 10px 10px 20px;
}

.profile-nav li:hover {
  color: var(--secondary-black-color);
  background-color: rgba(28, 202, 205, 0.05);
}

.profile-nav li.active {
  color: var(--secondary-black-color);
  background-color: rgba(28, 202, 205, 0.05);
}

.contacts-name {
  width: calc(100% - 85px);
  display: flex;
  align-items: center;
}

.img-item {
  width: 24px;
  height: 24px;
  overflow: hidden;
  border: 1px solid var(--white-color);
  border-radius: 50%;
  margin-right: 8px;
}

.img-item img,
.img-item svg {
  width: 100%;
  height: 100%;
}

.img-item img {
  object-fit: cover;
}

.holder-contacts {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.span-name {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  width: calc(100% - 42px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--secondary-black-color);
}

.search {
  border-radius: 79px;
  border: 1px solid var(--input-placeholder-value-color);
  padding: 0 0 0 44px;
  height: 35px;
  width: 100%;
  outline: none;
}

.dashboard-search {
  position: relative;
  width: auto;
  margin: 0 10px 20px 20px;
}

.search::placeholder {
  color: var(--light-gray);
  font-size: 14px;
}

.dashboard-search svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
}

.apps-attached {
  padding: 0 50px 0 27px;
}

.apps-attached-title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #373C44;
  margin-bottom: 24px;
}

.apps-attached-list {
  padding-left: 24px;
}

.apps-attached-list-item {
  list-style: disc;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #696F79;
}

.apps-attached-list-item:not(:last-child) {
  margin-bottom: 24px;
}

.loader-bg {
  height: 100%;
  transform: none;
  position: static;
}

.message-time {
  text-align: right;
  font-weight: 500;
  font-size: 11px;
  line-height: 19px;
  color: var(--secondary-text-color);
  width: 75px;
  margin-left: 10px;
}

.empty-apps-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 150px auto;
  max-width: 175px;
  width: 100%;
  gap: 40px;
}

.empty-apps-list span {
  font-size: 16px;
  font-weight: 500;
  line-height: 25.6px;
  text-align: center;
  color: var(--light-gray);
}
</style>