<template>
  <div class="wrapper">
    <Sidebar />
    <div class="content-wrapper">
      <HeaderRegistered
        :without-route-name="true"
        :return-path="true"
        :sub-title="caseTitle"
      />
      <main class="main">
        <AlertErrorMsg
            :title="errorMessage"
            class="page-alert-error"
            ref="alert"
        />
        <router-view @show-error="setErrorMessage" />
      </main>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../Sidebar";
import HeaderRegistered from "../../layout/HeaderRegistered";
import AlertErrorMsg from "../../profile/AlertErrorMsg";

export default {
  name: "CaseMessages",
  components: {
    AlertErrorMsg,
    HeaderRegistered,
    Sidebar,
  },
  data() {
    return {
      caseTitle: '',
      errorMessage: "",
    }
  },
  methods: {
    setErrorMessage(error) {
      this.errorMessage = error;
      this.$refs.alert.showAlert();
    },
  },
  mounted() {
    this.caseTitle = localStorage.getItem('title');
  },
}
</script>
<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-querybuilder/styles/material.css";
.e-query-builder {
  margin: 0 auto;
}
</style>