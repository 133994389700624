<template>
  <div class="wrapper">
    <Sidebar/>
    <div class="content-wrapper">
      <HeaderRegistered :returnPath="returnPath" :subTitle="caseTitle"/>
      <main class="main">
        <div class="dashboard-section">
          <CaseAssignList />
        </div>
      </main>
    </div>
  </div>
</template>

<script>

import HeaderRegistered from "../../layout/HeaderRegistered";
import Sidebar from "../../Sidebar";
import CaseAssignList from "./CaseAssignList";
import { computed } from "vue";

export default {
  name: "CaseAssign",
  data() {
    return {
      caseTitle: '',
      returnPath: true,
    }
  },
  computed: {
    providedCaseName() {
      return this.caseTitle
    }
  },
  provide() {
    return {
      caseName: computed(() => this.providedCaseName),
    }
  },
  mounted() {
    this.caseTitle = localStorage.getItem('title')
  },
  components: {
    HeaderRegistered,
    Sidebar,
    CaseAssignList,
  },
}
</script>

<style scoped>

</style>