<template>
  <div class="wrapper">
    <Sidebar/>
    <div class="content-wrapper">
      <HeaderRegistered :returnPath="returnPath" :subTitle="subTitle"/>
      <main class="main">
        <section class="dashboard-section">
          <div class="case-files-table"
          >
            <div class="holder-sorts">
              <div class="holder-search">
                <CustomSearch
                    :id="`search_in_files_case_${caseId}`"
                    :modelValue="searchText"
                    @update:modelValue="searchText = $event"
                />
              </div>
              <date-picker
                  ref="notifications"
                  v-model="dataRange"
                  :id="`sort_by_date_in_files_case_${caseId}`"
                  range
                  :format="'DD/MM/YYYY'"
                  :range-separator="' - '"
                  :editable="false"
                  :input-class="'notifications-input'"
                  :popup-class="'notifications-popup'"
                  :placeholder="'Select Date range'"
              >
                <template #icon-calendar>
                  <svg class="datepicker-icon" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.1452 11.4137C23.3452 13.3528 24.3653 17.5156 22.4238 20.7115C21.2063 22.7157 19.0372 23.948 16.6902 23.9688C14.5905 23.973 12.6114 22.9896 11.3483 21.3145C10.422 20.144 9.92486 18.6921 9.93972 17.2002C9.95437 13.487 12.9724 10.4846 16.6902 10.4846C17.9051 10.4744 19.0998 10.7955 20.1452 11.4137Z" fill="#082655"/>
                    <path d="M20.1445 8.22949V11.4147C19.0991 10.7966 17.9044 10.4754 16.6896 10.4857C12.9717 10.4856 9.95372 13.488 9.93907 17.2013C9.92422 18.6931 10.4213 20.1451 11.3477 21.3156H2.12549C1.24484 21.3156 0.530884 20.6025 0.530884 19.7229V8.22954H20.1445V8.22949Z" fill="white"/>
                    <path d="M20.1445 4.37973V8.22856H0.530884V4.37973C0.530884 3.50017 1.24484 2.78711 2.12549 2.78711H4.3048V4.43283H6.82962V2.78711H13.8193V4.43283H16.3441V2.78711H18.55C19.4306 2.78711 20.1445 3.50012 20.1445 4.37973Z" fill="#082655"/>
                    <path d="M16.3447 2.78757V4.43328H13.8198V2.78757V0.956055H16.3447V2.78757Z" fill="#082655"/>
                    <path d="M6.83018 2.78757V4.43328H4.30542V2.78757V0.956055H6.83018V2.78757Z" fill="#082655"/>
                    <path d="M11.3217 21.8718H2.12615C0.951924 21.8718 0 20.9211 0 19.7483V4.35299C0 3.18023 0.951924 2.22949 2.12615 2.22949H4.30545V3.29124H2.12615C1.53901 3.29124 1.06307 3.76658 1.06307 4.35299V19.7483C1.06307 20.3347 1.53901 20.81 2.12615 20.81H11.3217V21.8718Z" fill="#082655"/>
                    <path d="M20.7558 11.175V4.35327C20.7558 3.18534 19.7193 2.36248 18.5499 2.36248H16.7693V0.929186C16.8011 0.726418 16.6624 0.536301 16.4594 0.504496C16.4212 0.498501 16.3822 0.498501 16.3441 0.504496H13.8193C13.5269 0.504496 13.3143 0.637202 13.3143 0.929186V2.36253H7.46743V0.929186C7.46743 0.637202 7.12192 0.504496 6.82958 0.504496H4.30481C4.01247 0.504496 3.74672 0.637202 3.74672 0.929186V4.43293C3.75898 4.74021 3.99812 4.99038 4.30481 5.0169H6.82963C7.15306 4.99577 7.41829 4.75291 7.46748 4.43293V3.42428H13.3144V4.43293C13.2988 4.73127 13.5215 4.98886 13.8194 5.0169H16.3442C16.6365 5.0169 16.7694 4.72492 16.7694 4.43293V3.42428H18.55C19.1347 3.42428 19.6928 3.76935 19.6928 4.35332V7.67127H0.557449V8.73302H19.6928V10.5911C16.0518 8.91973 11.7437 10.5128 10.0703 14.1492C9.62624 15.1142 9.39997 16.1649 9.40755 17.227C9.43649 21.2317 12.6799 24.471 16.6896 24.4999C20.7113 24.5152 23.984 21.2714 23.9993 17.2547C24.0086 14.8128 22.7903 12.5293 20.7558 11.175ZM6.40436 3.95515H4.80975V1.56624H6.40436V3.95515ZM15.7062 3.95515H14.3774V1.56624H15.7062V3.95515ZM16.6896 23.4382C14.7517 23.4342 12.9255 22.532 11.7463 20.9962C9.662 18.2697 10.1854 14.3718 12.9154 12.2901C15.6453 10.2084 19.548 10.7312 21.6323 13.4577C23.7166 16.1843 23.1931 20.0821 20.4632 22.1638C19.3793 22.9904 18.0534 23.4381 16.6896 23.4382Z" fill="#082655"/>
                    <path d="M19.1884 18.2886H16.3447C16.0511 18.2886 15.8132 18.0509 15.8132 17.7577V14.0416C15.8132 13.7484 16.0511 13.5107 16.3447 13.5107C16.6383 13.5107 16.8762 13.7484 16.8762 14.0416V17.2269H19.1884C19.4669 17.2118 19.7049 17.4251 19.72 17.7032C19.7209 17.7214 19.7209 17.7395 19.72 17.7577C19.72 18.0509 19.482 18.2886 19.1884 18.2886Z" fill="white"/>
                  </svg>
                </template>
                <template #icon-clear>
                  <svg class="datepicker-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.0001 0.333496C18.4517 0.333496 23.6667 5.5485 23.6667 12.0002C23.6667 18.4518 18.4517 23.6668 12.0001 23.6668C5.54841 23.6668 0.333412 18.4518 0.333412 12.0002C0.333412 5.5485 5.54841 0.333496 12.0001 0.333496ZM6.16675 16.1885L7.81175 17.8335L12.0001 13.6452L16.1884 17.8335L17.8334 16.1885L13.6451 12.0002L17.8334 7.81183L16.1884 6.16683L12.0001 10.3552L7.81175 6.16683L6.16675 7.81183L10.3551 12.0002L6.16675 16.1885Z"
                      fill="#082655"/>
                  </svg>
                </template>
              </date-picker>
            </div>
              <table class="raz" cellpadding="0" cellspacing="0" border="0">
                <TableHeader
                    v-if="casesFiles"
                    class="table-header"
                >
                  <tr>
<!--                  <th class="holder-checkbox">-->
<!--                    <div class="custom-checkbox-group">-->
<!--                      <div class="checkbox-wrap">-->
<!--                        <input-->
<!--                            id="checkAll_files_in_cases"-->
<!--                            class="input-checkbox"-->
<!--                            type="checkbox"-->
<!--                            v-model="checkAll">-->
<!--                        <label for="checkAll_files_in_cases" class="label-checkbox">-->
<!--                          <span>{{ ' ' }}</span>-->
<!--                        </label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </th>-->
                  <th class="w-200">
                    <div
                        :id="`sort_files_in_case_${caseId}_by_name`"
                        @click="sortedBy('fileName')"
                        class="table-header-box"
                        :class="{
                          'sort-active': this.sortBy === 'fileName',
                          'sorted-desc': this.order === 'desc',
                        }"
                    >
                      <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.78125 9.5625C2.64035 9.5625 2.50523 9.50653 2.4056 9.4069C2.30597 9.30727 2.25 9.17215 2.25 9.03125C2.25 8.89035 2.30597 8.75523 2.4056 8.6556C2.50523 8.55597 2.64035 8.5 2.78125 8.5H10.2188C10.3596 8.5 10.4948 8.55597 10.5944 8.6556C10.694 8.75523 10.75 8.89035 10.75 9.03125C10.75 9.17215 10.694 9.30727 10.5944 9.4069C10.4948 9.50653 10.3596 9.5625 10.2188 9.5625H2.78125Z" fill="#D7DCE6"/>
                        <path d="M2.25 11.1562C2.25 11.0154 2.30597 10.8802 2.4056 10.7806C2.50523 10.681 2.64035 10.625 2.78125 10.625H10.2188C10.3596 10.625 10.4948 10.681 10.5944 10.7806C10.694 10.8802 10.75 11.0154 10.75 11.1562C10.75 11.2971 10.694 11.4323 10.5944 11.5319C10.4948 11.6315 10.3596 11.6875 10.2188 11.6875H2.78125C2.64035 11.6875 2.50523 11.6315 2.4056 11.5319C2.30597 11.4323 2.25 11.2971 2.25 11.1562Z" fill="#D7DCE6"/>
                        <path d="M2.78125 13.8125C2.64035 13.8125 2.50523 13.7565 2.4056 13.6569C2.30597 13.5573 2.25 13.4221 2.25 13.2812C2.25 13.1404 2.30597 13.0052 2.4056 12.9056C2.50523 12.806 2.64035 12.75 2.78125 12.75H7.03125C7.17215 12.75 7.30727 12.806 7.4069 12.9056C7.50653 13.0052 7.5625 13.1404 7.5625 13.2812C7.5625 13.4221 7.50653 13.5573 7.4069 13.6569C7.30727 13.7565 7.17215 13.8125 7.03125 13.8125H2.78125Z" fill="#D7DCE6"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.87381 0H2.25C1.68641 0 1.14591 0.223883 0.747398 0.622398C0.348883 1.02091 0.125 1.56141 0.125 2.125V14.875C0.125 15.4386 0.348883 15.9791 0.747398 16.3776C1.14591 16.7761 1.68641 17 2.25 17H10.75C11.3136 17 11.8541 16.7761 12.2526 16.3776C12.6511 15.9791 12.875 15.4386 12.875 14.875V5.00119C12.8749 4.71942 12.763 4.44921 12.5637 4.25L8.625 0.311313C8.42579 0.112041 8.15558 6.01772e-05 7.87381 0ZM8.09375 1.59375V3.71875C8.09375 4.00054 8.20569 4.27079 8.40495 4.47005C8.60421 4.66931 8.87446 4.78125 9.15625 4.78125H11.2812L8.09375 1.59375ZM2.4056 9.4069C2.50523 9.50653 2.64035 9.5625 2.78125 9.5625H10.2188C10.3596 9.5625 10.4948 9.50653 10.5944 9.4069C10.694 9.30727 10.75 9.17215 10.75 9.03125C10.75 8.89035 10.694 8.75523 10.5944 8.6556C10.4948 8.55597 10.3596 8.5 10.2188 8.5H2.78125C2.64035 8.5 2.50523 8.55597 2.4056 8.6556C2.30597 8.75523 2.25 8.89035 2.25 9.03125C2.25 9.17215 2.30597 9.30727 2.4056 9.4069ZM2.4056 10.7806C2.30597 10.8802 2.25 11.0154 2.25 11.1562C2.25 11.2971 2.30597 11.4323 2.4056 11.5319C2.50523 11.6315 2.64035 11.6875 2.78125 11.6875H10.2188C10.3596 11.6875 10.4948 11.6315 10.5944 11.5319C10.694 11.4323 10.75 11.2971 10.75 11.1562C10.75 11.0154 10.694 10.8802 10.5944 10.7806C10.4948 10.681 10.3596 10.625 10.2188 10.625H2.78125C2.64035 10.625 2.50523 10.681 2.4056 10.7806ZM2.4056 13.6569C2.50523 13.7565 2.64035 13.8125 2.78125 13.8125H7.03125C7.17215 13.8125 7.30727 13.7565 7.4069 13.6569C7.50653 13.5573 7.5625 13.4221 7.5625 13.2812C7.5625 13.1404 7.50653 13.0052 7.4069 12.9056C7.30727 12.806 7.17215 12.75 7.03125 12.75H2.78125C2.64035 12.75 2.50523 12.806 2.4056 12.9056C2.30597 13.0052 2.25 13.1404 2.25 13.2812C2.25 13.4221 2.30597 13.5573 2.4056 13.6569Z" fill="#D7DCE6"/>
                      </svg>
                      <span class="header-table-text">File Name</span>
                      <div class="arrow-icon">
                        <arrowDown class="arrow" />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                        :id="`sort_files_in_case_${caseId}_by_size`"
                        @click="sortedBy('size')"
                        class="table-header-box"
                        :class="{
                          'sort-active': this.sortBy === 'size',
                          'sorted-desc': this.order === 'desc',
                        }"
                    >
                      <CaseIcon />
                      <span class="header-table-text">size</span>
                      <div class="arrow-icon">
                        <arrowDown class="arrow" />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div class="device">
                      <div class="table-header-box table-header-box-doesnt-hover">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M13.4737 3.2H6.73684C6.5135 3.2 6.29931 3.28429 6.14138 3.43431C5.98346 3.58434 5.89474 3.78783 5.89474 4V14.4H0.842105C0.618765 14.4 0.404572 14.3157 0.246647 14.1657C0.0887215 14.0157 0 13.8122 0 13.6V0.8C0 0.587827 0.0887215 0.384344 0.246647 0.234315C0.404572 0.0842854 0.618765 0 0.842105 0H12.6316C12.8549 0 13.0691 0.0842854 13.227 0.234315C13.385 0.384344 13.4737 0.587827 13.4737 0.8V3.2ZM8.42105 4.8H15.1579C15.3812 4.8 15.5954 4.88429 15.7534 5.03431C15.9113 5.18434 16 5.38783 16 5.6V15.2C16 15.4122 15.9113 15.6157 15.7534 15.7657C15.5954 15.9157 15.3812 16 15.1579 16H8.42105C8.19771 16 7.98352 15.9157 7.82559 15.7657C7.66767 15.6157 7.57895 15.4122 7.57895 15.2V5.6C7.57895 5.38783 7.66767 5.18434 7.82559 5.03431C7.98352 4.88429 8.19771 4.8 8.42105 4.8Z"
                              fill="#D7DCE6"/>
                        </svg>
                        <span class="header-table-text">device</span>
                      </div>
                    </div>
                  </th>
                  <th class="w-300">
                    <div class="table-header-box table-header-box-doesnt-hover">
                      <CaseIcon />
                      <span class="header-table-text">owner</span>
                    </div>
                  </th>
                  <th>
                    <div
                        :id="`sort_files_in_case_${caseId}_by_date`"
                        @click="sortedBy('actionDate')"
                         class="table-header-box"
                        :class="{
                          'sort-active': this.sortBy === 'actionDate',
                          'sorted-desc': this.order === 'desc',
                        }"
                    >
                      <svg data-v-f83a7c74="" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path data-v-f83a7c74="" d="M3.0625 0.5C3.17853 0.5 3.28981 0.549386 3.37186 0.637294C3.45391 0.725201 3.5 0.84443 3.5 0.96875V1.4375H10.5V0.96875C10.5 0.84443 10.5461 0.725201 10.6281 0.637294C10.7102 0.549386 10.8215 0.5 10.9375 0.5C11.0535 0.5 11.1648 0.549386 11.2469 0.637294C11.3289 0.725201 11.375 0.84443 11.375 0.96875V1.4375H12.25C12.7141 1.4375 13.1592 1.63504 13.4874 1.98667C13.8156 2.33831 14 2.81522 14 3.3125V13.625C14 14.1223 13.8156 14.5992 13.4874 14.9508C13.1592 15.3025 12.7141 15.5 12.25 15.5H1.75C1.28587 15.5 0.840752 15.3025 0.512563 14.9508C0.184374 14.5992 0 14.1223 0 13.625V5.1875H14V4.25H0V3.3125C0 2.81522 0.184374 2.33831 0.512563 1.98667C0.840752 1.63504 1.28587 1.4375 1.75 1.4375H2.625V0.96875C2.625 0.84443 2.67109 0.725201 2.75314 0.637294C2.83519 0.549386 2.94647 0.5 3.0625 0.5V0.5Z" fill="#D7DCE6"></path>
                      </svg>
                      <span class="header-table-text">date</span>
                      <div class="arrow-icon">
                        <arrowDown class="arrow" />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                        :id="`sort_files_in_case_${caseId}_by_status`"
                        @click="sortedBy('status')"
                        class="table-header-box"
                        :class="{
                          'sort-active': this.sortBy === 'status',
                          'sorted-desc': this.order === 'desc',
                        }"
                    >
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 16C6.55208 16 5.21354 15.6432 3.98438 14.9297C2.75521 14.2161 1.78385 13.2448 1.07031 12.0156C0.356771 10.7865 0 9.44792 0 8C0 6.55208 0.356771 5.21354 1.07031 3.98438C1.78385 2.75521 2.75521 1.78385 3.98438 1.07031C5.21354 0.356771 6.55208 0 8 0C9.44792 0 10.7865 0.356771 12.0156 1.07031C13.2448 1.78385 14.2161 2.75521 14.9297 3.98438C15.6432 5.21354 16 6.55208 16 8C16 9.44792 15.6432 10.7865 14.9297 12.0156C14.2161 13.2448 13.2448 14.2161 12.0156 14.9297C10.7865 15.6432 9.44792 16 8 16ZM1 8C1 8.94792 1.17969 9.84896 1.53906 10.7031C1.89844 11.5573 2.40104 12.3073 3.04688 12.9531L8 8V1C7.05208 1 6.14583 1.1849 5.28125 1.55469C4.41667 1.92448 3.67188 2.42188 3.04688 3.04688C2.42188 3.67188 1.92448 4.41667 1.55469 5.28125C1.1849 6.14583 1 7.05208 1 8Z" fill="#D7DCE6"/>
                      </svg>

                      <span class="header-table-text">status</span>
                      <div class="arrow-icon">
                        <arrowDown class="arrow" />
                      </div>
                    </div>
                  </th>
                  <th class="w-200"></th>
                </tr>
                </TableHeader>
                <Loader v-if="loadFlag" :load-flag="loadFlag" />
                <template v-else>
                  <CaseFilesItem
                      v-if="casesFiles"
                      :casesFiles="casesFiles"
                      @download="$refs.downloadFiles.openPopup()"
                  />
                  <p class="no-found" v-else>Files are absent</p>
                </template>
              </table>
            <pagination v-if="casesFiles && casesFiles.length && count !== 1"
                        v-model="page"
                        :total-pages="count"
                        :link-gen="linkGen"
            ></pagination>
            <DownloadFilesPopup
                v-if="casesFiles"
                :casesFiles="casesFiles" ref="downloadFiles"
            />
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import linkGenerator from "../../../constants/linkGen";
import CaseFilesItem from "./CaseFilesItem";
import DownloadFilesPopup from "../../popups/DownloadFilesPopup";
import Sidebar from "../../Sidebar";
import HeaderRegistered from "../../layout/HeaderRegistered";
import TableHeader from "../../table/TableHeader";
import CustomSearch from "../../inputs/CustomSearch";
import Loader from "../../loader/Loader";
import DatePicker from "vue2-datepicker";
import ArrowDown from "../../icons/ArrowDown";
import CaseIcon from "../../icons/CaseIcon";
import Pagination from "@/components/common/Pagination.vue";

export default {
  name: "CaseFilesTable",
  components: {
    Pagination,
    CaseIcon,
    ArrowDown,
    DatePicker,
    Loader,
    CustomSearch,
    HeaderRegistered,
    Sidebar,
    TableHeader,
    CaseFilesItem,
    DownloadFilesPopup
  },
  data() {
    return {
      loadFlag: false,
      returnPath: true,
      caseTitle: '',
      casesFiles: [],
      caseId: this.$route.params.slug,
      searchText: "",
      roles: '',
      sortBy: 'actionDate',
      order: 'desc',
      page: 1,
      size: 10,
      count: 0,
      pageSize: 2,
      dateFrom: '',
      dateTo: '',
      dataRange: '',
      errorMessage: '',
    }
  },
  watch: {
    searchText() {
      this.getFiles()
    },
    '$route.path'() {
      this.dataRange = ""
    },
    dataRange(value) {
      this.dateFrom = isNaN(Date.parse(value[0])) == false ? Date.parse(value[0]) : ''
      this.dateTo = isNaN(Date.parse(value[1])) == false ? Date.parse(value[1]) : ''
      this.page = 1
      this.getFiles()
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.page = newVal
          this.getFiles()
        } else {
          this.page = 1
        }
      }
    },
  },
  methods: {
    linkGen(pageNum) {
      return linkGenerator(pageNum, `/cases/${this.caseId}/files`)
    },
    sortedBy(filterName) {
      this.order = filterName === this.sortBy && this.order === 'desc' ? 'asc' : 'desc'
      this.sortBy = filterName
      this.getFiles()
    },
    fixedEncodeURIComponent (str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    },
    getFiles() {
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.cases.getCasesFiles({
          caseId: this.caseId,
          sortBy: this.sortBy,
          keySearch: this.fixedEncodeURIComponent(this.searchText),
          page: this.page,
          size: this.size,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          order: this.order,
        }).then((res) => {
              this.casesFiles = res.data.body.content.map(i => {
                i.isActive = false
                return i
              })
              this.count = res.data.body.totalPages
              this.pageSize = res.data.body.size
            }, (error => {
              if (error.response) {
                this.errorMessage = error.response.data.errors.message[0]
              } else {
                this.errorMessage = error.data.errors.message[0]
              }
            })
        )
      })
      setTimeout(() => {
        this.loadFlag = false
      }, 1000)
    },
  },
  computed: {
    checkAll: {
      get() {
        if (this.casesFiles.length) {
          for (let i = 0; i < this.casesFiles.length; i++) {
            if (!this.casesFiles[i].isActive) return false;
          }
          return true;
        }
        return false
      },
      set(value) {
        this.casesFiles.forEach(function (item) {
          item.isActive = value;
        })
      }
    },
    subTitle() {
      return ` in ${this.caseTitle}`
    }
  },
  mounted() {
    this.page = this.$route.query.page || 1
    this.getFiles()
    this.caseTitle = localStorage.getItem('title')
  }

}
</script>

<style scoped>
.holder-sorts {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 30px;
}

.holder-search {
  margin-right: 18px;
}

.table-header-box svg {
  min-width: 16px;
}

.table-header-box svg path {
  fill: var(--input-placeholder-value-color);
  transition: all 0.3s;
}

.table-header-box:hover svg:first-child path {
  fill: var(--accent-color);
}

.arrow-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-icon svg path {
  fill: var(--light-gray);
}

</style>
