export default function(instance) {
  return {
    getClientContacts(caseId, clientId, params) {
      return instance.get(`/clientContact/case/${caseId}/client/${clientId}/contacts`, {params})
    },
    getClientContactsAdvanced(caseId, clientId, data, page, size, count) {
      return instance.post(`/clientContact/case/${caseId}/client/${clientId}/contacts/advanced?page=${page}&size=${size}&count=${count}`, data)
    },
    getFilterRules(caseId, clientId) {
      return instance.get(`/settings/search/case/${caseId}/client/${clientId}/contacts`);
    },
  }
}