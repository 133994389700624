<template>
  <div
      v-if="unsupportedFormat"
      @click="$emit('downloadFile', attachment)"
      :data-after-content="'.' + attachmentFormat"
      class="application attachment"
  >
    <div class="application-wrap" :data-after-content="'.' + attachmentFormat">
      <div class="circle">
        <svg width="22" height="30" viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9636 0.884766V7.92454C10.9636 8.72471 11.2815 9.49211 11.8473 10.0579C12.4131 10.6237 13.1805 10.9416 13.9807 10.9416H21.0205V26.0268C21.0205 26.827 20.7026 27.5944 20.1368 28.1602C19.571 28.726 18.8036 29.0439 18.0034 29.0439H3.92388C3.1237 29.0439 2.35631 28.726 1.7905 28.1602C1.2247 27.5944 0.90683 26.827 0.90683 26.0268V3.90181C0.90683 3.10164 1.2247 2.33424 1.7905 1.76844C2.35631 1.20263 3.1237 0.884766 3.92388 0.884766H10.9636ZM12.975 1.38761V7.92454C12.975 8.19126 13.081 8.44706 13.2696 8.63566C13.4582 8.82426 13.714 8.93022 13.9807 8.93022H20.5176L12.975 1.38761Z" fill="#082655"/>
        </svg>
      </div>
    </div>
  </div>
  <div
      v-else
      :class="[{'application': unsupportedFormat  || withoutPreview || audioWithoutPreview, 'recognised-text-visible': recognisedTextVisible, 'with-recognition-text': withRecognitionText}, attachmentType]"
      :data-after-content="isPaused ? '.' + attachmentFormat : 'playing'"
      class="attachment"
  >
    <div class="application-wrap"
         :data-after-content="isPaused ? '.' + attachmentFormat : 'playing'"
         v-if="attachmentType === 'application' || unsupportedFormat || withoutPreview || audioWithoutPreview"
         @click="$emit('downloadFile', attachment)"
    >
      <div class="circle">
        <svg width="22" height="30" viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9636 0.884766V7.92454C10.9636 8.72471 11.2815 9.49211 11.8473 10.0579C12.4131 10.6237 13.1805 10.9416 13.9807 10.9416H21.0205V26.0268C21.0205 26.827 20.7026 27.5944 20.1368 28.1602C19.571 28.726 18.8036 29.0439 18.0034 29.0439H3.92388C3.1237 29.0439 2.35631 28.726 1.7905 28.1602C1.2247 27.5944 0.90683 26.827 0.90683 26.0268V3.90181C0.90683 3.10164 1.2247 2.33424 1.7905 1.76844C2.35631 1.20263 3.1237 0.884766 3.92388 0.884766H10.9636ZM12.975 1.38761V7.92454C12.975 8.19126 13.081 8.44706 13.2696 8.63566C13.4582 8.82426 13.714 8.93022 13.9807 8.93022H20.5176L12.975 1.38761Z" fill="#082655"/>
        </svg>
      </div>
    </div>
    <div v-if="withoutPreview" class="application-recognised-text-wrap">
      <button v-if="!recognisedTextVisible && textVersion && withRecognitionText" @click="toggleRecognisedText" class="recognised-text-btn">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.21334 8.91726L6.21335 8.91726L6.21146 8.91552C6.20757 8.91192 6.20323 8.90503 6.20281 8.89419C6.20261 8.88918 6.20344 8.88529 6.20433 8.88282C6.20507 8.88079 6.20601 8.87916 6.20787 8.8772L6.20799 8.87732L6.21654 8.86784C6.22135 8.86251 6.22778 8.85918 6.23626 8.85881C6.24039 8.85863 6.24359 8.8593 6.24562 8.86004C6.24724 8.86063 6.24881 8.86146 6.2509 8.86344L6.25088 8.86346L6.25444 8.86677L9.59074 11.9749C9.59466 11.9789 9.59882 11.9847 9.60176 11.9913C9.60372 11.9958 9.60434 11.9988 9.60453 11.9999C9.60434 12.0011 9.60372 12.0041 9.60173 12.0087C9.5988 12.0153 9.59466 12.0211 9.59074 12.025L6.25574 15.132C6.25566 15.132 6.25558 15.1321 6.25549 15.1322C6.25036 15.1368 6.24342 15.14 6.23347 15.1396C6.22445 15.1393 6.21662 15.1362 6.20992 15.1301C6.20642 15.1258 6.20494 15.1226 6.20419 15.1206C6.20319 15.1178 6.20266 15.1147 6.2028 15.1112C6.20304 15.105 6.20545 15.0958 6.21537 15.0859L8.56381 12.9074L9.49611 12.0425H8.22442H2.42749C2.42025 12.0425 2.41264 12.04 2.40533 12.0327C2.39802 12.0254 2.39551 12.0177 2.39551 12.0105C2.39551 12.0034 2.39796 11.9959 2.40529 11.9885C2.41261 11.9812 2.42024 11.9787 2.42749 11.9787H8.22442H9.48928L8.56515 11.1151L6.21334 8.91726Z" fill="#696F79" stroke="#696F79" stroke-width="0.998048"/>
          <path d="M12.1035 18.5V5.5H16.346C17.3269 5.5 18.1321 5.6735 18.7616 6.02051C19.391 6.36328 19.8569 6.83512 20.1594 7.43604C20.4618 8.03695 20.6131 8.72038 20.6131 9.48633C20.6131 10.2523 20.4618 10.9315 20.1594 11.5239C19.8569 12.1164 19.393 12.5819 18.7677 12.9204C18.1423 13.2547 17.3433 13.4219 16.3705 13.4219H12.9373V12H16.3215C16.9918 12 17.5313 11.8984 17.94 11.6953C18.3528 11.4922 18.6512 11.2044 18.8351 10.832C19.0231 10.4554 19.1171 10.0068 19.1171 9.48633C19.1171 8.96582 19.0231 8.5109 18.8351 8.12158C18.6471 7.73226 18.3467 7.4318 17.9339 7.22022C17.5211 7.00439 16.9755 6.89648 16.297 6.89648H13.624V18.5H12.1035ZM18.0136 12.6602L21.1035 18.5H19.3378L16.297 12.6602H18.0136Z" fill="#696F79"/>
        </svg>
      </button>
      <button v-if="recognisedTextVisible && textVersion && withRecognitionText" @click="toggleRecognisedText" class="recognised-text-btn">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_12433_26892)">
            <path d="M19.5 16.1855L12 8.68555L4.5 16.1855" stroke="#696F79" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_12433_26892">
              <rect width="24" height="24" fill="white" transform="translate(0 0.435547)"/>
            </clipPath>
          </defs>
        </svg>
      </button>
      <div v-if="recognisedTextVisible" class="recognised-text-wrap">
        <div class="recognised-text-content">
          <p>{{textVersion}}</p>
        </div>
      </div>
    </div>
    <img
        v-else-if="attachmentType === 'image'"
        :src="attachment.link"
        alt="attachment"
        @click="openImage"
    >
    <div class="video-wrap" v-else-if="attachmentType === 'video'">
      <div class="player-wrap">
        <vue-plyr>
          <video
              @pause="isPaused = true"
              @play="isPaused = false"
          >
            <source
                :blankVideo="attachment.link"
                :src="attachment.link"
                class="video"
            />
          </video>
        </vue-plyr>
        <button v-if="!recognisedTextVisible && textVersion && withRecognitionText" @click="toggleRecognisedText" class="recognised-text-btn show-text">
          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
            <path d="M4.71334 1.41824L4.71335 1.41824L4.71146 1.41649C4.70757 1.41289 4.70323 1.40601 4.70281 1.39517C4.70261 1.39016 4.70344 1.38627 4.70433 1.3838C4.70507 1.38177 4.70601 1.38013 4.70787 1.37818L4.70799 1.37829L4.71654 1.36881C4.72135 1.36349 4.72778 1.36016 4.73626 1.35979C4.74039 1.35961 4.74359 1.36028 4.74562 1.36102C4.74724 1.36161 4.74881 1.36243 4.7509 1.36442L4.75088 1.36443L4.75444 1.36775L8.09074 4.47591C8.09466 4.47984 8.09882 4.48563 8.10176 4.49231C8.10372 4.49676 8.10434 4.49976 8.10453 4.5009C8.10434 4.50206 8.10372 4.50511 8.10173 4.50963C8.0988 4.5163 8.09466 4.52207 8.09074 4.52599L4.75574 7.63295C4.75566 7.63302 4.75558 7.6331 4.75549 7.63318C4.75036 7.63782 4.74342 7.64094 4.73347 7.6406C4.72445 7.64029 4.71662 7.63721 4.70992 7.63105C4.70642 7.6268 4.70494 7.62359 4.70419 7.62154C4.70319 7.61879 4.70266 7.6157 4.7028 7.61221C4.70304 7.60596 4.70545 7.59682 4.71537 7.58692L7.06381 5.40833L7.99611 4.54346H6.72442H0.92749C0.920248 4.54346 0.912641 4.54095 0.905331 4.53364C0.898021 4.52633 0.895509 4.51872 0.895509 4.51148C0.895509 4.50441 0.897959 4.49685 0.905289 4.48952C0.912614 4.4822 0.920242 4.47968 0.92749 4.47968H6.72442H7.98928L7.06515 3.61606L4.71334 1.41824Z" fill="#696F79" stroke="#696F79" stroke-width="0.998048"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 10 13" fill="none">
            <path d="M0.603516 13V0H4.84602C5.82695 0 6.63213 0.173503 7.26155 0.520508C7.89098 0.863281 8.35692 1.33512 8.65937 1.93604C8.96183 2.53695 9.11305 3.22038 9.11305 3.98633C9.11305 4.75228 8.96183 5.43148 8.65937 6.02393C8.35692 6.61637 7.89302 7.08187 7.26768 7.42041C6.64234 7.75472 5.8433 7.92188 4.87055 7.92188H1.4373V6.5H4.8215C5.4918 6.5 6.03131 6.39844 6.44003 6.19531C6.85283 5.99219 7.1512 5.70443 7.33512 5.33203C7.52313 4.9554 7.61714 4.50684 7.61714 3.98633C7.61714 3.46582 7.52313 3.0109 7.33512 2.62158C7.14711 2.23226 6.8467 1.9318 6.4339 1.72022C6.02109 1.50439 5.47545 1.39648 4.79698 1.39648H2.12395V13H0.603516ZM6.5136 7.16016L9.60352 13H7.83785L4.79698 7.16016H6.5136Z" fill="#696F79"/>
          </svg>
        </button>
        <button v-if="recognisedTextVisible && textVersion && withRecognitionText" @click="toggleRecognisedText" class="recognised-text-btn">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_12433_26892)">
              <path d="M19.5 16.1855L12 8.68555L4.5 16.1855" stroke="#696F79" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_12433_26892">
                <rect width="24" height="24" fill="white" transform="translate(0 0.435547)"/>
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
      <div v-if="recognisedTextVisible" class="recognised-text-wrap">
        <div class="recognised-text-content">
          <p>{{textVersion}}</p>
        </div>
      </div>
    </div>
    <div class="audio-wrap" v-else-if="attachmentType === 'audio' && !audioWithoutPreview">
      <div class="player-wrap">
        <vue-plyr>
          <audio>
            <source
                :autopause="true"
                :src="attachment.link"
                type="audio/mp3"
            />
          </audio>
        </vue-plyr>
        <button v-if="!recognisedTextVisible && textVersion && withRecognitionText" @click="toggleRecognisedText" class="recognised-text-btn show-text">
          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
            <path d="M4.71334 1.41824L4.71335 1.41824L4.71146 1.41649C4.70757 1.41289 4.70323 1.40601 4.70281 1.39517C4.70261 1.39016 4.70344 1.38627 4.70433 1.3838C4.70507 1.38177 4.70601 1.38013 4.70787 1.37818L4.70799 1.37829L4.71654 1.36881C4.72135 1.36349 4.72778 1.36016 4.73626 1.35979C4.74039 1.35961 4.74359 1.36028 4.74562 1.36102C4.74724 1.36161 4.74881 1.36243 4.7509 1.36442L4.75088 1.36443L4.75444 1.36775L8.09074 4.47591C8.09466 4.47984 8.09882 4.48563 8.10176 4.49231C8.10372 4.49676 8.10434 4.49976 8.10453 4.5009C8.10434 4.50206 8.10372 4.50511 8.10173 4.50963C8.0988 4.5163 8.09466 4.52207 8.09074 4.52599L4.75574 7.63295C4.75566 7.63302 4.75558 7.6331 4.75549 7.63318C4.75036 7.63782 4.74342 7.64094 4.73347 7.6406C4.72445 7.64029 4.71662 7.63721 4.70992 7.63105C4.70642 7.6268 4.70494 7.62359 4.70419 7.62154C4.70319 7.61879 4.70266 7.6157 4.7028 7.61221C4.70304 7.60596 4.70545 7.59682 4.71537 7.58692L7.06381 5.40833L7.99611 4.54346H6.72442H0.92749C0.920248 4.54346 0.912641 4.54095 0.905331 4.53364C0.898021 4.52633 0.895509 4.51872 0.895509 4.51148C0.895509 4.50441 0.897959 4.49685 0.905289 4.48952C0.912614 4.4822 0.920242 4.47968 0.92749 4.47968H6.72442H7.98928L7.06515 3.61606L4.71334 1.41824Z" fill="#696F79" stroke="#696F79" stroke-width="0.998048"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 10 13" fill="none">
            <path d="M0.603516 13V0H4.84602C5.82695 0 6.63213 0.173503 7.26155 0.520508C7.89098 0.863281 8.35692 1.33512 8.65937 1.93604C8.96183 2.53695 9.11305 3.22038 9.11305 3.98633C9.11305 4.75228 8.96183 5.43148 8.65937 6.02393C8.35692 6.61637 7.89302 7.08187 7.26768 7.42041C6.64234 7.75472 5.8433 7.92188 4.87055 7.92188H1.4373V6.5H4.8215C5.4918 6.5 6.03131 6.39844 6.44003 6.19531C6.85283 5.99219 7.1512 5.70443 7.33512 5.33203C7.52313 4.9554 7.61714 4.50684 7.61714 3.98633C7.61714 3.46582 7.52313 3.0109 7.33512 2.62158C7.14711 2.23226 6.8467 1.9318 6.4339 1.72022C6.02109 1.50439 5.47545 1.39648 4.79698 1.39648H2.12395V13H0.603516ZM6.5136 7.16016L9.60352 13H7.83785L4.79698 7.16016H6.5136Z" fill="#696F79"/>
          </svg>
        </button>
        <button v-if="recognisedTextVisible && textVersion && withRecognitionText" @click="toggleRecognisedText" class="recognised-text-btn">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_12433_26892)">
              <path d="M19.5 16.1855L12 8.68555L4.5 16.1855" stroke="#696F79" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_12433_26892">
                <rect width="24" height="24" fill="white" transform="translate(0 0.435547)"/>
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
      <div v-if="recognisedTextVisible" class="recognised-text-wrap">
        <div class="recognised-text-content">
          <p>{{textVersion}}</p>
        </div>
      </div>
    </div>
    <CommonDialog class="image-popup" ref="imagePopup">
      <img
          class="popup-image"
          :src="attachment.link"
          alt="attachment"
      >
    </CommonDialog>
  </div>
</template>
<script>
import CommonDialog from '../popups/CommonDialog';

export default {
  name: 'Attachments',
  components: { CommonDialog },
  props: {
    attachment: {
      type: Object,
    },
    withoutPreview: {
      type: Boolean,
    },
    withoutAudioPreview: {
      type: Boolean,
    },
    withRecognitionText: {
      type: Boolean,
    }
  },
  data() {
    return {
      isPaused: true,
      attachmentType: null,
      attachmentFormat: null,
      recognisedTextVisible: false,
    };
  },
  computed: {
    unsupportedFormat() {
      switch (this.attachmentFormat) {
        case 'ogg':
        case 'mp4':
        case 'ogv':
        case 'webm':
        case 'mp3':
        case 'wav':
        case 'jpeg':
        case 'jpg':
        case 'png':
        case 'gif':
        case 'webp':
          return false;
        default: return true;
      }
    },
    textVersion() {
      return this.attachment.textVersion;
    },
    audioWithoutPreview() {
      return this.attachmentType === 'audio' && this.withoutAudioPreview;
    }
  },
  methods: {
    getAttachmentType() {
      this.attachmentType = this.attachment.type.split('/')[0].toLowerCase();
      this.attachmentFormat = this.attachment.name.split('.').reverse()[0].toLowerCase();
    },
    openImage() {
      this.$refs.imagePopup.openPopups();
    },
    toggleRecognisedText() {
      this.recognisedTextVisible = !this.recognisedTextVisible;
    },
  },
  mounted() {
    this.getAttachmentType();
  },
};
</script>
<style scoped>
.attachment-wrapper .application,
.attachment-wrapper .image {
  position: relative;
  width: 170px;
  min-height: 115px;
}

.attachment-wrapper .application {
  display: flex;
  align-items: flex-start;
}

.attachment-wrapper .application .application-wrap {
  cursor: pointer;
  background-color: var(--file-background);
  max-width: 170px;
  height: 115px;
  justify-content: center;
}

.attachment-wrapper .application.recognised-text-visible {
  flex-direction: column;
}

.attachment-wrapper .application-recognised-text-wrap .recognised-text-btn {
  position: absolute;
  left: 178px;
  top: 0;
  display: flex;
  align-items: flex-start;
  background-color: #fff;
}

.attachment-wrapper .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
  border-radius: 50%;
  width: 59px;
  height: 59px;
}

.attachment-wrapper .application-wrap:after,
.attachment-wrapper .application:not(.with-recognition-text).image:after,
.attachment-wrapper .application:not(.with-recognition-text).video:after {
  content: attr(data-after-content);
  position: absolute;
  right: 8px;
  bottom: 8px;
  border-radius: 79px;
  padding: 3px 11px;
  background-color: #F8FCFC;
  border: 1px solid #D7DCE6;
  font-size: 12px;
  font-weight: 500;
  line-height: 160%;
  color: #373c44;
}

.attachment-wrapper .video:after {
  top: 150px;
  right: unset;
  bottom: unset;
  left: 270px;
  background-color: #373C44;
  border: none;
  color: #fff;
}

.attachment-wrapper .application-wrap,
.attachment-wrapper .video {
  position: relative;
}

.attachment-wrapper .video[data-after-content="playing"]:after {
  display: none;
}

.attachment-wrapper .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.attachment-wrapper .audio >>> .plyr--full-ui input {
  color: var(--accent-color);
}

.attachment-wrapper .audio >>> .plyr__controls .plyr__control,
.attachment-wrapper .audio >>> .plyr__controls .plyr__time {
  color: var(--case-btn-border-color);
}

.attachment:not(:last-child) {
  margin-bottom: 8px;
}

.attachment {
  margin-right: 12px;
}

.attachment.audio.recognised-text-visible,
.attachment.video.recognised-text-visible {
  width: 100%;
  align-items: flex-start;
}

.attachment.audio >>> .plyr__menu__container {
  background: rgba(255, 255, 255, 1);
}

.attachment.audio >>> .plyr__menu__container [id$=speed] [role=menu] {
  display: flex;
  max-height: 38px;
  max-width: 31vw;
  width: fit-content;
  overflow: auto;
}

.attachment.audio >>> .plyr__menu__container [data-plyr=speed] {
  width: fit-content;
}

.attachment.audio >>> .plyr__menu__container [data-plyr=speed]:not(:last-child) {
  margin-right: 4px;
}

.attachment.audio .plyr__progress .plyr__tooltip {
  display: none !important;
}

.image-popup >>> .common-dialog-wrapper {
  max-width: 700px;
  padding: 55px 10px;
  max-height: 70%;
  height: 100%;
}

.common-dialog-wrapper img.popup-image {
  object-fit: contain;
}

.image-popup >>> .dialog-btn {
  display: none;
}

.application-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.video-wrap, .audio-wrap, .player-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.video-wrap .player-wrap {
  width: 363px;
  height: 185px;
}

.video-wrap >>> .plyr {
  flex-grow: 1;
  border-radius: 8px;
}

.video-wrap >>> .plyr video {
  object-fit: cover;
}

.audio-wrap .player-wrap {
  align-items: center;
}

.audio-wrap >>> .plyr--audio {
 width: 100%;
}

.audio-wrap >>> .plyr--audio .plyr__controls {
  padding-left: 0;
}
.audio-wrap {
  align-items: stretch;
  justify-content: center;
}

.video-wrap, .audio-wrap {
  flex-direction: column;
}

.video-wrap .recognised-text-wrap {
  margin: 12px 0 16px 10px;
}

.recognised-text-wrap {
  position: relative;
  margin: 12px 0 16px;
}

.recognised-text-wrap::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 4px;
  background-color: var(--case-btn-border-color);
  border-radius: 8px;
}

.recognised-text-content {
  position: relative;
  margin-left: 16px;
  max-height: 150px;
  overflow: auto;
}

.recognised-text-wrap p {
  margin: 0;
}

.attachment-wrapper .recognised-text-wrap {
  background-color: transparent;
}
.recognised-text-btn {
  padding: 4px 8px;
  background: none;
  border: none;
  min-width: 44px;
  border-radius: 4px;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.09);
}

.recognised-text-btn svg {
  display: inline-block;
  vertical-align: middle;
  height: 24px;
}

.recognised-text-btn svg:first-of-type {
  margin-right: 4px;
}

.audio-wrap .recognised-text-wrap {
  margin-left: 10px;
}

.video-wrap .recognised-text-btn {
  position: relative;
  left: 18px;
}

.video-wrap >>> .plyr {
  left: 10px;
}

</style>
