var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('Sidebar'),_c('div',{staticClass:"content-wrapper"},[_c('AlertErrorMsg',{ref:"alert",staticClass:"page-alert-error",attrs:{"title":_vm.errorMessage}}),_c('HeaderRegistered',{attrs:{"returnPath":_vm.returnPath,"subTitle":_vm.subTitle}}),_c('main',{staticClass:"main"},[_c('section',{staticClass:"case-assign-manager"},[_c('div',{staticClass:"manager-wrapper"},[_c('div',{staticClass:"manage-filter-bar"},[_c('CustomSearch',{attrs:{"id":"search_assign_menagers","modelValue":_vm.searchText,"classComponent":"assign-case-manager"},on:{"update:modelValue":function($event){_vm.searchText = $event}}})],1),_c('table',{attrs:{"cellpadding":"0","cellspacing":"0","border":"0"}},[_c('TableHeader',[_c('tr',[_c('th',[_c('div',{staticClass:"filter-option-wrapper",class:{
                        'sort-active': this.sortBy === 'userName',
                        'sorted-desc': this.order === 'desc',
                      },attrs:{"id":`sort_case_menager_case_${_vm.caseId}_by_name`},on:{"click":function($event){return _vm.sortedBy('userName')}}},[_c('div',{staticClass:"filter-image"},[_c('svg',{attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M8 7.5C10.2094 7.5 12 5.82129 12 3.75C12 1.67871 10.2094 0 8 0C5.79063 0 4 1.67871 4 3.75C4 5.82129 5.79063 7.5 8 7.5ZM10.8 8.4375H10.2781C9.58437 8.73633 8.8125 8.90625 8 8.90625C7.1875 8.90625 6.41875 8.73633 5.72188 8.4375H5.2C2.88125 8.4375 1 10.2012 1 12.375V13.5938C1 14.3701 1.67188 15 2.5 15H13.5C14.3281 15 15 14.3701 15 13.5938V12.375C15 10.2012 13.1187 8.4375 10.8 8.4375Z","fill":"#D7DCE6"}})])]),_c('div',{staticClass:"filter-title"},[_c('span',{staticClass:"filter-title-text"},[_vm._v("User Name")])]),_c('ArrowDown',{staticClass:"arrow"})],1)]),_c('th',[_c('div',{staticClass:"filter-option-wrapper",class:{
                        'sort-active': this.sortBy === 'roles',
                        'sorted-desc': this.order === 'desc',
                      },attrs:{"id":`sort_case_menager_case_${_vm.caseId}_by_role`},on:{"click":function($event){return _vm.sortedBy('roles')}}},[_c('div',{staticClass:"filter-image"},[_c('RoleIcon')],1),_c('div',{staticClass:"filter-title"},[_c('span',{staticClass:"filter-title-text"},[_vm._v("role")])]),_c('ArrowDown',{staticClass:"arrow"})],1)]),_c('th',[_c('div',{staticClass:"filter-option-wrapper",class:{
                        'sort-active': this.sortBy === 'cases',
                        'sorted-desc': this.order === 'desc',
                      },attrs:{"id":`sort_case_menager_case_${_vm.caseId}_by_case`},on:{"click":function($event){return _vm.sortedBy('cases')}}},[_c('div',{staticClass:"filter-image"},[_c('CaseIcon')],1),_c('div',{staticClass:"filter-title"},[_c('span',{staticClass:"filter-title-text"},[_vm._v("cases")])]),_c('ArrowDown',{staticClass:"arrow"})],1)]),_c('th')])]),(_vm.loadFlag)?_c('Loader',{attrs:{"load-flag":_vm.loadFlag}}):[_c('CaseAssignManagerItem',{attrs:{"managerList":_vm.managerList,"caseManager":_vm.caseManager},on:{"assign-manager":_vm.assignNewManager}})]],2),_c('CommonDialog',{ref:"success",attrs:{"custom-class":"assign-success"}},[_c('AssignSuccessBlock',{attrs:{"caseManager":_vm.caseManager,"caseId":_vm.caseId,"caseTitle":_vm.caseTitle}})],1)],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }