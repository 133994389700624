import { render, staticRenderFns } from "./CaseActionsTabs.vue?vue&type=template&id=36f69573&scoped=true"
import script from "./CaseActionsTabs.vue?vue&type=script&lang=js"
export * from "./CaseActionsTabs.vue?vue&type=script&lang=js"
import style0 from "./CaseActionsTabs.vue?vue&type=style&index=0&id=36f69573&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36f69573",
  null
  
)

export default component.exports