<template>
  <section class="section">
    <div class="container-small">
      <h1 class="title">Pivotal Mobile eDiscovery Launches Comprehensive, Cost Effect Remote Mobile Data Collection
        Platform</h1>
      <p class="text"><span class="bold">White Plains, NY – 12/10/2024</span> - Today marks the launch of Pivotal
        Mobile eDiscovery (PME), an innovative company dedicated to providing secure, cost-effective access to
        critical data sets from mobile devices. PME provides targeted remote collection of critical data from mobile
        devices, supporting both iOS and Android operating systems. PME's services include a fully automated remote
        data collection tool—PME Collect—and a powerful, intuitive review platform—PME Review—that simplifies the data
        gathering and review process. </p>
      <p class="subtitle">PME Collect & PME Review – Two Powerful eDiscovery Tools</p>
      <p class="text">PME Collect allows automated, remote collection to be performed by the device user without the
        need for specialized hardware, complex software, or on-site visits. Guided workflow and built in support
        tools simplify the collection process. Prefiltering tools allow for targeted collection and upload of only
        the relevant data ensuring custodian privacy and saving you time and money during the review process.
        Selected data is extracted and securely uploaded to our cloud-based platform where it is available for
        immediate review.<br><br>

        PME Review is a secure and scalable platform that provides comprehensive case management and powerful data
        review tools, globally accessible to any authorized user with a web browser. Keep track of all your cases,
        review managers, and custodian devices from a single dashboard with at-a-glance visualization of key metrics
        and insightful reporting to ensure you never lose track of assets or costs. Use our advanced search tools to
        find the relevant data and then apply tags, comments, highlights or redactions as part of your collaborative
        workflow. Gain deeper insights into custodian data with audio and video transcription. PME Review can be
        integrated into third-party review platforms and data sources via API, or simply export your data in an easily
        consumable format. PME Review also includes support for worldwide regional data storage to support your
        compliance needs.<br><br>

        Brian George, COO of PME, emphasized the transformative potential of their solution: "Our customers can speed
        time to discovery while reducing costs by leveraging PME for user-driven remote collection of mobile data
        combined with a SaaS-based review platform, all stored securely in the cloud, available on-demand to users
        worldwide."<br><br>

        Jon Kaufman, CEO, highlighted the diverse applications of PME's technology: "Our platform is designed to
        provide valuable insights and efficiencies across a wide range of business functions from traditional
        eDiscovery to regulatory compliance, internal investigations or employee matters."<br><br></p>
      <p class="subtitle">About PME</p>
      <p class="text">Pivotal Mobile eDiscovery (PME) combines state-of-the-art technology with a robust understanding
        of the complexities of mobile data collection. By offering a simple device-based pricing model, PME puts
        clients in control of costs, ensuring transparent budgeting and efficient data management. With a commitment
        to security, privacy, and a defensible chain of custody, PME is poised to redefine the landscape of mobile
        eDiscovery.</p>
      <div class="contact-info">
        <p class="text"><i>
          <span class="bold">For more information or to schedule a demo, please contact:</span><br><br>
          Pivotal Mobile eDiscovery<br>
          <span class="link" @click="$router.push({ name: 'Home' })">https://pme-discovery.com</span><br>
          Email: <a :href="'mailto:info@pme-discovery.com'" class="email">info@pme-discovery.com</a><br>
          Phone: <span class="phone">888-320-2474</span><br><br>

          For media inquiries, please contact: George Hampton<br>
          Email: <a :href="'mailto:ghampton@pme-discovery.com'" class="email">ghampton@pme-discovery.com</a><br>
          Phone: <span class="phone">914-705-6108</span><br><br>

          <span class="link" @click="$router.push({ name: 'Home' })">https://pme-discovery.com</span></i>
        </p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "PressRelease",
}
</script>
<style scoped lang="scss">
.section {
  .container-small {
    max-width: 835px;
    width: 100%;

    .title {
      font-size: 36px;
      font-weight: 700;
      line-height: 43px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #292B2F;
      margin-bottom: 50px;
    }

    .subtitle {
      font-size: 24px;
      font-weight: 500;
      line-height: 38.4px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: var(--black-color);
      margin-top: 32px;
    }

    .text {
      font-size: 18px;
      font-weight: 400;
      line-height: 29px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

    .bold {
      font-weight: 700;
      color: var(--black-color);
    }

    .contact-info {
      margin-top: 32px;

      .bold {
        font-weight: 600;
      }

      .link,
      .email,
      .phone {
        font-size: 18px;
        font-style: italic;
        font-weight: 400;
        line-height: 29px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: var(--bright-accent-color);
        cursor: pointer;
        text-decoration: underline;
      }

      .phone {
        text-decoration: none;
        cursor: default;
      }
    }
  }
}
</style>