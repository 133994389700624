<template>
  <div class="preloader">
    <div class="loading">
      <div class="circle s_3">
      </div>
      <div class="circle s_2">
      </div>
      <div class="circle s_1">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoaderHome",
}
</script>

<style scoped>
.preloader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loading {
  width: 200px;
  height: 200px;
  z-index: 1;
}

.circle {
  border-radius: 100%;
  border-top: 5px solid var(--accent-color);
  margin: auto;
  transform-origin: 50%;
  position: relative;
}

.s_3 {
  width: 200px;
  height: 200px;
  animation: rot_right 2s infinite linear;
  -webkit-animation: rot_right 2s infinite linear;
  transition: 2s;
  -webkit-transition: 2s;
}

.s_2 {
  width: 150px;
  height: 150px;
  margin-top: 10px;
  animation: rot_left 2s infinite linear;
  -webkit-animation: rot_left 2s infinite linear;
  transition: 2s;
  -webkit-transition: 2s;
  margin-top: -180px;
}

.s_1 {
  width: 100px;
  height: 100px;
  margin-top: 10px;
  animation: rot_right 2s infinite linear;
  -webkit-animation: rot_right 2s infinite linear;
  transition: 2s;
  -webkit-transition: 2s;
  margin-top: -130px;
}

@keyframes rot_right {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes rot_right {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
}

@keyframes rot_left {
  from {
    transform: rotate(360deg)
  }
  to {
    transform: rotate(0deg)
  }
}

@-webkit-keyframes rot_left {
  from {
    transform: rotate(360deg)
  }
  to {
    transform: rotate(0deg)
  }
}

.s_title {
  border: 0;
  color: white;
  font-family: arial;
  text-align: center;
  margin-top: -60px;
  animation: blink 2s infinite linear;
  -webkit-animation: blink 2s infinite linear;
  transition: 2s;
  -webkit-transition: 2s;
  letter-spacing: 1px;
}

@keyframes blink {
  0% {
    opacity: 1
  }
  50% {
    opacity: 0.5
  }
  100% {
    opacity: 1
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1
  }
  50% {
    opacity: 0.5
  }
  100% {
    opacity: 1
  }
}
</style>
