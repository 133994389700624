<template>
  <div>
    <Header/>
    <section class="section reset-section">
      <h2>Check your e-mail address to set new password</h2>
      <div class="holder-img">
        <img src="@/assets/img/sign-up.svg" alt="reset-img">
      </div>
      <div class="reset-text">
        <p>We send you an email to reset your password</p>
      </div>
    </section>
  </div>
</template>

<script>
import Header from "../../components/layout/Header";

export default {
  name: "ResetPassword",
  components: {
    Header,
  },
}
</script>

<style scoped>
img {
  max-width: 100%;
  max-height: 100%;
}
.reset-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
h2 {
  text-align: center;
  font-weight: 700;
  color: var(--secondary-black-color);
  font-size: 30px;
}
.holder-img {
  width: 300px;
  height: 300px;
}
.reset-text {
  max-width: 494px;
  text-align: center;
  color: var(--accent-color);
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
}
</style>
