export default function(instance) {
    return {
        getPermissionsUser(data) {
            return instance.get(`/permission/user`, data)
        },
        changeUserRole(data) {
            return instance.post(`/permission/user/roles`, data)
        },
        updatePermissionsUser(userId, permissions) {
            return instance.post(`/permission/user?userId=${userId}`, {permissions})
        },
        resetPermissionsUser(userId) {
            return instance.post(`/permission/user/reset?userId=${userId}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        },
        getPermissionsRole(data) {
            return instance.get(`/permission/role?role=${data}`,)
        },
        updatePermissionsRole(role, permissions) {
            return instance.post(`/permission/role?role=${role}`, {permissions})
        },
        resetPermissionsRole(data) {
            return instance.post(`/permission/role/reset?role=${data}`,{
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        },
        getPermissionsUserInfo({sortBy, keySearch, order, roles}) {
            return instance.get(`/permission/user/info?sortBy=${sortBy}&keySearch=${keySearch}&order=${order}&roles=${roles}`,)
        },
        getPermissionsRoleInfo(data) {
            return instance.get(`/permission/role/info`, data)
        },
        getPermissionsCaseUser(userId, caseId) {
            return instance.get(`/permission/user/case?userId=${userId}&caseId=${caseId}`)
        },
        updatePermissionsCaseUser(userId, caseId, permissions) {
            return instance.post(`/permission/user/case?userId=${userId}&caseId=${caseId}`, {permissions})
        },
        getPermissionsCase({caseId, sortBy, keySearch, order, roles}) {
            return instance.get(`/caseUser/${caseId}/users?sortBy=${sortBy}&keySearch=${keySearch}&order=${order}&roles=${roles}`)
        },
    }
}