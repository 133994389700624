<template>
  <div
      class="custom-select"
      :class="customClass"
      :tabindex="tabindex"
      @blur="open = false"
  >
    <div
        class="selected"
        :class="{ open: open }"
        @click="open = !open"
    >
      <span v-if="!selected" class="placeholder">
        {{ placeholder }}
      </span>
      <span v-if="selected">
        {{ selected }}
      </span>
      <arrowDown class="arrow" />
    </div>
    <div v-if="typeSelect === 'filterUser'" class="items" :class="{ selectHide: !open }">
      <div
          v-for="(option, i) of options"
          :key="i"
          @click="
          selected = option.name;
          open = false;
          $emit('input', option.id)
        "
      >
        {{ option.name }}
      </div>
    </div>
    <div v-else class="items" :class="{ selectHide: !open }">
      <div
          v-for="(option, i) of options"
          :key="i"
          @click="
          selected = option.name;
          open = false;
          $emit('input', option.name)
        "
      >
        {{ option.name }}
      </div>
    </div>
  </div>
</template>

<script>
import ArrowDown from "../icons/ArrowDown";
export default {
  name: "CustomSelect",
  components: {ArrowDown},
  props: {
    typeSelect: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: '',
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.default
          ? this.default
          : this.placeholder
          ? null
          : this.options.length > 0
              ? this.options[0].name
              : null,
      open: false,
    };
  },
  watch: {
    default(val) {
      this.selected = val
    }
  },
  mounted() {
    if(this.typeSelect !== 'filterUser') {
      this.$emit("input", this.selected);
    }
  },
}
</script>

<style scoped>

.custom-select {
  position: relative;
  width: 100%;
  outline: none;
}

.custom-select .selected {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  background-color: var(--white-color);
  border-radius: 79px !important;
  padding: 0 30px;
  min-height: 40px;
  color: var(--secondary-black-color) !important;
  cursor: pointer;
  border: 1px solid var(--input-placeholder-value-color) !important;
  user-select: none;
  font-weight: 500;
}

.custom-select .selected:hover {
  filter: drop-shadow(0px 0px 5px rgba(200, 205, 212, 0.5));
}

.custom-select .selected.open svg {
  transform: rotateX(180deg);
}

.selected span {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected svg {
  margin-left: auto;
}

.selected svg path {
  fill: var(--input-placeholder-value-color);
  cursor: pointer;
  transition: .3s all;
}

.selected:hover svg path {
  fill: var(--light-gray);
}

.custom-select .selected.open {
  background: var(--white-color);
  border: 1px solid var(--input-placeholder-value-color);
  box-sizing: border-box;
  box-shadow: 0px 0px 15px rgba(5, 51, 48, 0.06);
  border-radius: 20px;
}


.custom-select .items {
  margin-top: 5px;
  color: var(--secondary-text-color);
  overflow-x: hidden;
  position: absolute;
  background-color: var(--white-color);
  left: 0;
  right: 0;
  z-index: 10;
  border-radius: 20px !important;
  border: 1px solid var(--background-button-disabled-color);
  overflow-y: scroll;
  height: fit-content;
  max-height: 250px;
  box-shadow: 0 4px 39px rgba(0, 0, 0, 0.08);
  scrollbar-color: var(--background-color) transparent;
  scrollbar-width: thin;
}

.custom-select .items div {
  padding: 15px 16px;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div {
  text-overflow: ellipsis;
  overflow: hidden;
}

.custom-select .items div:hover {
  background: #F8FCFC;
  width: 100%;
}

.selectHide {
  display: none;
}

.support-form .selected {
  padding: 15px 25px;
}

.support-form .selected svg path{
  fill: var(--light-gray);
}

.support-form .selected:hover {
  filter: none;
}

.selectMessages .selected {
  padding: 9px 15px;
}

.selectMessages .items div{
  font-size: 12px;
}

.placeholder {
  font-size: 14px;
  font-weight: 500;
  color: var(--light-gray);
}

.arrow {
  width: 15px;
  height: 15px;
  max-width: 15px;
  max-height: 15px;
}

</style>
