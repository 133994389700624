<template>
  <div
      class="popup-overlay"
      :class="dialog? 'active':''"
      v-if="dialog"
  >
    <div
        class="popup"
        :class="arrayRemoveList.length > 1 ? 'more-then-one':''"
        v-click-outside="closePopup"
    >
      <div class="popup-content">
        <h2 class="popup-title">Deactivate {{ selectTitle }}</h2>
        <h3 class="popup-message">Are you sure you want to deactivate
          {{
            arrayRemoveList.length + " " + (subtitle || 'user') + (arrayRemoveList.length > 1 ? 's' : '')
          }}?
        </h3>
        <div class="cases-wrapper" :class="showMoreItems ? 'open':''">
          <div class="popup-user-info" v-for="(user, index) in arrayRemoveList" :key="index">
            <div class="popup-user-wrapper">
              <div class="user-info">
                <div v-if="user.avatar" class="user-img">
                  <img :src="user.avatar" alt="avatar">
                </div>
                <div v-else class="user-img">
                  <img
                      src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png"
                      alt="">
                </div>
                <span class="user-name">{{ getUsername(user) }} </span>
              </div>
              <div class="roles" v-if="user.roles">
                <span class="span-cases" v-if="!user.roles.length">-</span>
                <span class="span-cases" v-else-if="user.roles.length > 2">
                  <div class="span-cases-wrap">
                    <span
                        v-text="getRoleName(user, 0, 0,0,1)"
                        class="span-cases"
                        :class="`span-cases-` + user.roles[0].toLowerCase()"
                    ></span>
                    <span>, </span>
                    <span
                        v-text="getRoleName(user, 1, 0 ,1,1)"
                        class="span-cases"
                        :class="`span-cases-` + user.roles[1].toLowerCase()"
                    ></span>
                      <span>, </span>
                    <span
                        v-text="getRoleName(user, 2, 0 ,2,1)"
                        class="span-cases"
                        :class="`span-cases-` + user.roles[2].toLowerCase()"
                    ></span>
                  </div>
                </span>
                <span v-else-if="user.roles.length === 1"
                      class="span-cases"
                >
                  <div class="span-cases-wrap">
                    <span
                      v-text="getRoleName(user, 0, 0,0,1)"
                      class="span-cases"
                      :class="`span-cases-` + user.roles[0].toLowerCase()"
                    ></span>
                  </div>
                </span>
                <div v-else class="span-cases-wrap">
                  <span
                      v-text="getRoleName(user, 0, 0 ,0,1)"
                      class="span-cases"
                      :class="`span-cases-` + user.roles[0].toLowerCase()"
                  ></span>
                  <span>, </span>
                  <span
                      v-text="getRoleName(user, 1, 0 ,1,1)"
                      class="span-cases"
                      :class="`span-cases-` + user.roles[1].toLowerCase()"
                  ></span>
                </div>
              </div>
            </div>
            <div @click.stop="uncheckDeactivateUser(index)" class="popup-close" :class="arrayRemoveList.length >= 1 ? 'case': ''">
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.9997 0.333496C5.54801 0.333496 0.333008 5.5485 0.333008 12.0002C0.333008 18.4518 5.54801 23.6668 11.9997 23.6668C18.4513 23.6668 23.6663 18.4518 23.6663 12.0002C23.6663 5.5485 18.4513 0.333496 11.9997 0.333496ZM17.833 16.1885L16.188 17.8335L11.9997 13.6452L7.81134 17.8335L6.16634 16.1885L10.3547 12.0002L6.16634 7.81183L7.81134 6.16683L11.9997 10.3552L16.188 6.16683L17.833 7.81183L13.6447 12.0002L17.833 16.1885Z"
                    fill="#D7DCE6"/>
              </svg>
            </div>
          </div>
        </div>
        <button class="other-cases" v-if="arrayRemoveList.length > 3" @click="showMoreItems = !showMoreItems">
          {{ moreItemsBtnText }}
        </button>
        <div class="popup-close" @click="closePopup">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.9997 0.333496C5.54801 0.333496 0.333008 5.5485 0.333008 12.0002C0.333008 18.4518 5.54801 23.6668 11.9997 23.6668C18.4513 23.6668 23.6663 18.4518 23.6663 12.0002C23.6663 5.5485 18.4513 0.333496 11.9997 0.333496ZM17.833 16.1885L16.188 17.8335L11.9997 13.6452L7.81134 17.8335L6.16634 16.1885L10.3547 12.0002L6.16634 7.81183L7.81134 6.16683L11.9997 10.3552L16.188 6.16683L17.833 7.81183L13.6447 12.0002L17.833 16.1885Z"
                fill="#D7DCE6"/>
          </svg>
        </div>
        <button class="btn popup-btn" @click.stop="deactivateArrayOfUsers(arrayRemoveList)">Deactivate</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeactivateItemPopUp",
  props: {
    arrayOfCheckedUsers: {
      type: Array,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    }
  },
  data() {
    return {
      dialog: false,
      showMoreItems: false,
      arrayRemoveList: [],
    }
  },
  watch: {
    arrayRemoveList() {
      this.arrayRemoveList.length ? this.dialog = true : this.dialog = false
    }
  },
  computed: {
    usersRemoveList() {
      return JSON.parse(JSON.stringify(this.arrayRemoveList))
    },
    selectTitle() {
      if (this.title) {
        if (this.arrayRemoveList.length > 1) {
          return this.title + 's';
        }
        return this.title;
      }
      let titleWord = 'user';
      if (this.arrayRemoveList.length > 1) {
        titleWord = 'users'
      }
      return titleWord
    },
    moreItemsBtnText() {
      return this.showMoreItems ? 'roll up' : `${this.arrayRemoveList.length - 3} others`
    }
  },
  methods: {
    deactivateArrayOfUsers () {
      this.$emit('deactivateArrayOfUsers', this.usersRemoveList)
    },
    uncheckDeactivateUser(index) {
        this.arrayRemoveList.splice(index, 1);
    },
    createRemoveList() {
      this.arrayRemoveList = JSON.parse(JSON.stringify(this.arrayOfCheckedUsers))
    },
    openPopup() {
      this.createRemoveList()
      this.dialog = true
    },
    closePopup() {
      this.dialog = false
      this.showMoreItems = false
    },
    getRoleName(user, firstIndex, secondIndex, thirdIndex, fourthIndex) {
      if (user.roles[firstIndex].toLowerCase() === "client") {
        return "Custodian";
      }
      return user.roles[firstIndex].toLowerCase()[secondIndex].toUpperCase()
        + user.roles[thirdIndex].toLowerCase().slice(fourthIndex)
    },
    getUsername(user) {
      return user.firstName ? user.firstName + ' ' + user.lastName
        : user.email
        ? user.email
        : user.clientEmail;
    }
  },
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, .5);
}

.popup-overlay.active {
  animation: show .3s linear;
}

.popup {
  padding: 50px 20px 30px 30px;
  width: 588px;
  min-height: 377px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  background-color: var(--white-color);
  border-radius: 30px;
  z-index: 2;
}

.popup.more-then-one {
  padding: 50px 20px 30px 30px;
}

.popup.more-then-one .popup-user-info {
  box-shadow: 0px 0px 15px rgba(5, 51, 48, 0.06);
  transition: .5s all;
  cursor: pointer;
}

.popup.more-then-one .popup-user-info:hover {
  box-shadow: 0px 4px 20px rgba(77, 106, 147, 0.15);
}

.popup.more-then-one .popup-user-wrapper {
  max-width: calc(100% - 44px);
  justify-content: space-between;
}

.popup-title {
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
  margin: 0;
  color: var(--secondary-black-color);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.popup-message {
  margin: 19px 0 20px 0;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: var(--light-gray);
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.popup-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 23px;
  width: 70px;
  height: 70px;
  cursor: pointer;

}

.popup-close.case {
  position: static;
  display: block;
  overflow: hidden;
  padding: 0;
  width: 23px;
  height: 23px;
  margin: 0 0 0 10px;
}

.popup-close svg path {
  transition: .5s all;
}

.popup-close:hover svg path {
  fill: var(--accent-color);
}

.popup-close svg {
  max-width: 100%;
  max-height: 100%;
}

.popup-user-info {
  padding: 27px 20px;
  margin: 0 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 15px rgba(5, 51, 48, 0.06);
  border-radius: 5px;
}

.popup.more-then-one {
  max-height: 100vh;
  overflow: hidden auto;
}

.popup.more-then-one .cases-wrapper {
  height: 268px;
  overflow: hidden;
}

.popup.more-then-one .cases-wrapper.open {
  height: auto;
  overflow: unset;
}

.popup-user-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: calc(100% - 50px);
}

.user-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.user-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.user-name {
  display: block;
  padding: 0 0 0 10px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: var(--secondary-text-color);
  overflow: hidden;
  max-width: 220px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup-btn {
  width: 100%;
  min-width: unset;
  max-width: 140px;
  padding: 13px 27px;
  margin: 35px auto 0;
  background-color: var(--white-color);
  border-color: var(--accent-color);
  color: var(--accent-color);
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  transition: .5s all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup-btn:hover {
  background-color: var(--accent-color);
  color: var(--white-color);
}

.other-cases {
  display: block;
  width: 100%;
  max-width: 95px;
  text-align: center;
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: .5px solid var(--case-btn-border-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: var(--light-gray);
  border-radius: 40px;
  background-color: var(--white-color);
  cursor: pointer;
  transition: .5s all;
}

.other-cases:hover {
  border-color: var(--accent-color);
  color: var(--accent-color);
}

.user-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.roles .span-cases {
  text-decoration: none;
  font-size: 12px;
  line-height: 19px;
  font-weight: 500;
}

.span-cases-wrap {
  border: none;
  padding: 0;
  margin-right: 0;
  min-width: initial;
}

.span-cases-wrap .span-cases {
  padding: 0;
  font-style: normal;
  font-size: 12px;
  line-height: 19px;
  min-width: auto;
  margin-right: 0;
}


</style>
