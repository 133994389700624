import { render, staticRenderFns } from "./AssignOnlyCasePopup.vue?vue&type=template&id=44a94408&scoped=true"
import script from "./AssignOnlyCasePopup.vue?vue&type=script&lang=js"
export * from "./AssignOnlyCasePopup.vue?vue&type=script&lang=js"
import style0 from "./AssignOnlyCasePopup.vue?vue&type=style&index=0&id=44a94408&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44a94408",
  null
  
)

export default component.exports