<template>
  <div v-if="editingMessage" class="message-details-wrapper">
    <AlertErrorMsg
      :title="errorMessage"
      class="page-alert-error"
      ref="alert"
    />
    <div class="message-details-header">
      <h2 class="message-details-title">Details</h2>
      <svg @click="closeDetails()" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.9987 2.3335C7.54703 2.3335 2.33203 7.5485 2.33203 14.0002C2.33203 20.4518 7.54703 25.6668 13.9987 25.6668C20.4504 25.6668 25.6654 20.4518 25.6654 14.0002C25.6654 7.5485 20.4504 2.3335 13.9987 2.3335ZM19.832 18.1885L18.187 19.8335L13.9987 15.6452L9.81036 19.8335L8.16537 18.1885L12.3537 14.0002L8.16537 9.81183L9.81036 8.16683L13.9987 12.3552L18.187 8.16683L19.832 9.81183L15.6437 14.0002L19.832 18.1885Z" fill="#D7DCE6"/>
      </svg>
    </div>
    <div class="menu-item-wrapper">
      <div class="menu-item-header">
        <div class="header-text">
          <h2 class="menu-item-title">Tags</h2>
          <span v-if="checkPermission(['add_tag'])" @click="$refs.editTagsPopup.openPopups()">Edit</span>
        </div>
        <svg
          @click="isViewTags = !isViewTags"
          :style="{ transform: isViewTags ? '' : 'rotate(180deg)' }"
          width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.8402 14.6143L12.3859 9.16001C12.3352 9.1093 12.2751 9.06907 12.2089 9.04162C12.1427 9.01417 12.0717 9.00004 12 9.00004C11.9283 9.00004 11.8573 9.01417 11.7911 9.04162C11.7249 9.06907 11.6648 9.1093 11.6141 9.16001L6.15984 14.6143C6.0575 14.7166 6 14.8554 6 15.0002C6 15.1449 6.0575 15.2837 6.15984 15.3861C6.26218 15.4884 6.40099 15.5459 6.54573 15.5459C6.69047 15.5459 6.82928 15.4884 6.93162 15.3861L12 10.317L17.0684 15.3861C17.1191 15.4367 17.1792 15.4769 17.2454 15.5044C17.3116 15.5318 17.3826 15.5459 17.4543 15.5459C17.5259 15.5459 17.5969 15.5318 17.6631 15.5044C17.7293 15.4769 17.7895 15.4367 17.8402 15.3861C17.8908 15.3354 17.931 15.2752 17.9585 15.209C17.9859 15.1428 18 15.0718 18 15.0002C18 14.9285 17.9859 14.8575 17.9585 14.7913C17.931 14.7251 17.8908 14.665 17.8402 14.6143Z" fill="#8692A6"/>
        </svg>
      </div>
      <transition v-if="isViewTags" name="slide">
        <div class="tags-wrapper" :class="{ 'tags-disabled': isLoadingState }">
          <div v-for="(category, index) in chatTags" class="tag-section" :key="index">
            <h2 class="section-header">{{ category.category.name }}</h2>
            <div class="section-tags">
              <div v-for="(tag, index) in category.tags" class="tag" :key="index">
                <div class="check">
                  <CustomCheckbox
                    :id="tag.id"
                    :input-element="tag"
                    @checked="changeTagState"
                  />
                </div>
                <span>{{ tag.tag }}</span>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <EditTagsPopup ref="editTagsPopup"/>
  </div>
</template>
<script>
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import parseDate from "@/mixins/parseDate";
import parseDateTime from "@/mixins/parseTime";
import { mapActions, mapGetters } from "vuex";
import AlertErrorMsg from "@/components/profile/AlertErrorMsg.vue";
import EditTagsPopup from "@/components/popups/EditTagsPopup.vue";
import checkPermission from "@/utils/permission";

export default {
  name: "TagsList",
  components: {
    EditTagsPopup,
    AlertErrorMsg,
    CustomCheckbox,
  },
  emits: ['tagsUpdated'],
  data() {
    return {
      errorMessage: '',
      isViewTags: true,
    }
  },
  computed: {
    ...mapGetters('caseMessages', {
      editingMessage: "getEditingMessage",
      chatTags: "getChatTags",
      isLoadingState: 'getLoadingState',
    }),
  },
  mixins: [parseDate, parseDateTime],
  methods: {
    checkPermission,
    ...mapActions('caseMessages', {
      setLoadingState: "setLoadingState",
      setChatTags: "setChatTags",
      resetTabState: "resetTabState",
      getListMessagesByChatIdAndTags: "getListMessagesByChatIdAndTags",
    }),
    closeDetails() {
      this.$router.push({ name: 'Case messages mobile list'})
    },
    changeTagState() {
      this.setLoadingState(true);
      this.setChatTags(this.chatTags);
      this.resetTabState();
      this.$emit("tagsUpdated")
    },
  },
}
</script>
<style scoped lang="scss">
.message-details-wrapper {
  max-width: 435px;
  max-height: 1031px;
  width: 100%;
  height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
  border-left: 1px solid var(--border-color);

  .message-details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px;

    .message-details-title {
      margin: 0;
      font-size: 18px;
      font-weight: 700;
      line-height: 21.78px;
      color: var(--secondary-black-color);
    }
  }

  .menu-item-wrapper {

    .menu-item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 25px;
      background-color: var(--hover-accent-color);

      .header-text {
        display: flex;
        align-items: center;
        gap: 10px;

        span {
          font-size: 14px;
          font-weight: 400;
          line-height: 16.94px;
          text-align: left;
          color: var(--light-gray);
          cursor: pointer;
        }
      }

      .menu-item-title {
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        text-align: left;
        text-transform: uppercase;
        color: var(--secondary-black-color);
      }

      svg {
        transition: transform 0.3s;
      }
    }
  }
}

.message-text {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 15px 25px 25px;
  transition: all .5s;
  position: relative;

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;
    color: var(--black-color);
  }
}

.tags-wrapper {
  padding: 0 25px 15px;
  transition: all .5s;

  .tag-section {
    padding-top: 15px;

    .section-header {
      font-size: 16px;
      font-weight: 700;
      line-height: 19.36px;
      text-align: left;
      color: var(--accent-color);
      padding-bottom: 15px;
    }

    .section-tags {

      .tag {
        display: flex;
        gap: 10px;
      }
    }

    &:not(:last-child) {
      padding-bottom: 15px;
      border-bottom: 1px solid var(--border-color);
    }
  }
}

.tags-disabled {
  opacity: 0.4;
  pointer-events: none;
}

h2 {
  margin: 0;
}

svg {
  cursor: pointer;
}

.slide-enter-active, .slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter, .slide-leave-to {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.slide-enter-to, .slide-leave {
  max-height: 1000px;
  opacity: 1;
}

.tools {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 0;
  justify-content: center;
  align-items: center;
  transition: 0.2s all;
  border: 1px solid var(--case-btn-border-color);
  border-radius: 8px;
  padding: 8px;
  background-color: var(--white-color);
  font-size: 12px;
}

.colors {
  display: flex;
  align-items: center;
  transform: translate(-10%, -50%);
  gap: 16px;

  .close {
    border: none;
    width: fit-content;
    padding: 0;
    background-color: transparent;
  }

  .color {
    border: 1px solid transparent;
    border-radius: 50%;
    width: 16px;
    height: 16px;

    &.white {
      border-color: var(--case-btn-border-color);
    }

    &:hover,
    &.active {
      border-color: #373c44;
    }
  }
}

.underline {
  text-decoration: underline;
  text-underline-offset: 2px;
}

.background {
  position: absolute;
  z-index: -1;
  top: 15px;
  right: 50px;
  bottom: 30px;
  left: 20px;
  opacity: 0.4;
}

</style>