export default {
    getNotificationCount({ commit }, {params}) {
        return new Promise((resolve, reject) => {
            window.instance.get(`/notification/count`, params).then((res) => {
                commit('SET_NOTIFICATIONS_DATA', res.data.body.result);
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getNotificationAll({ commit }, {params}) {
        return new Promise((resolve, reject) => {
            window.instance.get(`/notification/all`, params).then((res) => {
                commit('SET_NOTIFICATIONS_ALL_DATA', res.data.body.content);
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },
}