const debounce = {
  data() {
    return {
      timer: null,
    };
  },
  methods: {
    debounce(fn, timeout = 1000, ...params) {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => fn(...params), timeout);
    },
  },
};

export default debounce
