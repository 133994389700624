<template>
  <div v-if="isEmptyData" class="no-filters">
    <img src="@/assets/img/no-saved-filters.svg" alt="">
    <button @click="$emit('backToDefault')" class="btn btn-revert">Back to Default</button>
  </div>
  <ul v-else>
    <li v-for="filter in filterList" :key="filter.id">
      <p class="filter-name">{{ filter.name }}</p>
      <span class="filters-amount">
        {{ getRulesLength(filter.value) }} Filters applied
      </span>
      <div class="filter-buttons">
        <button class="btn btn-revert" @click="deleteFilterPattern(filter.id)">
          Remove
          <svg fill="none" height="25" viewBox="0 0 24 25" width="24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 2.5C6.47 2.5 2 6.97 2 12.5C2 18.03 6.47 22.5 12 22.5C17.53 22.5 22 18.03 22 12.5C22 6.97 17.53 2.5 12 2.5ZM17 16.09L15.59 17.5L12 13.91L8.41 17.5L7 16.09L10.59 12.5L7 8.91L8.41 7.5L12 11.09L15.59 7.5L17 8.91L13.41 12.5L17 16.09Z"
                fill="#D7DCE6"
            />
          </svg>
        </button>
        <button class="btn" @click="$emit('attachFilter', filter.id)">Attach</button>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SavedFilters',

  data() {
    return {
      filterList: [],
      isEmptyData: false
    };
  },

  methods: {
    async getFilterPattern() {
      await this.$load(async () => {
        const res = await this.$api.cases.getFilterPatterns(this.$route.params.slug);

        this.filterList = res.data.body;

        if(!this.filterList.length) {
          this.isEmptyData = true;
        }
      });
    },

    async deleteFilterPattern(filterId) {
      await this.$load(async () => {
        try {
          await this.$api.cases.deleteFilterPattern(this.$route.params.slug, filterId);
          await this.getFilterPattern();
        } catch (e) {
          console.error(e);
        }
      });
    },

    getRulesLength(value) {
      return JSON.parse(value).rules.rules.length - 1;
    },
  },

  created() {
    this.getFilterPattern();
  }
};
</script>

<style lang="scss" scoped>
.no-filters {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 15px;

  button {
    width: 121px;
    height: 35px;
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
    margin: 0 0 0 25px;
  }
}
p {
  margin: 0;
}

ul {
  width: 100%;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 12px;
    font-size: 14px;
    font-weight: 500;

    > * {
      width: 33.33%;
    }

    span {
      text-align: center;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--background-button-disabled-color);
    }

    .filters-amount {
      color: var(--light-gray);
    }

    .filter-buttons {
      display: flex;
      gap: 32px;
      justify-content: flex-end;

      svg path {
        fill: #d7dce6;
      }
    }

    .btn-revert svg {
      margin-left: 12px;
    }
  }
}
</style>