<template>
  <div class="wrapper">
    <Sidebar />
    <div class="content-wrapper">
      <HeaderRegistered />
      <main class="main">
        <section class="dashboard-section">
          <div class="holder-tab-sorts">
            <div class="tab-wrapper">
              <router-link
                  :to="{ path: '/invite-new-contact/approved' }"
                  tag="button"
                  exact-path
                  class="tab-btn"
                  id="permission_tab_approved"
              >
                <span class="tab-btn-text">Approved</span>
              </router-link>
              <router-link
                  :to="{ path: '/invite-new-contact/processing-requests' }"
                  tag="button"
                  exact-path
                  class="tab-btn"
                  id="permission_tab_processing"
              >
                <span class="tab-btn-text">Processing</span>
              </router-link>
              <router-link
                  :to="{ path: '/invite-new-contact/invite-team' }"
                  tag="button"
                  exact-path
                  class="tab-btn"
                  :class="{'active': subIsActive('/invite-new-contact/invite-team')}"
                  id="permission_tab_new"
              >
                <span class="tab-btn-text">Invite Team</span>
              </router-link>
            </div>

            <div class="holder-permission-filters">
              <CustomSearch
                  v-if="!hiddenSearch"
                  :modelValue="searchText"
                  @update:modelValue="searchText = $event"
                  :placeholder="searchPlaceholder"
              />
          </div>
          </div>
          <div class="profile-section">
            <router-view
              :searchText="searchText"
            />
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import checkPermission from "../utils/permission";
import Sidebar from "../components/Sidebar";
import HeaderRegistered from "../components/layout/HeaderRegistered";
import CustomSearch from "../components/inputs/CustomSearch";

export default {
  name: "InviteContact",
  data() {
    return {
      caseName: "",
      page: 1,
      size: 10,
      casesList: [],
      searchText: "",
      hiddenSearch: false,
      searchPlaceholder: '',
    }
  },
  watch: {
    '$route.path'(val) {
      this.searchText = '';

      this.setSearchInput(val);
    }
  },
  methods: {
    checkPermission,
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0 // current path starts with this path string
      })
    },
    setSearchInput(path) {
      if (path === '/invite-new-contact/approved') {
        this.searchPlaceholder = 'Search by name';
        this.hiddenSearch = false;
      } else if (path === '/invite-new-contact/processing-requests') {
        this.searchPlaceholder = 'Search by email';
        this.hiddenSearch = false;
      } else {
        this.hiddenSearch = true;
      }
    }
  },
  components: {
    CustomSearch,
    HeaderRegistered,
    Sidebar,
  },
  mounted() {
    document.title = 'Invite contact'

    this.setSearchInput(this.$route.path);
  },
}
</script>

<style scoped>
p {
  margin-top: 0;
}

ul {
  padding: 0;
}

.wrapper {
  display: flex;
}

.content-wrapper {
  width: calc(100% - 120px);
}

/* tabs */
.holder-tab-sorts {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.tab-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 714px;
  width: 100%;
  min-height: 35px;
  border-radius: 79px;
  border: 1px solid var(--case-btn-border-color);
}

.tab-btn {
  margin: -1px 0 -1px 0;
  width: 33.33%;
  max-height: 36px;
  padding: 10px 50px;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
  border-radius: 79px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  transition: 0.3s all;
}

.tab-btn.active {
  border-color: var(--accent-color);
}

.tab-btn-text {
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: var(--light-gray);
  transition: 0.3s all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-btn.active .tab-btn-text {
  color: var(--accent-color);
}

/* share */
.permission-reset {
  display: flex;
  align-items: center;
  padding: 9px 13px;
  background: var(--white-color);
  border-radius: 79px;
  color: var(--light-gray);
  cursor: pointer;
  border: 1px solid var(--background-button-disabled-color);
  transition: 0.3s all;
}

.permission-reset:hover {
  border: 1px solid var(--accent-color);
  color: var(--accent-color);
}

.permission-reset svg {
  margin-left: 5px;
}

</style>