export default function(instance) {
    return {
        getNotificationFiles({sortBy, keySearch, order, dateFrom, dateTo, page, size}) {
            return instance.get(`/notification/files?sortBy=${sortBy}&keySearch=${keySearch}&order=${order}&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}`,)
        },
        getNotificationCases({sortBy, keySearch, order, dateFrom, dateTo, page, size}) {
            return instance.get(`/notification/cases?sortBy=${sortBy}&keySearch=${keySearch}&order=${order}&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}`,)
        },
        getNotificationClients({sortBy, keySearch, order, dateFrom, dateTo, page, size}) {
            return instance.get(`/notification/clients?sortBy=${sortBy}&keySearch=${keySearch}&order=${order}&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}`,)
        },
        getNotificationTeam({sortBy, keySearch, order, dateFrom, dateTo, page, size}) {
            return instance.get(`/notification/team?sortBy=${sortBy}&keySearch=${keySearch}&order=${order}&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}`,)
        },
        getNotificationPermission({sortBy, keySearch, order, dateFrom, dateTo, page, size}) {
            return instance.get(`/notification/permission?sortBy=${sortBy}&keySearch=${keySearch}&order=${order}&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}`,)
        },
        getNotViewed() {
            return instance.get('/notification/all')
        },
        getPermissionsCount() {
            return instance.get('/notification/count')
        },
        changeStatusNotification({notificationId}) {
            return instance.put(`/notification/${notificationId}`, {})
        },
        changeStatusNotificationType({notificationIds}) {
            return instance.put(`/notification?notificationIds=${notificationIds }`, {})
        },
    }
}