import { render, staticRenderFns } from "./AssignSuccessBlock.vue?vue&type=template&id=511cae88&scoped=true"
import script from "./AssignSuccessBlock.vue?vue&type=script&lang=js"
export * from "./AssignSuccessBlock.vue?vue&type=script&lang=js"
import style0 from "./AssignSuccessBlock.vue?vue&type=style&index=0&id=511cae88&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "511cae88",
  null
  
)

export default component.exports