/**
 * Removes extra slashes from the base URL after the port number.
 * @param {string} baseURL - The base URL to be fixed.
 * @returns {string} The fixed base URL.
 */
export default function fixBaseURL(baseURL) {
  // Check if the base URL is a valid URL
  try {
    new URL(baseURL);
  } catch (_) {
    return baseURL; // Return the original base URL if it's not a valid URL
  }

  // Split the base URL into protocol, host, and path
  const url = new URL(baseURL);
  const protocol = url.protocol;
  const host = url.host;
  const path = url.pathname;

  // Check if the host contains two slashes after the port number
  const hostParts = host.split(':');
  const hostName = hostParts[0];
  const port = hostParts[1];
  const hasExtraSlashes = port && port.startsWith('//');

  // Remove extra slashes from the port if present
  const fixedPort = hasExtraSlashes ? port.slice(2) : port;
  const fixedHost = fixedPort ? `${hostName}:${fixedPort}` : hostName;

  // Reconstruct the fixed base URL
  return `${protocol}//${fixedHost}${path}`;
}