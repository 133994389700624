<template>
  <tbody>
  <tr v-for="(item, i) in caseExportList"
      :key="i"
  >
    <td>
      <div class="holder-info">
        <span class="download-date">{{ parseDate(item.createdAt) }}</span>
      </div>
    </td>
    <td>
      <div
          v-if="item.actorFirstName !== '' && item.actorLastName !== ''"
          :id="`goto_user_profile_${item.actorPublicId}`"
          @click="gotoProfile(item.actorPublicId)"
          class="holder-info name">
        <div class="img">
          <img
              v-if="item.actorAvatar"
              :src="item.actorAvatar"
              onerror="this.src='https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png';"
              alt=""
          >
          <img
              v-else
              src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png"
              alt=""
          >
        </div>
        <div class="info-name">
            <span
                class="info-name">{{ capitalizeFirstLetter(item.actorFirstName) }} {{ capitalizeFirstLetter(item.actorLastName) }}</span>
        </div>
      </div>
      <div class="holder-info" v-else>
        <span class="info-name">{{item.actorEmail}}</span>
      </div>
    </td>
    <td>
      <div class="holder-info">
         <span class="file-size">{{capitalizeFirstLetter(item.caseName)}}</span>
      </div>
    </td>
    <td>
          <span
              :class="item.status.toLowerCase()"
              class="loading-status">{{item.status.toLowerCase()}}</span>
    </td>
    <td :id="`download-case_${i}`" :ref="`download-case_${i}`">
      <button
          :disabled="!checkPermission(['export_case']) || item.status.toLowerCase() !== 'success'"
          :id="`download_export_files_${item.bucketName}`"
          class="download-file-btn"
          @click="downloadCase(item)"
      >
        <span class="btn-text">Download</span>
        <div class="btn-icon">
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_15459_6019)">
              <path d="M9.17501 13.0891C8.1676 11.3084 8.47937 9.07335 9.93541 7.63616C11.3916 6.19897 13.6305 5.91661 15.3979 6.94718C17.1653 7.97774 18.0222 10.0655 17.4886 12.0405C16.9549 14.0157 15.1633 15.3878 13.1172 15.388C11.4838 15.3924 9.97577 14.513 9.17501 13.0891Z" fill="white"/>
              <path d="M10.9567 6.05705C11.4619 6.05636 11.9611 6.16855 12.4173 6.38546C10.9498 6.61581 9.6883 7.5511 9.04148 8.88844C8.39465 10.2258 8.44458 11.7953 9.17517 13.0888H2.61414C2.01308 13.0885 1.43685 12.8486 1.01289 12.4224C0.589068 11.9961 0.352472 11.4185 0.355523 10.8174C0.355384 10.8143 0.355523 10.8114 0.3558 10.8085L0.359406 10.6891C0.361903 9.2633 1.33436 8.04842 2.66961 7.74137V7.74027C2.67003 5.81144 4.02054 4.14625 5.90776 3.74767C7.79498 3.34909 9.70356 4.32613 10.4838 6.09006C10.6405 6.06801 10.7985 6.05705 10.9567 6.05705Z" fill="#082655"/>
              <path d="M13.1169 5.97672C12.901 5.977 12.6853 5.99156 12.4712 6.02013C11.9941 5.80919 11.478 5.70088 10.9562 5.70227C10.8694 5.70227 10.7823 5.70532 10.6955 5.71128C9.93427 4.23401 8.41179 3.30538 6.74979 3.30469C4.39535 3.30469 2.46334 5.14878 2.32229 7.46925C1.69544 7.67311 1.1432 8.05838 0.735328 8.57609C0.263801 9.1777 0.00654113 9.91939 0.00390612 10.6837L0.000855062 10.7902C0.00016164 10.8006 2.29552e-05 10.8092 2.29552e-05 10.8157C-0.00288942 11.5097 0.271428 12.1762 0.762233 12.667C1.25304 13.1578 1.91969 13.4323 2.61367 13.4292H8.9736C9.86312 14.8849 11.4314 15.7355 13.1169 15.7355C15.8095 15.7355 18 13.5486 18 10.8561C18 8.1635 15.8095 5.97672 13.1169 5.97672ZM1.26413 12.1648C0.907019 11.8092 0.707452 11.3254 0.710087 10.8214C0.710087 10.8195 0.710226 10.8195 0.710226 10.8175L0.713832 10.6991C0.713832 10.6957 0.713832 10.693 0.713832 10.6896C0.714664 10.0826 0.91853 9.49335 1.29284 9.01558C1.65869 8.55002 2.17196 8.22258 2.74847 8.0875C2.9099 8.04992 3.02404 7.9061 3.0239 7.74052C3.02431 5.97936 4.2575 4.45896 5.98065 4.09519C7.70367 3.73142 9.44624 4.62344 10.1585 6.23398C10.2229 6.37905 10.3756 6.46392 10.5327 6.44187C10.673 6.42218 10.8145 6.41233 10.9561 6.41233C11.002 6.41233 11.0476 6.41358 11.093 6.41566C9.35401 7.20783 8.23677 8.94139 8.23386 10.8523C8.23178 11.4935 8.35895 12.1284 8.60761 12.7192H2.61367C2.10761 12.7232 1.62124 12.5235 1.26413 12.1648ZM13.1169 15.0328C10.8123 15.0328 8.94392 13.1646 8.94392 10.8598C8.94392 8.55515 10.8123 6.68679 13.1169 6.68679C15.4217 6.68679 17.2901 8.55515 17.2901 10.8598C17.2838 13.162 15.4191 15.0267 13.1169 15.0328Z" fill="#082655"/>
              <path d="M14.2 10.9328L13.4389 11.6622V9.2188C13.4389 9.0227 13.2799 8.86377 13.0838 8.86377C12.8877 8.86377 12.7288 9.0227 12.7288 9.2188V11.6622L11.9853 10.9328C11.9186 10.8673 11.8283 10.8313 11.7348 10.8328C11.6414 10.8343 11.5523 10.8732 11.4877 10.9407C11.3518 11.0822 11.3564 11.3068 11.4977 11.4428L12.8533 12.7557C12.9913 12.8891 13.2103 12.8891 13.3483 12.7557L14.703 11.4428C14.8425 11.305 14.8442 11.0804 14.7065 10.9407C14.5683 10.7999 14.3425 10.7963 14.2 10.9328Z" fill="#082655"/>
            </g>
            <defs>
              <clipPath id="clip0_15459_6019">
                <rect width="18" height="18" fill="white" transform="translate(0 0.5)"/>
              </clipPath>
            </defs>
          </svg>

        </div>
      </button>
      <tooltip v-if="!checkPermission(['export_case']) || item.status.toLowerCase() !== 'success'"
               :target-ref="$refs[`download-case_${i}`]"
      >
        <span>Export case application is pending</span>
      </tooltip>
    </td>
  </tr>
  </tbody>
</template>

<script>
import parseDate from "../../../mixins/parseDate";
import checkPermission from "../../../utils/permission";
import Tooltip from "@/components/common/Tooltip.vue";
export default {
  name: "CaseExportItem",
  components: {Tooltip},
  props: {
    caseExportList: Array,
  },
  mixins: [parseDate],
  methods: {
    checkPermission,
    downloadCase(exportCase) {
      this.$emit('downloadItem', exportCase)
    },
    capitalizeFirstLetter(string) {
      return string.replaceAll("\"", "")[0].toUpperCase() + string.replaceAll("\"", "").slice(1).toLowerCase();
    },
    gotoProfile(id) {
      let route = this.$router.resolve({name: 'User Profile', params: {slug: id}})
      window.open(route.href, '_blank');
    }
  }
}
</script>

<style scoped>
.download-date {
  display: block;
  width: 100%;
  max-width: 200px;
  font-size: 14px;
  font-weight: 400;
  color: var(--secondary-text-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 42px 0 0;
}

.file-owner {
  width: 100%;
  max-width: 250px;
  display: flex;
  align-items: center;
  margin: 0 40px 0 0;
}

.file-size {
  display: block;
  width: 100%;
  max-width: 100px;
  margin: 0 47px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  text-align: left;
  color: var(--secondary-text-color);
}

.damaged {
  background-color: var(--damaged-file-color);
  color: var(--white-color);
}

.loading {
  border-color: var(--loading-color);
  color: var(--loading-color);
}

.loaded {
  background-color: var(--loaded-color);
  color: var(--accent-color);
}

.download-file-btn {
  margin: 0;
  outline: none;
  min-width: 135px;
  padding: 9px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--white-color);
  border: .5px solid var(--case-btn-border-color);
  border-radius: 79px;
  transition: .5s all;
}

.download-file-btn:hover {
  border-color: var(--accent-color);
}

.download-file-btn:hover .btn-text {
  color: var(--accent-color);
}

.btn-text {
  display: block;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: var(--secondary-text-color);
  transition: .5s all;
  max-width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-icon {
  width: 18px;
  height: 18px;
  background-color: #fff;
}

.loading-status {
  border: 1px solid transparent;
  padding: 5px 20px;
  text-align: center;
  width: auto;
  max-width: 140px;
  display: block;
  font-size: 12px;
  font-weight: 500;
  border-radius: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.download-file-btn:disabled {
  background: var(--background-button-disabled-color);
  pointer-events: none;
}

.download-file-btn:disabled .btn-icon {
  background: transparent;
}

.denied {
  background-color: var(--damaged-file-color);
  color: var(--white-color);
}

.processing {
  border-color: var(--loading-color);
  color: var(--loading-color);
}

.success {
  background-color: var(--loaded-color);
  color: var(--accent-color);
}

td:last-child {
  display: block;
  margin-left: auto;
  width: fit-content;
}
</style>