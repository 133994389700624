import Vue from 'vue'
import App from './App.vue'
import VuePlyr from 'vue-plyr/dist/vue-plyr.ssr.js'
import 'vue-plyr/dist/vue-plyr.css'
import { registerLicense } from '@syncfusion/ej2-base';
import ApiPlugin from './plugins/api'
import LoadPlugin from './plugins/load'
import Vuelidate from 'vuelidate'
import VueRouter from 'vue-router'
import store from './store'
import router from './router'
import Vuex from 'vuex'
import OtpInput from "@bachdgvn/vue-otp-input";
import customAxios from './api/axios';
import customAxiosFiles from './api/axiosFiles';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import '@/assets/css/normalize.css';
import '@/assets/css/main.css';
import HighchartsVue from 'highcharts-vue'
import rounded from 'highcharts-rounded-corners'

import * as Highcharts from 'highcharts';

rounded(Highcharts)


window.$axios = customAxios;
window.$axiosFiles = customAxiosFiles;

Vue.config.productionTip = false

registerLicense(process.env.VUE_APP_SYNCFUSION_KEY);

Vue.use(VuePlyr, { plyr: { storage: {enabled: false, key: 'plyr'}} })
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(ApiPlugin)
Vue.use(LoadPlugin)
Vue.use(Vuelidate)
Vue.use(HighchartsVue, {tagName: 'charts'})
Vue.use(require('vue-moment'));
Vue.component("v-otp-input", OtpInput);

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

export default new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
})
