<template>
  <section class="section register-section">
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
    <div class="container-small">
      <div class="register-step">
        <div class="step-header">
          <div class="step-progress">
            <div class="bar progressbar" style="width: 25%;"></div>
          </div>
          <ul class="step-pills">
            <li class="step-item validated"><span class="step-link"><span class="tabStatus">1 </span><span
                class="tabLabel">2Factor Authentication</span></span></li>
            <li class="step-item active"><span class="step-link"><span class="tabStatus">2 </span><span
                class="tabLabel">Company registration</span></span></li>
            <li class="step-item"><span href="#" class="step-link"><span class="tabStatus">3</span><span
                class="tabLabel">Profile photo</span></span></li>
          </ul>
        </div>
        <div class="register-form-container">
          <div class="register-info">
            <h1 class="register-info-title main-title">Fill company information</h1>
            <p class="register-info-description main-description">To get started in PME add information about the
              company</p>
          </div>
          <div class="form-content">
            <form @submit.prevent="submit"
                  class="form-registration"
            >
              <div class="form-group"
                   :class="{ 'form-group--error': $v.formData.firmName.$error }"
              >
                <label for="register_firm-registration-name_input">Company Name*</label>
                <input
                    id="register_firm-registration-name_input"
                    name="organisation"
                    type="text"
                    class="form-control"
                    :class="false ? 'is-invalid' : ''"
                    placeholder="Enter company name"
                    :maxlength="firmNameMax"
                    v-model.trim="$v.formData.firmName.$model"
                >
                <div class="error" v-if="!$v.formData.firmName.required">Field is required</div>
                <div class="error" v-if="!$v.formData.firmName.minLength">Company name must have at least
                  {{ $v.formData.firmName.$params.minLength.min }} letters.
                </div>
                <div class="error" v-if="!$v.formData.firmName.maxLength">Company name must have no more than
                  {{ $v.formData.firmName.$params.maxLength.max }} letters.
                </div>
              </div>
              <div class="form-group"
                   :class="{ 'form-group--error': $v.formData.country.$error }"
              >
                <label for="register_firm-registration-country_input">Country*</label>
                <custom-select-geolocation
                    ref="selectCountry"
                    id="register_firm-registration-country_input"
                    :options="countries"
                    :customId="'countries-item'"
                    :searchInputId="'countries-search'"
                    @input="selectCountry"
                    @openSelect="closeOtherSelectFields($refs.selectCountry)"
                    default="Select a country"
                />
                <div class="error" v-if="!$v.formData.country.required">Field is required</div>
              </div>
              <div class="form-group"
                   :class="{ 'form-group--error': $v.formData.state.$error }"
              >
                <label for="register_firm-registration-state_input">State*</label>
                <custom-select-geolocation
                    ref="selectState"
                    id="register_firm-registration-state_input"
                    name="state"
                    :options="states"
                    :customId="'states-item'"
                    :searchInputId="'states-search'"
                    :class="states.length < 1 ? 'disabled' : ''"
                    @input="selectStates"
                    @openSelect="closeOtherSelectFields($refs.selectState)"
                    default="Select a state"
                />
                <div class="error" v-if="!$v.formData.state.required">Field is required</div>
              </div>
              <div class="form-group form-group-image"
                   :class="{ 'form-group--error': $v.formData.city.$error }"
              >
                <label for="register_firm-registration-city_input">City*</label>
                <custom-select-geolocation
                    ref="selectCity"
                    id="register_firm-registration-city_input"
                    name="city"
                    :options="cities"
                    :class="cities.length < 1 ? 'disabled' : ''"
                    :customId="'cities-item'"
                    :searchInputId="'cities-search'"
                    @input="selectCities"
                    @openSelect="closeOtherSelectFields($refs.selectCity)"
                    default="Select a city"
                />
                <div class="error" v-if="!$v.formData.city.required">Field is required</div>
              </div>
              <div class="form-group"
                   :class="{ 'form-group--error': $v.formData.region.$error }"
              >
                <label for="register_firm-registration-region_input">Region*</label>
                <CustomSelect
                  :default="defaultRegion"
                  :typeSelect="'filterMessengers'"
                  v-model="formData.region"
                  :custom-class="'select-databases top'"
                  :options="regions" />
                <div class="error" v-if="!$v.formData.region.required">Field is required</div>
              </div>
              <div class="form-group form-group-image"
                   :class="{ 'form-group--error': $v.formData.firstAddress.$error }"
              >
                <label for="register_firm-registration-first-adress_input">Company Physical Address 1*</label>
                <input
                    id="register_firm-registration-first-adress_input"
                    name="street address 1"
                    type="text"
                    class="form-control"
                    :class="false ? 'is-invalid' : ''"
                    placeholder="Enter Physical Address"
                    v-model="$v.formData.firstAddress.$model"
                >
                <div class="error" v-if="!$v.formData.firstAddress.required">Field is required</div>
                <div class="error"
                     v-if="$v.formData.firstAddress.required
                       && (!$v.formData.firstAddress.alphaNum || $v.formData.firstAddress.minLength || $v.formData.firstAddress.maxLength)"
                >
                  First address should have from {{ $v.formData.firstAddress.$params.minLength.min }} to
                  {{ $v.formData.firstAddress.$params.maxLength.max }} alphanumeric characters
                </div>
              </div>
              <div
                  class="form-group form-group-image"
                  :class="{ 'form-group--error': $v.formData.secondAddress.$error }"
              >
                <label for="register_firm-registration-second-adress_input">Company Physical Address 2</label>
                <input
                    id="firm_edit-second-adress_input"
                    type="text"
                    name="street address 2"
                    class="form-control"
                    :class="false ? 'is-invalid' : ''"
                    placeholder="Enter Physical Address"
                    v-model="$v.formData.secondAddress.$model"
                >
                <div class="error"
                     v-if="!$v.formData.secondAddress.alphaNum || $v.formData.secondAddress.minLength || $v.formData.secondAddress.maxLength"
                >
                  Second address should have from {{ $v.formData.secondAddress.$params.minLength.min }} to
                  {{ $v.formData.secondAddress.$params.maxLength.max }} alphanumeric characters
                </div>
              </div>
              <div class="form-group"
                   :class="{ 'form-group--error': $v.formData.postCode.$error }"
              >
                <label for="firm_edit-postcode_input">Postcode</label>
                <input
                    id="firm_edit-postcode_input"
                    type="tel"
                    class="form-control"
                    :class="false ? 'is-invalid' : ''"
                    placeholder="Enter postcode"
                    v-model="$v.formData.postCode.$model"
                >
                <div class="error" v-if="!$v.formData.postCode.numeric">Enter only numbers</div>
                <div class="error" v-if="!$v.formData.postCode.minLength">
                  Post code must be at least {{ $v.formData.postCode.$params.minLength.min }} digits
                </div>
                <div class="error" v-if="!$v.formData.postCode.maxLength">Card number must have no more than
                  {{ $v.formData.postCode.$params.maxLength.max }} letters.
                </div>
              </div>
              <div class="form-button-wrapper">
                <button
                    id="firm_edit_button"
                    type="submit"
                    class="btn form-btn"
                    v-text="'Confirm'"></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import AlertErrorMsg from "../profile/AlertErrorMsg";
import {required, minLength, maxLength, numeric, helpers} from 'vuelidate/lib/validators'
import CustomSelectGeolocation from "../inputs/CustomSelectGeolocation";
import LocalStorageService from "../../services/LocalStorageService";
import CustomSelect from "@/components/inputs/CustomSelect.vue";

// LocalstorageService
const localStorageService = LocalStorageService.getService();

const alphaNumS = helpers.regex('alpha', /^[a-zA-Z0-9\s#\-.(),/]*$/)

let apiKey = 'N3dGUHNibjUyRUJKR2pTQzlMMEdVUlpsQmZvYVFISEhITExtcmt4Vw=='

var headers = new Headers();
headers.append("X-CSCAPI-KEY", apiKey);

var requestOptions = {
  method: 'GET',
  headers: headers,
  redirect: 'follow'
};


export default {
  name: "FirmInfoStep",
  components: {
    CustomSelect,
    AlertErrorMsg,
    CustomSelectGeolocation
  },
  emits: ['update:modelValue'],
  props: {
    modelFullName: String,
    modelEmail: String,
  },
  data() {
    return {
      firmNameMax: 100,
      countries: [],
      cities: [],
      states: [],
      regions: [],
      defaultRegion: '',
      stateCode: '',
      countryCode: '',
      cityCode: '',
      formData: {
        firmName: '',
        state: '',
        country: '',
        city: '',
        region: '',
        firstAddress: '',
        secondAddress: null,
        postCode: null,
      },
      submitStatus: null,
      validForm: false,
      errorMessage: '',
    };
  },
  validations: {
    formData: {
      firmName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      country: {
        required,
      },
      city: {
        required,
      },
      region: {
        required,
      },
      state: {
        required,
      },
      firstAddress: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(250),
        alphaNumS
      },
      secondAddress: {
        minLength: minLength(5),
        maxLength: maxLength(250),
        alphaNumS

      },
      postCode: {
        minLength: minLength(5),
        maxLength: maxLength(50),
        numeric
      }
    },
  },
  computed: {
    selectFieldsArray() {
      return [this.$refs.selectCountry, this.$refs.selectState, this.$refs.selectCity]
    },
  },
  methods: {
    filtering(arr) {
      return arr.sort((a, b) => a.name > b.name ? 1 : -1);
    },
    selectCountry(code, value) {
      if (!value) return;
      this.resetSelectFields(true)
      this.countryCode = code
      this.formData.country = value
      this.getStates(code)
    },
    selectStates(code, value) {
      if (!value) return;
      this.resetSelectFields()
      this.stateCode = code
      this.formData.state = value
      this.getCity(this.countryCode, this.stateCode, code)
    },
    selectCities(code, value) {
      if (!value) return;
      this.cityCode = code
      this.formData.city = value
    },
    resetSelectFields(withStates) {
      if (withStates) {
        this.cities = [];
        this.$refs.selectState.clearField();
        this.formData.state = '';
      }
      this.states = [];
      this.$refs.selectCity.clearField();
      this.formData.city = '';
    },
    closeOtherSelectFields(ref) {
      for (const fieldRef of this.selectFieldsArray) {
        if (fieldRef !== ref) fieldRef.closeSelect();
      }
    },
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'
        for (var propName in this.formData) {
          if (this.formData[propName] === '') {
            this.formData[propName] = null
          }
        }
        this.$load(async () => {
          let updatedFormData = { ...this.formData, region: this.formData.region.toUpperCase() };
          await this.$api.auth.firmRegistration(updatedFormData).then(() => {
            localStorageService.setDataToLocalStorage(3);
            this.$router.push({name: 'ProfilePhotoStep'})
          })
        }, (error) => {
          let errors;
          if (error.response) {
            errors = Object.values(error.response.data.errors);
          } else if (error.data.errors.message) {
            errors = error.data.errors.message;
          } else {
            errors = error.data.errors;
          }
          for (const error of errors) {
            if (error[0] !== 'Bad Request') this.errorMessage = error[0];
          }
          if (this.errorMessage) this.$refs.alert.showAlert();
        })
      }
    },
    getStates(codeCountries) {
      fetch(`https://api.countrystatecity.in/v1/countries/${codeCountries}/states/`, requestOptions)
          .then(response => response.json())
          .then(result => this.states = this.filtering(result))
          .catch(error => console.log('error', error));
    },
    getCity(codeCountries, codeStates) {
      fetch(`https://api.countrystatecity.in/v1/countries/${codeCountries}/states/${codeStates}/cities`, requestOptions)
          .then(response => response.json())
          .then(result => this.cities = this.filtering(result))
          .catch(error => console.log('error', error));
    },
    getRegions() {
      this.$load(async () => {
        await this.$api.cases.getRegionsToCase("tenant").then((res) => {
          const regionArray = res.data.body.regions

          this.regions = regionArray.map((region, i) => {
            return {
              name: region.toLowerCase()[0].toUpperCase() + region.toLowerCase().slice(1),
              id: i,
            }
          })
          this.formData.region = this.regions[0].name
          this.defaultRegion = this.regions[0].name
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
      })
    }
  },
  mounted() {
    fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
        .then(response => response.json())
        .then(result => this.countries = this.filtering(result))
        .catch(error => console.log('error', error));
    this.getRegions();
  }
}
</script>

<style scoped>
.holder-alert.page-alert-error {
  position: fixed;
}

.form-btn {
  margin: 0 auto;
}
</style>
