import instance from './instance'

import loginModule from './login'
import authModule from './auth'
import invitesModule from './invites'
import publicPageModule from './publicPage'
import socialLinksModule from './socialLinks'
import faqPageModule from './faqPage'
import supportPageModule from './support'
import resetPasswordModule from './resetPassword'
import dashboardModule from './dashboard'
import profileModule from './profile'
import permissionsModule from './permissions'
import casesModule from './cases/cases'
import contactsModule from './contacts'
import notificationsModule from './notifications'
import invoicesModule from './invoice'
import screenshotsModule from './screenshots'
import caseConfigModule from './caseConfig'
import callsModule from './cases/calls'
import caseContactsModule from './cases/caseContacts'
import managementModule from './management'

export default {
    loginApi: loginModule(instance),
    auth: authModule(instance),
    invites: invitesModule(instance),
    publicPage: publicPageModule(instance),
    socialLinks: socialLinksModule(instance),
    faqPage: faqPageModule(instance),
    supportPage: supportPageModule(instance),
    resetPassword: resetPasswordModule(instance),
    dashboard: dashboardModule(instance),
    profile: profileModule(instance),
    permissions: permissionsModule(instance),
    cases: casesModule(instance),
    contacts: contactsModule(instance),
    notifications: notificationsModule(instance),
    invoices: invoicesModule(instance),
    screenshots: screenshotsModule(instance),
    caseConfig: caseConfigModule(instance),
    calls: callsModule(instance),
    caseContacts: caseContactsModule(instance),
    management: managementModule(instance)
}
