<template>
  <div class="wrapper">
    <Sidebar />
    <div class="content-wrapper">
      <HeaderRegistered :returnPath="returnPath" :sub-title="caseTitle" />
      <main class="main">
        <section class="dashboard-section">
          <AlertErrorMsg
              :title="errorMessage"
              class="page-alert-error"
              ref="alert"
          />
          <form @submit.prevent="submit">
            <div class="holder-table">
              <TableHeader>
                <table cellpadding="0" cellspacing="0" border="0">
                  <thead>
                  <tr>
                    <th>
                      <div class="table-header-box">
                        <UserIcon />
                        <span class="header-table-text">Email</span>
                      </div>
                    </th>
                    <th width="33%">
                      <div class="table-header-box">
                        <span class="header-table-text">Message</span>
                      </div>
                      <em>optional</em>
                    </th>
                  </tr>
                  </thead>
                </table>
              </TableHeader>
              <div>
                <table cellpadding="0" cellspacing="0" border="0">
                  <tbody>
                  <tr v-for="(v, index) in $v.formData.invites.$each.$iter"
                      :key="index">
                    <td>
                      <div class="table-body-box" :class="{ 'form-group--error': v.email.$error }">
                        <input
                            :id="`invite_input_mail_${index}_client`"
                            class="text"
                            type="text"
                            v-model="v.email.$model"
                            placeholder="Enter contact e-mail"
                        />
                        <div class="error" v-if="!v.email.required">Field is required</div>
                        <div class="error" v-if="!v.email.email">This must be an email</div>
                      </div>
                    </td>
                    <td>
                      <div class="holder-btns">
                        <div class="table-body-box">
                          <input
                              type="text"
                              class="text"
                              :id="`input_individual_message_to_client_${index}`"
                              v-model="v.message.$model"
                              placeholder="Type an individual message to your custodian"
                          >
                          <!-- <p style="position: absolute; font-size: 10px">msg:  {{valuemsg}}</p> -->
                        </div>
                        <div
                            :id="`delete_invite_${index}_client`"
                            class="close"
                            v-if="index > 0"
                            @click="deleteInvite(index)"
                        >
                          <button class="delete">
                            <CloseIcon />
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div class="add-invite">
                <button
                    id="add_new_invite_client"
                    class="invite-btn"
                    @click.prevent="addNewInvite">
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="15.5" cy="15.5" r="15.5" fill="#082655"/>
                    <path
                        d="M14.5341 21.7074H16.6023V16.6477H21.6619V14.5795H16.6023V9.51989H14.5341V14.5795H9.47443V16.6477H14.5341V21.7074Z"
                        fill="white"/>
                  </svg>
                </button>
              </div>

              <div class="holder-send">
                <button
                    id="send_invite_client"
                    type="submit"
                    class="btn">Send</button>
              </div>
            </div>
          </form>
          <CommonDialog
              ref="commonDialogFormSuccess"
          >
          <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z" fill="#082655"/>
          </svg>
          <h2 class="dialog-message-title">Success!</h2>
          <p class="dialog-message-description" style="display:block;">New custodians were invited to {{ caseTitle }}</p>
          </CommonDialog>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import '@/assets/css/table.css';
import {required, email} from 'vuelidate/lib/validators'
import Sidebar from "../../Sidebar";
import TableHeader from "../../table/TableHeader";
import HeaderRegistered from "../../layout/HeaderRegistered";
import AlertErrorMsg from "../../profile/AlertErrorMsg";
import CommonDialog from "../../popups/CommonDialog";
import UserIcon from "../../icons/UserIcon";
import CloseIcon from "../../icons/CloseIcon";
export default {
  name: "InviteUserInCase",
  components: {CloseIcon, UserIcon, CommonDialog, AlertErrorMsg, HeaderRegistered, Sidebar, TableHeader},
  data() {
    return {
      caseId: this.$route.params.slug,
      validForm: false,
      caseTitle: '',
      returnPath: true,
      errorMessage: '',
      formData: {
        invites: [
          {
            email: '',
            message: '',
            caseId: this.$route.params.slug,
          }
        ],
      },
    }
  },
  validations: {
    formData: {
      invites: {
        $each: {
          email: {
            required,
            email
          },
          message: {
            // required,
          },
        },
      },
    },
  },
  computed: {
    subtitle() {
      return `Case ${this.caseId}: `
    }
  },
  methods: {
    openDialogFormSuccess() {
      this.$refs.commonDialogFormSuccess.openPopups();
    },
    resetForm() {
      this.formData.invites = []
      this.$emit('clear', null);
      this.addNewInvite()
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    addNewInvite() {
      this.formData.invites.push({
        email: '',
        message: '',
        caseId: this.$route.params.slug
      })
    },
    deleteInvite(idx) {
      if (idx) {
        this.formData.invites.splice(idx, 1)
      }
    },
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = 'PENDING'
        let invitesArr = JSON.parse(JSON.stringify(this.formData.invites))
        this.$load(async () => {
          await this.$api.invites.sendInviteClient(invitesArr).then(() => {
            this.openDialogFormSuccess()
            this.resetForm()
          }, (error) => {
            if(error) {
              if(error.response) {
                if(error.response.data.errors.code[0] === "REG_PACK_INTEGRATION_EXCEPTION") {
                  let route = this.$router.resolve({path: '/billing-account'})
                  this.errorMessage = error.response.data.errors.message[0] + '<br>' +  'Go to the ' + `<a class="error-link" href="${route.href}">payment page</a>` + ' to increase your limit device count'
                }else {
                  this.errorMessage = error.response.data.errors.message[0]
                }
              }else {
                this.errorMessage = error.data.errors.message[0]
              }
              this.$refs.alert.showAlert()
            }
          })
        })
      }
    }
  },
  mounted() {
    this.caseTitle = localStorage.getItem('title')
  }
}
</script>

<style scoped>
.add-invite {
  position: relative;
  margin-top: 20px;
  width: 100%;
  border-bottom: 1px solid #D7DCE6;
}

.add-invite svg {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border: 1px solid var(--accent-color);
  border-radius: 50%;
}

.add-invite svg circle {
  fill: var(--accent-color);
  transition: 0.5s all;
}

.add-invite svg path {
  fill: var(--white-color);
  transition: 0.5s all;
}

.add-invite svg:hover circle {
  fill: var(--white-color);
}

.add-invite svg:hover path {
  fill: var(--accent-color);
}

.holder-send {
  margin-top: 60px;
}

.invite-btn {
  position: absolute;
  border: none;
  background-color: white;
}

.table-body.form-group--error .error {
  display: block;
}

.table-header .case, .table-header .message {
  flex-direction: column;
}

.table-body.border .table-row:last-child {
  border-bottom: none;
}

.delete {
  border: none;
  background-color: var(--white-color);
}

.table-body-box.form-group--error .error {
  position: absolute;
  bottom: -20px;
  padding-left: 15px;
}

.holder-search input {
  width: 100%;
  height: auto;
}

.text {
  width: 100%;
  border-radius: 79px;
  border: 1px solid var(--input-placeholder-value-color);
  padding: 12px 15px;
  font-size: 12px;
}

.text:hover {
  filter: drop-shadow(0px 0px 5px rgba(200, 205, 212, 0.5));
}

.dashboard-search {
  position: relative;
  width: 100%;
}

.text::placeholder {
  color: var(--input-placeholder-value-color);
  font-size: 14px;
}

.dialog-message-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #292B2F;
  text-align: center;
  margin: 0;
  display: block;
  width: 100%;
  margin-top: 25px;
}

.dialog-message-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #696F79;
  display: block;
  width: 100%;
  margin-top: 20px;
}
</style>
