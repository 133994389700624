import capitalizeFirstLetter from "@/mixins/capitalizeFirstLetter";

let getRoleName = {
  mixins: [capitalizeFirstLetter],
  methods: {
    getRoleNameFE(roleName) {
      if (!roleName) {
        return roleName;
      }
      const role = roleName.replaceAll('"', '')
      if (role.toLowerCase() === 'attorney') {
        return 'Review admin';
      }
      return this.capitalizeFirstLetter(role.replaceAll('_', ' '));
    },
    getRoleNameBE(role) {
      if (!role) {
        return role;
      }
      if (role.toLowerCase() === 'review admin') {
        return 'Attorney';
      }
      return role.toUpperCase().replaceAll(' ', '_');
    },
  },
}

export default getRoleName