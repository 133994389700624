let checkAll = {
    computed: {
        checkAll: {
            get() {
                if(this.users.length) {
                    for (let i = 0; i < this.users.length; i++) {
                        if (!this.users[i].isActive) return false;
                    }
                    return true;
                }
                return false
            },
            set(value) {
                this.users.forEach(function(item) {
                    item.isActive = value;
                })
            }
        },
        checkSome() {
            return this.users.filter(i => i.isActive)
        }
    }
}

export default checkAll