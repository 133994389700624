<template>
  <div class="messages-wrapper">
    <div class="content-wrapper">
      <CaseMessageDetailsNavigation />
      <CustomSearch placeholder="Find..." @update:modelValue="messageSearch = $event" />
      <div class="scrollable-container">
        <SetMessages v-if="messages.length > 0" :messages="messages" :messageSearch="messageSearch" editMode />
        <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
          <div slot="spinner">Loading...</div>
          <div slot="no-more">No more messages</div>
          <div slot="no-results">No results</div>
        </infinite-loading>
      </div>
    </div>
    <MessageDetails />
  </div>
</template>

<script>
import CaseMessageDetailsNavigation from "@/components/сases/case-messages/tags/CaseMessageDetailsNavigation.vue";
import SetMessages from "@/components/сases/case-messages/SetMessages.vue";
import { mapActions, mapGetters } from "vuex";
import CustomSearch from "@/components/inputs/CustomSearch.vue";
import MessageDetails from "@/components/сases/case-messages/tags/MessageDetails.vue";
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'CaseMessagesHighlight',
  components: {
    MessageDetails,
    CustomSearch,
    SetMessages,
    CaseMessageDetailsNavigation,
    InfiniteLoading,
  },
  data() {
    return {
      infiniteId: +new Date(),
      messageSearch: null,
    }
  },
  watch: {
    messageSearch(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setKeySearch(newVal);
        this.infiniteId += 1;
      }
    },
  },
  computed: {
    ...mapGetters('caseMessages', {
      messages: "getMessages",
      hasMoreMessages: "getHasMoreMessages",
    })
  },
  methods: {
    ...mapActions('caseMessages', {
      getListHighlightMessages: 'getListHighlightMessages',
      setKeySearch: 'setKeySearch',
      setCurrentRoute: "setCurrentRoute",
      resetTabState: "resetTabState",
    }),
    infiniteHandler($state) {
      this.getListHighlightMessages();
      setTimeout(() => {
        if (this.hasMoreMessages) {
          $state.loaded();
        } else {
          $state.complete();
        }
      }, 1000);
    },
  },
  created() {
    this.resetTabState()
    const path = this.$route.path;
    this.setCurrentRoute(path.substring(path.lastIndexOf("/") + 1));
  },
};
</script>

<style scoped lang="scss">
.messages-wrapper {
  display: flex;
  margin: 25px 60px 0 40px;
  max-width: 90vw;

  .content-wrapper {
    position: relative;
    padding: 50px 0 0;
    min-width: 600px;
    width: 100%;

    .scrollable-container {
      height: 90vh;
      overflow-y: auto;
      scrollbar-color: var(--background-color) transparent;
      scrollbar-width: thin;
    }
  }
}
</style>