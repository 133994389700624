const truncate = {
  methods: {
    truncate(value, maxLength = 200) {
      const length = maxLength;
      if (length <= 3) {
        console.error(`[${this.truncate.name}] length must be more than 3, you passed: `, length);
        return 'as';
      }
      if (!value) return '';
      let newStr = value;
      const doctypeRegex = /^<!doctype/i;
      if (doctypeRegex.test(newStr)) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(newStr, 'text/html');
        const titleElement = doc.querySelector('title');
        newStr = titleElement ? titleElement.textContent : '';
      }
      if (value.length > length) {
        newStr = `${newStr.substring(0, length - 3)}...`;
      }
      return newStr;
    }
  }
};

export default truncate;
