<template>
  <header>
    <div class="header-registered">
      <div class="logo-wrap" @click="changeActiveSection('features', 0)">
        <img :src="logo" alt="logo" />
      </div>
      <div v-if="$route.name === 'Home' || $route.name === 'PressRelease'" class="menu-panel" v-click-outside="closeDropdown">
        <div class="menu-item" v-for="(theme, index) in reviewThemes" :key="index">
          <div class="menu-title">
            <span
              @click="changeActiveSection(theme.name, index)"
              :class="{ active: activeReviewIndex === index }"
            >
              {{ theme.name }}
            </span>
            <svg
              v-if="theme.childs.length > 0"
              @click="toggleDropdown(index)"
              :class="{ activeArrow: activeDropdownIndex === index }"
              style="transition: all .3s ease;"
              width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.3402 6.43162L8.88589 11.8859C8.83523 11.9366 8.77508 11.9768 8.70887 12.0043C8.64265 12.0317 8.57168 12.0459 8.5 12.0459C8.42832 12.0459 8.35735 12.0317 8.29113 12.0043C8.22492 11.9768 8.16476 11.9366 8.11411 11.8859L2.65984 6.43162C2.5575 6.32928 2.5 6.19047 2.5 6.04573C2.5 5.90099 2.5575 5.76218 2.65984 5.65984C2.76218 5.5575 2.90099 5.5 3.04573 5.5C3.19047 5.5 3.32928 5.5575 3.43162 5.65984L8.5 10.7289L13.5684 5.65984C13.6191 5.60916 13.6792 5.56897 13.7454 5.54154C13.8116 5.51412 13.8826 5.5 13.9543 5.5C14.0259 5.5 14.0969 5.51412 14.1631 5.54154C14.2293 5.56897 14.2895 5.60916 14.3402 5.65984C14.3908 5.71052 14.431 5.77068 14.4585 5.83689C14.4859 5.9031 14.5 5.97406 14.5 6.04573C14.5 6.1174 14.4859 6.18836 14.4585 6.25457C14.431 6.32078 14.3908 6.38094 14.3402 6.43162Z" fill="#696F79"/>
            </svg>
          </div>
          <ul v-if="activeDropdownIndex === index && theme.childs.length" class="dropdown">
            <li v-for="child in theme.childs" :key="child" @click="handleChildSelect(child)">{{ child }}</li>
          </ul>
        </div>
      </div>
      <nav class="header-nav" v-if="loggingIn">
        <ul class="navigation">
          <li
            id="navigation_header_btn"
            class="nav-item">
            <HeaderNotificationsTemplate
              :notifications="notifications"
              @changedStatus="changeStatus"
            />
          </li>
          <li
            id="navigation_header_user_image"
            v-if="photoProfile"
            class="nav-item"
            @click.stop="$refs.userDropdown.toggleActive()"
          >
            <img
              class="user-image"
              :src="photoProfile"
              alt=""
              onerror="this.src='https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png'"
            >
          </li>
          <li
            class="nav-item"
            id="profile_header_btn"
            @click.stop="$refs.userDropdown.toggleActive()"
            v-else
          >
            <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="17.5" cy="18" r="17.5" fill="#F8FCFC" />
              <path
                d="M17.5 6.5C13.9236 6.5 11 9.42356 11 13C11 16.5764 13.9236 19.5 17.5 19.5C21.0764 19.5 24 16.5764 24 13C24 9.42356 21.0764 6.5 17.5 6.5Z"
                fill="#8692A6" />
              <path
                d="M29.9466 24.8139C29.7566 24.3355 29.5033 23.889 29.2183 23.4744C27.7616 21.3057 25.5134 19.8705 22.9802 19.5197C22.6635 19.4878 22.3152 19.5516 22.0618 19.7429C20.7319 20.7316 19.1486 21.2419 17.502 21.2419C15.8553 21.2419 14.272 20.7316 12.9421 19.7429C12.6887 19.5516 12.3404 19.4559 12.0238 19.5197C9.4905 19.8705 7.21059 21.3057 5.78565 23.4744C5.50066 23.889 5.24731 24.3674 5.05735 24.8139C4.96238 25.0053 4.99402 25.2285 5.08899 25.4199C5.34234 25.8664 5.65897 26.3129 5.94396 26.6956C6.38726 27.3016 6.86226 27.8437 7.4006 28.354C7.84391 28.8005 8.35055 29.2151 8.85725 29.6297C11.3588 31.5114 14.3671 32.5 17.4703 32.5C20.5736 32.5 23.5818 31.5113 26.0834 29.6297C26.59 29.247 27.0967 28.8005 27.54 28.354C28.0467 27.8437 28.5533 27.3015 28.9967 26.6956C29.3133 26.281 29.5984 25.8664 29.8517 25.4199C30.0099 25.2285 30.0415 25.0052 29.9466 24.8139Z"
                fill="#8692A6" />
            </svg>
          </li>
        </ul>
        <HeaderDropdown ref="userDropdown" />
      </nav>
    </div>
  </header>
</template>

<script>
import checkPermission from "../../utils/permission";
import { mapActions } from "vuex";
import LocalStorageService from "../../services/LocalStorageService";
import HeaderNotificationsTemplate from "./HeaderNotificationsTemplate";
import HeaderDropdown from "./HeaderDropdown";
import logo from "@/assets/img/logo.svg"

const localStorageService = LocalStorageService.getService();

export default {
  name: "HeaderRegistered",
  components: {
    HeaderDropdown,
    HeaderNotificationsTemplate
  },
  emits: ['goto'],
  props: {
    returnPath: {
      type: Boolean
    },
    subTitle: {
      type: String
    }
  },
  data() {
    return {
      logo,
      loggingIn: true,
      reviewThemes: [
        {
          name: "PME Features",
          childs: [],
        },
        {
          name: "Our Platform",
          childs: [],
        },
        {
          name: "Resources",
          childs: ['Blog', 'Press']
        },
        {
          name: "Contact us",
          childs: [],
        }
      ],
      activeReviewIndex: null,
      activeDropdownIndex: null
    }
  },
  computed: {
    photoProfile() {
      return this.$store.state.profile.user.photoProfile
    },
    notifications() {
      return this.$store.state.notifications.notificationsList
    },
  },
  methods: {
    ...mapActions({
      getNotificationCount: 'notifications/getNotificationCount',
      getNotificationAll: 'notifications/getNotificationAll'
    }),
    checkPermission,
    changeStatus() {
      this.getNotificationAll({}).then(() => {
        this.getNotificationCount({})
      })
    },
    closeDropdown() {
      this.activeDropdownIndex = null;
    },
    toggleDropdown(index) {
      this.activeDropdownIndex = this.activeDropdownIndex === index ? null : index;
    },
    changeActiveSection(themeName, index) {
      if (index === 2) {
        this.toggleDropdown(index);
      } else {
        this.activeReviewIndex = index;
        this.$emit('goto', themeName)
      }
    },
    handleIntersection(entries) {
      let intersectingCount = 0;

      entries.forEach(entry => {
        if (entry.isIntersecting) {
          intersectingCount++;
          const index = this.reviewThemes.findIndex(theme => theme.name === entry.target.id);
          if (index !== -1) {
            this.activeReviewIndex = index;
          }
        }
      });

      if (intersectingCount === 0) {
        this.activeReviewIndex = null;
      }
    },
    handleChildSelect(childName) {
      if (childName === 'Blog') {
        window.open('https://blog.pme-discovery.com/', '_blank');
      } else if (childName === 'Press') {
        this.$emit('goto', childName);
        this.activeReviewIndex = 2;
      }
      this.closeDropdown();
    },
  },
  mounted() {
    if (localStorageService.getAccessTokenLogin() !== null) {
      this.$store.commit('authentication/loginSuccess', localStorageService.getAccessTokenLogin)
    }

    this.getNotificationAll({}).then(() => {
      this.getNotificationCount({})
    })

    this.$store.dispatch('profile/getUser')

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // Adjust this threshold according to your needs
    };

    this.observer = new IntersectionObserver(this.handleIntersection, observerOptions);

    this.reviewThemes.forEach(theme => {
      const section = document.getElementById(theme.name);
      if (section) {
        this.observer.observe(section);
      }
    });
  },
  beforeDestroy() {
    // Disconnect observer when the component is destroyed
    if (this.observer) {
      this.observer.disconnect();
    }
  },
}
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}

ul {
  padding: 0;
  list-style: none;
}

header {
  padding: 10px 100px;
  width: 100%;
  z-index: 20;
  background: var(--white-color);
  height: 65px;
  position: sticky;
  top: 0;
}

.title-return {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.title-return svg {
  margin-right: 23px;
}

.header-registered {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 25px;
}

header h3 {
  display: inline-block;
  white-space: nowrap;
  color: var(--secondary-black-color);
  font-size: 26px;
  font-weight: 700;
  margin: 0;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.header-nav {
  position: relative;
}

.navigation {
  display: flex;
  align-items: center;
  margin: 0;
}

.nav-item {
  cursor: pointer;
  padding: 0 15px;
  position: relative;
}

.user-image {
  width: 35px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  height: 35px;
}

.notification-circle {
  position: absolute;
  top: -6px;
  right: 16px;
}

.notification-circle svg circle {
  fill: var(--accent-color);
  transition: all .2s;
}

.notification-circle.notification-empty svg circle {
  fill: #DDE3E3;
  transition: all .2s;
}

.nav-item:last-child {
  padding-right: 0;
}

.header-link {
  display: inline-block;
  margin-right: 34px;
}

.header-link a {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  transition: all .5s;
  text-decoration: none;
}

.header-link a:hover {
  color: var(--accent-color);
}

.menu-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 510px;
  width: 100%;

  .menu-item {
    padding: 10px;
    cursor: pointer;
    position: relative;

    .menu-title {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 16px;
      font-weight: 500;
      line-height: 25.6px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: var(--secondary-text-color);
      transition: all .3s ease;

      &:hover {
        color: var(--accent-color);
      }

      .active {
        color: var(--accent-color);
        box-shadow: 0 3px var(--accent-color);
      }
    }


    ul {
      list-style-type: none;
      margin: 0;
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: fit-content;
      min-width: 100px;
    }

    li {
      cursor: pointer;
      padding: 10px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 500;
      line-height: 25.6px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: var(--secondary-text-color);
    }

    li:hover {
      color: var(--accent-color);
      background-color: #EBEFF6;
    }

    .dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      background: white;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      z-index: 10;
      border-radius: 8px;
    }

    .activeArrow {
      transform: rotate(-180deg);
    }
  }
}

</style>
