<template>
  <div v-if="activeDialog && clientKey" class="overlay">
    <div class="key-management-wrapper">
      <div class="close-dialog" @click="closePopups">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.0007 0.333008C5.54898 0.333008 0.333984 5.54801 0.333984 11.9997C0.333984 18.4513 5.54898 23.6663 12.0007 23.6663C18.4523 23.6663 23.6673 18.4513 23.6673 11.9997C23.6673 5.54801 18.4523 0.333008 12.0007 0.333008ZM17.834 16.188L16.189 17.833L12.0007 13.6447L7.81232 17.833L6.16732 16.188L10.3557 11.9997L6.16732 7.81134L7.81232 6.16634L12.0007 10.3547L16.189 6.16634L17.834 7.81134L13.6457 11.9997L17.834 16.188Z"
            fill="#D7DCE6"/>
        </svg>
      </div>
      <svg width="95" height="98" viewBox="0 0 95 98" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M77.9571 79.4007C87.9276 69.4302 87.9314 53.2651 77.9609 43.2947C70.9435 36.2772 60.8654 34.197 52.0292 37.054L26.3974 11.4145C23.9365 8.95355 20.3836 7.91921 16.9844 8.68055C15.1003 9.09967 13.3777 10.0494 12.0127 11.4145L9.49023 13.9369V23.4267C9.49023 25.7184 11.0552 27.7141 13.2816 28.2562C15.0849 28.6946 16.4923 30.1058 16.9345 31.9091L17.0844 32.5282C17.5535 34.4546 19.1262 35.9196 21.0834 36.2503L24.0518 36.754C26.1359 37.1078 27.7663 38.7381 28.12 40.8222C28.3892 42.4064 28.693 44.2021 28.9044 45.4518C29.0775 46.4669 29.562 47.4051 30.2887 48.1357L35.6142 53.4651C32.7534 62.2974 34.8337 72.3794 41.8472 79.3968C51.8177 89.3673 67.9828 89.3712 77.9571 79.4007ZM60.6347 62.0744C63.2878 59.4251 67.5829 59.4251 70.236 62.0744C72.8853 64.7276 72.8853 69.0226 70.236 71.6758C67.5829 74.3251 63.284 74.3251 60.6347 71.6758C57.9815 69.0226 57.9815 64.7276 60.6347 62.0744Z" fill="#5692B7"/>
        <path d="M59.8654 61.3016C62.9377 58.2294 67.9364 58.2294 71.0048 61.3016C74.0771 64.3739 74.0771 69.3726 71.0048 72.4411C67.9326 75.5133 62.9338 75.5133 59.8654 72.4411C56.797 69.3726 56.797 64.3739 59.8654 61.3016ZM69.4668 70.903C71.6893 68.6805 71.6893 65.0661 69.4668 62.8436C67.2443 60.6211 63.6298 60.6211 61.4073 62.8436C59.1848 65.0661 59.1848 68.6805 61.4073 70.903C63.6298 73.1255 67.2443 73.1255 69.4668 70.903Z" fill="#082655"/>
      </svg>
      <div v-if="action === 'send'" class="body-wrapper">
        <span class="title">Resend the Key</span>
        <span class="subtitle">You can copy and share the Key manually:</span>
        <div class="key-holder">
          <div class="key-wrapper">
            <span>{{ clientKey.apiKey }}</span>
          </div>
          <div class="copy-wrapper" @click="copyKeyToBuffer(clientKey.apiKey)">
            <span>Copy</span>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.1875 0.47583H4.1875C4.03832 0.47583 3.89524 0.535093 3.78975 0.640583C3.68426 0.746072 3.625 0.889146 3.625 1.03833V3.85083H0.8125C0.663316 3.85083 0.520242 3.91009 0.414753 4.01558C0.309263 4.12107 0.25 4.26415 0.25 4.41333V13.4133C0.25 13.5625 0.309263 13.7056 0.414753 13.8111C0.520242 13.9166 0.663316 13.9758 0.8125 13.9758H9.8125C9.96168 13.9758 10.1048 13.9166 10.2102 13.8111C10.3157 13.7056 10.375 13.5625 10.375 13.4133V10.6008H13.1875C13.3367 10.6008 13.4798 10.5416 13.5852 10.4361C13.6907 10.3306 13.75 10.1875 13.75 10.0383V1.03833C13.75 0.889146 13.6907 0.746072 13.5852 0.640583C13.4798 0.535093 13.3367 0.47583 13.1875 0.47583ZM9.25 12.8508H1.375V4.97583H9.25V12.8508ZM12.625 9.47583H10.375V4.41333C10.375 4.26415 10.3157 4.12107 10.2102 4.01558C10.1048 3.91009 9.96168 3.85083 9.8125 3.85083H4.75V1.60083H12.625V9.47583Z" fill="#696F79"/>
            </svg>
            <span v-if="showTooltip" class="tooltip">Copied!</span>
          </div>
        </div>
        <div class="or-wrapper">
          <div class="line"></div>
          <span>or</span>
          <div class="line"></div>
        </div>
        <span class="send-text">Send the Key via e-mail:</span>
        <span class="send-email">{{ clientKey.email }}</span>
      </div>
      <div v-if="action === 'gen'" class="body-wrapper">
        <div class="title">
          <span>Generate the Key</span>
        </div>
        <div class="subtitle">
          <span>We’ve generated a new Key. You can copy and share it manually:</span>
        </div>
        <div class="key-holder">
          <div class="key-wrapper">
            <span>{{ clientKey.apiKey }}</span>
          </div>
          <div class="copy-wrapper" @click="copyKeyToBuffer()">
            <span>Copy</span>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.1875 0.47583H4.1875C4.03832 0.47583 3.89524 0.535093 3.78975 0.640583C3.68426 0.746072 3.625 0.889146 3.625 1.03833V3.85083H0.8125C0.663316 3.85083 0.520242 3.91009 0.414753 4.01558C0.309263 4.12107 0.25 4.26415 0.25 4.41333V13.4133C0.25 13.5625 0.309263 13.7056 0.414753 13.8111C0.520242 13.9166 0.663316 13.9758 0.8125 13.9758H9.8125C9.96168 13.9758 10.1048 13.9166 10.2102 13.8111C10.3157 13.7056 10.375 13.5625 10.375 13.4133V10.6008H13.1875C13.3367 10.6008 13.4798 10.5416 13.5852 10.4361C13.6907 10.3306 13.75 10.1875 13.75 10.0383V1.03833C13.75 0.889146 13.6907 0.746072 13.5852 0.640583C13.4798 0.535093 13.3367 0.47583 13.1875 0.47583ZM9.25 12.8508H1.375V4.97583H9.25V12.8508ZM12.625 9.47583H10.375V4.41333C10.375 4.26415 10.3157 4.12107 10.2102 4.01558C10.1048 3.91009 9.96168 3.85083 9.8125 3.85083H4.75V1.60083H12.625V9.47583Z" fill="#696F79"/>
            </svg>
          </div>
        </div>
        <div class="or-wrapper">
          <div class="line"></div>
          <span>or</span>
          <div class="line"></div>
        </div>
        <span class="send-text">Send the Key via e-mail:</span>
        <span class="send-email">{{ clientKey.email }}</span>
      </div>
      <button
        id="dialoge_continue"
        class="btn dialog-btn"
        @click="sendKey">
        <span class="dialog-btn-text">Send</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "KeyManagementPopup",
  emits: ["closed", "sendKey", "genKey"],
  props: {
    action: {
      type: String,
      required: true,
      validator: function (value) {
        const validActions = ['send', 'gen'];
        return validActions.includes(value);
      },
    },
    clientKey: {
      required: true,
    },
  },
  data() {
    return {
      activeDialog: false,
      showTooltip: false,
    }
  },
  methods: {
    sendKey() {
      this.$emit('sendKey', this.clientKey.id);
      this.closePopups();
    },
    openPopups() {
      this.activeDialog = true;
    },
    closePopups() {
      this.activeDialog = false;
      this.$emit("closed");
    },
    copyKeyToBuffer() {
      navigator.clipboard.writeText(this.clientKey.apiKey)
        .then(() => {
          this.showTooltip = true;

          setTimeout(() => {
            this.showTooltip = false;
          }, 2000);
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
        });
    },
  }
};
</script>

<style scoped>
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay {
  animation: show .3s linear;
}

.key-management-wrapper {
  width: 100%;
  max-width: 415px;
  border: 1px solid #D7DCE6;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 40px 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

.close-dialog {
  width: 70px;
  height: 70px;
  padding: 23px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.close-dialog svg {
  width: 100%;
  height: 100%;
}

.close-dialog svg path {
  transition: .5s all;
}

.close-dialog:hover svg path {
  fill: var(--accent-color);
}

.close-dialog img {
  display: block;
  width: 100%;
}

.key-management-wrapper .dialog-btn {
  min-width: 213px;
  width: 100%;
  max-width: 140px;
  padding: 13px 12px;
  background-color: var(--white-color);
  border-color: var(--accent-color);
}

.key-management-wrapper .dialog-btn:hover {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
}

.key-management-wrapper .dialog-btn:hover .dialog-btn-text {
  color: var(--white-color);
}

.key-management-wrapper > svg {
  margin: 0 0 25px 0;
}

.dialog-btn-text {
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: var(--accent-color);
  transition: .5s all;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}

.body-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 32px 32px;
}

.body-wrapper .title {
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  color: #292B2F;
  margin: 0 0 25px 0;
}

.body-wrapper .subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  color: var(--secondary-text-color);
  margin: 0 0 10px 0;
}

.body-wrapper .key-holder {
  border-radius: 16px;
  padding: 10px 16px;
  background: #EBEFF6;
  margin: 0 0 30px 0;
}

.body-wrapper .key-holder .key-wrapper {
  padding: 15px 0;
  border-bottom: 1px solid #D7DCE5;
}

.body-wrapper .key-holder .key-wrapper span {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  word-wrap: anywhere;
  color: var(--secondary-black-color);
}

.body-wrapper .key-holder .copy-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 15px 0;
  gap: 5px;
}

.body-wrapper .key-holder .copy-wrapper span {
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  color: var(--secondary-text-color);
  border-bottom: 1px solid var(--secondary-text-color);
}

.body-wrapper .or-wrapper {
  display: flex;
  gap: 15px;
  align-items: center;
  margin: 0 0 30px 0;
}

.body-wrapper .or-wrapper .line {
  width: 151px;
  height: 1px;
  border: 1px solid #EBEFF6
}

.body-wrapper .send-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  color: var(--secondary-text-color);
  margin: 0 0 10px 0;
}

.body-wrapper .send-email {
  width: fit-content;
  padding: 8px;
  border-radius: 16px;
  background: #EBEFF6;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: var(--secondary-text-color);
}

.tooltip {
  position: absolute;
  background-color: #fff;
  color: var(--secondary-text-color);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  transform: translate(0, -90%);
}

</style>
