<template>
  <div class="wrapper">
    <AlertErrorMsg
      :title="errorMessage"
      class="page-alert-error"
      ref="alert"
    />
    <Sidebar />
    <div class="content-wrapper">
      <HeaderRegistered title="PME Support"/>
      <main class="main">
        <section class="profile-section">
          <ul class="profile-nav">
            <router-link
              v-for="(topic, index) in topics"
              :key="index"
              :to="{ path: `/pme-support/topic/${topic}/=` }"
              tag="li"
              :id="`topic_${topic}`"
              @click="setActiveTopic()"
              :class="{ active: $route.path.includes(`/topic/${topic}`)}"
            >
              <a>{{ topic }}</a>
            </router-link>
          </ul>
          <div class="profile-content">
            <router-view :posts="activeTopic ? getActiveTopicPosts() : []" @pushTo="pushTo($event)" />
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import Sidebar from "../components/Sidebar";
import HeaderRegistered from "../components/layout/HeaderRegistered";
import AlertErrorMsg from "@/components/profile/AlertErrorMsg.vue";

export default {
  name: "PmeSupport",
  components: {
    AlertErrorMsg,
    Sidebar,
    HeaderRegistered,
  },
  data() {
    return {
      activeTopic: null,
      topics: null,
      posts: null,
      errorMessage: "",
    }
  },
  watch: {
    '$route.params.topicName': {
      immediate: true,
      handler(newTopicName) {
        if (newTopicName) {
          this.setActiveTopicByName(newTopicName);
        }
      }
    }
  },
  methods: {
    setActiveTopic(topic) {
      this.activeTopic = topic;
    },
    setActiveTopicByName(topicTitle) {
      this.activeTopic = topicTitle;
    },
    getSupportPosts() {
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.publicPage.getSupportPosts().then((res) => {
          this.topics = [...new Set(res.data.body.map(post => post.postTopic))].sort()
          this.posts = res.data.body.map(post => ({
            ...post,
            relatedPosts: post.relatedPosts.map(relatedPost => {
              const postToMap = res.data.body.find(post => post.id === relatedPost);
              if (postToMap) {
                return { id: postToMap.id, title: postToMap.postTitle }
              }
            })
          }));
          this.activeTopic = this.topics[0];
          this.$router.push({path: `/pme-support/topic/${this.topics[0]}/=`});
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
      });
    },
    getActiveTopicPosts() {
      if (this.posts) {
        return this.posts.filter((post) => post.postTopic === this.activeTopic);
      }
    },
    pushTo(article) {
      const postToPush = this.posts.find(post => post.id === article.id);
      if (postToPush) {
        this.activeTopic = postToPush.postTopic;
        this.$router.push({path: `/pme-support/topic/${postToPush.postTopic}/${postToPush.postTitle}`});
      } else {
        this.errorMessage = "Post with name '" + article.title + "' was not found";
        this.$refs.alert.showAlert();
      }
    },
  },
  mounted() {
    document.title = 'PME Support'
    this.getSupportPosts();
  },
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

p {
  margin-top: 0;
}

ul {
  padding: 0;
}

.wrapper {
  display: flex;
}

.content-wrapper {
  width: calc(100% - 120px);
}

.profile-section {
  display: flex;
  background-color: var(--white-color);
  padding: 0 15px;
  align-items: initial;
}

.profile-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 270px;
  border-right: 1px solid var(--border-color);
  margin-top: 0;
}

.profile-nav li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--light-gray);
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all .2s;
  border-bottom: 1px solid var(--border-color);
}

.profile-nav li:first-child {
  border-top: 1px solid var(--border-color);
}

.profile-nav li:after {
  content: '';
  width: 8px;
  height: 100%;
  background-color: var(--accent-color);
  border-radius: 10px 0 0 10px;
  opacity: 0;
  transition: all .2s;
}

.profile-nav li a {
  display: block;
  color: var(--light-gray);
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 25px 0 25px 20px;
}

.profile-nav li a:hover {
  color: var(--secondary-black-color);
  background-color: rgba(28, 202, 205, 0.05);
}

.profile-nav li:hover:after  {
  opacity: 1;
}

.profile-nav li.active::after {
  opacity: 1;
}

.profile-nav li.active a {
  background: var(--active-accent-color);
  color: var(--secondary-black-color);
}

.profile-content {
  width: calc(100% - 270px);
  padding: 20px 40px;
}
</style>