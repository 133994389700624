<template>
  <div class="wrapper">
    <AlertErrorMsg
      ref="alert"
      :title="errorMessage"
    />
    <Sidebar />
    <div class="content-wrapper">
      <HeaderRegistered
        :without-route-name="true"
        :returnPath="true"
        :sub-title="redirectTitle"
        :should-go-back-in-route="true"
      />
      <main class="main">
        <router-view
          @title-show="setPageTitle"
          @error-show="showErrorMessage"
        />
      </main>
    </div>
  </div>
</template>

<script>

import Sidebar from "../../../Sidebar";
import AlertErrorMsg from "../../../profile/AlertErrorMsg";
import HeaderRegistered from "../../../layout/HeaderRegistered";

export default {
name: "CaseEmailFolder",
  data() {
    return {
      loadFlag: true,
      redirectTitle: '',
      errorMessage: ''
    }
  },
  components: {
    Sidebar,
    AlertErrorMsg,
    HeaderRegistered
  },
  methods: {
    showErrorMessage(errorText) {
      this.errorMessage = errorText;
      this.$refs.alert.showAlert();
    },
    setPageTitle(title) {
      this.redirectTitle = title;
    }
  }
}
</script>

<style scoped>

</style>