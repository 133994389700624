<template>
  <div class="tooltip" v-if="visibleTooltip" :class="[{'active': activeTooltip}, sideClass]" ref="tooltipEl">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    targetRef: {
      required: true
    },
    side: {
      type: String,
      required: false,
      default: 'top',
      validator: (value) => ['top', 'bottom'].includes(value),
    },
  },
  computed: {
    sideClass() {
      return `${this.side}`;
    },
  },
  data() {
    return {
      visibleTooltip: false,
      activeTooltip: false,
      targetElement: null,
    };
  },
  methods: {
    showTooltip() {
      this.visibleTooltip = true;

      this.$nextTick(() => {
        const targetElementRect = this.targetElement.getBoundingClientRect();
        const tooltip = this.$refs.tooltipEl;
        const tooltipRect = tooltip.getBoundingClientRect();
        tooltip.style.left = `${targetElementRect.left - 24}px`;
        switch (this.sideClass) {
          case 'bottom':
            tooltip.style.top = `${targetElementRect.bottom + 6}px`;
            break;
          case 'top':
          default:
            tooltip.style.top = `${targetElementRect.top - tooltipRect.height - 6}px`;
        }
        this.activeTooltip = true;
      });
    },
    hideTooltip() {
      this.activeTooltip = false;
      this.visibleTooltip = false;
    },
    addHoverListener() {
      this.targetElement = this.targetRef instanceof HTMLElement ? this.targetRef : this.targetRef.value;
      if (!this.targetElement) return;
      this.targetElement.addEventListener('mouseenter', this.showTooltip);
      this.targetElement.addEventListener('mouseleave', this.hideTooltip);
    },
    removeHoverListener() {
      if (!this.targetElement) return;
      this.targetElement.removeEventListener('mouseenter', this.showTooltip);
      this.targetElement.removeEventListener('mouseleave', this.hideTooltip);
    },
  },
  mounted() {
    if (this.targetRef) {
      this.addHoverListener();
    }
  },
  beforeDestroy() {
    this.removeHoverListener();
  },
  watch: {
    'targetRef': {
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal || newVal === oldVal) return;
        this.addHoverListener();
      }
    },
  }
};
</script>

<style scoped>
.tooltip {
  position: fixed;
  visibility: hidden;
  background-color: var(--white-color);
  border: 0.5px solid var(--light-gray);
  box-shadow: 0 0 5px 0.5px var(--light-gray);
  padding: 10px;
}

.tooltip span {
  font-size: 14px;
  color: var(--secondary-text-color);
  white-space: break-spaces;
}

.active {
  visibility: visible;
}
</style>