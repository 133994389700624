import { render, staticRenderFns } from "./CaseDetails.vue?vue&type=template&id=d6405850&scoped=true"
import script from "./CaseDetails.vue?vue&type=script&lang=js"
export * from "./CaseDetails.vue?vue&type=script&lang=js"
import style0 from "./CaseDetails.vue?vue&type=style&index=0&id=d6405850&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6405850",
  null
  
)

export default component.exports