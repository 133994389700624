<template>
  <div class="notification-wrapper">
    <div class="holder-search">
      <CustomSearch
          id="notification_search_billing"
      />
    </div>
    <div class="holder-table">
        <table cellpadding="0" cellspacing="0" border="0">
          <TableHeader>
          <tr>
            <th>
              <div class="table-header-box">
                <span class="header-table-text"> - </span>
              </div>
            </th>
            <th>
              <div class="table-header-box">
                <span class="header-table-text"> - </span>
              </div>
            </th>
            <th>
              <div class="table-header-box">
                <span class="header-table-text"> - </span>
              </div>
            </th>
            <th>
              <div class="table-header-box">
                <span class="header-table-text"> - </span>
              </div>
            </th>
            <th>
              <div class="table-header-box">
                <span class="header-table-text"> - </span>
              </div>
            </th>
            <th>
              <div class="table-header-box">
                <span class="header-table-text"> - </span>
              </div>
            </th>
            <th>
              <div class="table-header-box">
                <span class="header-table-text"> - </span>
              </div>
            </th>
          </tr>
          </TableHeader>
          <Loader v-if="loadFlag" :load-flag="loadFlag" />
          <template v-else>
            <NotificationsBillingItem v-if="false" />
            <p v-else class="no-found">not found</p>
          </template>
        </table>
    </div>
  </div>
</template>

<script>
import NotificationsBillingItem from "./NotificationsBillingItem";
import TableHeader from "../table/TableHeader";
import CustomSearch from "../inputs/CustomSearch";
import Loader from "../loader/Loader";
export default {
  name: "NotificationsBilling",
  components: {Loader, NotificationsBillingItem, TableHeader, CustomSearch},
  data() {
    return {
      loadFlag: false,
    };
  },
}
</script>

<style scoped>
.holder-search {
  max-width: 300px;
  margin-bottom: 26px;
}
</style>
