<template>
  <div class="case-email-list">
      <div v-for="(item, index) in casesEmails"
          :key="index"
           class="case-email-item"
           :class="{disable: !item.isConnected}"
      >
        <a
            @click="!item.isConnected ? changePasswordEmail(item)  : $router.push({path: `/cases/${caseId}/emails/${item.emailId}`, params: {emailId: item.emailId}}).catch(()=>{})"
            class="case-email-item-name"
        >
          {{item.email}}
        </a>
        <div class="case-message-remove-btn-wrap" :id="`remove_emails_${index}`">
          <button
              :id="`remove_emails_${item.filename}`"
              class="btn btn-deactivate"
              @click="confirmDeactivateUser(item)"
          >
            <span class="btn-text">Remove</span>
            <div class="btn-icon">
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10 0.499969C4.47 0.499969 0 4.96997 0 10.5C0 16.03 4.47 20.5 10 20.5C15.53 20.5 20 16.03 20 10.5C20 4.96997 15.53 0.499969 10 0.499969ZM15 14.09L13.59 15.5L10 11.91L6.41 15.5L5 14.09L8.59 10.5L5 6.90997L6.41 5.49997L10 9.08997L13.59 5.49997L15 6.90997L11.41 10.5L15 14.09Z"
                    fill="#D7DCE6"/>
              </svg>
            </div>
          </button>
        </div>
      </div>
    <CommonDialog
      ref="commonDialogDeleteEmail"
    >
      <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z"
          fill="#082655"/>
      </svg>
      <h2 class="dialog-message-title">Success!</h2>
      <p class="dialog-message-description" style="display:block;">The e-mail integration was deleted.</p>
    </CommonDialog>
    <InfoDialog
      ref="infoDialog"
      :title="infoDialogData.dialogTitle"
      :text="infoDialogData.dialogText"
      :confirmBtnText="infoDialogData.confirmBtnText"
      :cancelBtnText="infoDialogData.cancelBtnText"
      @onConfirm="removeEmail"/>
  </div>
</template>

<script>
import checkPermission from "../../../utils/permission";
import InfoDialog from "@/components/popups/InfoDialog.vue";
import CommonDialog from "@/components/popups/CommonDialog.vue";
import { createDeleteDialog } from "@/constants/infoDialogData";

export default {
  name: "CaseEmailsItem",
  components: {
    CommonDialog,
    InfoDialog
  },
  props: {
    casesEmails: [],
  },
  data() {
    return {
      el: {
        isActive: true
      },
      caseId: this.$route.params.slug,
      infoDialogData: {},
      emailToRemove: null,
    }
  },
  methods: {
    checkPermission,
    removeEmail() {
      this.$emit('remove', this.emailToRemove)
      this.$refs.commonDialogDeleteEmail.openPopups();
    },
    changePasswordEmail(email) {
      this.$emit('changePasswordEmail', email)
    },
    confirmDeactivateUser(email) {
      this.infoDialogData = createDeleteDialog('e-mail integration');
      this.$refs.infoDialog.openPopup(email);
      this.emailToRemove = email;
    },
  }
}
</script>

<style scoped>

.btn-text {
  display: block;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: var(--secondary-text-color);
  transition: .5s all;
  max-width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-icon {
  width: 18px;
  height: 18px;
  background-color: #fff;
}

.case-email-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px 0px;
  border-bottom: 0.5px solid #D7DCE6;
}

.case-email-item.disable .case-email-item-name {
  color: var(--light-gray);
}

.case-email-list {
  margin-top: 34px;
}

.btn-deactivate {
  width: 129px;
  height: 35px;
  min-width: inherit;
  font-size: 12px;
  font-weight: 500;
}

.btn-text {
  margin-right: 12px;
  font-weight: 500;
  font-size: 14px;
  text-transform: none;
  color: #373C44;
}

.case-message-remove-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.case-email-item-name {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #373C44;
  transition: all .5s
}

.dialog-message-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #696F79;
  display: block;
  width: 100%;
  margin-top: 20px;
}

</style>
