<template>
  <div>
    <Header/>
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
    <div class="container-small">
      <section class="section register-section">
        <div class="holder-img">
          <img :src="signUpIcon" alt="register-img">
        </div>
        <div class="holder-form">
          <form @submit.prevent="submit">
            <h2 class="register-title">Register new Account!</h2>
            <p class="register-text">
              Create new account and join your Company Cases. Do you already have an account?
              <router-link
                  id="register_account_login_link"
                  class="log-in"
                  tag="a"
                  :to="{name: 'LoginInAccount'}"
              >Log in</router-link>
            </p>
            <div class="form-group"
                 :class="{ 'form-group--error': $v.formData.firstName.$error }"
            >
              <label for="register_account_first-name_input" class="label">First name*</label>
              <input
                  id="register_account_first-name_input"
                  type="text"
                  placeholder="Enter your first name"
                  class="input"
                  :class="false ? 'is-invalid' : ''"
                  autocomplete="off"
                  :maxlength="userFirstNameMax"
                  v-model="$v.formData.firstName.$model"
              >
              <div class="error" v-if="!$v.formData.firstName.required">Field is required</div>
              <div class="error" v-if="!$v.formData.firstName.minLength || !$v.formData.firstName.maxLength">
                First name should have from 1 to 50 alphabetic characters
              </div>
            </div>
            <div class="form-group"
                 :class="{ 'form-group--error': $v.formData.lastName.$error }"
            >
              <label for="register_account_last-name_input" class="label">Last name*</label>
              <input
                  id="register_account_last-name_input"
                  type="text"
                  placeholder="Enter your last name"
                  class="input"
                  :class="false ? 'is-invalid' : ''"
                  autocomplete="off"
                  :maxlength="userLastNameMax"
                  v-model="$v.formData.lastName.$model"
              >
              <div class="error" v-if="!$v.formData.lastName.required">Field is required</div>
              <div class="error" v-if="!$v.formData.firstName.minLength || !$v.formData.firstName.maxLength">
                Last name should have from 1 to 50 alphabetic characters
              </div>
            </div>
            <div class="form-group group-password"
                 :class="{ 'form-group--error': $v.formData.password.$error }"
            >
              <label for="register_account_password_input" class="label">Password*</label>
              <input
                  id="register_account_password_input"
                  @focus="focus = true"
                  @blur="focus = false"
                  :type="passwordType"
                  v-model="$v.formData.password.$model"
                  class="input input-password"
                  placeholder="Create a password"
                  :class="false ? 'is-invalid' : ''"
                  autocomplete="off"
              >
              <div class="error" v-if="!$v.formData.password.required">Field is required</div>
              <svg v-if="passwordType == 'password'"
                   @click="togglePassword"
                   class="invite-input-password"
                   width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M19 7C19 7 15.4375 0.5 9.5 0.5C3.5625 0.5 0 7 0 7C0 7 3.5625 13.5 9.5 13.5C15.4375 13.5 19 7 19 7ZM1.39294 7C1.96712 7.86849 2.62752 8.67737 3.36419 9.41445C4.8925 10.9378 6.9825 12.3182 9.5 12.3182C12.0175 12.3182 14.1063 10.9378 15.637 9.41445C16.3737 8.67737 17.0341 7.86849 17.6082 7C17.0341 6.13151 16.3737 5.32263 15.637 4.58555C14.1063 3.06218 12.0163 1.68182 9.5 1.68182C6.9825 1.68182 4.89369 3.06218 3.363 4.58555C2.62632 5.32263 1.96592 6.13151 1.39175 7H1.39294Z"
                      fill="#D7DCE6"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M9.5 4.0451C8.71264 4.0451 7.95753 4.35638 7.40078 4.91047C6.84403 5.46455 6.53125 6.21605 6.53125 6.99964C6.53125 7.78324 6.84403 8.53474 7.40078 9.08882C7.95753 9.64291 8.71264 9.95419 9.5 9.95419C10.2874 9.95419 11.0425 9.64291 11.5992 9.08882C12.156 8.53474 12.4688 7.78324 12.4688 6.99964C12.4688 6.21605 12.156 5.46455 11.5992 4.91047C11.0425 4.35638 10.2874 4.0451 9.5 4.0451ZM5.34375 6.99964C5.34375 5.90261 5.78164 4.85051 6.56109 4.07479C7.34054 3.29908 8.39769 2.86328 9.5 2.86328C10.6023 2.86328 11.6595 3.29908 12.4389 4.07479C13.2184 4.85051 13.6562 5.90261 13.6562 6.99964C13.6562 8.09668 13.2184 9.14878 12.4389 9.9245C11.6595 10.7002 10.6023 11.136 9.5 11.136C8.39769 11.136 7.34054 10.7002 6.56109 9.9245C5.78164 9.14878 5.34375 8.09668 5.34375 6.99964Z"
                      fill="#D7DCE6"/>
              </svg>
              <svg v-if="passwordType == 'text'"
                   @click="togglePassword"
                   class="invite-input-text"
                   width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.8644 12.4404C17.8844 10.8275 19.0006 9 19.0006 9C19.0006 9 15.4381 3.15625 9.50062 3.15625C8.36014 3.15976 7.23256 3.37239 6.1875 3.781L7.10187 4.60019C7.8707 4.3496 8.68238 4.22053 9.50062 4.21875C12.0181 4.21875 14.1069 5.45975 15.6376 6.82931C16.3743 7.49198 17.0347 8.2192 17.6089 9C17.54 9.09244 17.464 9.19444 17.3773 9.306C16.9795 9.816 16.3917 10.496 15.6376 11.1707C15.4417 11.346 15.2374 11.5192 15.0237 11.6871L15.8644 12.4404Z"
                    fill="#696F79"/>
                <path
                    d="M13.416 10.2493C13.681 9.5861 13.73 8.86924 13.5575 8.18209C13.3849 7.49494 12.9978 6.86576 12.4413 6.36776C11.8847 5.86976 11.1815 5.52342 10.4135 5.36902C9.64549 5.21463 8.84429 5.25854 8.1031 5.49564L9.08041 6.37008C9.53678 6.31163 10.0021 6.34909 10.4395 6.47948C10.8769 6.60987 11.2743 6.8296 11.6003 7.12128C11.9263 7.41295 12.1719 7.76855 12.3176 8.15989C12.4633 8.55123 12.5052 8.96756 12.4399 9.37589L13.416 10.2493ZM9.92116 11.6295L10.8973 12.5028C10.1561 12.7399 9.3549 12.7838 8.58691 12.6294C7.81892 12.4751 7.11572 12.1287 6.55914 11.6307C6.00255 11.1327 5.61545 10.5035 5.4429 9.81638C5.27034 9.12923 5.31942 8.41237 5.58441 7.7492L6.56172 8.62364C6.4964 9.03197 6.53827 9.4483 6.68399 9.83964C6.82972 10.231 7.07531 10.5866 7.4013 10.8783C7.72729 11.1699 8.12472 11.3897 8.5621 11.5201C8.99948 11.6504 9.46479 11.6879 9.92116 11.6295Z"
                    fill="#696F79"/>
                <path
                    d="M3.97812 6.31182C3.76437 6.48182 3.55894 6.65394 3.363 6.82926C2.62633 7.49193 1.96593 8.21914 1.39175 8.99995L1.62331 9.30595C2.02113 9.81595 2.60894 10.4959 3.363 11.1706C4.89369 12.5402 6.98369 13.7812 9.5 13.7812C10.3503 13.7812 11.1506 13.6399 11.8988 13.3987L12.8131 14.2189C11.7681 14.6275 10.6405 14.8402 9.5 14.8437C3.5625 14.8437 0 8.99995 0 8.99995C0 8.99995 1.11506 7.17138 3.13619 5.55957L3.97694 6.31288L3.97812 6.31182Z"
                    fill="#696F79"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M16.2051 15.751L1.95508 3.00103L2.79583 2.24878L17.0458 14.9988L16.2051 15.751Z"
                      fill="#696F79"/>
              </svg>
              <div v-show="focus" class="password-tooltip">
                <p class="register-new-acc-text">Password must contain:</p>
                <div class="holder-conditions">
                  <div class="group-conditions">
                    <div class="condition-item" :class="hasMinimumLength ? 'has-required' : 'no-required'">
                      <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                          fill="#8692A6"/>
                      </svg>
                      <p>8 or more characters</p>
                    </div>
                    <div class="condition-item" :class="hasLowercase ? 'has-required' : 'no-required'">
                      <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                          fill="#8692A6"/>
                      </svg>
                      <p>Lowercase letter</p>
                    </div>
                    <div class="condition-item" :class="hasSpecial ? 'has-required' : 'no-required'">
                      <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                          fill="#8692A6"/>
                      </svg>
                      <p>Symbols</p>
                    </div>
                    <div class="condition-item" :class="hasUppercase ? 'has-required' : 'no-required'">
                      <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                          fill="#8692A6"/>
                      </svg>
                      <p>Uppercase letter</p>
                    </div>
                    <div class="condition-item" :class="hasNumber ? 'has-required' : 'no-required'">
                      <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                          fill="#8692A6"/>
                      </svg>
                      <p>Numbers</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group group-password"
                 :class="{ 'form-group--error': $v.repeatPassword.$error }"
            >
              <label for="register_account_confirm-password_input" class="label">Repeat password*</label>
              <input
                 id="register_account_confirm-password_input"
                 :type="passwordConfirmType"
                 v-model="$v.repeatPassword.$model"
                 class="input"
                 placeholder="Repeat a password"
                 :class="false ? 'is-invalid' : ''"
                 autocomplete="off"
              >
              <div class="error" v-if="!$v.repeatPassword.required">Field is required</div>
              <div class="error" v-if="!$v.repeatPassword.sameAsPassword">Passwords must be identical.</div>
              <svg v-if="passwordConfirmType == 'password'"
                   @click="toggleConfirmPassword"
                   class="invite-input-password"
                   width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M19 7C19 7 15.4375 0.5 9.5 0.5C3.5625 0.5 0 7 0 7C0 7 3.5625 13.5 9.5 13.5C15.4375 13.5 19 7 19 7ZM1.39294 7C1.96712 7.86849 2.62752 8.67737 3.36419 9.41445C4.8925 10.9378 6.9825 12.3182 9.5 12.3182C12.0175 12.3182 14.1063 10.9378 15.637 9.41445C16.3737 8.67737 17.0341 7.86849 17.6082 7C17.0341 6.13151 16.3737 5.32263 15.637 4.58555C14.1063 3.06218 12.0163 1.68182 9.5 1.68182C6.9825 1.68182 4.89369 3.06218 3.363 4.58555C2.62632 5.32263 1.96592 6.13151 1.39175 7H1.39294Z"
                      fill="#D7DCE6"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M9.5 4.0451C8.71264 4.0451 7.95753 4.35638 7.40078 4.91047C6.84403 5.46455 6.53125 6.21605 6.53125 6.99964C6.53125 7.78324 6.84403 8.53474 7.40078 9.08882C7.95753 9.64291 8.71264 9.95419 9.5 9.95419C10.2874 9.95419 11.0425 9.64291 11.5992 9.08882C12.156 8.53474 12.4688 7.78324 12.4688 6.99964C12.4688 6.21605 12.156 5.46455 11.5992 4.91047C11.0425 4.35638 10.2874 4.0451 9.5 4.0451ZM5.34375 6.99964C5.34375 5.90261 5.78164 4.85051 6.56109 4.07479C7.34054 3.29908 8.39769 2.86328 9.5 2.86328C10.6023 2.86328 11.6595 3.29908 12.4389 4.07479C13.2184 4.85051 13.6562 5.90261 13.6562 6.99964C13.6562 8.09668 13.2184 9.14878 12.4389 9.9245C11.6595 10.7002 10.6023 11.136 9.5 11.136C8.39769 11.136 7.34054 10.7002 6.56109 9.9245C5.78164 9.14878 5.34375 8.09668 5.34375 6.99964Z"
                      fill="#D7DCE6"/>
              </svg>
              <svg v-if="passwordConfirmType == 'text'"
                   @click="toggleConfirmPassword"
                   class="invite-input-text"
                   width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.8644 12.4404C17.8844 10.8275 19.0006 9 19.0006 9C19.0006 9 15.4381 3.15625 9.50062 3.15625C8.36014 3.15976 7.23256 3.37239 6.1875 3.781L7.10187 4.60019C7.8707 4.3496 8.68238 4.22053 9.50062 4.21875C12.0181 4.21875 14.1069 5.45975 15.6376 6.82931C16.3743 7.49198 17.0347 8.2192 17.6089 9C17.54 9.09244 17.464 9.19444 17.3773 9.306C16.9795 9.816 16.3917 10.496 15.6376 11.1707C15.4417 11.346 15.2374 11.5192 15.0237 11.6871L15.8644 12.4404Z"
                    fill="#696F79"/>
                <path
                    d="M13.416 10.2493C13.681 9.5861 13.73 8.86924 13.5575 8.18209C13.3849 7.49494 12.9978 6.86576 12.4413 6.36776C11.8847 5.86976 11.1815 5.52342 10.4135 5.36902C9.64549 5.21463 8.84429 5.25854 8.1031 5.49564L9.08041 6.37008C9.53678 6.31163 10.0021 6.34909 10.4395 6.47948C10.8769 6.60987 11.2743 6.8296 11.6003 7.12128C11.9263 7.41295 12.1719 7.76855 12.3176 8.15989C12.4633 8.55123 12.5052 8.96756 12.4399 9.37589L13.416 10.2493ZM9.92116 11.6295L10.8973 12.5028C10.1561 12.7399 9.3549 12.7838 8.58691 12.6294C7.81892 12.4751 7.11572 12.1287 6.55914 11.6307C6.00255 11.1327 5.61545 10.5035 5.4429 9.81638C5.27034 9.12923 5.31942 8.41237 5.58441 7.7492L6.56172 8.62364C6.4964 9.03197 6.53827 9.4483 6.68399 9.83964C6.82972 10.231 7.07531 10.5866 7.4013 10.8783C7.72729 11.1699 8.12472 11.3897 8.5621 11.5201C8.99948 11.6504 9.46479 11.6879 9.92116 11.6295Z"
                    fill="#696F79"/>
                <path
                    d="M3.97812 6.31182C3.76437 6.48182 3.55894 6.65394 3.363 6.82926C2.62633 7.49193 1.96593 8.21914 1.39175 8.99995L1.62331 9.30595C2.02113 9.81595 2.60894 10.4959 3.363 11.1706C4.89369 12.5402 6.98369 13.7812 9.5 13.7812C10.3503 13.7812 11.1506 13.6399 11.8988 13.3987L12.8131 14.2189C11.7681 14.6275 10.6405 14.8402 9.5 14.8437C3.5625 14.8437 0 8.99995 0 8.99995C0 8.99995 1.11506 7.17138 3.13619 5.55957L3.97694 6.31288L3.97812 6.31182Z"
                    fill="#696F79"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M16.2051 15.751L1.95508 3.00103L2.79583 2.24878L17.0458 14.9988L16.2051 15.751Z"
                      fill="#696F79"/>
              </svg>
            </div>
            <div class="group">
              <div class="register-checkbox-wrap">
                <input type="checkbox"
                       id="register_account_checkbox_input"
                       class="input-checkbox"
                       v-model="formData.termsAndConditionVersion"
                >
                <label for="register_account_checkbox_input" class="label-checkbox">
                </label>
                <div class="register-checkbox-content">
                  By clicking the "Register Account" button, you give an agreement to PME's
                  <router-link
                      id="register_account_terms-conditions_link"
                      tag="a"
                      :to="{name: 'TermsOfConditions'}"
                      target= '_blank'
                  >Terms of use</router-link>
                  and
                  <router-link
                      id="register_account_privacy-policy_link"
                      tag="a"
                      :to="{name: 'PrivacyPolicy'}"
                      target= '_blank'
                  >Privacy Policy</router-link>
                </div>
              </div>
            </div>
            <div class="group">
              <button
                  id="register_account_button"
                  :disabled="!isValid || !formData.termsAndConditionVersion"
                  class="btn register-btn">Register Account
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import urlQuery from "../constants/urlQuery";
import LocalStorageService from "../services/LocalStorageService";
import Header from "../components/layout/Header";
import AlertErrorMsg from "../components/profile/AlertErrorMsg";
import {minLength, maxLength, required, sameAs } from "vuelidate/lib/validators";
import signUpIcon from "@/assets/img/sign-up.svg"

// LocalstorageService
const localStorageService = LocalStorageService.getService();

export default {
  name: "RegisterAccountInvite",
  components: {
    Header,
    AlertErrorMsg,
  },
  data() {
    return {
      signUpIcon,
      userFirstNameMax: 50,
      userLastNameMax: 50,
      passwordType: "password",
      passwordConfirmType: "password",
      repeatPassword: "",
      hasMinimumLength: false,
      hasNumber: false,
      hasLowercase: false,
      hasUppercase: false,
      hasSpecial: false,
      submitted: false,
      isValid: false,
      errorMessage: '',
      focus: false,
      formData: {
        firstName: "",
        lastName: "",
        password: "",
        termsAndConditionVersion: false,
        token: null,
      },
    }
  },
  validations: {
    formData: {
      firstName: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(50)
      },
      lastName: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(50)
      },
      password: {
        required,
      },
    },
    repeatPassword: {
      required,
      sameAsPassword: sameAs( function(){return this.formData.password})
    },
  },
  methods: {
    togglePassword() {
      this.passwordType = this.passwordType == "password" ? "text" : "password";
    },
    toggleConfirmPassword() {
      this.passwordConfirmType =
          this.passwordConfirmType == "password" ? "text" : "password";
    },
    validatePassword() {
      this.hasMinimumLength = (this.formData.password.length >= 8);
      this.hasNumber = /\d/.test(this.formData.password);
      this.hasLowercase = /[a-z]/.test(this.formData.password);
      this.hasUppercase = /[A-Z]/.test(this.formData.password);
      this.hasSpecial = /[#?!@$%/^()}{><.&|:;`',*~"-]/.test(this.formData.password);
      if (this.hasMinimumLength &&
          this.hasNumber &&
          this.hasLowercase &&
          this.hasUppercase &&
          this.hasSpecial &&
          this.repeatPassword &&
          this.repeatPassword === this.formData.password) {
            if (this.repeatPassword === this.formData.password) {
              this.isValid = true;
            } else {
              this.isValid = false;
            }
      } else {
        this.isValid = false;
      }
    },
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'

        this.$load(async () => {
          this.formData.token = urlQuery()
          await this.$api.auth.registerInvitedUsers(this.formData).then((res) => {
            localStorageService.setTokenLogin(res.data.body.token);
            this.$router.push({path: `/register-invite/phone-verify/`})
          })
        }, (error) => {
          if(error) {
            if(error.response) {
              this.errorMessage = Object.values(error.response.data.errors).flat().slice(1).join('; ');
            }else {
              this.errorMessage = Object.values(error.data.errors).flat().slice(1).join('; ');
            }
            this.$refs.alert.showAlert()
          }
        })
      }
    },
  },
  mounted() {
    document.title = 'Register'
    if(localStorage.getItem('invite_token')) {
      localStorage.removeItem('invite_token')
    }
  },
  watch: {
    'formData.password'() {
      this.validatePassword()
    },
    repeatPassword() {
      this.validatePassword()
    },
    'formData.termsAndConditionVersion'(oldVal) {
      oldVal ? this.formData.termsAndConditionVersion = 1 : this.formData.termsAndConditionVersion = 0
    },
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.register-title {
  font-size: 30px;
  font-weight: 700;
  color: #373C44;
}

.register-section {
  display: flex;
  justify-content: space-between;
}

.holder-img {
  width: 50%;
  margin: 15% 0 0;
}

.holder-form {
  width: 50%;
  align-self: flex-start;
}

.register-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: var(--secondary-black-color);
  margin-bottom: 40px;
  width: 400px;
}

.log-in {
  color: var(--accent-color);
  text-decoration: none;
}

.group-password {
  position: relative;
  margin-bottom: 20px;
}

.group-password .invite-input-password {
  position: absolute;
  top: 54px;
  right: 18px;
}

.group-password .invite-input-text {
  position: absolute;
  top: 52px;
  right: 18px;
}

.group-password input, .group-password label, .group button {
  width: 100%;
  display: block;
  border: none;
}

.group-password label {
  font-weight: 500;
  font-size: 14px;
  color: var(--secondary-black-color);
  margin-bottom: 20px;
}

.group-password input {
  padding: 15px 20px;
  border: 1px solid var(--input-placeholder-value-color);
  border-radius: 25px;
  background-color: var(--white-color);
}

.group-password input::placeholder {
  color: var(--input-placeholder-value-color);
}

.group-password input:focus {
  outline: none;
}

.register-btn {
  padding: 15px 0;
  border-radius: 25px;
}

.group .register-btn {
  border: 1px solid transparent;
}

.register-btn:disabled {
  background-color: var(--background-button-disabled-color);
}

/* start */

.register-new-acc-text {
  font-size: 12px;
  font-weight: 400;
  color: var(--secondary-text-color);
  margin: 0;
  padding: 10px 15px;
}

.holder-conditions {
  padding: 30px 25px 6px;
  box-shadow: 0 2px 5px var(--secondary-black-color);
  border-radius: 13px;
  display: flex;
}

.group-conditions {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.condition-item {
  display: flex;
  width: 50%;
  align-items: baseline;
}

.condition-item p {
  color: var(--secondary-text-color);
  font-weight: 400;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 10px;
}

.condition-item svg {
  margin-right: 9px;
  position: static;
}

.has-required svg path {
  fill: var(--accent-color);
}

.no-required svg path {
  fill: var(--error-color);
}

.no-required p {
  color: var(--error-color);
}

.register-checkbox-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 40px;
}

/* end */

.register-checkbox-content {
  width: calc(100% - 40px);
  margin-left: 15px;
  margin-right: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: var(--light-gray);
  max-width: 360px;
}

.register-checkbox-content a {
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  color: var(--accent-color);
}

.group .label-checkbox {
  cursor: pointer;
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: var(--white-color);
  border: 1px solid var(--secondary-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;
}

.label-checkbox:after {
  content: '';
  background: var(--accent-color);
  width: 16px;
  height: 16px;
  border-radius: 1px;
  opacity: 0;
  transition: all .3s ease;
}

.input-checkbox:checked + .label-checkbox:after {
  opacity: 1;
}

#register_account_checkbox_input {
  display: none;
}

.group {
  position: relative;
}

.password-tooltip {
  background: var(--white-color);
  position: absolute;
  bottom: calc(-100% - 60px);
  left: 0;
  z-index: 2;
  width: 100%;
}

</style>
