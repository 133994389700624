<template>
  <section class="section register-section">
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
    <div class="container-small">
      <div class="register-step">
        <div class="step-header">
          <div class="step-progress">
            <div class="bar progressbar" style="width: 25%;"></div>
          </div>
          <ul class="step-pills">
            <li class="step-item active"><span class="step-link"><span class="tabStatus">1 </span><span
                class="tabLabel">2 Factor Authentication</span></span></li>
            <li class="step-item"><span class="step-link"><span class="tabStatus">2 </span><span
                class="tabLabel">Company registration</span></span></li>
<!--            <li class="step-item"><span class="step-link"><span class="tabStatus">3 </span><span-->
<!--                class="tabLabel">Billing</span></span></li>-->
            <li class="step-item"><span href="#" class="step-link"><span class="tabStatus">3</span><span
                class="tabLabel">Profile photo</span></span></li>
          </ul>
        </div>
        <div class="register-form-container">
          <div class="register-info">
            <h1 class="register-info-title main-title">Get one-time password</h1>
          </div>
          <div class="form-content">
            <div :class="this.validatePhoneStatus ? 'phone-wrapper not-valid' : 'phone-wrapper' ">
              <span class="otp-subtitle">Your mobile number</span>
              <VuePhoneNumberInput
                  v-model="phone"
                  ref="phoneInputRef"
                  id="register_phone-verify_input"
                  :required="true"
                  :fetch-country="false"
                  :no-validator-state="false"
                  @update="validatePhone"
                  :error="validatePhoneStatus"
                  error-color="#FF2E2E"
                  valid-color="#082655"
                  :border-radius="25"
                  default-country-code="US"
              />
              <div v-if="validatePhoneStatus" class="phone-error" style="color:red;">Phone number is not valid</div>
            </div>
            <otp-code ref="otpInput"
                      :otpVerify="otpVerify"
                      :otpValid="otpValid"
                      @update:otpValid="otpValid = $event"
            />

            <button
                id="register_phone-resend_button"
                class="btn phone-verify-btn"
                v-if="!otpValid"
                v-text="phoneVerifyBtn"
                @click="sendPhone"
                :disabled="disabledButton"></button>
            <button
                @click="$router.push({path: '/register/firm-information'}).catch(()=>{})"
                id="register_phone-verify_button"
                class="btn phone-verify-btn"
                v-if="otpValid"
                v-text="'Continue'"></button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import urlQuery from "../../constants/urlQuery";
import LocalStorageService from "../../services/LocalStorageService";
import VuePhoneNumberInput from 'vue-phone-number-input';
import OtpCode from './OtpCode'
import AlertErrorMsg from "../profile/AlertErrorMsg";

// LocalstorageService
const localStorageService = LocalStorageService.getService();

export default {
  name: "PhoneVerify",
  components: {
    AlertErrorMsg,
    VuePhoneNumberInput,
    OtpCode,
  },
  props: {
    modelFullName: String,
    modelEmail: String,
  },
  data() {
    return {
      phone: "",
      dataPhone: '',
      validatePhoneStatus: false,
      count: 30,
      disabledButton: false,
      phoneVerifyBtn: 'Send Password',
      otpVerify: null,
      errorMessage: '',
      otpCode: null,
      otpValid: false,
    };
  },
  methods: {
    // Parse URL Queries
    validatePhone(event) {
      event.isValid ? this.validatePhoneStatus = false : this.validatePhoneStatus = true
      if (event.isValid) {
        this.dataPhone = event.formattedNumber
      }
    },
    handleUndisabledInputPhone() {
      this.$refs.otpInput.handleUndisabledInput();
    },
    sendPhone() {
      if(this.validatePhoneStatus) {
        return
      }
      if(!this.validatePhoneStatus === true) {
        this.handleUndisabledInputPhone()
      }
      this.$load(async () => {
        await this.$api.auth.phoneSend({phoneNumber: this.dataPhone}).then(() => {

        })
      }, (error) => {
        this.errorMessage = error.data.errors.message[0]
        this.$refs.alert.showAlert()
      })
      this.disabledButton = true
      let counter = setInterval(() => {
        this.count--
        this.phoneVerifyBtn = `Resend in ${this.count} sec`
        if(this.count === 0) {
          clearInterval(counter)
          this.disabledButton = false
          this.phoneVerifyBtn = `Resend code`
          this.count = 30
        }
      }, 1000)
    },
  },
  mounted() {
    const input = this.$refs.phoneInputRef.$refs.PhoneNumberInput.$refs.InputTel
    input.placeholder = "(000) 000 00 00";
    this.$load(async () => {
      await this.$api.auth.verifyEmail({code: urlQuery()}).then((res) => {
        localStorageService.setToken(res.data.body.token);
      })
    }, (error) => {
      if (error) {
        this.errorMessage = error.data.errors.message[0]
        this.$refs.alert.showAlert()
      }
    })
  },
}
</script>

<style>

.phone-wrapper {
  margin-bottom: 40px;
}

.register-form-container {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

.input-tel__input {
  box-shadow: none !important;
  caret-color: var(--secondary-black-color) !important;
}

.country-selector__label {
  display: none;
}

.input-tel__label {
  display: none;
}

.input-tel.has-hint .input-tel__input, .input-tel.has-value .input-tel__input {
  padding-top: 0;
  padding-bottom: 0;
}

.phone-verify-btn {
  width: 100%;
}

.phone-error {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: var(--alert-color);
  display: block;
  margin: 10px 0px;
}

.input-tel.input-phone-number {
  height: 48px !important;
  min-height: 48px !important;
}

.country-selector__input {
  height: 48px !important;
}

.input-phone-number .input-tel__input {
  height: 48px !important;
}

.vue-phone-number-input .select-country-container {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;
}

.input-tel__input {
  padding: 0 20px !important;
}

.input-tel__input {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: var(--secondary-black-color) !important;
  border: 1px solid var(--input-placeholder-value-color) !important;
}

.input-tel__input::placeholder {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: var(--input-placeholder-value-color) !important;
}

.input-tel__input:focus {
  outline: none !important;
}

.country-selector__input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.country-selector__input {
  border: 1px solid var(--input-placeholder-value-color) !important;
  border-radius: 25px 0px 0px 25px !important;
}

.input-tel.has-error:not(.is-valid) .input-tel__input[data-v-e59be3b4] {
  border-color: var(--alert-color) !important;
}

.not-valid .country-selector.has-value .country-selector__input {
  border-color: var(--alert-color) !important;
}

.country-selector.has-hint .country-selector__input,
.country-selector.has-value .country-selector__input {
  padding-top: 0px !important;
  padding-left: 30px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: var(--secondary-black-color) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.country-selector__toggle {
  right: 4px !important;
  top: calc(50% - 12px) !important;
}

.country-selector {
  height: 48px !important;
  min-height: 48px !important;
}

.country-selector__country-flag {
  top: 18px !important;
  left: 15px !important;
}

.iti-flag {
  height: 14px !important;
}

.phone-verify-btn {
  padding: 14px 12px;
  margin: 0 auto;
}

.step-link .tabStatus:after {
  width: 200px;
}
</style>
