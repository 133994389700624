<template>
  <ul class="profile-nav">
    <router-link
        v-for="(item, index) in users"
        :key="index"
        :id="`permission_individual_item_${index}`"
        tag="li"
        :to="{name: `Role Individual User`, params: { user_id: item.publicId } }"
    >
      <a @click="setTabIndive(item)">
        <div class="holder-contacts">
          <div class="contacts-imgs">
            <div class="img-item">
              <img
                  v-if="item.avatar"
                  :src="item.avatar"
                  onerror="this.src='https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png';"
                  alt=""
              >
              <img
                  v-else
                  src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png"
                  alt=""
              >
            </div>
          </div>
          <div class="contacts-count">
            <span v-text="(item.firstName.toLowerCase()[0].toUpperCase() + item.firstName.toLowerCase().slice(1)) + ' ' + (item.lastName.toLowerCase()[0].toUpperCase() + item.lastName.toLowerCase().slice(1))"></span>
            <span>{{item.mail}}</span>
          </div>
        </div>
        <span class="role-wrapper">
          <span class="individual-role-item">{{ getRoleNameFE(item.role) }}</span>
        </span>
      </a>
    </router-link>
  </ul>
</template>

<script>
import getRoleName from "@/mixins/getRoleName";

export default {
  name: "PermissionsList",
  data() {
    return {
      fullNameUsers: "",
    }
  },
  props: {
    users: {
      type: Array
    },
    tabIndive: {

    }
  },
  mixins: [getRoleName],
  methods: {
    handlerFullName(firstName, lastName) {
      return (firstName.toLowerCase()[0].toUpperCase() + firstName.toLowerCase().slice(1)) + ' ' + (lastName.toLowerCase()[0].toUpperCase() + lastName.toLowerCase().slice(1))
    },
    setTabIndive (item) {
      this.fullNameUsers = this.handlerFullName(item.firstName, item.lastName);
      this.$emit('setTabIndive', item)
    },
  },
  mounted() {
    setTimeout(() => {
      if(this.$route.params.slug || this.$route.params.case_id) {
        this.users.map((item) => {
          if(item.publicId === this.$route.params.case_id) {
            this.setTabIndive(item)
          }
        })
      }
    }, 1000)
  }
}
</script>

<style scoped>

p {
  margin-top: 0;
}

ul {
  padding: 0;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.profile-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-top: 0;
  height: 100%;
  max-height: 1300px;
  padding-right: 10px;
}

.profile-nav li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all .2s;
  border-bottom: 1px solid var(--border-color);
}

.profile-nav li:first-child {
  border-top: 1px solid var(--border-color);
}

.profile-nav li:after {
  content: '';
  width: 8px;
  height: 100%;
  background-color: var(--accent-color);
  border-radius: 10px 0 0 10px;
  opacity: 0;
  transition: all .2s;
}

.profile-nav li a {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  text-decoration: none;
  padding: 18px 0 18px 20px;
}

.profile-nav li:hover {
  color: var(--secondary-black-color);
  background-color: var(--active-accent-color);
}

.profile-nav span {
  display: inline-block;
  min-width: 95px;
  padding: 10px 8px;
  text-align: center;
  border-radius: 20px;
  margin-right: 10px;
}

.profile-nav li:hover:after  {
  opacity: 1;
}

.profile-nav li.active::after {
  opacity: 1;
}

.profile-nav li.active {
  color: var(--secondary-black-color);
  background-color: var(--active-accent-color);
}

.holder-contacts {
  display: flex;
  align-items: center;
}

.contacts-imgs {
  display: flex;
}

.img-item {
  width: 32px;
  height: 32px;
  overflow: hidden;
  border: 1px solid var(--white-color);
  border-radius: 50%;
  margin-left: -13px;
}

.img-item img {
  width: 100%;
  height: 100%;
}

.contacts-count {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.contacts-count span {
  font-weight: 500;
  font-size: 14px;
  color: var(--secondary-text-color);
  padding: 0;
  text-align: left;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.contacts-count span:last-child {
  font-weight: 400;
  font-size: 12px;
  color: var(--light-gray);
}

.profile-nav .individual-role-item {
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  min-width: auto;
  color: var(--secondary-text-color);
  text-decoration: none;
}

.profile-nav .individual-role-item:last-child {
  margin-right: 0;
}

a:visited {
  color: inherit;
  text-decoration: none;
}
</style>
