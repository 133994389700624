<template>
  <div class="case-data-info">
    <div class="case-user-info">
      <div class="user-data">
        <h3 class="user-data-title">{{dataActivity.countAttachments}}</h3>
        <span class="data-title-description">file attachments</span>
      </div>
      <div class="user-data">
        <h3 class="user-data-title">{{dataActivity.countActiveUsers}}</h3>
        <span class="data-title-description">active users</span>
      </div>
      <div class="user-data">
        <h3 class="user-data-title">{{dataActivity.countInvites}}</h3>
        <span class="data-title-description">invites amount</span>
      </div>
      <div class="user-data">
        <h3 class="user-data-title">{{dataActivity.countDevices}}</h3>
        <span class="data-title-description">devices amount</span>
      </div>
      <div class="user-data">
<!--        <h3 class="user-data-title">{{dataActivity.lastPaymentDate.split("-").join('/')}}</h3>-->
        <h3 class="user-data-title">{{ dataActivity.countExpiredInvites }}</h3>
        <span class="data-title-description">expired invitation</span>
      </div>
    </div>
    <CaseInternalStorageInfo
        :internalStorageGeneralSpace="dataActivity.storageStatistic.totalStorage"
        :usedStoragePercent="dataActivity.storageStatistic.usedStoragePercent"
        :usedStorage="dataActivity.storageStatistic.usedStorage"
    />
  </div>
</template>

<script>
import CaseInternalStorageInfo from "./CaseInternalStorageInfo";
export default {
  name: "CaseDataInfo",
  components: {
    CaseInternalStorageInfo
  },
  props: {
    dataActivity: {
      type: Object
    }
  }
}
</script>

<style scoped>

.case-data-info {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.case-user-info {
  width: 100%;
  max-width: 258px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
}

.user-data {
  margin: 0 0 10px 0;
  width: 124px;
  height: 77px;
  box-shadow: 0px 0px 15px rgba(5, 51, 48, 0.06);
  border-radius: 10px;
  overflow: hidden;
  padding: 14px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 6px;
}

.user-data:first-child {
  height: 45px;
  width: 100%;
  flex-direction: row;
}

.user-data:nth-last-child(2),
.user-data:last-child{
  margin-bottom: 0;
}

.user-data-title {
  margin: 0;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  color: var(--secondary-black-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data-title-description {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(--secondary-text-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
