export default function(instance) {
    return {
        getProfile(data) {
            return instance.get(`/profile`, data)
        },
        getProfileAvatar(data) {
            return instance.post(`/profile/avatar`, data)
        },
        deleteProfileAvatar(data) {
            return instance.delete(`/profile/avatar`, data)
        },
        updateProfileTerms(data) {
            return instance.put(`/profile/terms`, data)
        },
        updateProfilePassword(data) {
            return instance.put(`/profile/password`, data)
        },
        updateProfileInfo(data) {
            return instance.put(`/profile/info`, data)
        },
        getFirm(data) {
            return instance.get('/company', data)
        },
        updateFirm(data, companyId) {
            return instance.put(`/company/${companyId}`, data)
        },
        updateCompanyOwner(data, companyId) {
            return instance.put(`/company/${companyId}/owner`, data)
        },
        updateBillingContact(data, companyId) {
            return instance.put(`/company/${companyId}/billing`, data)
        },
        getProfileClient({clientId}) {
            return instance.get(`/profile/client/${clientId}`,)
        },
        getProfileUser({userId}) {
            return instance.get(`/profile/employee/${userId}`,)
        },

        checkProfileGoogleTwoFa(data) {
            return instance.get(`/profile/googleTwoFa`, data)
        },

        profileGoogleTwoFa(data) {
            return instance.post(`/profile/googleTwoFa`, data)
        },

        disableProfileGoogleTwoFa(data) {
            return instance.post(`/profile/googleTwoFa/disable`, data)
        },

        confirmProfileGoogleTwoFa(data) {
            return instance.post(`/profile/googleTwoFa/confirm`, data)
        },
        getGoogleTwoFaQr() {
            return instance.get(`/profile/googleTwoFa/qr`)
        },

        changeVerificationType(data) {
            return instance.put(`/profile/verification`, data)
        },

        updatePhoneStart(data) {
            return instance.put(`/profile/phone`, data)
        },
        verifyPhoneUpdate(data) {
            return instance.put(`/profile/phone/verify`, data)
        },
        updateEmailStart(data) {
            return instance.put(`/profile/email`, data)
        },
        updateEmailSecondStep(data) {
            return instance.put(`/profile/email/verify`, data)
        },
    }
}
