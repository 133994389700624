import { render, staticRenderFns } from "./ClientProfile.vue?vue&type=template&id=1591ab9c&scoped=true"
import script from "./ClientProfile.vue?vue&type=script&lang=js"
export * from "./ClientProfile.vue?vue&type=script&lang=js"
import style0 from "./ClientProfile.vue?vue&type=style&index=0&id=1591ab9c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1591ab9c",
  null
  
)

export default component.exports