let reformatDate = {
  methods: {
    reformatDate(dateString) {
      let date = new Date(dateString);
      let formattedDate = date.toISOString().split('T')[0];
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? '0' + minutes : minutes;

      let formattedTime = `${hours}:${minutes} ${ampm}`;
      return `${formattedDate} ${formattedTime}`;
    },
  },
}

export default reformatDate