export default function(instance) {
  return {
    getKeys(params) {
      return instance.get(`/keys`, {params})
    },
    sendKey(keyId) {
      return instance.post(`/keys/send/${keyId}`, {})
    },
    regenerateKey(keyId) {
      return instance.post(`/keys/regenerate/${keyId}`, {})
    },
  }
}
