<template>
  <li
      class="item notification-item js-item"
      :class="`notification-item-${notification.tag.toLowerCase()}`"
  >
    <div class="details">
      <div class="notification-header">
        <span class="notifications-title" v-text="notification.topic"></span>
        <span class="notifications-tag">
          <span v-text="getNotificationTag()"></span>
        </span>
        <div class="notification-delete" @click="changeStatusNotification(notification)">
          <svg data-v-1228be0d="" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-1228be0d="" d="M11.9997 0.333496C5.54801 0.333496 0.333008 5.5485 0.333008 12.0002C0.333008 18.4518 5.54801 23.6668 11.9997 23.6668C18.4513 23.6668 23.6663 18.4518 23.6663 12.0002C23.6663 5.5485 18.4513 0.333496 11.9997 0.333496ZM17.833 16.1885L16.188 17.8335L11.9997 13.6452L7.81134 17.8335L6.16634 16.1885L10.3547 12.0002L6.16634 7.81183L7.81134 6.16683L11.9997 10.3552L16.188 6.16683L17.833 7.81183L13.6447 12.0002L17.833 16.1885Z" fill="#D7DCE6"></path></svg>
        </div>
      </div>
      <span class="notifications-content">
          <span v-text="notification.message"></span>
      </span>
      <span
          class="notifications-date date"
          v-text="parseDate(notification.actionDate)">
      </span>
    </div>
    <!--    <button type="button"-->
    <!--            class="button-default button-dismiss js-dismiss close-notification"-->
    <!--            @click="changeStatus"-->
    <!--    >×-->
    <!--    </button>-->
  </li>
</template>

<script>
import parseDate from "../../mixins/parseDate";

export default {
  name: "HeaderNotificationsList",
  mixins: [parseDate],
  methods: {
    changeStatus() {
      this.$load(async () => {
        await this.$api.notifications.changeStatus(this.notification.uuid)
        this.$emit('changedStatus')
      })
    },
    changeStatusNotification(item) {
      this.$load(async () => {
        await this.$api.notifications.changeStatusNotification({
          notificationId: item.id,
        }).then(() => {
          this.$emit('changeStatus')
        })
      })
    },
    getNotificationTag() {
      if (this.notification.tag.toLowerCase() === 'clients') {
        return 'custodians'
      }
      return this.notification.tag;
    },
  },
  props: {
    notification: {
      type: Object,
      default: () => {
      }
    }
  }
}
</script>

<style scoped>

</style>