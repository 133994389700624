import axios from 'axios'
import LocalStorageService from "../services/LocalStorageService";
import router from "../router";
import store from "../store"
import fixBaseURL from "@/utils/fixBaseURL";

const getBaseUrl = () => {
  if (process.env.NODE_ENV !== "production") {
    return fixBaseURL(`${process.env.VUE_APP_API}/api/v1`);
  }
  return `${process.env.VUE_APP_API}/api/v1`;
}

const instance = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  }
})

window.instance = instance;

// LocalstorageService
const localStorageService = LocalStorageService.getService();

// Add a request interceptor
instance.interceptors.request.use(
  config => {
    const token = localStorageService.getAccessToken();
    const tokenLogin = localStorageService.getAccessTokenLogin();
    if (token) {
        localStorageService.clearTokenLogin()
        config.headers['Authorization'] = 'Bearer ' + token;
    }
    if (tokenLogin) {
        config.headers['Authorization'] = 'Bearer ' + tokenLogin;
    }
      config.headers['Cache-Control'] = 'no-cache';
      config.headers['Pragma'] = 'no-cache';
      config.headers['Expires'] = '0';
    return config;
  },
  error => {
    Promise.reject(error)
  }
  );

//Add a response interceptor

instance.interceptors.response.use((response) => {
  return response
}, function (error) {
    if(error.response.status === 500) {
        const data = {
            title: 'Something went wrong.',
            description: `Request ID - ${error.response.data.debugInfo.requestId}`,
        }
        store.commit('error/SET_GLOBAL_ERROR', data)
    }
   if (error.response.status === 401) {
       localStorageService.clearAllToLocalStorageData()
       router.push({name: 'LoginInAccount'})
   return Promise.reject(error);
}

return Promise.reject(error);
});



export default instance
