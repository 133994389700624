export default {
    login({ commit }, {params}) {
        return new Promise((resolve, reject) => {
            window.$axios.post(`/login`, params).then((res) => {
                commit('SET_LOGIN_DATA', res.data.body);
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    loginOtp({ commit }, {params}) {
        return new Promise((resolve, reject) => {
            window.$axios.post(`/login/otp`, params).then((res) => {
                commit('SET_LOGIN_DATA_OTP', res.data.body);
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },







}