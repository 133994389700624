let parseDateTime = {
    methods: {
        parseDateTime(data) {
            let newData = new Date(data)
            let options = {
                hour: 'numeric',
                minute: 'numeric',
            };
            return newData.toLocaleString("en-US", options)
        },
    },
}

export default parseDateTime