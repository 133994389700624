var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',_vm._l((7),function(item,i){return _c('tr',{key:i},[_vm._m(0,true),_vm._m(1,true),_vm._m(2,true),_vm._m(3,true),_vm._m(4,true),_vm._m(5,true),_vm._m(6,true)])}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"holder-info"},[_c('div',{staticClass:"info"},[_c('span',{},[_vm._v("name")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"holder-info"},[_c('div',{staticClass:"info"},[_c('span',{},[_vm._v("name")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"holder-info"},[_c('div',{staticClass:"info"},[_c('span',{},[_vm._v("name")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"holder-info"},[_c('div',{staticClass:"info"},[_c('span',{},[_vm._v("name")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"holder-info"},[_c('div',{staticClass:"info"},[_c('span',{},[_vm._v("name")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"holder-info"},[_c('div',{staticClass:"info"},[_c('span',{},[_vm._v("name")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"holder-info"},[_c('div',{staticClass:"info"},[_c('span',{},[_vm._v("name")])])])])
}]

export { render, staticRenderFns }