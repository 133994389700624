<template>
  <div class="holder-messages-list">
    <div class="messages-list">
      <ul>
        <Message
          v-for="(item, index) in messages"
          :key="index"
          :item="item"
          @changeCheckbox="$emit('changeCheckbox', $event)"
          @metaInfoPopup="openMetaInfoPopup"
          @downloadFile="$emit('downloadFile', $event)"
        />
      </ul>
    </div>
    <MetaInfoPopup
      ref="metaInfoPopup"
      :meta-info="metaInfo"
    />
  </div>
</template>

<script>
import Message from '@/components/сases/case-messages/Message';
import MetaInfoPopup from '@/components/popups/MetaInfoPopup';

export default {
  name: 'SetGroupMessages',
  emits: ['downloadFile', 'changeCheckbox'],
  components: {
    Message,
    MetaInfoPopup,
  },
  props: {
    messages: {
      type: Array
    }
  },
  data() {
    return {
      metaInfo: {},
    };
  },
  methods: {
    openMetaInfoPopup(metaInfo) {
      this.metaInfo = metaInfo;
      this.$refs.metaInfoPopup.openPopup();
    },
  },
};
</script>

<style scoped>
.holder-messages-list {
  display: flex;
  position: relative;
  flex-direction: column;
}
</style>
