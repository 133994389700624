<template>
  <div
      v-if="dialog"
      :class="dialog ? 'active': ''"
      class="overlay"
  >
    <div v-click-outside="closePopup" class="popup">
      <div class="popup-close" @click="closePopup">
        <svg fill="none"
            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.9997 0.333496C5.54801 0.333496 0.333008 5.5485 0.333008 12.0002C0.333008 18.4518 5.54801 23.6668 11.9997 23.6668C18.4513 23.6668 23.6663 18.4518 23.6663 12.0002C23.6663 5.5485 18.4513 0.333496 11.9997 0.333496ZM17.833 16.1885L16.188 17.8335L11.9997 13.6452L7.81134 17.8335L6.16634 16.1885L10.3547 12.0002L6.16634 7.81183L7.81134 6.16683L11.9997 10.3552L16.188 6.16683L17.833 7.81183L13.6447 12.0002L17.833 16.1885Z" fill="#D7DCE6" />
        </svg>
      </div>
      <div class="popup-content">
        <svg fill="none" height="94" viewBox="0 0 94 94" width="94" xmlns="http://www.w3.org/2000/svg">
          <path d="M47.0022 0.776367C37.8603 0.776367 28.9237 3.48725 21.3225 8.56622C13.7213 13.6452 7.79687 20.8641 4.29842 29.3101C0.799965 37.7561 -0.115389 47.0499 1.66811 56.0161C3.4516 64.9823 7.85384 73.2183 14.3181 79.6826C20.7824 86.1469 29.0184 90.5492 37.9847 92.3327C46.9509 94.1162 56.2447 93.2008 64.6907 89.7024C73.1367 86.2039 80.3556 80.2795 85.4346 72.6783C90.5135 65.0771 93.2244 56.1405 93.2244 46.9986C93.2244 34.7397 88.3546 22.9829 79.6862 14.3145C71.0179 5.6462 59.2611 0.776367 47.0022 0.776367ZM77.1911 31.4853L39.2311 69.4164L16.8133 46.9986C16.0471 46.2324 15.6167 45.1932 15.6167 44.1097C15.6167 43.0262 16.0471 41.987 16.8133 41.2208C17.5795 40.4546 18.6186 40.0242 19.7022 40.0242C20.7857 40.0242 21.8249 40.4546 22.5911 41.2208L39.2888 57.9186L71.4711 25.7653C71.8504 25.3859 72.3008 25.0849 72.7965 24.8796C73.2922 24.6743 73.8234 24.5686 74.36 24.5686C74.8965 24.5686 75.4277 24.6743 75.9234 24.8796C76.4191 25.0849 76.8695 25.3859 77.2488 25.7653C77.6282 26.1446 77.9292 26.595 78.1345 27.0907C78.3398 27.5864 78.4455 28.1176 78.4455 28.6541C78.4455 29.1907 78.3398 29.7219 78.1345 30.2176C77.9292 30.7133 77.6282 31.1637 77.2488 31.543L77.1911 31.4853Z" fill="#082655" />
        </svg>
        <h1>Success!</h1>
        <p class="content-message">{{ text }}</p>
        <button @click="closePopup" class="btn btn-revert success-popup-btn">
          Continue
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  emits: ["closed"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    openPopup() {
      this.dialog = true;
    },
    closePopup() {
      this.dialog = false;
      this.$emit("closed");
    },
  },
};
</script>
<style scoped>
.popup {
  position: relative;
  border-radius: 30px;
  width: 100%;
  max-width: 588px;
  padding: 52px 24px 34px;
  background-color: var(--white-color);
}

.popup-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 70px;
  padding: 23px;
  cursor: pointer;
}

.popup-close svg path {
  transition: .5s all;
}

.popup-close:hover svg path {
  fill: var(--accent-color);
}

.popup-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px auto 0;
  max-width: 305px;
  text-align: center;
}

.popup-content h1 {
  margin: 25px 0 16px;
}

.success-popup-btn {
  min-width: 165px;
  padding: 13px 20px;
}

.content-message {
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  margin-bottom: 30px;
  color: var(--secondary-text-color);
}
</style>
