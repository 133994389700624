<template>
  <div class="wrapper">
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
    <Sidebar/>
    <div class="content-wrapper">
      <HeaderRegistered/>
      <main class="main">
        <section class="dashboard-section">
          <div class="holder-search">
            <p class="dashboard-text">Welcome back {{ fullName }}!</p>
          </div>
          <div class="holder-cards">
            <transition name="tray">
              <div
                  class="card-item"
                  v-if="show"
              >
                <div class="card">
                  <div class="card-header">
                    <h5>
                      <span class="holder-cards-title">Cases</span>
                      <span class="holder-cards-subtitle" v-if="dashboardCategories.newCasesAmount"> + {{ dashboardCategories.newCasesAmount }} new</span>
                    </h5>
                    <a
                        id="dashboard_push_case"
                        @click="$router.push({path: '/cases'})">
                      <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.74789 7.0098C6.7483 7.21806 6.67568 7.41988 6.54264 7.58023L2.08066 12.928C1.92918 13.11 1.71152 13.2245 1.47555 13.2462C1.23957 13.268 1.00462 13.1952 0.82238 13.0439C0.640138 12.8926 0.525533 12.6752 0.503776 12.4395C0.48202 12.2038 0.554894 11.9692 0.706368 11.7872L4.7043 7.0098L0.849152 2.23245C0.775024 2.14128 0.719668 2.03638 0.686264 1.92377C0.65286 1.81117 0.642067 1.69308 0.654507 1.57629C0.666946 1.45951 0.702371 1.34633 0.758747 1.24326C0.815123 1.1402 0.891338 1.04927 0.983011 0.97572C1.07477 0.894096 1.18242 0.832276 1.29921 0.794135C1.416 0.755995 1.53942 0.742355 1.66173 0.754069C1.78405 0.765784 1.90262 0.8026 2.01003 0.862212C2.11743 0.921825 2.21135 1.00295 2.28591 1.1005L6.59618 6.44829C6.70819 6.61325 6.76161 6.81095 6.74789 7.0098Z"
                            fill="black"/>
                      </svg>
                    </a>
                  </div>
                  <div class="card-body">
                    <p v-if="false">Create a case to start your activity</p>
                    <span class="holder-cards-count">{{ dashboardCategories.casesAmount }}</span>
                  </div>
                  <div class="card-img">
                    <svg width="100" height="89" viewBox="0 0 100 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M90.0252 17.062H68.4879V7.90609C68.4879 3.54654 65.1261 0 60.9938 0H39.0047C34.8724 0 31.5106 3.54654 31.5106 7.90609V17.062H9.97331C4.48683 17.062 0.023107 21.7712 0.023107 27.5595C0.023107 32.8172 -0.487276 37.539 3.28933 42.8491C5.42607 45.8534 8.36242 47.9966 11.7808 49.0465L44.9754 59.2429C48.2616 60.2522 51.7359 60.2526 55.0228 59.2429L88.2174 49.0465C91.6357 47.9964 94.5721 45.8534 96.7088 42.8491C100.502 37.5152 99.975 32.7448 99.975 27.5595C99.9752 21.7712 95.5117 17.062 90.0252 17.062ZM37.8582 7.90609C37.8582 7.23914 38.3725 6.69671 39.0047 6.69671H60.9938C61.626 6.69671 62.1404 7.23935 62.1404 7.90609V17.062H37.8582V7.90609Z"
                          fill="#E6E7E9"/>
                      <path
                          d="M90.0119 55.8181C54.1903 65.8792 54.6541 66.0827 49.9999 66.0827C45.379 66.0827 46.4753 66.0662 9.98811 55.8183C6.14103 54.7376 2.73394 52.7459 0 50V79.3971C0 84.6922 4.46567 89 9.95497 89H90.045C95.5341 89 100 84.6922 100 79.3971V50C97.2659 52.7457 93.8588 54.7376 90.0119 55.8181Z"
                          fill="#E6E7E9"/>
                    </svg>
                  </div>
                  <div
                       id="disabled-create-case-button"
                       ref="disabledCreateCaseButton"
                       class="card-btn disabled-permission"
                  >
                  <button
                        :disabled="!checkPermission(['case_management'])"
                        id="dashboard_create_case"
                        @click="$router.push({path: '/create-case'})"
                    >Create Case</button>
                  </div>
                  <tooltip v-if="!checkPermission(['case_management'])" :target-ref="$refs.disabledCreateCaseButton">
                    <span>To access case creation - you need to have system permission - 'Case management'</span>
                  </tooltip>
                </div>
              </div>
            </transition>
            <transition name="tray">
              <div
                  class="card-item"
                  v-if="show"
              >
                <div class="card">
                  <div class="card-header">
                    <h5>
                      <span class="holder-cards-title">Users</span>
                      <span class="holder-cards-subtitle" v-if="dashboardCategories.newEmployeesAmount"> + {{ dashboardCategories.newEmployeesAmount }} new</span>
                    </h5>
                    <a
                        v-if="checkPermission(['view_contacts'])"
                        id="dashboard_push_users"
                        @click="$router.push({path: '/contacts/users'})">
                      <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.74789 7.0098C6.7483 7.21806 6.67568 7.41988 6.54264 7.58023L2.08066 12.928C1.92918 13.11 1.71152 13.2245 1.47555 13.2462C1.23957 13.268 1.00462 13.1952 0.82238 13.0439C0.640138 12.8926 0.525533 12.6752 0.503776 12.4395C0.48202 12.2038 0.554894 11.9692 0.706368 11.7872L4.7043 7.0098L0.849152 2.23245C0.775024 2.14128 0.719668 2.03638 0.686264 1.92377C0.65286 1.81117 0.642067 1.69308 0.654507 1.57629C0.666946 1.45951 0.702371 1.34633 0.758747 1.24326C0.815123 1.1402 0.891338 1.04927 0.983011 0.97572C1.07477 0.894096 1.18242 0.832276 1.29921 0.794135C1.416 0.755995 1.53942 0.742355 1.66173 0.754069C1.78405 0.765784 1.90262 0.8026 2.01003 0.862212C2.11743 0.921825 2.21135 1.00295 2.28591 1.1005L6.59618 6.44829C6.70819 6.61325 6.76161 6.81095 6.74789 7.0098Z"
                            fill="black"/>
                      </svg>
                    </a>
                  </div>
                  <div class="card-body">
                    <p v-if="false">Invite empoyees to start cooperate</p>
                    <span class="holder-cards-count">{{ dashboardCategories.employeesAmount }}</span>
                  </div>
                  <div class="card-img">
                    <svg width="73" height="81" viewBox="0 0 73 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M18.25 19.1842C18.25 29.7611 26.4382 38.3684 36.5 38.3684C46.5618 38.3684 54.75 29.7611 54.75 19.1842C54.75 8.60731 46.5618 0 36.5 0C26.4382 0 18.25 8.60731 18.25 19.1842ZM68.9444 81H73V76.7368C73 60.2853 60.2615 46.8947 44.6111 46.8947H28.3889C12.7344 46.8947 0 60.2853 0 76.7368V81H68.9444Z"
                          fill="#E6E7E9"/>
                    </svg>
                  </div>
                  <div class="card-btn disabled-permission" id="disabled-invite-button" ref="disabledInviteButton">
                    <button
                        :disabled="!checkPermission(['invite_users'])"
                        id="dashboard_invite_team"
                        @click="$router.push({path: `/invite-new-contact/invite-team`})"
                    >Invite</button>
                  </div>
                  <tooltip v-if="!checkPermission(['invite_users'])"  :target-ref="$refs.disabledInviteButton">
                    <span>To access invite users - you need to have 'Invite user's' permission</span>
                  </tooltip>
                </div>
              </div>
            </transition>
            <transition name="tray">
              <div
                  class="card-item"
                  v-if="show"
              >
                <div class="card">
                  <div class="card-header">
                    <h5>
                      <span class="holder-cards-title">Custodians</span>
                      <span class="holder-cards-subtitle" v-if="dashboardCategories.newClientsAmount"> + {{ dashboardCategories.newClientsAmount }} new</span>
                    </h5>
                    <a
                        v-if="checkPermission(['view_contacts'])"
                        id="dashboard_push_clients"
                        @click="$router.push({path: '/contacts/custodians'})">
                      <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.74789 7.0098C6.7483 7.21806 6.67568 7.41988 6.54264 7.58023L2.08066 12.928C1.92918 13.11 1.71152 13.2245 1.47555 13.2462C1.23957 13.268 1.00462 13.1952 0.82238 13.0439C0.640138 12.8926 0.525533 12.6752 0.503776 12.4395C0.48202 12.2038 0.554894 11.9692 0.706368 11.7872L4.7043 7.0098L0.849152 2.23245C0.775024 2.14128 0.719668 2.03638 0.686264 1.92377C0.65286 1.81117 0.642067 1.69308 0.654507 1.57629C0.666946 1.45951 0.702371 1.34633 0.758747 1.24326C0.815123 1.1402 0.891338 1.04927 0.983011 0.97572C1.07477 0.894096 1.18242 0.832276 1.29921 0.794135C1.416 0.755995 1.53942 0.742355 1.66173 0.754069C1.78405 0.765784 1.90262 0.8026 2.01003 0.862212C2.11743 0.921825 2.21135 1.00295 2.28591 1.1005L6.59618 6.44829C6.70819 6.61325 6.76161 6.81095 6.74789 7.0098Z"
                            fill="black"/>
                      </svg>
                    </a>
                  </div>
                  <div class="card-body">
                    <p v-if="false">Invite custodians to work in the case</p>
                    <span class="holder-cards-count">{{ dashboardCategories.clientsAmount }}</span>
                  </div>
                  <div class="card-img">
                    <svg width="104" height="90" viewBox="0 0 104 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M51.5739 35.0541C61.2539 35.0541 69.101 27.207 69.101 17.5271C69.101 7.84714 61.2539 0 51.5739 0C41.894 0 34.0469 7.84714 34.0469 17.5271C34.0469 27.207 41.894 35.0541 51.5739 35.0541Z"
                          fill="#CCCECE" fill-opacity="0.42"/>
                      <path
                          d="M87.031 35.0542C93.1505 35.0542 98.1113 30.0934 98.1113 23.9739C98.1113 17.8544 93.1505 12.8936 87.031 12.8936C80.9115 12.8936 75.9507 17.8544 75.9507 23.9739C75.9507 30.0934 80.9115 35.0542 87.031 35.0542Z"
                          fill="#CCCECE" fill-opacity="0.42"/>
                      <path
                          d="M16.117 35.0542C22.2365 35.0542 27.1973 30.0934 27.1973 23.9739C27.1973 17.8544 22.2365 12.8936 16.117 12.8936C9.99745 12.8936 5.03662 17.8544 5.03662 23.9739C5.03662 30.0934 9.99745 35.0542 16.117 35.0542Z"
                          fill="#CCCECE" fill-opacity="0.42"/>
                      <path
                          d="M27.034 45.004C22.6724 41.4305 18.7223 41.9035 13.6792 41.9035C6.13649 41.9035 0 48.0038 0 55.5001V77.5016C0 80.7572 2.65726 83.4044 5.92496 83.4044C20.0324 83.4044 18.3329 83.6596 18.3329 82.796C18.3329 67.2058 16.4863 55.7727 27.034 45.004Z"
                          fill="#CCCECE" fill-opacity="0.42"/>
                      <path
                          d="M56.3706 41.9843C47.562 41.2496 39.9055 41.9928 33.3014 47.4439C22.2499 56.2961 24.3767 68.2151 24.3767 82.7962C24.3767 86.6539 27.5154 89.8513 31.4318 89.8513C73.9565 89.8513 75.649 91.2231 78.1707 85.6388C78.9977 83.7503 78.771 84.3504 78.771 66.2845C78.771 51.9352 66.3464 41.9843 56.3706 41.9843Z"
                          fill="#CCCECE" fill-opacity="0.42"/>
                      <path
                          d="M89.4691 41.903C84.3983 41.903 80.47 41.4348 76.1143 45.0035C86.5832 55.6922 84.8153 66.3452 84.8153 82.7955C84.8153 83.6646 83.4045 83.4039 97.0118 83.4039C100.396 83.4039 103.148 80.662 103.148 77.2916V55.4996C103.148 48.0033 97.0118 41.903 89.4691 41.903Z"
                          fill="#CCCECE" fill-opacity="0.42"/>
                    </svg>
                  </div>
                  <div class="card-btn">
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <div class="holder-status">
            <transition name="fade">
              <div v-if="show">
                <h6>System status</h6>
              </div>
            </transition>
            <div class="status-content">
              <transition name="tray">
                <div
                    class="holder-status-content"
                >
                  <ul class="status-info">
                    <li class="holder-status-item">
                      <div class="card">
                        <div class="card-header">
                          <h5>Active Cases</h5>
                          <span>{{ dashboardInfo.activeCasesAmount + " " + (dashboardInfo.activeCasesAmount === 1 ? "case" : "cases") }}</span>
                        </div>
                        <div class="card-body">
                          <div class="card-body-info">
                            <button
                                id="dashboard_paid"
                                class="btn paid">paid</button>
                            <div class="right-info">
                              <span class="last-pay">Last payment</span>
                              <span class="data-span">{{ dashboardInfo.lastCasePaymentDate }}</span>
                            </div>
                          </div>
                          <span class="sum-info">$ {{ dashboardInfo.lastCasePaymentSum }}</span>
                          <span class="num-of-card">{{ dashboardInfo.lastCasePaymentCard }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="holder-status-item">
                      <div class="card">
                        <div class="card-header">
                          <h5>Storage Status</h5>
                        </div>
                        <div class="card-body">
                          <h2 class="status-info-title accent">{{ dashboardInfo.storageStatus || 0 }} GB</h2>
                          <p>of files added in the last 24 hours</p>
                        </div>
                      </div>
                    </li>
                    <li class="holder-status-item">
                      <div class="card">
                        <div class="card-header">
                          <h5>New Members</h5>
                        </div>
                        <div class="card-body">
                          <h2 class="status-info-title accent">
                            {{ dashboardInfo.newMembersAmount + " " + (dashboardInfo.newMembersAmount === 1 ? "member" : "people") }}
                          </h2>
                          <p>joined in the last 24 hours</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
          </div>
          <div class="holder-status holder-status-detail">
            <transition name="fade">
              <div v-if="show">
                <h6>details</h6>
              </div>
            </transition>
            <div class="status-content">
                <div
                    class="holder-status-img"
                    v-if="!isStatus"
                >
                  <img src="@/assets/img/system-status.svg" alt="">
                </div>
              <transition name="tray">
                <div
                    class="holder-status-content"
                    v-if="isStatus"
                >
                  <ul class="status-info">
                    <li class="holder-status-item w-33">
                      <div class="card">
                        <div class="card-header">
                          <h5>Reach by device</h5>
                        </div>
                        <div class="card-body">
                          <div class="holder-chart">
                            <PieCart
                                v-if="this.dashboardInfo.desktopReach && this.dashboardInfo.mobileReach"
                                :desktopReach="dashboardInfo.desktopReach"
                                :mobileReach="dashboardInfo.mobileReach"
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="holder-status-item w-33">
                      <div class="card">
                        <div class="card-header">
                          <h5>New Messages</h5>
                        </div>
                        <div class="card-body">
                          <ul>
                            <li class="new-mass"
                                v-for="(item, index) in dashboardInfo.newMessagesCases.slice(0, 3)"
                                :key="index"
                                :item="item"
                            >
                              <h4>{{ item.caseName }}</h4>
                              <div :id="`disabled-view-case-button_${index}`" :ref="`disabled-view-case-button_${index}`">
                                <button
                                  :disabled="!checkPermission(['view_case'])"
                                  @click="goToCaseMessages(item.caseId, item.caseName)"
                                  :id="`dashboard_message_${index+1}`"
                                  class="btn btn-revert btn-case-message"
                                >
                                  View
                                </button>
                              </div>
                              <tooltip v-if="!checkPermission(['view_case'])"  :target-ref="$refs[`disabled-view-case-button_${index}`]">
                                <span>To access view case - you need to have 'view case' permission</span>
                              </tooltip>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li class="holder-status-item w-33">
                      <div class="card">
                        <div class="card-header">
                          <h5 class="attention">Damaged Files</h5>
                          <h2 class="status-info-title error-title">{{ dashboardInfo.damagedFiles.length }} files </h2>
                        </div>
                        <div class="card-body">
                          <!--                          <button class="btn gray-btn">Recover</button>-->
                          <CustomInputFile
                              v-for="(item, index) in dashboardInfo.damagedFiles"
                              :key="index"
                              :damagedFile="item"
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>

import checkPermission from "../utils/permission";
import Sidebar from "../components/Sidebar";
import HeaderRegistered from "../components/layout/HeaderRegistered";
import AlertErrorMsg from "../components/profile/AlertErrorMsg";
import PieCart from "../components/Charts/PieCart";
import CustomInputFile from "../components/inputs/CustomInputFile";
import Tooltip from "@/components/common/Tooltip.vue";

export default {
  name: "Dashboard",
  components: {
    Tooltip,
    CustomInputFile,
    PieCart,
    HeaderRegistered,
    Sidebar,
    AlertErrorMsg,
  }
  ,
  data() {
    return {
      completedSteps: 35,
      totalSteps: 100,
      accentColor: '#082655',
      warningColor: '#FF792E',
      dangerColor: '#F1105F',
      show: false,
      isStatus: true,
      userInfo: {},
      dashboardInfo: {
        activeCasesAmount: 0,
        damagedFiles: [],
        desktopReach: 0,
        mobileReach: 0,
        lastCasePaymentCard: 0,
        lastCasePaymentDate: "",
        lastCasePaymentSum: 0,
        newMembersAmount: 0,
        newMessagesCases: [],
        storageStatus: 0,
      },
      dashboardCategories: {
        casesAmount: 0,
        clientsAmount: 0,
        usersAmount: 0,
        newCasesAmount: 0,
        newClientsAmount: 0,
        newEmployeesAmount: 0,
      },
      errorMessage: "",
    }
  },
  computed: {
    fullName() {
      return this.userInfo.firstName && this.userInfo.lastName ? (this.userInfo.firstName.toLowerCase()[0].toUpperCase() + this.userInfo.firstName.toLowerCase().slice(1)) + ' ' + (this.userInfo.lastName.toLowerCase()[0].toUpperCase() + this.userInfo.lastName.toLowerCase().slice(1)) : ""
    },
    error() {
      return this.$store
    }
  },
  methods: {
    checkPermission,
    beforeEnter: function (el) {
      el.style.opacity = el.style.transformOrigin = 'left'
    },
    goToCaseMessages(caseId, caseName) {
      localStorage.setItem('title', caseName);
      this.$router.push({path: `/cases/${caseId}/messages/mobile/correspondence`})
    },
  },
  mounted() {
    document.title = 'Dashboard'
    this.show = true
    this.$load(async () => {
      await this.$api.dashboard.getDashboardInfo().then((res) => {
        this.dashboardInfo = res.data.body
        this.dashboardInfo.newMessagesCases.forEach((item) => {
          this.$store.dispatch('profile/getPermissionPerCase', {caseId: item.caseId})
        })
      }, (error) => {
        if (error.response) {
          this.errorMessage = error.response.data.errors.message[0]
        } else {
          this.errorMessage = error.data.errors.message[0]
        }
        this.$refs.alert.showAlert()
      })
    })
    this.$load(async () => {
      await this.$api.dashboard.getDashboardDamagedFiles().then((res) => {
        if(this.dashboardInfo.damagedFiles.length > 3) {
          this.dashboardInfo.damagedFiles = res.data.body.damagedFiles.slice(0, 3)
        }else {
          this.dashboardInfo.damagedFiles = res.data.body.damagedFiles
        }
      }, (error) => {
        if (error.response) {
          this.errorMessage = error.response.data.errors.message[0]
        } else {
          this.errorMessage = error.data.errors.message[0]
        }
        this.$refs.alert.showAlert()
      })
    })
    this.$load(async () => {
      await this.$api.dashboard.getDashboardCategories().then((res) => {
        this.dashboardCategories = res.data.body
      }, (error) => {
        if (error.response) {
          this.errorMessage = error.response.data.errors.message[0]
        } else {
          this.errorMessage = error.data.errors.message[0]
        }
        this.$refs.alert.showAlert()
      })
    })
    this.$load(async () => {
      await this.$api.profile.getProfile().then((res) => {
        this.userInfo = res.data.body
      }, (error) => {
        if (error.response) {
          this.errorMessage = error.response.data.errors.message[0]
        } else {
          this.errorMessage = error.data.errors.message[0]
        }
        this.$refs.alert.showAlert()
      })
    })
  },
}
</script>

<style scoped>

.dashboard-section {
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  padding: 27px 55px 27px 35px;
}

.dashboard-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: var(--light-gray);
  margin: 0;
}

.holder-search {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 57px;
}

.search {
  border-radius: 79px;
  border: 1px solid var(--input-placeholder-value-color);
  padding: 9px 15px 9px 44px;
}

.dashboard-search {
  position: relative;
}

.search::placeholder {
  color: var(--input-placeholder-value-color);
  font-size: 14px;
}

.dashboard-search svg {
  position: absolute;
  top: 9px;
  left: 12px;
}

input {
  outline: none;
}

.holder-cards {
  display: flex;
  align-items: initial;
  justify-content: space-between;
  margin: 0 -15px;
}

.card-item {
  width: calc(33.33% - 48px);
  min-width: 330px;
  padding: 0 15px;
}

.card {
  display: flex;
  position: relative;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 10px;
  background-color: var(--white-color);
  overflow: hidden;
  min-height: 100%;
  padding: 23px;
  transition: box-shadow 0.5s;
  box-shadow: 0 4px 39px rgba(0, 0, 0, 0.06);
}

.card:hover {
  box-shadow: 0 4px 39px rgba(0, 0, 0, 0.2);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 19px;
}

.card-header h5 {
  color: var(--secondary-black-color);
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.card-header a {
  padding: 0 15px;
}

.card-body {
  margin-bottom: 25px;
}

.card-body p {
  width: 60%;
  color: var(--light-gray);
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.card-img {
  position: absolute;
  bottom: -18px;
  right: 0;
}

.card-btn button {
  color: var(--accent-color);
  min-width: 120px;
  padding: 10px 20px;
  border-radius: 25px;
  border: 1px solid var(--accent-color);
  font-size: 14px;
  font-weight: 600;
  background-color: var(--white-color);
}

.holder-status-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* status content */
.holder-status-content {
  display: flex;
}

.status-info {
  display: flex;
  align-items: initial;
  width: 100%;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  /*justify-content: space-between;*/
}

.status-info .card-body {
  margin: 0;
}

.status-info p {
  color: var(--secondary-text-color);
}

.holder-status-item {
  /*width: calc(33.33% - 48px);*/
  width: 25%;
  padding: 0 15px;
  margin-bottom: 25px;
}

.holder-status-detail .holder-status-item:first-child {
  display: none;
}

.holder-status-detail .holder-status-item {
  width: 50%;
}

.btn-case-message {
  width: 335px;
}

.card-body-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.right-info {
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
}

.paid {
  min-width: 68px;
}

.last-pay {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 10px;
  color: var(--light-gray);
  margin-bottom: 4px;
}

.data-span {
  display: block;
  font-weight: 500;
  font-size: 12px;
}

.sum-info {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: var(--accent-color);
  margin-bottom: 9px;
}

.num-of-card {
  font-weight: 400;
  font-size: 12px;
  color: var(--secondary-text-color);
}

.status-info-title {
  font-weight: 500;
  font-size: 14px;
}

.accent {
  color: var(--accent-color);
}

.w-33 {
  width: 33.33%;
}

.new-mass {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-mass h4 {
  font-weight: 500;
  font-size: 14px;
  margin-right: 40px;
  width: calc(100% - 335px);
}

.error-title {
  color: var(--error-color);
}

.holder-cards-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--accent-color);
  display: inline-block;
  margin-left: 13px;
}

.holder-cards-count {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 58px;
  color: #373C44;

}

</style>
