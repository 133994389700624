<template>
  <div class="holder-profile-form">
    <h3>Change password</h3>
    <form @submit.prevent="submit" ref="passwordSecurity" class="profile-form">
      <div class="form-group" :class="{ 'form-group--error': $v.formData.oldPassword.$error }">
        <label for="currentPassword">Verify current password*</label>
        <input
            id="currentPassword"
            :type="currentPasswordType"
            class="form-control"
            v-model="$v.formData.oldPassword.$model"
            :class="false ? 'is-invalid' : ''"
            placeholder="Enter current password"
        >
        <div class="error" v-if="!$v.formData.oldPassword.required">Field is required</div>
        <div class="error custom-error" v-if="currentPasswordError">{{ currentPasswordError }}</div>
        <svg
            v-if="currentPasswordType == 'password'"
            @click="toggleCurrentPassword"
            class="input-password"
            xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M19 7C19 7 15.4375 0.5 9.5 0.5C3.5625 0.5 0 7 0 7C0 7 3.5625 13.5 9.5 13.5C15.4375 13.5 19 7 19 7ZM1.39294 7C1.96712 7.86849 2.62752 8.67737 3.36419 9.41445C4.8925 10.9378 6.9825 12.3182 9.5 12.3182C12.0175 12.3182 14.1063 10.9378 15.637 9.41445C16.3737 8.67737 17.0341 7.86849 17.6082 7C17.0341 6.13151 16.3737 5.32263 15.637 4.58555C14.1063 3.06218 12.0163 1.68182 9.5 1.68182C6.9825 1.68182 4.89369 3.06218 3.363 4.58555C2.62632 5.32263 1.96592 6.13151 1.39175 7H1.39294Z"
                fill="#D7DCE6"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M9.5 4.04559C8.71264 4.04559 7.95753 4.35687 7.40078 4.91095C6.84403 5.46504 6.53125 6.21654 6.53125 7.00013C6.53125 7.78373 6.84403 8.53523 7.40078 9.08931C7.95753 9.6434 8.71264 9.95468 9.5 9.95468C10.2874 9.95468 11.0425 9.6434 11.5992 9.08931C12.156 8.53523 12.4688 7.78373 12.4688 7.00013C12.4688 6.21654 12.156 5.46504 11.5992 4.91095C11.0425 4.35687 10.2874 4.04559 9.5 4.04559ZM5.34375 7.00013C5.34375 5.9031 5.78164 4.851 6.56109 4.07528C7.34054 3.29956 8.39769 2.86377 9.5 2.86377C10.6023 2.86377 11.6595 3.29956 12.4389 4.07528C13.2184 4.851 13.6562 5.9031 13.6562 7.00013C13.6562 8.09716 13.2184 9.14926 12.4389 9.92498C11.6595 10.7007 10.6023 11.1365 9.5 11.1365C8.39769 11.1365 7.34054 10.7007 6.56109 9.92498C5.78164 9.14926 5.34375 8.09716 5.34375 7.00013Z"
                fill="#D7DCE6"/>
        </svg>
        <svg
            v-if="currentPasswordType == 'text'"
            @click="toggleCurrentPassword"
            class="input-text"
            xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
          <path
              d="M15.8635 13.3451C17.8834 11.5425 18.9996 9.5 18.9996 9.5C18.9996 9.5 15.4371 2.96875 9.49965 2.96875C8.35916 2.97268 7.23159 3.21032 6.18652 3.667L7.1009 4.58256C7.86973 4.3025 8.6814 4.15824 9.49965 4.15625C12.0171 4.15625 14.106 5.54325 15.6366 7.07394C16.3733 7.81457 17.0337 8.62734 17.6079 9.5C17.539 9.60331 17.463 9.71731 17.3763 9.842C16.9785 10.412 16.3907 11.172 15.6366 11.9261C15.4407 12.122 15.2365 12.3156 15.0227 12.5032L15.8635 13.3451Z"
              fill="#D7DCE6"/>
          <path
              d="M13.415 10.8973C13.68 10.1561 13.7291 9.3549 13.5565 8.58691C13.384 7.81892 12.9969 7.11572 12.4403 6.55914C11.8837 6.00255 11.1805 5.61545 10.4125 5.4429C9.64451 5.27034 8.84331 5.31942 8.10212 5.58441L9.07944 6.56172C9.5358 6.4964 10.0011 6.53827 10.4385 6.68399C10.8759 6.82972 11.2733 7.07531 11.5993 7.4013C11.9253 7.72729 12.1709 8.12472 12.3166 8.5621C12.4623 8.99948 12.5042 9.46479 12.4389 9.92116L13.415 10.8973ZM9.92019 12.4399L10.8963 13.416C10.1551 13.681 9.35392 13.73 8.58593 13.5575C7.81795 13.3849 7.11475 12.9978 6.55816 12.4413C6.00157 11.8847 5.61448 11.1815 5.44192 10.4135C5.26936 9.64549 5.31844 8.84429 5.58344 8.1031L6.56075 9.08041C6.49543 9.53678 6.53729 10.0021 6.68302 10.4395C6.82874 10.8769 7.07433 11.2743 7.40032 11.6003C7.72631 11.9263 8.12375 12.1719 8.56113 12.3176C8.99851 12.4633 9.46382 12.5052 9.92019 12.4399Z"
              fill="#D7DCE6"/>
          <path
              d="M3.97812 6.49602C3.76437 6.68602 3.55894 6.8784 3.363 7.07434C2.62633 7.81497 1.96593 8.62774 1.39175 9.5004L1.62331 9.8424C2.02113 10.4124 2.60894 11.1724 3.363 11.9265C4.89369 13.4571 6.98369 14.8441 9.5 14.8441C10.3503 14.8441 11.1506 14.6862 11.8988 14.4166L12.8131 15.3334C11.7681 15.79 10.6405 16.0277 9.5 16.0316C3.5625 16.0316 0 9.5004 0 9.5004C0 9.5004 1.11506 7.45671 3.13619 5.65527L3.97694 6.49721L3.97812 6.49602Z"
              fill="#D7DCE6"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M16.2051 17.0458L1.95508 2.79583L2.79583 1.95508L17.0458 16.2051L16.2051 17.0458Z" fill="#D7DCE6"/>
        </svg>
      </div>
      <div class="form-group" :class="{ 'form-group--error': $v.formData.password.$error }"
      >
        <label for="newPassword">Create a new password*</label>
        <input
            id="newPassword"
            @focus="focus = true"
            @blur="focus = false"
            :type="passwordNewType"
            v-model="$v.formData.password.$model"
            class="form-control"
            :class="false ? 'is-invalid' : ''"
            placeholder="Create a new password"
        >
        <svg
            @click="toggleNewPassword"
            v-if="passwordNewType == 'password'"
            class="input-password"
            xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M19 7C19 7 15.4375 0.5 9.5 0.5C3.5625 0.5 0 7 0 7C0 7 3.5625 13.5 9.5 13.5C15.4375 13.5 19 7 19 7ZM1.39294 7C1.96712 7.86849 2.62752 8.67737 3.36419 9.41445C4.8925 10.9378 6.9825 12.3182 9.5 12.3182C12.0175 12.3182 14.1063 10.9378 15.637 9.41445C16.3737 8.67737 17.0341 7.86849 17.6082 7C17.0341 6.13151 16.3737 5.32263 15.637 4.58555C14.1063 3.06218 12.0163 1.68182 9.5 1.68182C6.9825 1.68182 4.89369 3.06218 3.363 4.58555C2.62632 5.32263 1.96592 6.13151 1.39175 7H1.39294Z"
                fill="#D7DCE6"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M9.5 4.04559C8.71264 4.04559 7.95753 4.35687 7.40078 4.91095C6.84403 5.46504 6.53125 6.21654 6.53125 7.00013C6.53125 7.78373 6.84403 8.53523 7.40078 9.08931C7.95753 9.6434 8.71264 9.95468 9.5 9.95468C10.2874 9.95468 11.0425 9.6434 11.5992 9.08931C12.156 8.53523 12.4688 7.78373 12.4688 7.00013C12.4688 6.21654 12.156 5.46504 11.5992 4.91095C11.0425 4.35687 10.2874 4.04559 9.5 4.04559ZM5.34375 7.00013C5.34375 5.9031 5.78164 4.851 6.56109 4.07528C7.34054 3.29956 8.39769 2.86377 9.5 2.86377C10.6023 2.86377 11.6595 3.29956 12.4389 4.07528C13.2184 4.851 13.6562 5.9031 13.6562 7.00013C13.6562 8.09716 13.2184 9.14926 12.4389 9.92498C11.6595 10.7007 10.6023 11.1365 9.5 11.1365C8.39769 11.1365 7.34054 10.7007 6.56109 9.92498C5.78164 9.14926 5.34375 8.09716 5.34375 7.00013Z"
                fill="#D7DCE6"/>
        </svg>
        <svg
            @click="toggleNewPassword"
            v-if="passwordNewType == 'text'"
            class="input-text"
            xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
          <path
              d="M15.8635 13.3451C17.8834 11.5425 18.9996 9.5 18.9996 9.5C18.9996 9.5 15.4371 2.96875 9.49965 2.96875C8.35916 2.97268 7.23159 3.21032 6.18652 3.667L7.1009 4.58256C7.86973 4.3025 8.6814 4.15824 9.49965 4.15625C12.0171 4.15625 14.106 5.54325 15.6366 7.07394C16.3733 7.81457 17.0337 8.62734 17.6079 9.5C17.539 9.60331 17.463 9.71731 17.3763 9.842C16.9785 10.412 16.3907 11.172 15.6366 11.9261C15.4407 12.122 15.2365 12.3156 15.0227 12.5032L15.8635 13.3451Z"
              fill="#D7DCE6"/>
          <path
              d="M13.415 10.8973C13.68 10.1561 13.7291 9.3549 13.5565 8.58691C13.384 7.81892 12.9969 7.11572 12.4403 6.55914C11.8837 6.00255 11.1805 5.61545 10.4125 5.4429C9.64451 5.27034 8.84331 5.31942 8.10212 5.58441L9.07944 6.56172C9.5358 6.4964 10.0011 6.53827 10.4385 6.68399C10.8759 6.82972 11.2733 7.07531 11.5993 7.4013C11.9253 7.72729 12.1709 8.12472 12.3166 8.5621C12.4623 8.99948 12.5042 9.46479 12.4389 9.92116L13.415 10.8973ZM9.92019 12.4399L10.8963 13.416C10.1551 13.681 9.35392 13.73 8.58593 13.5575C7.81795 13.3849 7.11475 12.9978 6.55816 12.4413C6.00157 11.8847 5.61448 11.1815 5.44192 10.4135C5.26936 9.64549 5.31844 8.84429 5.58344 8.1031L6.56075 9.08041C6.49543 9.53678 6.53729 10.0021 6.68302 10.4395C6.82874 10.8769 7.07433 11.2743 7.40032 11.6003C7.72631 11.9263 8.12375 12.1719 8.56113 12.3176C8.99851 12.4633 9.46382 12.5052 9.92019 12.4399Z"
              fill="#D7DCE6"/>
          <path
              d="M3.97812 6.49602C3.76437 6.68602 3.55894 6.8784 3.363 7.07434C2.62633 7.81497 1.96593 8.62774 1.39175 9.5004L1.62331 9.8424C2.02113 10.4124 2.60894 11.1724 3.363 11.9265C4.89369 13.4571 6.98369 14.8441 9.5 14.8441C10.3503 14.8441 11.1506 14.6862 11.8988 14.4166L12.8131 15.3334C11.7681 15.79 10.6405 16.0277 9.5 16.0316C3.5625 16.0316 0 9.5004 0 9.5004C0 9.5004 1.11506 7.45671 3.13619 5.65527L3.97694 6.49721L3.97812 6.49602Z"
              fill="#D7DCE6"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M16.2051 17.0458L1.95508 2.79583L2.79583 1.95508L17.0458 16.2051L16.2051 17.0458Z" fill="#D7DCE6"/>
        </svg>
        <div class="error custom-error" v-if="newPasswordError">{{ newPasswordError }}</div>
        <div class="error" v-if="!$v.formData.password.required">Field is required</div>
        <div v-show="focus" class="password-tooltip">
          <p class="register-new-acc-text">Password must contain:</p>
          <div class="holder-conditions">
            <div class="group-conditions">
              <div class="condition-item">
                <svg :class="hasMinimumLength ? 'has-required' : ''"
                     width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                      fill="#8692A6"/>
                </svg>
                <p>8 or more characters</p>
              </div>
              <div class="condition-item">
                <svg :class="hasLowercase ? 'has-required' : ''"
                     width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                      fill="#8692A6"/>
                </svg>
                <p>Lowercase letter</p>
              </div>
              <div class="condition-item">
                <svg :class="hasSpecial ? 'has-required' : ''"
                     width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                      fill="#8692A6"/>
                </svg>
                <p>Symbols</p>
              </div>
              <div class="condition-item">
                <svg :class="hasUppercase ? 'has-required' : ''"
                     width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                      fill="#8692A6"/>
                </svg>
                <p>Uppercase letter</p>
              </div>
              <div class="condition-item">
                <svg :class="hasNumber ? 'has-required' : ''"
                     width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                      fill="#8692A6"/>
                </svg>
                <p>Numbers</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group" :class="{ 'form-group--error': $v.repeatPassword.$error }">
        <label for="passwordConfirm">Repeat a new password*</label>
        <input
            id="passwordConfirm"
            :type="passwordConfirmType"
            v-model="$v.repeatPassword.$model"
            class="form-control"
            :class="false ? 'is-invalid' : ''"
            placeholder="Repeat a new password"
        >
        <svg
            v-if="passwordConfirmType == 'password'"
            @click="toggleConfirmPassword"
            class="input-password"
            xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M19 7C19 7 15.4375 0.5 9.5 0.5C3.5625 0.5 0 7 0 7C0 7 3.5625 13.5 9.5 13.5C15.4375 13.5 19 7 19 7ZM1.39294 7C1.96712 7.86849 2.62752 8.67737 3.36419 9.41445C4.8925 10.9378 6.9825 12.3182 9.5 12.3182C12.0175 12.3182 14.1063 10.9378 15.637 9.41445C16.3737 8.67737 17.0341 7.86849 17.6082 7C17.0341 6.13151 16.3737 5.32263 15.637 4.58555C14.1063 3.06218 12.0163 1.68182 9.5 1.68182C6.9825 1.68182 4.89369 3.06218 3.363 4.58555C2.62632 5.32263 1.96592 6.13151 1.39175 7H1.39294Z"
                fill="#D7DCE6"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M9.5 4.04559C8.71264 4.04559 7.95753 4.35687 7.40078 4.91095C6.84403 5.46504 6.53125 6.21654 6.53125 7.00013C6.53125 7.78373 6.84403 8.53523 7.40078 9.08931C7.95753 9.6434 8.71264 9.95468 9.5 9.95468C10.2874 9.95468 11.0425 9.6434 11.5992 9.08931C12.156 8.53523 12.4688 7.78373 12.4688 7.00013C12.4688 6.21654 12.156 5.46504 11.5992 4.91095C11.0425 4.35687 10.2874 4.04559 9.5 4.04559ZM5.34375 7.00013C5.34375 5.9031 5.78164 4.851 6.56109 4.07528C7.34054 3.29956 8.39769 2.86377 9.5 2.86377C10.6023 2.86377 11.6595 3.29956 12.4389 4.07528C13.2184 4.851 13.6562 5.9031 13.6562 7.00013C13.6562 8.09716 13.2184 9.14926 12.4389 9.92498C11.6595 10.7007 10.6023 11.1365 9.5 11.1365C8.39769 11.1365 7.34054 10.7007 6.56109 9.92498C5.78164 9.14926 5.34375 8.09716 5.34375 7.00013Z"
                fill="#D7DCE6"/>
        </svg>
        <svg
            v-if="passwordConfirmType == 'text'"
            @click="toggleConfirmPassword"
            class="input-text"
            xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
          <path
              d="M15.8635 13.3451C17.8834 11.5425 18.9996 9.5 18.9996 9.5C18.9996 9.5 15.4371 2.96875 9.49965 2.96875C8.35916 2.97268 7.23159 3.21032 6.18652 3.667L7.1009 4.58256C7.86973 4.3025 8.6814 4.15824 9.49965 4.15625C12.0171 4.15625 14.106 5.54325 15.6366 7.07394C16.3733 7.81457 17.0337 8.62734 17.6079 9.5C17.539 9.60331 17.463 9.71731 17.3763 9.842C16.9785 10.412 16.3907 11.172 15.6366 11.9261C15.4407 12.122 15.2365 12.3156 15.0227 12.5032L15.8635 13.3451Z"
              fill="#D7DCE6"/>
          <path
              d="M13.415 10.8973C13.68 10.1561 13.7291 9.3549 13.5565 8.58691C13.384 7.81892 12.9969 7.11572 12.4403 6.55914C11.8837 6.00255 11.1805 5.61545 10.4125 5.4429C9.64451 5.27034 8.84331 5.31942 8.10212 5.58441L9.07944 6.56172C9.5358 6.4964 10.0011 6.53827 10.4385 6.68399C10.8759 6.82972 11.2733 7.07531 11.5993 7.4013C11.9253 7.72729 12.1709 8.12472 12.3166 8.5621C12.4623 8.99948 12.5042 9.46479 12.4389 9.92116L13.415 10.8973ZM9.92019 12.4399L10.8963 13.416C10.1551 13.681 9.35392 13.73 8.58593 13.5575C7.81795 13.3849 7.11475 12.9978 6.55816 12.4413C6.00157 11.8847 5.61448 11.1815 5.44192 10.4135C5.26936 9.64549 5.31844 8.84429 5.58344 8.1031L6.56075 9.08041C6.49543 9.53678 6.53729 10.0021 6.68302 10.4395C6.82874 10.8769 7.07433 11.2743 7.40032 11.6003C7.72631 11.9263 8.12375 12.1719 8.56113 12.3176C8.99851 12.4633 9.46382 12.5052 9.92019 12.4399Z"
              fill="#D7DCE6"/>
          <path
              d="M3.97812 6.49602C3.76437 6.68602 3.55894 6.8784 3.363 7.07434C2.62633 7.81497 1.96593 8.62774 1.39175 9.5004L1.62331 9.8424C2.02113 10.4124 2.60894 11.1724 3.363 11.9265C4.89369 13.4571 6.98369 14.8441 9.5 14.8441C10.3503 14.8441 11.1506 14.6862 11.8988 14.4166L12.8131 15.3334C11.7681 15.79 10.6405 16.0277 9.5 16.0316C3.5625 16.0316 0 9.5004 0 9.5004C0 9.5004 1.11506 7.45671 3.13619 5.65527L3.97694 6.49721L3.97812 6.49602Z"
              fill="#D7DCE6"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M16.2051 17.0458L1.95508 2.79583L2.79583 1.95508L17.0458 16.2051L16.2051 17.0458Z" fill="#D7DCE6"/>
        </svg>
        <div class="error" v-if="!$v.repeatPassword.required">Field is required</div>
        <div class="error" v-if="!$v.repeatPassword.sameAsPassword">Passwords must be identical.</div>
      </div>
      <div style="width: 100%">
        <div class="form-group">
          <button
              id="save_profile_security"
              :disabled="!isValid && isSending"
              class="btn profile-btn">Save
          </button>
        </div>
      </div>
    </form>
    <CommonDialog
        ref="commonDialogFormSuccess"
    >
      <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z"
            fill="#082655"/>
      </svg>
      <h2 class="dialog-message-title">Success!</h2>
      <p class="dialog-message-description" style="display:block;">The password was successfully changed</p>
    </CommonDialog>
  </div>
</template>

<script>
import {required, sameAs} from "vuelidate/lib/validators";
import CommonDialog from "../popups/CommonDialog";

export default {
  components: {
    CommonDialog,
  },
  data() {
    return {
      isSending: false,
      currentPasswordType: "password",
      passwordNewType: "password",
      passwordConfirmType: "password",
      hasMinimumLength: false,
      hasNumber: false,
      hasLowercase: false,
      hasUppercase: false,
      hasSpecial: false,
      isValid: false,
      repeatPassword: "",
      errorMessage: '',
      focus: false,
      formData: {
        password: "",
        oldPassword: ""
      },
      submitStatus: null,
      currentPasswordError: '',
      newPasswordError: '',
    };
  },
  validations: {
    formData: {
      oldPassword: {
        required,
      },
      password: {
        required,
      },
    },
    repeatPassword: {
      required,
      sameAsPassword: sameAs(function () {
        return this.formData.password
      })
    },
  },
  methods: {
    openDialogFormSuccess() {
      this.$refs.commonDialogFormSuccess.openPopups();
    },
    toggleCurrentPassword() {
      this.currentPasswordType = this.currentPasswordType == "password" ? "text" : "password";
    },
    toggleNewPassword() {
      this.passwordNewType = this.passwordNewType == "password" ? "text" : "password";
    },
    toggleConfirmPassword() {
      this.passwordConfirmType = this.passwordConfirmType == "password" ? "text" : "password";
    },
    validatePassword() {
      this.hasMinimumLength = (this.formData.password.length >= 8);
      this.hasNumber = /\d/.test(this.formData.password);
      this.hasLowercase = /[a-z]/.test(this.formData.password);
      this.hasUppercase = /[A-Z]/.test(this.formData.password);
      this.hasSpecial = /[#?!@$%/^()}{><.&|:;`',*~"-]/.test(this.formData.password);
      if (this.hasMinimumLength &&
          this.hasNumber &&
          this.hasLowercase &&
          this.hasUppercase &&
          this.hasSpecial &&
          this.repeatPassword &&
          this.repeatPassword === this.formData.password) {
        if (this.repeatPassword === this.formData.password) {
          this.isValid = true;
        } else {
          this.isValid = false;
        }
      } else {
        this.isValid = false;
      }
    },
    resetForm() {
      this.formData.oldPassword = ''
      this.formData.password = ''
      this.repeatPassword = ''
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    submit() {
      this.isSending = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        this.isSending = false
      } else {
        this.submitStatus = 'PENDING'
        this.$load(async () => {
              await this.$api.profile.updateProfilePassword(this.formData).then(() => {
                this.openDialogFormSuccess()
                this.resetForm()
                this.isSending = false
              })
            }, (error) => {
              this.isSending = false
              if (error.data.errors.message) {
                this.currentPasswordError = error.data.errors.message[0];
              } else {
                this.newPasswordError = error.data.errors.password[0];
              }
            }
        )
      }
    },
  },
  watch: {
    'formData.oldPassword'() {
      this.currentPasswordError = '';
    },
    'formData.password'() {
      this.validatePassword()
      this.newPasswordError = '';
    },
    repeatPassword() {
      this.validatePassword()
    },
  },
}
</script>

<style lang="scss" scoped>

.holder-profile-form {
  font-weight: 500;
  font-size: 16px;
  color: var(--secondary-text-color);
  padding-top: 50px;
}

.pass-text {
  width: 45%;
}

.holder-profile-form p {
  color: var(--secondary-black-color);
  margin: 0;
  width: 90%;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
}

h3 {
  font-weight: 700;
  font-size: 14px;
  color: var(--light-gray);
  text-transform: uppercase;
  margin: 0 0 40px;
}

.form-group {
  position: relative;
  width: 50%;
}

.form-group input {
  padding: 15px 44px;
}

.form-group .input-password {
  position: absolute;
  top: 44px;
  right: 23px;
}

.form-group .input-text {
  position: absolute;
  top: 41.5px;
  right: 23px;
}

.profile-btn {
  width: 100%;
  padding: 14px;
  margin-top: 30px;
}

/* start */

.password-tooltip {
  background: var(--white-color);
  position: absolute;
  bottom: calc(-100% - 95px);
  left: 0;
  z-index: 2;
  width: 100%;
}

.register-new-acc-text {
  font-size: 12px;
  font-weight: 400;
  color: var(--secondary-text-color);
  margin: 0;
  padding: 10px 15px;
}

.holder-conditions {
  padding: 38px 25px 6px;
  box-shadow: 0px 2px 5px var(--secondary-black-color);
  border-radius: 13px;
  display: flex;
}

.group-conditions {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.condition-item {
  display: flex;
  width: 50%;
  align-items: baseline;
}

.condition-item p {
  color: var(--secondary-text-color);
  font-weight: 400;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 10px;
}

.condition-item svg {
  margin-right: 9px;
  position: static;
}

.has-required path {
  fill: var(--accent-color);
}

/* end */


.dialog-message-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #292B2F;
  text-align: center;
  margin: 16px 0;
  display: block;
  width: 100%;
}

.dialog-message-description {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 26px;
  text-align: center;
  color: #696F79 !important;
  display: block;
  width: 100%;
  margin-bottom: 32px !important;
}
</style>