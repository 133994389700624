export default function(instance) {
  return {
    signUp(payload) {
      return instance.post('/registration/register', JSON.stringify(payload))
    },
    registerInvitedUsers(payload) {
      return instance.post('/registration/registerInvitedUsers', JSON.stringify(payload))
    },
    firmRegistration(payload) {
      return instance.post('/company', payload)
    },
    verifyEmail(payload) {
      return instance.post('/registration/verifyEmail', JSON.stringify(payload))
    },
    paymentCard(payload) {
      return instance.post('/company/paymentCard', payload)
    },
    phoneSend(payload) {
      return instance.post('/registration/addPhoneAndSendOtp', JSON.stringify(payload))
    },
    phoneSendInvite(payload) {
      return instance.post('/registration/addPhoneAndSendOtp/invite', JSON.stringify(payload))
    },
    otpSend(payload) {
      return instance.post('/registration/phoneVerify', JSON.stringify(payload))
    },
    otpSendInvited(payload) {
      return instance.post('/registration/phoneVerify/invite', JSON.stringify(payload))
    },
    otpPhoneConfirm(payload) {
      return instance.post('/registration/phoneConfirm/invite', JSON.stringify(payload))
    },
    forcePhoneSendOtp(payload) {
      return instance.post('/registration/forcePhoneAndSendOtp', JSON.stringify(payload))
    },
    userPhoto(payload) {
      return instance.post('/profile/avatar', payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
  }
}