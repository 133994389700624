<template>
  <div v-show="selectedMessagesIdCopy.length" class="selected-messages-info">
      <div class="selected-messages-info-wrapper">
        <div class="select-all-messages-wrapper">
          <div class="select-all-messages" :style="{ maxWidth: checkAll ? '137px' : '120px' }">
            <div class="btn-export btn-select-all">
              <div class="custom-checkbox-group">
                <div class="checkbox-wrap">
                  <input
                      :id="`check_all_messages`"
                      class="input-checkbox"
                      type="checkbox"
                      v-model="checkAll">
                  <label :for="`check_all_messages`" class="label-checkbox">
                    <span>{{ checkAll ? 'Unselect' : 'Select' }} All</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="selected-messages-length">
            ({{ selectedMessagesIdCopy.length }})
          </div>
        </div>
        <div class="export-selected-messages">
          <div class="holder-select-messanger">
            <CustomSelect
                default="Export"
                :typeSelect="'filterMessengers'"
                v-model="currentFormat"
                :custom-class="'select-databases top'"
                :options="formats"
                @input="exportMessages($event)"
            />
          </div>
          <div v-show="false" @click="exportMessages" class="btn-export">
            <span>Export</span>
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_8182_3088)">
                <path d="M17.6035 6.21479L13.4136 10.4258V7.87814C12.7609 7.77298 10.1502 7.58357 8.21339 10.8049L7.96048 11.2259L7.87638 10.7414C7.87638 10.7204 7.3289 7.70964 9.89764 5.58322C9.94008 5.54078 11.0327 4.53379 13.4136 4.5516V2.0459L17.6035 6.21479Z" fill="#082655"/>
                <path d="M13.6933 1.76481C13.5798 1.65191 13.4095 1.6183 13.2616 1.67979C13.1136 1.74127 13.0173 1.88577 13.0173 2.04591V4.16148C10.8757 4.24697 9.80354 5.13471 9.63969 5.28231C8.55929 6.16896 7.82163 7.40439 7.5537 8.7761C7.41973 9.43881 7.39588 10.1189 7.48292 10.7893L7.48323 10.7916C7.484 10.7975 7.48493 10.8036 7.48602 10.8093L7.56996 11.2937C7.5983 11.457 7.72514 11.5852 7.88791 11.6157C8.05068 11.6461 8.21516 11.572 8.3005 11.4302L8.5534 11.0092C9.32885 9.71944 10.2954 8.86453 11.4263 8.46821C11.9365 8.28779 12.4768 8.20864 13.0173 8.23497V10.4258C13.0173 10.5864 13.1141 10.7311 13.2625 10.7924C13.4109 10.8536 13.5815 10.8194 13.6947 10.7055L17.8848 6.4945C18.0392 6.33932 18.0386 6.08827 17.8833 5.93386L13.6933 1.76481ZM13.8103 9.46529V7.87814C13.8103 7.68347 13.669 7.5176 13.4768 7.48662C13.1375 7.43195 12.2329 7.34538 11.1641 7.71986C10.0235 8.11975 9.03676 8.90604 8.2243 10.0614C8.22197 9.68165 8.25791 9.30252 8.33162 8.93005C8.57261 7.7115 9.18467 6.68825 10.1506 5.88864C10.1591 5.88167 10.1673 5.87439 10.1751 5.86664C10.2039 5.84016 11.2112 4.93214 13.4109 4.94809C13.5165 4.94886 13.6181 4.90751 13.6932 4.83302C13.7681 4.75868 13.8103 4.65739 13.8103 4.55161V2.99978L17.0431 6.21635L13.8103 9.46529Z" fill="#082655"/>
                <path d="M16.547 13.3238C16.328 13.3238 16.1505 13.5013 16.1505 13.7203V15.3962C16.1498 16.0529 15.6176 16.585 14.9611 16.5856H1.98238C1.32572 16.585 0.793728 16.0529 0.792953 15.3962V5.95016C0.793728 5.29365 1.32572 4.7615 1.98238 4.76073H4.72442C4.94341 4.76073 5.1209 4.58324 5.1209 4.36425C5.1209 4.14541 4.94341 3.96777 4.72442 3.96777H1.98238C0.888046 3.96901 0.00123899 4.85597 0 5.95016V15.3962C0.00123899 16.4906 0.888046 17.3774 1.98238 17.3786H14.9611C16.0555 17.3774 16.9423 16.4906 16.9435 15.3962V13.7203C16.9435 13.5013 16.7659 13.3238 16.547 13.3238Z" fill="#082655"/>
              </g>
              <defs>
                <clipPath id="clip0_8182_3088">
                  <rect width="18" height="18" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import CustomSelect from '@/components/inputs/CustomSelect';
export default {
  name: 'SelectedMessagesInfo',
  components: { CustomSelect },
  props: {
    messages: {
      type: Array,
    },
    selectedMessagesId: {
      type: Array,
    },
    providedFormats: {
      type: Array,
    }
  },
  data: () => ({
    messagesCopy: [],
    currentFormat: null,
    localFormats: [
      { name: 'sql', id: 0},
      { name: 'xml', id: 1},
      { name: 'csv', id: 2},
      { name: 'ufdr', id: 3},
      { name: 'pdf', id: 4},
      { name: 'rsmf', id: 5},
    ],
    selectedMessagesIdCopy: [],
  }),
  watch: {
    currentFormat(val) {
      this.$emit('selectDB', val)
    },
    messages(val) {
      this.messagesCopy = JSON.parse(JSON.stringify(val));
    },
    selectedMessagesId(val) {
      this.selectedMessagesIdCopy = val;
    },
  },
  computed: {
    formats() {
      return this.providedFormats ? this.providedFormats : this.localFormats;
    },
    checkAll: {
      get() {
        return this.messages.every(message => message.isActive === true);
      },
      set(value) {
        this.selectedMessagesIdCopy = [];
        this.isCheckedAllMessages = value;

        if (value) {
          this.messagesCopy = this.messages.map(message => {
            this.selectedMessagesIdCopy.push(message.id);

            return { ...message, isActive: value };
          });
        } else {
          this.messagesCopy.forEach(message => message.isActive = value);
        }

        const params = {
          messages:  this.messagesCopy,
          isCheckedAllMessages: this.isCheckedAllMessages,
          selectedMessagesId: this.selectedMessagesIdCopy
        }

        this.$emit('updatedActiveStatus', params)
      }
    },
  },
  methods: {
    uncheckAll() {
      this.messagesCopy.forEach(message => message.isActive = false);

      const params = {
        messages:  this.messagesCopy,
        isCheckedAllMessages: false,
        selectedMessagesId: []
      }

      this.$emit('updatedActiveStatus', params)
    },
    exportMessages(format) {
      if (format !== "Export") {
        this.$emit('selectDB', this.currentFormat)
        this.$emit('exportMessages', this.selectedMessagesIdCopy)
        this.uncheckAll()
      }
    },
  },
};
</script>
<style scoped>

.selected-messages-info {
  position: sticky;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 24px 16px;
  background-color: var(--accent-color);
}

.selected-messages-info-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-all-messages-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.label-checkbox {
  margin: 0;
}

.btn-export {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-radius: 79px;
  outline: none;
  min-width: 140px;
  padding: 8px 26px;
  background-color: #fff;
  cursor: pointer;
  gap: 10px;
}

.btn-select-all {
  background-color: transparent;
}

.btn-export span {
  transition: all .5s;
  color: var(--white-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.btn-export:hover span {
  //color: var(--accent-color);
}

.selected-messages-length {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  color: var(--white-color);
}

.export-selected-messages {
  display: flex;
}

.select-databases {
  position: relative;
}

/*.select-databases::before {*/
/*  content: "Databases";*/
/*  position: absolute;*/
/*  top: 0;*/
/*  transform: translateY(-100%);*/
/*}*/

.select-databases >>> .selected {
  margin-right: 10px;
  width: 140px;
  height: 35px;
  color: var(--secondary-text-color);
}

.select-databases >>> .items {
  top: 0;
  transform: translateY(calc(-100% - 30px));
}

.label-checkbox {
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;
  border: 1px solid var(--accent-color);
  border-radius: 1px;
}

.label-checkbox:after {
  background: var(--accent-color);
}

</style>
