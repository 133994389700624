<template>
  <div class="wrapper">
    <AlertSuccessMsg
      :title="successMessage"
      class="page-alert-error"
      ref="alert"
    />
    <SmallAlert ref="smallAlert" :messageText="successMessage"/>
    <CustomSearch
      :modelValue="keySearch"
      id="cases_search_btn"
      @update:modelValue="keySearch = $event"
      @filter="filter"
      :classComponent="'case'"
      placeholder="Search by name"
    />
    <div class="holder-table">
      <TableHeader>
        <div class="email">
          <div class="table-header-box">
            <span class="header-table-text">Email</span>
          </div>
        </div>

        <div class="screenshots">
          <div class="table-header-box">
            <span class="header-table-text">Screenshots</span>
          </div>
        </div>

        <div class="filtering">
          <div class="table-header-box">
            <span class="header-table-text" style="word-wrap: break-word">Custodian Pre-Filtering in Collect App</span>
          </div>
        </div>

        <div class="date">
          <div class="table-header-box">
            <span class="header-table-text">Date range</span>
          </div>
        </div>

        <div class="sources">
          <div class="table-header-box">
            <span class="header-table-text">Data Sources</span>
          </div>
        </div>

        <div class="contacts">
          <div class="table-header-box">
            <span class="header-table-text">Contacts</span>
          </div>
        </div>

        <div class="calls">
          <div class="table-header-box">
            <span class="header-table-text">Call logs</span>
          </div>
        </div>
      </TableHeader>

      <div v-if="custodians.length > 0" class="table-body">
        <CustodianSettingsItem
          v-for="(custodian, index) in custodians"
          :key="index"
          :custodian="custodian"
          @changed="saveClientConfig"
        />
      </div>
      <div class="search-custodians" v-else>
        <h3>No custodians found</h3>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSearch from "@/components/inputs/CustomSearch.vue";
import TableHeader from "@/components/table/TableHeader.vue";
import CustodianSettingsItem from "@/components/сases/case-settings/CustodianSettingsItem.vue";
import AlertSuccessMsg from "@/components/profile/AlertSuccessMsg.vue";
import { nextTick } from "vue";
import SmallAlert from "@/components/alerts/SmallAlert.vue";

export default {
  name: "CaseSettingsGeneral",
  components: {
    SmallAlert,
    AlertSuccessMsg,
    CustodianSettingsItem,
    TableHeader,
    CustomSearch,
  },
  data() {
    return {
      keySearch: '',
      custodians: [],
      successMessage: '',
    }
  },
  methods: {
    filter() {
      nextTick(() => this.getClientsConfig())
    },
    getClientsConfig() {
      this.$load(async () => {
        await this.$api.caseConfig.getClientsConfig(this.$route.params.slug, this.keySearch).then((res) => {
          this.custodians = res.data.body.map(custodian => {
            return {
              ...custodian,
              ...this.arrayToNestedObject(custodian.configs)
            }
          });
        })
      })
    },
    saveClientConfig(custodian) {
      this.$load(async () => {
        await this.$api.caseConfig.updateCaseConfig(
          this.$route.params.slug,
          custodian.client.publicId,
          this.nestedObjectToArray(custodian));

        this.successMessage = 'Custodian ' + custodian.client.email + ' settings saved';
        this.$refs.smallAlert.showAlert();
      });
    },
    arrayToNestedObject(configArray) {
      const configs = {};

      configArray.forEach((item) => {
        switch (item.configName) {
          case "CASE_SCREENSHOT_ENABLED":
            configs.screenshotsConfig = {
              configName: item.configName,
              editable: item.editable,
              value: item.value === "true",
            };
            break;

          case "CASE_SPEECH_TO_TEXT_ENABLED":
            configs.speechConfig = {
              configName: item.configName,
              editable: item.editable,
              value: item.value === "true",
            };
            break;

          case "CASE_DATE_FROM":
            configs.dateFromConfig = {
              configName: item.configName,
              editable: item.editable,
              value: item.value,
            };
            break;

          case "CASE_DATE_TO":
            configs.dateToConfig = {
              configName: item.configName,
              editable: item.editable,
              value: item.value,
            };
            break;

          case "CASE_APP_LIST":
            configs.appConfig = {
              configName: item.configName,
              editable: item.editable,
              value: item.value,
            };
            break;

          case "CASE_CONTACTS_ENABLED":
            configs.contactsConfig = {
              configName: item.configName,
              editable: item.editable,
              value: item.value === "true",
            };
            break;

          case "CASE_CALLS_ENABLED":
            configs.callLogsConfig = {
              configName: item.configName,
              editable: item.editable,
              value: item.value === "true",
            };
            break;

          default:
            break;
        }
      });

      return { configs };
    },
    nestedObjectToArray(nestedConfig) {
      const resultArray = [];

      for (const key in nestedConfig.configs) {
        const config = nestedConfig.configs[key];
          resultArray.push({
            configName: config.configName,
            value: config.value === true ? "true" : config.value === false ? "false" : config.value ? config.value : "-",
          });
        }
      return resultArray;
    },
  },
  created() {
    this.getClientsConfig();
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;

  .holder-table {
    margin: 15px 0 0;

    .table-body {
      min-height: fit-content;
    }

    .table-header {
      max-height: 64px;
      height: 64px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 15px 0 15px 22px;
    }

    .table-header-box {
      cursor: default;

      svg {
        max-width: 16px;
        width: 100%;
      }
    }

    .table-header-box:hover .header-table-text {
      text-decoration: none;
    }

    .email {
      width: 25%;
    }

    .screenshots {
      width: 10%;
    }

    .filtering {
      width: 10%;
      padding: 5px 5px 5px 0;
    }

    .date {
      width: 22%;
    }

    .sources {
      width: 21%;
    }

    .contacts {
      width: 10%;
      padding: 0 5px;
    }

    .calls {
      width: 8%;
    }

    .btn-save-settings {
      width: 400px;
      height: 50px;
      margin: 30px 30px 0 0;
      padding: 14px 20px;
      border-radius: 25px;
    }

    .search-custodians {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70vh;

      h3 {
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        text-align: left;
        color: var(--light-gray);
      }
    }
  }
}
</style>
