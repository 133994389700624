import { render, staticRenderFns } from "./CaseActionsClients.vue?vue&type=template&id=a3b4633a&scoped=true"
import script from "./CaseActionsClients.vue?vue&type=script&lang=js"
export * from "./CaseActionsClients.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3b4633a",
  null
  
)

export default component.exports