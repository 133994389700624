<template>
  <div class="email-template">
    <div
      v-if="!loadFlag"
      class="email"
    >
      <div class="email-top">
        <div class="email-header indent">
        <span
          class="email-topic"
          v-text="content.subject"
        />
          <span
            class="email-folder_name"
            v-text="content.folder"
          />
        </div>
        <div class="email-info">
          <div class="email-sender-wrap">
            <div class="email-sender_image">
              <img v-if="content.image" :src="content.image" alt="Sender Image">
              <img v-else src="@/assets/img/sender-default-img.svg" alt="Sender Image">
            </div>
            <div class="email-sender">
              <p
                class="email-sender_name"
                v-text="content.sender"
              />
              <p class="email-recipient">
                <span>to: </span>
                <span
                  v-for="(item, index) in content.recipients"
                  :key="index"
                  v-text="item + (content.recipients.length - 1 !== index ? ',' : '')"
                />
              </p>
            </div>
          </div>
          <p
            class="email-time"
            v-text="`${parseDate(+content.date)} ${parseDateTime(+content.date)}`"
          />
        </div>
        <div class="email-content indent">
          <div class="email-text" v-html="content.content" />
        </div>
      </div>
      <div
        v-if="content.attachments && content.attachments.length"
        class="email-bottom indent"
      >
        <p class="email-attached-text" v-text="`${content.attachments.length} attached files`"/>
        <div class="email-attachment-list">
          <a
            :id="`email-template-${content.id}-attachment-file-${index + 1}`"
            class="email-attached"
            target="_blank"
            v-for="(item, index) in content.attachments"
            :key="index"
            :href="item"
          >

          <span class="email-attached-default">
            <svg width="22" height="30" viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.964 0.883789V7.92356C10.964 8.72373 11.2819 9.49113 11.8477 10.0569C12.4135 10.6227 13.1809 10.9406 13.9811 10.9406H21.0209V26.0258C21.0209 26.826 20.703 27.5934 20.1372 28.1592C19.5714 28.725 18.804 29.0429 18.0038 29.0429H3.92427C3.1241 29.0429 2.3567 28.725 1.7909 28.1592C1.22509 27.5934 0.907227 26.826 0.907227 26.0258V3.90083C0.907227 3.10066 1.22509 2.33327 1.7909 1.76746C2.3567 1.20166 3.1241 0.883789 3.92427 0.883789H10.964ZM12.9754 1.38663V7.92356C12.9754 8.19028 13.0814 8.44608 13.27 8.63469C13.4586 8.82329 13.7144 8.92924 13.9811 8.92924H20.518L12.9754 1.38663Z"
                fill="#082655"/>
            </svg>
          </span>
            <span
              v-if="item"
              class="email-file-format"
              v-text="formatCheck(item)"
            />
          </a>
        </div>
      </div>
    </div>
    <div v-else class="loader-bg">
      <div class="loader-p"></div>
    </div>
  </div>
</template>

<script>

import parseDate from "@/mixins/parseDate";
import parseDateTime from "@/mixins/parseTime";

export default {
  name: 'CaseEmailSpecificMessage',
  data() {
    return {
      loadFlag: true,
      caseId: this.$route.params.slug,
      emailId: this.$route.params.emailId,
      messageId: this.$route.params.messageId,
      content: {}
    }
  },
  mixins: [parseDate, parseDateTime],
  methods: {
    showErrorMessage(errorText) {
      this.$emit('error-show', errorText);
    },
    getMessage() {
      this.loadFlag = true;
      this.$load(async () => {
        await this.$api.cases.getCaseEmailSpecificMessage(this.caseId, this.emailId, this.messageId).then((res) => {
          this.content = res.data.body;
          this.loadFlag = false;
        })
      }, (error) => {
        if (error && error.response) {
          this.showErrorMessage(error.response.data.errors.message[0]);
        } else {
          this.showErrorMessage(error.data.errors.message[0]);
        }
        this.loadFlag = false;
      })
    },
    formatCheck(url) {
      if (url) {
        const arr = url.split('.');
        return `.${arr[arr.length - 1]}`;
      }
    }
  },
  mounted() {
    this.$emit('title-show', 'Messages’ list');
    this.getMessage();
  }
}
</script>

<style scoped>

.email-template {
  padding: 30px 55px 0 30px;
}

.indent {
  margin-left: 35px;
}

.email-top {
  padding-bottom: 24px;
  border-bottom: 1px solid #D7DCE6;
  margin-bottom: 24px;
}

.email-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.email-topic {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--secondary-black-color);
}

.email-folder_name {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--secondary-text-color);
}

.email-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.email-sender-wrap {
  display: flex;
  align-items: center;
}

.email-sender_image {
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;
}

.email-sender_image img {
  width: 100%;
  height: 100%;
}

.email-sender_name {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--secondary-black-color);
  margin-bottom: 3px;
}

p.email-recipient {
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: var(--secondary-text-color);
  margin-bottom: 0;
}

p.email-recipient span:not(:last-child) {
  margin-right: 4px;
}

.email-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--secondary-text-color);
  word-break: break-word;
  text-align: left;
  white-space: pre-line;
}

.email-time {
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  text-align: right;
  color: var(--secondary-text-color);
}

.email-attached-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--secondary-black-color);
  margin-bottom: 16px;
}

.email-attached {
  width: 171px;
  height: 114px;
  position: relative;
  background-color: var(--active-accent-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: .2s ease;
  margin-bottom: 15px;
}

.email-attached:hover {
  background-color: rgba(28, 202, 205, 0.5);
}

.email-attached:not(:last-child) {
  margin-right: 15px;
}

.email-attached-default {
  border-radius: 50%;
  width: 59px;
  height: 59px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-file-format {
  position: absolute;
  bottom: 3px;
  right: 3px;
  min-width: 46px;
  padding: 3px 12px;
  border: 0.5px solid #D7DCE6;
  border-radius: 79px;
  background-color: #F8FCFC;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: var(--secondary-black-color);
}

.loader-bg {
  position: static;
  transform: none;
  height: 300px;
}

.email-attachment-list {
  display: flex;
  flex-wrap: wrap;
}

</style>