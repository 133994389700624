<template>
  <div>
    <Header/>
    <section class="section">
      <div class="container-small">
        <otp-code ref="otpInput"
                  :otpVerify="otpVerify"
                  :otpValid="otpValid"
                  @update:otpValid="otpValid = $event"
        />
      </div>
    </section>
  </div>
</template>

<script>
import Header from "../components/layout/Header";
import OtpCode from "../components/login/OtpCode";

export default {
  name: "LoginInAccountOtp",
  data() {
    return {
      validatePhoneStatus: false,
      count: 30,
      disabledButton: false,
      phoneVerifyBtn: 'Send Password',
      otpVerify: null,
      errorMessage: '',
      otpCode: null,
      otpValid: false,
    };
  },
  components: {
    Header,
    OtpCode
  },
}
</script>

<style scoped>

</style>