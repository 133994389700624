<template>
  <div class="holder-table">
    <TableHeader>
      <div
        class="email"
        :style="{ width: $route.params.slug ? '35%' : '25%' }"
      >
        <div class="table-header-box">
          <EmailIcon />
          <span class="header-table-text">Custodian Email</span>
        </div>
      </div>

      <div v-if="!$route.params.slug" class="case">
        <div class="table-header-box">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3888 3.35802C14.8384 3.35802 15.2194 3.52263 15.5316 3.85185C15.8439 4.18107 16 4.58272 16 5.05679V14.3012C16 14.7753 15.8439 15.177 15.5316 15.5062C15.2194 15.8354 14.8384 16 14.3888 16H1.61124C1.16159 16 0.78064 15.8354 0.468384 15.5062C0.156128 15.177 0 14.7753 0 14.3012V5.05679C0 4.58272 0.156128 4.18107 0.468384 3.85185C0.78064 3.52263 1.16159 3.35802 1.61124 3.35802H4.79625V1.65926C4.79625 1.21152 4.95238 0.823045 5.26464 0.493827C5.57689 0.164609 5.95785 0 6.40749 0H9.59251C10.0422 0 10.4231 0.164609 10.7354 0.493827C11.0476 0.823045 11.2037 1.21152 11.2037 1.65926V3.35802H14.3888ZM9.59251 3.35802V1.65926H6.40749V3.35802H9.59251Z" fill="#D7DCE6"/>
          </svg>
          <span class="header-table-text">Case</span>
        </div>
      </div>

      <div class="registered">
        <div class="table-header-box">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16339_663)">
              <path d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z" fill="#D7DCE6"/>
            </g>
            <defs>
              <clipPath id="clip0_16339_663">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <span class="header-table-text">Registration date</span>
        </div>
      </div>

      <div class="usage">
        <div class="table-header-box">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16339_663)">
              <path d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z" fill="#D7DCE6"/>
            </g>
            <defs>
              <clipPath id="clip0_16339_663">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <span class="header-table-text">Usage period</span>
        </div>
      </div>

      <div class="days-left">
        <div class="table-header-box">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16339_663)">
              <path d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V5H16V4H0V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0Z" fill="#D7DCE6"/>
            </g>
            <defs>
              <clipPath id="clip0_16339_663">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <span class="header-table-text">Days left</span>
        </div>
      </div>

      <div class="status">
        <div class="table-header-box">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 16C6.55208 16 5.21354 15.6432 3.98438 14.9297C2.75521 14.2161 1.78385 13.2448 1.07031 12.0156C0.356771 10.7865 0 9.44792 0 8C0 6.55208 0.356771 5.21354 1.07031 3.98438C1.78385 2.75521 2.75521 1.78385 3.98438 1.07031C5.21354 0.356771 6.55208 0 8 0C9.44792 0 10.7865 0.356771 12.0156 1.07031C13.2448 1.78385 14.2161 2.75521 14.9297 3.98438C15.6432 5.21354 16 6.55208 16 8C16 9.44792 15.6432 10.7865 14.9297 12.0156C14.2161 13.2448 13.2448 14.2161 12.0156 14.9297C10.7865 15.6432 9.44792 16 8 16ZM1 8C1 8.94792 1.17969 9.84896 1.53906 10.7031C1.89844 11.5573 2.40104 12.3073 3.04688 12.9531L8 8V1C7.05208 1 6.14583 1.1849 5.28125 1.55469C4.41667 1.92448 3.67188 2.42188 3.04688 3.04688C2.42188 3.67188 1.92448 4.41667 1.55469 5.28125C1.1849 6.14583 1 7.05208 1 8Z" fill="#D7DCE6"/>
          </svg>
          <span class="header-table-text">Status</span>
        </div>
      </div>
    </TableHeader>

    <div v-if="keysData.length > 0" class="table-body">
      <div
        class="table-row"
        v-for="(key, index) in keysData"
        :key="index"
      >
        <div class="card">

          <div
            class="email"
            :style="{ width: $route.params.slug ? '35%' : '25%' }"
          >
            <div class="holder-info">
              <span>{{ key.email }}</span>
            </div>
          </div>

          <div
            v-if="!$route.params.slug"
            class="case"
            @click="$router.push({path: `/cases/${key.caseId}`}).catch(()=>{})"
          >
            <div class="holder-info">
              <span>{{ key.caseName }}</span>
            </div>
          </div>

          <div class="registered">
            <div class="holder-info">
              <span>{{ parseDate(key.createdAt) }}</span>
            </div>
          </div>

          <div class="usage">
            <div class="holder-info">
              <span>{{ parseDate(key.usageDateFrom) + " - " + parseDate(key.usageDateTo) }}</span>
            </div>
          </div>

          <div class="days-left">
            <div class="holder-info">
              <span :style="{ color: key.dayLeft > 5 ? '#5692B7' : key.dayLeft > 0 ? '#FF9537' : '#F1105F' }">
                {{ (key.dayLeft ? key.dayLeft : "0") + " days" }}
              </span>
            </div>
          </div>

          <div class="status">
            <div
              class="holder-info"
              :class="{
                active: key.status === 'Active',
                pending: key.status === 'Pending',
                expired: key.status === 'Expired',
              }">
              <span>{{ key.status }}</span>
            </div>
          </div>

          <div class="key-button">
            <button
              v-if="key.status === 'Expired'"
              id="button_search_sessions"
              class="btn btn-create-case"
              @click="openKeyPopup(key, 'gen')"
            >
              Generate
              <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.875 1.93748V5.31248C14.875 5.46166 14.8157 5.60474 14.7103 5.71023C14.6048 5.81572 14.4617 5.87498 14.3125 5.87498H10.9375C10.7883 5.87498 10.6452 5.81572 10.5398 5.71023C10.4343 5.60474 10.375 5.46166 10.375 5.31248C10.375 5.16329 10.4343 5.02022 10.5398 4.91473C10.6452 4.80924 10.7883 4.74998 10.9375 4.74998H12.8641L10.9945 3.03717L10.9769 3.02029C10.1951 2.23876 9.20028 1.70492 8.11682 1.48547C7.03336 1.26602 5.90925 1.37068 4.88493 1.78638C3.86061 2.20207 2.98148 2.91038 2.35737 3.82281C1.73326 4.73524 1.39184 5.81134 1.37575 6.91669C1.35967 8.02203 1.66964 9.10761 2.26694 10.0378C2.86424 10.968 3.72239 11.7016 4.73419 12.1469C5.74598 12.5922 6.86657 12.7296 7.95595 12.5417C9.04534 12.3539 10.0552 11.8492 10.8595 11.0908C10.9679 10.9882 11.1126 10.9329 11.2618 10.9371C11.411 10.9412 11.5525 11.0045 11.655 11.1129C11.7576 11.2213 11.8129 11.3661 11.8087 11.5153C11.8046 11.6645 11.7413 11.8059 11.6329 11.9085C10.3824 13.0945 8.72348 13.7539 7.00001 13.75H6.90719C5.8017 13.7348 4.71684 13.4484 3.74798 12.9158C2.77912 12.3832 1.95591 11.6208 1.35072 10.6955C0.745522 9.77025 0.376864 8.71051 0.277146 7.60942C0.177429 6.50832 0.349705 5.39959 0.778832 4.38067C1.20796 3.36175 1.8808 2.46383 2.73822 1.76584C3.59563 1.06785 4.61138 0.59116 5.69617 0.377661C6.78096 0.164162 7.90158 0.220394 8.95955 0.541414C10.0175 0.862434 10.9804 1.43841 11.7637 2.21873L13.75 4.03279V1.93748C13.75 1.78829 13.8093 1.64522 13.9148 1.53973C14.0202 1.43424 14.1633 1.37498 14.3125 1.37498C14.4617 1.37498 14.6048 1.43424 14.7103 1.53973C14.8157 1.64522 14.875 1.78829 14.875 1.93748Z" fill="white"/>
              </svg>
            </button>
            <button
              v-if="key.status === 'Active' || key.status === 'Pending'"
              id="button_search_sessions"
              class="btn btn-revert btn-active"
              @click="openKeyPopup(key, 'send')"
            >
              Resend
              <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.26742 6.56235L15.2074 2.29485C15.347 2.22938 15.5034 2.20852 15.6553 2.23513C15.8072 2.26173 15.9472 2.33451 16.0562 2.44355C16.1653 2.55258 16.238 2.69258 16.2646 2.84446C16.2913 2.99635 16.2704 3.15275 16.2049 3.29235L11.9374 15.2324C11.8997 15.3981 11.8067 15.546 11.6739 15.652C11.541 15.758 11.3761 15.8157 11.2062 15.8157C11.0362 15.8157 10.8713 15.758 10.7385 15.652C10.6056 15.546 10.5127 15.3981 10.4749 15.2324L8.59242 10.3424C8.55499 10.2389 8.49528 10.145 8.41751 10.0673C8.33975 9.98949 8.24583 9.92978 8.14242 9.89235L3.25242 8.02485C3.08707 7.98542 2.94003 7.891 2.83538 7.75705C2.73073 7.62311 2.67469 7.45759 2.67643 7.28762C2.67817 7.11765 2.7376 6.95331 2.84498 6.82154C2.95235 6.68977 3.1013 6.59838 3.26742 6.56235Z" fill="#082655"/>
                <path d="M8.46492 10.0349L10.7149 7.78485M3.26742 6.56235L15.2074 2.29485C15.347 2.22938 15.5034 2.20852 15.6553 2.23513C15.8072 2.26173 15.9472 2.33451 16.0562 2.44355C16.1653 2.55258 16.238 2.69258 16.2646 2.84446C16.2913 2.99635 16.2704 3.15275 16.2049 3.29235L11.9374 15.2324C11.8997 15.3981 11.8067 15.546 11.6739 15.652C11.541 15.758 11.3761 15.8157 11.2062 15.8157C11.0362 15.8157 10.8713 15.758 10.7385 15.652C10.6056 15.546 10.5127 15.3981 10.4749 15.2324L8.59242 10.3424C8.55499 10.2389 8.49528 10.145 8.41751 10.0673C8.33975 9.98949 8.24583 9.92978 8.14242 9.89235L3.25242 8.02485C3.08707 7.98542 2.94003 7.891 2.83538 7.75705C2.73073 7.62311 2.67469 7.45759 2.67643 7.28762C2.67817 7.11765 2.7376 6.95331 2.84498 6.82154C2.95235 6.68977 3.1013 6.59838 3.26742 6.56235V6.56235Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>

        </div>
      </div>
    </div>
    <div class="search-cases" v-else>
      <h3>No keys found</h3>
    </div>
    <key-management-popup
      ref="keyManagementPopup"
      :clientKey="currentKey"
      :action="popupAction"
      @sendKey="$emit('sendKey', $event)"
    />
  </div>
</template>

<script>
import TableHeader from "../table/TableHeader";
import EmailIcon from "../icons/EmailIcon";
import parseDate from "@/mixins/parseDate";
import KeyManagementPopup from "@/components/popups/KeyManagementPopup.vue";

export default {
  name: "ManageKeysList",
  components: {
    KeyManagementPopup,
    EmailIcon,
    TableHeader,
  },
  props: {
    keysData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      popupAction: 'send',
      currentKey: null,
    }
  },
  mixins: [parseDate],
  methods: {
    openKeyPopup(key, action) {
      this.popupAction = action;
      this.currentKey = key;
      if (action === "gen") {
        this.generateNewKey(key.id);
      }
      this.$refs.keyManagementPopup.openPopups();
    },
    generateNewKey(keyId) {
      this.$load(async () => {
        await this.$api.management.regenerateKey(keyId)
          .then((res) => {
            this.currentKey.apiKey = res.data.body.key;
          });
      }, (error) => {
        if (error.response) {
          this.errorMessage = error.response.data.errors.message[0]
        } else {
          this.errorMessage = error.data.errors.message[0]
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>

.holder-table {
  margin: 15px 0 0;
}

.table-body {
  min-height: fit-content;
}

.table-header {
  max-height: 64px;
  height: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0 15px 22px;
}

.table-header-box {
  cursor: default;
}

.table-header-box:hover .header-table-text {
  text-decoration: none;
}

.email {
  width: 25%;
}

.case {
  width: 10%;
}

.registered {
  width: 15%;
}

.usage {
  width: 17%;
}

.days-left {
  width: 10%;
}

.status {
  width: 10%;
}

.key-button {
  width: 11%;
}

.card {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 15px 0;
  transition: all 0.5s;
  position: relative;
  border-bottom: 1px solid var(--case-btn-border-color);
  width: 100%;
}

.holder-info {
  display: flex;
  align-items: center;
}

.holder-info {
  margin: 0 0 0 15px;
  color: var(--secondary-text-color);
}

.case .holder-info span {
  cursor: pointer;
  text-decoration: underline;
}

.dialog-message-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #292B2F;
  text-align: center;
  margin: 25px 0;
  display: block;
  width: 100%;
}
.dialog-message-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #696F79;
  display: block;
  width: 100%;
  margin-top: 20px;
}

.arrow {
  transition: transform 0.3s;
}

.search-cases {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.search-cases h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  color: var(--light-gray);
}

.btn {
  display: flex;
  gap: 5px;
}

.active {
  width: 76px;
  height: 25px;
  padding: 10px 20px;
  border-radius: 20px;
  background: #0826551A;
}

.active span {
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  color: var(--accent-color);
}

.expired {
  width: 76px;
  height: 25px;
  padding: 3px 12px;
  border-radius: 20px;
  background: var(--storage-warning);
}

.expired span {
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  color: var(--white-color);
}

.pending {
  width: 76px;
  height: 25px;
  padding: 3px 14px;
  border-radius: 20px;
  background: #FFC10780;
  border: 1px solid #FFC107;
}

.pending span {
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  color: #8B6900;
}

.btn-active svg path {
  fill: var(--accent-color);
}

</style>
