<template>
<div class="wrapper">
  <Sidebar />
  <div class="content-wrapper">
    <HeaderRegistered title="My profile"/>
    <main class="main">
      <section class="profile-section">
        <ul class="profile-nav">
          <router-link
              :to="{ path: '/profile-user' }"
              tag="li"
              id="profile-user"
              exact-path
              :class="{'active': $route.path === ('/cases')}"
          >
            <a>Edit profile</a>
          </router-link>
          <router-link
              v-if="checkPermission(['view_system_settings'])"
              :to="{ path: '/profile-user/information' }"
              tag="li"
              id="profile-information"
              exact-path
          >
            <a>Company information</a>
          </router-link>
          <router-link
            :to="{ path: '/profile-user/security' }"
            tag="li"
            id="profile-security"
            exact-path
          >
            <a>Security</a>
          </router-link>
          <router-link
              :to="{ path: '/profile-user/password' }"
              tag="li"
              id="profile-security"
              exact-path
          >
            <a>Change password</a>
          </router-link>
          <router-link
            :to="{ path: '/profile-user/phone' }"
            tag="li"
            id="profile-password"
            exact-path
          >
            <a>Change phone number</a>
          </router-link>
          <router-link
            :to="{ path: '/profile-user/email' }"
            tag="li"
            id="profile-password"
            exact-path
          >
            <a>Change email</a>
          </router-link>
        </ul>
        <div class="profile-content">
          <router-view></router-view>
        </div>
      </section>
    </main>
  </div>
</div>
</template>

<script>
import checkPermission from "../utils/permission";
import Sidebar from "../components/Sidebar";
import HeaderRegistered from "../components/layout/HeaderRegistered";

export default {
  name: "Profile",
  components: {
    Sidebar,
    HeaderRegistered,
  },
  methods: {
    checkPermission,
  },
  mounted() {
    document.title = 'Profile'
  },
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

p {
  margin-top: 0;
}

ul {
  padding: 0;
}

.wrapper {
  display: flex;
}

.content-wrapper {
  width: calc(100% - 120px);
}

.profile-section {
  display: flex;
  background-color: var(--white-color);
  padding: 0 15px;
  align-items: initial;
}

.profile-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 270px;
  border-right: 1px solid var(--border-color);
  margin-top: 0;
}

.profile-nav li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--light-gray);
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all .2s;
  border-bottom: 1px solid var(--border-color);
}

.profile-nav li:first-child {
  border-top: 1px solid var(--border-color);
}

.profile-nav li:after {
  content: '';
  width: 8px;
  height: 100%;
  background-color: var(--accent-color);
  border-radius: 10px 0 0 10px;
  opacity: 0;
  transition: all .2s;
}

.profile-nav li a {
  display: block;
  color: var(--light-gray);
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 25px 0 25px 20px;
}

.profile-nav li a:hover {
  color: var(--secondary-black-color);
  background-color: rgba(28, 202, 205, 0.05);
}

.profile-nav li:hover:after  {
  opacity: 1;
}

.profile-nav li.active::after {
  opacity: 1;
}

.profile-nav li.active a {
  background: var(--active-accent-color);
  color: var(--secondary-black-color);
}

.profile-content {
  width: calc(100% - 270px);
  padding: 20px 40px;
}
</style>