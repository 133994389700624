import axios from 'axios'
import LocalStorageService from "../services/LocalStorageService";
import fixBaseURL from "@/utils/fixBaseURL";

const localStorageService = LocalStorageService.getService();

const fixedBaseURL = fixBaseURL(`${process.env.VUE_APP_API}/api/v1`);

const customAxios = axios.create({
    baseURL: fixedBaseURL,
    headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
    }
})

// Add a request interceptor
customAxios.interceptors.request.use(
    config => {
        const token = localStorageService.getAccessToken();
        const tokenLogin = localStorageService.getAccessTokenLogin();
        if (token) {
            localStorageService.clearTokenLogin()
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        if (tokenLogin) {
            config.headers['Authorization'] = 'Bearer ' + tokenLogin;
        }
        config.headers['Cache-Control'] = 'no-cache';
        config.headers['Pragma'] = 'no-cache';
        config.headers['Expires'] = '0';
        return config;
    },
    error => {
        Promise.reject(error)
    }
);

//Add a response interceptor

customAxios.interceptors.response.use((response) => {
    return response
}, function (error) { return Promise.reject(error); });

export default customAxios;
