<template>
  <div class="case-list">
    <div v-if="showSearchPanel" class="cases-search-panel">
      <CustomSearch
          :modelValue="keySearch"
          id="cases_search_btn"
          @update:modelValue="keySearch = $event"
          @filter="filter"
          :classComponent="'case'"/>
      <div class="cases-filter-panel">
        <div id="disabled-create-case-button" ref="disabledCreateCaseButton">
          <button
              :disabled="!checkPermission(['case_management'])"
              @click="$router.push({name: 'Create Case'})"
              id="button_create_case"
              class="btn btn-create-case"
          >Create new case</button>
        </div>
        <tooltip v-if="!checkPermission(['case_management'])" :target-ref="$refs.disabledCreateCaseButton">
          <span>To access case creation - you need to have system permission - 'Case management'</span>
        </tooltip>
      </div>
    </div>
    <div class="creating-cases" v-if="keySearch && casesFromApi.length === 0">
      <div class="default-cases-image">
        <img src="@/assets/img/no-cases-found.svg" alt="no-cases-found">
      </div>
      <h3 class="creating-cases-title">No cases found, try again</h3>
    </div>
    <div class="creating-cases" v-if="showEmptySection">
      <div class="default-cases-image">
        <img src="@/assets/img/if-cases-is-empty.svg" alt="start-creating-cases">
      </div>
      <h3 class="creating-cases-title">Start with creating cases</h3>
      <div id="disabled-create-case-button-empty" ref="disabledCreateCaseButtonEmpty">
        <button
            id="create_case_button"
            :disabled="!checkPermission(['case_management'])"
            @click="$router.push({name: 'Create Case'})"
            class="btn create-case-btn">
          Create case
        </button>
      </div>
      <tooltip v-if="!checkPermission(['case_management'])" side="bottom" :target-ref="$refs.disabledCreateCaseButtonEmpty">
        <span>To access case creation - you need to have system permission - 'Case management'</span>
      </tooltip>
    </div>
    <div class="cases" v-else>
        <div class="cases-wrapper">
          <Case
              v-for="(caseItem, index) in casesFromApi"
              :key="index"
              :caseItem="caseItem"
              @caseDeleted="getCases"
          />
        </div>
      <pagination v-if="count !== 1"
                  v-model="page"
                  :total-pages="count"
                  :link-gen="linkGen"
      ></pagination>
      <FiltersPopupWrapper ref="caseFilter">
        <CasesFilterBlock/>
      </FiltersPopupWrapper>
    </div>
  </div>
</template>
<script>
import linkGenerator from "../../../constants/linkGen";
import checkPermission from "../../../utils/permission";
import Case from "./Case";
import CustomSearch from "../../inputs/CustomSearch";
import FiltersPopupWrapper from "../../popups/FiltersPopupWrapper";
import CasesFilterBlock from "../case-filters/CasesFilterBlock";
import Tooltip from "../../common/Tooltip.vue";
import Pagination from "@/components/common/Pagination.vue";

export default {
  name: "CasesList",
  components: {
    Pagination,
    Tooltip,
    Case,
    CustomSearch,
    FiltersPopupWrapper,
    CasesFilterBlock
  },
  data() {
    return {
      casesFromApi: [],
      caseName: '',
      page: 1,
      size: 9,
      count: 1,
      pageSize: 2,
      showEmptySection: false,
      showSearchPanel: true,
      keySearch: "",
      errorMessage: ''
    }
  },
  watch: {
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if(newVal) {
          this.page = newVal
          this.getCases()
        } else {
          this.page = 1
        }
      }
    }
  },
  methods: {
    checkPermission,
    linkGen(pageNum) {
      return linkGenerator(pageNum, '/cases')
    },
    fixedEncodeURIComponent (str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    },
    getCases() {
      this.$load(async () => {
        let page = this.page
        let size = this.size
        await this.$api.cases.getCases({
          caseName: this.fixedEncodeURIComponent(this.keySearch),
          page,
          size
        }).then((res) => {
          this.casesFromApi = res.data.body.content
          this.count = res.data.body.totalPages
          this.pageSize = res.data.body.size
          if (!this.keySearch.length && this.casesFromApi.length) this.showSearchPanel = true;
          this.showEmptySection = this.keySearch.length === 0 && this.casesFromApi.length === 0;
          }, (error => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
        })
        )
      })
    },
    filter()  {
      this.getCases()
    },
  },
  mounted() {
    this.page = +this.$route.query.page || 1
    this.getCases()
  },
}
</script>

<style scoped>

.case-list {
  width: 100%;
}

.creating-cases {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 105px 0 0 0;
  text-align: center;
}

.default-cases-image {
  max-width: 387px;
  margin: 0 auto;
}

.default-cases-image img {
  max-width: 100%;
  max-height: 100%;
}

.creating-cases-title {
  margin: 33px 0 15px 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--light-gray);
}

.create-case-btn {
  padding: 9px 19px;
  background-color: var(--white-color);
  color: var(--accent-color);
  border: 1px solid var(--accent-color);
}

.create-case-btn:hover {
  background-color: var(--accent-color);
  color: var(--white-color);
}

.cases-filter-panel {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.cases-search-panel {
  display: flex;
  justify-content: space-between;
}

.cases-wrapper {
  width: 100%;
  padding: 60px 0 0 0;
  display: flex;
  flex-wrap: wrap;
}

.btn-create-case {
  margin-right: 30px;
  padding: 14px 20px;
  border-radius: 25px;
}

</style>
