import { render, staticRenderFns } from "./CaseMessagesCallLogs.vue?vue&type=template&id=aff95b90&scoped=true"
import script from "./CaseMessagesCallLogs.vue?vue&type=script&lang=js"
export * from "./CaseMessagesCallLogs.vue?vue&type=script&lang=js"
import style0 from "./CaseMessagesCallLogs.vue?vue&type=style&index=0&id=aff95b90&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aff95b90",
  null
  
)

export default component.exports