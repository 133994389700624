<template>
  <div
      v-if="unsupportedFormat"
      @click="$emit('downloadFile', attachment)"
      :data-after-content="'.' + attachmentFormat"
      class="application attachment"
  >
    <div class="application-wrap" :data-after-content="'.' + attachmentFormat">
      <div class="circle">
        <svg width="22" height="30" viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9636 0.884766V7.92454C10.9636 8.72471 11.2815 9.49211 11.8473 10.0579C12.4131 10.6237 13.1805 10.9416 13.9807 10.9416H21.0205V26.0268C21.0205 26.827 20.7026 27.5944 20.1368 28.1602C19.571 28.726 18.8036 29.0439 18.0034 29.0439H3.92388C3.1237 29.0439 2.35631 28.726 1.7905 28.1602C1.2247 27.5944 0.90683 26.827 0.90683 26.0268V3.90181C0.90683 3.10164 1.2247 2.33424 1.7905 1.76844C2.35631 1.20263 3.1237 0.884766 3.92388 0.884766H10.9636ZM12.975 1.38761V7.92454C12.975 8.19126 13.081 8.44706 13.2696 8.63566C13.4582 8.82426 13.714 8.93022 13.9807 8.93022H20.5176L12.975 1.38761Z" fill="#082655"/>
        </svg>
      </div>
    </div>
  </div>
  <div
      v-else
      :class="[{'application': unsupportedFormat }, attachmentType]"
      :data-after-content="isPaused ? '.' + attachmentFormat : 'playing'"
      class="attachment"
  >
    <div class="check">
      <CustomCheckbox
        :id="attachment.id"
        :input-element="attachment"
        :item="attachment"
        @checked="changeCheckbox"
        white-theme
      />
    </div>
    <div class="application-wrap"
         :data-after-content="isPaused ? '.' + attachmentFormat : 'playing'"
         v-if="attachmentType === 'application' || unsupportedFormat"
    >
      <div class="circle">
        <svg width="22" height="30" viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9636 0.884766V7.92454C10.9636 8.72471 11.2815 9.49211 11.8473 10.0579C12.4131 10.6237 13.1805 10.9416 13.9807 10.9416H21.0205V26.0268C21.0205 26.827 20.7026 27.5944 20.1368 28.1602C19.571 28.726 18.8036 29.0439 18.0034 29.0439H3.92388C3.1237 29.0439 2.35631 28.726 1.7905 28.1602C1.2247 27.5944 0.90683 26.827 0.90683 26.0268V3.90181C0.90683 3.10164 1.2247 2.33424 1.7905 1.76844C2.35631 1.20263 3.1237 0.884766 3.92388 0.884766H10.9636ZM12.975 1.38761V7.92454C12.975 8.19126 13.081 8.44706 13.2696 8.63566C13.4582 8.82426 13.714 8.93022 13.9807 8.93022H20.5176L12.975 1.38761Z" fill="#082655"/>
        </svg>
      </div>
    </div>
    <img
        v-else-if="attachmentType === 'image'"
        :src="attachment.link"
        alt="attachment"
        @click="openImage"
    >
    <div class="video-wrap" v-else-if="attachmentType === 'video'">
      <div class="player-wrap" @click="openImage">
        <vue-plyr :options="{ enabled: false, clickToPlay: false }">
          <video
              @pause="isPaused = true"
              @play="isPaused = false"
          >
            <source
                :blankVideo="attachment.link"
                :src="attachment.link"
                class="video"
            />
          </video>
        </vue-plyr>
      </div>
    </div>
    <div class="attachment-format-sign">
      <span>{{ "." + attachmentFormat }}</span>
    </div>
    <CommonDialog class="image-popup" ref="imagePopup">
      <img
          v-if="attachmentType === 'image'"
          class="popup-image"
          :src="attachment.link"
          alt="attachment"
      >
      <vue-plyr v-else-if="attachmentType === 'video'">
        <video
          @pause="isPaused = true"
          @play="isPaused = false"
        >
          <source
            :blankVideo="attachment.link"
            :src="attachment.link"
            class="video"
          />
        </video>
      </vue-plyr>
    </CommonDialog>
  </div>
</template>
<script>
import CommonDialog from '@/components/popups/CommonDialog';
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";

export default {
  name: 'Attachments',
  components: {CustomCheckbox, CommonDialog },
  emits: ["changeCheckbox"],
  props: {
    attachment: {
      type: Object,
    },
  },
  data() {
    return {
      isPaused: true,
      attachmentType: null,
      attachmentFormat: null,
    };
  },
  computed: {
    unsupportedFormat() {
      switch (this.attachmentFormat) {
        case 'ogg':
        case 'mp4':
        case 'ogv':
        case 'webm':
        case 'jpeg':
        case 'jpg':
        case 'png':
        case 'gif':
        case 'webp':
          return false;
        default: return true;
      }
    },
    textVersion() {
      return this.attachment.textVersion;
    },
  },
  methods: {
    getAttachmentType() {
      this.attachmentType = this.attachment.type.split('/')[0].toLowerCase();
      this.attachmentFormat = this.attachment.name.split('.').reverse()[0].toLowerCase();
    },
    openImage() {
      this.$refs.imagePopup.openPopups();
    },
    changeCheckbox(item) {
      this.$emit('changeCheckbox', item);
    },
  },
  mounted() {
    this.getAttachmentType();
  },
};
</script>
<style scoped>
.attachment-wrapper .application,
.attachment-wrapper .image {
  position: relative;
  width: 170px;
  min-height: 115px;
}

.attachment-wrapper .application {
  display: flex;
  align-items: flex-start;
}

.attachment-wrapper .application .application-wrap {
  cursor: pointer;
  background-color: var(--file-background);
  max-width: 170px;
  height: 115px;
  justify-content: center;
}

.attachment-wrapper .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
  border-radius: 50%;
  width: 59px;
  height: 59px;
}

.attachment-wrapper .application-wrap:after,
.attachment-wrapper .application:not(.with-recognition-text).image:after,
.attachment-wrapper .application:not(.with-recognition-text).video:after {
  content: attr(data-after-content);
  position: absolute;
  right: 8px;
  bottom: 8px;
  border-radius: 79px;
  padding: 3px 11px;
  background-color: #F8FCFC;
  border: 1px solid #D7DCE6;
  font-size: 12px;
  font-weight: 500;
  line-height: 160%;
  color: #373c44;
}

.attachment-wrapper .video:after {
  top: 150px;
  right: unset;
  bottom: unset;
  left: 270px;
  background-color: #373C44;
  border: none;
  color: #fff;
}

.attachment-wrapper .application-wrap,
.attachment-wrapper .video {
  position: relative;
}

.attachment-wrapper .video[data-after-content="playing"]:after {
  display: none;
}

.attachment-wrapper .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.attachment:not(:last-child) {
  margin-bottom: 8px;
}

.attachment {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 115px;
  overflow: hidden;
  margin-right: 12px;
}

.image-popup >>> .common-dialog-wrapper {
  max-width: 700px;
  padding: 55px 10px;
  max-height: 70%;
  height: 100%;
}

.common-dialog-wrapper img.popup-image {
  object-fit: contain;
}

.image-popup >>> .dialog-btn {
  display: none;
}

.application-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.video-wrap, .player-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.video-wrap .player-wrap {
  width: 363px;
  height: 185px;
}

.video-wrap >>> .plyr {
  flex-grow: 1;
  border-radius: 8px;
}

.video-wrap >>> .plyr video {
  object-fit: cover;
}

.video-wrap >>> .plyr {
  left: 10px;
}

.attachment-format-sign {
  position: absolute;
  text-align: center;
  width: 46px;
  height: 25px;
  top: 86px;
  left: 122px;
  padding: 2px 0 0 0;
  border-radius: 79px;
  border: 0.5px solid var(--case-btn-border-color);
  background: var(--gray-bg-color-2);
}

.attachment-format-sign span {
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  color: var(--secondary-black-color);
}

.check {
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 5px;
}

</style>
