<template>
  <div class="holder-table">
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
    <div class="search-bar">
      <div class="holder-search">
        <CustomSearch
            id="shared_search"
            placeholder="Search by case name"
        />
      </div>
    </div>
    <TableHeader>
      <table cellpadding="0" cellspacing="0" border="0">
        <thead>
          <tr>
            <th>
              <div class="holder-info">
                <span>Case Name</span>
              </div>
            </th>
            <th>
              <div class="holder-info">
                <span>Actor</span>
              </div>
            </th>
            <th>
              <div class="holder-info">
                <span>Actor’s role</span>
              </div>
            </th>
            <th>
              <div class="holder-info">
                <span>Date</span>
              </div>
            </th>
          </tr>
        </thead>
      </table>
    </TableHeader>
    <div v-if="true">
      <p class="no-found">No cases found, you can &nbsp;
        <span id="disabled-case-management" ref="disabledCaseManagement">
          <router-link
              id="shared_create_case"
              tag="a"
              :to="{ path: '/create-case' }"
              :class="!checkPermission(['case_management']) ? 'disabled' : ''"
          >
            create new case
          </router-link>
        </span>
      </p>
      <tooltip v-if="!checkPermission(['case_management'])" :target-ref="$refs.disabledCaseManagement" side="bottom">
        <span>To access case creation - you need to have system permission - 'Case management'</span>
      </tooltip>
    </div>
    <div
        v-else
        class="table-body">
      <SharedList />
    </div>
  </div>
</template>

<script>
import checkPermission from "../../utils/permission";
import AlertErrorMsg from "../profile/AlertErrorMsg";
import TableHeader from "../table/TableHeader";
import CustomSearch from "../inputs/CustomSearch";
import SharedList from "./SharedList";
import Tooltip from "@/components/common/Tooltip.vue";

export default {
  name: "DownloadsContent",
  components: {
    Tooltip,
    SharedList,
    CustomSearch,
    TableHeader,
    AlertErrorMsg
  },
  data() {
    return {
      errorMessage: '',
    }
  },
  methods: {
    checkPermission,
  },
}
</script>

<style scoped>
.search-bar {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.holder-search {
  max-width: 310px;
  margin-bottom: 25px;
  margin-right: 24px;
}

.holder-info span {
  text-transform: capitalize;
  font-size: 14px;
}
</style>