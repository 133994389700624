export default function(instance) {
    return {
        getDashboardInfo(data) {
            return instance.get(`/dashboard/info`, data)
        },
        getDashboardCategories(data) {
            return instance.get(`/dashboard/categories`, data)
        },

        getDashboardDamagedFiles() {
            return instance.get(`/dashboard/damaged/files`)
        },
    }
}