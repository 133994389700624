<template>
  <header>
    <div class="header-registered">
      <div class="header-title">
        <div
            v-if="returnPath"
            id="header_return_btn"
            @click="shouldGoBackInRoute? $router.go(-1) : $router.push({name: 'case-details', params: { slug: $route.params.slug}})"
            class="title-return"
            >
          <svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.25266 8.51235C0.252147 8.77475 0.343646 9.02905 0.511276 9.23109L6.13337 15.9693C6.32423 16.1986 6.59849 16.3429 6.89581 16.3702C7.19314 16.3976 7.48918 16.3059 7.7188 16.1153C7.94843 15.9247 8.09283 15.6508 8.12024 15.3538C8.14766 15.0568 8.05583 14.7612 7.86498 14.5318L2.82758 8.51235L7.68507 2.49289C7.77847 2.37801 7.84822 2.24584 7.89031 2.10395C7.9324 1.96207 7.946 1.81328 7.93032 1.66613C7.91465 1.51898 7.87001 1.37637 7.79898 1.24651C7.72794 1.11665 7.63191 1.00208 7.51641 0.909409C7.40079 0.806561 7.26516 0.728668 7.118 0.680611C6.97084 0.632553 6.81534 0.615368 6.66122 0.630128C6.5071 0.644888 6.3577 0.691276 6.22237 0.766388C6.08704 0.8415 5.96869 0.943718 5.87475 1.06664L0.443811 7.80484C0.302679 8.0127 0.235377 8.2618 0.25266 8.51235Z" fill="#696F79"/>
          </svg>
          <h3>
            <span v-text="headTitle" />
<!--            {{ subTitle ? $route.name + ' ' + subTitle : $route.name}}-->
            <slot></slot>
          </h3>
        </div>
        <h3 v-else-if="title">{{ title }}
          <slot></slot>
        </h3>
        <h3 v-else>{{ subTitle ? subTitle + $route.name : $route.name}}
          <slot></slot>
        </h3>
      </div>
      <nav class="header-nav" v-if="loggingIn">
        <ul class="navigation">
          <li
              id="navigation_header_btn"
              class="nav-item">
            <HeaderNotificationsTemplate
                :notifications="notifications"
                @changedStatus="changeStatus"
            />
          </li>
          <li
              id="navigation_header_user_image"
              v-if="photoProfile"
              class="nav-item"
              @click.stop="$refs.userDropdown.toggleActive()"
          >
            <img
                class="user-image"
                :src="photoProfile"
                alt=""
                onerror="this.src='https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png'"
            >
          </li>
          <li
              class="nav-item"
              id="profile_header_btn"
              @click.stop="$refs.userDropdown.toggleActive()"
              v-else
          >
            <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="17.5" cy="18" r="17.5" fill="#F8FCFC"/>
              <path
                  d="M17.5 6.5C13.9236 6.5 11 9.42356 11 13C11 16.5764 13.9236 19.5 17.5 19.5C21.0764 19.5 24 16.5764 24 13C24 9.42356 21.0764 6.5 17.5 6.5Z"
                  fill="#8692A6"/>
              <path
                  d="M29.9466 24.8139C29.7566 24.3355 29.5033 23.889 29.2183 23.4744C27.7616 21.3057 25.5134 19.8705 22.9802 19.5197C22.6635 19.4878 22.3152 19.5516 22.0618 19.7429C20.7319 20.7316 19.1486 21.2419 17.502 21.2419C15.8553 21.2419 14.272 20.7316 12.9421 19.7429C12.6887 19.5516 12.3404 19.4559 12.0238 19.5197C9.4905 19.8705 7.21059 21.3057 5.78565 23.4744C5.50066 23.889 5.24731 24.3674 5.05735 24.8139C4.96238 25.0053 4.99402 25.2285 5.08899 25.4199C5.34234 25.8664 5.65897 26.3129 5.94396 26.6956C6.38726 27.3016 6.86226 27.8437 7.4006 28.354C7.84391 28.8005 8.35055 29.2151 8.85725 29.6297C11.3588 31.5114 14.3671 32.5 17.4703 32.5C20.5736 32.5 23.5818 31.5113 26.0834 29.6297C26.59 29.247 27.0967 28.8005 27.54 28.354C28.0467 27.8437 28.5533 27.3015 28.9967 26.6956C29.3133 26.281 29.5984 25.8664 29.8517 25.4199C30.0099 25.2285 30.0415 25.0052 29.9466 24.8139Z"
                  fill="#8692A6"/>
            </svg>
          </li>
        </ul>
        <HeaderDropdown ref="userDropdown"/>
      </nav>
    </div>
  </header>
</template>

<script>
import {mapActions} from "vuex";
import LocalStorageService from "../../services/LocalStorageService";

const localStorageService = LocalStorageService.getService();

import HeaderNotificationsTemplate from "./HeaderNotificationsTemplate";
import HeaderDropdown from "./HeaderDropdown";

export default {
  name: "HeaderRegistered",
  props: {
    returnPath: {
      type: Boolean
    },
    title: {
      type: String,
      default: () => null
    },
    subTitle: {
      type: String
    },
    withoutRouteName: {
      type: Boolean,
      required: false,
      default: () => false
    },
    shouldGoBackInRoute: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      loggingIn: true,
    }
  },
  computed: {
    headTitle() {
      return this.withoutRouteName
        ? this.subTitle : this.subTitle
          ? this.$route.name + ' ' + this.subTitle : this.$route.name;
    },
    photoProfile() {
      return this.$store.state.profile.user.photoProfile
    },
    notifications() {
      return this.$store.state.notifications.notificationsList
    },
  },
  methods: {
    ...mapActions({
      getNotificationCount: 'notifications/getNotificationCount',
      getNotificationAll: 'notifications/getNotificationAll'
    }),
    changeStatus() {
      this.getNotificationAll({}).then(() => {
        this.getNotificationCount({})
      })
    },
  },
  mounted() {
    if(localStorageService.getAccessTokenLogin() !== null) {
      this.$store.commit('authentication/loginSuccess', localStorageService.getAccessTokenLogin)
    }

    this.getNotificationAll({}).then(() => {
      this.getNotificationCount({})
    })

    this.$store.dispatch('profile/getUser')

  },
  components: {
    HeaderDropdown,
    HeaderNotificationsTemplate
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

ul {
  padding: 0;
  list-style: none;
}

header {
  padding: 40px 55px 0 35px;
}

.title-return {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.title-return svg {
  margin-right: 23px;
}

.header-registered {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--input-placeholder-value-color);
}

header h3 {
  display: inline-block;
  white-space: nowrap;
  color: var(--secondary-black-color);
  font-size: 26px;
  font-weight: 700;
  margin: 0;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.header-nav {
  position: relative;
}

.navigation {
  display: flex;
  align-items: center;
  margin: 0;
}

.nav-item {
  cursor: pointer;
  padding: 0 15px;
  position: relative;
}

.user-image {
  width: 35px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  height: 35px;
}

.notification-circle {
  position: absolute;
  top: -6px;
  right: 16px;
}

.notification-circle svg circle {
  fill: var(--accent-color);
  transition: all .2s;
}

.notification-circle.notification-empty svg circle {
  fill: #DDE3E3;
  transition: all .2s;
}

.nav-item:last-child {
  padding-right: 0;
}

</style>