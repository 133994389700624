<template>
  <div class="holder-table">
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
      <table cellpadding="0" cellspacing="0" border="0">
        <TableHeader v-if="actionPermissionList.length">
          <tr>
            <th class="w-250">
              <div
                  class="table-header-box"
                  @click="sortedBy('userName')"
                  :class="{
                    'sort-active': this.sortBy === 'userName',
                    'sorted-desc': this.order === 'desc',
                  }"
              >
                <UserIcon />
                <span class="header-table-text">User Name</span>
                <arrowDown class="arrow" />
              </div>
            </th>
            <th class="w-200">
              <div
                  class="table-header-box table-header-box-doesnt-hover"
              >
                <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.48024 0H0.520239C0.245378 0 0.0224609 0.222917 0.0224609 0.497778V15.5022C0.0224609 15.7771 0.245378 16 0.520239 16H9.48024C9.7551 16 9.97802 15.7771 9.97802 15.5022V0.497778C9.97802 0.222917 9.7551 0 9.48024 0ZM3.16552 0.462222H6.83496C7.18774 0.462222 7.47496 0.724583 7.47496 1.04889C7.47496 1.37319 7.18774 1.63556 6.83496 1.63556H3.16552C2.81274 1.63556 2.52552 1.37319 2.52552 1.04889C2.52552 0.724583 2.81274 0.462222 3.16552 0.462222ZM1.58691 2.80889C1.58691 2.6125 1.74607 2.45333 1.94246 2.45333H8.02246C8.21885 2.45333 8.37802 2.6125 8.37802 2.80889V9.49333C8.37802 9.68972 8.21885 9.84889 8.02246 9.84889H1.94246C1.74607 9.84889 1.58691 9.68972 1.58691 9.49333V2.80889ZM1.90691 11.52C1.78913 11.52 1.69357 11.4244 1.69357 11.3067C1.69357 11.1889 1.78913 11.0933 1.90691 11.0933H8.05802C8.1758 11.0933 8.27135 11.1889 8.27135 11.3067C8.27135 11.4244 8.1758 11.52 8.05802 11.52H1.90691ZM7.73802 12.7644C7.73802 12.8822 7.64246 12.9778 7.52468 12.9778H2.44024C2.32246 12.9778 2.22691 12.8822 2.22691 12.7644C2.22691 12.6467 2.32246 12.5511 2.44024 12.5511H7.52468C7.64246 12.5511 7.73802 12.6467 7.73802 12.7644ZM8.59135 14.4H1.37357C1.25579 14.4 1.16024 14.3044 1.16024 14.1867C1.16024 14.0689 1.25579 13.9733 1.37357 13.9733H8.59135C8.70913 13.9733 8.80468 14.0689 8.80468 14.1867C8.80468 14.3044 8.70913 14.4 8.59135 14.4Z" fill="#D7DCE6"/>
                </svg>
                <span class="header-table-text">Permissions</span>
              </div>
            </th>
            <th class="w-300">
              <div
                  class="table-header-box table-header-box-doesnt-hover"
              >
                <UserIcon />
                <span class="header-table-text">Actor</span>
              </div>
            </th>
            <th>
              <div
                  class="table-header-box table-header-box-doesnt-hover"
              >
                <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.48024 0H0.520239C0.245378 0 0.0224609 0.222917 0.0224609 0.497778V15.5022C0.0224609 15.7771 0.245378 16 0.520239 16H9.48024C9.7551 16 9.97802 15.7771 9.97802 15.5022V0.497778C9.97802 0.222917 9.7551 0 9.48024 0ZM3.16552 0.462222H6.83496C7.18774 0.462222 7.47496 0.724583 7.47496 1.04889C7.47496 1.37319 7.18774 1.63556 6.83496 1.63556H3.16552C2.81274 1.63556 2.52552 1.37319 2.52552 1.04889C2.52552 0.724583 2.81274 0.462222 3.16552 0.462222ZM1.58691 2.80889C1.58691 2.6125 1.74607 2.45333 1.94246 2.45333H8.02246C8.21885 2.45333 8.37802 2.6125 8.37802 2.80889V9.49333C8.37802 9.68972 8.21885 9.84889 8.02246 9.84889H1.94246C1.74607 9.84889 1.58691 9.68972 1.58691 9.49333V2.80889ZM1.90691 11.52C1.78913 11.52 1.69357 11.4244 1.69357 11.3067C1.69357 11.1889 1.78913 11.0933 1.90691 11.0933H8.05802C8.1758 11.0933 8.27135 11.1889 8.27135 11.3067C8.27135 11.4244 8.1758 11.52 8.05802 11.52H1.90691ZM7.73802 12.7644C7.73802 12.8822 7.64246 12.9778 7.52468 12.9778H2.44024C2.32246 12.9778 2.22691 12.8822 2.22691 12.7644C2.22691 12.6467 2.32246 12.5511 2.44024 12.5511H7.52468C7.64246 12.5511 7.73802 12.6467 7.73802 12.7644ZM8.59135 14.4H1.37357C1.25579 14.4 1.16024 14.3044 1.16024 14.1867C1.16024 14.0689 1.25579 13.9733 1.37357 13.9733H8.59135C8.70913 13.9733 8.80468 14.0689 8.80468 14.1867C8.80468 14.3044 8.70913 14.4 8.59135 14.4Z" fill="#D7DCE6"/>
                </svg>
                <span class="header-table-text">Actor’s Role</span>
              </div>
            </th>
            <th>
              <div
                  @click="sortedBy('actionDate')"
                  class="table-header-box"
                  :class="{
                    'sort-active': this.sortBy === 'actionDate',
                    'sorted-desc': this.order === 'desc',
                  }"
              >
                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.0625 0.5C3.17853 0.5 3.28981 0.549386 3.37186 0.637294C3.45391 0.725201 3.5 0.84443 3.5 0.96875V1.4375H10.5V0.96875C10.5 0.84443 10.5461 0.725201 10.6281 0.637294C10.7102 0.549386 10.8215 0.5 10.9375 0.5C11.0535 0.5 11.1648 0.549386 11.2469 0.637294C11.3289 0.725201 11.375 0.84443 11.375 0.96875V1.4375H12.25C12.7141 1.4375 13.1592 1.63504 13.4874 1.98667C13.8156 2.33831 14 2.81522 14 3.3125V13.625C14 14.1223 13.8156 14.5992 13.4874 14.9508C13.1592 15.3025 12.7141 15.5 12.25 15.5H1.75C1.28587 15.5 0.840752 15.3025 0.512563 14.9508C0.184374 14.5992 0 14.1223 0 13.625V5.1875H14V4.25H0V3.3125C0 2.81522 0.184374 2.33831 0.512563 1.98667C0.840752 1.63504 1.28587 1.4375 1.75 1.4375H2.625V0.96875C2.625 0.84443 2.67109 0.725201 2.75314 0.637294C2.83519 0.549386 2.94647 0.5 3.0625 0.5V0.5Z" fill="#D7DCE6"/>
                </svg>
                <span class="header-table-text">Date</span>
                <arrowDown class="arrow" />
              </div>
            </th>
          </tr>
        </TableHeader>
        <loader v-if="loadFlag" :load-flag="loadFlag" />
        <template v-else>
          <CaseActionsPermissionItem
              v-if="actionPermissionList.length"
              :actionPermissionList="actionPermissionList"
          />
          <p class="no-found" v-else>not found</p>
        </template>
      </table>
    <pagination v-if="actionPermissionList.length && count !== 1"
                v-model="page"
                :total-pages="count"
                :link-gen="linkGen"
    ></pagination>
  </div>
</template>

<script>
import AlertErrorMsg from "../../profile/AlertErrorMsg";
import TableHeader from "../../table/TableHeader";
import CaseActionsPermissionItem from "./CaseActionsPermissionItem";
import ArrowDown from "../../icons/ArrowDown";
import UserIcon from "../../icons/UserIcon";
import Loader from "../../loader/Loader";
import linkGenerator from "../../../constants/linkGen";
import Pagination from "@/components/common/Pagination.vue";

export default {
  name: "CaseActionsPermission",
  components: {
    Pagination,
    Loader,
    UserIcon,
    ArrowDown,
    CaseActionsPermissionItem,
    TableHeader,
    AlertErrorMsg
  },
  props: {
    searchText: {
      type: String
    },
    dataRange: {},
  },
  data() {
    return {
      loadFlag: false,
      errorMessage: '',
      caseId: this.$route.params.slug,
      keySearch: '',
      dateFrom: '',
      dateTo: '',
      sortBy: 'actionDate',
      order: 'desc',
      page: 1,
      size: 10,
      count: 1,
      pageSize: 2,
      actionPermissionList: [],
    }
  },
  watch: {
    searchText() {
      this.getActionPermission()
    },
    dataRange(value) {
      this.dateFrom = isNaN(Date.parse(value[0])) == false ? Date.parse(value[0]) : ''
      this.dateTo = isNaN(Date.parse(value[1])) == false ? Date.parse(value[1]) : ''
      this.getActionPermission()
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if(newVal) {
          this.page = newVal
          this.getActionPermission()
        } else {
          this.page = 1
        }
      }
    }
  },
  methods: {
    sortedBy(filterName) {
      this.order = filterName === this.sortBy && this.order === 'desc' ? 'asc' : 'desc'
      this.sortBy = filterName
      this.getActionPermission()
    },
    fixedEncodeURIComponent (str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    },
    linkGen(pageNum) {
      return linkGenerator(pageNum, `/cases/${this.caseId}/case-actions/permission`)
    },
    getActionPermission() {
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.cases.getCaseActionPermission({
          caseId: this.caseId,
          sortBy: this.sortBy,
          keySearch: this.fixedEncodeURIComponent(this.searchText),
          order: this.order,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          page: this.page,
          size: this.size,
        }).then((res) => {
          this.actionPermissionList = res.data.body.content
          this.count = res.data.body.totalPages
          this.pageSize = res.data.body.size
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
        setTimeout(() => {
          this.loadFlag = false
        }, 1000)
      })
    },
  },
  mounted() {
    this.page = this.$route.query.page || 1
    this.getActionPermission()
  }
}
</script>

<style scoped>
</style>
