<template>
  <div class="overlay"
       v-if="activeDialog"
       :class="activeDialog ? 'active': ''"
  >
    <form class="filters-form common-dialog-wrapper"
          :class="customClass">
      <h1 class="filter-title">Filter Settings</h1>
      <div class="close-dialog" @click="activeDialog = false">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M12.0007 0.333008C5.54898 0.333008 0.333984 5.54801 0.333984 11.9997C0.333984 18.4513 5.54898 23.6663 12.0007 23.6663C18.4523 23.6663 23.6673 18.4513 23.6673 11.9997C23.6673 5.54801 18.4523 0.333008 12.0007 0.333008ZM17.834 16.188L16.189 17.833L12.0007 13.6447L7.81232 17.833L6.16732 16.188L10.3557 11.9997L6.16732 7.81134L7.81232 6.16634L12.0007 10.3547L16.189 6.16634L17.834 7.81134L13.6457 11.9997L17.834 16.188Z"
              fill="#D7DCE6"/>
        </svg>
      </div>
      <slot></slot>
      <div class="dialog-btns-wrap">
        <button class="btn dialog-btn" @click="activeDialog = false">
          <span class="dialog-btn-text">Apply</span>
        </button>
        <button type="reset" class="dialog-btn-reset" title="reset all filters">
          <span class="dialog-reset-text">Reset all filters</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "FiltersPopupWrapper",
  props: {
    showFooter: {
      type: Boolean
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      activeDialog: false,
    };
  },
  methods: {
    openPopups() {
      this.activeDialog = true;
    },
    closePopups() {
      this.activeDialog = false;
    },
  }
};
</script>

<style scoped>
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay.active {
  animation: show .3s linear;
}

.overlay {
  z-index: 1;
}

.filters-form {
  width: 100%;
}

.filter-title {
  margin: 0;
  font-size: 30px;
  font-weight: 700;
  color: var(--black-color);
  text-align: center;
}

.common-dialog-wrapper {
  width: 100%;
  max-width: 440px;
  border: 1px solid #D7DCE6;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 50px 27px 32px 27px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  position: relative;
}

.close-dialog {
  width: 70px;
  height: 70px;
  padding: 23px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.close-dialog svg path {
  transition: .5s all;
}

.close-dialog:hover svg path {
  fill: var(--accent-color);
}

.close-dialog svg {
  width: 100%;
  height: 100%;
}

.common-dialog-wrapper .dialog-btn {
  min-width: 140px;
  width: 100%;
  max-width: 140px;
  padding: 13px 12px;
  background-color: var(--white-color);
  border-color: var(--accent-color);
}

.common-dialog-wrapper .dialog-btn:hover {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
}

.common-dialog-wrapper .dialog-btn:hover .dialog-btn-text {
  color: var(--white-color);
}

.dialog-btn-text {
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: var(--accent-color);
  transition: .5s all;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}

.dialog-btns-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dialog-btn-reset {
  margin: 23px 0 0 0;
  padding: 0;
  border: none;
  outline: none;
  background-color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.dialog-reset-text {
  display: block;
  max-width: 120px;
  font-size: 14px;
  font-weight: 600;
  color: var(--light-gray);
  text-decoration: underline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/*custom classes */
</style>