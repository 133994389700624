<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99998 0.426758L4.48665 2.00009H2.33331V3.33342L0.97998 4.19342C0.593313 4.42676 0.333313 4.84676 0.333313 5.33342V12.0001C0.333313 12.3537 0.473789 12.6929 0.723837 12.9429C0.973886 13.1929 1.31302 13.3334 1.66665 13.3334H12.3333C13.0733 13.3334 13.6666 12.7401 13.6666 12.0001V5.33342C13.6666 4.84676 13.4066 4.42676 13.02 4.19342L11.6666 3.33342V2.00009H9.51331L6.99998 0.426758ZM3.66665 3.33342H10.3333V6.58676L6.99998 8.66676L3.66665 6.58676V3.33342ZM4.33331 4.00009V5.00009H9.66665V4.00009H4.33331ZM2.33331 4.92009V5.75342L1.66665 5.33342L2.33331 4.92009ZM11.6666 4.92009L12.3333 5.33342L11.6666 5.75342V4.92009ZM4.33331 5.66676V6.66676H9.66665V5.66676H4.33331Z" fill="#D7DCE6"/>
  </svg>
</template>

<script>
export default {
  name: "EmailIcon"
}
</script>

<style scoped>

</style>