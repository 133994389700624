<template>
  <div class="storage-info">
    <radial-progress-bar :diameter="136"
                         :completed-steps="usedStoragePercent"
                         :startColor="stepColor"
                         :stopColor="stepColor"
                         :strokeWidth="22"
                         :total-steps="totalSteps"
                         strokeColor="#C4C4C4"
                         innerStrokeColor="#EFF0F1"
                         :innerStrokeWidth="13"
    >
      <span class="progress-percents"
            :class="classColor"
      >{{ usedStoragePercent }}%</span>
    </radial-progress-bar>
    <div class="storage-statistic">
      <h3 class="statistic-title">Internal Storage</h3>
      <div class="statistic-info">
        <span class="statistic-current" :class="classColor">{{ usedStorage.toFixed(2) }}GB</span>
        <span class="statistic-total">/{{internalStorageGeneralSpace}}GB</span>
        <div class="statistic-warning" :class="classColor" v-if="usedStoragePercent > 50">
          <div class="warning-icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
                <path d="M13.1865 16.7114H10.8438V19.6536H13.1865V16.7114Z" fill="#fff"/>
                <path d="M13.1865 7.6748H10.8438V14.5723H13.1865V7.6748Z" fill="#fff"/>
                <path
                    d="M23.0745 18.5366C23.7299 19.7294 23.2943 21.2278 22.1014 21.8832C21.7466 22.0781 21.3494 22.1827 20.9447 22.1876H3.05447C1.69348 22.171 0.60365 21.0542 0.620306 19.6933C0.625256 19.2886 0.72979 18.8913 0.924707 18.5366L9.90026 3.01955C10.5805 1.86013 12.072 1.47169 13.2314 2.15195C13.5899 2.3623 13.8886 2.66099 14.099 3.01955L23.0745 18.5366ZM13.1558 19.6623V16.711H10.8435V19.6623H13.1558ZM13.1558 14.5813V7.67467H10.8435V14.5813H13.1558Z"
                    fill="#FF792E"/>
                <path
                    d="M20.9449 22.7965H3.05469C1.37434 22.8032 0.00672156 21.4464 2.4392e-05 19.7661C-0.00213035 19.2279 0.13851 18.6988 0.40762 18.2327L9.35277 2.71567C10.2148 1.25377 12.0988 0.767494 13.5607 1.62957C14.0088 1.89384 14.3825 2.26754 14.6468 2.71567L23.5919 18.2327C24.432 19.6879 23.9335 21.5487 22.4783 22.3889C22.0122 22.658 21.483 22.7986 20.9449 22.7965ZM11.9998 2.41145C11.3454 2.40457 10.7393 2.75434 10.4177 3.32418L1.47259 18.8412C1.14006 19.4043 1.14006 20.1036 1.47259 20.6667C1.78922 21.2415 2.39866 21.593 3.05469 21.5795H20.9449C21.601 21.593 22.2103 21.2415 22.527 20.6667C22.8595 20.1036 22.8595 19.4043 22.527 18.8412L13.5819 3.32418C13.2603 2.75428 12.6541 2.40457 11.9998 2.41145Z"
                    fill="#D7DCE6"/>
                <path
                    d="M13.1562 20.2713H10.8439C10.5078 20.2713 10.2354 19.9989 10.2354 19.6628V16.7115C10.2354 16.3755 10.5078 16.103 10.8439 16.103H13.1562C13.4923 16.103 13.7647 16.3755 13.7647 16.7115V19.6628C13.7647 19.9989 13.4923 20.2713 13.1562 20.2713ZM11.4524 19.0543H12.5477V17.32H11.4524V19.0543Z"
                    fill="#fff"/>
                <path
                    d="M13.1562 15.19H10.8439C10.5078 15.19 10.2354 14.9176 10.2354 14.5815V7.67492C10.2354 7.33884 10.5078 7.06641 10.8439 7.06641H13.1562C13.4923 7.06641 13.7647 7.33884 13.7647 7.67492V14.5815C13.7647 14.9176 13.4923 15.19 13.1562 15.19ZM11.4524 13.973H12.5477V8.28343H11.4524V13.973Z"
                    fill="#fff"/>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
          <span class="warning-message">You need extra storage</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'

export default {
  name: 'CaseInternalStorageInfo',
  props: {
    internalStorageGeneralSpace: {
      type: Number
    },
    usedStoragePercent: {
      type: Number
    },
    usedStorage: {
      type: Number,
    }
  },
  data() {
    return {
      color: '',
      accentColor: '#082655',
      warningColor: '#FF792E',
      dangerColor: '#F1105F',
      totalSteps: 100
    }
  },
  computed: {
    stepColor() {
      let currentColor;
      if (this.usedStoragePercent > 0) {
        currentColor = this.accentColor
      }
      if (this.usedStoragePercent >= 50) {
        currentColor = this.warningColor
      }
      if (this.usedStoragePercent >= 80) {
        currentColor = this.dangerColor
      }

      return currentColor
    },
    classColor() {

      let currentClass;
      if (this.usedStoragePercent > 0) {
        currentClass = 'accent'
      }
      if (this.usedStoragePercent >= 50) {
        currentClass = 'warning'
      }
      if (this.usedStoragePercent >= 80) {
        currentClass = 'danger'
      }

      return currentClass
    },
  },
  components: {
    RadialProgressBar
  }
}
</script>

<style scoped>

.storage-info {
  margin: 0 0 0 10px;
  padding: 14px 20px;
  box-shadow: 0 0 15px rgba(5, 51, 48, 0.06);
  border-radius: 10px;
  background-color: var(--white-color);
  display: flex;
  min-width: 387px;
  width: 100%;
  min-height: 164px;
  height: 100%;
  align-items: center;
}

.storage-statistic {
  padding-left: 5px;
  width: 100%;
  overflow: hidden;
}

.statistic-title {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--secondary-black-color);
}

.statistic-info {
  margin: 15px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.statistic-current {
  font-size: 24px;
  display: block;
  font-weight: 700;
  color: var(--accent-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(50% - 8px);
}

.statistic-total {
  font-size: 24px;
  display: block;
  font-weight: 700;
  color: var(--secondary-black-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(50% - -3px);
}

.statistic-warning {
  margin: 25px 0 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.warning-icon {
  width: 24px;
  height: 21px;
  overflow: hidden;
}

.warning-icon svg {
  max-width: 100%;
  max-height: 100%;
}

.statistic-warning.warning .warning-iconsvg path:nth-child(2) {
  color: var(--case-btn-border-color);
}

.statistic-warning.warning .warning-icon svg path:nth-child(3) {
  fill: var(--storage-warning);
}

.statistic-warning.danger .warning-icon svg path:nth-child(2) {
  color: var(--white-color);
}

.statistic-warning.danger .warning-icon svg path:nth-child(3) {
  fill: var(--error-color);
}

.warning-message {
  display: block;
  font-size: 14px;
  text-decoration: underline;
  color: var(--secondary-text-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(98% - 28px);
}

.progress-percents {
  margin: 0 auto;
  display: block;
  margin: 0 0 0 12px;
  font-size: 25px;
  line-height: 25px;
  font-weight: 700;
  color: var(--accent-color);
}

.accent {
  color: var(--accent-color);
}

.warning {
  color: var(--storage-warning);
}

.danger {
  color: var(--error-color);
}

</style>