<template>
  <tbody>
  <tr v-for="(item, i) in 7"
      :key="i">
    <td>
      <div class="holder-info">
        <div class="info">
          <span class="">name</span>
        </div>
      </div>
    </td>
    <td>
      <div class="holder-info">
        <div class="info">
          <span class="">name</span>
        </div>
      </div>
    </td>
    <td>
      <div class="holder-info">
        <div class="info">
          <span class="">name</span>
        </div>
      </div>
    </td>
    <td>
      <div class="holder-info">
        <div class="info">
          <span class="">name</span>
        </div>
      </div>
    </td>
    <td>
      <div class="holder-info">
        <div class="info">
          <span class="">name</span>
        </div>
      </div>
    </td>
    <td>
      <div class="holder-info">
        <div class="info">
          <span class="">name</span>
        </div>
      </div>
    </td>
    <td>
      <div class="holder-info">
        <div class="info">
          <span class="">name</span>
        </div>
      </div>
    </td>
  </tr>
  </tbody>
</template>

<script>
export default {
  name: "NotificationsBillingItem"
}
</script>

<style scoped>

</style>