<template>
  <div
      class="custom-select"
      :tabindex="tabindex"
    id="register_card-months_input"
  >
    <div
        class="selected"
        :class="{ open: open, checked: checked}"
        @click="toggleSelect"
        id="register_card-months_selected_input"
    >
      {{ selected }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <input type="text" v-model="keyword" class="form-control options-search-input" :id="searchInputId" placeholder="Search...">
      <div
          v-for="(option, i) of optionsFilteredList"
          :key="i"
          :id="`register_${customId}_${i+1}_input`"
          @click="
          selected = option.name;
          checked = true;
          open = false;
          $emit('input', option.iso2 ? option.iso2 : option.name, option.name,);
        "
      >
        {{ option.name }}
      </div>
      <span v-if="!optionsFilteredList.length" class="options-search-input-empty">not found</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomSelectGeolocation",
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: 'MM',
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    customId: String,
    searchInputId: String,
  },
  data() {
    return {
      keyword: '',
      checked: false,
      selected: this.default
          ? this.default
          : this.options.length > 0
              ? this.options[0]
              : null,
      open: false,
    };
  },
  computed: {
    optionsFilteredList() {
      return this.options.filter((option) => {
        return this.keyword.toLowerCase().split(' ').every(v => option.name.toLowerCase().includes(v));
      });
    }
  },
  methods: {
    clearField() {
      this.selected = this.default;
      this.checked = false;
      this.open = false;
      this.$emit("input", this.selected);
    },
    toggleSelect() {
      this.open = !this.open;
      if (this.open) this.$emit("openSelect");
    },
    closeSelect() {
      this.open = false;
    }
  }
}
</script>

<style scoped>

.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 47px;
  line-height: 47px;
}

.custom-select .selected {
  background-color: #0a0a0a;
  border-radius: 6px;
  border: 1px solid #666666;
  color: #fff;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  transition: all .3s ease-in-out;
}

.custom-select .selected.open {
  border: 1px solid #ad8225;
  border-radius: 6px 6px 0 0;
}

.custom-select .selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
}

.custom-select .items {
  color: #fff;
  border-radius: 0 0 6px 6px;
  overflow: hidden;
  border-right: 1px solid #ad8225;
  border-left: 1px solid #ad8225;
  border-bottom: 1px solid #ad8225;
  position: absolute;
  background-color: #0a0a0a;
  left: 0;
  right: 0;
  z-index: 1;
}

.custom-select .items div {
  color: #fff;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: #ad8225;
}

.selectHide {
  display: none;
}

.options-search-input {
  width: calc(100% - 30px);
  padding: 10px 15px;
  margin: 15px;
}

.options-search-input-empty {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 15px;
}

</style>
