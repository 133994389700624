<template>
  <div class="wrapper">
    <Sidebar />
    <div class="content-wrapper">
      <HeaderRegistered />
      <main class="main">
        <section class="dashboard-section">
          <CustodianActivity />
        </section>
      </main>
    </div>
  </div>
</template>

<script>

import Sidebar from "../components/Sidebar";
import HeaderRegistered from "../components/layout/HeaderRegistered";
import CustodianActivity from "@/components/support/CustodianSupport.vue";

export default {
  name: "ClientSupport",
  components: {
    CustodianActivity,
    HeaderRegistered,
    Sidebar,
  },
  mounted() {
    document.title = 'Support'
  }
}
</script>

<style scoped>

</style>