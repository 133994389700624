<template>
  <div class="range-slider">
    <div class="participants-count-info">
      <span class="participants-count-text">{{ minParticipants }}</span>
      <span class="participants-count-text">-</span>
      <span class="participants-count-text">{{ maxParticipants }}</span>
    </div>
    <MultiRangeSlider
        :min="0"
        :max="100"
        :ruler="false"
        :label="false"
        :minValue="minParticipants"
        :maxValue="maxParticipants"
        @input="UpdateValues"
    />
  </div>
</template>

<script>
import MultiRangeSlider from "multi-range-slider-vue";

export default {
  name: "CustomRangeSlider",
  data() {
    return {
      minParticipants: 0,
      maxParticipants: 100,
    }
  },
  methods: {
    UpdateValues(e) {
      this.minParticipants = e.minValue;
      this.maxParticipants = e.maxValue;
    }
  },
  components: {
    MultiRangeSlider
  }
}
</script>

<style>
.participants-count-info {
  box-sizing: border-box;
  padding: 15px 30px;
  background-color: var(--disabled-bgcolor);
  border-radius: 25px;
  margin: 0 0 15px 0;
}

.participants-count-text {
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-black-color);
}

.range-slider .multi-range-slider {
  box-shadow: none;
  border: none;
}

.range-slider .multi-range-slider .bar-inner {
  border: none;
  background-color: var(--accent-color);
  box-shadow: none;
}

.range-slider .multi-range-slider .bar-left,
.range-slider .multi-range-slider .bar-right {
  padding: 2px 0;
  box-shadow: none;
  border-radius: 0;
  background-color: var(--range-slider-bgcolor);
}

.range-slider .multi-range-slider .bar * {
  transition: 0s;
}

.range-slider .multi-range-slider .thumb::before {
  width: 25px;
  height: 25px;
  background-color: var(--accent-color);
  border: none;
  box-shadow: none;
  margin: -10px;
}

.range-slider .multi-range-slider .thumb * {
  box-shadow: none;
  background-color: var(--white-color);
  color: var(--secondary-black-color);
  font-size: 12px;
  font-weight: 500;
  bottom: -40px;
  left: -12px;
}

</style>