<template>
  <div class="support">
    <Header v-if="!login" />
    <HeaderDefaultRegistered v-else />
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
    <section class="support-content">
      <div class="form-wrapper">
        <form @submit.prevent="submit">
          <h2 class="support-title">Support</h2>
          <div class="form-group"
               :class="{ 'form-group--error': $v.formData.nickName.$error }"
          >
            <label for="support_account_nick-name_input" class="label">Nick name*</label>
            <input
                id="support_account_nick-name_input"
                type="text"
                placeholder="Enter your first name"
                class="input"
                :class="false ? 'is-invalid' : ''"
                autocomplete="off"
                :maxlength="userFirstNameMax"
                v-model="$v.formData.nickName.$model"
            >
            <div class="error" v-if="!$v.formData.nickName.required">Field is required</div>
            <div class="error" v-if="!$v.formData.nickName.minLength">Name must have at least
              {{ $v.formData.nickName.$params.minLength.min }} letters.
            </div>
          </div>
          <div class="form-group"
               :class="{ 'form-group--error': $v.formData.email.$error }"
          >
            <label for="support_account_mail_input" class="label">Email address*</label>
            <input
                id="support_account_mail_input"
                type="text"
                placeholder="Enter email address"
                class="input"
                :class="false ? 'is-invalid' : ''"
                autocomplete="off"
                v-model="$v.formData.email.$model"
            >
            <div class="error" v-if="!$v.formData.email.required">Field is required</div>
            <div class="error" v-if="!$v.formData.email.email">This must be an email</div>
          </div>
          <div class="form-group">
            <span class="select-label">Topic</span>
            <CustomSelect
                custom-class="support-form"
                :options="topicText"
                v-model="topic"
                default="Other"
            />

          </div>
          <div class="form-group"
               :class="{ 'form-group--error': $v.formData.subject.$error }"
          >
            <label for="support_account_subject_input" class="label">Subject*</label>
            <input
                id="support_account_subject_input"
                type="text"
                name="subject"
                placeholder="Enter the title for your question"
                class="input"
                autocomplete="off"
                v-model="formData.subject"
            >
            <div class="error" v-if="!$v.formData.subject.required">Field is required</div>
          </div>
          <div class="form-group description"
               :class="{ 'form-group--error': $v.formData.description.$error }"
          >
            <label for="support_description">Description*</label>
            <textarea
                name="description"
                id="support_description"
                class="support-textarea"
                placeholder="Describe your question"
                v-model="formData.description"
            >
            </textarea>
            <div class="support-download-file">
              <img src="../assets/img/download-file.svg" alt="download-file">
              <input
                  type="file"
                  name="description-file"
                  class="support-description-file"
                  @change="downloadFile"
              >
            </div>
            <div class="error" v-if="!$v.formData.description.required">Field is required</div>
          </div>
          <div class="group">
            <button
                id="support_account_button"
                class="btn support-btn">Submit
            </button>
          </div>
        </form>
      </div>
    </section>
    <Footer/>
    <CommonDialog
        ref="commonDialogFormSuccess"
        :title="'Order'"
    >
      <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z" fill="#082655"/>
      </svg>
      <h2 class="dialog-message-title">Success!</h2>
      <p class="dialog-message-description">Your question has been sent.<br>
        Our experts are already looking for a solution to your problem.</p>
    </CommonDialog>
  </div>
</template>

<script>
import login from "../mixins/login";
import Header from "../components/layout/Header";
import HeaderDefaultRegistered from "../components/layout/HeaderDefaultRegistered";
import Footer from "../components/Footer";
import AlertErrorMsg from "../components/profile/AlertErrorMsg";
import {minLength, required, email} from "vuelidate/lib/validators";
import CustomSelect from "../components/inputs/CustomSelect";
import CommonDialog from "../components/popups/CommonDialog"

export default {
  name: "Support",
  data() {
    return {
      topicText:[
          {name:'Registration'},
          {name:'Company registration'},
          {name:'Dashboard'},
          {name:'Contacts'},
          {name:'Invitations'},
          {name:'Cases'},
          {name:'Actions in case'},
          {name:'System permissions'},
          {name:'Permissions in case'},
          {name:'User roles'},
          {name:'Billing'},
          {name:'Notifications'},
          {name:'User profile'},
          {name:'2 Factor authentication'},
          {name:'Service price'},
          {name:'Other'},
      ],
      userFirstNameMax: 50,
      userLastNameMax: 50,
      passwordType: "password",
      passwordConfirmType: "password",
      repeatPassword: "",
      hasMinimumLength: false,
      hasNumber: false,
      hasLowercase: false,
      hasUppercase: false,
      hasSpecial: false,
      submitted: false,
      isValid: false,
      errorMessage: '',
      topic: '',
      focus: false,
      formData: {
        nickName: null,
        email: null,
        topic: null,
        subject: null,
        description: null,
        file: null,
      },
    }
  },
  mixins: [login],
  validations: {
    formData: {
      nickName: {
        required,
        minLength: minLength(2)
      },
      email: {
        required,
        email,
      },
      subject: {
        required,
      },
      description: {
        required,
      },
    },
  },
  methods: {
    downloadFile(e){
      this.formData.file = e.target.files[0]
    },
    openDialogFormSuccess() {
      this.$refs.commonDialogFormSuccess.openPopups();
    },
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.$load(async () => {
          await this.$api.supportPage.support({
            nickName: this.formData.nickName,
            email: this.formData.email,
            subject: this.formData.subject,
            description: this.formData.description,
            topic: this.topic,
          }).then(() => {
            this.openDialogFormSuccess()
            this.formData.email = ""
            this.formData.description = ""
            this.formData.topic = ""
            this.formData.nickName = ""
            this.formData.subject = ""
            this.$nextTick(() => { this.$v.$reset(); });
          })
        }, (error) => {
          if(error) {
            this.errorMessage = Object.values(error.data.errors).join('</br>')
            this.$refs.alert.showAlert()
          }
        })
      }
    },
  },
  mounted() {
    document.title = 'Support'
  },
  components: {
    Header,
    HeaderDefaultRegistered,
    Footer,
    CustomSelect,
    AlertErrorMsg,
    CommonDialog
  },
}
</script>

<style scoped>
.support-content {
  padding: 40px 0 161px 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.form-wrapper {
  width: 100%;
  max-width: 400px;
}

.support-title {
  margin: 0 0 50px 0;
  font-size: 36px;
  font-weight: 700;
  color: var(--black-color);
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--secondary-black-color);
  margin-bottom: 10px;
  cursor: pointer;
}

.form-group input {
  width: 100%;
  border: 1px solid var(--input-placeholder-value-color);
  border-radius: 25px;
  padding: 15px 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--secondary-black-color);
}

.support-textarea {
  width: 100%;
  resize: none;
  min-height: 245px;
  outline: none;
  border: 1px solid var(--input-placeholder-value-color);
  border-radius: 25px;
  padding: 23px 30px;
  font-size: 14px;
  font-weight: 500;
}

.support-textarea::placeholder {
  color: var(--input-placeholder-value-color);
}

.support-btn {
  width: 100%;
  padding: 14px 0;
}

.form-group.description {
  margin: 0 0 40px 0;
  position: relative;
}

.support-download-file {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 18px;
  height: 20px;
  display: none;
}

.support-download-file img {
  max-width: 100%;
  max-height: 100%;
}

.form-group.description .support-description-file {
  position: absolute;
  z-index: 1;
  border: none;
  right: 0;
  bottom: 0;
  opacity: 0;
  font-size: 0;
  line-height: 0;
  appearance: none;
  width: 18px;
  height: 20px;
  cursor: pointer;
}

.custom-select .selected {
  justify-content: flex-end;
}

.select-label {
  display: block;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--secondary-black-color);
  margin-bottom: 10px;
  cursor: pointer;
}

.error {
  display: none;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: var(--alert-color);
  margin-top: 10px;
}

.dialog-message-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #292B2F;
  text-align: center;
  margin: 0;
  display: block;
  width: 100%;
  margin-top: 25px;
}
.dialog-message-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #696F79;
  display: block;
  margin-top: 20px;
}

</style>
