<template>
  <div v-if="activeDialog"
       :class="activeDialog ? 'active': ''"
       class="overlay"
  >
    <div id="dialog_close"
         :class="customClass"
         class="common-dialog-wrapper"
    >
      <div class="close-dialog" @click="closePopup">
        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M12.0007 0.333008C5.54898 0.333008 0.333984 5.54801 0.333984 11.9997C0.333984 18.4513 5.54898 23.6663 12.0007 23.6663C18.4523 23.6663 23.6673 18.4513 23.6673 11.9997C23.6673 5.54801 18.4523 0.333008 12.0007 0.333008ZM17.834 16.188L16.189 17.833L12.0007 13.6447L7.81232 17.833L6.16732 16.188L10.3557 11.9997L6.16732 7.81134L7.81232 6.16634L12.0007 10.3547L16.189 6.16634L17.834 7.81134L13.6457 11.9997L17.834 16.188Z"
              fill="#D7DCE6"
          />
        </svg>
      </div>
      <div class="content">
        <h1 class="popup-title">
          {{ title }}
        </h1>
        <p class="popup-description">
          {{ description }}
        </p>
        <slot></slot>
      </div>
      <div class="buttons-confirmation">
        <button
            id="dialog_continue"
            class="btn dialog-btn"
            @click="closePopup"
        >
          <span class="dialog-btn-text">
            {{ buttonCancelText }}
          </span>
        </button>
        <button
            id="dialog_continue"
            class="btn confirm-btn"
            @click="confirm"
        >
          <span class="dialog-btn-text">
            {{ buttonConfirmText }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmPopup",
  props: {
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    buttonCancelText: {
      type: String,
      default: 'Cancel'
    },
    buttonConfirmText: {
      type: String,
      default: 'Confirm'
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      activeDialog: false,
    };
  },
  methods: {
    openPopups() {
      this.activeDialog = true;
    },
    closePopup() {
      this.activeDialog = false;
    },
    confirm() {
      this.$emit('confirm');
    }
  },
};
</script>

<style scoped>
.buttons-confirmation {
  display: flex;
  gap: 32px;
}

.content {
  margin: 15px 0 20px;
}

.popup-title {
  margin: 0 0 15px;
}

.buttons-confirmation button:not(:last-child) {
  margin-right: 8px;
}

.common-dialog-wrapper .btn {
  max-width: 217px;
  width: 217px;
  height: 48px;
  border-radius: 25px;
}

.confirm-btn .dialog-btn-text {
  color: var(--white-color);
}

.confirm-btn:hover .dialog-btn-text {
  color: var(--accent-color);
}
</style>