<template>
  <div>
    <div class="pagination">
      <button
          @click="prevPage"
          :disabled="currentPage === 1"
          :class="{ active: currentPage !== 1}"
      >
        {{ prevLabel }}
      </button>
      <button :class="{ active: currentPage === 1}" @click="goToPage(1)">
        1
      </button>
      <button v-if="totalPages > 9 && currentPage >= 5">
        ...
      </button>
      <button
          v-for="(page, index) in visiblePages"
          :key="index"
          :class="{ active: currentPage === page}"
          :disabled="page === '...'"
          @click="goToPage(page)"
      >
        {{ page }}
      </button>
      <button v-if="totalPages > 9 && totalPages - this.currentPage >= 4">
        ...
      </button>
      <button :class="{ active: currentPage === totalPages}" @click="goToPage(totalPages)">
        {{totalPages}}
      </button>
      <button
          @click="nextPage"
          :disabled="currentPage === totalPages"
          class="btn-next"
          :class="{ active: currentPage !== totalPages }"
      >
        {{ nextLabel }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    value: {
      type: [Number, String],
    },
    prevLabel: {
      type: String,
      default: "«",
    },
    nextLabel: {
      type: String,
      default: "»",
    },
    totalPages: {
      type: Number,
      default: 1,
    },
    linkGen: {
      type: Function,
    },
    maxVisiblePages: {
      type: Number,
      required: false,
      default: 3,
    }
  },
  data() {
    return {
      currentPage: +this.$route.query.page || +this.value || 1,
      showFirstDots: false,
      showLastDots: false,
    };
  },
  computed: {
    visiblePages() {
      const itemsArray = [];
      for (let i = 2; i < this.totalPages; i++) {
        if (this.totalPages < 10) {
          itemsArray.push(i);
        } else if (this.currentPage < 5 && i <= 5 ) {
          itemsArray.push(i);
        } else if (this.currentPage >= 5 && i >= 5 && this.totalPages - this.currentPage >= 4) {
          itemsArray.push(this.currentPage - 1);
          itemsArray.push(this.currentPage);
          itemsArray.push(this.currentPage + 1);
          return itemsArray;
        } else if (this.currentPage >= 5 && this.totalPages - i < 5) {
          itemsArray.push(i);
        }
      }
      return itemsArray;
    }
  },
  methods: {
    goToPage(page) {
      this.currentPage = page;
      this.$router.push(this.linkGen(page));
      this.$emit("input", this.currentPage);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.$router.push(this.linkGen(this.currentPage));
        this.$emit("input", this.currentPage);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.$router.push(this.linkGen(this.currentPage));
        this.$emit("input", this.currentPage);
      }
    },
    switchToPage(page) {
      if (page !== this.currentPage) {
        this.goToPage(page);
      }
    },
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #373C44;
  background: #fff;
  padding: 8px 13px;
  text-decoration: none;
  border-radius: 50%;
  margin-right: 10px;
  transition: all .5s;
  border: none;
}

.pagination button.active {
  background: var(--active-accent-color);
}
</style>