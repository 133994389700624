export default function(instance) {
  return {
    passwordRecovery(payload) {
      return instance.post('/passwordRecovery', payload)
    },
    passwordRecoveryVerify(payload) {
      return instance.post('/passwordRecovery/verify', JSON.stringify(payload))
    },
    passwordRecoveryResetPassword(payload) {
      return instance.post('/passwordRecovery/resetPassword', payload)
    },
  }
}