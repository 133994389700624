import { render, staticRenderFns } from "./AssignCaseManager.vue?vue&type=template&id=06ef8436&scoped=true"
import script from "./AssignCaseManager.vue?vue&type=script&lang=js"
export * from "./AssignCaseManager.vue?vue&type=script&lang=js"
import style0 from "./AssignCaseManager.vue?vue&type=style&index=0&id=06ef8436&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06ef8436",
  null
  
)

export default component.exports