
export default function(instance) {
    return {
        getPageHtml(pageName) {
            return instance.get(`/public/${pageName}`, {})
        },
        getAppVersion({apiKey}) {
            return instance.get(`/app/versions`, {
                headers: {
                    'apiKey': apiKey
                }
            })
        },
        getAppVersionById({id, apiKey}) {
            return instance.get(`/app/versions/${id}`, {
                headers: {
                    'apiKey': apiKey
                }
            })
        },
        getSupportPosts() {
            return instance.get(`/public/supportPosts`, {})
        },
        sendContactSalesMail(data) {
            return instance.post(`/public/sendContactSalesMail`, data)
        },
    }
}