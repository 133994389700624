<template>
  <div class="public-page-wrapper">
    <Header v-if="!login" />
    <HeaderDefaultRegistered v-else />
    <h1>
      Cookie Policy
    </h1>
    <div class="main-section-logo-container">
      <img src="@/assets/img/cookie-policy.svg" alt=" logo" class="logo">
    </div>
    <div class="container">
      <p class="last-updated">Updated: June 4, 2024</p>
      <p>This Cookie Policy explains how Pivotal Mobile eDiscovery, Inc. (“PME”, “we”, “us” or
        “our”) uses cookies and similar technologies in connection with the
        <a @click="$router.push({ name: 'Home' })" class="text-link">www.pme-discovery.com</a>
        website, any other website that we own or control and which posts or links to this Cookie Policy (collectively,
        the “Sites”), the PME desktop and mobile applications, and any other mobile applications that we own or control
        and which posts or links to this Cookie Policy (collectively, the “Apps”).</p>
      <p class="subtitle">What are cookies?</p>
      <p>Cookies are small data files that are placed on your computer or mobile device when you
        visit a website. Cookies serve different purposes, like helping us understand how a site is being used, letting
        you navigate between pages efficiently, remembering your preferences and generally improving your browsing experience.</p>
      <p>Our Sites may use both session cookies (which expire once you close your web browser) and
        persistent cookies (which stay on your computer or mobile device until you delete them).</p>
      <p>We use two broad categories of cookies: (1) first party cookies, served directly by us to
        your computer or mobile device, which we use to recognize your computer or mobile device when it revisits our
        Sites; and (2) third party cookies, which are served by service providers or business partners on our Sites, and
        can be used by these parties to recognize your computer or mobile device when it visits other websites. Third
        party cookies can be used for a variety of purposes, including site analytics, advertising and social media features.</p>
      <p class="subtitle">Other technologies</p>
      <p>On the Sites, we use cookies and other tracking technologies as described in the cookie
        banner at the footer of our Sites. In addition to cookies, our Sites may use other technologies, such as browser
        web storage, web beacons, and pixel tags to collect information automatically.</p>
      <p class="subtitle-italic">Browser Web Storage</p>
      <p>We may use browser web storage (including via HTML5), also known as locally stored objects
        (“LSOs”), for similar purposes as cookies. Browser web storage enables the storage of a larger amount of data
        than cookies. Your web browser may provide functionality to clear your browser web storage.</p>
      <p class="subtitle-italic">Web Beacons or Pixel Tags</p>
      <p>We may also use web beacons (which are also known as pixel tags and clear GIFs) on our
        Sites and in our HTML formatted emails to track the actions of users on our Sites and interactions with our
        emails. Unlike cookies, which are stored on the hard drive of your computer or mobile device by a website,
        pixel tags are embedded invisibly on webpages or within HTML formatted emails. Pixel tags are used to
        demonstrate that a webpage was accessed or that certain content was viewed, typically to measure the success of
        our marketing campaigns or engagement with our emails and to compile statistics about usage of the Sites, so
        that we can manage our content more effectively.</p>
      <p class="subtitle-italic">Mobile Application Software Development Kits (SDKs)</p>
      <p>We may use third-party software development kits (“SDKs”) in our mobile applications. A
        SDK is third-party computer code that may be used for a variety of purposes, including to provide us with
        analytics regarding the use of our mobile applications, to integrate with social media, to add features or
        functionality to our app, or to facilitate online advertising. SDKs may enable third parties to collect
        information directly via our App.</p>
      <p>On our Sites, you may block or enable certain cookies and other similar tracking
        technologies using the third-party provided cookie management platform (“CMP”). When you first visit our Sites,
        please click on the privacy icon on the Site to access the CMP. If this is not your first time visiting our
        Sites or you previously agreed to the placement of cookies or similar technologies on our Sites, please clear
        your browser’s cookie history and revisit our Sites.</p>
      <p>Within the CMP, you are able to review the types of cookies and other similar tracking
        technologies that we or our third parties may use. You may exercise your choices with respect to allowing the
        cookies and other similar tracking technologies to process your information. Because these choices are specific
        to the device or browser on which they are exercised, you will need to exercise such choices on every browser
        and device that you use.</p>
      <p>Additionally, most browsers let you remove or reject cookies. To do this, follow the
        instructions in your browser settings. Many browsers accept cookies by default until you change your settings.
        Please note that if you set your browser to disable cookies, the Sites may not work properly.</p>
      <p>For more information about cookies, including how to see what cookies have been set on
        your computer or mobile device and how to manage and delete them, visit
        <a href="https://www.allaboutcookies.org">www.allaboutcookies.org</a>. If you disable or do not accept our cookies,
        you may experience some inconvenience in your use of our Sites. For example, we may not be able to recognize
        your computer or mobile device and you may need to log in every time you visit our Sites.</p>
      <p>Users may opt out of receiving targeted advertising on websites through members of the
        Network Advertising Initiative by clicking <a href="https://www.networkadvertising.org/choices">here</a>
        or the Digital Advertising Alliance by clicking <a href="https://www.aboutads.info/choices">here</a>.
        European users may opt out of receiving targeted advertising on websites through members of the European
        Interactive Digital Advertising Alliance by clicking <a href="https://www.youronlinechoices.eu/">here</a>,
        selecting the user’s country, and then clicking “Choices” (or similarly-titled link). Users of our mobile
        applications may opt out of receiving targeted advertising in mobile apps through participating members of the
        Digital Advertising Alliance by installing the AppChoices mobile app, available
        <a href="https://www.youradchoices.com/appchoices">here</a>, and selecting the user’s choices. Please note that
        we also may work with companies that offer their own opt-out mechanisms and these companies may not participate
        in the opt-out mechanisms that we reference and link above.</p>
      <p>If you choose to opt-out of targeted advertisements, you may still see advertisements
        online but they may not be relevant to you. Even if you do choose to opt out, not all companies that serve
        online behavioral advertising are included in this list, and so you may still receive some cookies and tailored
        advertisements from companies that are not listed.</p>
      <p>For more information about how we collect, use and share your information, see our
        <a @click="$router.push({ name: 'PrivacyPolicy' })" class="text-link">Privacy Policy</a>.</p>
      <p class="subtitle">Changes</p>
      <p>Information about the cookies we use may be updated from time to time, so please check
        back on a regular basis for any changes.</p>
      <p class="subtitle">Questions</p>
      <p>If you have any questions about this Cookie Policy, please contact us by email at privacy@pme-discovery.com</p>
    </div>
    <div v-show="false" class="container public-page-container" v-html="cookiePolicy">
    </div>
    <Footer />
  </div>
</template>

<script>
import login from "../mixins/login"
import Header from "../components/layout/Header";
import HeaderDefaultRegistered from "../components/layout/HeaderDefaultRegistered";
import Footer from "../components/Footer.vue";

export default {
  name: "CookiePolicy",
  data() {
    return {
      cookiePolicy: null
    }
  },
  mixins: [login],
  created() {
    this.$load(async() => {
      let response = (await this.$api.publicPage.getPageHtml('cookiePolicy'))
      this.cookiePolicy = response.data.body.content
    })
  },
  mounted() {
    document.title = 'Cookie Policy'
  },
  components: {
    Footer,
    HeaderDefaultRegistered,
    Header,
  }
}
</script>

<style scoped>
.container {
  max-width: 720px;
  margin: 0 auto 200px;
}

.main-section-logo-container {
  text-align: center;
  margin-bottom: 50px;
}
.static-content {
  padding-top: 45px;
  padding-bottom: 30px;
}
.main-section {
  padding-top: 226px;
}
h1 {
  text-align: center;
  color: var(--secondary-black-color);
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 50px;
}
.last-update-paragraph {
  font-style: italic;
  color: var(--secondary-black-color);
}
.simple-text-paragraph {
  color: var(--secondary-text-color);
  line-height: 28px;
}
.static-content a {
  color: inherit;
}

.last-updated {
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  text-align: left;
  margin-bottom: 50px;
}

.text-link {
  color: blue;
  text-decoration: underline;
}

.subtitle {
  font-size: 24px;
  font-weight: 500;
  text-align: left;
}

.subtitle-italic {
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  font-style: italic;
}
</style>
