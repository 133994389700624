<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M6.99992 0.426666L4.48659 2H2.33325V3.33333L0.979919 4.19333C0.593252 4.42667 0.333252 4.84667 0.333252 5.33333V12C0.333252 12.3536 0.473728 12.6928 0.723776 12.9428C0.973825 13.1929 1.31296 13.3333 1.66659 13.3333H12.3333C13.0733 13.3333 13.6666 12.74 13.6666 12V5.33333C13.6666 4.84667 13.4066 4.42667 13.0199 4.19333L11.6666 3.33333V2H9.51325L6.99992 0.426666ZM3.66659 3.33333H10.3333V6.58667L6.99992 8.66667L3.66659 6.58667V3.33333ZM4.33325 4V5H9.66659V4H4.33325ZM2.33325 4.92V5.75333L1.66659 5.33333L2.33325 4.92ZM11.6666 4.92L12.3333 5.33333L11.6666 5.75333V4.92ZM4.33325 5.66667V6.66667H9.66659V5.66667H4.33325Z"
        fill="#D7DCE6"/>
  </svg>
</template>

<script>
export default {
  name: "MessageIcon"
}
</script>

<style scoped>

</style>