<template>
  <div class="public-page-wrapper">
    <Header v-if="!login" />
    <HeaderDefaultRegistered v-else />
    <h1>
      Privacy Policy
    </h1>
    <div class="main-section-logo-container">
      <img src="@/assets/img/privacy-policy.svg" alt=" logo" class="logo">
    </div>
    <div class="container">
      <p class="last-updated">Updated: June 4, 2024</p>
      <p>You may direct any questions or comments about this Privacy Policy or our privacy
        practices to our privacy office and your communication will be handled by our privacy team (what is sometimes
        referred to as a Grievance Officer, Data Protection Officer, or Privacy Officer). Our privacy office may be contacted by:</p>
      <ul>
        <li>Emailing privacy@pme-discovery.com; or></li>
        <li>Sending postal mail to:<br> Pivotal Mobile eDiscovery, Inc.<br>Attn: Legal – Privacy<br>1536 State Route 23 #1049
          Wayne, NJ 07470<br>United States</li>
      </ul>
      <p>This “Privacy Policy” describes the privacy practices of Pivotal Mobile eDiscovery, Inc.
        and all subsidiaries and affiliates (collectively, “PME”) in connection with PME consumer activity in all regions
        and across all businesses and services, as well as privacy choices available to individuals with respect to their
        information. The first part of this document is applicable to all regions; the second part of this document
        includes information applicable only to certain regions, specifically for United States, European Economic Area
        (including Switzerland and the United Kingdom), Australia/New Zealand, and Mexico. The term “personal information”
        used throughout the first part of this document applies to “personal data,” “personally identifiable information,”
        and other similar definitions of such information. Certain legal bases are specific to certain jurisdictions and
        apply only as and if relevant to that particular jurisdiction. For example, “legitimate use” is a reference to
        India’s Digital Personal Data Protection Act, while “legitimate interest” is a reference to Europe’s GDPR, Brazil’s
        LGPD, and other regions which use that term.</p>
      <p>This Privacy Policy applies to all PME products and services, including business units
        responsible for hardware, software, and SAAS, websites, the PME web and mobile applications, and any other
        website or mobile application that we own or control (all of the above collectively referred to as the “Business”).
        This includes the following domains and their sub-domains and alternative extensions: pmediscovery.ai,
        pme-discovery.com, and pme-demo.com.</p>
      <p>English is the governing version of this Privacy Policy. Any localized or translated
        versions are provided only for convenience. If you do not agree with our policies and practices, it is your
        choice to not interact with our Business. We may modify this Privacy Policy from time-to-time and will endeavor
        to notify you specifically of any material changes that implicate new processing activities.</p>
      <p class="subtitle">Personal information we collect</p>
      <p><b>Information you provide to us.</b> Personal information you provide to us through the
        Service or otherwise may include:</p>
      <ul>
        <li><b>Business and personal contact information,</b>such as your first and last name, email and physical addresses,
          phone number, professional title, and company name.</li>
        <li><b>Profile information,</b> such as your username and password that you may set to establish an online account
          with us, your account name, account number, PME system identification number, photograph, interests, and preferences.</li>
        <li><b>Registration information,</b> such as information that may be related to a service, an account or an event for which you register.</li>
        <li><b>Feedback or correspondence,</b> such as information you provide when you contact us with questions, feedback,
          or otherwise correspond with us online.</li>
        <li><b>Financial information,</b> such as the information you provided to make a purchase such as payment card
          information, financial account number, and billing information is never collected or stored by PME, but is
          provided directly to third-party payment processors such as Orb, Inc.</li>
        <li><b>Marketing information,</b> such as your preferences for receiving communications about our activities,
          events, and publications, and details about how you engage with our communications. We also may conduct user
          surveys, and research and analysis about your use of, or interest in, our products and services.</li>
        <li><b>Other information</b> that we may collect which is not specifically listed here but which we will use in
          accordance with this Privacy Policy or as otherwise disclosed at the time of collection.</li>
      </ul>
      <p class="subtitle">How we use your personal information</p>
      <p>We may use your personal information for the following purposes and as otherwise described
        in this Privacy Policy or at the time of collection:</p>
      <p><b>To operate our business.</b> We may use your personal information to:</p>
      <ul>
        <li>provide, operate and improve our business, including to provide the services that you request (including the Service);</li>
        <li>provide information about our products and services;</li>
        <li>establish and maintain your user profile on the Service;</li>
        <li>enable security features of the Service, such as by sending you security codes via email or text, and
          remembering devices from which you have previously logged into the Service;</li>
        <li>facilitate social features of the Service, such as providing chat or messaging functionality;</li>
        <li>communicate with you about the Service, including by sending you announcements, updates, security alerts,
          support, service-related matters, and administrative messages;</li>
        <li>understand your needs and interests, and personalize your experience with the Service and our communications;</li>
        <li>provide customer support and maintenance for the Service; and</li>
        <li>respond to your requests, questions and feedback.</li>
      </ul>
      <p><b>For research and development.</b> We analyze your use of the Service (including your personal information
        such as demographics) to improve the Service, to develop new products and services, and to market and promote our business.</p>
      <p><b>To send marketing and promotional communications.</b> We may send PME-related marketing communications as
        permitted by law. Recipients of our marketing communications will have the ability to opt-out of our marketing
        and promotional communications as described in the
        <a href="https://enphase.com/legal/privacy-policy#your-choices">Opt-out of Marketing Communications</a> section below.</p>
      <p><b>To comply with law.</b> We use your personal information as we believe necessary or appropriate to comply with
        applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities.</p>
      <p><b>For protection of rights, fraud prevention, and safety.</b> We may use your personal information and disclose it
        to law enforcement, government authorities, and private parties as we believe necessary or appropriate to: (a)
        protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims);
        (b) enforce the terms and conditions that govern the Service; and (c) protect, investigate and deter against
        fraudulent, harmful, unauthorized, unethical or illegal activity.</p>
      <p><b>With your consent.</b> In certain circumstances, we may specifically ask for you to consent to the collection,
        use or sharing of your personal information, such as when required by law or in connection with a specific
        offering in which you have elected to participate.</p>
      <p class="subtitle">How we share your personal information</p>
      <p>We share your personal information as described below with the following entities.</p>
      <p><b>With your consent.</b>We may share your personal information with your consent or as otherwise instructed by you.</p>
      <p><b>To fulfill your request.</b>In order to carry out our obligation or contract to you, or fulfill your request,
        we may need to share your personal information. </p>
      <p><b>Affiliates.</b> We may share your personal information with our corporate parent, subsidiaries, and affiliates,
        for purposes consistent with this Privacy Policy.</p>
      <p><b>Service providers.</b>We may share your personal information with third party companies and individuals that
        provide services on our behalf or help us operate our business (such as payment processors, customer support,
        hosting, analytics, email delivery, marketing, and database management)</p>
      <p><b>Professional advisors.</b>We may disclose your personal information to professional advisors, such as lawyers,
        bankers, auditors and insurers, where necessary in the course of the professional services that they render to us.</p>
      <p><b>Governmental authorities and private parties.</b>We may share your personal information in connection with
        protection of rights, fraud prevention and safety, as
        <a href="https://enphase.com/legal/privacy-policy#how-we-use-your-personal-information"> described above</a>.</p>
      <p><b>Business transfers.</b>We may sell, transfer, or otherwise share some or all of our business or assets,
        including your personal information, in connection with a business transaction (or potential business transaction)
        such as a corporate divestiture, merger, consolidation, acquisition, reorganization or sale of assets, or in the
        event of bankruptcy or dissolution.</p>
      <p class="subtitle">Your choices</p>
      <p>In this section, we describe the rights and choices available to all users. Users who are located in the United
        States, the European Economic Area, Australia & New Zealand, and Mexico can find additional information about
        their rights <a href="https://enphase.com/legal/privacy-policy#european-users">below</a>.</p>
      <p><b>Access or update your information.</b>If you have registered for an PME Account, you may review and update
        certain personal information in your account profile by logging into the PME Account.</p>
      <p><b>Opt-out of marketing communications.</b>You may opt out of marketing-related emails by following the opt-out or
        unsubscribe instructions at the bottom of the email, and by contacting us at our privacy office as indicated
        below. If you receive marketing text messages from us, you may opt out of receiving further marketing text
        messages from us by replying STOP to our marketing message. Even if you opt-out of receiving marketing
        communications from us you may continue to receive service-related and other non-marketing communications.</p>
      <p><b>Choosing not to share your personal information.</b> Where we are required by law to collect your personal
        information and you do not provide it or you ask us to delete it, we may not be able to provide the Service to
        you. Additionally, we may not be able to provide you with the Service or the quality of the Service may be
        affected if (1) you do not provide personal information when requested (2) you withdraw consent previously given
        or (3) you later ask to delete it. We will tell you what information you must provide to receive the Service by
        designating it as required at the time of collection or through other appropriate means.</p>
      <p class="subtitle">Other sites, mobile applications, and services</p>
      <p>The Service may contain links to other websites, mobile applications, and other online services operated by
        third parties. These links are not an endorsement of, or representation that we are affiliated with, any third
        party. In addition, our content may be included on web pages or in mobile applications or online services that
        are not associated with us. We do not control third party websites, mobile applications or online services, and
        we are not responsible for their actions. Other websites and services follow different rules regarding the
        collection, use and sharing of your personal information. We encourage you to read the privacy policies of the
        other websites and mobile applications and online services you use.</p>
      <p class="subtitle">Security practices</p>
      <p>The security of your personal information is important to us. We employ a number of organizational, technical
        and physical safeguards designed to protect the personal information we collect. However, security risk is
        inherent in all internet and information technologies and we cannot guarantee the security of your personal information.</p>
      <p class="subtitle">Retention</p>
      <p>We retain personal information for as long as necessary to fulfill the purposes for which we collected it,
        including for the purposes of satisfying any legal, accounting, or reporting requirements, to establish or
        defend legal claims, or for fraud prevention purposes.</p>
      <p>To determine the appropriate retention period for personal information, we consider the amount, nature, and
        sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your
        personal information, the purposes for which we process your personal information and whether we can achieve
        those purposes through other means, and the applicable legal requirements.</p>
      <p>When we no longer require the personal information we have collected about you, we will either delete or anonymize it or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible. If we anonymize your personal information (so that it can no longer be associated with you), we may use this information indefinitely without further notice to you.</p>
      <p class="subtitle">International data transfers</p>
      <p>We are headquartered in the United States and may have service providers in other countries. Accordingly, your
        personal information may be transferred to the United States, or other locations outside of your state,
        province, or country, where privacy laws may not be as protective as those in your state, province, or country.
        European Union users should read the important information provided
        <a href="https://enphase.com/legal/privacy-policy#european-users">below</a> about transfer of personal
        information outside of the European Union.</p>
      <p class="subtitle">Children</p>
      <p>The Service is not directed to, and we do not knowingly collect personal information from, anyone under the age
        of 18. If a parent or guardian becomes aware that his or her child has provided us with information without
        their consent, he or she should contact us. We will delete such information from our files as soon as reasonably
        practicable. We encourage parents with concerns to <a href="https://enphase.com/legal/privacy-policy#contact-us">contact us</a>.</p>
      <p class="subtitle">Changes to this Privacy Policy</p>
      <p>We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on the Service. We may, and if required by law will, also provide notification of changes to this Privacy Policy using additional methods that we believe are reasonably likely to reach you, such as via e-mail (if you have an PME Account where we have your contact information).</p>
      <p>Any modifications to this Privacy Policy will be effective upon our posting the new terms and/or upon implementation of the new changes on the Service (or as otherwise indicated at the time of posting). In all cases, your continued use of the Service after the posting or effective date as stated in the notification of any modified Privacy Policy indicates your acceptance of the terms of the modified Privacy Policy.</p>
      <p class="subtitle">How to contact us</p>
      <p>You may direct any questions or comments about this Policy or our privacy practices to our privacy office and your communication will be handled by our privacy team (including what is sometimes referred to as a Grievance Officer, Data Protection Officer, or Privacy Officer). Our privacy office may be contacted by:</p>
      <ul>
        <li>Emailing: privacy@pme-discovery.com; or</li>
        <li>Sending postal mail to:<br>Pivotal Mobile eDiscovery, Inc.<br>Attn: Legal – Privacy<br>1536 State Route 23 #1049<br>Wayne, NJ 07470</li>
      </ul>
      <p class="subtitle">Important information for United States residents</p>
      <p>This section applies only to United States residents. It describes how we collect, use and share Personal Information of United States residents in operating our business, and their rights with respect to that Personal Information. For purposes of this section, “Personal Information” has the broadest interpretation of that term or any analogous term in any of the California Privacy Rights Act, Virginia Consumer Data Protection Act, Colorado Privacy Act, Utah Consumer Privacy Act, and Connecticut Data Privacy Act.</p>
      <p><b>Your privacy rights.</b> Depending on your state of residence, you may have the rights listed below. However, these rights are not absolute, and in certain cases we may decline your request as permitted by law.</p>
      <ul>
        <li><b>Information.</b> You can request the following information about how we have collected and used your Personal Information during the past 12 months:</li>
        <ul>
          <li>The categories of Personal Information that we have collected.</li>
          <li>The categories of sources from which we collected Personal Information.</li>
          <li>The business or commercial purpose for collecting and/or selling Personal Information.</li>
          <li>The categories of third parties with whom we share Personal Information.</li>
          <li>Whether we have disclosed your Personal Information for a business purpose, and if so, the categories of Personal Information received by each category of third-party recipient.</li>
          <li>Whether we’ve sold your Personal Information, and if so, the categories of Personal Information received by each category of third-party recipient.</li>
        </ul>
        <li><b>Access/data portability.</b> You can request a copy of the Personal Information that we have collected about you during the past 12 months, including in a format that is easily understandable and, to the extent technically feasible, in a structured, commonly used, machine-readable format.</li>
        <li><b>Deletion.</b> You can ask us to delete the Personal Information that we have collected from you.</li>
        <li><b>Opt-out of sales.</b> In conducting the Service, we do not sell your Personal Information.</li>
        <li><b>Opt-out of sharing.</b> We do not share you Personal Information with advertisers.</li>
        <li><b>Correction.</b> You may request that we update or correct inaccuracies in your Personal Information.</li>
        <li><b>Appeal.</b> If we decline to take action on your data subject request for any reason, we will provide you a written statement of the reasons why; you are entitled to an appeal by contacting us with information relevant to the issue, or by contacting your state Attorney General or other privacy protection agency, which may take up the matter depending on your state of residence.</li>
        <li><b>Nondiscrimination.</b>You are entitled to exercise the rights described above free from discrimination in the form of legally prohibited increases in the price or decreases in the quality of our Service.</li>
      </ul>
      <p class="subtitle">How to exercise your rights</p>
      <p>You may exercise your privacy rights by any of the methods listed in the “How to Contact Us” section above.</p>
      <p>We reserve the right to confirm your account to process your requests and will need to confirm your identity to process your requests to exercise your information, access/data portability, or deletion rights. We generally seek to validate your request without collecting any additional personal information and seek to rely for example on information we already have in our possession -- the email address from which you sent the request and/or the mobile phone number used to register you account. Consistent with law, you may designate an authorized agent to make a request on your behalf. To designate an authorized agent to make a request on your behalf, you must provide a valid power of attorney, the requester’s valid government-issued identification, and the authorized agent’s valid government-issued identification. We cannot process your request if you do not provide us with sufficient detail to allow us to understand and respond to it.</p>
      <p class="subtitle">Personal information that we collect, use and share</p>
      <p>The chart below summarizes our relevant data practices during the 12 months preceding the effective date of this Privacy Policy. Categories in the chart refer to the categories described above in the general section of this Privacy Policy.</p>
      <table class="personal-info-table">
        <tbody>
        <tr>
          <td colspan="5">
            <p class="table-subtitle">Category of personal information:
              <a href="https://leginfo.legislature.ca.gov/faces/billCompareClient.xhtml?bill_id=201720180SB1121&showamends=false">Identifiers</a></p>
          </td>
        </tr>
        <tr>
          <td>
            <p><b>Personal information we collected</b></p>
          </td>
          <td>
            <p><b>Personal information source</b></p>
          </td>
          <td>
            <p><b>Purpose for personal information collection</b></p>
          </td>
          <td>
            <p><b>Categories of third parties to whom we “Disclose” the personal information for a business purpose</b></p>
          </td>
          <td>
            <p><b>Categories of third parties to whom we “Sell” or “Share” the personal information</b></p>
          </td>
        </tr>
        <tr>
          <td>
            <ul>
              <li>Business and personal contact information</li>
              <li>Registration information</li>
              <li>Feedback or correspondence</li>
              <li>Demographic information</li>
              <li>Other information</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>You</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>To operate the Service</li>
              <li>For research and development</li>
              <li>To send you marketing and promotional communications</li>
              <li>To comply with law</li>
              <li>For protection of rights, fraud prevention, and safety</li>
              <li>To create anonymous, aggregated or de-identified data</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Affiliates</li>
              <li>Service providers</li>
              <li>Professional advisors</li>
              <li>Governmental authorities / private parties</li>
              <li>Business transferees</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>None</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td colspan="5">
            <p class="table-subtitle">Category of personal information:
              <a href="https://leginfo.legislature.ca.gov/faces/billCompareClient.xhtml?bill_id=201720180SB1121&showamends=false">Online Identifiers</a></p>
          </td>
        </tr>
        <tr>
          <td>
            <p><b>Personal information we collected</b></p>
          </td>
          <td>
            <p><b>Personal information source</b></p>
          </td>
          <td>
            <p><b>Purpose for personal information collection</b></p>
          </td>
          <td>
            <p><b>Categories of third parties to whom we “Disclose” the personal information for a business purpose</b></p>
          </td>
          <td>
            <p><b>Categories of third parties to whom we “Sell” or “Share” the personal information</b></p>
          </td>
        </tr>
        <tr>
          <td>
            <ul>
              <li>None</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>N/A</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>N/A</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>N/A</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>N/A</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td colspan="5">
            <p class="table-subtitle">Category of personal information:
              <a href="https://leginfo.legislature.ca.gov/faces/billCompareClient.xhtml?bill_id=201720180SB1121&showamends=false">Commercial Information</a></p>
          </td>
        </tr>
        <tr>
          <td>
            <p><b>Personal information we collected</b></p>
          </td>
          <td>
            <p><b>Personal information source</b></p>
          </td>
          <td>
            <p><b>Purpose for personal information collection</b></p>
          </td>
          <td>
            <p><b>Categories of third parties to whom we “Disclose” the personal information for a business purpose</b></p>
          </td>
          <td>
            <p><b>Categories of third parties to whom we “Sell” or “Share” the personal information</b></p>
          </td>
        </tr>
        <tr>
          <td>
            <ul>
              <li>Financial information</li>
              <li>Transaction information</li>
              <li>Usage information</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>You</li>
              <li>Third Parties</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>To operate the Service</li>
              <li>For research and development</li>
              <li>To send you marketing and promotional communications</li>
              <li>To comply with law</li>
              <li>For protection of rights, fraud prevention, and safety</li>
              <li>To create anonymous, aggregated or de-identified data</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Affiliates</li>
              <li>Service providers</li>
              <li>Professional advisors</li>
              <li>Governmental authorities / private parties</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>None</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td colspan="5">
            <p class="table-subtitle">Category of personal information:
              <a href="https://leginfo.legislature.ca.gov/faces/billCompareClient.xhtml?bill_id=201720180SB1121&showamends=false">Financial Information</a></p>
          </td>
        </tr>
        <tr>
          <td>
            <p><b>Personal information we collected</b></p>
          </td>
          <td>
            <p><b>Personal information source</b></p>
          </td>
          <td>
            <p><b>Purpose for personal information collection</b></p>
          </td>
          <td>
            <p><b>Categories of third parties to whom we “Disclose” the personal information for a business purpose</b></p>
          </td>
          <td>
            <p><b>Categories of third parties to whom we “Sell” or “Share” the personal information</b></p>
          </td>
        </tr>
        <tr>
          <td>
            <ul>
              <li>Financial information</li>
              <li>Transaction information</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>You</li>
              <li>Third Parties</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>To operate the Service</li>
              <li>To comply with law</li>
              <li>For protection of rights, fraud prevention, and safety</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Affiliates</li>
              <li>Service providers</li>
              <li>Professional advisors</li>
              <li>Governmental authorities / private parties</li>
              <li>Business transferees</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>None</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td colspan="5">
            <p class="table-subtitle">Category of personal information:
              <a href="https://leginfo.legislature.ca.gov/faces/billCompareClient.xhtml?bill_id=201720180SB1121&showamends=false">Internet or Network Information</a></p>
          </td>
        </tr>
        <tr>
          <td>
            <p><b>Personal information we collected</b></p>
          </td>
          <td>
            <p><b>Personal information source</b></p>
          </td>
          <td>
            <p><b>Purpose for personal information collection</b></p>
          </td>
          <td>
            <p><b>Categories of third parties to whom we “Disclose” the personal information for a business purpose</b></p>
          </td>
          <td>
            <p><b>Categories of third parties to whom we “Sell” or “Share” the personal information</b></p>
          </td>
        </tr>
        <tr>
          <td>
            <ul>
              <li>Usage data</li>
              <li>Device data</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Automatic data collection</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>To operate the Service</li>
              <li>For research and development</li>
              <li>To send you marketing and promotional communications</li>
              <li>To comply with law</li>
              <li>For protection of rights, fraud prevention, and safety</li>
              <li>To create anonymous, aggregated or de-identified data</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Affiliates</li>
              <li>Service providers</li>
              <li>Professional advisors</li>
              <li>Governmental authorities / private parties</li>
              <li>Business transferees</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>None</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td colspan="5">
            <p class="table-subtitle">Category of personal information:
              <a href="https://leginfo.legislature.ca.gov/faces/billCompareClient.xhtml?bill_id=201720180SB1121&showamends=false">Inferences</a></p>
          </td>
        </tr>
        <tr>
          <td>
            <p><b>Personal information we collected</b></p>
          </td>
          <td>
            <p><b>Personal information source</b></p>
          </td>
          <td>
            <p><b>Purpose for personal information collection</b></p>
          </td>
          <td>
            <p><b>Categories of third parties to whom we “Disclose” the personal information for a business purpose</b></p>
          </td>
          <td>
            <p><b>Categories of third parties to whom we “Sell” or “Share” the personal information</b></p>
          </td>
        </tr>
        <tr>
          <td>
            <p class="table-subtitle" style="margin-bottom: 10px">May be derived from your:</p>
            <ul>
              <li>Profile information</li>
              <li>Registration information</li>
              <li>Feedback or correspondence</li>
              <li>Demographic information</li>
              <li>Financial information</li>
              <li>Transaction information</li>
              <li>Usage information</li>
              <li>Device data</li>
              <li>Online identifiers</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>You</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>To operate the Service</li>
              <li>For research and development</li>
              <li>To send you marketing and promotional communications</li>
              <li>To comply with law</li>
              <li>For protection of rights, fraud prevention, and safety</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Affiliates</li>
              <li>Service providers</li>
              <li>Professional advisors</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>None</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td colspan="5">
            <p class="table-subtitle">Category of personal information:
              <a href="https://leginfo.legislature.ca.gov/faces/billCompareClient.xhtml?bill_id=201720180SB1121&showamends=false">Protected Classification Characteristics</a></p>
          </td>
        </tr>
        <tr>
          <td>
            <p><b>Personal information we collected</b></p>
          </td>
          <td>
            <p><b>Personal information source</b></p>
          </td>
          <td>
            <p><b>Purpose for personal information collection</b></p>
          </td>
          <td>
            <p><b>Categories of third parties to whom we “Disclose” the personal information for a business purpose</b></p>
          </td>
          <td>
            <p><b>Categories of third parties to whom we “Sell” or “Share” the personal information</b></p>
          </td>
        </tr>
        <tr>
          <td>
            <p class="table-subtitle" style="margin-bottom: 10px">May be derived from your:</p>
            <ul>
              <li>We do not systematically intentionally collect this information but it may be revealed in identity data or other information we collect</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>N/A</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>N/A</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>N/A</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>N/A</li>
            </ul>
          </td>
        </tr>
        </tbody>
      </table>
      <p class="subtitle">Important information for European Economic Area residents</p>
      <p>The information provided in this section applies only to individuals in the European Economic Area.</p>
      <p><b>Personal information.</b> References to “Personal Data” in this Privacy Policy are defined as “personal data” governed by European data protection legislation.</p>
      <p><b>Controller and representative.</b> PME is the controller of your Personal Data covered by this Privacy Policy for purposes of European data protection legislation.</p>
      <p><b>Legal bases for processing.</b> We use your Personal Data only as permitted by law. Our legal bases for processing the Personal Data described in this Privacy Policy are described in the table below.</p>
      <table class="legal-table">
        <tbody>
        <tr>
          <td>
            <p><b>Processing purpose</b></p>
            <p>Details regarding each processing purpose listed below are provided in the section above titled “How we use your personal information”.</p>
          </td>
          <td>
            <p><b>Legal basis</b></p>
          </td>
        </tr>
        <tr>
          <td>
            <p><b>To operate our business</b></p>
          </td>
          <td>
            <p>Processing is necessary to perform a contractual obligation owed to you or to take steps that you request prior to signing a contract. If there is no contractual obligation, we process your Personal Data based on our legitimate interest in operating our business.</p>
          </td>
        </tr>
        <tr>
          <td>
            <ul>
              <li><b>To administer events, programs, and contests</b></li>
              <li><b>For research and development</b></li>
              <li><b>To send you marketing and promotional communications</b></li>
              <li><b>To display advertisements</b></li>
              <li><b>For protection of rights, fraud prevention and safety</b></li>
              <li><b>To create anonymous, aggregated or de identified data</b></li>
              <li><b>To respond to your requests</b></li>
              <li><b>To improve our products and Services</b></li>
            </ul>
          </td>
          <td>
            <p>These activities constitute our legitimate interests in fulfilling your requests, operating our business, and other purposes consistent with this privacy policy, Art. 6 (1) (f) GDPR. We do not use your Personal Data for these activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law).</p>
          </td>
        </tr>
        <tr>
          <td>
            <ul>
              <li><b>To comply with law</b></li>
            </ul>
          </td>
          <td>
            <p>Processing is necessary to comply with our legal obligations.</p>
          </td>
        </tr>
        <tr>
          <td>
            <ul>
              <li><b>With your consent</b></li>
            </ul>
          </td>
          <td>
            <p>Processing is based on your consent. Where we rely on your consent you have the right to withdraw it at any time in the manner indicated when you consent or in the Service.</p>
          </td>
        </tr>
        </tbody>
      </table>
      <p><b>Use for new purposes.</b> We may use your Personal Data for reasons not described in this Privacy Policy where permitted by law and the reason is compatible with the purpose for which we collected it. If we need to use your Personal Data for an unrelated purpose, we will notify you and explain the applicable legal basis.</p>
      <p><b>Sensitive Personal Information.</b> We ask that you not provide us with any sensitive Personal Data (e.g., information related to racial or ethnic origin, political opinions, religion or other beliefs, health, biometrics or genetic characteristics, criminal background or trade union membership) on or through the Service, or otherwise.</p>
      <p>If you provide us with any sensitive personal information when you use the Service, you must consent to our processing and use of such sensitive Personal Data in accordance with this Privacy Policy. If you do not consent to our processing and use of such sensitive Personal Data, you must not submit such sensitive Personal Data through our Service.</p>
      <p style="font-size: 20px;"><b>Your rights</b></p>
      <p>European data protection laws give you certain rights regarding your Personal Data . If you are located within the European Union, you may ask us to take the following actions in relation to your Personal Data that we hold:</p>
      <ul>
        <li><b>Access.</b>Provide you with information about our processing of your Personal Data and give you access to your Personal Data.</li>
        <li><b>Correct.</b> Update or correct inaccuracies in your Personal Data.</li>
        <li><b>Delete.</b> Delete your Personal Data.</li>
        <li><b>Transfer.</b> Transfer a machine-readable copy of your Personal Data to you or a third party of your choice.</li>
        <li><b>Restrict.</b> Restrict the processing of your Personal Data</li>
        <li><b>Object.</b> Object to our reliance on our legitimate interests as the basis of our processing of your Personal Data that impacts your rights.</li>
        <li><b>Instruction.</b> Define instructions for the management of your Personal Data after your death.</li>
        <li><b>Withdraw your consent.</b> You can withdraw your consent at any time. The withdrawal of consent is only effective for the future, i.e., the withdrawal shall not affect the lawfulness of processing on the basis of consent before its withdrawal.</li>
      </ul>
      <p>You may exercise your privacy rights by any of the methods listed in the “How to Contact Us” section above. We may request specific information from you to help us confirm your identity and process your request.</p>
      <p>Applicable law may require or permit us to decline your request. If we decline your request, we will tell you
        why, subject to legal restrictions. If you would like to submit a complaint about our use of your personal
        information or our response to your requests regarding your Personal Data, you may
        <a href="https://enphase.com/legal/privacy-policy#contact-us">contact us</a> or submit a complaint to the data
        protection regulator in your jurisdiction. You can find your data protection regulator
        <a href="https://edpb.europa.eu/about-edpb/board/members_en">here</a>.</p>
      <p>If we serve as a processor for a controller in the European Economic Area processing your Personal Data, then you may contact us for a list of sub-processors on whom we rely and share your Personal Data.</p>
      <p style="font-size: 20px;"><b>Cross-border data transfer</b></p>
      <p>If we transfer your personal information out of Europe to a country not deemed by the European Commission to provide an adequate level of Personal Data protection, the transfer will be performed:</p>
      <ul>
        <li>We rely on standard contractual clauses which have been entered into between our European entities as data exporters and other group companies as data importers</li>
        <li>Rarely, if at all, we rely on Pursuant to GDPR Article 49 derogations, including the consent of the individual to whom the Personal Data pertains, as necessary for the performance of a contract, or for compelling legitimate interests upon confirmation that such legitimate interests are not overridden by your interests or rights and freedoms</li>
        <li>We may in the future rely on other permitted bases as allowable by applicable European requirements</li>
        <li>You may contact us if you want further information on the specific mechanism used by us when transferring your Personal Data out of Europe, for identification of data importers and their identity/contact details, or for other information related to our data transfer mechanisms</li>
      </ul>
      <p class="subtitle">Important information for Australian and New Zealand users</p>
      <p>The information provided in this “Notice to Australian and New Zealand Users” section applies only to individuals in Australia and New Zealand. This notice supplements and must be read in conjunction with our Privacy Policy.</p>
      <p><b>Personal information.</b>References to “personal information” in this Privacy Policy have the same meaning
        as in the <i>Privacy Act 1988 (Cth) or Privacy Act 2020</i> (NZ) (as applicable) (collectively the “Privacy Laws”) and include any information about an identifiable individual.</p>
      <p><b>Sensitive information.</b> We ask that you not provide us with any “sensitive information.” Examples of sensitive information include (without limitation, and by way of example only) information about an individual’s racial or ethnic origin, political opinions, religious beliefs or affiliation, sexual orientation, criminal record or health information.</p>
      <p>If you do provide us with sensitive information, it will be used and disclosed by us only for the purposes for which it was provided, or a directly related secondary purpose, unless you agree otherwise or for other specific reasons such as if the use or disclosure of this information is required by law.</p>
      <p><b>Anonymity and pseudonyms.</b> You have a right to use a pseudonym or to remain anonymous in your dealings with us and/or the Service. However, if you do not provide us with the personal information that we request, our ability to provide you with access to the Service or to handle your enquiry, request, complaint or other communication may be diminished.</p>
      <p><b>Cross border disclosure.</b> Any personal information which you provide to us may be transferred to, stored
        by or disclosed to an overseas recipient in order to provide the Service and our products to you (this includes
        storage of data on servers which are located overseas). The recipient may be in a jurisdiction that does not
        offer similar protections to the Privacy Laws. <b>You expressly authorize us to disclose your personal
          information to overseas recipients for such purposes</b>.</p>
      <p>In order to protect your personal information, we take care to work with service providers and third parties who we consider maintain acceptable privacy and data security standards and compliance. In addition, we will take reasonable steps to ensure that the recipient does not breach Privacy Laws when handling your personal information.</p>
      <p><b>Managing personal information.</b> You may:</p>
      <ul>
        <li>request access to your personal information that we hold, subject to any limitations at law; and</li>
        <li>request us to correct information about you that you believe is inaccurate, incomplete or out of date,</li>
        <li>by writing to us at the contact details below.</li>
        <li>If you are located in New Zealand, you may also contact us by submitting a request through the ‘AboutMe’
          tool offered by the Office of the Privacy Commissioner for New Zealand accessible via the following
          link <a href="https://www.privacy.org.nz/tools/aboutme-request-my-info-tool/">https://www.privacy.org.nz/tools/aboutme-request-my-info-tool/</a>.</li>
      </ul>
      <p>We may require you to verify your identity before giving you access to, or correcting, your personal information.</p>
      <p>We will respond to requests within a reasonable period after the request is made. There is no charge to make a request, however we may charge a reasonable fee to cover the administrative costs of retrieving your personal information.</p>
      <p>In certain circumstances, we may refuse, or be unable, to correct or provide you with access to your personal information. In these circumstances, we will write to you to explain the reasons why this is the case.</p>
      <p><b>Data breach.</b> Where an unauthorized disclosure of, access to or loss of, personal information has occurred, we will act in accordance with our plan for responding to such an incident. If we determine that a reportable data breach has occurred in accordance with the Privacy Laws, we will report it to (as applicable):</p>
      <ul>
        <li>the Office of the Australian Information Commissioner; and/or</li>
        <li>the Office of the New Zealand Privacy Commissioner,</li>
      </ul>
      <p>and any other entity or person, including affected individual(s), if required under Privacy Laws or other governing rules.</p>
      <p><b>Queries.</b> If you have a question or wish to make a complaint regarding how we handle your personal information, you may contact us using the details below. We will respond within a reasonable time and will use best endeavors to investigate and resolve the issue. This may involve requesting further information from you. If we need additional time, we will notify you of this (and reasons for the delay) and seek to agree a reasonable revised timeframe with you.</p>
      <p>If we cannot resolve a complaint related to your personal information, you may direct your complaint to:</p>
      <ul>
        <li>the Office of the Australian Information Commissioner as detailed on
          <a href="https://www.oaic.gov.au/privacy/privacy-complaints/">https://www.oaic.gov.au/privacy/privacy-complaints/</a>; or</li>
        <li>to the Office of the Privacy Commissioner for New Zealand as detailed on
          <a href="https://privacy.org.nz/your-rights/making-a-complaint/">https://privacy.org.nz/your-rights/making-a-complaint/</a>.</li>
      </ul>
      <p><b>Contact details.</b> We can be contacted and you may exercise your privacy rights by any of the methods listed in the “How to Contact Us” section above.</p>
      <p>For more information about privacy issues in Australia and New Zealand, please visit
        <a href="https://www.oaic.gov.au/">www.oaic.gov.au</a> or <a href="https://www.privacy.org.nz/">www.privacy.org.nz</a>.</p>
      <p class="subtitle">Important information for Mexico users</p>
      <p>According to Mexican legislation, you have the right to Access your personal data, request the correction or update of your personal data (Rectification); request that we delete your personal data in situations where this is possible (Cancellation); oppose the processing of your personal data for voluntary purposes or under other legitimate grounds (Opposition) (“ARCO Rights”). Likewise, you may limit the use and disclosure of your personal data or revoke the consent you have provided us for the processing of your personal data without retroactive effects.</p>
      <p>The individual concerned can exercise these rights by contacting PME via the “How To Contact Us” section above.</p>
    </div>
    <Footer />
  </div>
</template>

<script>
import login from "../mixins/login";
import Header from "../components/layout/Header";
import HeaderDefaultRegistered from "../components/layout/HeaderDefaultRegistered";
import Footer from "../components/Footer.vue";

export default {
  name: "PrivacyPolicy",
  data() {
    return {
      privacyPolicy: null
    }
  },
  mixins: [login],
  created() {
    this.$load(async() => {
      let response = (await this.$api.publicPage.getPageHtml('privacyPolicy'))
      this.privacyPolicy = response.data.body.content
    })
  },
  mounted() {
    document.title = 'Privacy policy'
  },
  components: {
    Footer,
    Header,
    HeaderDefaultRegistered,
  }
}
</script>

<style scoped lang="scss">
.container {
  max-width: 720px;
  margin: 0 auto 200px;
}
.main-section-logo-container {
  text-align: center;
  margin-bottom: 50px;
}
.static-content {
  padding-top: 45px;
  padding-bottom: 30px;
}
.main-section {
  padding-top: 226px;
}
h1 {
  text-align: center;
  color: var(--secondary-black-color);
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 50px;
}
.last-update-paragraph {
  font-style: italic;
  color: var(--secondary-black-color);
}
.simple-text-paragraph {
  color: var(--secondary-text-color);
  line-height: 28px;
}
.static-content a {
  color: inherit;
}


.last-updated {
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  text-align: left;
  margin-bottom: 50px;
}

.text-link {
  color: blue;
  text-decoration: underline;
}

.subtitle {
  font-size: 22px;
  font-weight: 400;
  text-align: left;
}

.subtitle-italic {
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  font-style: italic;
}

ul {
  list-style-type: disc;
  margin: 0 0 30px 30px;
}
ul li {
  margin-bottom: 10px;
}

.personal-info-table {
  border-collapse: collapse;
  border: 1px solid #000;
  width: 830px;
  margin: 0 -7% 30px;

  td {
    border: 1px solid #000;
    padding: 5px;

    p {
      margin: 0;
      text-align: center;
    }

    .table-subtitle {
      text-align: left;
      font-weight: 700;
    }

    ul {
      margin: 0;
    }

    li {
      margin: 0 15%;
    }
  }
}

.legal-table {
  border-collapse: collapse;
  border: 1px solid #000;
  width: 830px;
  margin: 0 -7% 30px;

  td {
    border: 1px solid #000;
    padding: 5px;

    p {
      margin: 10px;
    }

    ul {
      margin: 0;
    }

    li {
      margin: 0 5%;
    }
  }
}
</style>
