<template>
    <div class="faq-sub-item">
      <div class="sub-item-header" @click="subContent = !subContent">
        <h3 class="faq-item-subtitle">{{question}}</h3>
      </div>
      <div class="sub-item-content" v-if="subContent">
        <div class="content" v-text="answer"></div>
<!--        <div class="faq-item-info">-->
<!--          <h3 class="faq-item-question">Was this information helpful?</h3>-->
<!--          <div class="faq-info-btns">-->
<!--            <button-->
<!--                id="faq_info_btn_yes"-->
<!--                class="faq-info-btn">Yes</button>-->
<!--            <button-->
<!--                id="faq_info_btn_no"-->
<!--                class="faq-info-btn">no</button>-->
<!--          </div>-->
<!--          <span class="faq-info-statistic">This article was helpful to 83% of users</span>-->
<!--        </div>-->
      </div>
    </div>
</template>

<script>
export default {
  name: "FaqSubAccordeon",
  props: {
    answer: String,
    question: String,
  },
  data() {
    return {
      subContent: false
    }
  }
}
</script>

<style scoped>

.sub-item-header {
  padding: 25px 35px 0 88px;
}

.sub-item-content {
  padding: 38px 57px 65px 88px;
  border-bottom: 0.5px solid var(--input-placeholder-value-color);
}

.faq-item-subtitle {
  color: var(--secondary-black-color);
  display: block;
  transition: all .5s;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  margin: 0;
  text-decoration: underline;
}

.faq-item-subtitle:hover {
  color: var(--accent-color);
}

.sub-item-content p {
  margin: 0;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: var(--faq-text-color);
}

.sub-item-content p a {
  text-decoration: none;
  color: var(--faq-text-color);
}

.faq-item-info {
  margin: 69px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-item-question {
  margin: 0 27px 0 0;
  font-size: 18px;
  font-weight: 500;
  color: var(--secondary-black-color);
}

.faq-info-btns {
  margin: 0 auto 0 0;
  width: 100%;
  max-width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.faq-info-btn {
  padding: 15px 0;
  width: 100px;
  max-width: 91px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  outline: none;
  background-color: var(--white-color);
  border: 1px solid var(--light-gray);
  border-radius: 30px;
  color: var(--light-gray);
  transition: .5s all;
}

.faq-info-btn:hover {
  border-color: var(--accent-color);
  color: var(--accent-color);
}

.faq-info-statistic {
  font-weight: 500;
  font-size: 18px;
  color: var(--input-placeholder-value-color);
}
</style>
