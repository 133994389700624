<template>
  <thead class="table-header">
    <slot></slot>
  </thead>
</template>

<script>
export default {
  name: "TableHeader"
}
</script>

<style scoped>

.table-header-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.table-header-box:hover .header-table-text {
  text-decoration: underline;
}

.table-header svg {
  margin-right: 10px;
}

.table-header .btn-deactivate svg {
  margin-right: 0px;
}

.table-header .btn-deactivate {
  width: auto;
}

.table-header span {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 10px;
  color: var(--secondary-black-color);
  margin-right: 10px;
}

em {
  color: var(--light-gray);
  font-size: 12px;
  font-weight: 400;
  margin-left: 10px;
}

table{
  width:100%;
  table-layout: fixed;
}

.table-header {
  background: #F8FAFC;
}

th{
  padding: 24px 15px;
  text-align: left;
}

td{
  padding: 15px;
  text-align: left;
  vertical-align:middle;
  font-weight: 300;
  font-size: 12px;
  color: #fff;
  border-bottom: solid 1px rgba(255,255,255,0.1);
}

/*  */

/* for custom scrollbar for webkit browser*/
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

</style>
