<template>
  <div class="table-body">
    <table cellpadding="0" cellspacing="0" border="0">
      <tbody>
        <tr v-for="(item, i) in 5"
            :key="item + i">
          <td>
            <div class="holder-info">
              <div class="info">
                <span class="info-mail">Case 3003</span>
              </div>
            </div>
          </td>
          <td>
            <div class="holder-info">
                <span class="info-mail">Bessie Cooper</span>
            </div>
          </td>
          <td>
            <div class="holder-info">
              <span class="info-mail">Reviewers</span>
            </div>
          </td>
          <td>
            <div class="holder-info">
              <span class="info-mail">27.12.2020</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "SharedList"
}
</script>

<style scoped>

</style>