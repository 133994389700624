<template>
  <div>
    <Header v-if="!login" />
    <HeaderDefaultRegistered v-else />
    <div class="main">
      <section class="faq">
        <div class="faq-container">
          <h1 class="faq-title">FAQ</h1>
          <CustomSearch class-component="faq-search"/>
          <FaqItemList :data="data" />
        </div>
      </section>
    </div>
    <Footer/>
  </div>
</template>

<script>
import login from "../mixins/login";
import Header from "../components/layout/Header";
import HeaderDefaultRegistered from "../components/layout/HeaderDefaultRegistered";
import Footer from "../components/Footer.vue";
import CustomSearch from "../components/inputs/CustomSearch";
import FaqItemList from "../components/FAQ/FaqItemList";

export default {
  name: "faq",
  components: {
    Footer,
    Header,
    HeaderDefaultRegistered,
    CustomSearch,
    FaqItemList
  },
  data() {
    return {
      data: [],
    }
  },
  mixins: [login],
  created() {
    this.$load(async() => {
      let response = (await this.$api.faqPage.getFaqPage())
      this.data = response.data.body.categories
    })
  },
  mounted() {
    document.title = 'FAQ'
  }
}
</script>

<style scoped>
.faq {
  padding: 50px 0 200px 0;
  min-height: 100vh;
}

.faq-title {
  font-size: 36px;
  text-align: center;
  margin: 0 0 50px 0;
}

.faq-container {
  width: 100%;
  max-width: 1120px;
  padding: 0 15px;
  margin: 0 auto;
}

.faq-search {
  display: none;
}

</style>