<template>
  <section
    class="messages-section"
    :class="[
      isAdvancedFilter || isAdvancedFilterApplied ? 'advanced-filter' : 'simple-filter',
      isAdvancedFilter ? 'open' : '',
    ]"
  >
    <div class="top-bar">
      <div class="settings-wrapper">
        <div v-show="!isAdvancedFilter" class="tab-wrapper">
          <router-link
            :to="{ name: 'Case messages mobile list' }"
            tag="button"
            id="messages_mobile_tab"
            exact-path
            class="tab-btn"
          >
            <span class="tab-btn-text">Mobile chats</span>
          </router-link>
          <router-link
            :to="{ name: 'Case messages email list' }"
            tag="button"
            id="messages_email_tab"
            exact-path
            class="tab-btn"
          >
            <span class="tab-btn-text">Email</span>
          </router-link>
          <router-link
            :to="{ name: 'Case messages media list' }"
            tag="button"
            id="messages_media_tab"
            exact-path
            class="tab-btn"
          >
            <span class="tab-btn-text">Media</span>
          </router-link>
          <router-link
            :to="{ name: 'Case messages call logs list' }"
            tag="button"
            id="messages_logs_tab"
            exact-path
            class="tab-btn"
          >
            <span class="tab-btn-text">Call Logs</span>
          </router-link>
          <router-link
            :to="{ name: 'Case messages contacts list' }"
            tag="button"
            id="messages_contacts_tab"
            exact-path
            class="tab-btn"
          >
            <span class="tab-btn-text">Contacts</span>
          </router-link>
          <router-link
            :to="{ name: 'Case messages apps list' }"
            tag="button"
            id="messages_apps_tab"
            exact-path
            class="tab-btn"
          >
            <span class="tab-btn-text">Apps</span>
          </router-link>
        </div>
        <template v-if="messageIsLoad && messagesList.length && isAdvancedFilterApplied || isAdvancedFilter">
          <div v-if="isAdvancedFilter" class="filter-types">
            <button
              :class="{selected: !filterTypeSaved}"
              class="filter-type"
              @click="filterTypeSaved = false"
            >
              Default
            </button>
            <button
              :class="{selected: filterTypeSaved}"
              class="filter-type"
              @click="filterTypeSaved = true"
            >
              Saved
            </button>
          </div>
        </template>
        <div v-if="messageIsLoad && messagesList.length" class="advanced-change-button-wrapper">
          <button v-if="stringifiedRules" class="advanced-change-button add-to-favorites"
                  @click="openSaveFilterPatternPopup">
            <svg width="16" height="23" viewBox="0 0 16 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.9221 0.09375V22.3134C15.9219 22.5357 15.7975 22.7394 15.5996 22.8409C15.4017 22.9424 15.1636 22.9248 14.9829 22.795L7.99416 17.7856L1.00567 22.795C0.824907 22.9248 0.586905 22.9424 0.388995 22.8409C0.191085 22.7394 0.066638 22.5357 0.0664062 22.3134V0.09375H15.9221Z"
                fill="#082655"/>
            </svg>

            <span>Add To Favorites</span>
          </button>
          <button
            :disabled="!isAdvancedFilterApplied"
            class="reset-filters"
            @click="resetFilters"
          >
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.9997 2.33203C7.54801 2.33203 2.33301 7.54703 2.33301 13.9987C2.33301 20.4504 7.54801 25.6654 13.9997 25.6654C20.4513 25.6654 25.6663 20.4504 25.6663 13.9987C25.6663 7.54703 20.4513 2.33203 13.9997 2.33203ZM19.833 18.187L18.188 19.832L13.9997 15.6437L9.81134 19.832L8.16634 18.187L12.3547 13.9987L8.16634 9.81036L9.81134 8.16537L13.9997 12.3537L18.188 8.16537L19.833 9.81036L15.6447 13.9987L19.833 18.187Z" fill="#FF9537"/>
            </svg>
            Reset Filters
          </button>
          <button @click="changeFilterView" class="advanced-change-button">
            <svg :class="{'advanced-mode': isAdvancedFilter}" width="24" height="25" viewBox="0 0 24 25" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.7236 19.6382L10.7236 21.1382C10.3912 21.3044 10 21.0627 10 20.691V13.7071C10 13.5745 9.94732 13.4473 9.85355 13.3536L4.14645 7.64645C4.05268 7.55268 4 7.4255 4 7.29289V5C4 4.72386 4.22386 4.5 4.5 4.5H19.5C19.7761 4.5 20 4.72386 20 5V7.29289C20 7.4255 19.9473 7.55268 19.8536 7.64645L14.1464 13.3536C14.0527 13.4473 14 13.5745 14 13.7071V19.191C14 19.3804 13.893 19.5535 13.7236 19.6382Z"
                stroke="#082655"/>
            </svg>

            <span>Advanced</span>

            <svg :class="{'advanced-mode-arrow': isAdvancedFilter}" width="16" height="17" viewBox="0 0 16 17"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.99059 12.498C7.79067 12.4984 7.59692 12.4287 7.44298 12.3009L2.3091 8.01743C2.13437 7.87202 2.02448 7.66306 2.00362 7.43652C1.98276 7.20999 2.05263 6.98444 2.19787 6.80948C2.34311 6.63453 2.55181 6.52451 2.77806 6.50363C3.00432 6.48274 3.22959 6.5527 3.40433 6.69811L7.99059 10.5361L12.5768 6.83519C12.6644 6.76402 12.7651 6.71088 12.8732 6.67881C12.9813 6.64675 13.0946 6.63638 13.2068 6.64833C13.3189 6.66027 13.4275 6.69428 13.5265 6.7484C13.6254 6.80252 13.7127 6.87568 13.7833 6.96369C13.8617 7.05178 13.921 7.15512 13.9576 7.26724C13.9942 7.37936 14.0073 7.49784 13.9961 7.61526C13.9848 7.73268 13.9495 7.84652 13.8923 7.94963C13.835 8.05273 13.7572 8.1429 13.6635 8.21447L8.52964 12.3523C8.37128 12.4599 8.18148 12.5111 7.99059 12.498Z"
                fill="#8692A6"/>
            </svg>
          </button>
        </div>
      </div>
      <div
        v-if="messageIsLoad && messagesList.length"
        v-show="isAdvancedFilter"
        class="advanced-filter-wrap"
      >
        <SavedFilters
          v-if="filterTypeSaved"
          @attachFilter="attachFilter"
          @backToDefault="filterTypeSaved = false"
        />
        <div v-show="!filterTypeSaved" class="control-section">
          <AdvancedSearch
              ref="advancedSearch"
              :client-list="clientList"
              :current-client-id="clientId"
              :data-source="dataSource"
              :import-rules="importRules"
              :initial-data="initialData"
              :result-count="resultCount"
              local-storage-rules-name="emailRules"
              @filter="filter"
              @reset-count="resultCount = 0"
              @reset-search="resetFilters"
              @set-advanced-messages="setAdvancedMessages"
          />
        </div>
      </div>
      <div
        v-if="messageIsLoad && messagesList.length && isAdvancedFilterApplied && !isAdvancedFilter"
        class="advanced-filter-wrap"
      >
        <div class="filter-tags-close">
          <button class="advanced-change-button" @click="isOpenedFilterList = !isOpenedFilterList">
            <span>{{ isOpenedFilterList ? 'Close' : 'Show' }} Applied Filters</span>
            <svg
              :class="{'advanced-mode-arrow': isOpenedFilterList}"
              class="filter-tags-arrow"
              fill="none"
              height="17"
              viewBox="0 0 16 17"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.99059 12.498C7.79067 12.4984 7.59692 12.4287 7.44298 12.3009L2.3091 8.01743C2.13437 7.87202 2.02448 7.66306 2.00362 7.43652C1.98276 7.20999 2.05263 6.98444 2.19787 6.80948C2.34311 6.63453 2.55181 6.52451 2.77806 6.50363C3.00432 6.48274 3.22959 6.5527 3.40433 6.69811L7.99059 10.5361L12.5768 6.83519C12.6644 6.76402 12.7651 6.71088 12.8732 6.67881C12.9813 6.64675 13.0946 6.63638 13.2068 6.64833C13.3189 6.66027 13.4275 6.69428 13.5265 6.7484C13.6254 6.80252 13.7127 6.87568 13.7833 6.96369C13.8617 7.05178 13.921 7.15512 13.9576 7.26724C13.9942 7.37936 14.0073 7.49784 13.9961 7.61526C13.9848 7.73268 13.9495 7.84652 13.8923 7.94963C13.835 8.05273 13.7572 8.1429 13.6635 8.21447L8.52964 12.3523C8.37128 12.4599 8.18148 12.5111 7.99059 12.498Z"
                fill="#8692A6"
              />
            </svg>
          </button>
        </div>
        <ul v-show="isOpenedFilterList" class="control-tags">
          <li
            v-for="tag of filteredTags"
            :key="tag.id"
            :class="{ 'removed': tag.removed }"
            class="filter-tag"
          >
            <button type="button" @click="removeFilter(tag)">
              <svg fill="none" height="28" viewBox="0 0 28 28" width="28" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14 2.3335C7.54833 2.3335 2.33333 7.5485 2.33333 14.0002C2.33333 20.4518 7.54833 25.6668 14 25.6668C20.4517 25.6668 25.6667 20.4518 25.6667 14.0002C25.6667 7.5485 20.4517 2.3335 14 2.3335ZM19.8333 18.1885L18.1883 19.8335L14 15.6452L9.81166 19.8335L8.16666 18.1885L12.355 14.0002L8.16666 9.81183L9.81166 8.16683L14 12.3552L18.1883 8.16683L19.8333 9.81183L15.645 14.0002L19.8333 18.1885Z"
                  data-v-1f88d880=""
                  fill="#D7DCE5"
                />
              </svg>
            </button>
            <strong>{{ tag.label | filteringLabel }}</strong>:&nbsp;
            <span>{{ tag.initialValue || tag.value }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="messageIsLoad && !messagesList.length" class="filter-content no-data">
      <img
        src="@/assets/img/empty-emails-icon.svg"
        alt="Empty emails image"
      >
      <span>You have no e-mails integrated to the account </span>
      <router-link
        class="btn redirect"
        tag="button"
        :to="{ name: 'E-mail integration' }"
      >Integrate e-mail
      </router-link>
    </div>
    <div v-else-if="messageIsLoad" class="filter-content">
      <div v-if="viewMessages === 'simple'" class="nav-chat">
        <div v-if="clientName" class="email-name">
          <span v-text="clientName" />
        </div>
        <EmailMessagesList
          v-if="messagesList.length && currentMessageId"
          :infinite-flag="false"
          :messages="messagesList"
          :current-id="currentMessageId"
          @change-message="changeMessage"
        />
      </div>
      <div v-else-if="viewMessages === 'advanced'" class="nav-chat">
        <div v-if="clientName" class="email-name">
          <span v-text="clientName" />
        </div>
        <EmailMessagesList
          :infinite-flag="true"
          :infinite-id="infiniteAdvancedId"
          @infinite-event="infiniteHandlerAdvanced"
          :messages="messagesAdvancedList"
          :current-id="currentMessageId"
          @change-message="changeMessage"
        />
      </div>
      <div class="chat-list">
        <SpecificEmailMessage
          :content="specificMessage"
          :load-flag="specificMessageLoader"
          @downloadFile="downloadFile"
          @export="exportMessages"
        />
      </div>
    </div>
    <SuccessPopup
      ref="successPopup"
      :text="'You exported the message!'"
    />
    <SaveFilterPattern
      ref="saveFilterPattern"
      @saveFilterPattern="saveFilterPattern"
    />
  </section>
</template>

<script>

import settings from "../../../mixins/scrollSettings";
import SuccessPopup from '@/components/popups/SuccessPopup';
import moment from 'moment';
import debounce from '@/mixins/debounce';
import SavedFilters from '@/components/сases/case-messages/SavedFilters';
import SaveFilterPattern from '@/components/popups/SaveFilterPattern';
import EmailMessagesList from "@/components/сases/case-messages/EmailMessagesList.vue";
import SpecificEmailMessage from "@/components/сases/case-messages/SpecificEmailMessage.vue";
import AdvancedSearch from '@/components/сases/advanced-search/AdvancedSearch';


export default {
  name: 'CaseMessagesEmails',
  components: {
    AdvancedSearch,
    SpecificEmailMessage,
    EmailMessagesList,
    SaveFilterPattern,
    SavedFilters,
    SuccessPopup,
  },
  emits: ['show-error'],
  mixins: [settings, debounce],
  data() {
    return {
      messageIsLoad: false,
      specificMessageLoader: true,
      currentMessageId: null,
      specificMessage: {},
      isAdvancedFilterApplied: false,
      dataRange: '',
      caseId: this.$route.params.slug,
      filterTypeSaved: false,
      isAdvancedFilter: false,
      dateFrom: '',
      dateTo: '',
      messagesList: [],
      msgFilters: [],
      isOpenedFilterList: true,
      messagesAdvancedList: [],
      filteredTags: [],
      viewMessages: 'simple',
      infiniteId: +new Date(),
      infiniteAdvancedId: +new Date(),
      messagesAdvancedListPage: 0,
      messagesAdvancedListSize: 20,
      advancedInfiniteHandlerState: null,
      clientId: this.$route.query.clientId || '',
      clientName: '',
      userSelectedFlag: false,
      dataSource: [],
      queryFilterParams: null,
      defaultQueryFilterParams: [],
      defaultImportRules: {'condition': "and",'rules': [{}, { field: 'folder', operator: 'is', key: 'is', type: 'string', label: 'Folder' }]},
      importRules: {'condition': "and",'rules': [{}, { field: 'folder', operator: 'is', key: 'is', type: 'string', label: 'Folder' }]},
      stringifiedRules: '',
      disabledFiltersId: [],
      resultCount: 0,
      clientList: [],
      initialData: [],
    }
  },
  watch: {
    clientId(newVal) {
      if (newVal) {
        if (newVal !== this.$route.query.clientId) {
          this.$router.replace({ query: { clientId: newVal } });
        }

        if (this.userSelectedFlag) {
          if (this.viewMessages === 'simple') {
            this.getCaseMessages(newVal);
          }
        }

        this.getFilterRules();
        this.userSelectedFlag = true;
      }
    },
    dataRange(value) {
      if (value) {
        this.dateFrom = !isNaN(Date.parse(value[0])) ? Date.parse(value[0]) : ''
        this.dateTo = !isNaN(Date.parse(value[1])) ? Date.parse(value[1])  : ''
      } else {
        this.dataRange = '';
        this.dateFrom = '';
        this.dateTo = '';
        this.getCaseMessages()
      }
    },
    currentMessageId(newValue) {
      if (newValue === null) {
        this.specificMessage = {};
      }

      this.getSpecificMessage(newValue);
    }
  },
  computed: {

  },
  filters: {
    filteringLabel(label) {
      let labelsList = label.split(" ");
      labelsList = labelsList.map(phrase => {
        if (phrase === 'a' || phrase === "for" || phrase === 'Search' || phrase === 'the') {
          return;
        }
        return phrase;
      });

      return labelsList.join(" ");
    }
  },
  methods: {
    reset() {
      this.$refs.advancedSearch.$refs.querybuilder.ej2Instances.reset()
      this.$refs.advancedSearch.$refs.querybuilder.ej2Instances.levelColl = []
    },
    filterTags(rules) {
      rules.forEach(rule => {
        rule.rules ? this.filterTags(rule.rules) : this.filteredTags.push(rule);
      })
    },
    removeFilter(disabledTag) {
      let areThereRules = false;

      if (disabledTag.removed) {
        const disabledFilterId = this.disabledFiltersId.findIndex(el => el === disabledTag.id);

        this.disabledFiltersId.splice(disabledFilterId, 1);
      } else {
        this.disabledFiltersId.push(disabledTag.id);
      }

      disabledTag.removed = !disabledTag.removed;

      this.queryFilterParams = this.defaultQueryFilterParams
        .map(parameter => {
          const rules = parameter.rules.filter(rule => {
            for (let i = 0; i < this.disabledFiltersId.length; i++) {
              if (rule.id === this.disabledFiltersId[i]) {
                return false;
              }
            }
            return true;
          });

          if (rules.length) {
            areThereRules = true;
          }

          return {
            ...parameter,
            rules,
          };
        })
        .filter(queryParam => !!queryParam.rules.length);

      if (areThereRules) {
        this.messagesAdvancedList = [];
        this.messagesAdvancedListPage = 0;

        this.debounce(() => {
          this.infiniteHandlerAdvanced(this.advancedInfiniteHandlerState);
        }, 1000);
      } else {
        this.resetFilters();
      }
    },

    changeFilterView() {
      this.isAdvancedFilter = !this.isAdvancedFilter;
    },
    filter(getResultCount, queryFilterParams) {
      if (queryFilterParams) {
        this.queryFilterParams = queryFilterParams;
      }

      if (!getResultCount && this.clientId) {
        this.stringifiedRules = JSON.stringify({
          clientId: this.clientId,
          rules: this.$refs.advancedSearch.$refs.querybuilder.ej2Instances.rule.properties
        });
        localStorage.setItem('emailRules', this.stringifiedRules);
      } else if (!this.clientId) {
        this.resetFilters();
      }

      this.queryFilterParams = this.queryFilterParams.filter(param => param.rules.length);

      this.queryFilterParams.forEach(item => {
        if(item.rules.length) {
          item.rules.forEach((el, i) => {
            if(!el.value) {
              item.rules.splice(i, 1);
              return;
            }
            if(el.field === 'date') {

              if(el.value.length) {
                let dateObj = {
                  field: "date",
                  label: "Date",
                  operator: "gth",
                  type: "date",
                  value: ""
                }
                let dateFromObj = Object.assign({}, dateObj)
                let dateToObj = Object.assign({}, dateObj)
                let dateFrom = el.value[0].split('/')
                let dateTo = el.value[1].split('/')
                let dateFromTime = `${dateFrom[2]}-${dateFrom[1]}-${dateFrom[0]}`
                let dateToTime = `${dateTo[2]}-${dateTo[1]}-${dateTo[0]}`
                dateFrom = moment(dateFromTime).valueOf()
                dateTo = moment(dateToTime).endOf('day').valueOf()
                dateFromObj.value = dateFrom
                dateFromObj.operator = 'gth'
                dateToObj.value = dateTo
                dateToObj.operator = 'lth'
                dateFromObj.initialValue = dateFromTime
                dateToObj.initialValue = dateToTime
                item.rules.push(dateFromObj)
                item.rules.push(dateToObj)
                item.rules.splice(i, 1);
              }
            }
          });
        }
      });

      this.messagesAdvancedListPage = 0;

      if(this.advancedInfiniteHandlerState) {
        this.advancedInfiniteHandlerState.reset();
      }

      if (getResultCount) {
        this.getAdvancedResultAmount();
      } else {
        this.viewMessages = 'advanced';
        this.messagesAdvancedList = [];
        this.filteredTags = [];
        this.isAdvancedFilterApplied = true;
        this.infiniteAdvancedId += 1;
        this.isAdvancedFilter = false;
        this.defaultQueryFilterParams = this.queryFilterParams;
        this.filterTags(this.queryFilterParams);
      }
    },
    openSaveFilterPatternPopup() {
      this.$refs.saveFilterPattern.openPopups();
    },
    async saveFilterPattern(filterName) {
      await this.$load(async() => {
        try {
          await this.$api.cases.saveFilterPattern(this.caseId, {
            name: filterName,
            value: this.stringifiedRules,
          });
          this.stringifiedRules = '';
        } catch (error) {
          this.$emit('show-error', error.response.data.errors.message.toString());
        }
      })
    },
    async attachFilter(filterId) {
      await this.$load(async () => {
        const filter = await this.$api.cases.getFilterPattern(this.caseId, filterId);
        this.filterTypeSaved = false;
        this.$refs.advancedSearch.setParsedRules(JSON.parse(filter.data.body.value));
      });
    },
    resetFilters() {
      localStorage.removeItem('emailRules');
      this.stringifiedRules = '';
      this.isAdvancedFilter = true;
      this.isAdvancedFilterApplied = false;
      this.messagesAdvancedListPage = 0;
      this.queryFilterParams = null;
      this.messagesAdvancedList = [];
      this.viewMessages = 'simple';
      this.filteredTags = [];
      this.isOpenedFilterList = true;
      this.resultCount = 0;
      this.reset();
      this.$refs.advancedSearch.$refs.querybuilder.ej2Instances.setRules(this.defaultImportRules);
      this.getClientList();
      this.getCaseMessages();
    },
    downloadFile(data) {
      this.$load(async () => {
        await this.$api.cases
          .downloadEmailsAttachment({caseId: this.caseId, attachmentId: data.id})
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', data.name);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          }, (error) => {
            if (error.response) {
              this.$emit('show-error', error.response.data.errors.message[0]);
            } else {
              this.$emit('show-error', error.data.errors.message[0]);
            }
          });
      });
    },
    changeMessage(data) {
      if (this.currentMessageId === data.id) {
        return;
      }
      this.currentMessageId = data.id;
    },

    getClientList() {
      this.$load(async () => {
        await this.$api.cases
          .getUsersInMessages({caseId: this.caseId})
          .then((res) => {
            this.clientList = [];
            if (res.data.body.length) {
              res.data.body.forEach((item) => {
                this.clientList.push({id: String(item.id), value: item.name});
              });
            }

            const isCurrentUser = this.clientList.find(user => user.id === this.clientId);
            if (isCurrentUser) {
              this.clientName = isCurrentUser.value;
            } else {
              this.clientId = this.clientList[0].id;
              this.clientName = this.clientList[0].value;
            }
          }, (error) => {
            if (error.response) {
              this.$emit('show-error', error.response.data.errors.message[0]);
            } else {
              this.$emit('show-error', error.data.errors.message[0]);
            }
          });
      });
    },
    getCaseMessages() {
      this.$load(async () => {
        await this.$api.cases
          .getCaseMessagesEmailList(this.caseId)
          .then((res) => {
            const list = res.data.body;
            this.messagesList = list;
            this.infiniteId += 1;

            if (list.length) {
              this.currentMessageId = list[0].id;
            }

            this.messageIsLoad = true;
          }, (error) => {
            if (error.response) {
              this.$emit('show-error', error.response.data.errors.message[0]);
            } else {
              this.$emit('show-error', error.data.errors.message[0]);
            }
          });
      });
    },
    getSpecificMessage(emailId) {
      this.specificMessageLoader = true;
      this.$load(async() => {
        await this.$api.cases.getCaseMessagesEmailSpecificMail(this.caseId, emailId).then((res) => {
          this.specificMessage = [res.data.body].map(item => {
            return {
              sender: item.senderName,
              recipients: item.recipientEmails,
              attachments: item.attachments,
              date: item.date,
              id: item.id,
              subject: item.subject,
              folder: item.folder,
              content: item.email,
              tags: item.tags
            };
          })[0];

          setTimeout(() => {
            this.specificMessageLoader = false;
          }, 500);
        }, (error) => {
          if (error.response) {
            this.$emit('show-error', error.response.data.errors.message[0]);
          } else {
            this.$emit('show-error', error.data.errors.message[0]);
          }

          setTimeout(() => {
            this.specificMessageLoader = false;
          }, 500);
        });
      });
    },
    getAdvancedResultAmount() {
      if (this.queryFilterParams.length) {
        this.$load(() => {
          const params = {
            page: 1,
            size: 20,
            count: true
          };
          this.$api.cases
            .getCaseMessagesEmailsAdvanced(this.caseId, this.queryFilterParams, params)
            .then(res => {
              this.resultCount = res.data.body.totalElements;
            });
        });
      }
    },
    infiniteHandlerAdvanced($state) {
      if (this.queryFilterParams) {
        this.$load(() => {
          this.messagesAdvancedListPage += 1;
          const params = {
            page: this.messagesAdvancedListPage,
            size: this.messagesAdvancedListSize,
            count: false
          };
          this.$api.cases
            .getCaseMessagesEmailsAdvanced(this.caseId, this.queryFilterParams, params)
            .then((res) => {
              const {content} = res.data.body;
              this.resultCount = res.data.body.totalElements;
              if (content.length) {
                this.messagesAdvancedList.push(...content);
                this.currentMessageId = this.messagesAdvancedList[0].id;
                if ($state) {
                  this.advancedInfiniteHandlerState = $state;
                  $state.loaded();
                }
              } else {
                if ($state) {
                  $state.complete();
                }
              }
            }, (error) => {
              if (error.response) {
                this.$emit('show-error', error.response.data.errors.message[0]);
              } else {
                this.$emit('show-error', error.data.errors.message[0]);
              }
              if ($state) {
                $state.error();
              }
            });
        });
      }
    },
    exportMessages(id, format) {
      this.$load(async() => {
        await this.$api.cases
          .exportEmailMessage(this.caseId, id, {format})
          .then(() => {
            this.$refs.successPopup.openPopup();
          }, (error) => {
            if (error.response) {
              this.$emit('show-error', error.response.data.errors.message[0]);
            } else {
              this.$emit('show-error', error.data.errors.message[0]);
            }
          });
      });
    },
    getFilterRules() {
      if (!this.clientId) {
        return;
      }
      this.$load(async () => {
        await this.$api.cases
          .getFilterEmailRules(this.caseId)
          .then((res) => {
            this.initialData = res.data.body;
          }, (error) => {
            if (error.response) {
              this.$emit('show-error', error.response.data.errors.message[0]);
            } else {
              this.$emit('show-error', error.data.errors.message[0]);
            }
          });
      });
    },
    setAdvancedMessages(queryFilterParams) {
      this.importRules = {};
      this.viewMessages = 'advanced';
      this.isAdvancedFilterApplied = true;
      this.filter(true, queryFilterParams);
      this.filter(false, queryFilterParams);
    }
  },
  mounted() {
    const clientId = this.$route.query.clientId;

    if (clientId !== undefined) {
      this.clientId = clientId;
    }

    this.getClientList();
    this.getCaseMessages();
    this.getFilterRules();
  }
};
</script>

<style scoped>
.messages-section {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 27px 55px 27px 35px;
}

.filter-content {
  display: flex;
  width: 100%;
}

.filter-content.no-data {
  margin-top: 220px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.filter-content.no-data img {
  margin-bottom: 30px;
}

.filter-content.no-data span {
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: var(--light-gray);
  margin-bottom: 20px;
}

.filter-content.no-data .redirect {
  padding: 8px 15px;
  background-color: var(--white-color);
  border-color: var(--accent-color);
  color: var(--accent-color);
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
}

.filter-content.no-data .redirect:hover {
  background-color: var(--accent-color);
  color: var(--white-color);
}

.nav-chat {
  margin-top: 92px;
  width: 381px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #D7DCE6;
}

.advanced-filter .nav-chat {
  margin-top: 0;
}

.advanced-filter .chat-list {
  margin-top: 30px;
}

.simple-filter .top-bar {
  padding: 30px 24px;
}

.chat-list {
  width: calc(100% - 381px);
  height: calc(100vh - 200px);
  min-height: 940px;
  /*overflow: auto;*/
  margin-top: 120px;
}

.top-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  padding: 28px 22px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(77, 106, 147, 0.15);
}

.advanced-filter .top-bar {
  position: static;
  width: 100%;
}

.open.advanced-filter .top-bar {
  padding-bottom: 35px;
}

.advanced-change-button-wrapper {
  display: flex;
}

.settings-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.messages-section.open .settings-wrapper {
  padding-bottom: 28px;
  border-bottom: 0.5px solid var(--case-filters-border-color);
}

.filter-types {
  border-radius: 79px;
  outline: 1px solid var(--case-btn-border-color);
  width: fit-content;
  min-height: 35px;
}

.filter-type {
  height: 35px;
  position: relative;
  z-index: 2;
  transition: outline 0.3s, color 0.3s;
  border: none;
  border-radius: 79px;
  outline: 1px solid transparent;
  width: 144px;
  padding: 5px 16px;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: var(--light-gray);
}

.filter-type.selected {
  outline: 1px solid var(--accent-color);
  color: var(--accent-color);
}

.filter-type:hover {
  color: var(--accent-color);
}

.reset-filters {
  background-color: transparent;
  display: flex;
  align-items: center;
  border: none;
  margin-right: 23px;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  color: var(--secondary-black-color);
}

.reset-filters:disabled path {
  fill: var(--case-btn-border-color);
}

.reset-filters:disabled {
  cursor: not-allowed;
}

.reset-filters svg {
  margin-right: 10px;
}

.reset-filters:not(:disabled):hover {
  text-decoration: underline;
}

.add-to-favorites {
  margin-right: 23px;
}

.advanced-change-button {
  height: 30px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  padding: 0;
  background-color: unset;
}

.advanced-change-button span {
  margin: 0 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #373C44;
}

.advanced-change-button:hover span {
  text-decoration: underline;
}

.advanced-mode {
  fill: var(--accent-color);
}

.advanced-mode-arrow {
  transform: rotate(180deg);
}

.empty-chats-wrap {
  text-align: center;
  margin-top: 45px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.advanced-filter-wrap {
  width: 100%;
  margin-top: 20px;
  position: relative;
}

.e-query-builder {
  width: 100% !important;
}

.control-tags {
  margin-bottom: 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.filter-tag {
  display: flex;
  align-items: center;
  width: max-content;
  padding: 6px 12px;
  border-radius: 20px;
  background-color: var(--accent-color);
  font-size: 13px;
  color: var(--white-color);
}

.filter-tag button {
  display: flex;
  margin-right: 8px;
  border: navajowhite;
  padding: 0;
  background-color: unset;
}

.filter-tag.removed path {
  fill: #ff9537;
}

.filter-tag svg {
  width: 18px;
  height: 18px;
}

.filter-tag path {
  transition: fill 0.3s;
}

.filter-tag strong {
  text-transform: capitalize;
}

.filter-tags-close {
  display: flex;
  align-items: center;
}

.filter-tags-arrow {
  transition: all 0.3s;
}

.filtering-name b {
  color: red;
}

.tab-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 310px;
  width: auto;
  min-height: 35px;
  margin-right: 20px;
  border-radius: 79px;
  border: 1px solid var(--case-btn-border-color);
}

.tab-btn {
  margin: -1px 0 -1px 0;
  width: 50%;
  max-height: 35px;
  padding: 10px 30px;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
  border-radius: 79px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  transition: 0.3s all;
}

.tab-btn.active {
  border-color: var(--accent-color);
}

.tab-btn-text {
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: var(--light-gray);
  transition: 0.3s all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-btn.active .tab-btn-text,
.tab-btn:hover .tab-btn-text {
  color: var(--accent-color);
}

.email-name {
  margin: 32px 20px 19px 20px;
}

.email-name span {
  word-break: break-word;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--light-gray);
}

</style>