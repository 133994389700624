<template>
  <header class="header">
    <div class="container-large header-wrapper">
      <div class="logo-wrap" @click="changeActiveSection('features', 0)">
        <img :src="logo" alt="logo" />
      </div>
      <div v-if="$route.name === 'Home' || $route.name === 'PressRelease'" class="menu-panel" v-click-outside="closeDropdown">
        <div class="menu-item" v-for="(theme, index) in reviewThemes" :key="index">
          <div class="menu-title">
            <span
              @click="changeActiveSection(theme.name, index)"
              :class="{ active: activeReviewIndex === index }"
            >
              {{ theme.name }}
            </span>
            <svg
              v-if="theme.childs.length > 0"
              @click="toggleDropdown(index)"
              :class="{ activeArrow: activeDropdownIndex === index }"
              style="transition: all .3s ease;"
              width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.3402 6.43162L8.88589 11.8859C8.83523 11.9366 8.77508 11.9768 8.70887 12.0043C8.64265 12.0317 8.57168 12.0459 8.5 12.0459C8.42832 12.0459 8.35735 12.0317 8.29113 12.0043C8.22492 11.9768 8.16476 11.9366 8.11411 11.8859L2.65984 6.43162C2.5575 6.32928 2.5 6.19047 2.5 6.04573C2.5 5.90099 2.5575 5.76218 2.65984 5.65984C2.76218 5.5575 2.90099 5.5 3.04573 5.5C3.19047 5.5 3.32928 5.5575 3.43162 5.65984L8.5 10.7289L13.5684 5.65984C13.6191 5.60916 13.6792 5.56897 13.7454 5.54154C13.8116 5.51412 13.8826 5.5 13.9543 5.5C14.0259 5.5 14.0969 5.51412 14.1631 5.54154C14.2293 5.56897 14.2895 5.60916 14.3402 5.65984C14.3908 5.71052 14.431 5.77068 14.4585 5.83689C14.4859 5.9031 14.5 5.97406 14.5 6.04573C14.5 6.1174 14.4859 6.18836 14.4585 6.25457C14.431 6.32078 14.3908 6.38094 14.3402 6.43162Z" fill="#696F79"/>
            </svg>
          </div>
          <ul v-if="activeDropdownIndex === index && theme.childs.length" class="dropdown">
            <li v-for="child in theme.childs" :key="child" @click="handleChildSelect(child)">{{ child }}</li>
          </ul>
        </div>
      </div>
      <div class="header-btn-wrap">
        <button
          v-if="$route.name === 'Home'"
          @click="openCalendly"
          id="home_header_button"
          class="btn-revert btn"
        >
          Book a Demo
        </button>
        <button
          @click="$router.push({name: 'LoginInAccount'})"
          id="home_header_button"
          class="header-btn btn"
        >
          Log in
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import LocalStorageService from "../../services/LocalStorageService";
import logo from "@/assets/img/logo.svg"

const localStorageService = LocalStorageService.getService();

export default {
  name: "Header",
  emits: ['goto'],
  data() {
    return {
      logo,
      reviewThemes: [
        {
          name: "PME Features",
          childs: [],
        },
        {
          name: "Our Platform",
          childs: [],
        },
        {
          name: "Resources",
          childs: ['Blog', 'Press']
        },
        {
          name: "Contact us",
          childs: [],
        }
      ],
      activeReviewIndex: null,
      activeDropdownIndex: null
    }
  },
  methods: {
    openCalendly() {
      if (window.Calendly) {
        window.Calendly.initPopupWidget({
          url: 'https://calendly.com/d/cp96-rfb-jg8/pme-demo'
        });
      }
    },
    closeDropdown() {
      this.activeDropdownIndex = null;
    },
    toggleDropdown(index) {
      this.activeDropdownIndex = this.activeDropdownIndex === index ? null : index;
    },
    changeActiveSection(themeName, index) {
      if (index === 2) {
        this.toggleDropdown(index);
      } else {
        this.activeReviewIndex = index;
      }
      this.$emit('goto', themeName)
    },
    handleIntersection(entries) {
      let intersectingCount = 0;

      entries.forEach(entry => {
        if (entry.isIntersecting) {
          intersectingCount++;
          const index = this.reviewThemes.findIndex(theme => theme.name === entry.target.id);
          if (index !== -1) {
            this.activeReviewIndex = index;
          }
        }
      });

      if (intersectingCount === 0) {
        this.activeReviewIndex = null;
      }
    },
    handleChildSelect(childName) {
      if (childName === 'Blog') {
        window.open('https://blog.pme-discovery.com/', '_blank');
      } else if (childName === 'Press') {
        this.$emit('goto', childName);
      }
      this.closeDropdown();
    },
  },
  mounted() {
    if (localStorageService.getAccessTokenLogin() !== null) {
      this.$store.commit('authentication/loginSuccess', null)
    }

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // Adjust this threshold according to your needs
    };

    this.observer = new IntersectionObserver(this.handleIntersection, observerOptions);

    this.reviewThemes.forEach(theme => {
      const section = document.getElementById(theme.name);
      if (section) {
        this.observer.observe(section);
      }
    });
  },
  beforeDestroy() {
    // Disconnect observer when the component is destroyed
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
</script>

<style scoped lang="scss">

header {
  padding: 10px 100px;
  width: 100%;
  z-index: 20;
  background: var(--white-color);
  height: 65px;
  position: sticky;
  top: 0;
}

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-btn-wrap {
    display: flex;
    align-items: center;
    gap: 15px;

    .btn {
      height: 40px;
      width: 100%;
      max-width: 150px;
    }

    .btn-revert {
      width: 100%;
      max-width: 165px;
    }
  }
}

.menu-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 510px;
  width: 100%;
}

.menu-item {
  padding: 10px;
  cursor: pointer;
  position: relative;
}

.menu-title {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 25.6px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--secondary-text-color);
  transition: all .3s ease;

  &:hover {
    color: var(--accent-color);
  }

  .active {
    color: var(--accent-color);
    box-shadow: 0 3px var(--accent-color);
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  min-width: 100px;
}

li {
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 25.6px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--secondary-text-color);
}

li:hover {
  color: var(--accent-color);
  background-color: #EBEFF6;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  border-radius: 8px;
}

.activeArrow {
  transform: rotate(-180deg);
}

</style>