<template>
  <div class="wrapper-popup">
    <div class="holder-popup">
        <!-- content -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupWrapper",
  methods: {
    hide() {
      // this.$emit("hide-popup");
    },
  },
}
</script>

<style scoped>

.wrapper-popup {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.38);
}

.holder-popup {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;
  position: absolute;
  border-radius: 30px;
  overflow-y: auto;
  background-color: var(--white-color);
}

.popup-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 23px;
  width: 70px;
  height: 70px;
  cursor: pointer;
}

.popup-close svg path {
  transition: .5s all;
}

.popup-close:hover svg path {
  fill: var(--accent-color);
}

</style>
