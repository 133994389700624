import LocalStorageService from "@/services/LocalStorageService";

export function authHeader() {
    // return authorization header with jwt token
    const localStorageService = LocalStorageService.getService();
    const token = localStorageService.getAccessToken();
    const tokenLogin = localStorageService.getAccessTokenLogin();
    if (token) {
        localStorageService.clearTokenLogin()
        return 'Bearer ' + token;
    }
    if (tokenLogin) {
        return 'Bearer ' + tokenLogin;
    }
}