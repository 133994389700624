<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M14.3888 3.35802C14.8384 3.35802 15.2194 3.52263 15.5316 3.85185C15.8439 4.18107 16 4.58272 16 5.05679V14.3012C16 14.7753 15.8439 15.177 15.5316 15.5062C15.2194 15.8354 14.8384 16 14.3888 16H1.61124C1.16159 16 0.78064 15.8354 0.468384 15.5062C0.156128 15.177 0 14.7753 0 14.3012V5.05679C0 4.58272 0.156128 4.18107 0.468384 3.85185C0.78064 3.52263 1.16159 3.35802 1.61124 3.35802H4.79625V1.65926C4.79625 1.21152 4.95238 0.823045 5.26464 0.493827C5.57689 0.164609 5.95785 0 6.40749 0H9.59251C10.0422 0 10.4231 0.164609 10.7354 0.493827C11.0476 0.823045 11.2037 1.21152 11.2037 1.65926V3.35802H14.3888ZM9.59251 3.35802V1.65926H6.40749V3.35802H9.59251Z"
        fill="#D7DCE6"/>
  </svg>
</template>

<script>
export default {
  name: "CaseIcon"
}
</script>

<style scoped>

</style>