export default {
    SET_ROLE_DATA(state, data) {
        state.roleInfo = data;
    },
    SET_USER_DATA(state, data) {
        state.userInfo = data;
    },
    SET_USER_INFO(state, data) {
        state.user = data;
    },
    SET_PERMISSION_PER_CASE(state, data) {
        state.permission = data;
    },
    SET_CASE_USERS_DATA(state, data) {
        state.userInfoCase = data;
    },
    SET_CASE_DATA(state, data) {
        state.casesList = data;
    },
    SET_CURRENT_CASE_DATA(state, data) {
        state.curentCase = data;
    },
}