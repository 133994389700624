<template>
  <div class="otp-wrapper">
    <span class="otp-subtitle">Enter one-time password</span>
    <v-otp-input
        ref="otpInput"
        input-classes="otp-input"
        separator="-"
        :num-inputs="6"
        @on-change="handleOnChange"
        @on-complete="handleOnComplete"
        :input-type="'number'"
        class="otp"
        :class="otpValidator === null ? '' : otpValidator ? 'valid' : 'not-valid'"
    />
    <div v-if="otpValidator !== null && !otpValidator" class="phone-error" style="color:red;">Incorrect code</div>
  </div>
</template>

<script>
// import LocalStorageService from "../../services/LocalStorageService";
//
// // LocalstorageService
// const localStorageService = LocalStorageService.getService();

export default {
  name: "OtpCodeInvite",
  props:['otpVerify', 'otpCode', 'otpValid'],
  data() {
    return {
      otpValidator: this.otpVerify,
    }
  },
  methods: {
    handleOnComplete(value) {
      if(value === null) {
        return
      }
      value ? this.otpValidator = true : this.otpValidator = false
      this.$load(async () => {
        await this.$api.auth.otpSendInvited({code: value}).then(() => {
          this.otpValidator = true
          this.$emit('update:otpValid', this.otpValidator)
        })
      }, (error) => {
        this.otpValidator = false
        this.$emit('update:otpValid', this.otpValidator)
        this.errorMessage = error.data.errors.message[0]
        // this.$refs.alert.showAlert()
      })
    },
    handleOnChange() {
      this.$refs.otpInput.$children.map((item) => {

        if(item.$el.firstChild.value.length) {
          item.$el.firstChild.classList.add('not-empty');
        }else{
          item.$el.firstChild.classList.remove('not-empty');
        }
      })
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    handleFocusInput() {
      this.$refs.otpInput.handleOnFocus(-1);
    },
    handleUndisabledInput() {
      this.$refs.otpInput.$children.map((item) => {
        item.$el.firstChild.disabled = false;
      })
    },
  },
  mounted() {
    this.$refs.otpInput.$children.map((item, index) => {
      item.$el.firstChild.id = `register_phone-verify__otp_input_${index}`
      item.$el.firstChild.disabled = true;
    })
  },
}
</script>

<style>

.otp-wrapper {
  margin-bottom: 40px;
}

.otp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.otp-input {
  width: 51px;
  height: 71px;
  margin-right: 18px;
  background: #F8FCFC;
  box-shadow: 0px 2px 7px rgb(0 0 0 / 10%);
  border-radius: 2px;
  text-align: center;
  border: 1px solid transparent;
  padding: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: var(--black-color)
}

.otp-input:last-child {
  margin-right: 0;
}

.otp-input ~ span {
  display: none;
}

.otp-input .error {
  border: 1px solid red !important;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: block;
  margin-bottom: 10px;
  color: var(--secondary-black-color);
}

.otp-input:focus-visible {
  outline: none;
}

.otp.not-valid .otp-input {
  border: 1px solid var(--alert-color);
  color: var(--alert-color);
}


.otp.valid .otp-input {
  border: 1px solid var(--accent-color);
  color: var(--accent-color);
}

.otp-input.not-empty {
  border: 1px solid var(--accent-color);
  color: var(--accent-color);
}


</style>
