<template>
  <div
    v-if="invoices && invoices.length"
    class="history-table"
  >
    <TableHeader>
      <table cellpadding="0" cellspacing="0" border="0">
        <thead>
        <tr>
          <th>
            <div class="table-header-box">
              <span>Status</span>
            </div>
          </th>
          <th>
            <div class="table-header-box">
              <span>transaction Id</span>
            </div>
          </th>
          <th>
            <div class="table-header-box">
              <span>Last payment</span>
            </div>
          </th>
          <th>
            <div class="table-header-box">
              <span>Price</span>
            </div>
          </th>
          <th>
            <div class="table-header-box">
              <span>cases</span>
            </div>
          </th>
<!--          <th>-->
<!--          </th>-->
        </tr>
        </thead>
      </table>
    </TableHeader>
    <div class="table-body" :loadFlag="loadFlag">
      <table cellpadding="0" cellspacing="0" border="0">
        <tbody>
        <tr
            v-for="(item, index) in invoices"
            :key="index">
          <td>
            <div class="table-body-box">
                      <span
                          class="btn archived-plan-btn"
                          :class="{approved: item.paymentStatus === 'approved'}"
                      >{{item.paymentStatus === 'approved' ? 'paid' : item.paymentStatus}}</span>
            </div>
          </td>
          <td>
            <div class="table-body-box">
              <!--                      <span class="data-card">**** **** **** 9102</span>-->
              <span class="data-card">{{item.transactionId}}</span>
            </div>
          </td>
          <td>
            <div class="table-body-box">
              <span class='data'>{{parseDate(item.createdAt)}}</span>
            </div>
          </td>
          <td>
            <div class="table-body-box">
              <span class='sum'>$ {{item.amount}}</span>
            </div>
          </td>
          <td>
            <div class="table-body-box">
              <span class='data'>{{item.caseName}}</span>
            </div>
          </td>
<!--          <td>-->
<!--            <div class="table-body-box">-->
<!--              <router-link :to="{name: 'PrintInvoice'}" target= '_blank'-->
<!--                           tag="a"-->
<!--                           :id="`link-invoice_0`"-->
<!--                           class='link-invoice'>-->
<!--                  <span>View invoice-->
<!--                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M12.6908 0.411067C12.4476 0.299006 12.161 0.342613 11.962 0.52188L10.9123 1.45131C10.8838 1.47673 10.8407 1.47657 10.8123 1.45101L9.38407 0.173594C9.12576 -0.0578648 8.7347 -0.0578648 8.47639 0.173594L7.04944 1.44984C7.02073 1.47548 6.97735 1.47548 6.94864 1.44984L5.52154 0.173594C5.26323 -0.057827 4.87217 -0.057827 4.61386 0.173594L3.18543 1.45101C3.15684 1.47657 3.11364 1.47669 3.0849 1.45127L2.03426 0.52188C1.75552 0.274007 1.32861 0.299006 1.08074 0.57774C0.969583 0.702736 0.908768 0.864531 0.910054 1.03177V14.9683C0.907558 15.3419 1.20838 15.6467 1.58197 15.6492C1.74932 15.6504 1.91112 15.5894 2.03615 15.4782L3.08585 14.5487C3.1144 14.5233 3.15748 14.5235 3.18588 14.549L4.61412 15.8264C4.87243 16.0579 5.26349 16.0579 5.5218 15.8264L6.94891 14.5502C6.97761 14.5246 7.021 14.5246 7.0497 14.5502L8.47681 15.8264C8.73515 16.0578 9.12618 16.0578 9.38449 15.8264L10.813 14.549C10.8416 14.5234 10.8848 14.5233 10.9135 14.5487L11.9642 15.4781C12.243 15.7259 12.6699 15.7007 12.9176 15.4218C13.0286 15.297 13.0894 15.1354 13.0881 14.9683V1.03177C13.0917 0.764042 12.9354 0.519913 12.6908 0.411067ZM3.41401 5.42825H7.18037C7.34746 5.42825 7.48293 5.56372 7.48293 5.73081C7.48293 5.8979 7.34746 6.03337 7.18037 6.03337H3.41401C3.24692 6.03337 3.11145 5.8979 3.11145 5.73081C3.11145 5.56372 3.24692 5.42825 3.41401 5.42825ZM10.5842 10.5718H3.41401C3.24692 10.5718 3.11145 10.4363 3.11145 10.2692C3.11145 10.1021 3.24692 9.96667 3.41401 9.96667H10.5842C10.7513 9.96667 10.8867 10.1021 10.8867 10.2692C10.8867 10.4363 10.7513 10.5718 10.5842 10.5718ZM10.5842 8.30258H3.41401C3.24692 8.30258 3.11145 8.16711 3.11145 8.00002C3.11145 7.83293 3.24692 7.69746 3.41401 7.69746H10.5842C10.7513 7.69746 10.8867 7.83293 10.8867 8.00002C10.8867 8.16711 10.7513 8.30258 10.5842 8.30258Z" fill="#0FC7D2"/>-->
<!--                    </svg>-->
<!--                  </span>-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </td>-->
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div v-else class="no-data">
    <span>Your payment history is empty</span>
  </div>
</template>

<script>
import TableHeader from "../table/TableHeader";
import parseDate from "../../mixins/parseDate";

export default {
name: "BillingHistory",
  data() {
    return {
      loadFlag: false,
      ifLastPayment: true,
      show: false,
      errorMessage: "",
      invoices: []
    }
  },
  components: {
    TableHeader,
  },
  mixins: [parseDate],
  methods: {
    getInvoices() {
      this.$load(async() => {
        this.loadFlag = true
        await this.$api.invoices.getPaymentInfo().then((res) => {
          this.invoices = res.data.body.content
        }, (error) => {
          if(error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          }else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
        setTimeout(() => {
          this.loadFlag = false
        }, 4000)
      })
    }
  },
  mounted() {
    this.getInvoices()
    document.title = 'Billing'
    this.show = true
  }
}
</script>

<style scoped>
h1, h2, h3, h4, h5 {
  margin: 0;
}

.dashboard-section h2 {
  margin-top: 22px;
  margin-bottom: 22px;
}

.holder-cards {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 47px;
}

.archived-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.archived-title span {
  font-weight: 500;
  font-size: 12px;
  margin-right: 25px;
}

.holder-cards h5 {
  font-weight: 700;
  font-size: 18px;
}

.last-payment-text {
  font-size: 10px;
  font-weight: 400;
  color: var(--light-gray);
  text-transform: uppercase;
}

.archived-plan-box {
  background: var(--white-color);
  border-radius: 10px;
  padding: 20px 15px;
  width: 302px;
  margin-right: 44px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 4%), 0 6px 6px rgb(0 0 0 / 8%)
}

.archived-plan-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
}

.archived-plan-title span {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  color: var(--light-gray);
}

.archived-plan-btn {
  min-width: 68px;
  padding: 5px 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
  background: #FFC107;
  display: inline-block;
}

.archived-plan-btn.approved {
  background: var(--accent-color);
}

.archived-plan-btn:hover {
  color: #FFFFFF;
  background: #FFC107;
}

.archived-plan-btn.approved:hover {
  color: #FFFFFF;
  background: var(--accent-color);
}

.archived-plan-content {
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.archived-plan-footer h3 {
  text-transform: uppercase;
  color: var(--background-button-disabled-color);
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 11px;
}

.archived-plan-data {
  display: flex;
  justify-content: space-between;
}

.data {
  color: var(--secondary-text-color);
  font-size: 12px;
  font-weight: 400;
}

.sum {
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  color: var(--accent-color);
  margin-bottom: 9px;
  margin: 0;
}

.link-invoice {
  font-weight: 500;
  font-size: 14px;
  color: var(--secondary-black-color);
  text-decoration: none;
  transition: all .3s;
}

.link-invoice:hover {
  color: var(--accent-color);
  text-decoration: underline;
}

.link-invoice span {
  display: flex;
  align-items: center;
}

.link-invoice svg {
  margin-left: 12px;
}

.data-card {
  font-size: 12px;
  font-weight: 400;
  color: var(--secondary-text-color);
}

.history-header {
  display: flex;
  justify-content: flex-start;
  padding: 24px 0;
  background: #F8FCFC;
  margin-bottom: 49px;
  width: 100%;
  font-size: 10px;
  font-weight: 400;
  color: var(--light-gray);
  text-transform: uppercase;
}

.history-body {
  display: flex;
  align-items: center;
  padding: 22px 0;
  transition: all .3s;
}
.history-body:hover {
  box-shadow: 0px 4px 39px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.history-body div, .history-header div {
  display: flex;
  justify-content: center;
}

.status {
  width: 10%;
}
.card {
  width: 15%;
}
.last-payment {
  width: 15%;
}
.price {
  width: 20%;
}
.cases {
  width: 20%;
}
.invoice {
  width: 20%;
}

.accent-link {
  color: var(--accent-color);
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
}

.table-header {
  background: var(--active-accent-color);
}

.no-data {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 100px;
}
</style>