<template>
  <div class="assign-success-block">
    <div class="assign-image">
      <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M52.0025 5.77637C42.8606 5.77637 33.924 8.48725 26.3228 13.5662C18.7216 18.6452 12.7972 25.8641 9.29875 34.3101C5.8003 42.7561 4.88495 52.0499 6.66844 61.0161C8.45194 69.9823 12.8542 78.2183 19.3185 84.6826C25.7828 91.1469 34.0188 95.5492 42.985 97.3327C51.9512 99.1162 61.245 98.2008 69.691 94.7024C78.137 91.2039 85.3559 85.2795 90.4349 77.6783C95.5139 70.0771 98.2247 61.1405 98.2247 51.9986C98.2247 39.7397 93.3549 27.9829 84.6866 19.3145C76.0182 10.6462 64.2614 5.77637 52.0025 5.77637ZM82.1914 36.4853L44.2314 74.4164L21.8136 51.9986C21.0474 51.2324 20.617 50.1932 20.617 49.1097C20.617 48.0262 21.0474 46.987 21.8136 46.2208C22.5798 45.4546 23.619 45.0242 24.7025 45.0242C25.7861 45.0242 26.8252 45.4546 27.5914 46.2208L44.2892 62.9186L76.4714 30.7653C76.8508 30.3859 77.3012 30.0849 77.7968 29.8796C78.2925 29.6743 78.8238 29.5686 79.3603 29.5686C79.8968 29.5686 80.4281 29.6743 80.9238 29.8796C81.4194 30.0849 81.8698 30.3859 82.2492 30.7653C82.6286 31.1446 82.9295 31.595 83.1348 32.0907C83.3401 32.5864 83.4458 33.1176 83.4458 33.6541C83.4458 34.1907 83.3401 34.7219 83.1348 35.2176C82.9295 35.7133 82.6286 36.1637 82.2492 36.543L82.1914 36.4853Z"
            fill="#082655"/>
      </svg>
    </div>
    <h2 class="assign-success-title">Success!</h2>
    <div class="assigned-person">
      <div class="person-avatar">
        <svg v-if="!caseManager.avatar" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle data-v-341a6cc2="" cx="17.5" cy="18" r="17.5" fill="#F8FCFC"></circle>
          <path data-v-341a6cc2=""
                d="M17.5 6.5C13.9236 6.5 11 9.42356 11 13C11 16.5764 13.9236 19.5 17.5 19.5C21.0764 19.5 24 16.5764 24 13C24 9.42356 21.0764 6.5 17.5 6.5Z"
                fill="#E6E7E9"></path>
          <path data-v-341a6cc2=""
                d="M29.9466 24.8139C29.7566 24.3355 29.5033 23.889 29.2183 23.4744C27.7616 21.3057 25.5134 19.8705 22.9802 19.5197C22.6635 19.4878 22.3152 19.5516 22.0618 19.7429C20.7319 20.7316 19.1486 21.2419 17.502 21.2419C15.8553 21.2419 14.272 20.7316 12.9421 19.7429C12.6887 19.5516 12.3404 19.4559 12.0238 19.5197C9.4905 19.8705 7.21059 21.3057 5.78565 23.4744C5.50066 23.889 5.24731 24.3674 5.05735 24.8139C4.96238 25.0053 4.99402 25.2285 5.08899 25.4199C5.34234 25.8664 5.65897 26.3129 5.94396 26.6956C6.38726 27.3016 6.86226 27.8437 7.4006 28.354C7.84391 28.8005 8.35055 29.2151 8.85725 29.6297C11.3588 31.5114 14.3671 32.5 17.4703 32.5C20.5736 32.5 23.5818 31.5113 26.0834 29.6297C26.59 29.247 27.0967 28.8005 27.54 28.354C28.0467 27.8437 28.5533 27.3015 28.9967 26.6956C29.3133 26.281 29.5984 25.8664 29.8517 25.4199C30.0099 25.2285 30.0415 25.0052 29.9466 24.8139Z"
                fill="#E6E7E9"></path>
        </svg>
        <img v-else
            :src="caseManager.avatar"
            alt="user-avatar">
      </div>
      <span class="person-name">{{ caseManager.firstName }} {{caseManager.lastName}}</span>
    </div>
    <p class="assigned-success-message">was assigned as Case manager in <span class="span-case-success">Case {{caseTitle}}</span>
<!--      <a href="#" class="message-case">{{ caseTitle }}</a>-->
    </p>
  </div>
</template>

<script>
export default {
  name: "AssignSuccessBlock",
  props: {
    caseManager: {
      type: Object,
    },
    caseTitle: {
      type: String,
    },
    caseId: {
      type: String,
    }
  }
}
</script>

<style scoped>

.assign-success-block {
  padding: 23px 0 30px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.span-case-success {
  font-weight: 600;
  font-size: 16px;
  color: var(--accent-color);
}

.assign-image {
  width: 104px;
  height: 104px;
}

.assign-image svg {
  width: 100%;
  height: 100%;
}

.assign-success-title {
  margin: 25px 0 0 0;
  font-size: 30px;
  font-weight: 700;
  color: var(--secondary-black-color);
}

.assigned-person {
  margin: 27px 0 0 0;
  display: flex;
  align-items: center;
}

.person-avatar {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: initial;
  border-radius: 50%;
  overflow: hidden;
}

.person-avatar img {
  object-fit: cover;
}

.person-name {
  margin: 0 0 0 10px;
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: var(--gray-color);
}

.assigned-success-message {
  margin: 10px 0 0 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--secondary-text-color);
}

.message-case {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: var(--accent-color);
  text-decoration: underline;
}
</style>