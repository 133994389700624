<template>
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M7 7.5C9.20938 7.5 11 5.82129 11 3.75C11 1.67871 9.20938 0 7 0C4.79063 0 3 1.67871 3 3.75C3 5.82129 4.79063 7.5 7 7.5ZM9.8 8.4375H9.27812C8.58437 8.73633 7.8125 8.90625 7 8.90625C6.1875 8.90625 5.41875 8.73633 4.72188 8.4375H4.2C1.88125 8.4375 0 10.2012 0 12.375V13.5938C0 14.3701 0.671875 15 1.5 15H12.5C13.3281 15 14 14.3701 14 13.5938V12.375C14 10.2012 12.1187 8.4375 9.8 8.4375Z"
        fill="#D7DCE6"/>
  </svg>
</template>

<script>
export default {
  name: "UserIcon"
}
</script>

<style scoped>

</style>