import { authHeader } from "@/helpers";

export default function(instance) {
    return {
        createCase(data) {
            return fetch(`${process.env.VUE_APP_API}/api/v1/cases/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authHeader(),
                },
                body: JSON.stringify(data)
            })
        },
        getCases({caseName, page, size}) {
            return instance.get(`/cases?caseName=${caseName}&page=${page}&size=${size}`,)
        },
        getCasesInvite() {
            return instance.get(`/cases/assign`,)
        },
        getCaseStatistic(caseId) {
            return instance.get(`/cases/${caseId}/statistic`,)
        },
        deleteCase(caseId) {
          return instance.delete(`/cases/${caseId}`)
        },
        assignUserCase(data) {
            return instance.post(`/caseUser/assign`, data)
        },
        getCasesUnemployed({caseId, sortBy, order, keySearch, page, size}) {
            return instance.get(`/caseUser/${caseId}/unemployed?sortBy=${sortBy}&order=${order}&keySearch=${keySearch}&page=${page}&size=${size}`,)
        },
        getCasesManager({caseId}) {
            return instance.get(`/casesManager/${caseId}/manager`,)
        },
        getCasesManagers({caseId, sortBy, order, roles, keySearch, page, size}) {
            return instance.get(`/casesManager/${caseId}/managers?sortBy=${sortBy}&order=${order}&roles=${roles}&keySearch=${keySearch}&page=${page}&size=${size}`,)
        },
        setCasesManager(caseId,  params) {
            return instance.post(`/casesManager/${caseId}/manager`, null,  {params})
        },
        getCasesFiles({caseId, sortBy, keySearch, size, page, order, dateFrom, dateTo}) {
            return instance.get(`/caseFile/${caseId}/files?sortBy=${sortBy}&keySearch=${keySearch}&page=${page}&order=${order}&size=${size}&dateFrom=${dateFrom}&dateTo=${dateTo}`,)
        },
        downloadFile({caseId, fileId}) {
            return window.$axiosFiles.get(`/caseFile/${caseId}/files/${fileId}`, {
                headers: {
                    'Content-Type': 'application/octet-stream'
                }
            })
        },
        downloadAttachment(caseId, attachmentId) {
            return window.$axiosFiles.get(`/attachment/${caseId}/attachment/${attachmentId}`, {
                responseType: 'blob'
            });
        },
        getCasesEmployees({caseId, sortBy, keySearch, order, page, size}) {
            return instance.get(`/cases/${caseId}/employees?sortBy=${sortBy}&keySearch=${keySearch}&order=${order}&page=${page}&size=${size}`,{
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        },
        getCasesClients(caseId, params) {
            return instance.get(`/cases/${caseId}/clients`, { params });
        },
        getCasesUsers({caseId, keySearch, roles, sortBy, order }) {
            return instance.get(`/caseUser/${caseId}/users?searchKey=${keySearch}&roles=${roles}&sortBy=${sortBy}&order=${order}`,)
        },
        deleteCasesUser(caseId, userId) {
            return instance.delete(`/caseUser/${caseId}/users?userPublicIds=${userId}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        },
        reInviteClientToCase(body) {
            return instance.post('/cases/client/reinvite', body);
        },
        getCasesActivity({caseId}) {
            return instance.get(`/cases/${caseId}/activity`,)
        },
        getCaseActionPermission({caseId, sortBy, keySearch, order, dateFrom, dateTo, page, size}) {
            return instance.get(`/actions/${caseId}/action/permission?sortBy=${sortBy}&keySearch=${keySearch}&order=${order}&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}`)
        },
        getCaseActionTeam({caseId, sortBy, keySearch, order, dateFrom, dateTo, page, size}) {
            return instance.get(`/actions/${caseId}/action/team?sortBy=${sortBy}&keySearch=${keySearch}&order=${order}&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}`)
        },
        getCaseActionClients({caseId, sortBy, keySearch, order, dateFrom, dateTo, page, size}) {
            return instance.get(`/actions/${caseId}/action/clients?sortBy=${sortBy}&keySearch=${keySearch}&order=${order}&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}`)
        },
        getCaseActionFiles({caseId, sortBy, keySearch, order, dateFrom, dateTo, page, size}) {
            return instance.get(`/actions/${caseId}/action/files?sortBy=${sortBy}&keySearch=${keySearch}&order=${order}&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}`)
        },

        getCaseMessages({caseId, clientId, messengers, keySearch, sortBy, type, dateFrom, dateTo}) {
            return instance.get(`/chat/${caseId}/chats/v2?sortBy=${sortBy}&messengers=${messengers}&keySearch=${keySearch}&type=${type}&dateFrom=${dateFrom}&dateTo=${dateTo}&clientId=${clientId}`)
        },
        getUsersInMessages({caseId}) {
            return instance.get(`/chat/${caseId}/chat/users`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        },
        getDeviceAliases(caseId, clientId) {
            return instance.get(`/messages/${caseId}/alias/client/${clientId}`)
        },
        saveFilterPattern(caseId, params) {
            return instance.post(`/settings/search/case/${caseId}/filter`, params)
        },
        getFilterPatterns(caseId) {
            return instance.get(`settings/search/case/${caseId}/filter`)
        },
        getFilterPattern(caseId, filterId) {
            return instance.get(`settings/search/case/${caseId}/filter/${filterId}`)
        },
        deleteFilterPattern(caseId, filterId) {
          return instance.delete(`/settings/search/case/${caseId}/filter/${filterId}`)
        },
        createDeviceAlias(caseId, clientId, alias) {
            return instance.post(`/messages/${caseId}/alias/client/${clientId}`, alias)
        },
        updateAlias(caseId, alias) {
            return instance.put(`/messages/${caseId}/alias/${alias.id}/${alias.type}`, alias)
        },
        deleteDeviceAlias(caseId, aliasId, type) {
            return instance.delete(`/messages/${caseId}/alias/${aliasId}/${type}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        },
        getSimsList(caseId, clientId) {
            return instance.get(`/messages/${caseId}/chat/client/${clientId}/sim`)
        },
        getImeiList(caseId, clientId) {
            return instance.get(`/messages/${caseId}/chat/client/${clientId}/imei`)
        },
        getMessengersInMessages(caseId,  clientId) {
            return instance.get(`/messages/${caseId}/chat/messengers`,  {clientId})
        },
        getCaseChatMessages({caseId, keySearch, page, size, chatId}) {
            return instance.get(`/messages/${caseId}/chat/${chatId}/messages?page=${page}&keySearch=${keySearch}&size=${size}`)
        },
        getMessagesChat({caseId, chatId, page, size}) {
            return instance.get(`/messages/${caseId}/chat/${chatId}/messages?page=${page}&size=${size}`)
        },
        getMessagesMerged({caseId, clientId, messengers, page, size, dateFrom, dateTo}) {
            return instance.get(`/messages/${caseId}/chat/messages/${clientId}?messengers=${messengers}&page=${page}&size=${size}&dateFrom=${dateFrom}&dateTo=${dateTo}`)
        },
        getCaseClients({caseId}) {
            return instance.get(`/cases/${caseId}/clients`)
        },
        sendAdvancedFilterParams(caseId, data, page, size, count) {
            return instance.post(`/messages/${caseId}/messages/advanced?page=${page}&size=${size}&count=${count}`, data)
        },
        createHighlightForMessage(caseId, messageId, color) {
            color = color.replace('#', '')

            return instance.post(`/messages/${caseId}/message/${messageId}/highlight?color=${color}`, null , {
              headers: { 'Content-Type': 'application/json' }
            })
        },
        deleteHighlightForMessage(caseId, messageId) {
            return instance.delete(`/messages/${caseId}/message/${messageId}/highlight`)
        },
        editMessage(caseId, messageId, message) {
            return instance.put(`/messages/${caseId}/message/${messageId}`, { message })
        },
        createCommentForMessage(caseId, messageId, text) {
            return instance.post(`/messages/${caseId}/message/${messageId}/comment`, { text } , {
                headers: { 'Content-Type': 'application/json' }
            })
        },
        getCommentsForMessage(caseId, messageId) {
            return instance.get(`/messages/${caseId}/message/${messageId}/comments`);
        },
        getFilterRules(caseId, clientId) {
            return instance.get(`/settings/search/case/${caseId}/client/${clientId}`);
        },
        getExportCases({caseId, page, size}) {
            return instance.get(`/cases/${caseId}/export?page=${page}&size=${size}`)
        },
        getMetaInfo(params) {
            return instance.get(`/messages/${params.caseId}/get/message/${params.messageId}/metainfo`)
        },
        getExportCasesDownload({caseId, exportId}) {
            return window.$axiosFiles.get(`/cases/${caseId}/export/${exportId}/download`, {}, {
                headers: {
                    'Content-Type': 'application/octet-stream'
                }
            })
        },
        exportCases(caseId, format) {
            return fetch(`${process.env.VUE_APP_API}/api/v1/cases/${caseId}/export?format=${format}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authHeader(),
                },
                body: {},
            })
        },
        exportMessages(caseId, format, data) {
            return fetch(`${process.env.VUE_APP_API}/api/v1/cases/${caseId}/export/messages?format=${format}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authHeader(),
                },
                body: JSON.stringify(data)
            })
        },
        importMessages(caseId, data) {
            return fetch(`${process.env.VUE_APP_API}/api/v1/chat/import/${caseId}`, {
                method: 'POST',
                headers: {
                    'Authorization': authHeader(),
                },
                body: data
            })
        },
        importUfdr(caseId, data) {
            return fetch(`${process.env.VUE_APP_API}/api/v1/chat/import/case/${caseId}/ufdr`, {
                method: 'POST',
                headers: {
                    'Authorization': authHeader(),
                },
                body: data
            })
        },
        getSubscriptionsInfo(caseId) {
            return instance.get(`/payment/${caseId}/subscriptions/info`)
        },
        updateSubscriptionsInfo(caseId) {
            return instance.put(`/payment/${caseId}/subscriptions/info`)
        },
        getRegionsToCase(type) {
            return instance.get(`/rds/regions/${type}`)
        },
        removeCaseEmail(caseId, emailId) {
            return instance.delete(`/caseEmail/case/${caseId}/email/${emailId}`)
        },
        changePasswordCaseEmail(emailId, newPassword) {
            return instance.put(`/caseEmail/${emailId}`, {newPassword})
        },
        getCaseEmail(caseId, keySearch) {
            return instance.get(`/caseEmail/case/${caseId}/emails?&keySearch=${keySearch}`)
        },
        createCaseEmail(caseId, params) {
            return instance.post(`/caseEmail/case/${caseId}/email`, params)
        },
        getCaseEmailFolders(caseId, emailId, keySearch) {
            return instance.get(`/caseEmail/case/${caseId}/email/${emailId}/folders?&keySearch=${keySearch}`)
        },
        getCaseEmailMessages(caseId, emailId, page, size, params) {
            return instance.post(`/caseEmail/case/${caseId}/email/${emailId}/messages?page=${page}&size=${size}`, params)
        },
        importEmailFromCase(caseId, emailId, body) {
            return instance.post(`/caseEmail/case/${caseId}/email/${emailId}/upload`, body);
        },
        getCaseEmailSpecificMessage(caseId, emailId, messageId) {
            return instance.get(`/caseEmail/case/${caseId}/email/${emailId}/messages/${messageId}`)
        },
        getCaseMessagesEmailsAdvanced(caseId, data, params) {
            return instance.post(`/emails/case/${caseId}/emails/advanced`, data, {params});
        },
        getCaseMessagesEmailList(caseId) {
            return instance.get(`/emails/case/${caseId}/emails`);
        },
        getCaseMessagesEmailSpecificMail(caseId, emailId) {
            return instance.get(`/emails/case/${caseId}/email/${emailId}`);
        },
        getCommentListForEmail(caseId, emailId) {
            return instance.get(`/emails/case/${caseId}/email/${emailId}/comments`);
        },
        createCommentForEmail(caseId, emailId, body) {
            return instance.post(`/emails/case/${caseId}/email/${emailId}/comment`, body);
        },
        deleteCommentForEmail(caseId, commentId) {
            return instance.delete(`/api/v1/emails/case/${caseId}/comment/${commentId}`);
        },
        changeCommentForEmail(caseId, commentId) {
            return instance.put(`/emails/case/${caseId}/comment/${commentId}`);
        },
        getEmailMetaInfo(caseId, emailId) {
            return instance.get(`/emails/case/${caseId}/email/${emailId}/metainfo`);
        },
        exportEmailMessage(caseId, emailId, params) {
            return instance.get(`/cases/${caseId}/export/email/${emailId}`, { params });
        },
        getClientApps(caseId, publicId) {
            return instance.get(`/apps/case/${caseId}/client/${publicId}/apps`);
        },
        getGalleryListFiles(caseId, clientId, params) {
            return instance.get(`/gallery/case/${caseId}/user/${clientId}`, { params });
        },
        exportGallery(caseId, format, idsList) {
            return instance.post(`/cases/${caseId}/export/gallery?format=${format}`, idsList, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        },
        downloadEmailsAttachment({ caseId, attachmentId }) {
            return window.$axiosFiles.get(`/attachment/${caseId}/email/attachment/${attachmentId}`, {
                responseType: 'blob'
            })
        },
        getFilterEmailRules(caseId) {
            return instance.get(`/settings/search/case/${caseId}/email`);
        },
    }

}

