<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>

body {
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
}


</style>
