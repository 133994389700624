<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M9.4092 7.51465C9.04323 7.81854 8.45823 8.04257 8.00017 8.04257C7.54212 8.04257 6.95698 7.81895 6.59101 7.51507C5.97656 7.88104 5.6199 8.53326 5.57031 9.42215H10.43C10.3805 8.53326 10.0238 7.88076 9.4092 7.51465Z"
        fill="#DDE3E3"/>
    <path
        d="M8.00052 6.85858C8.43594 6.86053 8.82955 6.59969 8.99761 6.19803C9.16553 5.79622 9.07469 5.33289 8.76747 5.02428C8.46025 4.71567 7.99719 4.62275 7.59469 4.789C7.19233 4.95511 6.92969 5.34761 6.92969 5.78303C6.92899 6.37553 7.40802 6.85678 8.00052 6.85858Z"
        fill="#DDE3E3"/>
    <path
        d="M6.5277 7.06322C6.61117 7.02127 6.71228 7.03849 6.77714 7.10572C7.0427 7.3821 7.60298 7.61544 8.00048 7.61544C8.39798 7.61544 8.95798 7.38169 9.22353 7.1053C9.28853 7.03808 9.38964 7.02113 9.47298 7.06335C10.303 7.47933 10.8003 8.31988 10.8574 9.4221H10.9514V2.87988H5.01367V9.4221H5.14326C5.20048 8.31988 5.6977 7.47919 6.5277 7.06322ZM8.00034 4.28072C8.60839 4.27891 9.15756 4.64363 9.39159 5.20488C9.62562 5.76599 9.49826 6.41294 9.06895 6.84349C8.63978 7.27419 7.99326 7.40349 7.43131 7.17127C6.86937 6.93905 6.50284 6.39099 6.50284 5.78294C6.50242 4.95502 7.17228 4.28294 8.00034 4.28072Z"
        fill="#DDE3E3"/>
    <path
        d="M6.16548 1.20867H9.83493C9.95048 1.20867 10.0483 1.13617 10.0483 1.04867C10.0483 0.961172 9.95048 0.888672 9.83493 0.888672H6.16548C6.04993 0.888672 5.95215 0.961172 5.95215 1.04867C5.95215 1.13617 6.04993 1.20867 6.16548 1.20867Z"
        fill="#DDE3E3"/>
    <path
        d="M12.4802 0H3.52024C3.24538 0 3.02246 0.222917 3.02246 0.497778V15.5022C3.02246 15.7771 3.24538 16 3.52024 16H12.4802C12.7551 16 12.978 15.7771 12.978 15.5022V0.497778C12.978 0.222917 12.7551 0 12.4802 0ZM6.16552 0.462222H9.83496C10.1877 0.462222 10.475 0.724583 10.475 1.04889C10.475 1.37319 10.1877 1.63556 9.83496 1.63556H6.16552C5.81274 1.63556 5.52552 1.37319 5.52552 1.04889C5.52552 0.724583 5.81274 0.462222 6.16552 0.462222ZM4.58691 2.80889C4.58691 2.6125 4.74607 2.45333 4.94246 2.45333H11.0225C11.2189 2.45333 11.378 2.6125 11.378 2.80889V9.49333C11.378 9.68972 11.2189 9.84889 11.0225 9.84889H4.94246C4.74607 9.84889 4.58691 9.68972 4.58691 9.49333V2.80889ZM4.90691 11.52C4.78913 11.52 4.69357 11.4244 4.69357 11.3067C4.69357 11.1889 4.78913 11.0933 4.90691 11.0933H11.058C11.1758 11.0933 11.2714 11.1889 11.2714 11.3067C11.2714 11.4244 11.1758 11.52 11.058 11.52H4.90691ZM10.738 12.7644C10.738 12.8822 10.6425 12.9778 10.5247 12.9778H5.44024C5.32246 12.9778 5.22691 12.8822 5.22691 12.7644C5.22691 12.6467 5.32246 12.5511 5.44024 12.5511H10.5247C10.6425 12.5511 10.738 12.6467 10.738 12.7644ZM11.5914 14.4H4.37357C4.25579 14.4 4.16024 14.3044 4.16024 14.1867C4.16024 14.0689 4.25579 13.9733 4.37357 13.9733H11.5914C11.7091 13.9733 11.8047 14.0689 11.8047 14.1867C11.8047 14.3044 11.7091 14.4 11.5914 14.4Z"
        fill="#D7DCE6"/>
  </svg>
</template>

<script>
export default {
  name: "RoleIcon"
}
</script>

<style scoped>

</style>