<template>
  <div class="wrapper">
    <Sidebar/>
    <div class="content-wrapper">
      <HeaderRegistered/>
      <main class="main">
        <section class="dashboard-section">
          <CasesList/>
        </section>
      </main>
    </div>
  </div>
</template>

<script>

import Sidebar from "../components/Sidebar";
import HeaderRegistered from "../components/layout/HeaderRegistered";
import CasesList from "../components/сases/case/CasesList";

export default {
  name: "Cases",
  components: {
    HeaderRegistered,
    Sidebar,
    CasesList
  },
  mounted() {
    document.title = 'Cases'
  }
}
</script>

<style scoped>
</style>
