import capitalizeFirstLetter from "@/mixins/capitalizeFirstLetter";

function createDeactivateDialog(value) {
    return {
        dialogTitle:`Deactivate ${getValue(value)}`,
        dialogText: `Are you sure you want to deactivate ${value}?`,
        confirmBtnText: `Deactivate`,
        cancelBtnText: 'No'
    }
}

export function createActivateDialog(value) {
    return {
        dialogTitle:`Activate ${getValue(value)}`,
        dialogText: `Are you sure you want to activate ${value}?`,
        confirmBtnText: `Activate`,
        cancelBtnText: 'No'
    }
}

export function createDeleteDialog(value) {
    return {
        dialogTitle:`Delete ${getValue(value)}`,
        dialogText: `Are you sure you want to delete this ${value}?`,
        confirmBtnText: `Delete`,
        cancelBtnText: 'Cancel'
    }
}

export function createTagDeleteDialog(value) {
    return {
        dialogTitle:`Delete ${getValue(value)}`,
        dialogText: `Are you sure you want to delete tag ${value}?`,
        confirmBtnText: `Delete`,
        cancelBtnText: 'Cancel'
    }
}

export function createCategoryDeleteDialog(value) {
    return {
        dialogTitle:`Delete ${getValue(value)}`,
        dialogText: `Are you sure you want to delete category ${value}?`,
        confirmBtnText: `Delete`,
        cancelBtnText: 'Cancel'
    }
}

function getValue(value) {
    if (value === 'account') {
        return capitalizeFirstLetter.methods.capitalizeFirstLetter(value);
    } else {
        return value;
    }
}

export default createDeactivateDialog