<template>
  <div class="keys-list">
    <AlertErrorMsg
      :title="errorMessage"
      class="page-alert-error"
      ref="alert"
    />
    <div v-if="!$route.params.slug" class="keys-search-panel">
      <CustomSearch
        :modelValue="keySearch"
        id="keys_search_input"
        @update:modelValue="keySearch = $event"
        @filter="getKeysByFilter()"
        :classComponent="'case'"
        placeholder="Search by email"
        style="max-width: 280px"
      />
      <div class="keys-datepicker-wrapper">
        <span>Filter by usage period:</span>
        <date-picker
          v-model="dataRange"
          :id="`sort_by_date_keys`"
          range
          :format="'DD/MM/YYYY'"
          :range-separator="' - '"
          :editable="false"
          class="datepicker-input"
          :input-class="'datepicker-input'"
          placeholder="Select Date range"
        >
          <template #icon-calendar>
            <svg class="datepicker-icon" width="24" height="25" viewBox="0 0 24 25" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20.1452 11.4137C23.3452 13.3528 24.3653 17.5156 22.4238 20.7115C21.2063 22.7157 19.0372 23.948 16.6902 23.9688C14.5905 23.973 12.6114 22.9896 11.3483 21.3145C10.422 20.144 9.92486 18.6921 9.93972 17.2002C9.95437 13.487 12.9724 10.4846 16.6902 10.4846C17.9051 10.4744 19.0998 10.7955 20.1452 11.4137Z"
                fill="#082655" />
              <path
                d="M20.1445 8.22949V11.4147C19.0991 10.7966 17.9044 10.4754 16.6896 10.4857C12.9717 10.4856 9.95372 13.488 9.93907 17.2013C9.92422 18.6931 10.4213 20.1451 11.3477 21.3156H2.12549C1.24484 21.3156 0.530884 20.6025 0.530884 19.7229V8.22954H20.1445V8.22949Z"
                fill="white" />
              <path
                d="M20.1445 4.37973V8.22856H0.530884V4.37973C0.530884 3.50017 1.24484 2.78711 2.12549 2.78711H4.3048V4.43283H6.82962V2.78711H13.8193V4.43283H16.3441V2.78711H18.55C19.4306 2.78711 20.1445 3.50012 20.1445 4.37973Z"
                fill="#082655" />
              <path d="M16.3447 2.78757V4.43328H13.8198V2.78757V0.956055H16.3447V2.78757Z" fill="#082655" />
              <path d="M6.83018 2.78757V4.43328H4.30542V2.78757V0.956055H6.83018V2.78757Z" fill="#082655" />
              <path
                d="M11.3217 21.8718H2.12615C0.951924 21.8718 0 20.9211 0 19.7483V4.35299C0 3.18023 0.951924 2.22949 2.12615 2.22949H4.30545V3.29124H2.12615C1.53901 3.29124 1.06307 3.76658 1.06307 4.35299V19.7483C1.06307 20.3347 1.53901 20.81 2.12615 20.81H11.3217V21.8718Z"
                fill="#082655" />
              <path
                d="M20.7558 11.175V4.35327C20.7558 3.18534 19.7193 2.36248 18.5499 2.36248H16.7693V0.929186C16.8011 0.726418 16.6624 0.536301 16.4594 0.504496C16.4212 0.498501 16.3822 0.498501 16.3441 0.504496H13.8193C13.5269 0.504496 13.3143 0.637202 13.3143 0.929186V2.36253H7.46743V0.929186C7.46743 0.637202 7.12192 0.504496 6.82958 0.504496H4.30481C4.01247 0.504496 3.74672 0.637202 3.74672 0.929186V4.43293C3.75898 4.74021 3.99812 4.99038 4.30481 5.0169H6.82963C7.15306 4.99577 7.41829 4.75291 7.46748 4.43293V3.42428H13.3144V4.43293C13.2988 4.73127 13.5215 4.98886 13.8194 5.0169H16.3442C16.6365 5.0169 16.7694 4.72492 16.7694 4.43293V3.42428H18.55C19.1347 3.42428 19.6928 3.76935 19.6928 4.35332V7.67127H0.557449V8.73302H19.6928V10.5911C16.0518 8.91973 11.7437 10.5128 10.0703 14.1492C9.62624 15.1142 9.39997 16.1649 9.40755 17.227C9.43649 21.2317 12.6799 24.471 16.6896 24.4999C20.7113 24.5152 23.984 21.2714 23.9993 17.2547C24.0086 14.8128 22.7903 12.5293 20.7558 11.175ZM6.40436 3.95515H4.80975V1.56624H6.40436V3.95515ZM15.7062 3.95515H14.3774V1.56624H15.7062V3.95515ZM16.6896 23.4382C14.7517 23.4342 12.9255 22.532 11.7463 20.9962C9.662 18.2697 10.1854 14.3718 12.9154 12.2901C15.6453 10.2084 19.548 10.7312 21.6323 13.4577C23.7166 16.1843 23.1931 20.0821 20.4632 22.1638C19.3793 22.9904 18.0534 23.4381 16.6896 23.4382Z"
                fill="#082655" />
              <path
                d="M19.1884 18.2886H16.3447C16.0511 18.2886 15.8132 18.0509 15.8132 17.7577V14.0416C15.8132 13.7484 16.0511 13.5107 16.3447 13.5107C16.6383 13.5107 16.8762 13.7484 16.8762 14.0416V17.2269H19.1884C19.4669 17.2118 19.7049 17.4251 19.72 17.7032C19.7209 17.7214 19.7209 17.7395 19.72 17.7577C19.72 18.0509 19.482 18.2886 19.1884 18.2886Z"
                fill="white" />
            </svg>
          </template>
          <template #icon-clear>
            <svg class="datepicker-icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.0001 0.333496C18.4517 0.333496 23.6667 5.5485 23.6667 12.0002C23.6667 18.4518 18.4517 23.6668 12.0001 23.6668C5.54841 23.6668 0.333412 18.4518 0.333412 12.0002C0.333412 5.5485 5.54841 0.333496 12.0001 0.333496ZM6.16675 16.1885L7.81175 17.8335L12.0001 13.6452L16.1884 17.8335L17.8334 16.1885L13.6451 12.0002L17.8334 7.81183L16.1884 6.16683L12.0001 10.3552L7.81175 6.16683L6.16675 7.81183L10.3551 12.0002L6.16675 16.1885Z"
                fill="#082655" />
            </svg>
          </template>
        </date-picker>
      </div>
      <div class="keys-status-filter-wrapper">
        <span>Filter by status:</span>
        <CustomSelect
          v-model="selectedStatus"
          placeholder="Choose Status"
          :options="statusList"
          default="All"
          @input="getKeysByFilter"
          :customClass="'custom-select-sessions'"
        />
      </div>
    </div>
    <router-view
      :keysData="keysData"
      @sendKey="sendKey($event)"
    />
    <pagination v-if="keysData && keysData.length && totalPages !== 1"
                v-model="page"
                :total-pages="totalPages"
                :link-gen="linkGen"
                ref="pagination"
    ></pagination>
    <CommonDialog ref="successPopup">
      <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z" fill="#082655" />
      </svg>
      <h2 class="dialog-message-title">Success!</h2>
      <p class="dialog-message-description">The key was sent to custodian email</p>
    </CommonDialog>
  </div>
</template>
<script>
import checkPermission from "@/utils/permission";
import CustomSearch from "@/components/inputs/CustomSearch";
import DatePicker from "vue2-datepicker";
import CustomSelect from "@/components/inputs/CustomSelect.vue";
import AlertErrorMsg from "@/components/profile/AlertErrorMsg.vue";
import CommonDialog from "@/components/popups/CommonDialog.vue";
import Pagination from "@/components/common/Pagination.vue";

export default {
  name: "CustodianSupport",
  components: {
    Pagination,
    CommonDialog,
    AlertErrorMsg,
    CustomSelect,
    CustomSearch,
    DatePicker,
  },
  data() {
    return {
      casesFromApi: [],
      errorMessage: '',
      dataRange: '',
      keysData: [],
      loadFlag: false,
      keySearch: '',
      dateFrom: '',
      dateTo: '',
      selectedStatus: 'All',
      totalPages: 0,
      page: 1,
      size: 10,
      statusList: [
        {
          name: 'All',
          id: 0,
        },
        {
          name: 'Active',
          id: 1,
        },
        {
          name: 'Pending',
          id: 2,
        },
        {
          name: 'Expired',
          id: 3,
        }
      ],
    }
  },
  watch: {
    dataRange(value) {
      this.dateFrom = isNaN(Date.parse(value[0])) == false ? Date.parse(value[0]) : ''
      this.dateTo = isNaN(Date.parse(value[1])) == false ? Date.parse(value[1]) : ''
      this.getKeys();
    },
    keysData(val) {
      if (this.$route.params.slug && val.length > 0) {
        this.$emit('title', this.keysData[0].caseName)
      }
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.page = newVal
          this.getKeys();
        } else {
          this.page = 1
        }
      }
    },
  },
  methods: {
    checkPermission,
    linkGen(pageNum) {
      if (this.$route.params.slug || this.$route.params.case_id) {
        return {
          path: `/cases/${this.$route.params.slug}/management/keys`,
          query: {page: pageNum}
        }
      }
      return {
        path: `/management/keys`,
        query: {page: pageNum}
      }
    },
    fixedEncodeURIComponent(str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    },
    getKeysByFilter() {
      this.page = 1;
      this.getKeys();
      this.$refs.pagination.goToPage(this.page)
    },
    getKeys() {
      this.$load(async () => {
        await this.$api.management.getKeys(
          {
            page: this.page,
            size: this.size,
            keySearch: this.fixedEncodeURIComponent(this.keySearch),
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            status: this.selectedStatus,
            caseId: this.$route.params.slug ? this.$route.params.slug : null,
          })
          .then((res) => {
            this.keysData = res.data.body.content;
            this.totalPages = res.data.body.totalPages
          })
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
        }
      );
    },
    sendKey(keyId) {
      this.$load(async () => {
          await this.$api.management.sendKey(keyId)
            .then(() => {
              this.$refs.successPopup.openPopups();
            });
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
        }
      );
    }
  },
  mounted() {
    this.page = this.$route.query.page || 1;
    this.getKeys();
  },
}
</script>

<style scoped>

.keys-list {
  width: 100%;
}

.default-cases-image img {
  max-width: 100%;
  max-height: 100%;
}

.keys-search-panel {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  height: 60px;
  align-items: flex-end;
}

.datepicker-input {
  width: 220px;
}

.keys-datepicker-wrapper,
.keys-status-filter-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.keys-datepicker-wrapper span,
.keys-status-filter-wrapper span {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  color: var(--secondary-black-color);
}

</style>
