export default function(instance) {
  return {
    getClientCalls(caseId, clientId, params) {
      return instance.get(`/calls/case/${caseId}/client/${clientId}/calls`, { params })
    },
    getClientCallsAdvanced(caseId, clientId, data, page, size, count) {
      return instance.post(`/calls/case/${caseId}/client/${clientId}/calls/advanced?page=${page}&size=${size}&count=${count}`, data)
    },
    getFilterRules(caseId, clientId) {
      return instance.get(`/settings/search/case/${caseId}/client/${clientId}/calls`);
    },
  }
}