<template>
  <transition name="expand">
    <div v-if="visibleAlertMsg" ref="alert" class="holder-alert">
      <div class="alert-msg">
        <svg fill="none" height="36" viewBox="0 0 36 36" width="36" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0)">
            <path d="M17.2316 17.7096C17.2748 18.1905 17.3468 18.5486 17.4476 18.7837C17.5482 19.0186 17.7285 19.1365 17.988 19.1365C18.0367 19.1365 18.0804 19.1278 18.1238 19.119C18.1674 19.1278 18.2106 19.1365 18.2591 19.1365C18.5183 19.1365 18.6982 19.0186 18.7995 18.7837C18.8999 18.5486 18.9722 18.1905 19.0152 17.7096L19.2456 13.9968C19.2888 13.2731 19.3103 12.7535 19.3103 12.4385C19.3103 12.0094 19.206 11.6746 18.9977 11.4347C18.7885 11.1946 18.5136 11.0742 18.173 11.0742C18.1551 11.0742 18.1414 11.0786 18.1241 11.0794C18.1068 11.0786 18.0929 11.0742 18.0752 11.0742C17.7342 11.0742 17.4597 11.1945 17.2507 11.4347C17.042 11.6748 16.9377 12.0096 16.9377 12.4387C16.9377 12.7539 16.9595 13.2733 17.0024 13.997L17.2327 17.7096" fill="white" />
            <path d="M18.1302 20.4922C17.8004 20.4922 17.5188 20.6049 17.2855 20.8291C17.0526 21.0546 16.9359 21.3269 16.9359 21.6469C16.9359 22.0096 17.0538 22.2933 17.2893 22.4995C17.5254 22.7062 17.8004 22.8103 18.1141 22.8103C18.4341 22.8103 18.7129 22.7078 18.9513 22.504C19.1898 22.2998 19.308 22.0142 19.308 21.6467C19.308 21.3267 19.1939 21.0544 18.9661 20.8289C18.7386 20.6049 18.4598 20.4922 18.1295 20.4922" fill="white" />
          </g>
          <path d="M16.268 5C17.0378 3.66666 18.9622 3.66667 19.732 5L30.1244 23C30.8942 24.3333 29.9319 26 28.3923 26H7.60769C6.06809 26 5.10584 24.3333 5.87564 23L16.268 5Z" stroke="white" stroke-width="2" />
          <defs>
            <clipPath id="clip0">
              <rect fill="white" height="12.2927" transform="translate(12.2927 10.5371)" width="11.4146" />
            </clipPath>
          </defs>
        </svg>
        <p v-html="title"></p>
      </div>
      <div class="alert-close" @click="hideAlert">
        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 0.333984C5.54831 0.333984 0.333313 5.54898 0.333313 12.0007C0.333313 18.4523 5.54831 23.6673 12 23.6673C18.4516 23.6673 23.6666 18.4523 23.6666 12.0007C23.6666 5.54898 18.4516 0.333984 12 0.333984ZM17.8333 16.189L16.1883 17.834L12 13.6457L7.81165 17.834L6.16665 16.189L10.355 12.0007L6.16665 7.81232L7.81165 6.16732L12 10.3557L16.1883 6.16732L17.8333 7.81232L13.645 12.0007L17.8333 16.189Z" fill="white" fill-opacity="0.66" />
        </svg>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "AlertErrorMsg",
  props: {
    title: String,
  },
  data() {
    return {
      visibleAlertMsg: false,
    }
  },
  methods: {
    hideAlert() {
      this.visibleAlertMsg = false;
    },
    showAlert() {
      this.visibleAlertMsg = true;

      setTimeout(() => {
        this.hideAlert();
      },10000)
    }
  },
}
</script>

<style scoped>
.holder-alert {
  display: flex;
  position: fixed;
  z-index: 1000000;
  top: 0;
  left: 0;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 5px 5px;
  width: 100%;
  padding: 14px 18px;
  background-color: var(--error-color);
  color: var(--white-color);
}

.alert-msg {
  display: flex;
  align-items: center;
  width: calc(100% - 50px);
}

.alert-msg p {
  margin: 0;
}

.holder-alert svg {
  margin-right: 12px;
}

.alert-close {
  cursor: pointer;
}

.expand-enter-active,
.expand-leave-active {
  transform: translateY(0);
  transition: transform 0.5s;
}

.expand-enter,
.expand-leave-to {
  transform: translateY(-100%);
}
</style>
