let capitalizeFirstLetter = {
    methods: {
        capitalizeFirstLetter(string) {
            if (!string || typeof string !== 'string') return string;
            if (
              string.charAt(0) === string.charAt(0).toUpperCase()
              && string.charAt(1) === string.charAt(1).toLowerCase()) return string;
            return string[0].toUpperCase() + string.slice(1).toLowerCase();
        },
    },
}

export default capitalizeFirstLetter