<template>
  <div
      v-if="activeDialog"
      :class="activeDialog ? 'active': ''"
      class="overlay"
  >
    <div
        :class="customClass"
        class="common-dialog-wrapper"
    >
      <div id="dialoge_close" class="close-dialog" @click="closePopups">
        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M12.0007 0.333008C5.54898 0.333008 0.333984 5.54801 0.333984 11.9997C0.333984 18.4513 5.54898 23.6663 12.0007 23.6663C18.4523 23.6663 23.6673 18.4513 23.6673 11.9997C23.6673 5.54801 18.4523 0.333008 12.0007 0.333008ZM17.834 16.188L16.189 17.833L12.0007 13.6447L7.81232 17.833L6.16732 16.188L10.3557 11.9997L6.16732 7.81134L7.81232 6.16634L12.0007 10.3547L16.189 6.16634L17.834 7.81134L13.6457 11.9997L17.834 16.188Z"
              fill="#D7DCE6"
          />
        </svg>
      </div>
      <form @submit.prevent="submit" class="content">
        <h2 class="reset-pass-email-title">Add new password</h2>
        <p class="reset-pass-email-subtitle">The password linked to this email has been changed</p>
        <div class="form-group"
             :class="{ 'form-group--error': $v.formData.password.$error }"
        >
          <label for="create-case-popup-password_input">Enter new password*</label>
          <input
              id="create-case-popup-password_input"
              type="password"
              class="form-control"
              :class="false ? 'is-invalid' : ''"
              placeholder="Enter your new email password"
              v-model.trim="$v.formData.password.$model"
          >
          <div class="error" v-if="!$v.formData.password.required">Field is required</div>
        </div>
      </form>
      <button
          id="dialoge_continue"
          class="btn email-integration-btn"
          @click="submit"
      >
        Сhange password
      </button>
    </div>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";

export default {
  name: 'ChangePasswordCaseEmailPopup',
  props: {
    showFooter: {
      type: Boolean
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    },
  },

  data() {
    return {
      activeDialog: false,
      caseId: this.$route.params.slug,
      formData: {
        password: "",
      },
      submitStatus: null,
      payload: null,
      validForm: false,
      errorMessage: '',
    };
  },
  validations: {
    formData: {
      password: {
        required,
      },
    },
  },
  methods: {
    openPopups(payload) {
      this.activeDialog = true;
      this.payload = payload;
    },
    closePopups() {
      this.activeDialog = false;
      this.formData.password = '';
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'

        this.$load(async () => {
          await this.$api.cases.changePasswordCaseEmail(this.payload.emailId, this.formData.password).then(() => {
            this.$emit('changePassword', null)
            this.closePopups()
          }).catch(err => {
            this.errorMessage = err.response.data.errors.message[0] || err.response.data.errors.password[0];
            this.$emit('error', this.errorMessage)
          })
        }, (error) => {
          if(error) {
            if (error.response) {
              this.errorMessage = error.response.data.errors.message[0]
            } else {
              this.errorMessage = error.data.errors.message[0]
            }
            this.$emit('error', this.errorMessage)
          }
        })
      }
    },
  }
};
</script>

<style scoped>
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay.active {
  animation: show .3s linear;
}

.common-dialog-wrapper {
  box-sizing: border-box;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: 1px solid #d7dce6;
  border-radius: 30px;
  width: 100%;
  max-width: 520px;
  padding: 32px 10px;
  background: #fff;
  text-align: center;
}

.close-dialog {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 70px;
  padding: 23px;
  cursor: pointer;
}

.close-dialog svg {
  width: 100%;
  height: 100%;
}

.close-dialog svg path {
  transition: .5s all;
}

.close-dialog:hover svg path {
  fill: var(--accent-color);
}

.close-dialog img {
  display: block;
  width: 100%;
}

.common-dialog-wrapper .dialog-btn {
  border-color: var(--accent-color);
  min-width: 140px;
  width: 100%;
  max-width: 140px;
  padding: 13px 12px;
  background-color: var(--white-color);
}

.common-dialog-wrapper .dialog-btn:hover {
  border-color: var(--accent-color);
  background-color: var(--accent-color);
}

.common-dialog-wrapper .dialog-btn:hover .dialog-btn-text {
  color: var(--white-color);
}

.dialog-btn-text {
  display: block;
  overflow: hidden;
  transition: .5s all;
  max-width: 100%;
  font-size: 16px;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--accent-color);
}

/*custom classes */
.common-dialog-wrapper.assign-success {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 588px;
}

label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  gap: 10px;
}

input {
  border: 1px solid var(--case-btn-border-color);
  border-radius: 25px;
  height: 48px;
  padding: 0 20px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-black-color);
}

input::placeholder {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: var(--input-placeholder-value-color);
}

button[disabled] {
  pointer-events: none;
}

.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: 0px 60px;
  margin-bottom: 30px;
}

.content label {
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: #373C44;
}

.content label input {
  width: 100%;
}

.common-dialog-wrapper .btn {
  min-width: 215px;
  height: 48px;
}

.content .form-group {
  width: 100%;
}

.btn.email-integration-btn {
  font-size: 12px;
  line-height: 19px;
  padding: 7px 30px;
  min-width: 120px;
  background-color: var(--white-color);
  color: var(--accent-color);
  border: 1px solid var(--accent-color);
  min-width: 152px;
}

.btn.email-integration-btn:hover {
  background-color: var(--accent-color);
  color: var(--white-color);
}

.form-group--error .form-group__message, .form-group--error .error {
  text-align: left;
}

.reset-pass-email-subtitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--light-gray);
  margin-bottom: 32px;
}

.reset-pass-email-title {
  margin-top: 20px;
  margin-bottom: 10px;
}

</style>
