import { render, staticRenderFns } from "./ConfirmPhone.vue?vue&type=template&id=8dd9e776&scoped=true"
import script from "./ConfirmPhone.vue?vue&type=script&lang=js"
export * from "./ConfirmPhone.vue?vue&type=script&lang=js"
import style0 from "./ConfirmPhone.vue?vue&type=style&index=0&id=8dd9e776&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dd9e776",
  null
  
)

export default component.exports