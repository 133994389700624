<template>
  <div class="public-page-wrapper">
    <Header v-if="!login" />
    <HeaderDefaultRegistered v-else />
    <h1>
      Terms of conditions
    </h1>
    <div class="main-section-logo-container">
      <img src="@/assets/img/terms-of-conditions.svg" alt="terms" class="logo">
    </div>
    <div class="container">
      <p class="last-updated">Updated: June 4, 2024</p>
      <p class="subtitle">Acceptance of this Terms of Service</p>
      <p>THESE TERMS OF SERVICE AND USE APPLY TO ALL PIVOTAL MOBILE EDISCOVERY, INC. ("PME," "WE," "US," OR "OUR") AND ASSOCIATED WEBSITES INCLUDING www.pmediscovery.ai, www.pme-discovery.com (THE "CORPORATE WEBSITE"), pme-dev.com (THE "DEVELOPER WEBSITE”), api.pme-discovery.com (THE “API” WEBSITE), and pme.pme-discovery.com (THE “PLATFORM” WEBSITE) COLLECTIVELY, THE "WEBSITES". PLEASE REVIEW THE FOLLOWING TERMS AND CONDITIONS FOR THE USE OF THE WEBSITES AND THE SERVICES PROVIDED THROUGH ANY OF THE WEBSITES (SUCH SERVICES, THE "SERVICES" AND SUCH TERMS AND CONDITIONS, THE "TERMS OF SERVICE" OR "TERMS"). BY USING ANY WEBSITE, SERVICE, PRODUCT (AS DEFINED BELOW), OR APPLICATION PROGRAMMING INTERFACE ("API"), YOU AGREE TO BE BOUND BY THESE TERMS OR YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND THE PERSON OR ENTITY YOU NAME AS A USER OF ANY OF THE WEBSITES OR SERVICES ("YOU"). IF YOU DO NOT HAVE SUCH AUTHORITY OR YOU DO NOT AGREE WITH THESE TERMS, PLEASE DO NOT USE ANY WEBSITE, SERVICE OR PRODUCT (INCLUDING THE API).</p>
      <p>By accessing and utilizing the Website and Services, you acknowledge your commitment to abide by the terms laid out in this Terms of Service document, along with adhering to all pertinent laws, regulations, and accepted norms within the relevant jurisdictions. PIVOTAL MOBILE EDISCOVERY IS NOT RESPONSIBLE FOR ANY VIOLATION OF APPLICABLE LAWS, RULES, OR REGULATIONS COMMITTED BY YOU OR A THIRD PARTY AT YOUR BEHEST. IT IS YOUR RESPONSIBILITY TO ENSURE THAT YOUR USE OF THE WEBSITE AND SERVICES DOES NOT CONTRAVENE APPLICABLE LAWS, RULES, OR REGULATIONS.  Specifically, you confirm and guarantee that your utilization of the Website and Services aligns with the legal frameworks of (1) your place of residence and (2) the location where Pivotal Mobile eDiscovery operates. This includes adherence to export and import restrictions and any other pertinent regulations and restrictions.</p>
      <p class="subtitle">Additional Terms</p>
      <p>Your conduct on these Websites is governed by PME Privacy Policy and the API License Agreement, both of which are part of these Terms and are incorporated in these Terms by this reference.</p>
      <p>To access and use certain Services, you may be required to agree to certain additional terms and conditions.</p>
      <p>Capitalized terms used in these Terms but not defined in these Terms have the meanings given to them in the Privacy Policy or other additional terms.</p>
      <p class="subtitle">Description of Services</p>
      <p>Pivotal Mobile eDiscovery (“PME”) operates a SaaS-based platform designed to help our customers achieve secure, forensically sound, targeted remote collection of data from mobile devices, and to provide a platform where such data can undergo a workflow-driven review process.  PME provides this service through two complimentary components, a data collection application and web-based case management and review platform.</p>
      <p>The descriptions and specifications of Products and Services on the Websites are subject to change at any time, whether or not reflected in such descriptions or specifications. You agree that descriptions and specifications of Products and Services on the Websites do not create any obligations or liabilities for Enphase. We may modify, suspend or discontinue any or all of the Websites and/or Products or Services at any time, for any or no reason, in our sole discretion.</p>
      <p class="subtitle">Use of the Website and Services</p>
      <p>By agreeing to these terms, you commit to accessing the Services solely through the designated channels provided by Pivotal Mobile eDiscovery or its authorized partners. You also agree not to attempt to access the Services through automated means and pledge not to engage in any activities that could impede or disrupt the functioning of the Website, Services, or their connected servers and networks.</p>
      <p>Furthermore, you agree to:</p>
      <ul>
        <li>Refrain from disrupting or hindering other users' enjoyment of the Website, Services, or any associated sites.</li>
        <li>Abstain from uploading, posting, or transmitting any viruses or other harmful files through the Website or Services.</li>
        <li>Avoid accessing or attempting to access any Content or User Content beyond the scope of authorization outlined in these terms.</li>
        <li>Refrain from compromising the security of the Website, Services, Content, User Content, system resources, accounts, passwords, servers, or networks, or causing any harm to them or to any affiliated or linked sites.</li>
      </ul>
      <p class="subtitle">User Account Guidelines</p>
      <p>In order to use certain Services, you may be required to create an account with PME by registering on the applicable Website ("Account"). By creating an Account and/or using the associated Service, you represent and warrant that (i) you are at least eighteen (18) years of age, (ii) all required registration or other information you submit is truthful and accurate; (iii) you will update and maintain the accuracy of such information; and (iv) your use of the Service will not violate any applicable law, rule or regulation or the terms of these Terms. When you sign up for an Account, you may be asked to choose a user name and password. You are entirely responsible for maintaining the confidentiality of your password. You agree to notify us immediately if you suspect any unauthorized use of your Account or access to your password. You agree not to (a) use the Account, username or password of any other user at any time; (b) allow or enable any third party to use your Account, username or password; or (c) sell, rent, borrow, or otherwise transfer your Account, temporarily or permanently, to any third party. You are solely responsible for any and all use of your Account. We reserve the right to suspend or terminate your Account for any or no reason in our sole discretion.</p>
      <p>If you provide any personally identifiable information, please review our Privacy Policy, which outlines how Pivotal Mobile eDiscovery collects, utilizes, discloses, manages, and stores your personally identifiable information ("Personal Information"). You acknowledge and agree to maintain the confidentiality of all usernames and passwords associated with your User Account. Moreover, you must not use another individual's User Account without their explicit consent. While Pivotal Mobile eDiscovery is not responsible for any damages resulting from the theft or misuse of your username, password, or User Content, you may be held accountable for any losses suffered by Pivotal Mobile eDiscovery, other users, or third parties due to unauthorized access to your User Account. Should you become aware of any unauthorized use of your User Account, please promptly notify Pivotal Mobile eDiscovery at support@pmediscovery.ai.</p>
      <p class="subtitle">User Content</p>
      <p>User Account holders have the ability to upload specific content, termed as "User Content," which is intended for review, markup, and sharing by the account holder(s) through the Website and Services. Subject to compliance with these Terms of Service, your User Content may be utilized in ways authorized by you. You acknowledge and consent to third-party services processing the User Content you upload to the Website when it is required to deliver one or more Services provided by the Website (for example, voice-to-text transcription or AI-assisted document review).  The use and handling of any and all User Content is specified in our Privacy Policy.</p>
      <p>Pivotal Mobile eDiscovery does not assert ownership over any User Content and refrains from monitoring, editing, or disclosing any information regarding you or your User Account without your explicit consent, except as outlined in these Terms of Service or the Privacy Policy. As a general practice, Pivotal Mobile eDiscovery does not review, inspect, edit, or monitor any User Content posted, stored, or accessed by you or any other user of the Website or Services. However, Pivotal Mobile eDiscovery reserves the discretion to refuse, remove, or disable access to any User Content that may potentially be illegal or violate the terms of these Terms of Service, although it is not obligated to do so. Pivotal Mobile eDiscovery's actions or inactions in regulating content or conduct, or in enforcing against any potential violations of these Terms of Service by any user (or any other third party), do not waive Pivotal Mobile eDiscovery's right to implement or refrain from implementing regulation or enforcement measures regarding any subsequent or similar content, conduct, or potential violation of the Terms of Service.</p>
      <p>All materials, documents, communications, or information submitted to, sent through, or stored on the Website and Services by you will be subject to this Terms of Service and Privacy Policy. The security of such information is very important to Pivotal Mobile eDiscovery.</p>
      <p class="subtitle">Accessing Technical Support</p>
      <p>Pivotal Mobile eDiscovery offers technical support services to users. To access technical support, users must initiate a support request by contacting Pivotal Mobile eDiscovery at support@pme-discovery.com or through the Website. The user should outline the issue to be addressed and provide relevant personal information (such as name and account number). While Pivotal Mobile eDiscovery endeavors to respond promptly to support requests, it is not obligated to do so. Creating a User Account may be necessary to access technical support services. Users are expected to cooperate with Pivotal Mobile eDiscovery by furnishing pertinent information for diagnosing and resolving issues. While Pivotal Mobile eDiscovery cannot guarantee issue resolution, it commits to providing technical support services in a professional manner, exerting reasonable efforts to address users' concerns.</p>
      <p class="subtitle">Distributed Software</p>
      <p>PME products ("Products") include software applications distributed through our Website, Content Distribution Network, partner “App Store”, or directly to end-users by electronic or physical delivery.  These software applications are designed to be installed by the end-user on a Personal Computer, laptop, tablet, mobile device, or other such personal electronic device, for the sole purpose of enabling PME data collection services.</p>
      <p>PME retains ownership of all Intellectual Property Rights (defined as any and all now known or hereafter existing (a) rights associated with works of authorship throughout the universe, including exclusive exploitation rights, copyrights, moral rights, and mask works; (b) trademark and trade name rights; (c) trade secret rights; (d) patents, designs, algorithms, and other industrial property rights; (e) other intellectual property and proprietary rights of every kind and nature throughout the universe, whether arising by operation of law, by contract or license, or otherwise; and (f) all registrations, applications, renewals, extensions, combinations, divisions, or reissues of the foregoing) in the Products.  The Products are offered for sale and sold on the condition in every case that, except as expressly set forth herein, such sale does not convey any license, express or implied, under any Intellectual Property Right of PME, and all such rights are reserved. PME grants no implied licenses. Distributed Software is provided under license and is not sold. Subject to these Terms, PME grants to Customers a non-exclusive and non-transferable license, to (a) execute and use the Distributed Software solely to support PME Products.  Customers shall not, and shall use all reasonable endeavors to ensure that no third party shall, reverse engineer, decompile, disassemble the Products, or otherwise attempt to derive the source code for the Distributed Software.</p>
      <p class="subtitle">Acceptable Use</p>
      <p>You are solely responsible for any and all of your activities and use of PME Websites and Services. You represent and warrant that: (i) your use of the PME Wedsites will be consistent with the terms applicable thereto; and (ii) your activities and use of any Website and/or Service will not:</p>
      <p>(i) violate any law, rule or regulation (including any law regarding the export of data, software or technology to and from the United States and other countries) or these Terms;</p>
      <p>(ii) access or attempt to access any Website or any web page thereof by any means other than the interface that is provided by Enphase (and specifically, you agree not to access or attempt to access any Website or any web page thereof through any automated means, such as, but not limited to, the use of scripts or web crawlers) and you agree, at all times, to comply with the instructions set out in any robots.txt file provided on any Website;</p>
      <p>(iii) provide personal information or access to personal information, including site access, to anyone not expressly authorized by the Account Owner, and specifically you may not provide site access through your PME account to any third-party;</p>
      <p>(iv) engage in any activity of any kind that may interfere with or disrupt the functioning of any Website or Service or the servers and networks used to provide any Website or Service;</p>
      <p>(v) involve the transmission of any unsolicited commercial or bulk email and you shall not use your Account or any Website as a return address for unsolicited commercial mail originating elsewhere;</p>
      <p>(vi) collect, harvest, mine or engage in any other activity to obtain personal information or any other information about others except with their express written consent and of which consent you shall maintain a written record for a period of three (3) years after any termination of this Agreement;</p>
      <p>(vii) remove from any Website Content or falsely add to any Website Content, any copyright, trademark or other legal or proprietary rights notices, author attributions or other information such as origin or source of such Website Content;</p>
      <p>(viii) create a false identity for the purpose of misleading others;</p>
      <p>(ix) violate the rights of PME or any third party (including other users’ rights) or abuse, defame, harass, stalk or threaten another; or</p>
      <p>(x) create liability for Pivotal Mobile eDiscovery.</p>
      <p class="subtitle">Term and Termination</p>
      <p>The term of this Terms of Service (“Term”) shall begin when you start using the Website or Services and shall continue in perpetuity unless otherwise terminated by Pivotal Mobile eDiscovery or you by written notice. Pivotal Mobile eDiscovery reserves the right to change, suspend or discontinue the Services or any portion thereof, at any time. Without prejudice to any other rights, this Terms of Service will terminate automatically if you fail to comply with any of the limitations or other requirements described herein. Upon any termination or expiration of this Terms of Service, you must immediately cease using this Website and the Services including without limitation any use of Pivotal Mobile eDiscovery’ trademarks, trade names, copyrights and other intellectual property.</p>
      <p>UPON TERMINATION OF THIS AGREEMENT, YOU WILL NO LONGER BE AUTHORIZED TO USE THE PIVOTAL MOBILE EDISCOVERY APPLICATION, WEBSITE, OR SERVICES IN ANY WAY.</p>
      <p class="subtitle">Links to External Sites and Third-Party Materials</p>
      <p>The Website and Services may contain links to external websites and/or third-party products that are beyond the control of Pivotal Mobile eDiscovery (referred to collectively as "Third-Party Materials"). Pivotal Mobile eDiscovery assumes no responsibility for these Third-Party Materials. These links are provided solely for the convenience of users of the Website and Services, and their inclusion does not imply endorsement by Pivotal Mobile eDiscovery of the content, products, and/or services offered by these Third-Party Materials. It is important to note that, notwithstanding any provision to the contrary in these Terms of Service, nothing herein grants you rights or licenses concerning these Third-Party Materials or authorizes you to utilize them.</p>
      <p class="subtitle">Intellectual Property Rights</p>
      <p>Copyrights, trademarks, and all other proprietary rights associated with the Website, Services, and Content (including, but not limited to, software, services, audio, video, text, and photographs, excluding User Content) are owned by Pivotal Mobile eDiscovery and/or its licensors. Unless explicitly provided herein or authorized in writing by Pivotal Mobile eDiscovery, all rights to the Website, Services, and Content not expressly granted herein are reserved. You agree not to copy, republish, frame, make available for download, transmit, modify, rent, lease, loan, sell, assign, distribute, license, sublicense, reverse engineer, or create derivative works based on the Content, Website, or Services, except as expressly permitted in conjunction with the Services offered by Pivotal Mobile eDiscovery through the Website.</p>
      <p>Pivotal Mobile eDiscovery hereby waives any claims to trademarks, service marks, trade names, logos, copyrights, patents, domain names, or other intellectual property interests of third parties. All intellectual property interests of third parties mentioned herein, including Third-Party Materials or other content provided on this Website, are the properties of their respective owners. Pivotal Mobile eDiscovery does not assert any proprietary rights in the intellectual property rights of third parties, except for their own.</p>
      <p class="subtitle">Security</p>
      <p>Security is a high priority for PME, and we make reasonable efforts to protect your Account. However, no company, including PME, can fully eliminate security risks connected to handling data on the Internet and you acknowledge, understand and agree that we cannot and do not guarantee the security of your Content while it is transmitted over the Internet or stored on our Services.</p>
      <p class="subtitle">Indemnity</p>
      <p>You agree to indemnify and hold PME, our affiliates, officers, employees, agents, partners and other users, harmless from and against any and all loss, cost (including attorneys’ fees), liability, damage, and expense arising from or relating to any claim or demand made by any third party (including other users) arising out of or related to (i) your use of any of the Websites, Services, or Website Content, your violation of these Terms, or your violation of any rights of any third party or (ii) Your Content.</p>
      <p class="subtitle">Release</p>
      <p>To the extent permitted under applicable law, you hereby release PME from any liability related to: (i) any incorrect or inaccurate Website Content, whether caused by any user of any Service or Website or by any of the equipment or programming associated with or utilized in the Services or in the provision of the Websites; (ii) the conduct, whether online or offline, of any user of any Service or Website; (iii) any problems or technical malfunction of any data network or lines, computer online systems, servers or providers, computer equipment, software, failure of email or Website Content on account of technical problems or traffic congestion on the Internet or at any Website, or combination thereof, including injury or damage to any person’s computer related to or resulting from participating or downloading materials in connection with the Services or Websites; (iv) any loss or damage caused by or in reliance upon any Website Content or content transmitted by and to users, or any interactions between users of any Service or Website, whether online or offline; and (v) any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, any Website, Service, or user communication.</p>
      <p>If you are a California resident, you hereby waive California Civil Code Section 1542, which states: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH, IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR."</p>
      <p class="subtitle">Warranties and Disclaimers</p>
      <p>ALL CONTENT AND SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. PIVOTAL MOBILE EDISCOVERY EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THE OPERATION OF THIS WEBSITE, SERVICES, OR CONTENT. PIVOTAL MOBILE EDISCOVERY DOES NOT GUARANTEE THE SECURITY OF THE WEBSITE, SERVICES, OR CONTENT. YOU ACKNOWLEDGE THE POSSIBILITY OF INTERCEPTION OF TRANSMITTED INFORMATION. PIVOTAL MOBILE EDISCOVERY DOES NOT WARRANT THAT THE WEBSITE, SERVICES, CONTENT, OR SERVERS USED TO OPERATE THIS WEBSITE, OR ELECTRONIC COMMUNICATIONS SENT BY PIVOTAL MOBILE EDISCOVERY, ARE FREE FROM VIRUSES OR OTHER HARMFUL ELEMENTS.  Unless specifically agreed upon in writing by Pivotal Mobile eDiscovery, contractors, agents, dealers, distributors, or any other third parties are not authorized to alter this limited warranty or provide additional warranties.</p>
      <p>Your use of the Website or Services, as well as the downloading or utilization of any products through the Website, is undertaken at your own discretion and risk. By agreeing, you acknowledge that you will be solely responsible for any damage to your computer system, loss of data, or other harm resulting from such activities. Pivotal Mobile eDiscovery bears no liability for any computer virus or similar software code downloaded to your computer from the Website or in connection with any services or products offered through the Website. Any advice or information, whether oral or written, obtained from Pivotal Mobile eDiscovery or the Website, does not create any warranty beyond what is expressly stated in the Terms of Service.</p>
      <p>The Website and Services may mention certain Pivotal Mobile eDiscovery products and services that may not be accessible in certain countries. However, any such reference does not guarantee the availability of these products or services in any country or at any time.</p>
      <p>By using the Website and Services, you acknowledge that you may encounter content that you find offensive, indecent, or objectionable. Consequently, you agree that your use of the Website and Services exposes you to such content at your own risk.</p>
      <p>PIVOTAL MOBILE EDISCOVERY AND ITS AFFILIATES SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, PUNITIVE, SPECIAL, OR INCIDENTAL DAMAGES, INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOSS OF BUSINESS, CONTRACT, REVENUE, DATA, INFORMATION, OR BUSINESS INTERRUPTION, UNDER ANY LEGAL THEORY, ARISING FROM THE USE OF, OR INABILITY TO USE, THE WEBSITE OR SERVICES, OR THE CONTENT, PRODUCTS, SERVICES, STATEMENTS, OR ACTIONS OF ANY THIRD PARTY ON OR THROUGH THE WEBSITE OR SERVICES. THIS INCLUDES DAMAGES RESULTING FROM UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSION OR DATA, THE SENDING OR RECEIVING (OR NON-RECEIPT) OF INFORMATION, FAILURE TO STORE DATA, LOSS OR DAMAGE TO FILES OR CONTENT, OR DELAYED OR INTERRUPTED SERVICES AVAILABLE THROUGH THE WEBSITE, EVEN IF PIVOTAL MOBILE EDISCOVERY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
      <p>ANY LEGAL ACTION AGAINST PIVOTAL MOBILE EDISCOVERY RELATING TO THE WEBSITE AND SERVICES MUST BE INITIATED AND NOTIFIED TO PIVOTAL MOBILE EDISCOVERY IN WRITING WITHIN ONE (1) YEAR FROM THE DATE THE CAUSE OF ACTION AROSE.</p>
      <p class="subtitle">Jurisdiction</p>
      <p>These Terms and any action related to these Terms are governed by the laws of the State of New York, United States of America, without giving effect to any conflicts of laws principles that would require the application of the law of a different state or jurisdiction. By using any of the Websites, Services, or Products you consent to the personal jurisdiction and venue of these courts.</p>
      <p class="subtitle">Miscellaneous</p>
      <p>These Terms constitute the entire agreement between you and PME regarding your use of the Websites and Services. Our failure to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision. If any provision of these Terms is, for any reason, held to be invalid or unenforceable, the other provisions of these Terms will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law. As used in these Terms, the word "including" means "including but not limited to." You agree not to export, re-export, or transfer, directly or indirectly, any United States technical data acquired from us, in violation of United States export laws or regulations. Neither the rights nor obligations arising under these Terms are assignable by you, and any such attempted assignment or transfer shall be void and without effect. We may assign this Agreement without restriction.</p>
    </div>
    <Footer />
  </div>
</template>

<script>
import login from "../mixins/login";
import Header from "../components/layout/Header";
import HeaderDefaultRegistered from "../components/layout/HeaderDefaultRegistered";
import Footer from "../components/Footer.vue";

export default {
  name: "TermsOfConditions",
  mixins: [login],
  data() {
    return {
      termsConditions: null
    }
  },
  created() {
    this.$load(async() => {
      let response = (await this.$api.publicPage.getPageHtml('termsAndConditions'))
      this.termsConditions = response.data.body.content
    })
  },
  mounted() {
    document.title = 'Terms of conditions'
  },
  components: {
    Footer,
    HeaderDefaultRegistered,
    Header,
  }
}
</script>

<style scoped>
.container {
  max-width: 720px;
  margin: 0 auto 200px;
}
.main-section-logo-container {
  text-align: center;
  margin-bottom: 50px;
}
.static-content {
  padding-top: 45px;
  padding-bottom: 30px;
}
.main-section {
  padding-top: 226px;
}
h1 {
  text-align: center;
  color: var(--secondary-black-color);
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 50px;
}
.last-update-paragraph {
  font-style: italic;
  color: var(--secondary-black-color);
}
.simple-text-paragraph {
  color: var(--secondary-text-color);
  line-height: 28px;
}
.static-content a {
  color: inherit;
}

.last-updated {
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  text-align: left;
  margin-bottom: 50px;
}

.text-link {
  color: blue;
  text-decoration: underline;
}

.subtitle {
  font-size: 20px;
  font-weight: 700;
  text-align: left;
}

.subtitle-italic {
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  font-style: italic;
}

ul {
  list-style-type: disc;
  margin: 0 0 30px 30px;
}
ul li {
  margin-bottom: 10px;
}
</style>
