<template>
  <div class="holder-sessions">
    <table>
      <thead>
      <tr>
        <th width="140">
          <span>Devices</span>
        </th>
        <th width="140">
          <span>Data session</span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in 10"
          :key="index"
      >
        <td width="140">
          <span class="devices-span">iOS 7.8.4</span>
        </td>
        <td width="140">
          <span class="data-span">18 Sept, 2020</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "ClientSessions"
}
</script>

<style scoped>
td, th {
  padding: 13px 0 13px 15px;
  text-align: left;
  vertical-align: middle;
}

table {
  /*width:100%;*/
  table-layout: fixed;
}

table thead span {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.devices-span {
  font-size: 14px;
  font-weight: 500;
}

.data-span {
  font-size: 12px;
  font-weight: 500;
  color: var(--secondary-text-color);
}

</style>