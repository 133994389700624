<template>
    <div class="holder-profile-data">
      <div class="top-info">
        <div class="top-info-content">
          <h2>{{ getProfileName() }}</h2>
          <span v-if="roleUser || (firm && firm.country)">({{ getRoleNameFE(roleUser) || firm.country }})</span>
        </div>
        <div class="top-info-img">
          <div
              v-if="type === 'profile'"
              class="form-group"
              :class="{ 'form-group--error': $v.userPhoto.$error }"
          >
            <div
                class="form-profile-image"
                :class="{'active': previewImage}"
            >
              <img
                  :src="previewImage"
                  v-if="previewImage"
                  class="uploading-image"
                  alt="preview"
                  onerror="this.src='https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png'"
              />
              <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.7188 37.0004H52.2743M36.9965 21.7227V52.2782V21.7227Z" stroke="white" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M37.0009 67.5555C53.8762 67.5555 67.5564 53.8753 67.5564 36.9999C67.5564 20.1245 53.8762 6.44434 37.0009 6.44434C20.1255 6.44434 6.44531 20.1245 6.44531 36.9999C6.44531 53.8753 20.1255 67.5555 37.0009 67.5555Z" stroke="white" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <div :class="['custom-file',$v.userPhoto.$error?'is-invalid':'']">
                <input
                    type="file"
                    id="register_profile-photo_input"
                    accept=".png,.jpeg,.jpg"
                    class="input-image"
                    @change="onLogoChange($event)"
                >
              </div>
              <label for="register_profile-photo_input" class="input__file-button">
              </label>
            </div>
            <div id="register_profile-photo_error" class="error user-photo-error" v-if="!$v.userPhoto.isTrueImage">Invalid image file size.</div>
          </div>
<!--              <img v-else-if="type == 'profile' && !photoProfile" src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png" alt="">-->
          <div v-else-if="type === 'firm' && photoFirm">
            <img
                :src="photoFirm"
                alt="logo"
            >
          </div>
          <div v-else-if="type === 'firm' && !photoFirm">
            <img :src="'https://cdn4.iconfinder.com/data/icons/social-media-logos-6/512/126-beatspill_beats_pill-512.png'" alt="logo">
          </div>
        </div>
      </div>
      <div class="bottom-info">
        <div class="bottom-info-content">
          <div
              v-if="firm && address"
              class="info-content-item"
          >
            <span>Country</span>
            <p>
              {{firm.country}}
            </p>
          </div>
          <div
              v-if="firm && address"
              class="info-content-item"
          >
            <span>State</span>
            <p>
              {{firm.city}}
            </p>
          </div>
          <div
              v-if="firm && address"
              class="info-content-item"
          >
            <span>City</span>
            <p>
              {{firm.state}}<br>
            </p>
          </div>
          <div
              v-if="firm && address"
              class="info-content-item"
          >
            <span>First Address</span>
            <p>
              {{firm.firstAddress}}<br>
            </p>
          </div>
          <div
            class="info-content-item"
            v-if="emailUser"
          >
            <span>Email</span>
            <p>{{emailUser}}</p>
          </div>
          <div
              class="info-content-item"
              v-if="phoneUser"
          >
            <span>phone number</span>
            <p>{{phoneUser}}</p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import LocalStorageService from "@/services/LocalStorageService";
import getRoleName from "@/mixins/getRoleName";

const localStorageService = LocalStorageService.getService();

const isTrueImage = (value) => {
  if (!value) {
    return true;
  }
  let file = value;
  if(file.size === 0) {
    return false;
  }
  return (+(file.size / 1024) <= +5120);
}

export default {
  name: "HeaderMyProfile",
  props: {
    user: {
      type: Object,
    },
    type: {
      type: String,
    },
    firm: {
      type: Object,
      default: null,
    },
    address: {
      type: String,
    },
    fullNameUser: {
      type: String
    },
    photoProfile: {
      type: String
    },
    phoneUser: {
      type: String
    },
    emailUser: {
      type: String
    },
    roleUser: {
      type: String
    },
    photoFirm: {

    },
  },
  data(){
    return{
      userPhoto: null,
      previewImage: this.photoProfile,
      submitStatus: null,
      validForm: false,
      errorMessage: '',
    }
  },
  validations: {
    userPhoto: { isTrueImage }
  },
  mixins: [getRoleName],
  methods: {
    getProfileName() {
      if (this.fullNameUser){
        return this.fullNameUser;
      } else if (this.firm && this.firm.firmName) {
        return this.firm.firmName;
      } else {
        return 'No data';
      }
    },
    onLogoChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      const file = files[0];
      this.userPhoto = file;
      this.$v.userPhoto.$touch();
      if (this.$v.userPhoto.$error) {
        return false
      } else {
        this.$load(async () => {
          let formData = new FormData();
          formData.append('file', this.userPhoto);

          await this.$api.auth.userPhoto(formData).then(() => {
          })
        }, (error) => {
          this.errorMessage = error.data.errors.message[0]
          this.$refs.alert.showAlert()
        })
        this.previewImage = URL.createObjectURL(file);
      }
    }
  },
  mounted() {
    if(localStorageService.getAccessTokenLogin() !== null) {
      this.$store.commit('authentication/loginSuccess', localStorageService.getAccessTokenLogin)
    }
    setTimeout(() => {
      this.previewImage = this.photoProfile
    },1000)
  }
}
</script>

<style lang="scss" scoped>

h2 {
  margin: 0;
}

p {
  margin-top: 0;
}

ul {
  padding: 0;
}

.holder-profile-data {
  display: flex;
  flex-direction: column;
  margin-bottom: 55px;
}

.top-info {
  position: relative;
  background-color: var(--accent-color);
  border-radius: 40px 40px 0 0;
  min-height: 90px;
}

.top-info-img {
  position: absolute;
  top: 24px;
  left: 35px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
}

.bottom-info {
  background-color: var(--blue-bg-color);
  border-radius: 0 0  40px 40px;
}

.top-info-content h2 {
  font-size: 26px;
  font-weight: 700;
  color: var(--white-color);
}

.top-info-content span {
  display: block;
  text-transform: uppercase;
  color: var(--white-color);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px;
}

.top-info, .bottom-info {
  display: flex;
  justify-content: flex-end;
}

.top-info-content, .bottom-info-content {
  width: 80%;
}

.top-info-content {
  padding-top: 30px;
}

.bottom-info-content {
  width: 80%;
  display: flex;
  padding-top: 35px;
  padding-bottom: 35px;
  align-items: flex-start;
  justify-content: flex-start;
}

.info-content-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  margin-right: 32px;
}

.info-content-item span {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
  color: var(--secondary-black-color);
  margin-bottom: 20px;
}

.info-content-item p {
  font-weight: 500;
  font-size: 14px;
  color: var(--secondary-black-color);
  line-height: 17px;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.profile-image-info {
  text-align: center;
}

.input-image {
  display: none;
}

.form-profile-image {
  position: relative;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-profile-image:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--accent-color);
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  border-radius: 50%;
}

.input__file-button {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  margin: 0;
  z-index: 2;
}

.uploading-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  z-index: 1;
  border: 1px solid transparent;
  transition: all .5s;
}

.form-profile-image svg {
  position: absolute;
  z-index: 2;
  transition: all .5s;
}

.form-profile-image.active svg {
  z-index: 4;
}

.form-profile-image.active .input__file-button {
  z-index: 5;
}

.form-profile-image.active:after {
  z-index: 3;
  opacity: 0;
  transition: all .5s;
}

.form-profile-image.active:hover:after {
  opacity: 0.5;
}

.form-profile-image.active svg {
  opacity: 0;
}

.form-profile-image.active:hover svg {
  opacity: 1;
}

.user-photo-error {
  text-align: center;
}

</style>
