<template>
  <div
      v-if="activeDialog"
      :class="{
        'active' : activeDialog,
        'clue': showClue,
      }"
      class="overlay"
  >
    <div
        :class="{
          customClass,
          'dialog-clue': showClue,
        }"
        class="common-dialog-wrapper"
    >
      <div id="dialoge_close" class="close-dialog" @click="closePopups">
        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M12.0007 0.333008C5.54898 0.333008 0.333984 5.54801 0.333984 11.9997C0.333984 18.4513 5.54898 23.6663 12.0007 23.6663C18.4523 23.6663 23.6673 18.4513 23.6673 11.9997C23.6673 5.54801 18.4523 0.333008 12.0007 0.333008ZM17.834 16.188L16.189 17.833L12.0007 13.6447L7.81232 17.833L6.16732 16.188L10.3557 11.9997L6.16732 7.81134L7.81232 6.16634L12.0007 10.3547L16.189 6.16634L17.834 7.81134L13.6457 11.9997L17.834 16.188Z"
              fill="#D7DCE6"
          />
        </svg>
      </div>
      <div class="content">
        <h2>Add email integration</h2>
        <p class="title-desc">To confirm enter password</p>

        <div
          class="form-group"
          :class="{ 'form-group--error': $v.formData.provider.$error }"
        >
          <p class="label">Choose your e-mail provider:</p>
          <div class="checkbox-wrap">
            <div class="custom-checkbox-group">
              <div class="checkbox-wrap">
                <input
                  id="create-case-popup-checkbox_gmail"
                  class="input-checkbox"
                  type="radio"
                  value="GMAIL"
                  v-model="formData.provider">
                <label
                  for="create-case-popup-checkbox_gmail"
                  class="label-checkbox checkbox-title"
                >
                </label>
                <span class="checkbox-unselect email-integration-checkbox">
                Gmail
              </span>
              </div>
            </div>
            <div class="custom-checkbox-group">
              <div class="checkbox-wrap">
                <input
                  id="create-case-popup-checkbox_outlook"
                  class="input-checkbox"
                  type="radio"
                  value="OUTLOOK"
                  v-model="formData.provider">
                <label
                  for="create-case-popup-checkbox_outlook"
                  class="label-checkbox checkbox-title"
                />
                <span class="checkbox-unselect email-integration-checkbox">
                Outlook
              </span>
              </div>
            </div>
          </div>
          <div class="error" v-if="!$v.formData.provider.required">Field is required</div>
        </div>

        <div class="form-group"
             :class="{ 'form-group--error': $v.formData.email.$error }"
        >
          <label for="create-case-popup-email_input">E-mail address</label>
          <input
              id="create-case-popup-email_input"
              type="text"
              class="form-control"
              :class="false ? 'is-invalid' : ''"
              placeholder="Enter your email"
              v-model.trim="$v.formData.email.$model"
          >
          <div class="error" v-if="!$v.formData.email.required">Field is required</div>
        </div>
        <div class="form-group"
             :class="{ 'form-group--error': $v.formData.password.$error }"
        >
          <div class="password-header-wrapper">
            <div>
              <label for="create-case-popup-password_input">Enter your password</label>
            </div>
            <div class="generate-password-wrapper" @click="showClue = !showClue">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_11123_85241)">
                  <path d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 4.375H8.125V5.625H6.875V4.375ZM6.875 6.875H8.125V10.625H6.875V6.875Z" fill="#082655"/>
                </g>
                <defs>
                  <clipPath id="clip0_11123_85241">
                    <rect width="15" height="15" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <p class="generate-password-text">Generate password</p>
            </div>
          </div>
          <transition name="slide">
            <div v-if="showClue" class="clue-wrapper">
              <h5 class="clue-title">Gmail integration instruction:</h5>
              <ol>
                <li>
                  <p class="complex-list-item">Open Gmail and go to <b>Settings</b>
                    <svg width="17" height="16" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_11151_85364)">
                        <path d="M13.5575 9.16603C13.5859 8.95353 13.6 8.73395 13.6 8.5002C13.6 8.27353 13.5859 8.04686 13.5505 7.83436L14.9884 6.71519C15.1159 6.61603 15.1513 6.42478 15.0734 6.28311L13.7134 3.93145C13.6284 3.77561 13.4513 3.72603 13.2955 3.77561L11.6025 4.45561C11.2484 4.18645 10.873 3.95978 10.455 3.78978L10.2 1.99061C10.1717 1.82061 10.03 1.7002 9.86004 1.7002H7.14004C6.97004 1.7002 6.83545 1.82061 6.80712 1.99061L6.55212 3.78978C6.1342 3.95978 5.7517 4.19353 5.40462 4.45561L3.7117 3.77561C3.55587 3.71895 3.37879 3.77561 3.29379 3.93145L1.94087 6.28311C1.85587 6.43186 1.8842 6.61603 2.02587 6.71519L3.46379 7.83436C3.42837 8.04686 3.40004 8.28061 3.40004 8.5002C3.40004 8.71978 3.4142 8.95353 3.44962 9.16603L2.0117 10.2852C1.8842 10.3844 1.84879 10.5756 1.9267 10.7173L3.2867 13.0689C3.3717 13.2248 3.54879 13.2744 3.70462 13.2248L5.39754 12.5448C5.7517 12.8139 6.12712 13.0406 6.54504 13.2106L6.80004 15.0098C6.83545 15.1798 6.97004 15.3002 7.14004 15.3002H9.86004C10.03 15.3002 10.1717 15.1798 10.193 15.0098L10.448 13.2106C10.8659 13.0406 11.2484 12.8139 11.5955 12.5448L13.2884 13.2248C13.4442 13.2814 13.6213 13.2248 13.7063 13.0689L15.0663 10.7173C15.1513 10.5614 15.1159 10.3844 14.9813 10.2852L13.5575 9.16603ZM8.50004 11.0502C7.09754 11.0502 5.95004 9.9027 5.95004 8.5002C5.95004 7.0977 7.09754 5.95019 8.50004 5.95019C9.90254 5.95019 11.05 7.0977 11.05 8.5002C11.05 9.9027 9.90254 11.0502 8.50004 11.0502Z" fill="#696F79"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_11151_85364">
                          <rect width="17" height="17" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-top: 1.5px">
                      <path d="M8.24848 6.50706C8.24877 6.657 8.19649 6.80231 8.1007 6.91777L4.88807 10.7682C4.77901 10.8992 4.62229 10.9816 4.45239 10.9973C4.28249 11.0129 4.11333 10.9605 3.98211 10.8516C3.8509 10.7427 3.76838 10.5861 3.75272 10.4165C3.73705 10.2468 3.78952 10.0778 3.89859 9.94675L6.7771 6.50706L4.00139 3.06736C3.94802 3.00172 3.90816 2.92619 3.88411 2.84512C3.86006 2.76404 3.85229 2.67902 3.86124 2.59493C3.8702 2.51085 3.89571 2.42936 3.9363 2.35515C3.97689 2.28094 4.03176 2.21548 4.09777 2.16252C4.16383 2.10375 4.24134 2.05924 4.32543 2.03178C4.40952 2.00432 4.49838 1.9945 4.58645 2.00293C4.67451 2.01136 4.75989 2.03787 4.83722 2.08079C4.91455 2.12371 4.98218 2.18213 5.03585 2.25236L8.13925 6.10277C8.2199 6.22154 8.25836 6.36389 8.24848 6.50706Z" fill="#696F79"/>
                    </svg>
                  <b>See all settings</b>.
                  </p>
                </li>
                <li>Navigate to the <b>Forwarding and POP/IMAP</b> tab.</li>
                <li>In the "IMAP access" section, select <b>Enable IMAP</b>.</li>
                <li>Click <b>Save Changes</b>.</li>
                <li>Click on your profile picture.</li>
                <li>Select <b>Manage your Google Account</b>.</li>
                <li>Go to the <b>Security</b> page and enable <b>2-Step Verification</b>.</li>
                <li>Generate an <a href="https://myaccount.google.com/apppasswords" target="_blank">App Password</a>
                  by entering an app name and clicking <b>Create</b>.</li>
                <li>Return to this page and enter your email and the app password.</li>
              </ol>
              <h5 class="clue-title">Outlook integration instruction:</h5>
              <ol>
                <li>
                  <p class="complex-list-item">Open Outlook and go to <b>Settings</b>
                    <svg width="17" height="16" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_11151_85364)">
                        <path d="M13.5575 9.16603C13.5859 8.95353 13.6 8.73395 13.6 8.5002C13.6 8.27353 13.5859 8.04686 13.5505 7.83436L14.9884 6.71519C15.1159 6.61603 15.1513 6.42478 15.0734 6.28311L13.7134 3.93145C13.6284 3.77561 13.4513 3.72603 13.2955 3.77561L11.6025 4.45561C11.2484 4.18645 10.873 3.95978 10.455 3.78978L10.2 1.99061C10.1717 1.82061 10.03 1.7002 9.86004 1.7002H7.14004C6.97004 1.7002 6.83545 1.82061 6.80712 1.99061L6.55212 3.78978C6.1342 3.95978 5.7517 4.19353 5.40462 4.45561L3.7117 3.77561C3.55587 3.71895 3.37879 3.77561 3.29379 3.93145L1.94087 6.28311C1.85587 6.43186 1.8842 6.61603 2.02587 6.71519L3.46379 7.83436C3.42837 8.04686 3.40004 8.28061 3.40004 8.5002C3.40004 8.71978 3.4142 8.95353 3.44962 9.16603L2.0117 10.2852C1.8842 10.3844 1.84879 10.5756 1.9267 10.7173L3.2867 13.0689C3.3717 13.2248 3.54879 13.2744 3.70462 13.2248L5.39754 12.5448C5.7517 12.8139 6.12712 13.0406 6.54504 13.2106L6.80004 15.0098C6.83545 15.1798 6.97004 15.3002 7.14004 15.3002H9.86004C10.03 15.3002 10.1717 15.1798 10.193 15.0098L10.448 13.2106C10.8659 13.0406 11.2484 12.8139 11.5955 12.5448L13.2884 13.2248C13.4442 13.2814 13.6213 13.2248 13.7063 13.0689L15.0663 10.7173C15.1513 10.5614 15.1159 10.3844 14.9813 10.2852L13.5575 9.16603ZM8.50004 11.0502C7.09754 11.0502 5.95004 9.9027 5.95004 8.5002C5.95004 7.0977 7.09754 5.95019 8.50004 5.95019C9.90254 5.95019 11.05 7.0977 11.05 8.5002C11.05 9.9027 9.90254 11.0502 8.50004 11.0502Z" fill="#696F79"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_11151_85364">
                          <rect width="17" height="17" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-top: 1.5px">
                      <path d="M8.24848 6.50706C8.24877 6.657 8.19649 6.80231 8.1007 6.91777L4.88807 10.7682C4.77901 10.8992 4.62229 10.9816 4.45239 10.9973C4.28249 11.0129 4.11333 10.9605 3.98211 10.8516C3.8509 10.7427 3.76838 10.5861 3.75272 10.4165C3.73705 10.2468 3.78952 10.0778 3.89859 9.94675L6.7771 6.50706L4.00139 3.06736C3.94802 3.00172 3.90816 2.92619 3.88411 2.84512C3.86006 2.76404 3.85229 2.67902 3.86124 2.59493C3.8702 2.51085 3.89571 2.42936 3.9363 2.35515C3.97689 2.28094 4.03176 2.21548 4.09777 2.16252C4.16383 2.10375 4.24134 2.05924 4.32543 2.03178C4.40952 2.00432 4.49838 1.9945 4.58645 2.00293C4.67451 2.01136 4.75989 2.03787 4.83722 2.08079C4.91455 2.12371 4.98218 2.18213 5.03585 2.25236L8.13925 6.10277C8.2199 6.22154 8.25836 6.36389 8.24848 6.50706Z" fill="#696F79"/>
                    </svg>
                    <b>Sync email</b>.
                  </p>
                </li>
                <li>In the POP and IMAP section, click <b>Let devices and apps use POP</b>.</li>
                <li>Navigate to your <a href="https://account.microsoft.com/" target="_blank">Microsoft account</a>.</li>
                <li>Go to the <b>Security</b> page and click <b>Manage how I sign in</b>.</li>
                <li>Enable <b>Two-step verification</b>.</li>
                <li>Go back to the <b>Security</b> page and go to the <b>App passwords</b> section.</li>
                <li>Click <b>Create a new app password</b> and copy the app password.</li>
                <li>Return to this page and enter your email and the app password.</li>
              </ol>
            </div>
          </transition>
          <input
              id="create-case-popup-password_input"
              type="password"
              class="form-control"
              :class="false ? 'is-invalid' : ''"
              placeholder="Enter your email password"
              v-model.trim="$v.formData.password.$model"
          >
          <div class="error" v-if="!$v.formData.password.required">Field is required</div>
        </div>
      </div>
      <button
          id="dialoge_continue"
          class="btn email-integration-btn"
          @click="submit"
      >
        Validate integration
      </button>
    </div>
  </div>
</template>

<script>

import {required} from "vuelidate/lib/validators";

export default {
  name: 'CaseEmailPopup',
  props: {
    showFooter: {
      type: Boolean
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    },
  },

  data() {
    return {
      showClue: false,
      activeDialog: false,
      caseId: this.$route.params.slug,
      formData: {
        email: '',
        password: '',
        provider: null
      },
      submitStatus: null,
      validForm: false,
      errorMessage: '',
    };
  },
  validations: {
    formData: {
      email: {
        required,
      },
      password: {
        required,
      },
      provider: {
        required
      },
    },
  },
  methods: {
    openPopups() {
      this.activeDialog = true;
    },
    closePopups() {
      this.activeDialog = false;
      this.formData.email = '';
      this.formData.password = '';
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'

        this.$load(async () => {
          await this.$api.cases.createCaseEmail(this.caseId, this.formData).then(() => {
            this.$emit('addedEmail', null)
            this.closePopups()
          }).catch(err => {
            this.errorMessage = err.response.data.errors.message[0] || err.response.data.errors.email[0] || err.response.data.errors.password[0];
            this.$emit('error', this.errorMessage)
          })
        }, (error) => {
          if(error) {
            if (error.response) {
              this.errorMessage = error.response.data.errors.message[0]
            } else {
              this.errorMessage = error.data.errors.message[0]
            }
            this.$emit('error', this.errorMessage)
          }
        })
      }
    },
  }
};
</script>

<style scoped>
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay.active {
  animation: show .3s linear;
}

.overlay.active.clue {
  overflow-y: scroll;
}

.dialog-clue {
  transform: translate(0, 15%);
}

.common-dialog-wrapper {
  box-sizing: border-box;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: 1px solid #d7dce6;
  border-radius: 30px;
  width: 100%;
  max-width: 520px;
  padding: 32px 10px;
  background: #fff;
  text-align: center;
}

.close-dialog {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 70px;
  padding: 23px;
  cursor: pointer;
}

.close-dialog svg {
  width: 100%;
  height: 100%;
}

.close-dialog svg path {
  transition: .5s all;
}

.close-dialog:hover svg path {
  fill: var(--accent-color);
}

.close-dialog img {
  display: block;
  width: 100%;
}

.common-dialog-wrapper .dialog-btn {
  border-color: var(--accent-color);
  min-width: 140px;
  width: 100%;
  max-width: 140px;
  padding: 13px 12px;
  background-color: var(--white-color);
}

.common-dialog-wrapper .dialog-btn:hover {
  border-color: var(--accent-color);
  background-color: var(--accent-color);
}

.common-dialog-wrapper .dialog-btn:hover .dialog-btn-text {
  color: var(--white-color);
}

.dialog-btn-text {
  display: block;
  overflow: hidden;
  transition: .5s all;
  max-width: 100%;
  font-size: 16px;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--accent-color);
}

/*custom classes */
.common-dialog-wrapper.assign-success {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 588px;
}

label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 25px;
  gap: 10px;
}

input {
  border: 1px solid var(--case-btn-border-color);
  border-radius: 25px;
  height: 48px;
  padding: 0 20px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-black-color);
}

input::placeholder {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: var(--input-placeholder-value-color);
}

button[disabled] {
  pointer-events: none;
}

.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: 0px 60px;
  margin-bottom: 30px;
}

.content h2 {
  font-size: 30px;
}

.content label {
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: #373C44;
}

.content label input {
  width: 100%;
}

.common-dialog-wrapper .btn {
  min-width: 215px;
  height: 48px;
}

.content .form-group {
  width: 100%;
}

.btn.email-integration-btn {
  font-size: 12px;
  line-height: 19px;
  padding: 7px 30px;
  min-width: 120px;
  background-color: var(--white-color);
  color: var(--accent-color);
  border: 1px solid var(--accent-color);
  min-width: 152px;
}

.btn.email-integration-btn:hover {
  background-color: var(--accent-color);
  color: var(--white-color);
}

.form-group--error .form-group__message, .form-group--error .error {
  text-align: left;
}

.title-desc {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--light-gray);
  margin-bottom: 32px;
}

.form-group .label {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: #373C44;
}

.email-integration-checkbox {
  font-weight: 500;
  line-height: 17px;
  color: var(--secondary-text-color);
}

.checkbox-wrap {
  display: flex;
  align-items: center;
  width: 100%;
}

.custom-checkbox-group {
  width: 150px;
}

.custom-checkbox-group .label-checkbox {
  margin: 0;
}

.password-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.generate-password-wrapper {
  display: flex;
  width: 130px;
  cursor: pointer;
}

.generate-password-wrapper svg {
  margin-right: 1px;
}

.generate-password-text {
  font-size: 12px;
  font-weight: 500;
  color: var(--accent-color);
  text-decoration: underline;
}

.clue-wrapper {
  width: 400px;
  height: 715px;
  padding: 32px 15px 32px 24px;
  gap: 40px;
  border-radius: 18px;
  background: var(--active-accent-color);
  box-shadow: 0 0 5px 0 #C8CDD480;
  margin-bottom: 16px;
}

.clue-wrapper p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.clue-title {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

ol {
  padding: 0 15px;
}

ol li {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-bottom: 10px;
}

ol li .complex-list-item {
  display: flex;
  gap: 4px;
}

ol li ul {
  list-style-type: disc;
  margin: 0 0 0 15px;
}

.custom-checkbox-group .label-checkbox {
  cursor: pointer;
  position: relative;
  width: 24px;
  height: 24px;
  background-color: var(--checkbox-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;
  border: var(--checkbox-border);
  border-radius: 1px;
  margin-bottom: 6px;
}

.slide-enter-active, .slide-leave-active {
  transition: all 0.7s ease;
}

.slide-enter, .slide-leave-to {
  height: 0;
  opacity: 0;
  overflow: hidden;
}

</style>
