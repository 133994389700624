<template>
  <div class="cases-filter-block">
    <div class="filter-group">
      <label class="filter-label">
        <span class="group-label-text">Choose cases</span>
      </label>
      <CustomMultiSelect
          :id="`choose_case_filter`"
          :options="testCases"
          placeholder="Select Case"
          customClass="cases-filter"
      />
    </div>
    <div class="filter-group">
      <label class="filter-label">
        <span class="group-label-text">Period</span>
      </label>
      <CustomDatepicker
          placeholder-string="Choose date"
          format="MMMM YYYY"
          :editable="false"
          :multiple="true"
          custom-class="cases-filter"
          rangeSeparator=" - "
      />
    </div>
    <div class="filter-group">
      <label class="filter-label">
        <span class="group-label-text">Choose the number of participants </span>
      </label>
      <CustomRangeSlider id="participants"/>
    </div>

    <div class="filter-group">
      <label class="filter-label">
        <span class="group-label-text">Choose Case Manager</span>
      </label>
      <CustomMultiSelect
          :options="testCaseManagers"
          :id="`choose_case_manager`"
          placeholder="Select Case"
          customClass="case-manager-filter"
      />
    </div>
  </div>
</template>

<script>
import CustomMultiSelect from "../../inputs/CustomMultiSelect";
import CustomDatepicker from "../../inputs/CustomDatepicker";
import CustomRangeSlider from "../../inputs/CustomRangeSlider";

export default {
  name: "CasesFilterBlock",
  data() {
    return {
      testCases: ['case3000', 'case3020', 'case3330'],
      testCaseManagers: ['Guy Hawkins', 'Jane Cooper', 'Kathryn Murphy'],
    }
  },
  components: {
    CustomMultiSelect,
    CustomDatepicker,
    CustomRangeSlider
  }
}
</script>

<style scoped>
.cases-filter-block {
  width: 100%;
  min-height: 400px;
  margin: 31px 0 0 0;
}

.filter-group {
  margin: 0 0 23px 0;
  text-align: left;
}

.filter-group .multiselect {
  cursor: pointer;
}

.group-label-text {
  font-size: 12px;
  font-weight: 500;
  color: var(--secondary-text-color);
}

.filter-label {
  display: block;
  margin-bottom: 10px;
}
</style>