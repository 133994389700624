<template>
  <div class="overlay"
       :class="dialog ? 'active': ''"
       v-if="dialog">
    <div class="popup" v-click-outside="closePopup">
      <div
          :id="`close_case_popup_${caseId}`"
          class="popup-close" @click="closePopup">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M11.9997 0.333496C5.54801 0.333496 0.333008 5.5485 0.333008 12.0002C0.333008 18.4518 5.54801 23.6668 11.9997 23.6668C18.4513 23.6668 23.6663 18.4518 23.6663 12.0002C23.6663 5.5485 18.4513 0.333496 11.9997 0.333496ZM17.833 16.1885L16.188 17.8335L11.9997 13.6452L7.81134 17.8335L6.16634 16.1885L10.3547 12.0002L6.16634 7.81183L7.81134 6.16683L11.9997 10.3552L16.188 6.16683L17.833 7.81183L13.6447 12.0002L17.833 16.1885Z"
              fill="#D7DCE6"/>
        </svg>
      </div>
      <div class="popup-content">
        <h2 class="popup-title">{{ ucFirst }} Case</h2>
        <span class="popup-message">Are you sure you want to {{ actionType }} {{ caseId }}?</span>
        <div
            class="popup-action-wrapper"
            :class="actionType === 'delete' ? 'delete-action':''"
        >
          <div class="popup-action-type" v-if="actionType === 'archive'">
            <div class="action-image archive">
              <svg width="82" height="78" viewBox="0 0 82 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M28.3591 25.9732L27.8782 24.6249C27.5474 23.6981 26.9114 22.9109 26.0742 22.3919C25.237 21.873 24.2482 21.653 23.2695 21.7681L4.11734 24.0047C2.97001 24.139 1.92312 24.7229 1.2069 25.6277C0.490686 26.5326 0.163793 27.6845 0.298123 28.83L5.74842 75.3621C5.79367 75.7513 5.91533 76.1278 6.10644 76.47C6.29755 76.8122 6.55436 77.1134 6.86218 77.3564C7.17 77.5995 7.52279 77.7795 7.90037 77.8863C8.27795 77.9931 8.67292 78.0245 9.06268 77.9788L77.5921 69.9767C77.9826 69.9325 78.3606 69.8117 78.7043 69.6214C79.0479 69.431 79.3506 69.1747 79.5948 68.8673C79.8391 68.5599 80.0201 68.2074 80.1276 67.8299C80.2351 67.4525 80.2668 67.0576 80.2211 66.6678L75.4921 26.4413C75.4007 25.6561 75.0006 24.9393 74.3798 24.4486C73.759 23.9578 72.9684 23.7334 72.1819 23.8247L32.3867 28.4739C31.5311 28.5727 30.6672 28.3794 29.9358 27.9252C29.2044 27.4711 28.6487 26.7831 28.3591 25.9732Z"
                    fill="#082655"/>
                <path d="M30.2721 5.73822L8.62012 10.0459L14.5647 39.8282L36.2167 35.5206L30.2721 5.73822Z"
                      fill="#082655"/>
                <path opacity="0.7"
                      d="M30.2721 5.73822L8.62012 10.0459L14.5647 39.8282L36.2167 35.5206L30.2721 5.73822Z"
                      fill="white"/>
                <g opacity="0.4">
                  <path opacity="0.4"
                        d="M27.3821 12.8946L14.0449 15.5512C13.7342 15.6115 13.4123 15.5469 13.1489 15.3716C12.8856 15.1964 12.7021 14.9245 12.6382 14.615C12.5779 14.3048 12.6426 13.9834 12.8181 13.7205C12.9937 13.4576 13.266 13.2744 13.576 13.2106L26.9212 10.5659C27.2319 10.5057 27.5539 10.5703 27.8172 10.7455C28.0806 10.9208 28.264 11.1927 28.3279 11.5022C28.3845 11.8117 28.3171 12.1311 28.1402 12.3915C27.9633 12.6519 27.6911 12.8325 27.3821 12.8946Z"
                        fill="#082655"/>
                  <path opacity="0.4"
                        d="M28.3637 17.8282L15.0265 20.4969C14.7164 20.5559 14.3955 20.4908 14.133 20.3157C13.8706 20.1405 13.6877 19.8693 13.6238 19.5606C13.5647 19.2511 13.6299 18.9307 13.8053 18.6686C13.9808 18.4066 14.2524 18.224 14.5616 18.1603L27.8988 15.5076C28.2089 15.4485 28.5298 15.5136 28.7922 15.6888C29.0547 15.864 29.2376 16.1352 29.3014 16.4438C29.356 16.751 29.2886 17.0673 29.1135 17.3257C28.9385 17.5841 28.6695 17.7643 28.3637 17.8282Z"
                        fill="#082655"/>
                  <path opacity="0.4"
                        d="M29.3441 22.7525L16.0069 25.4052C15.6968 25.4642 15.3759 25.3991 15.1135 25.224C14.8511 25.0488 14.6682 24.7776 14.6043 24.4689C14.5452 24.1593 14.6104 23.839 14.7858 23.5769C14.9613 23.3149 15.2329 23.1323 15.5421 23.0686L28.8793 20.4119C29.1894 20.3528 29.5103 20.4179 29.7727 20.5931C30.0352 20.7683 30.218 21.0395 30.2819 21.3481C30.3422 21.6583 30.2776 21.9797 30.102 22.2427C29.9265 22.5056 29.6541 22.6888 29.3441 22.7525Z"
                        fill="#082655"/>
                  <path opacity="0.4"
                        d="M30.3313 27.6985L16.9941 30.3512C16.6834 30.4115 16.3615 30.3469 16.0981 30.1716C15.8348 29.9964 15.6513 29.7245 15.5875 29.415C15.5272 29.1048 15.5918 28.7834 15.7674 28.5205C15.9429 28.2576 16.2152 28.0744 16.5252 28.0106L29.8624 25.3579C30.1731 25.2977 30.4951 25.3623 30.7584 25.5375C31.0218 25.7128 31.2053 25.9847 31.2691 26.2942C31.3294 26.6044 31.2648 26.9258 31.0892 27.1887C30.9136 27.4516 30.6413 27.6348 30.3313 27.6985Z"
                        fill="#082655"/>
                  <path opacity="0.4"
                        d="M27.6658 33.3277L17.9755 35.2562C17.6654 35.3152 17.3444 35.2501 17.082 35.075C16.8196 34.8998 16.6367 34.6286 16.5728 34.3199C16.5125 34.0097 16.5771 33.6883 16.7527 33.4254C16.9283 33.1625 17.2006 32.9793 17.5106 32.9156L27.2009 30.9871C27.511 30.928 27.8319 30.9931 28.0943 31.1683C28.3568 31.3435 28.5397 31.6147 28.6035 31.9233C28.6638 32.2335 28.5992 32.5549 28.4236 32.8179C28.2481 33.0808 27.9758 33.264 27.6658 33.3277Z"
                        fill="#082655"/>
                </g>
                <path d="M61.123 -0.00126938L39.249 2.98633L43.3719 33.074L65.2458 30.0864L61.123 -0.00126938Z"
                      fill="#082655"/>
                <path opacity="0.7"
                      d="M61.123 -0.00126938L39.249 2.98633L43.3719 33.074L65.2458 30.0864L61.123 -0.00126938Z"
                      fill="white"/>
                <g opacity="0.4">
                  <path opacity="0.4"
                        d="M57.8029 6.97248L44.3294 8.81296C44.0156 8.85405 43.6983 8.76997 43.4462 8.57897C43.1941 8.38797 43.0277 8.10545 42.9829 7.79269C42.9417 7.47943 43.0259 7.16261 43.2172 6.91094C43.4085 6.65927 43.6915 6.49305 44.0048 6.44834L57.4783 4.60786C57.792 4.56676 58.1094 4.65084 58.3614 4.84184C58.6135 5.03284 58.78 5.31536 58.8248 5.62812C58.866 5.94138 58.7817 6.25821 58.5904 6.50988C58.3991 6.76155 58.1161 6.92777 57.8029 6.97248Z"
                        fill="#082655"/>
                  <path opacity="0.4"
                        d="M58.484 11.9471L45.0105 13.7875C44.6968 13.8286 44.3794 13.7446 44.1274 13.5536C43.8753 13.3626 43.7088 13.08 43.664 12.7673C43.624 12.4547 43.7087 12.1389 43.8999 11.8881C44.0911 11.6373 44.3735 11.4717 44.6859 11.4269L58.1594 9.58645C58.4725 9.54528 58.7894 9.62881 59.0413 9.81898C59.2933 10.0092 59.4602 10.2907 59.5059 10.6027C59.5471 10.916 59.4629 11.2328 59.2716 11.4845C59.0803 11.7361 58.7973 11.9024 58.484 11.9471Z"
                        fill="#082655"/>
                  <path opacity="0.4"
                        d="M49.4047 18.2571L45.6937 18.7653C45.3799 18.8063 45.0626 18.7223 44.8105 18.5313C44.5584 18.3403 44.3919 18.0577 44.3471 17.745C44.306 17.4317 44.3902 17.1149 44.5815 16.8632C44.7728 16.6116 45.0558 16.4453 45.3691 16.4006L49.0841 15.8925C49.3972 15.8525 49.7135 15.9371 49.9647 16.128C50.2159 16.3189 50.3818 16.6008 50.4266 16.9128C50.4678 17.226 50.3836 17.5429 50.1923 17.7945C50.0009 18.0462 49.718 18.2124 49.4047 18.2571Z"
                        fill="#082655"/>
                  <path opacity="0.4"
                        d="M56.6059 17.2723L52.8908 17.7804C52.5777 17.8204 52.2614 17.7358 52.0102 17.5449C51.759 17.354 51.5931 17.0721 51.5483 16.7601C51.5071 16.4469 51.5914 16.13 51.7827 15.8784C51.974 15.6267 52.257 15.4605 52.5702 15.4158L56.2812 14.9116C56.5944 14.8705 56.9112 14.954 57.1632 15.1442C57.4152 15.3343 57.5821 15.6159 57.6278 15.9279C57.6689 16.2412 57.5847 16.558 57.3934 16.8097C57.2021 17.0613 56.9191 17.2276 56.6059 17.2723Z"
                        fill="#082655"/>
                  <path opacity="0.4"
                        d="M59.8483 21.8973L46.3748 23.7378C46.061 23.7789 45.7437 23.6948 45.4916 23.5038C45.2395 23.3128 45.073 23.0303 45.0283 22.7175C44.9882 22.4049 45.073 22.0891 45.2642 21.8383C45.4554 21.5875 45.7377 21.4219 46.0502 21.3771L59.5236 19.5327C59.8374 19.4916 60.1548 19.5756 60.4068 19.7666C60.6589 19.9576 60.8254 20.2402 60.8702 20.5529C60.9113 20.8662 60.8271 21.183 60.6358 21.4347C60.4445 21.6864 60.1615 21.8526 59.8483 21.8973Z"
                        fill="#082655"/>
                  <path opacity="0.4"
                        d="M56.8495 27.3743L47.055 28.7106C46.7424 28.7507 46.4267 28.6666 46.1756 28.4766C45.9245 28.2865 45.7582 28.0056 45.7124 27.6944C45.6712 27.3817 45.7549 27.0654 45.9454 26.8139C46.1358 26.5623 46.4178 26.3957 46.7304 26.35L56.5249 25.0137C56.8374 24.9736 57.1532 25.0577 57.4043 25.2478C57.6553 25.4378 57.8216 25.7187 57.8674 26.0299C57.9086 26.3426 57.825 26.6589 57.6345 26.9105C57.444 27.162 57.162 27.3286 56.8495 27.3743Z"
                        fill="#082655"/>
                </g>
                <path d="M40.4826 4.38836L18.4883 6.30176L21.1288 36.555L43.1231 34.6416L40.4826 4.38836Z"
                      fill="#082655"/>
                <path opacity="0.8" d="M40.4826 4.38836L18.4883 6.30176L21.1288 36.555L43.1231 34.6416L40.4826 4.38836Z"
                      fill="white"/>
                <g opacity="0.4">
                  <path opacity="0.4"
                        d="M36.8233 11.1984L23.2737 12.3747C22.9584 12.4003 22.6458 12.3009 22.4035 12.0979C22.1612 11.8949 22.0088 11.6048 21.9792 11.2905C21.9547 10.9764 22.0548 10.6653 22.258 10.4243C22.4612 10.1832 22.7511 10.0316 23.0653 10.0021L36.6149 8.8018C36.9301 8.77621 37.2428 8.87567 37.4851 9.07863C37.7273 9.28159 37.8798 9.57169 37.9093 9.88608C37.9248 10.0435 37.9089 10.2025 37.8626 10.3538C37.8163 10.5051 37.7406 10.6458 37.6396 10.7677C37.5387 10.8897 37.4146 10.9905 37.2745 11.0644C37.1344 11.1384 36.9811 11.1839 36.8233 11.1984Z"
                        fill="#082655"/>
                  <path opacity="0.4"
                        d="M37.2591 16.1999L23.7095 17.4002C23.3943 17.4258 23.0816 17.3263 22.8393 17.1234C22.5971 16.9204 22.4446 16.6303 22.4151 16.3159C22.3895 16.0012 22.4891 15.6891 22.6924 15.4472C22.8957 15.2053 23.1862 15.0531 23.5011 15.0236L37.0507 13.8233C37.366 13.7977 37.6786 13.8972 37.9209 14.1001C38.1632 14.3031 38.3156 14.5932 38.3452 14.9076C38.3708 15.2223 38.2712 15.5344 38.0679 15.7763C37.8646 16.0182 37.574 16.1704 37.2591 16.1999Z"
                        fill="#082655"/>
                  <path opacity="0.4"
                        d="M37.6966 21.2009L24.147 22.4012C23.8318 22.4268 23.5191 22.3273 23.2769 22.1244C23.0346 21.9214 22.8821 21.6313 22.8526 21.3169C22.827 21.0022 22.9266 20.69 23.1299 20.4481C23.3332 20.2062 23.6237 20.0541 23.9386 20.0246L37.4882 18.8243C37.8035 18.7987 38.1161 18.8981 38.3584 19.1011C38.6007 19.3041 38.7531 19.5942 38.7827 19.9085C38.8083 20.2233 38.7087 20.5354 38.5054 20.7773C38.3021 21.0192 38.0115 21.1714 37.6966 21.2009Z"
                        fill="#082655"/>
                  <path opacity="0.4"
                        d="M38.141 26.2023L24.5914 27.4026C24.2761 27.4282 23.9635 27.3287 23.7212 27.1257C23.4789 26.9228 23.3265 26.6327 23.2969 26.3183C23.2713 26.0036 23.3709 25.6914 23.5742 25.4495C23.7775 25.2076 24.0681 25.0554 24.383 25.026L37.9326 23.8497C38.2478 23.8241 38.5605 23.9235 38.8028 24.1265C39.045 24.3294 39.1975 24.6195 39.227 24.9339C39.2461 25.2447 39.1435 25.5507 38.9408 25.7873C38.7382 26.024 38.4514 26.1728 38.141 26.2023Z"
                        fill="#082655"/>
                  <path opacity="0.4"
                        d="M34.867 31.528L25.0204 32.3842C24.7032 32.4132 24.3874 32.3152 24.1425 32.1119C23.8975 31.9087 23.7434 31.6166 23.7139 31.3C23.6883 30.9852 23.7879 30.6731 23.9912 30.4312C24.1945 30.1893 24.4851 30.0371 24.8 30.0076L34.6466 29.1514C34.9618 29.1258 35.2745 29.2253 35.5168 29.4282C35.759 29.6312 35.9115 29.9213 35.941 30.2357C35.9668 30.5485 35.8688 30.859 35.668 31.1006C35.4673 31.3422 35.1797 31.4956 34.867 31.528Z"
                        fill="#082655"/>
                </g>
                <path d="M67.2826 2.9191L45.2305 3.98047L46.6951 34.3132L68.7472 33.2518L67.2826 2.9191Z"
                      fill="#082655"/>
                <path opacity="0.8" d="M67.2826 2.9191L45.2305 3.98047L46.6951 34.3132L68.7472 33.2518L67.2826 2.9191Z"
                      fill="white"/>
                <g opacity="0.4">
                  <path opacity="0.4"
                        d="M63.3663 9.57209L49.7846 10.2283C49.469 10.2414 49.1609 10.1299 48.9269 9.91805C48.6929 9.70616 48.5519 9.41085 48.5342 9.09596C48.5211 8.78086 48.6327 8.47326 48.845 8.23966C49.0572 8.00606 49.353 7.86524 49.6684 7.84764L63.254 7.19147C63.5697 7.17836 63.8778 7.2898 64.1118 7.50168C64.3457 7.71357 64.4868 8.00888 64.5044 8.32376C64.5175 8.63953 64.4053 8.94771 64.1923 9.18142C63.9792 9.41513 63.6824 9.5555 63.3663 9.57209Z"
                        fill="#082655"/>
                  <path opacity="0.4"
                        d="M63.6105 14.5898L50.0248 15.2419C49.7092 15.255 49.4011 15.1436 49.1671 14.9317C48.9332 14.7198 48.7921 14.4245 48.7745 14.1096C48.7614 13.7939 48.8735 13.4857 49.0866 13.252C49.2997 13.0183 49.5965 12.8779 49.9126 12.8613L63.4943 12.2051C63.8105 12.1921 64.1192 12.304 64.3533 12.5168C64.5874 12.7295 64.728 13.0258 64.7446 13.3414C64.7578 13.6565 64.6461 13.9641 64.4339 14.1977C64.2217 14.4313 63.9259 14.5722 63.6105 14.5898Z"
                        fill="#082655"/>
                  <path opacity="0.4"
                        d="M54.0121 20.0956L50.269 20.2757C49.9534 20.2888 49.6453 20.1773 49.4113 19.9654C49.1773 19.7536 49.0362 19.4583 49.0186 19.1434C49.0055 18.8283 49.1171 18.5207 49.3293 18.2871C49.5416 18.0535 49.8374 17.9126 50.1528 17.895L53.8958 17.711C54.2121 17.6979 54.5208 17.8099 54.7549 18.0226C54.989 18.2354 55.1296 18.5317 55.1462 18.8473C55.1593 19.1624 55.0477 19.47 54.8355 19.7036C54.6232 19.9372 54.3275 20.078 54.0121 20.0956Z"
                        fill="#082655"/>
                  <path opacity="0.4"
                        d="M61.2699 19.7274L57.5268 19.9074C57.2112 19.9205 56.9031 19.8091 56.6691 19.5972C56.4351 19.3853 56.2941 19.09 56.2764 18.7751C56.2633 18.46 56.3749 18.1524 56.5871 17.9188C56.7994 17.6852 57.0952 17.5444 57.4106 17.5268L61.1536 17.3467C61.4693 17.3336 61.7774 17.4451 62.0113 17.657C62.2453 17.8688 62.3864 18.1642 62.404 18.479C62.4171 18.7941 62.3055 19.1017 62.0933 19.3353C61.8811 19.5689 61.5853 19.7098 61.2699 19.7274Z"
                        fill="#082655"/>
                  <path opacity="0.4"
                        d="M64.0949 24.62L50.5092 25.2722C50.1936 25.2853 49.8855 25.1739 49.6515 24.962C49.4175 24.7501 49.2765 24.4548 49.2588 24.1399C49.2457 23.8241 49.3579 23.516 49.571 23.2822C49.7841 23.0485 50.0809 22.9082 50.397 22.8916L63.9786 22.2354C64.2949 22.2223 64.6036 22.3343 64.8377 22.547C65.0718 22.7598 65.2124 23.0561 65.229 23.3717C65.2421 23.6868 65.1305 23.9944 64.9183 24.228C64.7061 24.4616 64.4103 24.6024 64.0949 24.62Z"
                        fill="#082655"/>
                  <path opacity="0.4"
                        d="M60.625 29.8143L50.7543 30.2904C50.4387 30.3035 50.1306 30.1921 49.8966 29.9802C49.6626 29.7683 49.5216 29.473 49.504 29.1581C49.4908 28.843 49.6025 28.5354 49.8147 28.3018C50.0269 28.0682 50.3227 27.9274 50.6381 27.9098L60.5087 27.4337C60.8244 27.4205 61.1325 27.532 61.3665 27.7439C61.6004 27.9558 61.7415 28.2511 61.7591 28.566C61.7722 28.8811 61.6606 29.1887 61.4484 29.4223C61.2362 29.6559 60.9404 29.7967 60.625 29.8143Z"
                        fill="#082655"/>
                </g>
                <path
                    d="M34.4741 26.8343L34.1214 25.518C33.8743 24.6069 33.3006 23.8183 32.5093 23.3019C31.718 22.7856 30.7642 22.5775 29.8293 22.7172L10.6771 24.9538C9.51518 25.096 8.4451 25.6564 7.66748 26.53C6.88985 27.4036 6.45809 28.5304 6.45312 29.6991L7.53918 75.2509C7.54566 75.6261 7.63252 75.9956 7.79393 76.3345C7.95534 76.6734 8.18755 76.9738 8.47495 77.2156C8.76235 77.4575 9.09827 77.6351 9.46014 77.7365C9.82201 77.8379 10.2014 77.8608 10.5729 77.8036L79.1023 69.8015C79.898 69.7052 80.6312 69.3225 81.1646 68.7252C81.698 68.1278 81.9949 67.3569 81.9998 66.5567L81.062 27.1784C81.055 26.803 80.9676 26.4335 80.8058 26.0946C80.6439 25.7557 80.4113 25.4553 80.1236 25.2136C79.8359 24.9718 79.4997 24.7943 79.1377 24.6929C78.7756 24.5915 78.396 24.5686 78.0243 24.6257L38.2331 29.2709C37.4163 29.3954 36.5822 29.2161 35.8891 28.7668C35.1961 28.3176 34.6925 27.6299 34.4741 26.8343Z"
                    fill="#082655"/>
                <path opacity="0.3"
                      d="M34.4741 26.8343L34.1214 25.518C33.8743 24.6069 33.3006 23.8183 32.5093 23.3019C31.718 22.7856 30.7642 22.5775 29.8293 22.7172L10.6771 24.9538C9.51518 25.096 8.4451 25.6564 7.66748 26.53C6.88985 27.4036 6.45809 28.5304 6.45312 29.6991L7.53918 75.2509C7.54566 75.6261 7.63252 75.9956 7.79393 76.3345C7.95534 76.6734 8.18755 76.9738 8.47495 77.2156C8.76235 77.4575 9.09827 77.6351 9.46014 77.7365C9.82201 77.8379 10.2014 77.8608 10.5729 77.8036L79.1023 69.8015C79.898 69.7052 80.6312 69.3225 81.1646 68.7252C81.698 68.1278 81.9949 67.3569 81.9998 66.5567L81.062 27.1784C81.055 26.803 80.9676 26.4335 80.8058 26.0946C80.6439 25.7557 80.4113 25.4553 80.1236 25.2136C79.8359 24.9718 79.4997 24.7943 79.1377 24.6929C78.7756 24.5915 78.396 24.5686 78.0243 24.6257L38.2331 29.2709C37.4163 29.3954 36.5822 29.2161 35.8891 28.7668C35.1961 28.3176 34.6925 27.6299 34.4741 26.8343Z"
                      fill="black"/>
              </svg>
            </div>
            <span class="action-message">After archiving you can restore this case in the Case list.</span>
            <span class="action-sub-message">Payment for archived case consists <strong>${{ archivePayment }}.</strong></span>
          </div>
          <div class="popup-action-type" v-if="actionType === 'export'">
            <div class="action-image export">
              <svg width="93" height="89" viewBox="0 0 93 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M44.4072 22.8554H38.0999C37.8365 22.8384 37.5894 22.7222 37.4089 22.5303C37.2284 22.3384 37.1279 22.0853 37.1279 21.8222C37.1279 21.5592 37.2284 21.306 37.4089 21.1142C37.5894 20.9223 37.8365 20.806 38.0999 20.7891H44.4072C44.6706 20.806 44.9176 20.9223 45.0981 21.1142C45.2786 21.306 45.3791 21.5592 45.3791 21.8222C45.3791 22.0853 45.2786 22.3384 45.0981 22.5303C44.9176 22.7222 44.6706 22.8384 44.4072 22.8554Z"
                    fill="#082655"/>
                <g opacity="0.7">
                  <path opacity="0.7"
                        d="M44.4072 22.8554H38.0999C37.8365 22.8384 37.5894 22.7222 37.4089 22.5303C37.2284 22.3384 37.1279 22.0853 37.1279 21.8222C37.1279 21.5592 37.2284 21.306 37.4089 21.1142C37.5894 20.9223 37.8365 20.806 38.0999 20.7891H44.4072C44.6706 20.806 44.9176 20.9223 45.0981 21.1142C45.2786 21.306 45.3791 21.5592 45.3791 21.8222C45.3791 22.0853 45.2786 22.3384 45.0981 22.5303C44.9176 22.7222 44.6706 22.8384 44.4072 22.8554Z"
                        fill="white"/>
                </g>
                <path
                    d="M67.5456 44.0408L43.3639 88.3872C43.2139 88.661 42.961 88.8643 42.6607 88.9524C42.3604 89.0406 42.0373 89.0064 41.7622 88.8574L0.61539 66.5634C0.340634 66.4139 0.136636 66.1619 0.0481704 65.8626C-0.0402949 65.5634 -0.00599695 65.2414 0.143534 64.9673L31.5935 7.29976C31.7435 7.02597 31.9965 6.82268 32.2968 6.73453C32.597 6.64637 32.9202 6.68055 33.1952 6.82955L60.9006 21.8418L67.5456 44.0408Z"
                    fill="#B0EDF0"/>
                <g opacity="0.2">
                  <path opacity="0.2"
                        d="M67.5456 44.0408L43.3639 88.3872C43.2139 88.661 42.961 88.8643 42.6607 88.9524C42.3604 89.0406 42.0373 89.0064 41.7622 88.8574L0.61539 66.5634C0.340634 66.4139 0.136636 66.1619 0.0481704 65.8626C-0.0402949 65.5634 -0.00599695 65.2414 0.143534 64.9673L31.5935 7.29976C31.7435 7.02597 31.9965 6.82268 32.2968 6.73453C32.597 6.64637 32.9202 6.68055 33.1952 6.82955L60.9006 21.8418L67.5456 44.0408Z"
                        fill="#CFF4F6"/>
                </g>
                <path d="M53.9679 53.9985L23.2383 37.4756L22.2105 39.3738L52.94 55.8967L53.9679 53.9985Z"
                      fill="#082655"/>
                <path d="M49.7716 61.7533L19.042 45.2305L18.0142 47.1287L48.7437 63.6516L49.7716 61.7533Z"
                      fill="#082655"/>
                <path d="M45.5694 69.5121L14.8398 52.9893L13.812 54.8875L44.5416 71.4104L45.5694 69.5121Z"
                      fill="#082655"/>
                <path
                    d="M60.957 21.8193L53.667 35.1923C53.5174 35.4663 53.4831 35.7883 53.5716 36.0876C53.6601 36.3868 53.8641 36.6389 54.1388 36.7884L67.5197 44.0357L60.957 21.8193Z"
                    fill="#082655"/>
                <path opacity="0.7"
                      d="M60.957 21.8193L53.667 35.1923C53.5174 35.4663 53.4831 35.7883 53.5716 36.0876C53.6601 36.3868 53.8641 36.6389 54.1388 36.7884L67.5197 44.0357L60.957 21.8193Z"
                      fill="white"/>
                <path
                    d="M73.8483 42.5477L49.671 86.894C49.521 87.1678 49.268 87.3711 48.9677 87.4593C48.6674 87.5474 48.3443 87.5133 48.0693 87.3642L6.92244 65.0875C6.64694 64.9387 6.44201 64.687 6.35271 64.3877C6.26342 64.0884 6.29707 63.766 6.44626 63.4914L37.8962 5.81091C38.0455 5.53637 38.2981 5.33215 38.5985 5.24317C38.8988 5.15418 39.2224 5.18771 39.4979 5.33639L67.225 20.3486L73.8483 42.5477Z"
                    fill="#082655"/>
                <g opacity="0.5">
                  <path opacity="0.5"
                        d="M73.8483 42.5477L49.671 86.894C49.521 87.1678 49.268 87.3711 48.9677 87.4593C48.6674 87.5474 48.3443 87.5133 48.0693 87.3642L6.92244 65.0875C6.64694 64.9387 6.44201 64.687 6.35271 64.3877C6.26342 64.0884 6.29707 63.766 6.44626 63.4914L37.8962 5.81091C38.0455 5.53637 38.2981 5.33215 38.5985 5.24317C38.8988 5.15418 39.2224 5.18771 39.4979 5.33639L67.225 20.3486L73.8483 42.5477Z"
                        fill="#082655"/>
                </g>
                <path
                    d="M59.4627 52.0596L30.372 36.4219C30.2647 36.3637 30.1468 36.3272 30.0253 36.3146C29.9037 36.3019 29.7809 36.3134 29.6638 36.3483C29.5467 36.3831 29.4377 36.4408 29.343 36.5178C29.2484 36.5949 29.17 36.6898 29.1123 36.7972L28.9694 37.0604C28.9109 37.1677 28.8742 37.2854 28.8615 37.4069C28.8487 37.5283 28.8602 37.6511 28.8952 37.7681C28.9302 37.8852 28.988 37.9942 29.0654 38.0889C29.1428 38.1836 29.2382 38.2621 29.3461 38.32L58.4367 53.9577C58.6538 54.0741 58.9083 54.1 59.1445 54.0296C59.3806 53.9593 59.5792 53.7984 59.6965 53.5824L59.8393 53.3149C59.8977 53.208 59.9344 53.0905 59.947 52.9694C59.9597 52.8483 59.9482 52.7258 59.9132 52.6092C59.8782 52.4925 59.8204 52.3838 59.7431 52.2895C59.6657 52.1952 59.5705 52.1171 59.4627 52.0596Z"
                    fill="white"/>
                <path
                    d="M55.2572 59.8217L26.1658 44.1797C25.7134 43.9364 25.1488 44.1047 24.9047 44.5555L24.7608 44.8213C24.5167 45.272 24.6856 45.8347 25.138 46.0779L54.2293 61.7199C54.6817 61.9632 55.2463 61.7949 55.4904 61.3441L55.6343 61.0784C55.8784 60.6276 55.7095 60.0649 55.2572 59.8217Z"
                    fill="white"/>
                <path
                    d="M51.0589 67.5854L21.9675 51.9433C21.5152 51.7001 20.9506 51.8684 20.7065 52.3192L20.5626 52.5849C20.3185 53.0357 20.4873 53.5983 20.9397 53.8416L50.0311 69.4836C50.4835 69.7268 51.0481 69.5586 51.2922 69.1078L51.436 68.842C51.6801 68.3912 51.5113 67.8286 51.0589 67.5854Z"
                    fill="white"/>
                <path
                    d="M67.2633 20.3311L59.969 33.704C59.8947 33.8395 59.848 33.9883 59.8317 34.1419C59.8154 34.2955 59.8298 34.4508 59.8741 34.5988C59.9184 34.7468 59.9916 34.8846 60.0897 35.0043C60.1877 35.1239 60.3085 35.223 60.4452 35.2958L73.8261 42.5431L67.2633 20.3311Z"
                    fill="#082655"/>
                <path opacity="0.7"
                      d="M67.2633 20.3311L59.969 33.704C59.8947 33.8395 59.848 33.9883 59.8317 34.1419C59.8154 34.2955 59.8298 34.4508 59.8741 34.5988C59.9184 34.7468 59.9916 34.8846 60.0897 35.0043C60.1877 35.1239 60.3085 35.223 60.4452 35.2958L73.8261 42.5431L67.2633 20.3311Z"
                      fill="white"/>
                <path
                    d="M65.5934 0L67.628 9.003C59.9225 11.1082 53.0091 18.7092 48.0351 25.8874C45.9995 28.8682 44.3624 32.1006 43.165 35.503C43.165 35.503 58.706 21.4959 67.2731 20.6763L66.8618 35.5547C66.8618 35.5547 75.7492 18.2993 93.0001 12.0011C93.0174 11.9968 78.55 1.62632 65.5934 0Z"
                    fill="#0A8B93"/>
              </svg>
            </div>
            <span class="action-message">Attention!</span>
            <span
                class="action-sub-message">After exporting this Case all files<br><strong>will be deleted.</strong></span>
          </div>
          <div class="popup-action-type" v-if="actionType === 'delete'">
            <div class="action-image delete">
              <img :src="caseDeletionIcon" alt="case-delete" />
            </div>
            <span class="action-message">Attention!</span>
            <span
                class="action-sub-message">After deleting you can not restore this Case.</span>
          </div>
        </div>
        <button
            :id="`${actionType}_case_popup_${caseId}`"
            class="btn popup-action-btn"
            @click="$emit('confirm')"
        >
          <span class="action-btn-text">{{ ucFirst }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import caseDeletionIcon from '@/assets/img/case-deletion.svg';
export default {
  name: "CaseActionsPopup",
  props: {
    actionType: {
      type: String,
      required: true
    },
    caseId: {
      type: String,
      required: true,
    },
    archivePayment: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      caseDeletionIcon,
      dialog: false
    }
  },
  methods: {
    openPopup() {
      this.dialog = true
    },
    closePopup() {
      this.dialog = false
    }
  },
  computed: {
    ucFirst() {
      let str = this.actionType[0].toUpperCase() + this.actionType.slice(1)
      return str
    }
  }
}
</script>

<style scoped>

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.overlay.active {
  animation: show .3s linear;
}

.popup {
  position: absolute;
  padding: 52px 128px 30px 128px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 588px;
  min-height: 450px;
  border-radius: 30px;
  background-color: var(--white-color);
}

.popup-close {
  position: absolute;
  right: 0;
  top: 0;
  width: 70px;
  height: 70px;
  padding: 23px;
  cursor: pointer;
}

.popup-close svg path {
  transition: .5s all;
}

.popup-close:hover svg path {
  fill: var(--accent-color);
}

.popup-title {
  font-size: 30px;
  line-height: 30px;
  color: var(--black-color);
  font-weight: 700;
  text-align: center;
  margin: 0;
}

.popup-message {
  margin: 10px 0 0 0;
  text-align: center;
  display: block;
  color: var(--light-gray);
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
}

.popup-action-wrapper {
  margin: 30px 0 0 0;
  padding: 20px 20px 16px 20px;
  width: 100%;
  min-height: 213px;
  background-color: rgba(241, 16, 95, 0.05);
  border-radius: 6px;
}

.delete-action {
  padding: 20px 18px 16px 18px;
}

.action-image.archive {
  margin: 0 auto;
  width: 81px;
  height: 78px;
}

.action-image.export {
  margin: 0 auto;
  width: 93px;
  height: 89px;
}

.action-image.delete {
  margin: 0 auto;
  width: 73px;
  height: 93px;
}

.action-message {
  display: block;
  margin: 26px 0 0 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--error-color);
  text-align: center;
}

.action-sub-message {
  display: block;
  margin: 17px 0 0 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--error-color);
  text-align: center;
}

.action-sub-message strong {
  font-size: 18px;
}

.popup-action-btn {
  padding: 15px 50px;
  width: 100%;
  max-width: 152px;
  margin: 18px auto 0;
  background-color: var(--white-color);
  border-color: var(--accent-color);
  justify-content: center;
  border-radius: 25px;
}

.popup-action-btn:hover {
  background-color: var(--accent-color);
}

.popup-action-btn:hover .action-btn-text {
  color: var(--white-color);
}

.action-btn-text {
  display: block;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  color: var(--accent-color);
  transition: .5s all;
}
</style>
