<template>
  <div>
    <Header/>
    <router-view></router-view>
  </div>
</template>

<script>
import Header from "../components/layout/Header";

export default {
  name: "Register",
  components: {
    Header,
  },
  data() {
    return {
      formData: {
        fullName: "",
        email: null,
        companyName: null,
        numberOfEmployees: null,
        referral: null,
        terms: false
      },
    };
  },
  mounted() {
    document.title = 'Register'
  },
  destroyed() {
    localStorage.removeItem('step')
  }
}

</script>

<style>

.vue-step-wizard {
  background: transparent;
  width: auto;
  padding: 0;
}

.step-header {
  position: relative;
}

.step-progress {
  position: absolute;
  z-index: 1;
  width: 100%;
  bottom: 79px;
  margin: 0;
  height: 1px;
  background: #D7DCE6;
  display: none;
}

.step-pills {
  position: relative;
  z-index: 2;
  background: transparent;
  padding: 0;
  border-radius: 0;
  box-shadow: none !important;
  margin-top: 0;
  margin-bottom: 50px;
}


.step-pills .step-item {
  width: 25%;
}

.step-pills .step-item.active {
  border: none;
}

.step-pills .step-item.validated {
  border: none;
}

.tabStatus {
  width: 46px;
  height: 46px;
  margin-right: 0;
  line-height: initial;
  color: var(--input-placeholder-value-color);
  text-align: center;
  background: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #D7DCE6;
  background: #fff;
  position: relative;
  z-index: 2;
}

.tabStatus:after {
  content: '';
  position: absolute;
  left: calc(100% + 1px);
  top: 50%;
  display: block;
  z-index: 1;
  width: 260px;
  margin: 0;
  height: 1px;
  background: #D7DCE6;
}

.step-pills .step-item {
  background-color: transparent;
  border-radius: 0px;
  padding: 0;
  list-style-type: none;
}

.step-pills .step-item .step-link {
  text-decoration: none;
  color: #7b7b7b;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.tabLabel {
  display: block;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: var(--input-placeholder-value-color);
  margin-top: 20px;
}

.step-progress .bar {
  height: 1px;
}

.step-pills .step-item.active .tabStatus {
  border: 1px solid var(--accent-color);
  color: var(--accent-color);
}

.step-pills .step-item.validated .tabStatus {
  border: 1px solid var(--accent-color);
  color: var(--white-color);
  background: var(--accent-color);
}

.step-pills .step-item:last-child .tabStatus:after {
  display: none;
}

.step-pills .step-item.validated .tabStatus:after {
  background: var(--accent-color);
}

.step-pills .step-item.active .tabLabel {
  color: var(--accent-color);
}

.step-pills .step-item.validated .tabLabel {
  color: var(--accent-color);
}

.register-info {
  margin-top: 0;
  margin-bottom: 50px;
}

.main-title {
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: var(--black-color);
}

.main-description {
  margin: 0;
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--secondary-black-color);
}

.form-group {
  margin-bottom: 20px;
}

.form-group:last-child {
  margin-bottom: 0;
}

.form-group label {
  display: block;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--secondary-black-color);
  margin-bottom: 10px;
  cursor: pointer;
}

.form-group input {
  width: 100%;
  border: 1px solid var(--input-placeholder-value-color);
  border-radius: 25px;
  padding: 15px 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--secondary-black-color);
}

.form-group input::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--input-placeholder-value-color);
}

.form-group input:focus {
  outline: none;
}

.form-group .input-icon-wrapper {
  position: relative;
}

.form-group .input-icon-wrapper svg {
  position: absolute;
  left: 22px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.form-group .input-icon-wrapper input {
  padding-left: 60px;
  background: transparent;
}



</style>