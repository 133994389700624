import { render, staticRenderFns } from "./BillingHistory.vue?vue&type=template&id=2e54dff6&scoped=true"
import script from "./BillingHistory.vue?vue&type=script&lang=js"
export * from "./BillingHistory.vue?vue&type=script&lang=js"
import style0 from "./BillingHistory.vue?vue&type=style&index=0&id=2e54dff6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e54dff6",
  null
  
)

export default component.exports