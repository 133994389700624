<template>
  <Transition name="fade">
    <div v-if="visibleAlertMsg" class="alert-notification">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.026 8.03594L5.62605 16.2859C5.48577 16.4239 5.29688 16.5013 5.10011 16.5013C4.90334 16.5013 4.71445 16.4239 4.57417 16.2859L0.974175 12.7506C0.903938 12.6816 0.847993 12.5993 0.809533 12.5087C0.771074 12.418 0.750853 12.3206 0.750026 12.2221C0.749199 12.1236 0.767782 12.0259 0.804713 11.9346C0.841644 11.8432 0.896201 11.7601 0.965268 11.6898C1.03434 11.6196 1.11656 11.5637 1.20725 11.5252C1.29794 11.4867 1.39531 11.4665 1.49381 11.4657C1.59232 11.4649 1.69002 11.4835 1.78134 11.5204C1.87266 11.5573 1.95581 11.6119 2.02605 11.6809L5.10011 14.6997L12.9751 6.96532C13.1171 6.82583 13.3087 6.74846 13.5077 6.75021C13.6062 6.75109 13.7036 6.77136 13.7943 6.80987C13.8851 6.84839 13.9673 6.9044 14.0364 6.97469C14.1054 7.04499 14.16 7.12821 14.1969 7.21958C14.2338 7.31096 14.2523 7.40871 14.2515 7.50726C14.2506 7.60581 14.2303 7.70322 14.1918 7.79393C14.1533 7.88464 14.0973 7.96688 14.027 8.03594H14.026ZM23.0354 6.97469C22.9664 6.90434 22.8842 6.84829 22.7934 6.80974C22.7027 6.77119 22.6053 6.7509 22.5067 6.75003C22.4082 6.74916 22.3104 6.76772 22.219 6.80467C22.1276 6.84161 22.0444 6.8962 21.9742 6.96532L14.0992 14.6997L12.3339 12.9653C12.1919 12.826 12.0004 12.7487 11.8014 12.7505C11.6025 12.7524 11.4124 12.8332 11.2731 12.9752C11.1337 13.1171 11.0565 13.3087 11.0583 13.5076C11.0602 13.7065 11.141 13.8966 11.2829 14.0359L13.5732 16.2859C13.7135 16.4239 13.9024 16.5013 14.0992 16.5013C14.2959 16.5013 14.4848 16.4239 14.6251 16.2859L23.0251 8.03594C23.0955 7.96697 23.1516 7.88479 23.1903 7.79411C23.2289 7.70343 23.2493 7.60602 23.2502 7.50746C23.2512 7.40889 23.2327 7.31111 23.1959 7.21969C23.159 7.12828 23.1045 7.04502 23.0354 6.97469Z" fill="#5692B7"/>
      </svg>
      <span class="message-content">{{ messageText }}</span>
    </div>
  </Transition>
</template>

<script>
export default {
  name: "SmallAlert",
  props: {
    messageText: {
      type: String,
      default: "Changes saved"
    }
  },
  data() {
    return {
      visibleAlertMsg: false,
    }
  },
  methods: {
    hideAlert() {
      this.visibleAlertMsg = false;
    },
    showAlert() {
      this.visibleAlertMsg = true;
      setTimeout(() => this.hideAlert(),3000);
    }
  },
};
</script>

<style scoped>
.alert-notification {
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  background: var(--active-accent-color);
  position: absolute;
  top: 6%;
  left: 43%;
  z-index: 1000;
  transform: translate(-50%, -50%);
  height: 40px;
  padding: 8px 24px 8px 24px;
  border-radius: 5px;
}

.message-content {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: var(--bright-accent-color);
}

.fade-enter-active .alert-notification,
.fade-leave-active .alert-notification {
  transition: opacity 0.5s ease;
}

.fade-enter-from .alert-notification,
.fade-leave-to .alert-notification {
  opacity: 0;
}
</style>
