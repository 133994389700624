<template>
  <div class="public-page-wrapper">
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
    <Header v-if="!login" />
    <HeaderDefaultRegistered v-else />
    <main class="main">
      <section class="app-version-page">
        <div class="container-small">
          <h1 class="title app-version-title">App Versions</h1>
          <div class="app-version-list-wrapper">
            <div
                class="app-version-list"
                v-for="(version, index) in appVersionsList"
                :key="index"
                :class="appVersionsList.length > 2 ? 'many-el' : ''"
            >
              <h2 class="app-version-list-title">PME Collect for <b>{{getOs(version.versionName)}}</b>:</h2>
              <div class="app-version-list-body">
                <div class="app-version-item">
                  <span @click="getAppVersionById(version.versionContent.id)">{{version.versionContent.version}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <Footer />
  </div>
</template>

<script>
import urlQuery from "../constants/urlQuery";
import login from "../mixins/login";
import AlertErrorMsg from "../components/profile/AlertErrorMsg";
import Header from "../components/layout/Header";
import HeaderDefaultRegistered from "../components/layout/HeaderDefaultRegistered";
import Footer from "../components/Footer";
import capitalizeFirstLetter from "@/mixins/capitalizeFirstLetter";

export default {
  name: "AppVersions",
  mixins: [login, capitalizeFirstLetter],
  data() {
    return {
      appVersionsList: [],
      appVersionsListTitle: [],
      errorMessage: '',
    }
  },
  components: {
    Header,
    HeaderDefaultRegistered,
    Footer,
    AlertErrorMsg,
  },
  methods: {
    getFileName(str) {
      let lastSlash = str.lastIndexOf("/");
      return str.substring(lastSlash+1).split('?')[0]
    },
    download(url, filename) {
      let a = document.createElement("a");
      a.href = url
      a.setAttribute("download", filename);
      a.click();
    },
    getAppVersion() {
      this.$load(async () => {
        await this.$api.publicPage.getAppVersion({
          apiKey: urlQuery(),
        }).then((res) => {
          this.appVersionsList = res.data.body;
          const objArr = Object.entries(this.appVersionsList);

          let versionsList = [];

          objArr.forEach(([os, versions]) => {
            if (versions.length > 0) {
              const latestVersion = versions.sort((a, b) => b.id - a.id)[0];

              versionsList.push({
                versionName: os,
                versionContent: latestVersion,
              });
            }
          });
          this.appVersionsList = versionsList.slice();
        }, (error) => {
          if(error) {
            if(error.response) {
              this.errorMessage = error.response.data.errors.message[0]
            }else {
              this.errorMessage = error.data.errors.message[0]
            }
            this.$refs.alert.showAlert()
          }
        })
      })
    },
    getAppVersionById(id) {
      this.$load(async () => {
        await this.$api.publicPage.getAppVersionById({
          id: id,
          apiKey: urlQuery()
        }).then((res) => {
          this.download(res.data.body.url, this.getFileName(res.data.body.url))
        }, (error) => {
          if(error) {
            if(error.response) {
              this.errorMessage = error.response.data.errors.message[0]
            }else {
              this.errorMessage = error.data.errors.message[0]
            }
            this.$refs.alert.showAlert()
          }
        })
      })
    },
    getOs(osName) {
      if (osName.toLowerCase() === 'macos') {
        return 'MacOS';
      }
      return this.capitalizeFirstLetter(osName);
    }
  },
  mounted() {
    document.title = 'Application version'
    this.getAppVersion()
  }
}
</script>

<style scoped>

.app-version-page {
  min-height: 500px;
}
  .app-version-title {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 58px;
    text-align: center;
    color: #292B2F;
    margin-top: 0;
    margin-bottom: 40px;
  }

  .app-version-list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
  }

  .app-version-list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: calc(50% - 30PX);
  }

  .app-version-list.many-el {
    width: calc(33.33% - 10PX);
  }

  .app-version-list-header {
    padding: 24px 69px;
    background: #D7DCE6;
    border: 1px solid #EBEFF7;
    border-radius: 10px;
    text-align: left;
  }

  .app-version-list-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: var(--accent-color);
    margin: 0;
  }

  .app-version-item {
    margin-bottom: 30px;
  }

  .app-version-item:last-child {
    margin-bottom: 0;
  }

  .app-version-item span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--accent-color);
    text-decoration: underline;
    transition: all .5s;
    cursor: pointer;
  }

  .app-version-item span:hover {
    text-decoration: underline;
  }


</style>
