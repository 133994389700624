<template>
    <input
      ref="customInput"
      class="text"
      type="text"
      :placeholder=placeholder
      @keyup.enter="$emit('input', $event.target.value)"
      />
</template>

<script>
export default {
  name: "CustomInput",
  props: {
    placeholder: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      inputValue: '',
    }
  },
  methods: {
    getInput() {
      return this.$refs.customInput.value;
    },
    clear() {
      this.$refs.customInput.value = '';
    }
  },
}
</script>

<style scoped>
.holder-search input {
  width: 100%;
  height: auto;
}

.text {
  width: 100%;
  border-radius: 79px;
  border: 1px solid var(--input-placeholder-value-color);
  padding: 12px 15px !important;
  font-size: 12px;
}

.text:hover {
  filter: drop-shadow(0px 0px 5px rgba(200, 205, 212, 0.5));
}

.text::placeholder {
  color: var(--input-placeholder-value-color);
  font-size: 14px;
}

input {outline:none;}
</style>