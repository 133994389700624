  <template>
  <div class="notification-wrapper">
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
    <div class="holder-search">
      <CustomSearch
          id="notification_search_files"
          :modelValue="searchText"
          @update:modelValue="searchText = $event"
          placeholder="Search by file name"
      />
    </div>
    <div class="holder-table">
        <table cellpadding="0" cellspacing="0" border="0">
          <TableHeader>
            <tr>
              <th style="width: 200px;">
                <div class="table-header-box">
                  <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.28125 9.5625C3.14035 9.5625 3.00523 9.50653 2.9056 9.4069C2.80597 9.30727 2.75 9.17215 2.75 9.03125C2.75 8.89035 2.80597 8.75523 2.9056 8.6556C3.00523 8.55597 3.14035 8.5 3.28125 8.5H10.7188C10.8596 8.5 10.9948 8.55597 11.0944 8.6556C11.194 8.75523 11.25 8.89035 11.25 9.03125C11.25 9.17215 11.194 9.30727 11.0944 9.4069C10.9948 9.50653 10.8596 9.5625 10.7188 9.5625H3.28125Z" fill="#D7DCE6"/>
                    <path d="M2.75 11.1562C2.75 11.0154 2.80597 10.8802 2.9056 10.7806C3.00523 10.681 3.14035 10.625 3.28125 10.625H10.7188C10.8596 10.625 10.9948 10.681 11.0944 10.7806C11.194 10.8802 11.25 11.0154 11.25 11.1562C11.25 11.2971 11.194 11.4323 11.0944 11.5319C10.9948 11.6315 10.8596 11.6875 10.7188 11.6875H3.28125C3.14035 11.6875 3.00523 11.6315 2.9056 11.5319C2.80597 11.4323 2.75 11.2971 2.75 11.1562Z" fill="#D7DCE6"/>
                    <path d="M3.28125 13.8125C3.14035 13.8125 3.00523 13.7565 2.9056 13.6569C2.80597 13.5573 2.75 13.4221 2.75 13.2812C2.75 13.1404 2.80597 13.0052 2.9056 12.9056C3.00523 12.806 3.14035 12.75 3.28125 12.75H7.53125C7.67215 12.75 7.80727 12.806 7.9069 12.9056C8.00653 13.0052 8.0625 13.1404 8.0625 13.2812C8.0625 13.4221 8.00653 13.5573 7.9069 13.6569C7.80727 13.7565 7.67215 13.8125 7.53125 13.8125H3.28125Z" fill="#D7DCE6"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.37381 0H2.75C2.18641 0 1.64591 0.223883 1.2474 0.622398C0.848883 1.02091 0.625 1.56141 0.625 2.125V14.875C0.625 15.4386 0.848883 15.9791 1.2474 16.3776C1.64591 16.7761 2.18641 17 2.75 17H11.25C11.8136 17 12.3541 16.7761 12.7526 16.3776C13.1511 15.9791 13.375 15.4386 13.375 14.875V5.00119C13.3749 4.71942 13.263 4.44921 13.0637 4.25L9.125 0.311313C8.92579 0.112041 8.65558 6.01772e-05 8.37381 0ZM8.59375 1.59375V3.71875C8.59375 4.00054 8.70569 4.27079 8.90495 4.47005C9.10421 4.66931 9.37446 4.78125 9.65625 4.78125H11.7812L8.59375 1.59375ZM2.9056 9.4069C3.00523 9.50653 3.14035 9.5625 3.28125 9.5625H10.7188C10.8596 9.5625 10.9948 9.50653 11.0944 9.4069C11.194 9.30727 11.25 9.17215 11.25 9.03125C11.25 8.89035 11.194 8.75523 11.0944 8.6556C10.9948 8.55597 10.8596 8.5 10.7188 8.5H3.28125C3.14035 8.5 3.00523 8.55597 2.9056 8.6556C2.80597 8.75523 2.75 8.89035 2.75 9.03125C2.75 9.17215 2.80597 9.30727 2.9056 9.4069ZM2.9056 10.7806C2.80597 10.8802 2.75 11.0154 2.75 11.1562C2.75 11.2971 2.80597 11.4323 2.9056 11.5319C3.00523 11.6315 3.14035 11.6875 3.28125 11.6875H10.7188C10.8596 11.6875 10.9948 11.6315 11.0944 11.5319C11.194 11.4323 11.25 11.2971 11.25 11.1562C11.25 11.0154 11.194 10.8802 11.0944 10.7806C10.9948 10.681 10.8596 10.625 10.7188 10.625H3.28125C3.14035 10.625 3.00523 10.681 2.9056 10.7806ZM2.9056 13.6569C3.00523 13.7565 3.14035 13.8125 3.28125 13.8125H7.53125C7.67215 13.8125 7.80727 13.7565 7.9069 13.6569C8.00653 13.5573 8.0625 13.4221 8.0625 13.2812C8.0625 13.1404 8.00653 13.0052 7.9069 12.9056C7.80727 12.806 7.67215 12.75 7.53125 12.75H3.28125C3.14035 12.75 3.00523 12.806 2.9056 12.9056C2.80597 13.0052 2.75 13.1404 2.75 13.2812C2.75 13.4221 2.80597 13.5573 2.9056 13.6569Z" fill="#D7DCE6"/>
                  </svg>
                  <span class="header-table-text">File Name</span>
                </div>
              </th>
              <th>
                <div class="table-header-box">
                  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.34961 0.861328C1.24504 0.861328 0.349609 1.75676 0.349609 2.86133V16C0.349609 17.1046 1.24504 18 2.34961 18H17.6522C18.7568 18 19.6522 17.1046 19.6522 16V2.86133C19.6522 1.75676 18.7568 0.861328 17.6522 0.861328H2.34961ZM11.1918 10.9515C10.4486 10.8618 9.63781 10.817 8.75946 10.817C8.71442 10.817 8.66937 10.8226 8.62433 10.8338C8.57928 10.845 8.53424 10.8618 8.4892 10.8842C8.44415 10.9067 8.40474 10.9347 8.37096 10.9683C8.26961 11.0803 8.21893 11.2091 8.21893 11.3548V13.8418L2.5941 9.2038L8.21893 4.61619V7.03603C8.21893 7.18167 8.27242 7.3077 8.3794 7.41413C8.48638 7.52056 8.61307 7.57377 8.75946 7.57377C11.8675 7.57377 14.1591 8.4308 15.6343 10.1449C16.6027 11.2539 17.1939 12.6207 17.4079 14.2451C17.1939 13.9427 16.9687 13.6682 16.7322 13.4217C16.4957 13.1753 16.2029 12.9148 15.8538 12.6403C15.5048 12.3658 15.1078 12.125 14.663 11.9177C14.2182 11.7105 13.7143 11.52 13.1512 11.3464C12.5882 11.1727 11.935 11.0411 11.1918 10.9515Z" fill="#D7DCE6"/>
                  </svg>
                  <span class="header-table-text">Action</span>
                </div>
              </th>
              <th>
                <div class="table-header-box">
                  <CaseIcon />
                  <span class="header-table-text">Case</span>
                </div>
              </th>
              <th>
                <div class="table-header-box">
                  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.7244 3.2H6.98757C6.76423 3.2 6.55004 3.28429 6.39212 3.43431C6.23419 3.58434 6.14547 3.78783 6.14547 4V14.4H1.09284C0.869497 14.4 0.655305 14.3157 0.497379 14.1657C0.339454 14.0157 0.250732 13.8122 0.250732 13.6V0.8C0.250732 0.587827 0.339454 0.384344 0.497379 0.234315C0.655305 0.0842854 0.869497 0 1.09284 0H12.8823C13.1057 0 13.3198 0.0842854 13.4778 0.234315C13.6357 0.384344 13.7244 0.587827 13.7244 0.8V3.2ZM8.67179 4.8H15.4086C15.632 4.8 15.8462 4.88429 16.0041 5.03431C16.162 5.18434 16.2507 5.38783 16.2507 5.6V15.2C16.2507 15.4122 16.162 15.6157 16.0041 15.7657C15.8462 15.9157 15.632 16 15.4086 16H8.67179C8.44845 16 8.23425 15.9157 8.07633 15.7657C7.9184 15.6157 7.82968 15.4122 7.82968 15.2V5.6C7.82968 5.38783 7.9184 5.18434 8.07633 5.03431C8.23425 4.88429 8.44845 4.8 8.67179 4.8Z" fill="#D7DCE6"/>
                  </svg>
                  <span class="header-table-text">Device</span>
                </div>
              </th>
              <th>
                <div class="table-header-box">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 7.5C9.70938 7.5 11.5 5.82129 11.5 3.75C11.5 1.67871 9.70938 0 7.5 0C5.29063 0 3.5 1.67871 3.5 3.75C3.5 5.82129 5.29063 7.5 7.5 7.5ZM10.3 8.4375H9.77812C9.08437 8.73633 8.3125 8.90625 7.5 8.90625C6.6875 8.90625 5.91875 8.73633 5.22188 8.4375H4.7C2.38125 8.4375 0.5 10.2012 0.5 12.375V13.5938C0.5 14.3701 1.17188 15 2 15H13C13.8281 15 14.5 14.3701 14.5 13.5938V12.375C14.5 10.2012 12.6187 8.4375 10.3 8.4375Z" fill="#D7DCE6"/>
                  </svg>
                  <span class="header-table-text">Actor</span>
                </div>
              </th>
              <th>
                <div class="table-header-box">
                  <RoleIcon />
                  <span class="header-table-text">Actor’s Role</span>
                </div>
              </th>
              <th>
                <div
                    id="notification_files_sort_by_data"
                    @click="sortedBy('createdAt')"
                    class="table-header-box"
                    :class="{
                      'sort-active': this.sortBy === 'createdAt',
                      'sorted-desc': this.order === 'desc',
                    }"
                >
                  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.0625 0C3.17853 0 3.28981 0.049386 3.37186 0.137294C3.45391 0.225201 3.5 0.34443 3.5 0.46875V0.9375H10.5V0.46875C10.5 0.34443 10.5461 0.225201 10.6281 0.137294C10.7102 0.049386 10.8215 0 10.9375 0C11.0535 0 11.1648 0.049386 11.2469 0.137294C11.3289 0.225201 11.375 0.34443 11.375 0.46875V0.9375H12.25C12.7141 0.9375 13.1592 1.13504 13.4874 1.48667C13.8156 1.83831 14 2.31522 14 2.8125V13.125C14 13.6223 13.8156 14.0992 13.4874 14.4508C13.1592 14.8025 12.7141 15 12.25 15H1.75C1.28587 15 0.840752 14.8025 0.512563 14.4508C0.184374 14.0992 0 13.6223 0 13.125V4.6875H14V3.75H0V2.8125C0 2.31522 0.184374 1.83831 0.512563 1.48667C0.840752 1.13504 1.28587 0.9375 1.75 0.9375H2.625V0.46875C2.625 0.34443 2.67109 0.225201 2.75314 0.137294C2.83519 0.049386 2.94647 0 3.0625 0V0Z" fill="#D7DCE6"/>
                  </svg>
                  <span class="header-table-text">Date</span>
                  <ArrowDown class="arrow" />
                </div>
              </th>
            </tr>
          </TableHeader>
          <Loader v-if="loadFlag" :load-flag="loadFlag" />
          <template v-else>
            <NotificationsFilesItem
                v-if="notificationsFilesList.length"
                :notificationsFilesList="notificationsFilesList"
            />
            <p v-if="!notificationsFilesList.length" class="no-found">not found</p>
          </template>
        </table>
      <pagination v-if="notificationsFilesList.length && count !== 1"
                  v-model="currentPage"
                  :total-pages="count"
                  :link-gen="linkGen"
      ></pagination>
    </div>
  </div>
</template>

<script>
import AlertErrorMsg from "../profile/AlertErrorMsg";
import CustomSearch from "../inputs/CustomSearch";
import TableHeader from "../table/TableHeader";
import NotificationsFilesItem from "./NotificationsFilesItem";
import {mapActions} from "vuex";
import Loader from "../loader/Loader";
import CaseIcon from "../icons/CaseIcon";
import RoleIcon from "../icons/RoleIcon";
import linkGenerator from "../../constants/linkGen";
import ArrowDown from '@/components/icons/ArrowDown';
import Pagination from "@/components/common/Pagination.vue";

export default {
  name: "NotificationsFiles",
  components: {
    Pagination,
    Loader,
    RoleIcon,
    CaseIcon,
    AlertErrorMsg,
    NotificationsFilesItem,
    TableHeader,
    CustomSearch,
    ArrowDown,
  },
  props: {
    dataRange: {},
  },
  data() {
    return {
      currentPage: 1,
      size: 10,
      count: 0,
      pageSize: 2,
      sortBy: 'createdAt',
      order: 'desc',
      searchText: '',
      dateFrom: '',
      dateTo: '',
      notificationsFilesList: [],
      notificationsFilesNotViewList: [],
      loadFlag: false,
      errorMessage: '',
    }
  },
  watch: {
    searchText() {
      this.getNotificationsFiles();
    },
    dataRange(value) {
      this.dateFrom = isNaN(Date.parse(value[0])) == false ? Date.parse(value[0]) : '';
      this.dateTo = isNaN(Date.parse(value[1])) == false ? Date.parse(value[1])  : '';
      this.currentPage = 1;
      this.getNotificationsFiles();
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if(newVal) {
          this.currentPage = +newVal;
          this.getNotificationsFiles();
        } else {
          this.currentPage = 1;
        }
      }
    },
    currentPage(newVal, oldVal) {
      if (newVal !== oldVal && +this.$router.currentRoute.query.page !== newVal) {
        this.$router.push(this.linkGen(newVal));
      }
    },
  },
  methods: {
    ...mapActions({
      getNotificationCount: 'notifications/getNotificationCount',
      getNotificationAll: 'notifications/getNotificationAll'
    }),
    linkGen(pageNum) {
      return linkGenerator(pageNum, '/notifications/files')
    },
    sortedBy(filterName) {
      this.order = filterName === this.sortBy && this.order === 'desc' ? 'asc' : 'desc'
      this.sortBy = filterName
      this.getNotificationsFiles()
    },
    fixedEncodeURIComponent (str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    },
    notificationView(oldArr, arr) {
      oldArr.forEach(item => {
        if(!item.viewed) {
          arr.push(item.id)
        }
      })
    },
    getNotificationsFiles() {
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.notifications.getNotificationFiles({
          sortBy: this.sortBy,
          keySearch: this.fixedEncodeURIComponent(this.searchText),
          order: this.order,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          page: this.currentPage,
          size: this.size,
        }).then((res) => {
          this.notificationsFilesList = res.data.body.content
          this.count = res.data.body.totalPages
          this.pageSize = res.data.body.size
          this.notificationsPermissionNotViewList = []
          this.notificationView(this.notificationsFilesList, this.notificationsFilesNotViewList)
          if(this.notificationsFilesNotViewList.length) {
            this.changeStatusNotificationType()
          }
        }, (error) => {
          if(error) {
            if(error.response) {
              this.errorMessage = error.response.data.errors.message[0]
            }else {
              this.errorMessage = error.data.errors.message[0]
            }
            this.$refs.alert.showAlert()
          }
        })
        setTimeout(() => {
          this.loadFlag = false
        }, 1000)
      })
    },
    changeStatusNotificationType() {
      this.$load(async () => {
        await this.$api.notifications.changeStatusNotificationType({
          notificationIds : this.notificationsFilesNotViewList.toString(),
        }).then(() => {
          this.getNotificationAll({}).then(() => {
            this.getNotificationCount({})
          })
        })
      })
    },
  },
  mounted() {
    this.currentPage = +this.$route.query.page || 1;
    this.getNotificationsFiles()
  }
}
</script>

<style scoped>
.holder-search {
  max-width: 300px;
  margin-bottom: 26px;
}

.holder-table {
  position: relative;
}

</style>
