<template>
  <div class="case-user-table">
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
      <table cellpadding="0" cellspacing="0" border="0" class="raz">
        <TableHeader
            v-if="users.length"
            :class="checkAll || checkSome.length >= 2 ? 'active ' : ''">
          <tr>
          <th class="holder-checkbox">
            <div class="custom-checkbox-group">
              <div class="checkbox-wrap">
                <input
                    :id="`checkAll_user_in_case_${caseId}`"
                    class="input-checkbox"
                    type="checkbox"
                    v-model="checkAll">
                <label :for="`checkAll_user_in_case_${caseId}`" class="label-checkbox">
                  <span>{{' '}}</span>
                </label>
                <span class="checkbox-unselect" @click="unselectAll">{{ checkAll || checkSome.length >= 2 ? 'Unselect All' : '' }}</span>
              </div>
            </div>
          </th>
          <th>
            <div
                v-show="!checkAll && checkSome.length <= 1"
                :id="`sort_user_in_case_${caseId}_by_name`"
                @click="sortedBy('userName')"
                class="table-header-box"
                :class="{
                  'sort-active': this.sortBy === 'userName',
                  'sorted-desc': this.order === 'desc',
                }"
            >
              <UserIcon />
              <span class="header-table-text">User Name</span>
              <div class="arrow-icon">
                <arrowDown class="arrow" />
              </div>
            </div>
          </th>
          <th>
            <div v-if="!checkAll  && checkSome.length <= 1"
                 :id="`sort_user_in_case_${caseId}_by_phone`"
                 @click="sortedBy('phoneNumber')"
                 class="table-header-box"
                 :class="{
                   'sort-active': this.sortBy === 'phoneNumber',
                   'sorted-desc': this.order === 'desc',
                 }"
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.5646 11.7424L13.3317 9.50954C12.5343 8.71209 11.1786 9.0311 10.8596 10.0678C10.6204 10.7855 9.82296 11.1842 9.10526 11.0247C7.51037 10.626 5.35727 8.5526 4.95854 6.87797C4.71931 6.16024 5.19778 5.36279 5.91548 5.12359C6.95216 4.80461 7.27114 3.44895 6.47369 2.65151L4.24084 0.418659C3.60289 -0.139553 2.64595 -0.139553 2.08774 0.418659L0.572591 1.93381C-0.942556 3.5287 0.732081 7.75516 4.48008 11.5032C8.22807 15.2511 12.4545 17.0056 14.0494 15.4106L15.5646 13.8955C16.1228 13.2575 16.1228 12.3006 15.5646 11.7424Z"
                    fill="#D7DCE6"/>
              </svg>
              <span class="header-table-text">Phone number</span>
              <div class="arrow-icon">
                <arrowDown class="arrow" />
              </div>
            </div>
          </th>
          <th>
            <div v-if="!checkAll && checkSome.length <= 1"
                 class="table-header-box"
                 @click="sortedBy('role')"
                 :class="{
                   'sort-active': this.sortBy === 'role',
                   'sorted-desc': this.order === 'desc',
                 }"
            >
              <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.48024 0H0.520239C0.245378 0 0.0224609 0.222917 0.0224609 0.497778V15.5022C0.0224609 15.7771 0.245378 16 0.520239 16H9.48024C9.7551 16 9.97802 15.7771 9.97802 15.5022V0.497778C9.97802 0.222917 9.7551 0 9.48024 0ZM3.16552 0.462222H6.83496C7.18774 0.462222 7.47496 0.724583 7.47496 1.04889C7.47496 1.37319 7.18774 1.63556 6.83496 1.63556H3.16552C2.81274 1.63556 2.52552 1.37319 2.52552 1.04889C2.52552 0.724583 2.81274 0.462222 3.16552 0.462222ZM1.58691 2.80889C1.58691 2.6125 1.74607 2.45333 1.94246 2.45333H8.02246C8.21885 2.45333 8.37802 2.6125 8.37802 2.80889V9.49333C8.37802 9.68972 8.21885 9.84889 8.02246 9.84889H1.94246C1.74607 9.84889 1.58691 9.68972 1.58691 9.49333V2.80889ZM1.90691 11.52C1.78913 11.52 1.69357 11.4244 1.69357 11.3067C1.69357 11.1889 1.78913 11.0933 1.90691 11.0933H8.05802C8.1758 11.0933 8.27135 11.1889 8.27135 11.3067C8.27135 11.4244 8.1758 11.52 8.05802 11.52H1.90691ZM7.73802 12.7644C7.73802 12.8822 7.64246 12.9778 7.52468 12.9778H2.44024C2.32246 12.9778 2.22691 12.8822 2.22691 12.7644C2.22691 12.6467 2.32246 12.5511 2.44024 12.5511H7.52468C7.64246 12.5511 7.73802 12.6467 7.73802 12.7644ZM8.59135 14.4H1.37357C1.25579 14.4 1.16024 14.3044 1.16024 14.1867C1.16024 14.0689 1.25579 13.9733 1.37357 13.9733H8.59135C8.70913 13.9733 8.80468 14.0689 8.80468 14.1867C8.80468 14.3044 8.70913 14.4 8.59135 14.4Z"
                    fill="#D7DCE6"/>
              </svg>
              <span class="header-table-text">Role</span>
              <div class="arrow-icon">
                <arrowDown class="arrow" />
              </div>
            </div>
          </th>
          <th>
            <div v-if="!checkAll && checkSome.length <= 1"
                 :id="`sort_user_in_case_${caseId}_by_date`"
                 @click="sortedBy('registerDate')"
                 class="table-header-box"
                 :class="{
                   'sort-active': this.sortBy === 'registerDate',
                   'sorted-desc': this.order === 'desc',
                 }"
            >
              <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.0625 0.5C3.17853 0.5 3.28981 0.549386 3.37186 0.637294C3.45391 0.725201 3.5 0.84443 3.5 0.96875V1.4375H10.5V0.96875C10.5 0.84443 10.5461 0.725201 10.6281 0.637294C10.7102 0.549386 10.8215 0.5 10.9375 0.5C11.0535 0.5 11.1648 0.549386 11.2469 0.637294C11.3289 0.725201 11.375 0.84443 11.375 0.96875V1.4375H12.25C12.7141 1.4375 13.1592 1.63504 13.4874 1.98667C13.8156 2.33831 14 2.81522 14 3.3125V13.625C14 14.1223 13.8156 14.5992 13.4874 14.9508C13.1592 15.3025 12.7141 15.5 12.25 15.5H1.75C1.28587 15.5 0.840752 15.3025 0.512563 14.9508C0.184374 14.5992 0 14.1223 0 13.625V5.1875H14V4.25H0V3.3125C0 2.81522 0.184374 2.33831 0.512563 1.98667C0.840752 1.63504 1.28587 1.4375 1.75 1.4375H2.625V0.96875C2.625 0.84443 2.67109 0.725201 2.75314 0.637294C2.83519 0.549386 2.94647 0.5 3.0625 0.5V0.5Z"
                    fill="#D7DCE6"/>
              </svg>
              <span class="header-table-text">registration date</span>
              <div class="arrow-icon">
                <arrowDown class="arrow" />
              </div>
            </div>
          </th>
            <th></th>
          <th>
            <div v-if="checkAll || checkSome.length >= 2" class="holder-deactivate-btn">
              <div id="disabled-deactivate-all" ref="disabledDeactivateAll">
                <button
                    :id="`deactivate_users_in_case_${caseId}`"
                    :disabled="!checkPermission(['assign_user_to_case'])"
                    class="btn btn-deactivate"
                    @click.stop="openDeactivatePopup"
                >
                  <span class="btn-span">Deactivate all</span>
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10 0.499969C4.47 0.499969 0 4.96997 0 10.5C0 16.03 4.47 20.5 10 20.5C15.53 20.5 20 16.03 20 10.5C20 4.96997 15.53 0.499969 10 0.499969ZM15 14.09L13.59 15.5L10 11.91L6.41 15.5L5 14.09L8.59 10.5L5 6.90997L6.41 5.49997L10 9.08997L13.59 5.49997L15 6.90997L11.41 10.5L15 14.09Z"
                        fill="#D7DCE6"/>
                  </svg>
                </button>
              </div>
              <tooltip v-if="!checkPermission(['assign_user_to_case'])" :target-ref="$refs.disabledDeactivateAll">
                <span>To be able to deactivate all users from a case, you must have the "Assign user to case" permission for that case.</span>
              </tooltip>
            </div>
          </th>
        </tr>
        </TableHeader>
        <Loader v-if="loadFlag" :load-flag="loadFlag"/>
        <template v-else>
          <CaseEmployeesItem
              v-if="users.length"
              :usersList="users"
              :casesManager="casesManager"
              @confirmDeactivateUser="confirmDeactivateUser"
          />
          <p v-else class="no-found">not found</p>
        </template>
      </table>
    <CommonDialog
        ref="commonDialogDeleteUser"
    >
      <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M47.0015 0.776367C37.8596 0.776367 28.9231 3.48725 21.3218 8.56622C13.7206 13.6452 7.79623 20.8641 4.29777 29.3101C0.799324 37.7561 -0.11603 47.0499 1.66746 56.0161C3.45096 64.9823 7.8532 73.2183 14.3175 79.6826C20.7818 86.1469 29.0178 90.5492 37.984 92.3327C46.9503 94.1162 56.244 93.2008 64.69 89.7024C73.136 86.2039 80.355 80.2795 85.4339 72.6783C90.5129 65.0771 93.2238 56.1405 93.2238 46.9986C93.2238 34.7397 88.3539 22.9829 79.6856 14.3145C71.0172 5.6462 59.2604 0.776367 47.0015 0.776367V0.776367ZM77.1904 31.4853L39.2304 69.4164L16.8127 46.9986C16.0465 46.2324 15.616 45.1932 15.616 44.1097C15.616 43.0262 16.0465 41.987 16.8127 41.2208C17.5788 40.4546 18.618 40.0242 19.7015 40.0242C20.7851 40.0242 21.8242 40.4546 22.5904 41.2208L39.2882 57.9186L71.4704 25.7653C71.8498 25.3859 72.3002 25.0849 72.7959 24.8796C73.2915 24.6743 73.8228 24.5686 74.3593 24.5686C74.8958 24.5686 75.4271 24.6743 75.9228 24.8796C76.4184 25.0849 76.8688 25.3859 77.2482 25.7653C77.6276 26.1446 77.9285 26.595 78.1338 27.0907C78.3392 27.5864 78.4448 28.1176 78.4448 28.6541C78.4448 29.1907 78.3392 29.7219 78.1338 30.2176C77.9285 30.7133 77.6276 31.1637 77.2482 31.543L77.1904 31.4853Z"
            fill="#082655"/>
      </svg>
      <h2 class="dialog-message-title">Success!</h2>
      <p class="dialog-message-description" style="display:block;">Selected users were deactivated</p>
    </CommonDialog>
    <DeactivateItemPopUp
        :arrayOfCheckedUsers="checkSome"
        ref="usersPopup"
        @deactivateArrayOfUsers="confirmDeactivateUserList"
    />
    <InfoDialog
        ref="infoDialog"
        :title="infoDialogData.dialogTitle"
        :text="infoDialogData.dialogText"
        :confirmBtnText="infoDialogData.confirmBtnText"
        :cancelBtnText="infoDialogData.cancelBtnText"
        @onConfirm="deleteUser"
    />
    <InfoDialog
        ref="infoDialogDeactivateUserList"
        :title="infoDialogData.dialogTitle"
        :text="infoDialogData.dialogText"
        :confirmBtnText="infoDialogData.confirmBtnText"
        :cancelBtnText="infoDialogData.cancelBtnText"
        @onConfirm="deactivateArrayOfUsers"
    />
  </div>
</template>

<script>
import linkGenerator from "../../../constants/linkGen";
import checkPermission from "../../../utils/permission";
import InfoDialog from "../../popups/InfoDialog";
import TableHeader from "../../table/TableHeader";
import CaseEmployeesItem from "./CaseEmployeesItem";
import AlertErrorMsg from "../../profile/AlertErrorMsg";
import CommonDialog from "../../popups/CommonDialog";
import DeactivateItemPopUp from "../../popups/DeactivateItemPopUp";
import createDeactivateDialog from "../../../constants/infoDialogData";
import checkAll from "../../../mixins/checkAll";
import ArrowDown from "../../icons/ArrowDown";
import UserIcon from "../../icons/UserIcon";
import Loader from "../../loader/Loader";
import Tooltip from "@/components/common/Tooltip.vue";

export default {
  name: "CaseEmployeesTable",
  components: {
    Tooltip,
    UserIcon,
    ArrowDown,
    Loader,
    DeactivateItemPopUp,
    InfoDialog,
    CommonDialog,
    TableHeader,
    CaseEmployeesItem,
    AlertErrorMsg
  },
  props: {
    searchText: {
      type: String
    },
  },
  mixins: [checkAll],
  data() {
    return {
      infoDialogData: {},
      loadFlag: false,
      caseId: this.$route.params.slug,
      keySearch: '',
      sortBy: 'userName',
      order: 'asc',
      page: 1,
      size: 10,
      count: 0,
      pageSize: 2,
      errorMessage: '',
      casesManager: {},
      users: [],
      tableHeaderType: 'default',
      selectedFlag: false,
    }
  },
  watch: {
    searchText() {
      this.getEmployees()
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if(newVal) {
          this.page = newVal
          this.getEmployees()
        } else {
          this.page = 1
        }
      }
    }
  },
  methods: {
    checkPermission,
    unselectAll() {
      this.users.forEach((item) => {
        item.isActive = false;
      })
    },
    linkGen(pageNum) {
      return linkGenerator(pageNum, '/cases/:slug/users')
    },
    changeCheckbox(item) {
      this.$emit('changeCheckbox', item, this.title)
    },
    openDeactivatePopup() {
      this.$refs.usersPopup.openPopup()
    },
    openDeactivatePopupUser() {
      this.$refs.commonDialogDeleteUser.openPopups();
    },
    confirmDeactivateUser(user) {
      this.infoDialogData = createDeactivateDialog('user');
      this.$refs.infoDialog.openPopup(user);
    },
    confirmDeactivateUserList(userlist) {
      this.infoDialogData = createDeactivateDialog('users');
      this.$refs.infoDialogDeactivateUserList.openPopup(userlist);
    },
    sortedBy(filterName) {
      this.order = filterName === this.sortBy && this.order === 'desc' ? 'asc' : 'desc'
      this.sortBy = filterName
      this.getEmployees()
    },
    fixedEncodeURIComponent (str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    },
    getEmployees() {
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.cases.getCasesEmployees({
          caseId: this.caseId,
          sortBy: this.sortBy,
          order: this.order,
          keySearch: this.fixedEncodeURIComponent(this.searchText),
          page: 1,
          size: 20
        }).then((res) => {
          this.users = res.data.body.content.map(i => {
            i.isActive = false
            return i
          })
        }, (error) => {
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
        setTimeout(() => {
          this.loadFlag = false
        }, 1000)
      })
    },
    deleteUser(user) {
      this.$load(async () => {
        await this.$api.cases.deleteCasesUser(this.caseId, user.publicId).then(() => {
          this.$refs.infoDialog.closePopup();
          this.openDeactivatePopupUser()
          this.getEmployees()
        }, (error => {
          this.$refs.infoDialog.closePopup();
          if (error.response) {
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
        )
      })
    },
    getManager() {
      this.$load(async () => {
        await this.$api.cases.getCasesManager({caseId: this.caseId}).then((res) => {
              this.casesManager = res.data.body
            }, (error => {
              if (error.response) {
                this.errorMessage = error.response.data.errors.message[0]
              } else {
                this.errorMessage = error.data.errors.message[0]
              }
              this.$refs.alert.showAlert()
            })
        )
      })
    },

    deactivateArrayOfUsers(arrayOfCheckedUsers) {
      const result = arrayOfCheckedUsers.map(user => user.publicId)
      result.toString()
      this.$load(async () => {
        this.loadFlag = true
        await this.$api.cases.deleteCasesUser(this.caseId, result).then(() => {
          this.$refs.infoDialogDeactivateUserList.closePopup();
          this.openDeactivatePopupUser()
          this.getEmployees()
        }, (error) => {
          if (error.response) {
            this.loadFlag = false
            this.$refs.infoDialogDeactivateUserList.closePopup();
            this.errorMessage = error.response.data.errors.message[0]
          } else {
            this.errorMessage = error.data.errors.message[0]
          }
          this.$refs.alert.showAlert()
        })
      })
    },
  },
  mounted() {
    this.page = this.$route.query.page || 1
    this.getEmployees()
  }

}
</script>

<style scoped>
.table-header.active {
  justify-content: space-between;
  padding-right: 13px;
  background-color: var(--accent-color);
}

.table-header-box svg path {
  fill: var(--input-placeholder-value-color);
  transition: all 0.3s;
}

.table-header-box:hover svg:first-child path {
  fill: var(--accent-color);
}

.arrow-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-icon svg path {
  fill: var(--light-gray);
}

.table-header.active .holder-deactivate-btn {
  display: flex;
  justify-content: flex-end;
}

.table-header.active th {
  padding: 22px 15px 21px;
}

.dialog-message-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #696F79;
  display: block;
  width: 100%;
}

.checkbox-wrap .checkbox-unselect {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  text-transform: initial;
  cursor: pointer;
  padding: 2px 0 0;
}
</style>
