// LocalStorageService.js
const LocalStorageService = (function(){
    var _service;
    function _getService() {
        if(!_service) {
            _service = this;
            return _service
        }
        return _service
    }
    function _setToken(access_token) {
        localStorage.setItem('token', JSON.stringify(access_token));
    }
    function _setTokenLogin(access_token) {
        localStorage.setItem('access_token', JSON.stringify(access_token));
    }
    function _getAccessToken() {
        if(localStorage.getItem('token') !== null || localStorage.getItem('token') !== undefined) {
            return JSON.parse(localStorage.getItem('token'));
        }
    }
    function _getAccessTokenLogin() {
        return JSON.parse(localStorage.getItem('access_token'));
    }
    function _getRefreshToken() {
        return JSON.parse(localStorage.getItem('refresh_token'));
    }
    function _clearToken() {
        localStorage.removeItem('token');
    }
    function _clearTokenLogin() {
        localStorage.removeItem('access_token');
    }

    function _setDataToLocalStorage(data)
    {
        var a = [];
        // Parse the serialized data back into an aray of objects
        a = JSON.parse(localStorage.getItem('step')) || [];
        // Push the new data (whether it be an object or anything else) onto the array
        a.push(data);
        // Alert the array value
        // Re-serialize the array back into a string and store it in localStorage
        localStorage.setItem('step', JSON.stringify(a));
    }

    function _clearDataToLocalStorage(data)
    {
        localStorage.removeItem(data);
    }

    function _clearAllToLocalStorage()
    {
        localStorage.removeItem('one_time_login_email');
        localStorage.removeItem('one_time_login_token');
        localStorage.removeItem('one_time_login_type_verification');
        localStorage.removeItem('step');
    }

    function _clearAllToLocalStorageData()
    {
        localStorage.clear()
    }

    return {
        getService : _getService,
        setToken : _setToken,
        setTokenLogin : _setTokenLogin,
        getAccessToken : _getAccessToken,
        getAccessTokenLogin : _getAccessTokenLogin,
        getRefreshToken : _getRefreshToken,
        setDataToLocalStorage : _setDataToLocalStorage,
        clearToken : _clearToken,
        clearTokenLogin : _clearTokenLogin,
        clearDataToLocalStorage : _clearDataToLocalStorage,
        clearAllToLocalStorage : _clearAllToLocalStorage,
        clearAllToLocalStorageData : _clearAllToLocalStorageData
    }
})();

export default LocalStorageService;