<template>
  <section class="section register-section">
    <AlertErrorMsg
        :title="errorMessage"
        class="page-alert-error"
        ref="alert"
    />
    <div class="container-small">
      <div class="register-step">
        <div class="step-header">
          <div class="step-progress">
            <div class="bar progressbar" style="width: 50%;"></div>
          </div>
          <ul class="step-pills step-pills-invite">
            <li class="step-item validated"><span class="step-link"><span class="tabStatus">1 </span><span
                class="tabLabel">2Factor Authentication</span></span></li>
            <li class="step-item active"><span href="#" class="step-link"><span class="tabStatus">2 </span><span
                class="tabLabel">Profile photo</span></span></li>
          </ul>
        </div>
        <div class="register-form-container card-form">
          <div class="register-info profile-image-info">
            <h1 class="register-info-title main-title">Add Profile Photo</h1>
          </div>
          <div class="form-content">
            <form @submit.prevent="submit"
                  class="form-registration"
            >
              <div
                  class="form-group"
                  :class="{ 'form-group--error': $v.userPhoto.$error }"
              >
                <div
                  class="form-profile-image"
                  :class="{'active': previewImage}"
                >
                  <img :src="previewImage" v-if="previewImage" class="uploading-image" />
                  <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.7188 37.0004H52.2743M36.9965 21.7227V52.2782V21.7227Z" stroke="white" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M37.0009 67.5555C53.8762 67.5555 67.5564 53.8753 67.5564 36.9999C67.5564 20.1245 53.8762 6.44434 37.0009 6.44434C20.1255 6.44434 6.44531 20.1245 6.44531 36.9999C6.44531 53.8753 20.1255 67.5555 37.0009 67.5555Z" stroke="white" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <div :class="['custom-file',$v.userPhoto.$error?'is-invalid':'']">
                    <input
                        type="file"
                        id="register_profile-photo_input"
                        accept=".png,.jpeg,.jpg"
                        class="input-image"
                        @change="onLogoChange($event)"
                    >
                  </div>
                  <label for="register_profile-photo_input" class="input__file-button">
                  </label>
                </div>
                <div id="register_profile-photo_error" class="error user-photo-error" v-if="!$v.userPhoto.isTrueImage">Invalid image file size.</div>
              </div>
              <div class="form-button-wrapper form-button-profile-photo-wrapper">
                <button id="register_profile-photo_button" type="button" @click="submit" class="btn form-btn" v-text="'Confirm'"></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import AlertErrorMsg from "../profile/AlertErrorMsg";
import LocalStorageService from "../../services/LocalStorageService";

// LocalstorageService
const localStorageService = LocalStorageService.getService();

const isTrueImage = (value) => {
  if (!value) {
    return true;
  }
  let file = value;
  if(file.size === 0) {
    return false;
  }
  return (+(file.size / 1024) <= +5120);
}

export default {
  name: "ProfilePhotoStepInvite",
  data(){
    return{
      userPhoto: null,
      previewImage: null,
      submitStatus: null,
      validForm: false,
      errorMessage: '',
    }
  },
  components: {
    AlertErrorMsg
  },
  methods:{
    onLogoChange(e) {
      const files =  e.target.files || e.dataTransfer.files;
      const file = files[0];
      this.userPhoto = file;
      this.$v.userPhoto.$touch();
      if (this.$v.userPhoto.$error) {
        return false
      }else {
        this.$load(async () => {
          let formData = new FormData();
          formData.append('file', this.userPhoto);

          await this.$api.auth.userPhoto(formData).then(() => {
            // this.$router.push({name: 'Billing'})
          })
        }, (error) => {
          this.errorMessage = error.data.errors.message[0]
          this.$refs.alert.showAlert()
        })
        this.previewImage = URL.createObjectURL(file);
      }
    },
    submit() {
      localStorageService.clearAllToLocalStorage()
      localStorage.setItem('isLogged', true)
      this.$router.push({path: '/dashboard'})
    },
  },
  validations: {
    userPhoto: { isTrueImage }
  }
}
</script>

<style scoped>

.profile-image-info {
  text-align: center;
}

.input-image {
  display: none;
}

.form-profile-image {
  position: relative;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-profile-image:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--accent-color);
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  border-radius: 50%;
}

.input__file-button {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  margin: 0;
  z-index: 2;
}

.uploading-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  z-index: 1;
  border: 1px solid transparent;
  transition: all .5s;
}

.form-profile-image.active {
  border: 1px solid var(--accent-color);
}

.form-profile-image svg {
  position: absolute;
  z-index: 2;
  transition: all .5s;
}

.form-profile-image img {
  transform: scale(0.9);
}

.form-profile-image.active svg {
  z-index: 4;
}

.form-profile-image.active .input__file-button {
  z-index: 5;
}

.form-profile-image.active:after {
  z-index: 3;
  opacity: 0;
  transition: all .5s;
}

.form-profile-image.active:hover:after {
  opacity: 0.5;
}

.form-profile-image.active svg {
  opacity: 0;
}

.form-profile-image.active:hover svg {
  opacity: 1;
}

.user-photo-error {
  text-align: center;
}

.step-link .tabStatus:after {
  width: 200px;
}

.form-btn {
  margin: 0 auto;
}

</style>
