import { render, staticRenderFns } from "./FaqSubAccordeon.vue?vue&type=template&id=17ca6970&scoped=true"
import script from "./FaqSubAccordeon.vue?vue&type=script&lang=js"
export * from "./FaqSubAccordeon.vue?vue&type=script&lang=js"
import style0 from "./FaqSubAccordeon.vue?vue&type=style&index=0&id=17ca6970&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17ca6970",
  null
  
)

export default component.exports