<template>
  <div v-if="activeDialog" class="overlay active">
    <div class="invite-dialog-wrapper">
      <div class="invite-popup-header">
        <div id="close_dialog_invite" class="close-dialog-invite" @click="closePopups()">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.0007 0.333008C5.54898 0.333008 0.333984 5.54801 0.333984 11.9997C0.333984 18.4513 5.54898 23.6663 12.0007 23.6663C18.4523 23.6663 23.6673 18.4513 23.6673 11.9997C23.6673 5.54801 18.4523 0.333008 12.0007 0.333008ZM17.834 16.188L16.189 17.833L12.0007 13.6447L7.81232 17.833L6.16732 16.188L10.3557 11.9997L6.16732 7.81134L7.81232 6.16634L12.0007 10.3547L16.189 6.16634L17.834 7.81134L13.6457 11.9997L17.834 16.188Z"
              fill="#D7DCE6"
            />
          </svg>
        </div>
        <div class="invite-title">
          {{ isPrimaryContact ? 'Change Primary Contact' : 'Change Billing contact' }}
        </div>
      </div>
      <div class="invitations-content-wrapper">
        <div class="item-wrapper">
          <div class="proof-of-leaving-text" :class="{ 'custom-select-disabled' : !isChoosingUser }">
            {{ 'Choose new ' + (isPrimaryContact ? 'Primary Contact' : 'Billing contact') + ' from the list' }}
          </div>
          <CustomSelectContact
            :custom-class="isChoosingUser ? '' : 'custom-select-disabled'"
            :options="contacts"
            :type-select="'contactUser'"
            placeholder="Choose user"
            @selectedUser="selectedUser = $event"
          />
          <div class="error" :style="{ display: isChoosingUser && !$v.selectedUser.required ? 'block' : 'none' }">Choose a user</div>
        </div>
        <div class="check">
          <CustomCheckbox
            @checked="changeCheckbox"
            :label="'I want to invite new user for ' + (isPrimaryContact ? 'Primary Contact' : 'Billing contact') + ' role'"
            :label-id="'label_send_manually'"
            :id="'check_send_manually'"
          />
        </div>
        <div class="item-wrapper highlighter" :class="{ 'send-to-disabled' : isChoosingUser }">
          <div class="proof-of-leaving-text mrg-bt-8">Send to:</div>
          <div class="textarea-wrapper" :class="{ 'form-group--error': $v.sendToEmail.$error }">
            <highlightable-input
              :highlight-enabled="true"
              :highlight="emailPattern"
              v-model="sendToEmail"
              class="invite-textarea"
              :class="{ 'placeholder-none': sendToEmail.length > 0 }"
            />
            <div class="error" :style="{ display: !isChoosingUser && !$v.sendToEmail.containsEmail ? 'block' : 'none' }">The field must contain one valid email address</div>
          </div>
        </div>
      </div>
      <div class="dialog-btns-wrap">
        <button class="btn dialog-btn" id="dialog_logout_btn" @click="onConfirm">
          <span class="dialog-btn-text">Continue</span>
        </button>
        <button id="dialog_logout_cancel_btn" @click="closePopups()" class="btn dialog-cancel">
          <span class="dialog-btn-text cancel">Cancel</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import HighlightableInput from "vue-highlightable-input";
import CustomCheckbox from "@/components/inputs/CustomCheckbox.vue";
import CustomSelectContact from "@/components/inputs/CustomSelectContact.vue";
import { email, required } from "vuelidate/lib/validators";

const containsEmail = (value) => {
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
  return emailRegex.test(value);
};

export default {
  name: "ChangeContactPopup",
  components: {
    CustomSelectContact,
    CustomCheckbox,
    HighlightableInput,
  },
  emits: ["selectedContact", "completed"],
  props: {
    isPrimaryContact: {
      type: Boolean,
    },
    contacts: {
      required: true,
    }
  },
  data() {
    return {
      activeDialog: false,
      sendToEmail: "",
      isChoosingUser: true,
      selectedUser: null,
      selectError: '',
      emailError: '',
      emailPattern: [
        {
          text: /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g,
          style:"background-color:#EBEFF6;" +
            " border-radius: 16px;" +
            "color: #696F79;" +
            "display: inline-block;" +
            "padding: 4px 8px;",
        },
      ]
    };
  },
  validations() {
    return {
      sendToEmail: {
        email,
        containsEmail,
        required,
      },
      selectedUser: { required },
    }
  },
  methods: {
    changeCheckbox() {
      this.isChoosingUser = !this.isChoosingUser;
    },
    openPopups() {
      this.activeDialog = true;
    },
    closePopups() {
      this.selectedUser = null;
      this.sendToEmail = '';
      this.activeDialog = false
    },
    onConfirm() {
      this.$v.$touch();
      if (this.isChoosingUser && this.$v.selectedUser.required) {
        this.$emit('selectedContact', this.selectedUser);
      } else if (!this.isChoosingUser && this.$v.sendToEmail.containsEmail) {
        this.$emit('selectedContact', this.sendToEmail);
      }
      this.$emit('completed')
      this.closePopups();
    },
  },
};
</script>

<style scoped>
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay.active {
  animation: show 0.3s linear;
}

.invite-dialog-wrapper {
  width: 100%;
  max-width: 711px;
  min-height: 539px;
  border: 1px solid #D7DCE6;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 32px;
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
}

.invite-popup-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.invitations-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.item-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.highlighter {
  position: relative;
  display: inline-block;
}

.close-dialog-invite {
  width: 70px;
  height: 70px;
  padding: 23px;
  cursor: pointer;
}

.close-dialog-invite svg {
  width: 100%;
  height: 100%;
}

.close-dialog-invite svg path {
  transition: 0.5s all;
}

.close-dialog-invite:hover svg path {
  fill: var(--accent-color);
}

.invite-title {
  font-size: 26px;
  font-weight: 700;
  color: var(--black-color);
}

.proof-of-leaving-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 25.6px;
  text-align: left;
  color: var(--secondary-black-color);
}

.invite-dialog-wrapper .dialog-btn {
  min-width: 140px;
  width: 100%;
  max-width: 213px;
  height: 48px;
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  border-radius: 25px;
}

.invite-dialog-wrapper .dialog-btn:hover {
  background-color: var(--white-color);
  border-color: var(--accent-color);
}

.invite-dialog-wrapper .dialog-btn:hover .dialog-btn-text {
  color: var(--accent-color);
}

.dialog-btns-wrap {
  margin-top: 19px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  align-items: center;
  gap: 16px;
}

.dialog-cancel {
  min-width: 140px;
  width: 100%;
  max-width: 213px;
  height: 48px;
  padding: 15px;
  cursor: pointer;
  transition: 0.5s all;
  background-color: var(--white-color);
  border-color: var(--accent-color);
  border-radius: 25px;
}

.dialog-btn-text {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: var(--white-color);
  transition: 0.5s all;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}

.dialog-btn-text.cancel {
  font-size: 14px;
  font-weight: 500;
  color: var(--accent-color);
}

.invite-textarea {
  width: 100%;
  resize: none;
  min-height: 104px;
  outline: none;
  border: 1px solid var(--input-placeholder-value-color);
  border-radius: 16px !important;
  padding: 16px 24px;
  font-size: 14px;
  font-weight: 500;
  background: transparent;
  position: relative;
  z-index: 2;
  color: var(--secondary-text-color);
}

.invite-textarea::before {
  content: "Enter contact e-mail";
  color: #D7DCE5;
  pointer-events: none;
}

.placeholder-none::before {
  content: "";
}

.check {
  display: flex;
  align-items: center;
  width: 5%;
  margin-bottom: 16px;
}

.custom-select-disabled,
.send-to-disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
