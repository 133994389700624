<template>
  <ul v-if="currentClientId && currentChatId" class="profile-nav client">
    <li  v-for="(item, index) in clientsArray"
         :key="`${item}_${index}`"
         :id="`case_message_${item}_${index}`"
         :class="{'active': item.chatRooms[0] && item.chatRooms[0].lastMessageChatInfo[0].chatId === currentChatId}"
         @click="toggleDevicesChats(item.clientId)">
      <div v-if="item.chatRooms[0]">
        <div class="client-contacts-wrap">
          <div class="img-item">
            <img v-if="item.avatar"
                 :src="item.avatar" alt="avatar">
            <img
                v-else
                src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png"
                alt="">
          </div>
          <a>{{item.email}}</a>
          <button class="btn-toggle-devices-visibility" v-if="item.chatRooms[0] && !clientsChats[index].visible" @click.stop="toggleDevicesChats(item.clientId)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.5 8.25L12 15.75L4.5 8.25" stroke="#373C44" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
          <button class="btn-toggle-devices-visibility" v-if="item.chatRooms[0] && clientsChats[index].visible" @click.stop="toggleDevicesChats(item.clientId)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.5 15.75L12 8.25L4.5 15.75" stroke="#373C44" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <ul v-if="clientsChats[index].visible" class="profile-nav device">
          <li  v-for="(device, index) in item.chatRooms"
               :key="`${device}_${index}`"
               :id="`case_message_${device}_${index}`"
               @click.stop="setTab({type: 'device', data: device})">
            <a>
              {{ getDeviceName(device) }}
            </a>
            <ul v-if="currentChatId" class="profile-nav chat">
              <li v-for="(chat, index) in device.lastMessageChatInfo"
                  :key="`${chat}_${index}`"
                  :id="`case_message_${chat}_${index}`"
                  :class="{'active': chat.chatId === currentChatId}"
                  @click.stop="setTab({type: 'chat', data: chat})">
                <a>
                  <div class="holder-contacts">
                    <div class="contacts-name">
                      <div class="img-item img-item-messagers">
                        <ImgOfMessangers :nameOfMessanger="chat.messengerType.toLowerCase()"/>
                      </div>
                      <span class="span-name">{{ chat.chatName }}</span>
                    </div>
                    <div class="info-contact">
                      <div class="spans-data">
                        <div class="desc-contact">
                          <p v-if="chat.messageText">{{ messageToShow(chat) }}</p>
                        </div>
                        <div>
                          <!--                <span class="span-date">-->
                          <!--                  {{ getTimeOfMsg(item.date) }}-->
                          <!--                </span>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
import ImgOfMessangers from './ImgOfMessangers';
import debounce from '@/mixins/debounce';

export default {
  name: "MessageList",
  components: {ImgOfMessangers},
  mixins: [debounce],
  props: {
    clientsArray: {
      type: Array
    },
  },
  data() {
    const nonEmptyClient = this.clientsArray.find(client => client.chatRooms.length !== 0);
    const currentClient = nonEmptyClient;
    const currentDevice = nonEmptyClient.chatRooms[0];
    const currentChat = currentDevice ? nonEmptyClient.chatRooms[0].lastMessageChatInfo[0] : null;
    const clientsChats = this.clientsArray.map((i) => {
      return {clientId: i.clientId, visible: true}
    });

    if (currentClient) {
      this.setTab({ type: "client", data: currentClient });
    } else if (currentDevice) {
      this.setTab({ type: "device", data: currentDevice });
    } else if (currentChat) {
      this.setTab({ type: "chat", data: currentChat });
    }
    return {
      currentClientId: currentClient ? currentClient.clientId : '',
      currentDeviceId: currentDevice ? currentDevice.device.deviceId : '',
      currentChatId: currentChat ? currentChat.chatId : '',
      clientsChats: clientsChats,
    }
  },
  methods: {
    setTab({ type = '', data = {} }) {
      switch (type) {
        case 'client':
          if (this.currentChatId === data.chatRooms[0].lastMessageChatInfo[0].chatId) return;
          this.currentChatId = data.chatRooms[0].lastMessageChatInfo[0].chatId;
          this.debounce(() => this.$emit('setTabItemMsg', data.chatRooms[0].lastMessageChatInfo[0]), 100);
          break;
        case 'device':
          if (this.currentChatId === data.lastMessageChatInfo[0].chatId) return;
          this.currentChatId = data.lastMessageChatInfo[0].chatId;
          this.debounce(() => this.$emit('setTabItemMsg', data.lastMessageChatInfo[0]), 100);
          break;
        case 'chat':
          if (this.currentChatId === data.chatId) return;
          this.currentChatId = data.chatId;
          this.debounce(() => this.$emit('setTabItemMsg', data), 100);
      }
    },
    getTimeOfMsg(data) {
      const time = new Date(data);
      const minutes = time.getMinutes();

      return time.getHours() + ":" + (minutes < 10 ? '0' : '') + minutes;
    },
    messageToShow(item) {
      if (item.editedMessageText && item.editedMessageText !== item.messageText) return item.editedMessageText;
      return item.messageText || 'No messages preview available';
    },
    toggleDevicesChats(clientId) {
      for (let i = 0; i < this.clientsChats.length; i++) {
        if (this.clientsChats[i].clientId === clientId) {
          this.clientsChats[i].visible = !this.clientsChats[i].visible;
        }
      }
    },
    getDeviceName(device) {
      if (device.device.deviceOs && device.device.deviceOsVersion) {
        return device.device.deviceOs + " " + device.device.deviceOsVersion;
      }
      return "Unknown device";
    }
  }
}
</script>

<style scoped>
.profile-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-top: 0;
  height: calc(700px + 130px)
}

.profile-nav.device, .profile-nav.chat {
  height: fit-content;
}

.profile-nav li {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  overflow-x: hidden;
}

.profile-nav li a {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  text-decoration: none;
  padding: 16px;
}

.profile-nav.chat > li:hover {
  color: var(--secondary-black-color);
  background-color: var(--gray-bg-color);
}

.profile-nav.chat li.active {
  color: var(--secondary-black-color);
  background-color: var(--light-green);
}

.client-contacts-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.profile-nav .client-contacts-wrap {
  padding: 16px;
}

.profile-nav .client-contacts-wrap a {
  padding: 0;
}

.contacts-name {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.img-item {
  max-width: 32px;
  max-height: 32px;
  overflow: hidden;
  border: 1px solid var(--white-color);
  border-radius: 50%;
}

.img-item img {
  width: 100%;
  height: 100%;
}

.holder-contacts {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.spans-data {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.span-name {
  font-size: 14px;
  font-weight: 700;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.img-item {
  margin-right: 8px;
}

.span-date {
  font-size: 11px;
  font-weight: 500;
  color: var(--secondary-text-color);
}

.info-contact {
  width: 100%;
  display: flex;
  align-items: center;
}

.desc-contact p {
  font-size: 11px;
  font-weight: 500;
  color: var(--light-gray);
  line-height: 160%;
  margin: 0;
  width: 100%;
  max-height: 90px;
  overflow-y: auto;
}

.img-item-messagers {
  width: 30px;
  height: 30px;
  overflow: visible;
}

.btn-toggle-devices-visibility {
  background: none;
  border: none;
}

</style>
