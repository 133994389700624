<template>
  <div class="two-factor-auth">
<!--    <h1 class="main-title">Choose the way of 2-Factor Authentication</h1>-->
    <h1 class="main-title">{{ titleText }}</h1>
    <div class="otp-wrapper">
      <span class="otp-subtitle">Enter one-time password</span>
      <v-otp-input
          ref="otpInput"
          input-classes="otp-input"
          separator="-"
          :num-inputs="6"
          @on-change="handleOnChange"
          @on-complete="handleOnComplete"
          :should-auto-focus="true"
          :input-type="'number'"
          class="otp"
          :class="otpValidator === null ? '' : otpValidator ? 'valid' : 'not-valid'"
      />
      <div v-if="otpValidator !== null && !otpValidator" class="phone-error" style="color:red;">Incorrect code</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LocalStorageService from "../../services/LocalStorageService";

const localStorageService = LocalStorageService.getService();

export default {
  name: "OtpCode",
  props:['otpVerify', 'otpCode', 'otpValid'],
  data() {
    return {
      otpValidator: this.otpVerify,
      token: localStorage.getItem('one_time_login_token'),
      type: localStorage.getItem('one_time_login_type_verification'),
      email: localStorage.getItem('one_time_login_email'),
      errorMessage: "",
    }
  },
  computed: {
    titleText() {
      if (this.type === 'EMAIL_2FA') {
        return "Provide one time token sent to your email";
      } else if (this.type === 'G2FA') {
        return "Provide one time token from your google authenticator";
      } else if (this.type === 'SMS_2FA') {
        return "Provide one time token sent to your phone";
      }
      return "Provide one time token";
    },
  },
  methods: {
    ...mapActions({
      loginOtp: 'login/loginOtp',
    }),
    handleOnComplete(value) {
      if(value === null) {
        return
      }
      value ? this.otpValidator = true : this.otpValidator = false
      this.loginOtp({
        params: {
          token: this.token,
          type: this.type,
          email: this.email,
          code: value
        }
      }).then((res) => {
        this.otpValidator = true
        localStorageService.setTokenLogin(res.data.body.token)
        this.$store.commit('authentication/loginSuccess', localStorageService.getAccessTokenLogin)
        localStorage.setItem('isLogged', true)
        localStorage.removeItem('one_time_login_token')
        localStorage.removeItem('one_time_login_type_verification')
        localStorage.removeItem('one_time_login_email')
        this.$router.push({name: 'Dashboard'})
      }).catch(error => {
        if(error.response.data.errors) {
          this.otpValidator = false
          let errorArr = []
          for (let key in error.response.data.errors) {
            errorArr.push(`${key[0].toUpperCase() + key.slice(1)} - ${error.response.data.errors[key]}`)
            this.errorMessage = errorArr.join('</br>')
          }
        }
        return false
      })
    },
    handleOnChange() {
      this.$refs.otpInput.$children.map((item) => {

        if(item.$el.firstChild.value.length) {
          item.$el.firstChild.classList.add('not-empty');
        }else{
          item.$el.firstChild.classList.remove('not-empty');
        }
      })
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    handleFocusInput() {
      this.$refs.otpInput.handleOnFocus(-1);
    },
  },
  mounted() {
    this.$refs.otpInput.$children.map((item, index) => {
      item.$el.firstChild.id = `login_phone-verify__otp_input_${index}`
    })
  },
}
</script>

<style>

.register-form-container .otp-wrapper {
  margin-bottom: 40px;
}

.otp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.otp-input {
  width: 51px;
  height: 71px;
  margin-right: 18px;
  background: #F8FCFC;
  box-shadow: 0px 2px 7px rgb(0 0 0 / 10%);
  border-radius: 2px;
  text-align: center;
  border: 1px solid transparent;
  padding: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: var(--black-color)
}

.otp-input:last-child {
  margin-right: 0;
}

.otp-input ~ span {
  display: none;
}

.otp-input .error {
  border: 1px solid red !important;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: block;
  margin-bottom: 10px;
  color: var(--secondary-black-color);
}

.otp-input:focus-visible {
  outline: none;
}

.otp.not-valid .otp-input {
  border: 1px solid var(--alert-color);
  color: var(--alert-color);
}


.otp.valid .otp-input {
  border: 1px solid var(--accent-color);
  color: var(--accent-color);
}

.otp-input.not-empty {
  border: 1px solid var(--accent-color);
  color: var(--accent-color);
}

.otp-wrapper {
  max-width: 400px;
  margin: 0 auto;
}

.two-factor-auth .main-title {
  margin-bottom: 30px;
  text-align: center;
  color: var(--secondary-black-color);
}


</style>
