<template>
  <section
    class="messages-section"
    :class="[
      isAdvancedFilter || isAdvancedFilterApplied ? 'advanced-filter' : 'simple-filter',
      isAdvancedFilter ? 'open' : '',
    ]"
  >
    <div class="top-bar">
      <div class="settings-wrapper">
        <CaseMessagesNavigation v-if="!isAdvancedFilter" />
        <template v-if="isAdvancedFilterApplied || isAdvancedFilter">
          <div v-if="isAdvancedFilter" class="filter-types">
            <button
              :class="{selected: !filterTypeSaved}"
              class="filter-type"
              @click="filterTypeSaved = false"
            >
              Default
            </button>
            <button
              :class="{selected: filterTypeSaved}"
              class="filter-type"
              @click="filterTypeSaved = true"
            >
              Saved
            </button>
          </div>
          <button
            v-show="isAdvancedFilter"
            class="btn btn-revert device-configure"
            @click="$router.push({ name: 'Configure Device', query: { clientId } })"
          >
            Configure Device
          </button>
        </template>
        <div class="advanced-change-button-wrapper">
          <button v-if="stringifiedRules" class="advanced-change-button add-to-favorites"
                  @click="openSaveFilterPatternPopup">
            <svg width="16" height="23" viewBox="0 0 16 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.9221 0.09375V22.3134C15.9219 22.5357 15.7975 22.7394 15.5996 22.8409C15.4017 22.9424 15.1636 22.9248 14.9829 22.795L7.99416 17.7856L1.00567 22.795C0.824907 22.9248 0.586905 22.9424 0.388995 22.8409C0.191085 22.7394 0.066638 22.5357 0.0664062 22.3134V0.09375H15.9221Z"
                fill="#082655" />
            </svg>
            <span>Add To Favorites</span>
          </button>
          <button
            v-if="isAdvancedFilterApplied || isAdvancedFilter"
            :disabled="!isAdvancedFilterApplied"
            class="reset-filters"
            @click="resetFilters"
          >
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.9997 2.33203C7.54801 2.33203 2.33301 7.54703 2.33301 13.9987C2.33301 20.4504 7.54801 25.6654 13.9997 25.6654C20.4513 25.6654 25.6663 20.4504 25.6663 13.9987C25.6663 7.54703 20.4513 2.33203 13.9997 2.33203ZM19.833 18.187L18.188 19.832L13.9997 15.6437L9.81134 19.832L8.16634 18.187L12.3547 13.9987L8.16634 9.81036L9.81134 8.16537L13.9997 12.3537L18.188 8.16537L19.833 9.81036L15.6447 13.9987L19.833 18.187Z"
                fill="#FF9537" />
            </svg>
            Reset Filters
          </button>
          <button @click="changeFilterView" class="advanced-change-button">
            <svg :class="{'advanced-mode': isAdvancedFilter}" width="24" height="25" viewBox="0 0 24 25" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.7236 19.6382L10.7236 21.1382C10.3912 21.3044 10 21.0627 10 20.691V13.7071C10 13.5745 9.94732 13.4473 9.85355 13.3536L4.14645 7.64645C4.05268 7.55268 4 7.4255 4 7.29289V5C4 4.72386 4.22386 4.5 4.5 4.5H19.5C19.7761 4.5 20 4.72386 20 5V7.29289C20 7.4255 19.9473 7.55268 19.8536 7.64645L14.1464 13.3536C14.0527 13.4473 14 13.5745 14 13.7071V19.191C14 19.3804 13.893 19.5535 13.7236 19.6382Z"
                stroke="#082655" />
            </svg>

            <span>Advanced</span>

            <svg :class="{'advanced-mode-arrow': isAdvancedFilter}" width="16" height="17" viewBox="0 0 16 17"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.99059 12.498C7.79067 12.4984 7.59692 12.4287 7.44298 12.3009L2.3091 8.01743C2.13437 7.87202 2.02448 7.66306 2.00362 7.43652C1.98276 7.20999 2.05263 6.98444 2.19787 6.80948C2.34311 6.63453 2.55181 6.52451 2.77806 6.50363C3.00432 6.48274 3.22959 6.5527 3.40433 6.69811L7.99059 10.5361L12.5768 6.83519C12.6644 6.76402 12.7651 6.71088 12.8732 6.67881C12.9813 6.64675 13.0946 6.63638 13.2068 6.64833C13.3189 6.66027 13.4275 6.69428 13.5265 6.7484C13.6254 6.80252 13.7127 6.87568 13.7833 6.96369C13.8617 7.05178 13.921 7.15512 13.9576 7.26724C13.9942 7.37936 14.0073 7.49784 13.9961 7.61526C13.9848 7.73268 13.9495 7.84652 13.8923 7.94963C13.835 8.05273 13.7572 8.1429 13.6635 8.21447L8.52964 12.3523C8.37128 12.4599 8.18148 12.5111 7.99059 12.498Z"
                fill="#8692A6" />
            </svg>
          </button>
        </div>
      </div>
      <div v-if="isLoad" v-show="isAdvancedFilter" class="advanced-filter-wrap">
        <SavedFilters
          v-if="filterTypeSaved"
          @attachFilter="attachFilter"
          @backToDefault="filterTypeSaved = false"
        />
        <div v-else class="control-section">
          <div class="col-lg-12 querybuilder-control">
            <ejs-querybuilder
              ref="querybuilder"
              :dataSource="dataSource"
              :maxGroupCount="100"
              :rule="importRules"
              @change="ruleChanged"
            >
              <e-columns>
                <e-column field='custodianEmail' label='Search your custodian' type='string' :template='defaultTemplate'
                          :operators="[{ value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }]" />
                <e-column field='message' label='Search for a Phrase' type='string'
                          :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'contain', key: 'contain' }, { value: 'not_contain', key: 'not contain' }]" />
                <!--                      <e-column field='SearchContact' label='Search the contact' type='boolean' :values="values"/>-->
                <e-column field='username' label='Search the contact' :template='defaultTemplate' type='string'
                          :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }, { value: 'contain', key: 'contain' }, { value: 'not_contain', key: 'not contain' }]" />
                <e-column field='imei' label='Search for IMEI' :template='defaultTemplate' type='string'
                          :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }, { value: 'contain', key: 'contain' }, { value: 'not_contain', key: 'not contain' }]" />
                <!--                      <e-column field='Email' label='Hire Date' type='date' format='dd/MM/yyyy' />-->
                <e-column field='phoneNumber' label='Phone number' :template='defaultTemplate' type='string'
                          :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }, { value: 'contain', key: 'contain' }, { value: 'not_contain', key: 'not contain' }]" />
                <e-column field='dataType' label='Data type' type='string' :template='defaultTemplate'
                          :operators="[{ value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }]" />
                <e-column field='osSource' label='OS source' type='string' :template='defaultTemplate'
                          :operators="[{ value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }]" />
                <!--                      <e-column field='device' label='Device' type='string' :template='messengerTemplate' :operators="['is', 'is not']" />-->
                <e-column field='messengerType' label='Messenger' type='string' :template='defaultTemplate'
                          :operators="[{ value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }]" />
                <e-column field='deviceOs' label='Device OS' type='string' :template='defaultTemplate'
                          :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }]" />
                <e-column field='deviceId' label='Device Id' :template='defaultTemplate' type='string'
                          :operators="[{ value: 'is', key: 'is' }, { value: 'is_not', key: 'is not' }, { value: 'in', key: 'in' }, { value: 'not_in', key: 'not in' }, { value: 'contain', key: 'contain' }, { value: 'not_contain', key: 'not contain' }]" />
                <e-column field='date' label='Date' type='date' :template='dateTemplate'
                          :operators="[{ value: 'range', key: 'range' }]" format='dd/MM/yyyy' />
              </e-columns>
            </ejs-querybuilder>
            <div class="advanced-btn-wrapper">
              <button class="btn btn-apply" type="button" @click="addRule">+ Add Rule</button>
              <button class="btn btn-apply" type="button" @click="addGroup">+ Add Group</button>
              <button
                :disabled="emptyRule"
                class="btn btn-apply"
                type="button"
                @click="filter(false)"
              >Apply
              </button>
              <span>{{ resultCount }} matches</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isAdvancedFilterApplied && !isAdvancedFilter" class="advanced-filter-wrap">
        <div class="filter-tags-close">
          <button class="advanced-change-button" @click="isOpenedFilterList = !isOpenedFilterList">
            <span>{{ isOpenedFilterList ? 'Close' : 'Show' }} Applied Filters</span>
            <svg
              :class="{'advanced-mode-arrow': isOpenedFilterList}"
              class="filter-tags-arrow"
              fill="none"
              height="17"
              viewBox="0 0 16 17"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.99059 12.498C7.79067 12.4984 7.59692 12.4287 7.44298 12.3009L2.3091 8.01743C2.13437 7.87202 2.02448 7.66306 2.00362 7.43652C1.98276 7.20999 2.05263 6.98444 2.19787 6.80948C2.34311 6.63453 2.55181 6.52451 2.77806 6.50363C3.00432 6.48274 3.22959 6.5527 3.40433 6.69811L7.99059 10.5361L12.5768 6.83519C12.6644 6.76402 12.7651 6.71088 12.8732 6.67881C12.9813 6.64675 13.0946 6.63638 13.2068 6.64833C13.3189 6.66027 13.4275 6.69428 13.5265 6.7484C13.6254 6.80252 13.7127 6.87568 13.7833 6.96369C13.8617 7.05178 13.921 7.15512 13.9576 7.26724C13.9942 7.37936 14.0073 7.49784 13.9961 7.61526C13.9848 7.73268 13.9495 7.84652 13.8923 7.94963C13.835 8.05273 13.7572 8.1429 13.6635 8.21447L8.52964 12.3523C8.37128 12.4599 8.18148 12.5111 7.99059 12.498Z"
                fill="#8692A6"
              />
            </svg>
          </button>
        </div>

        <ul v-show="isOpenedFilterList" class="control-tags">
          <li
            v-for="tag of filteredTags"
            :key="tag.id"
            :class="{ 'removed': tag.removed }"
            class="filter-tag"
          >
            <button type="button" @click="removeFilter(tag)">
              <svg fill="none" height="28" viewBox="0 0 28 28" width="28" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14 2.3335C7.54833 2.3335 2.33333 7.5485 2.33333 14.0002C2.33333 20.4518 7.54833 25.6668 14 25.6668C20.4517 25.6668 25.6667 20.4518 25.6667 14.0002C25.6667 7.5485 20.4517 2.3335 14 2.3335ZM19.8333 18.1885L18.1883 19.8335L14 15.6452L9.81166 19.8335L8.16666 18.1885L12.355 14.0002L8.16666 9.81183L9.81166 8.16683L14 12.3552L18.1883 8.16683L19.8333 9.81183L15.645 14.0002L19.8333 18.1885Z"
                  data-v-1f88d880=""
                  fill="#D7DCE5"
                />
              </svg>
            </button>

            <strong>{{ tag.label | filteringLabel }}</strong>:&nbsp;
            <span>{{ tag.initialValue || tag.value }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="filter-content">
      <div v-if="isViewWithSidebar" class="nav-chat">
        <div v-if="clientsArray.length" class="scroll-area">
          <MessagesList :clientsArray="clientsArray" :tabItemMsg="tabItemMsg" @setTabItemMsg='getTabItemMsg' />
        </div>
        <div v-else class="empty-chats-wrap">
          <span>No results</span>
        </div>
      </div>
      <div v-if="!isAdvancedFilterApplied" class="chat-list">
        <div class="messages">
          <SetMessages
            v-if="tabItemMsg"
            :tabItemMsg="tabItemMsg"
            :messages="messages"
            @changeCheckbox="changeMessageCheckbox"
            @downloadFile="downloadAttachment"
          />
          <SelectedMessagesInfo
            :messages="messages"
            :selectedMessagesId="selectedMessagesId"
            @updatedActiveStatus="updatedActiveStatus($event, 'messages')"
            @exportMessages="exportMessages($event)"
            @selectDB="format = $event"
          />
          <infinite-loading
            v-if="this.currentUser"
            ref="scrollWrapper"
            :identifier="infiniteId"
            @infinite="infiniteHandler"
          >
            <div slot="spinner">Loading...</div>
            <div slot="no-more">No more messages</div>
            <div slot="no-results">No results</div>
          </infinite-loading>
          <div v-else class="no-messages-wrapper">
            <img :src="noMessagesImg" alt="" />
            <span>Choose chat</span>
          </div>
        </div>
      </div>
      <div v-else-if="!isAdvancedFilterApplied && !isViewWithSidebar" class="chat-list-group">
        <SetGroupMessages
          :messages="messagesMerged"
          @changeCheckbox="changeMessageCheckbox"
          @downloadFile="downloadAttachment"
        />
        <SelectedMessagesInfo
          :messages="messagesMerged"
          :selectedMessagesId="selectedMessagesId"
          @updatedActiveStatus="updatedActiveStatus($event, 'messagesMerged')"
          @exportMessages="exportMessages($event)"
          @selectDB="format = $event"
        />
        <infinite-loading ref="scrollWrapper2" direction="bottom" :identifier="infiniteMerged"
                          @infinite="infiniteHandlerMerged">
          <div slot="spinner">Loading...</div>
          <div slot="no-more">No more messages</div>
          <div slot="no-results">No results</div>
        </infinite-loading>
      </div>
      <div v-else-if="isAdvancedFilterApplied" class="chat-list-group">
        <SetAdvancedMessages
          v-if="tabItemMsg"
          :tabItemMsg="tabItemMsg"
          :messages="messagesAdvanced"
          @changeCheckbox="changeMessageCheckbox"
          @downloadFile="downloadAttachment"
        />
        <SelectedMessagesInfo
          :messages="messagesAdvanced"
          :selectedMessagesId="selectedMessagesId"
          @updatedActiveStatus="updatedActiveStatus($event, 'messagesAdvanced')"
          @exportMessages="exportMessages($event)"
          @selectDB="format = $event"
        />
        <infinite-loading ref="scrollWrapper3" direction="bottom" :identifier="infiniteAdvancedId"
                          @infinite="infiniteHandlerAdvanced">
          <div slot="spinner">Loading...</div>
          <div slot="no-more">No more messages</div>
          <div slot="no-results">No results</div>
        </infinite-loading>
      </div>
    </div>
    <CommonDialog
      ref="createCaseDialog"
      :show-footer="false"
      :closable="false"
    >
      <h2 class="dialog-message-title">Exporting messages</h2>
      <p class="dialog-create-description" style="display:block; font-size: 14px;">Please wait a bit. The messages are
        being exported.</p>
      <div class="loader-p"></div>
    </CommonDialog>
    <SuccessPopup
      ref="successPopup"
      text="A URL will be sent to your email with a download link. Do not share this link."
    />
    <SaveFilterPattern
      ref="saveFilterPattern"
      @saveFilterPattern="saveFilterPattern"
    />
  </section>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import MessagesList from "./MessageList";
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import SetMessages from "./SetMessages";
import SetGroupMessages from "./SetGroupMessages"
import settings from "../../../mixins/scrollSettings";
import Vue from "vue";
import { QueryBuilderPlugin } from "@syncfusion/ej2-vue-querybuilder";
import { DateRangePicker } from '@syncfusion/ej2-calendars';
import { MultiSelect, CheckBoxSelection, AutoComplete } from '@syncfusion/ej2-dropdowns';
import { createElement, getComponent } from "@syncfusion/ej2-base";
import { Query } from '@syncfusion/ej2-data';
import SelectedMessagesInfo from '@/components/selected-messages-info/SelectedMessagesInfo';
import SuccessPopup from '@/components/popups/SuccessPopup';
import SetAdvancedMessages from '@/components/сases/case-messages/SetAdvancedMessages';
import moment from 'moment';
import debounce from '@/mixins/debounce';
import SavedFilters from '@/components/сases/case-messages/SavedFilters';
import SaveFilterPattern from '@/components/popups/SaveFilterPattern';
import CommonDialog from "@/components/popups/CommonDialog.vue";
import capitalizeFirstLetter from "@/mixins/capitalizeFirstLetter";
import noMessagesImg from "@/assets/img/no-messages-img.png"
import CaseMessagesNavigation from "@/components/сases/case-messages/CaseMessagesNavigation.vue";
import { mapActions } from "vuex";

MultiSelect.Inject(CheckBoxSelection);

Vue.use(QueryBuilderPlugin);

let inOperators = ['is', 'is_not', 'contain', 'not_contain'];
const types = ['value', 'deleteRule', 'deleteGroup', 'condition'];

export default {
  name: 'CaseMessagesMobile',
  components: {
    CaseMessagesNavigation,
    CommonDialog,
    SaveFilterPattern,
    SavedFilters,
    SetAdvancedMessages,
    SelectedMessagesInfo,
    InfiniteLoading,
    SetMessages,
    SetGroupMessages,
    SuccessPopup,
    MessagesList,
  },
  emits: ['show-error'],
  mixins: [settings, debounce, capitalizeFirstLetter],
  data() {
    return {
      noMessagesImg,
      filtersAppliedCount: 0,
      filtersAmountApplied: 0,
      isAdvancedFilterApplied: false,
      isLoad: true,
      dataRange: '',
      caseId: this.$route.params.slug,
      messengers: [],
      filterTypeSaved: false,
      searchText: "",
      keySearch: '',
      sortBy: '',
      type: '',
      isAdvancedFilter: false,
      dateFrom: '',
      dateTo: '',
      clientId: '',
      clientsArray: [],
      users: [],
      usersName: '',
      tabItemMsg: {},
      msgFilters: [],
      isOpenedFilterList: true,
      chatId: '',
      messages: [],
      messagesAdvanced: [],
      messagesMerged: [],
      selectedMessagesId: [],
      format: 'sql',
      filteredTags: [],
      isCheckedAllMessages: false,
      viewMessages: 'with-sidebar',
      withSidebar: 'with-sidebar',
      withoutSidebar: 'without-sidebar',
      infiniteId: +new Date(),
      infiniteAdvancedId: +new Date(),
      infiniteMerged: +new Date(),
      MessagesChatPage: 0,
      MessagesChatSize: 20,
      MessagesMergedPage: 1,
      MessagesMergedSize: 20,
      MessagesAdvancedPage: 0,
      MessagesAdvancedSize: 20,
      advancedInfiniteHandlerState: null,
      currentUser: 0,
      currentMessengers: 'All',
      selectUser: false,
      placeholderDate: 'Date range chats',
      dataSource: [],
      filterData: null,
      emptyRule: true,
      queryFilterParams: null,
      defaultQueryFilterParams: [],
      defaultImportRules: {
        'condition': "and",
        'rules': [{}, {
          field: 'custodianEmail',
          operator: 'in',
          key: 'in',
          type: 'string',
          label: 'Search your custodian'
        }]
      },
      importRules: {
        'condition': "and",
        'rules': [{}, {
          field: 'custodianEmail',
          operator: 'in',
          key: 'in',
          type: 'string',
          label: 'Search your custodian'
        }]
      },
      stringifiedRules: '',
      disabledFiltersId: [],
      resultCount: 0,
      clientList: [],
      groupCheckbox: false,
      initialData: [],
      dialog: {
        status: "",
        text: "Starting..."
      },
      progressPercentage: 0,
      dateTemplate: {
        create: () => {
          return createElement('input', {attrs: {'type': 'text'}});
        },
        destroy: (args) => {
          let daterangepicker = getComponent(document.getElementById(args.elementId), 'daterangepicker');
          if (daterangepicker)
            daterangepicker.destroy();
        },
        write: (args) => {
          let daterangepicker = new DateRangePicker({
            placeholder: 'Select date range',
            disabled: true,
            change: (e) => {
              this.$refs.querybuilder.$el.ej2_instances[0].notifyChange(e.value, e.element);
            },
          });

          if (args.values) {
            daterangepicker.startDate = new Date(this.formatDate(args.values[0]));
            daterangepicker.endDate = new Date(this.formatDate(args.values[1]));
          }

          daterangepicker.appendTo('#' + args.elements.id);
        }
      },

      defaultTemplate: {
        create: () => {
          return createElement('input', {attrs: {'type': 'text'}});
        },
        destroy: (args) => {
          let multiselect = getComponent(document.getElementById(args.elementId), 'multiselect');
          if (multiselect)
            multiselect.destroy();
          let autoComplete = getComponent(document.getElementById(args.elementId), 'autocomplete');
          if (autoComplete)
            autoComplete.destroy();
        },
        write: (args) => {
          let ds = []

          if (args.field === 'custodianEmail') {
            ds = this.clientList
          } else {
            this.initialData.forEach(item => {
              if (item.fieldName === args.field) {
                item.values.forEach(el => {
                  let elName = el[0].toUpperCase() + el.toLowerCase().slice(1)
                  ds.push({id: el, value: elName})
                })
              }
            })
          }

          if (inOperators.indexOf(args.operator) > -1) {
            let autoCompleteObj = new AutoComplete({
              dataSource: ds,
              fields: {text: 'value', value: 'id'},
              placeholder: `Find a ${this.stringModify(args.field)}`,
              highlight: true,
              itemTemplate: "<span class='filtering-name'>${id}</span>",
              change: (e) => {
                const {field} = this.$refs.querybuilder.$el.ej2_instances[0].prevItemData;
                let name = '';

                if (e.itemData) {
                  name = e.itemData.id;
                }

                if (field === 'phoneNumber' || field === 'imei') {
                  name = name.replace(/[()]/g, '');
                }

                this.$refs.querybuilder.$el.ej2_instances[0].notifyChange(name, e.element);
              }
            });
            autoCompleteObj.appendTo('#' + args.elements.id);
          } else {
            let multiSelectObj = new MultiSelect({
              dataSource: ds,
              value: args.values,
              fields: {text: 'value', value: 'id'},
              mode: 'CheckBox',
              placeholder: `Select ${this.stringModify(args.field)}`,
              change: (e) => {
                this.$refs.querybuilder.$el.ej2_instances[0].notifyChange(e.value, e.element);
              },
              filtering: function (e) {
                let query = new Query();
                //frame the query based on search string with filter type.
                query = (e.text != "") ? query.where("value", "startswith", e.text, true) : query;
                //pass the filter data source, filter query to updateData method.
                e.updateData(ds, query);
              }
            });
            multiSelectObj.appendTo('#' + args.elements.id);
            if (args.values) {
              multiSelectObj.change(multiSelectObj);
            }
          }
        }
      },

      textTemplate: {
        create: () => {
          return createElement('input', {attrs: {'type': 'text'}});
        },
        destroy: (args) => {
          let autoComplete = getComponent(document.getElementById(args.elementId), 'autocomplete');
          autoComplete.destroy();
        },
        write: (args) => {
          let ds = []

          this.initialData.forEach(item => {
            if (item.fieldName === this.$refs.querybuilder.$el.ej2_instances[0].prevItemData.field) {
              item.values.forEach(el => {
                ds.push({Name: el, Code: el})
              })
            }
          })

          let autoCompleteObj = new AutoComplete({
            dataSource: ds,
            fields: {value: "Code", text: "Name"},
            placeholder: `Find a ${this.$refs.querybuilder.$el.ej2_instances[0].prevItemData.field}`,
            highlight: true,
            itemTemplate: "<span class='filtering-name'>${Name}</span>",
            change: (e) => {
              const {field} = this.$refs.querybuilder.$el.ej2_instances[0].prevItemData;
              let name = e.itemData.Name;

              if (field === 'phoneNumber' || field === 'imei') {
                name = name.replace(/[()]/g, '');
              }

              this.$refs.querybuilder.$el.ej2_instances[0].notifyChange(name, e.element);
            }
          });
          autoCompleteObj.appendTo('#' + args.elements.id);
        }
      },
    }
  },
  watch: {
    viewMessages(val) {
      this.selectedMessagesId = [];
      this.isCheckedAllMessages = false;
      if (val === this.withSidebar) {
        this.messages.forEach(message => message.isActive = false);
      } else {
        this.messagesMerged.forEach(message => message.isActive = false);
      }
    },
    searchText() {
      if (!this.isViewWithSidebar) {
        this.messagesMerged = []
        this.infiniteMerged += 1;
      } else {
        this.getCaseMessages()
      }
    },
    currentUser(value) {
      if (value) {
        if (value !== +this.$route.query.clientId) {
          this.$router.replace({query: {clientId: value}});
        }

        this.clientId = value
        if (this.selectUser) {
          if (!this.isViewWithSidebar) {
            this.MessagesMergedPage = 1
            this.messagesMerged = []
            this.infiniteMerged += 1;
          } else {
            this.getCaseMessages(value)
          }
        }

        this.getFilterRules();
        this.selectUser = true
      }
    },
    currentMessengers(value) {
      if (value) {
        this.currentMessengers = value
        if (!this.isViewWithSidebar) {
          this.MessagesMergedPage = 1
          this.messagesMerged = []
          this.infiniteMerged += 1;
        } else {
          this.getCaseMessages(this.clientId)
        }
      }
    },
    dataRange(value) {
      if (value) {
        this.dateFrom = isNaN(Date.parse(value[0])) == false ? Date.parse(value[0]) : ''
        this.dateTo = isNaN(Date.parse(value[1])) == false ? Date.parse(value[1]) : ''

        if (this.groupCheckbox) {
          this.MessagesMergedPage = 1;
          this.messagesMerged = [];
          this.infiniteMerged += 1;
        } else {
          this.MessagesChatPage = 0;
          this.messages = [];
          this.getCaseMessages();
        }
      } else {
        this.dataRange = ''
        this.dateFrom = ''
        this.dateTo = ''
        this.MessagesChatPage = 0
        this.messages = []
        this.getCaseMessages()
      }
    },
  },
  filters: {
    filteringLabel(label) {
      let labelsList = label.split(" ")
      labelsList = labelsList.map(phrase => {
        if (phrase === 'a' || phrase === "for" || phrase === 'Search' || phrase === 'the') {
          return
        }
        return phrase;
      });

      return labelsList.join(" ")
    }
  },
  computed: {
    isViewWithSidebar() {
      return this.viewMessages === this.withSidebar;
    },
    isValidQueryFilterParams() {
      return this.queryFilterParams.length === 1 && this.queryFilterParams[0] && !this.queryFilterParams[0].rules.length || this.queryFilterParams[0].rules[0] && !this.queryFilterParams[0].rules[0].value;
    },
  },
  methods: {
    ...mapActions('caseMessages', {
      setEditMode: 'setEditMode',
      setChatId: 'setChatId',
    }),
    stringModify(str) {
      return str.split('').map((letter, idx) => {
        return letter.toUpperCase() === letter
          ? `${idx !== 0 ? ' ' : ''}${letter.toLowerCase()}`
          : letter;
      }).join('');
    },
    reset() {
      this.$refs.querybuilder.ej2Instances.reset()
      this.$refs.querybuilder.ej2Instances.levelColl = []
    },
    addRule(_, rule) {
      let count = Object.keys(this.$refs.querybuilder.ej2Instances.levelColl)[Object.keys(this.$refs.querybuilder.ej2Instances.levelColl).length - 1].slice(-1)
      this.$refs.querybuilder.ej2Instances.addRules([rule || {field: 'custodianEmail'}], `group${count}`);
    },
    addGroup(_, group) {
      let count = Object.keys(this.$refs.querybuilder.ej2Instances.levelColl)[Object.keys(this.$refs.querybuilder.ej2Instances.levelColl).length - 1].slice(-1)
      this.$refs.querybuilder.ej2Instances.addGroups([group || {
        'condition': "and",
        'rules': [{}, {field: 'custodianEmail'}]
      }], `group${count}`);

      document.querySelectorAll('.e-group-container').forEach(container => {
        const {classList} = container.previousSibling;

        if (classList && !classList.contains('last-rule-container')) {
          container.previousSibling.classList.add('last-rule-container');
        }
      })
    },
    updatedActiveStatus($event, messagesName) {
      this[messagesName] = $event.messages;
      this.isCheckedAllMessages = $event.isCheckedAllMessages;
      this.selectedMessagesId = $event.selectedMessagesId;
    },
    isCondition(item) {
      return item.condition !== undefined
    },
    hasRuleset(condition) {
      return !!condition.rules && !!condition.rules.length
    },
    formatDate(date) {
      const initial = date.split(/\//);

      return [initial[1], initial[0], initial[2]].join('/')
    },
    filterTags(rules) {
      rules.forEach(rule => {
        rule.rules ? this.filterTags(rule.rules) : this.filteredTags.push(rule);
      })
    },
    removeFilter(disabledTag) {
      let areThereRules = false;

      if (disabledTag.removed) {
        const disabledFilterId = this.disabledFiltersId.findIndex(el => el === disabledTag.id);

        this.disabledFiltersId.splice(disabledFilterId, 1);
      } else {
        this.disabledFiltersId.push(disabledTag.id);
      }

      disabledTag.removed = !disabledTag.removed;

      this.queryFilterParams = this.defaultQueryFilterParams
        .map(parameter => {
          const rules = parameter.rules.filter(rule => {
            for (let i = 0; i < this.disabledFiltersId.length; i++) {
              if (rule.id === this.disabledFiltersId[i]) {
                return false;
              }
            }

            return true;
          });

          if (rules.length) {
            areThereRules = true;
          }

          return {
            ...parameter,
            rules,
          };
        })
        .filter(queryParam => !!queryParam.rules.length);

      if (areThereRules) {
        this.messagesAdvanced = [];
        this.MessagesAdvancedPage = 0;

        this.debounce(() => {
          this.infiniteHandlerAdvanced(this.advancedInfiniteHandlerState);
        }, 1000);
      } else {
        this.resetFilters();
      }
    },

    flattenRules(rootRule) {
      if (!this.isCondition(rootRule) || !this.hasRuleset(rootRule)) return;

      const flat = (currentCondition) => {
        if (!this.hasRuleset(currentCondition)) return;

        // Filtering current ruleset to find inner conditions with non-empty rulesets;
        const nestedConditions = currentCondition.rules.filter(
          (item) => this.isCondition(item) && this.hasRuleset(item)
        );

        return [
          {
            ...currentCondition,
            // We exclude inner conditions from the ruleset and leave only the descriptions;
            rules: currentCondition.rules.filter((item) => !this.isCondition(item)),
          },
          ...nestedConditions.reduce(
            // We recursively call this function with all of the nested conditions;
            (acc, condition) => [...acc, ...flat(condition)],
            []
          ),
        ];
      };

      return flat(rootRule);
    },
    flatten(conditionsResult, setId) {
      let conditionsResultArray = this.flattenRules(conditionsResult)

      conditionsResultArray.forEach((item) => {
        if (item.condition === null) {
          item.condition = 'and'
        }
        if (item.rules.length) {
          item.rules.forEach((rule) => {
            if (setId) {
              rule.id = Math.random().toString(36).substr(2, 9);
            }
            if (rule.field !== 'date') {
              if (rule.field === 'custodianEmail') {
                rule.field = 'clientId';
              }

              if (Array.isArray(rule.value)) {
                rule.value = rule.value.join(';')
              }
            }
          })
        }
      })

      return conditionsResultArray
    },
    changeFilterView() {
      this.isAdvancedFilter = !this.isAdvancedFilter;
    },
    filter(getResultCount) {
      if (!getResultCount) {
        this.stringifiedRules = JSON.stringify({
          clientId: this.clientId,
          rules: this.$refs.querybuilder.ej2Instances.rule.properties
        });

        localStorage.setItem('rules', this.stringifiedRules);
        this.queryFilterParams = this.getQueryParams(true);
      }

      this.filtersAmountApplied = this.queryFilterParams.reduce((a, b) => a + b.rules.length, 0)
      this.queryFilterParams = this.queryFilterParams.filter(param => param.rules.length);

      this.queryFilterParams.forEach(item => {
        if (item.rules.length) {
          item.rules.forEach((el, i) => {
            if (!el.value) {
              item.rules.splice(i, 1);
              return;
            }
            if (el.field === 'date') {

              if (el.value.length) {
                let dateObj = {
                  field: "date",
                  label: "Date",
                  operator: "gth",
                  type: "date",
                  value: ""
                }
                let dateFromObj = Object.assign({}, dateObj)
                let dateToObj = Object.assign({}, dateObj)
                let dateFrom = el.value[0].split('/')
                let dateTo = el.value[1].split('/')
                let dateFromTime = `${dateFrom[2]}-${dateFrom[1]}-${dateFrom[0]}`
                let dateToTime = `${dateTo[2]}-${dateTo[1]}-${dateTo[0]}`
                dateFrom = moment(dateFromTime).valueOf()
                dateTo = moment(dateToTime).endOf('day').valueOf()
                dateFromObj.value = dateFrom
                dateFromObj.operator = 'gth'
                dateToObj.value = dateTo
                dateToObj.operator = 'lth'
                dateFromObj.initialValue = dateFromTime
                dateToObj.initialValue = dateToTime
                item.rules.push(dateFromObj)
                item.rules.push(dateToObj)
                item.rules.splice(i, 1);
              }
            } else if (el.field === 'clientId') {
              let arr = el.value.split(';')
              arr.map((item, i) => {
                const client = this.clientList.find(client => client.id === item);

                if (client) {
                  arr[i] = client.value
                }
              })
              el.initialValue = arr.join(';')
            }
          })
        }
      })

      this.filterData = JSON.stringify(this.msgFilters, null, 4)
      this.MessagesAdvancedPage = 0;

      if (this.advancedInfiniteHandlerState) {
        this.advancedInfiniteHandlerState.reset();
      }

      if (getResultCount) {
        this.getAdvancedResultAmount();
      } else {
        this.viewMessages = this.withoutSidebar;
        this.messagesAdvanced = [];
        this.filteredTags = [];
        this.isAdvancedFilterApplied = true;
        this.isAdvancedFilter = false;
        this.defaultQueryFilterParams = this.queryFilterParams;
        this.filterTags(this.queryFilterParams);
        this.debounce(() => this.infiniteAdvancedId += 1, 0);
      }
    },
    openSaveFilterPatternPopup() {
      this.$refs.saveFilterPattern.openPopups();
    },
    async saveFilterPattern(filterName) {
      await this.$load(async () => {
        try {
          await this.$api.cases.saveFilterPattern(this.caseId, {
            name: filterName,
            value: this.stringifiedRules,
          });
          this.stringifiedRules = '';
        } catch (error) {
          this.$emit('show-error', error.response.data.errors.message.toString());
        }
      })
    },
    async attachFilter(filterId) {
      await this.$load(async () => {
        const filter = await this.$api.cases.getFilterPattern(this.caseId, filterId);
        this.filterTypeSaved = false;
        this.setParsedRules(JSON.parse(filter.data.body.value));
      });
    },
    resetFilters() {
      localStorage.removeItem('rules');
      this.stringifiedRules = '';
      this.isAdvancedFilter = true;
      this.isAdvancedFilterApplied = false;
      this.filtersAmountApplied = 0;
      this.MessagesChatPage = 0
      this.MessagesAdvancedPage = 0
      this.queryFilterParams = null;
      this.messages = [];
      this.messagesAdvanced = [];
      this.viewMessages = this.withSidebar;
      this.groupCheckbox = false
      this.filteredTags = [];
      this.isOpenedFilterList = true;
      this.resultCount = 0;
      this.reset();
      this.$refs.querybuilder.ej2Instances.setRules(this.defaultImportRules)
      this.filtersAppliedCount = 0
      this.getUsersInMsg();
      this.getMessengers();
    },
    getQueryParams(setId = false) {
      this.msgFilters = this.$refs.querybuilder.ej2Instances.getValidRules(this.$refs.querybuilder.ej2Instances.rule);
      return this.flatten(this.msgFilters, setId);
    },
    setSavedRules(rules) {
      rules.forEach(rule => {
        if (rule.rules && rule.rules.length) {
          this.addGroup(false, {'condition': rule.condition || 'and', 'rules': [{}]});
          this.setSavedRules(rule.rules);
        } else if (rule.value) {
          this.addRule(false, rule);
        }
      });
    },
    changedQueryBuilder(e) {
      if (e.type === 'insertRule') {
        return;
      }

      this.queryFilterParams = this.getQueryParams();

      if (this.isValidQueryFilterParams) {
        this.resultCount = 0
        return;
      }

      if (e.type === 'deleteGroup') {
        const groupID = e.groupID.split('group').join('');

        this.queryFilterParams.splice(groupID, 1);
      }

      if (types.some(type => type === e.type)) {
        this.filter(true);
      }
    },
    checkIsNestedRulesNotEmpty(rules) {
      return rules.every(rule => {
        if (rule.value === undefined) return true;

        return rule.rules
          ? this.checkIsNestedRulesNotEmpty(rule.rules)
          : rule.value && rule.value.length;
      });
    },
    ruleChanged(event) {
      const rules = [];

      this.$refs.querybuilder.ej2Instances.rule.properties.rules.forEach(rule => {
        if (rule.rules) {
          if (!rule.rules.length) {
            return;
          }

          rules.push(this.checkIsNestedRulesNotEmpty(rule.rules));
          return;
        }

        rules.push(rule.value && !!rule.value.length);
      });

      this.emptyRule = rules.some(rule => rule !== undefined && !rule);

      if (!this.emptyRule || (!this.emptyRule && event.type === 'condition')) {
        this.debounce(this.changedQueryBuilder, 1000, event)
      }
    },
    changeCheckbox() {
      this.dataRange = ""
      this.dateFrom = ""
      this.dateTo = ""
      this.viewMessages = this.isViewWithSidebar ? this.withoutSidebar : this.withSidebar
    },
    changeMessageCheckbox(item) {
      if (item.isActive) {
        this.selectedMessagesId.push(item.id);
      } else {
        this.selectedMessagesId = this.selectedMessagesId.filter(id => id !== item.id);
      }
    },
    downloadAttachment(attachment) {
      this.$load(async () => {
        await this.$api.cases
          .downloadAttachment(this.caseId, attachment.id)
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', attachment.name);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          }, (error) => {
            if (error.response) {
              this.$emit('show-error', error.response.data.errors.message[0]);
            } else {
              this.$emit('show-error', error.data.errors.message[0]);
            }
          });
      });
    },
    getTabItemMsg(data) {
      if (this.tabItemMsg.id !== data.chatId) {
        this.MessagesChatPage = 0
        this.messages = []
        this.tabItemMsg = data
        this.chatId = data.chatId
        this.setChatId(this.chatId)
        this.infiniteId += 1;
      }
    },

    // users in select
    async getUsersInMsg() {
      await this.$load(async () => {
        // this.loadFlagPermission = true
        await this.$api.cases.getUsersInMessages({
          caseId: this.caseId,
        }).then((res) => {
          this.clientList = []
          this.users = res.data.body
          this.users.forEach((item) => {
            this.clientList.push({id: String(item.id), value: item.name})
          })

          const currentUser = this.users.find(user => user.id === this.currentUser);

          if (currentUser) {
            this.usersName = currentUser.name;
          } else {
            this.currentUser = this.users[0].id;
            this.usersName = this.users[0].name;
          }

          this.getCaseMessages(this.currentUser)
          this.isLoad = true
        }, (error) => {
          if (error.response) {
            this.$emit('show-error', error.response.data.errors.message[0]);
          } else {
            this.$emit('show-error', error.data.errors.message[0]);
          }
        })
        setTimeout(() => {
          // this.loadFlagPermission = false
        }, 1000)
      })
    },

    // viber.... in select
    getMessengers() {
      this.$load(async () => {
        // this.loadFlagPermission = true
        await this.$api.cases.getMessengersInMessages(this.caseId, this.currentUser).then((res) => {
          this.messengers = res.data.body.types.map(i => {
            return {
              name: i
            }
          })
        }, (error) => {
          if (error.response) {
            this.$emit('show-error', error.response.data.errors.message[0]);
          } else {
            this.$emit('show-error', error.data.errors.message[0]);
          }
        })
        setTimeout(() => {
          // this.loadFlagPermission = false
        }, 1000)
      })
    },

    fixedEncodeURIComponent(str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    },

    // left list
    getCaseMessages(value) {
      this.placeholderDate = 'Date range chats'
      this.$load(async () => {
        await this.$api.cases.getCaseMessages({
          caseId: this.caseId,
          clientId: value || this.clientId,
          messengers: this.currentMessengers,
          keySearch: this.fixedEncodeURIComponent(this.searchText),
          sortBy: this.sortBy,
          type: this.type,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
        }).then((res) => {
          this.clientsArray = res.data.body;
          this.tabItemMsg = this.clientsArray[0].chatRooms[0].lastMessageChatInfo[0]
          this.chatId = this.clientsArray[0].chatRooms[0].lastMessageChatInfo[0].chatId;
          this.MessagesChatPage = 0
          this.messages = []
          this.infiniteId += 1;
          this.setChatId(this.clientsArray[0].chatRooms[0].lastMessageChatInfo[0].chatId)
        }, (error) => {
          if (error.response) {
            this.$emit('show-error', error.response.data.errors.message[0]);
          } else {
            this.$emit('show-error', error.data.errors.message[0]);
          }
        })
        setTimeout(() => {
          // this.loadFlagPermission = false
        }, 1000)
      }, (error) => {
        this.infiniteId += 1;
        if (error.response) {
          this.$emit('show-error', error.response.data.errors.message[0]);
        } else {
          this.$emit('show-error', error.data.errors.message[0]);
        }
      })
    },
    infiniteHandler($state) {
      this.placeholderDate = 'Date range chats'
      if (this.chatId) {
        this.$load(async () => {
          this.MessagesChatPage += 1;
          await this.$api.cases.getMessagesChat({
            caseId: this.caseId,
            chatId: this.chatId,
            page: this.MessagesChatPage,
            size: this.MessagesChatSize
          }).then((res) => {
            if (res.data.body.content.length) {
              if (this.isCheckedAllMessages) {
                res.data.body.content.forEach(message => {
                  this.selectedMessagesId.push(message.id);
                  message.isActive = true;
                });
              }
              const newMessages = res.data.body.content;
              const existingMessageIds = new Set(this.messages.map(msg => msg.id));
              const uniqueNewMessages = newMessages.filter(msg => !existingMessageIds.has(msg.id));
              this.messages.push(...uniqueNewMessages);
              $state.loaded();
            } else {
              $state.complete();
            }
          }, (error) => {
            if (error.response) {
              this.$emit('show-error', error.response.data.errors.message[0]);
            } else {
              this.$emit('show-error', error.data.errors.message[0]);
            }
            $state.error()
          })
        })
      }
    },
    getAdvancedResultAmount() {
      if (this.queryFilterParams.length) {
        this.$load(() => {
          this.$api.cases.sendAdvancedFilterParams(this.caseId, this.queryFilterParams, 1, this.MessagesAdvancedSize, true)
            .then(res => {
              this.resultCount = res.data.body.totalElements;
            })
        });
      }
    },
    infiniteHandlerAdvanced($state) {
      if (this.queryFilterParams) {
        this.$load(() => {
          this.MessagesAdvancedPage += 1;
          this.$api.cases.sendAdvancedFilterParams(this.caseId, this.queryFilterParams, this.MessagesAdvancedPage, this.MessagesAdvancedSize, false).then((res) => {
            if (res.data.body.content.length) {
              this.messagesAdvanced.unshift(...res.data.body.content.reverse());
              if ($state) {
                // this.infiniteAdvancedId += 1;
                this.advancedInfiniteHandlerState = $state;
                $state.loaded();
              }
            } else {
              if ($state) {
                $state.complete();
              }
            }
          }, (error) => {
            if (error.response) {
              this.$emit('show-error', error.response.data.errors.message[0]);
            } else {
              this.$emit('show-error', error.data.errors.message[0]);
            }
          })
        })
      }
    },
    infiniteHandlerMerged($state) {
      this.placeholderDate = 'Date range messages'
      this.$load(async () => {
        await this.$api.cases.getMessagesMerged({
          caseId: this.caseId,
          clientId: this.clientId,
          messengers: this.currentMessengers,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          page: this.MessagesMergedPage,
          size: this.MessagesMergedSize
        }).then((res) => {

          if (res.data.body.content.length) {
            this.MessagesMergedPage += 1;
            this.messagesMerged.unshift(...res.data.body.content.reverse());
            $state.loaded();
          } else {
            $state.complete();
          }
        }, (error) => {
          if (error.response) {
            this.$emit('show-error', error.response.data.errors.message[0]);
          } else {
            this.$emit('show-error', error.data.errors.message[0]);
          }
          $state.error()
        })
      })
    },

    exportMessages(data) {
      this.$load(async () => {
        this.$refs.createCaseDialog.openPopups();
        await this.$api.cases.exportMessages(this.caseId, this.format, {messageIds: data}).then(async (response) => {
          // eslint-disable-next-line no-undef
          const reader = response.body.pipeThrough(new TextDecoderStream()).getReader()
          await this.readStreamData(reader);
          if (!this.dialog.status.includes("error")) {
            this.dialog.text = "Starting..."
            this.dialog.status = ""
            this.$refs.successPopup.openPopup();
          }
        }, (error) => {
          if (error.response) {
            this.$emit('show-error', error.response.data.errors.message[0]);
          } else {
            this.$emit('show-error', error.data.errors.message[0]);
          }
        })
      })
    },

    async readStreamData(reader) {
      this.progressPercentage = 0;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const {value, done} = await reader.read();
        if (done) {
          this.$refs.createCaseDialog.closePopups();
          break;
        }
        const splitChunks = value.toString().split("\n");
        for (let i = 0; i < splitChunks.length; i++) {
          if (splitChunks[i].trim()) {
            if (splitChunks[i].includes("event:")) {
              const dataStatus = splitChunks[i].split("event:").find(chunk => chunk.trim() !== '');
              if (dataStatus && typeof dataStatus !== "undefined") {
                this.dialog.status = dataStatus;
              }
            }
            if (splitChunks[i].includes("data:")) {
              const dataText = splitChunks[i].split("data:").find(chunk => chunk.trim() !== '');
              if (dataText && typeof dataText !== "undefined") {
                this.dialog.text = dataText;
              }
            }
          }
        }
        this.progressPercentage += 33;
      }
    },

    getFilterRules() {
      this.isLoad = false;
      this.$load(async () => {
        await this.$api.cases.getFilterRules(this.caseId, this.clientId).then((res) => {
          this.initialData = res.data.body;
          this.isLoad = true;
        })
      })
    },

    setParsedRules(parsedRules) {
      this.importRules = {};
      this.viewMessages = this.withoutSidebar;
      this.isAdvancedFilterApplied = true;

      setTimeout(() => {
        const {rules} = parsedRules.rules;
        const lastRuleRules = rules[rules.length - 1].rules;

        this.setSavedRules(!lastRuleRules || lastRuleRules && lastRuleRules.length ? rules : rules.reverse());
        this.queryFilterParams = this.getQueryParams(true);

        this.filter(true);
        this.filter();
      }, 1000);
    }
  },
  async mounted() {
    await this.setEditMode(false);
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach((mutation) => {
        if (
          mutation.type === 'childList'
          && mutation.addedNodes[0]
          && mutation.addedNodes[0].nodeType === Node.ELEMENT_NODE
        ) {
          if (mutation.addedNodes[0].querySelector('[style*="background-color: rgba(0, 0, 0, 0.5);"]')) {
            mutation.addedNodes[0].remove();
          }
        }
      });
    });

    document.querySelectorAll('a').forEach(element => {
      if (element.textContent.includes('Claim your free account')) {
        element.parentNode.remove();
      }
    });

    observer.observe(document.body, {childList: true});
    let el = document.querySelectorAll("*[id='js-licensing']")
    if (el[0]) {
      el[0].remove()
    }

    const rulesFromServer = localStorage.getItem('rules');
    const clientId = this.$route.query.clientId

    if (clientId) {
      this.clientId = clientId;
      this.currentUser = clientId;
      await this.getFilterRules()
    }

    await this.getUsersInMsg()
    this.getMessengers()

    if (rulesFromServer) {
      const parsedRules = JSON.parse(rulesFromServer);

      if (parsedRules.clientId === this.clientId) {
        this.setParsedRules(parsedRules);
      }
    }
  }
};
</script>

<style scoped>

.messages-section {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 27px 55px 27px 35px;
}

.filter-content {
  display: flex;
  width: 100%;
}

.nav-chat {
  margin-top: 92px;
  width: 381px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #D7DCE6;
}

.nav-chat .scroll-area {
  height: fit-content;
}

.advanced-filter .nav-chat {
  margin-top: 0;
}

.advanced-filter .chat-list {
  margin-top: 30px;
}

.simple-filter .top-bar {
  padding: 30px 24px;
}

.chat-list {
  width: 100%;
  height: 100%;
  max-height: 90vh;
  overflow: visible;
  margin-top: 120px;
  display: flex;
  overflow-y: auto;
  scrollbar-color: var(--background-color) transparent;
  scrollbar-width: thin;
}

.chat-list .messages {
  width: 100%;
  height: 100%;
}

.top-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 28px 22px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(77, 106, 147, 0.15);
}

.advanced-filter .top-bar {
  position: static;
  width: 100%;
}

.open.advanced-filter .top-bar {
  padding-bottom: 35px;
}

.advanced-change-button-wrapper {
  display: flex;
}

.settings-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.messages-section.open .settings-wrapper {
  padding-bottom: 28px;
  border-bottom: 0.5px solid var(--case-filters-border-color);
}

.filter-types {
  border-radius: 79px;
  outline: 1px solid var(--case-btn-border-color);
  width: fit-content;
  min-height: 35px;
}

.filter-type {
  height: 35px;
  position: relative;
  z-index: 2;
  transition: outline 0.3s, color 0.3s;
  border: none;
  border-radius: 79px;
  outline: 1px solid transparent;
  width: 144px;
  padding: 5px 16px;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: var(--light-gray);
}

.filter-type.selected {
  outline: 1px solid var(--accent-color);
  color: var(--accent-color);
}

.filter-type:hover {
  color: var(--accent-color);
}

.reset-filters {
  background-color: transparent;
  display: flex;
  align-items: center;
  border: none;
  margin-right: 23px;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  color: var(--secondary-black-color);
}

.reset-filters:disabled path {
  fill: var(--case-btn-border-color);
}

.reset-filters:disabled {
  cursor: not-allowed;
}

.reset-filters svg {
  margin-right: 10px;
}

.reset-filters:not(:disabled):hover {
  text-decoration: underline;
}

.add-to-favorites {
  margin-right: 23px;
}

.advanced-change-button {
  height: 30px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  padding: 0;
  background-color: unset;
}

.advanced-change-button span {
  margin: 0 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #373C44;
}

.advanced-change-button:hover span {
  text-decoration: underline;
}

.advanced-mode {
  fill: var(--accent-color);
}

.advanced-mode-arrow {
  transform: rotate(180deg);
}

/* end top bar */

/* for view group */
.chat-list-group {
  width: 100%;
  height: calc(100vh - 500px);
  min-height: 940px;
  overflow: auto;
  margin-top: 30px;
}

.empty-chats-wrap {
  text-align: center;
  margin-top: 45px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.advanced-filter-wrap {
  width: 100%;
  margin-top: 20px;
  position: relative;
}

.e-query-builder {
  width: 100% !important;
}

.control-tags {
  margin-bottom: 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.filter-tag {
  display: flex;
  align-items: center;
  width: max-content;
  padding: 6px 12px;
  border-radius: 20px;
  background-color: var(--accent-color);
  font-size: 13px;
  color: var(--white-color);
}

.filter-tag button {
  display: flex;
  margin-right: 8px;
  border: navajowhite;
  padding: 0;
  background-color: unset;
}

.filter-tag.removed path {
  fill: #ff9537;
}

.filter-tag svg {
  width: 18px;
  height: 18px;
}

.filter-tag path {
  transition: fill 0.3s;
}

.filter-tag strong {
  text-transform: capitalize;
}

.filter-tags-close {
  display: flex;
  align-items: center;
}

.filter-tags-arrow {
  transition: all 0.3s;
}

.filtering-name b {
  color: red;
}

.device-configure {
  padding: 9px 20px;
  max-height: 35px;
}


.dialog-message-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #292B2F;
  text-align: center;
  margin: 25px 0 0;
  display: block;
  width: 100%;
}

.dialog-create-description {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  color: var(--light-gray);
  margin: 10px 0 0;
}

.loader-p {
  width: 50px;
  height: 50px;
  position: relative;
  margin: 20px 40px 20px 0;
}

.no-messages-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 150px auto;
  max-width: 350px;
  width: 100%;
  gap: 40px;
}

.no-messages-wrapper span {
  font-size: 16px;
  font-weight: 500;
  line-height: 25.6px;
  text-align: center;
  color: var(--light-gray);
}
</style>