<template>
  <div class="holder-empty-notification">
    <div class="holder-img">
      <img src="@/assets/img/empty-notification.svg" alt="empty-notification">
    </div>
    <div class="holder-text">
      <p>Any Notifications yet. Start with creating Cases</p>
    </div>
    <div class="holder-btn">
      <button
          id="create_case_in_empty_notification"
          class="btn">Create case</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyNotification"
}
</script>

<style scoped>
.holder-empty-notification {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 190px;
}

.holder-img {
  margin-bottom: 56px;
}

.holder-text {
  margin-bottom: 15px;
}

.holder-btn {
  display: flex;
  justify-content: center;
}
</style>