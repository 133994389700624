<template>
  <div class="wrapper">
    <AlertErrorMsg
        class="page-alert-error"
        ref="alert"
    />
    <Sidebar />
    <div class="content-wrapper">
      <HeaderRegistered />
      <main class="main">
        <section class="dashboard-section">
          <div class="holder-tab-sorts">
            <div class="tab-wrapper">
              <router-link
                  :to="{ path: '/billing-account/payment-history' }"
                  tag="button"
                  exact-path
                  class="tab-btn history-tab"
                  id="billing_history_btn"
              >
                <span class="tab-btn-text">Payment History</span>
              </router-link>
              <router-link
                  :to="{ path: '/billing-account/regPack' }"
                  tag="button"
                  exact-path
                  class="tab-btn regpack-tab"
                  id="billing_regpack_btn"
              >
                <span class="tab-btn-text">Regpack</span>
              </router-link>
            </div>
          </div>
<!--          <ProblemCardPopup />-->
<!--          <h2 class="title">Payment status</h2>-->
          <!-- Archived CASES CARDS -->
<!--          <div class="holder-cards">-->
<!--            <transition name="tray">-->
<!--              <div v-if="show" class="archived-plan-box">-->
<!--                <div class="archived-title">-->
<!--                  <h5>Full plan</h5>-->
<!--                  <span>33 cases</span>-->
<!--                </div>-->
<!--                <div v-if="ifLastPayment" class="archived-plan-content">-->
<!--                  <button class="btn archived-plan-btn btn-error">not-paid</button>-->
<!--                  <div>-->
<!--                    <a class="accent-link">Try again</a>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div v-if="!ifLastPayment" class="archived-plan-content">-->
<!--                  <button-->
<!--                      :id="`archived_case_paid`"-->
<!--                      class="btn archived-plan-btn">paid</button>-->
<!--                  <div>-->
<!--                    <h3 class="last-payment-text">Last payment</h3>-->
<!--                    <span class='data'>22/09/21</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="archived-plan-footer">-->
<!--                  <span class='sum'>$100</span>-->
<!--                  <p class="data-card">**** **** **** 9102</p>-->
<!--                </div>-->
<!--              </div>              -->
<!--            </transition>-->
<!--            <transition name="tray">-->
<!--              <div v-if="show" class="archived-plan-box">-->
<!--                <div class="archived-title">-->
<!--                  <h5>Active Cases</h5>-->
<!--                  <span>33 cases</span>-->
<!--                </div>-->
<!--                <div v-if="ifLastPayment" class="archived-plan-content">-->
<!--                  <button-->
<!--                      :id="`active_case_paid`"-->
<!--                      class="btn archived-plan-btn">paid</button>-->
<!--                  <div>-->
<!--                    <h3 class="last-payment-text">Last payment</h3>-->
<!--                    <span class='data'>22/09/21</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div v-if="!ifLastPayment" class="archived-plan-content">-->
<!--                  <button-->
<!--                      :id="`case_not_paid`"-->
<!--                      class="btn archived-plan-btn btn-error">not-paid</button>-->
<!--                  <div>-->
<!--                    <a class="accent-link">Try again</a>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="archived-plan-footer">-->
<!--                  <span class='sum'>$100</span>-->
<!--                  <p class="data-card">**** **** **** 9102</p>-->
<!--                </div>-->
<!--              </div>              -->
<!--            </transition>-->
<!--          </div>-->
          <!-- end Archived CASES CARDS -->
          <router-view></router-view>
        </section>
      </main>
    </div>
  </div>
</template>

<script>

import Sidebar from "../components/Sidebar";
import HeaderRegistered from "../components/layout/HeaderRegistered";
import AlertErrorMsg from "../components/profile/AlertErrorMsg";

export default {
  name: "ProfileBilling",
  components: {
    HeaderRegistered,
    Sidebar,
    AlertErrorMsg,
  },
}
</script>

<style scoped>

/* tabs */
.holder-tab-sorts {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.tab-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 886px;
  width: 100%;
  min-height: 35px;
  border-radius: 79px;
  border: 1px solid var(--case-btn-border-color);
}

.tab-btn {
  margin: -1px -1px -1px -1px;
  width: 33.33%;
  height: 38px;
  padding: 10px 50px;
  border: .5px solid transparent;
  outline: none;
  background-color: transparent;
  border-radius: 79px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  transition: 0.3s all;
}

.tab-btn.active {
  border-color: var(--accent-color);
}

.tab-btn-text {
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: var(--light-gray);
  transition: 0.3s all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-btn.active .tab-btn-text {
  color: var(--accent-color);
}

.holder-tab-sorts {
  margin-bottom: 16px;
}

.tab-btn {
  width: auto;
}

.tab-wrapper {
  width: auto;
}
</style>