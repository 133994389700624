export default function(instance) {
  return {
    getCaseConfig(caseId) {
      return instance.get(`/caseConfig/case/${caseId}/config`)
    },
    getClientsConfig(caseId, keySearch) {
      return instance.get(`/caseConfig/case/${caseId}/config/clients?keySearch=${keySearch}`)
    },
    updateCaseConfig(caseId, publicId, data) {
      return instance.post(`/caseConfig/case/${caseId}/config?publicId=${publicId}`, data)
    },
  }
}