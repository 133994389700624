<template>
      <tbody>
        <tr
            v-for="(item, i) in approvedList"
            :key="item + i"
        >
          <td class="w-300">
            <div class="holder-info">
            <div class="img">
              <img
                  v-if="item.avatar"
                  :src="item.avatar"
                  onerror="this.src='https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png';"
                  alt=""
              >
              <img
                  v-else
                  src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/girl_female_woman_avatar-512.png"
                  alt=""
              >
            </div>
            <div class="info">
            <span
                class="info-name"
                v-if="item.firstName && item.lastName"
                v-text="(item.firstName.toLowerCase()[0].toUpperCase() + item.firstName.toLowerCase().slice(1)) + ' ' + (item.lastName.toLowerCase()[0].toUpperCase() + item.lastName.toLowerCase().slice(1))"
            ></span>
              <span class="info-mail">{{item.email}}</span>
            </div>
          </div>
          </td>
          <td>
            <span
              v-if="item.role"
              v-text="getRoleNameFE(item.role)"
              class="span-cases"
              :class="`span-cases-` + item.role.toLowerCase()"
            ></span>
          </td>
          <td>
            <span class="span-cases" v-if="item.cases.length > 1">{{item.cases.length}} cases</span>
            <span v-else-if="item.cases.length === 1">{{item.cases[0].caseName}}</span>
            <span v-else>-</span>
          </td>
          <td>
            <div class="requestdate">
              <span>{{ item.requestDate | moment("D MMM, YYYY") }}</span>
            </div>
          </td>
          <td class="w-200">
            <div class="requestdate">
              <span>{{ item.acceptedDate | moment("D MMM, YYYY") }}</span>
            </div>
          </td>
        </tr>
      </tbody>
</template>

<script>
import checkPermission from "../../utils/permission";
import '@/assets/css/table.css';
import getRoleName from "@/mixins/getRoleName";
export default {
  name: "ApprovedNewItem",
  props: {
    approvedList: {
      type: Array
    },
  },
  data() {
    return {
      margin0: 'm-0',
      newArr: this.casesList,
      caseId: this.$route.params.slug,
      errorMessage: '',
    }
  },
  mixins: [getRoleName],
  methods: {
    checkPermission,
    deactivateUser (item) {
      this.$emit('deactivateUser', item)
    },
  }
}
</script>

<style scoped>
.approved-body tr:hover {
  box-shadow: 0px 4px 20px rgba(77, 106, 147, 0.15);
}

.approved-body .custom-select {
  padding-right: 46px;
}

.span-cases-wrap {
  border: none;
  text-align: left;
  padding: 0;
}

.span-cases {
  font-size: 12px;
  /*padding: 10px 8px;*/
}

.info-mail {
  text-overflow: ellipsis;
  max-width: 180px;
  overflow: hidden;
  color: var(--light-gray);
  font-size: 12px;
  font-weight: 400;
}

.requestdate {
  font-size: 14px;
  font-weight: 400;
  color: var(--secondary-text-color);
}
</style>